<template>
    <transition name="fade">
        <div
            v-if="showReply"
            class="reply-wrapper"
        >
            <img
                :src="$store.state.profile.currentUserProfile.photoURL"
                :alt="$t('avatar_of', [$store.state.profile.currentUserProfile.displayName])"
                class="avatar comment-avatar"
            />
            <div
                :key="'reply-box'"
                class="grey-box with-noupy reply"
                @click="openReplyEditor"
            >
                <PostEditor
                    ref="editor"
                    class="comment-reply-editor"
                    :collapsable="true"
                    :busy="saving"
                    :edit-mode="true"
                    :initially-open="true"
                    :place-holder="$t('enter_your_comment_here')"
                    :attachment-manager="attachmentManager"
                    no-topics
                    @save="$emit('save', $event)"
                    @cancel="closeReplyEditor"
                />
            </div>
        </div>
    </transition>
</template>

<script>
import PostEditor from "@web/components/editor/PostEditor";
import { wait } from "@web/lib/wait";
import { AttachmentManager } from "@web/components/attachments/attachment-manager";

export default {
    name: "CommentReplyEditor",
    components: {
        PostEditor,
    },
    props: {
        saving: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            showReply: true,
            attachmentManager: new AttachmentManager(),
        };
    },
    methods: {
        async openReplyEditor() {
            // without a short wait the PostEditor component will notice the click event used to show the editor
            // and cause the PostEditor's blur handler to react
            // Vue.nextTick() is unfortunately too fast and does not prevent this issue
            await wait(10);
            this.showReply = true;
        },
        async closeReplyEditor({ close }) {
            close();
            await wait(100); // wait for animation
            this.showReply = false;
            this.$emit("cancel");
        },
    },
};
</script>

<style lang="scss" scoped>
.comment-reply-editor {
    &.collapsed {
        max-height: unset;
    }
}
</style>
