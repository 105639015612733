import {
    mapActions,
    mapGetters,
} from "vuex";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import { INTRANET_UID } from "@web/store/intranet/getters";
import { profileService } from "@web/services";
import { PROFILE_MODULE_NAME } from "@web/store/profile/profile";
import { FETCH_PROFILE } from "@web/store/profile/actions";

/**
 * Renderless component with loading logic of a single user by its `uid`.
 * Default slot must be a single element and accept the scoped context.
 * See `render()` method for available fields within the scoped context.
 */
export default {
    props: {
        userUid: String,
    },
    data() {
        return {
            userLoading: false,
            /** @type {BaseUserProfile} */
            user: undefined,
        };
    },
    computed: {
        ...mapGetters({
            intranetUid: INTRANET_MODULE_NAME + INTRANET_UID,
        }),
    },
    watch: {
        userUid: {
            async handler() {
                await this.fetchUser();
            },
            immediate: true,
        },
    },
    async mounted() {
        await this.fetchUser();
    },
    methods: {
        ...mapActions({
            fetchProfile: PROFILE_MODULE_NAME + FETCH_PROFILE,
        }),
        async fetchUser() {
            this.userLoading = true;
            if (!this.userUid) return;
            try {
                this.user = await this.fetchProfile(this.userUid)
                    .catch(() => profileService.fallbackProfile);
            } finally {
                this.userLoading = false;
            }
        },
    },
    render() {
        const { userLoading, user } = this;
        return this.$scopedSlots.default({
            userLoading,
            user,
        });
    },
};
