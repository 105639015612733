<script>
import { mapGetters } from "vuex";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import { INTRANET_UID } from "@web/store/intranet/getters";
import { viewService } from "@web/services";

import { AUTH_MODULE_NAME } from "@/store/auth/auth";
import { CURRENT_USER } from "@/store/auth/getters";

export default {
    name: "ViewTrigger",
    props: {
        entity: {
            type: Object,
            required: true,
        },
        entityType: String,
    },
    data() {
        return {
            intersectionObserver: undefined,
        };
    },
    computed: {
        ...mapGetters({
            intranetUid: INTRANET_MODULE_NAME + INTRANET_UID,
            currentUser: AUTH_MODULE_NAME + CURRENT_USER,
        }),
    },
    async mounted() {
        if (!this.entity.uid) throw new Error("entity.uid is required");
        if (!this.entity.docPath) throw new Error("entity.docPath is required");
        if (!this.entity.creatorUid) throw new Error("entity.uid is required");

        // ignore own content
        if (this.entity.creatorUid === this.currentUser.uid) {
            return;
        }

        const alreadyViewed = await viewService.viewed({
            intranetUid: this.intranetUid,
            entity: this.entity,
            userUid: this.currentUser.uid
        });

        if (!alreadyViewed) {
            this.registerObserver();
        }
    },
    unmouted() {
        this.unregisterObserver();
    },
    methods: {
        triggerViewEvent() {
            viewService.view(this.intranetUid, this.entityType, this.entity.uid, this.entity.docPath);
        },
        registerObserver() {
            if (this.intersectionObserver) return;
            this.intersectionObserver = new IntersectionObserver((entries) => {
                // If intersectionRatio is 0, the target is out of view
                // and the snapshot listener should be deactivated.
                if (entries[0].intersectionRatio <= 0) {
                    return;
                }
                this.triggerViewEvent();
            });
            this.intersectionObserver.observe(this.$el.parentElement);
        },
        unregisterObserver() {
            if (!this.intersectionObserver) return;
            this.intersectionObserver.unobserve(this.$el.parentElement);
            this.intersectionObserver = undefined;
        },
    },
    render() {
        return null;
    }
};
</script>
