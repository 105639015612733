<template>
    <div
        :data-edit-mode="editMode"
        class="ql-toolbar"
    >
        <span class="ql-formats">
            <button class="ql-bold"></button>
            <button class="ql-italic"></button>
        </span>
        <template v-if="showHeaderButtons">
            <span class="ql-divider"></span>
            <span class="ql-formats">
                <button
                    class="ql-header"
                    value="1"
                ></button>
                <button
                    class="ql-header"
                    value="2"
                ></button>
                <button
                    class="ql-header"
                    value="3"
                >
                    <span
                        class="toolbar-icon"
                        v-html="require('quill/assets/icons/header-3.svg')"
                    ></span>
                </button>
            </span>
        </template>
        <span class="ql-divider"></span>
        <span class="ql-formats">
            <button
                class="ql-list"
                value="bullet"
            ></button>
            <button
                class="ql-list"
                value="ordered"
            ></button>
        </span>
        <template v-if="showLinkButton">
            <span class="ql-divider"></span>
            <span class="ql-formats">
                <button class="ql-link">
                    <span
                        class="toolbar-icon"
                        v-html="require('@web/assets/icons/link.svg')"
                    ></span>
                </button>
            </span>
        </template>
        <template v-if="showAttachmentUploadButton || showEmbedUploadedImageButton || showEmbedUnsplashImageButton">
            <span class="ql-divider"></span>
            <span class="ql-formats">
                <v-popover
                    v-if="showEmbedUploadedImageButton || showEmbedUnsplashImageButton"
                    class="ql-popover"
                    placement="top-start"
                    popover-class="no-padding"
                >
                    <button class="ql-image"></button>
                    <template slot="popover">
                        <ul class="ql-toolbar-image-upload-options ptop-xsmall pbottom-xsmall">
                            <li
                                v-if="showEmbedUploadedImageButton"
                                class="clickable hover:background-lowest-contrast pbottom-xsmall ptop-xsmall pleft-small pright-small"
                                @click="selectFile('imageEmbedUpload')"
                            >
                                {{ $t("editor_upload_image") }}
                            </li>
                            <li
                                v-if="showEmbedUnsplashImageButton"
                                v-close-popover
                                class="clickable hover:background-lowest-contrast pbottom-xsmall ptop-xsmall pleft-small pright-small"
                                @click="$emit('unsplash-select')"
                            >
                                {{ $t("editor_unsplash") }}
                            </li>
                        </ul>
                    </template>
                </v-popover>
                <button
                    v-if="showAttachmentUploadButton"
                    class="ql-upload"
                    @click="selectFile('attachmentUpload')"
                >
                    <span
                        class="toolbar-icon center-container attachment-icon"
                        v-html="require('@web/assets/icons/paperclip.svg')"
                    ></span>
                </button>
            </span>
        </template>

        <template v-if="showAttachmentUploadButton">
            <input
                ref="attachmentUpload"
                class="hidden"
                multiple
                type="file"
                @change="emitUploadEvent('attachmentUpload', 'attachment-selected', $event)"
            />
        </template>

        <template v-if="showEmbedUploadedImageButton">
            <input
                ref="imageEmbedUpload"
                class="hidden"
                type="file"
                accept="image/*"
                @change="emitUploadEvent('imageEmbedUpload', 'embed-image-selected', $event)"
            />
        </template>
    </div>
</template>

<script>
import { validateFileInputEvent } from "@web/components/attachments/attachment-utils";

export default {
    name: "EditorToolbar",
    props: {
        editMode: {
            type: Boolean,
            default: false,
        },
        showHeaderButtons: {
            type: Boolean,
            default: false,
        },
        showAttachmentUploadButton: {
            type: Boolean,
            default: false,
        },
        showLinkButton: {
            type: Boolean,
            default: true,
        },
        showEmbedUploadedImageButton: {
            type: Boolean,
            default: false,
        },
        showEmbedUnsplashImageButton: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        selectFile(refName) {
            if (this.$refs[refName]) {
                this.$emit("file-select");
                this.$refs[refName].click();
            }
        },
        emitUploadEvent(refName, eventName, fileUploadEvent) {
            const files = validateFileInputEvent(fileUploadEvent);
            this.$emit(eventName, {
                files,
                reset: () => {
                    if (this.$refs[refName]) {
                        this.$refs[refName].value = null;
                    }
                }
            });
        },
    },
};
</script>

<style lang="scss">
.ql-toolbar {
    .toolbar-icon svg path {
        fill: $highest-contrast;
    }

    .ql-popover {
        background: none;
        border: none;
        cursor: pointer;
        display: inline-block;
        float: left;
        height: 24px;
        width: 28px;
        border-radius: 3px;
        margin-right: 3px;
    }

    .attachment-icon svg {
        width: 15px;
        height: 15px;
    }
}

.ql-toolbar-image-upload-options {
    font-size: 1.2rem;
    font-weight: initial;
}
</style>
