<template>
    <featured-content
        v-if="event"
        :to="route"
        class="shared-event"
        :feature-image-url="coverImageUrl"
    >
        <template #featuring>
            <event-date-tile :date="event.startTimestamp"/>
        </template>
        <template #main>
            <event-attend-button
                :event="event"
                class="float-right"
            />
            <div class="flex flex-column h-100">
                <h4 class="mbottom-xxsmall ellipsis">
                    {{ event.title }}
                </h4>
                <div class="mbottom-xxsmall">
                    {{ timeRange }}
                </div>
                <div class="flex-fill">
                    <event-attend-badge :event="event"/>
                </div>
                <avatar-list
                    v-if="event.attendees > 0"
                    :user-total="event.attendees"
                    :user-uids="event.lastAttendees"
                    avatar-size="xsmall"
                    class="mtop-xxsmall"
                    stacked
                    @click.prevent.stop.native
                />
            </div>
        </template>
    </featured-content>
    <featured-content-skeleton v-else-if="loading"/>
    <missing-featured-content v-else/>
</template>

<script>
import FeaturedContent from "@/components/attachments/shared-content/FeaturedContent";
import { mapActions } from "vuex";
import EventDateTile from "@web/components/events/EventDateTile";
import { timeForRange } from "@web/lib/time-utils";
import FeaturedContentSkeleton from "@web/components/attachments/shared-content/FeaturedContentSkeleton";
import { EVENT_MODULE_NAME } from "@web/store/event/event";
import { LOAD_EVENT } from "@web/store/event/actions";
import EventAttendButton from "@web/components/events/EventAttendButton";
import MissingFeaturedContent from "@web/components/attachments/shared-content/MissingFeaturedContent";
import EventAttendBadge from "@web/components/events/EventAttendBadge";
import AvatarList from "@web/components/AvatarList";

export default {
    name: "SharedEvent",
    components: {
        AvatarList,
        EventAttendBadge,
        MissingFeaturedContent,
        EventAttendButton,
        FeaturedContentSkeleton,
        EventDateTile,
        FeaturedContent,
    },
    inheritAttrs: false,
    props: {
        uid: String,
    },
    data() {
        return {
            loading: false,
            event: undefined,
        };
    },
    computed: {
        route() {
            return { name: "event-modal-route", params: { eventUid: this.uid } };
        },
        timeRange() {
            return timeForRange(this.event.startTimestamp, this.event.endTimestamp);
        },
        coverImageUrl() {
            if (this.event && this.event.coverImagePath) {
                return this.event.coverImagePath;
            }
            return "/event-bg.jpg";
        },
    },
    mounted() {
        this.fetchEvent();
    },
    methods: {
        ...mapActions({
            globalLoadEvent: EVENT_MODULE_NAME + LOAD_EVENT,
        }),
        async fetchEvent() {
            if (!this.uid) {
                return;
            }
            this.loading = true;
            try {
                this.event = await this.globalLoadEvent({ eventUid: this.uid });
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.shared-event {
    min-height: 10rem;
}
</style>
