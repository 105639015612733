import { ApiClient } from "@web/api/ApiClient";
import { ApiClientBuilder } from "@web/api/ApiClientBuilder";
import { AxiosResponse } from "axios";
import { cloudRunUrl } from "@web/cloud-run-url";
import {
    View,
    ViewableEntityType,
    ViewInFirestoreFieldKeys,
    ViewPaginationSearchParameters,
    ViewTrackable,
    ViewTrackableInFirestore,
    ViewWriteResult,
} from "@backend/view-tracking/types";
import { firestore } from "@web/firebase-instance";
import localforage from "localforage";
import { getGlobalConfiguration } from "@web/global-config";

export class ViewService {
    public static readonly viewServiceEndpoint = `${cloudRunUrl.viewTracking}/api/view-tracking`;

    private readonly api: Promise<ApiClient>;

    private readonly cache: LocalForage;

    constructor() {
        this.api = new ApiClientBuilder().build();
        this.cache = localforage.createInstance({
            driver: localforage.INDEXEDDB,
            name: "caching",
            size: 32768, // 32KB
            storeName: "viewCache",
        });
    }

    public async viewed({ intranetUid, entity, userUid }: { intranetUid: string, entity: ViewTrackable, userUid: string }): Promise<boolean> {
        if (this.isBeforeUnreadMigration(entity)) {
            return true;
        }
        if (userUid === entity.creatorUid) {
            return true;
        }
        const viewDocPath = this.viewDocPath({ intranetUid, entityUid: entity.uid, userUid });
        const cacheValue: boolean | null = await this.cache.getItem(viewDocPath);
        if (cacheValue == null) {
            const viewDocRef = firestore.doc(viewDocPath);
            const viewDoc = await viewDocRef.get();
            // Only cache existing values to be able to update unread documents
            if (viewDoc.exists) {
                await this.cache.setItem(viewDocPath, viewDoc.exists);
            }
            return viewDoc.exists;
        }
        return cacheValue;
    }

    private isBeforeUnreadMigration(entity: ViewTrackable): boolean {
        const migrationDate = Date.parse(getGlobalConfiguration().view_tracking_comment_and_post_migration_date);
        return (entity.creationDate._seconds * 1000) < migrationDate;
    }

    public async view(intranetUid: string, entityType: ViewableEntityType, viewableEntityUid: string, docPath: string): Promise<ViewWriteResult> {
        const client = await this.api;
        const url = `${ViewService.viewServiceEndpoint}/${intranetUid}/${entityType}/${viewableEntityUid}/`;
        const response: AxiosResponse = await client.post(url, { docPath });
        if (response.data.updated && response.data.viewDocPath) {
            await this.cache.setItem(response.data.viewDocPath, true);
        }
        return response.data;
    }

    public async getViewers({ intranetUid, entityUid, creatorUid, searchParams }: { intranetUid: string, entityUid: string, creatorUid: string, searchParams: ViewPaginationSearchParameters }): Promise<View[]> {
        let query = firestore.collection(`intranet/${intranetUid}/view`)
            .where(ViewInFirestoreFieldKeys.entityUid, "==", entityUid)
            .where(ViewInFirestoreFieldKeys.creatorUid, "==", creatorUid)
            .orderBy(ViewInFirestoreFieldKeys.viewedTimestamp, "desc")
            .limit(searchParams.limit);

        if (searchParams.startAfter) {
            query = query.startAfter(searchParams.startAfter);
        }

        const results = await query.get();
        return results.docs.map(result => result.data() as View);
    }

    /**
     *
     * @return An unsubscribe function that can be called to cancel the snapshot listener.
     */
    public onViewersUpdates({ intranetUid, entityUid, creatorUid, onCollectionUpdate }: { intranetUid: string, entityUid: string, creatorUid: string, onCollectionUpdate: (views: View[]) => void }): () => void {
        const query = firestore.collection(`intranet/${intranetUid}/view`)
            .where(ViewInFirestoreFieldKeys.entityUid, "==", entityUid)
            .where(ViewInFirestoreFieldKeys.creatorUid, "==", creatorUid)
            .where(ViewInFirestoreFieldKeys.viewedTimestamp, ">=", new Date())
            .orderBy(ViewInFirestoreFieldKeys.viewedTimestamp, "desc");

        return query.onSnapshot({
            next: (snapshot) => {
                onCollectionUpdate(snapshot.docs.map(snapshot => snapshot.data() as View));
            }
        });
    }

    public onViewsUpdates({ docPath, onDocUpdate }: { docPath: string, onDocUpdate: (viewTrackableInFirestore: ViewTrackableInFirestore) => void }): () => void {
        return firestore.doc(docPath).onSnapshot({
            next: (snapshot) => {
                onDocUpdate(snapshot.data() as ViewTrackableInFirestore);
            }
        });
    }

    public viewUid({ entityUid, userUid }: { entityUid: string, userUid: string }): string {
        return `${entityUid}-${userUid}`;
    }

    public viewDocPath({ intranetUid, entityUid, userUid }: { intranetUid: string, entityUid: string, userUid: string }): string {
        const viewUid = this.viewUid({ entityUid, userUid });
        return `intranet/${intranetUid}/view/${viewUid}`;
    }
}
