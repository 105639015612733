import { cloudRunUrl } from "@web/cloud-run-url";
import { ApiClient } from "@web/api/ApiClient";
import { ApiClientBuilder } from "@web/api/ApiClientBuilder";
import { Timestamp } from "@backend/entity/types";
import { StripeSessionInfo } from "@backend/product-subscription/types";

export class ProductSubscriptionService {
    private static readonly functionUrl = `${cloudRunUrl.productSubscription}/api/product-subscriptions`;

    private readonly api: Promise<ApiClient>;

    constructor() {
        this.api = new ApiClientBuilder().build();
    }

    async setSkipTimestamp(intranetUid: string): Promise<void> {
        const api = await this.api;
        await api.post(`${ProductSubscriptionService.functionUrl}/${intranetUid}/skipTimestamp`);
    }

    async getSkipTimestamp(intranetUid: string): Promise<Timestamp | null> {
        const api = await this.api;
        const skipTimestampRequest = await api.get<Timestamp | null>(`${ProductSubscriptionService.functionUrl}/${intranetUid}/skipTimestamp`);
        return skipTimestampRequest.data;
    }

    async createStripeCheckoutSession(intranetUid: string): Promise<StripeSessionInfo> {
        const api = await this.api;
        const stripeSessionInfo = await api.post<StripeSessionInfo>(
            `${ProductSubscriptionService.functionUrl}/${intranetUid}/payment-session`,
        );
        return stripeSessionInfo.data;
    }

    async createCustomerPortalSession(intranetUid: string): Promise<StripeSessionInfo> {
        const api = await this.api;
        const stripeSessionInfo = await api.post<StripeSessionInfo>(
            `${ProductSubscriptionService.functionUrl}/${intranetUid}/customer-portal-session`,
        );
        return stripeSessionInfo.data;
    }
}
