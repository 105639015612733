export const SET_SINGLE_POST = "setSinglePost";
export const SET_SINGLE_POST_WITH_COMMENT = "setSinglePostWithComment";
export const SET_SINGLE_POST_COMMENT_TREE = "setSinglePostCommentTree";
export const SET_MODE = "setMode";
export const REPLACE_POST_CONTENT = "replacePostContent";
export const MARK_POST_AS_DELETED = "markPostAsDeleted";
export const PREPEND_COMMENTS = "prependComments";
export const APPEND_COMMENTS = "appendComments";
export const REPLACE_COMMENT = "replaceComment";
export const MARK_COMMENT_AS_DELETED = "markCommentAsDeleted";
export const CLEAN_STATE = "cleanState";
