import Quill from "quill";

/**
 * @return true, if event handling should stop after execution of handler
 */
type EventHandler = (event: Event, quill?: Quill) => boolean;

/**
 * Registers event handlers on quill.root
 * Handlers should return true, if they were executed and do not want subsequent handlers to execute.
 * Handlers on the same event are prioritized by their index.
 * example:
 * {
 *     "paste": [
 *         imagePasteHandler, // this is executed first
 *         textPasteHandler, // this is executed second
 *     ],
 *     "drop": [
 *         imagePasteHandler,
 *     ]
 * }
 */
interface EventHandlingOptions {
    eventHandlers: Record<string, EventHandler[]>
}

export class EventHandling {
    public constructor(
        private readonly quill: Quill,
        private readonly options: EventHandlingOptions) {
        this.addListenersFromOptions();
    }

    private addListenersFromOptions() {
        for (const eventName in this.options.eventHandlers) {
            this.quill.root.addEventListener(eventName, (event) => {
                for (const handler of this.options.eventHandlers[eventName]) {
                    const stop = handler(event, this.quill);
                    if (stop) {
                        break;
                    }
                }
            });
        }
    }
}
