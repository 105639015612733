// ROOT-COLLECTIONS
export const USER_COLLECTION_IDENTIFIER = "user";
export const INTRANET_COLLECTION_IDENTIFIER = "intranet";
export const PROFILE_COLLECTION_IDENTIFIER = "profile";
export const ATLASSIAN_ACCESS_TOKENS_COLLECTION_IDENTIFIER = "atlassian-access-tokens";
export const GOOGLE_ACCESS_TOKENS_COLLECTION_IDENTIFIER = "google-access-tokens";
export const GLOBALS_COLLECTION_IDENTIFIER = "globals";
export const ATLASSIAN_CLOUD_COLLECTION_IDENTIFIER = "atlassian-cloud";
export const FCM_DEVICE_TOKEN_COLLECTION_IDENTIFIER = "fcm-device-tokens";

export const PRIVATE_COLLECTION_IDENTIFIER = "private";
