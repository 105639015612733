<template>
    <v-modal
        :scrollable="true"
        :name="name"
        :transition="modalConfig.transition"
        :width="customWidth || modalConfig.width"
        :height="customHeight || modalConfig.height"
        :delay="modalConfig.delay"
        :pivot-y="modalPivotY"
        :click-to-close="isAvoidable"
        :data-unavoidable="unavoidable"
        :data-fullscreen="fullscreen"
        :data-dark="dark"
        :data-position="position"
        v-on="$listeners"
    >
        <div class="flex controls top-right">
            <slot name="controls"></slot>
            <div
                v-if="fullscreen && isAvoidable"
                class="control close"
                @click="closeModal"
            >
                <Icon name="x"/>
            </div>
        </div>
        <div
            v-if="showHeader"
            class="modal-header"
        >
            <slot name="header">
                <div>
                    <h3 class="header-title">
                        {{ title }}
                    </h3>
                    <div
                        v-if="subTitle"
                        class="header-subtitle mtop-small"
                    >
                        {{ subTitle }}
                    </div>
                </div>
            </slot>
            <div class="actions">
                <div
                    v-if="isAvoidable"
                    class="close-button"
                    @click="closeModal"
                >
                    <Icon
                        name="x"
                    />
                </div>
            </div>
        </div>
        <div
            class="modal-body"
            :data-fullscreen="fullscreen"
        >
            <slot
                v-if="!fullscreen"
                name="body"
            >
                <p>
                    <slot></slot>
                </p>
            </slot>
            <slot
                v-if="fullscreen"
            ></slot>
        </div>
        <div
            class="modal-footer"
            :class="fullscreen? 'fullscreen-footer' :''"
        >
            <slot
                v-if="!hideFooter"
                name="footer"
            >
                <div
                    v-if="!fullscreen"
                    class="grid"
                >
                    <div class="left"></div>
                    <div class="right">
                        <div class="button-group">
                            <app-button
                                :type="warning ? 'warning' : 'primary'"
                                :busy="busy"
                                :disabled="confirmButtonDisabled"
                                @click="confirmModal"
                            >
                                {{ confirmText || $t("button_ok") }}
                            </app-button>
                            <app-button
                                type="link"
                                @click="closeModal"
                            >
                                {{ cancelText || $t("button_cancel") }}
                            </app-button>
                        </div>
                    </div>
                </div>
            </slot>
        </div>
    </v-modal>
</template>

<script>
import Icon from "@web/components/Icon.vue";

export default {
    name: "Modal",
    components: {
        Icon,
    },
    props: {
        name: {
            type: String,
            default: "modal",
        },
        title: {
            type: String,
            default: "",
        },
        subTitle: {
            type: String,
            default: "",
        },
        unavoidable: {
            type: Boolean,
            default: false,
        },
        fullscreen: {
            type: Boolean,
            default: false,
        },
        warning: {
            type: Boolean,
            default: false,
        },
        hideFooter: {
            type: Boolean,
            default: false,
        },
        dark: {
            type: Boolean,
            default: false,
        },
        position: {
            type: String,
            default: "center",
        },
        customWidth: {
            type: String,
            default: "",
        },
        customHeight: {
            type: String,
            default: "",
        },
        confirmText: {
            type: String,
            default: "",
        },
        cancelText: {
            type: String,
            default: "",
        },
        /**
         * @type {() => Promise<void>}
         */
        asyncHandler: {
            type: Function,
            default: async() => {},
        },
        errorHandler: {
            type: Function,
            default: undefined,
        },
        confirmButtonDisabled: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        return {
            busy: false,
        };
    },
    computed: {
        showHeader() {
            return this.title && !this.fullscreen;
        },
        isAvoidable() {
            return !this.unavoidable;
        },
        modalConfig() {
            return {
                transition: this.fullscreen ? "fade" : "modalFadeIn",
                width: this.fullscreen ? "100%" : "500",
                height: this.fullscreen ? "100%" : "auto",
                delay: this.fullscreen ? 150 : 0,
            };
        },
        modalPivotY() {
            if (this.position === "top") {
                return 0.1;
            }
            if (this.position === "bottom") {
                return 0.8;
            }
            return 0.5;
        },
    },
    methods: {
        async confirmModal() {
            try {
                this.busy = true;
                await this.asyncHandler();
                this.$emit("modal-confirm");
                this.closeModal();
            } catch (e) {
                this.errorHandler(e);
            } finally {
                this.busy = false;
            }
        },
        openModal(name = this.name) {
            this.$modal.show(name);
        },
        closeModal() {
            this.$modal.hide(this.name);
        },
    },
};
</script>

<style lang="scss" scoped>
[data-fullscreen="true"] .modal-footer.fullscreen-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 0.8rem;
}

.header-title {
    margin-bottom: 4px;
}

.header-subtitle {
    margin-bottom: 0;
    font-size: 16px;
    color: var(--high-contrast);
}
</style>
