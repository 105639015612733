<template>
    <!--Intranet wrapper is necessary to provide thie.$el for StyleMixin-->
    <main id="intranet-wrapper">
        <!-- an intranet can only be used by a loggedIn user -->
        <upgrade-subscription-hint
            :loading="!(intranet && loggedIn && isSetUp)"
        />
        <LoadingWrapper
            :is-loading="!(intranet && loggedIn && isSetUp)"
            class="site-wide"
        >
            <template #indicator>
                <splash-screen/>
            </template>
            <Sidebar/>
            <everest-product-fruits/>
            <div class="intranet-content">
                <Header>
                    <content-search-bar/>
                </Header>
                <transition
                    mode="out-in"
                    name="smoothmove"
                >
                    <router-view/>
                </transition>
            </div>

            <notifications
                :duration="4000"
                class="top-right"
                classes="vue-notification everest-notification mbottom-medium card depth-3"
                group="app"
                position="top right"
            />
            <EventModal/>
            <ReactionsModal/>
            <ViewersModal />
            <ConfirmModal/>
            <GenericModal/>
            <router-view name="modal"/>
            <social-notification-interaction-popup/>
            <intranet-lifecycle-observer/>
        </LoadingWrapper>
    </main>
</template>

<script>
import Sidebar from "@web/views/Sidebar.vue";
import Header from "@web/views/intranet/Header";
import { INITIALIZE_INTRANET } from "@web/store/intranet/actions";
import {
    INTRANET_MODULE_NAME,
    LAST_VISITED_INTRANET,
} from "@web/store/intranet/intranet";
import {
    mapActions,
    mapGetters,
    mapMutations,
} from "vuex";
import { COLORS } from "@web/store/theming/getters";
import StyleMixin from "@web/views/StyleMixin.vue";
import { AUTH_MODULE_NAME } from "@web/store/auth/auth";
import { LOGGED_IN } from "@web/store/auth/getters";
import {
    INITIALIZED,
    INTRANET,
    IS_ADMIN,
} from "@web/store/intranet/getters";
import EventModal from "@web/views/events/EventModal.vue";
import ReactionsModal from "@web/components/reaction/ReactionsModal.vue";
import { SET_HAS_USER_NAVIGATED_TO_AN_INTRANET } from "@web/store/intranet/mutations";
import ConfirmModal from "@web/components/modals/ConfirmModal";
import SplashScreen from "@web/components/SplashScreen";
import IntranetLifecycleObserver from "@web/components/renderless/IntranetLifecycleObserver.vue";
import ContentSearchBar from "@web/views/intranet/content-search/ContentSearchBar";
import SocialNotificationInteractionPopup from "@web/components/social-notifications/SocialNotificationInteractionPopup";
import GenericModal from "@web/components/modals/GenericModal";
import ViewersModal from "@/components/view-tracking/ViewersModal";
import EverestProductFruits from "@web/views/EverestProductFruits";
import { TOPIC_SUBSCRIPTION_MODULE_NAME } from "@web/store/topic-subscription/topic-subscription";
import { FETCH_TOPIC_SUBSCRIPTION } from "@web/store/topic-subscription/actions";
import { LIVE_NEW_CONTENT_MODULE_NAME } from "@web/store/live-new-content/live-new-content";
import {
    REGISTER_LISTENERS,
    UNREGISTER_LISTENERS,
} from "@web/store/live-new-content/actions";
import UpgradeSubscriptionHint from "@web/views/intranet/UpgradeSubscriptionHint";

export default {
    name: "Intranet",
    components: {
        UpgradeSubscriptionHint,
        EverestProductFruits,
        ContentSearchBar,
        GenericModal,
        SocialNotificationInteractionPopup,
        SplashScreen,
        ConfirmModal,
        Sidebar,
        Header,
        EventModal,
        ReactionsModal,
        ViewersModal,
        IntranetLifecycleObserver,
    },
    mixins: [
        StyleMixin,
    ],
    props: {
        intranetId: String,
    },
    data() {
        return {
            isSetUp: false,
        };
    },
    computed: {
        ...mapGetters({
            loggedIn: AUTH_MODULE_NAME + LOGGED_IN,
            colors: INTRANET_MODULE_NAME + COLORS,
            intranetInitialized: INTRANET_MODULE_NAME + INITIALIZED,
            isAdmin: INTRANET_MODULE_NAME + IS_ADMIN,
            intranet: INTRANET_MODULE_NAME + INTRANET,
        }),
    },
    watch: {
        intranetId: {
            handler() {
                this.initIntranet();
            },
            immediate: true,
        },
        colors: {
            handler() {
                // $nextTick is necessary to have an initialized this.$el for the style changes
                this.$nextTick(() => {
                    this.styleInject();
                });
            },
            deep: true,
            immediate: true,
        },
        intranetInitialized: {
            handler() {
                this.fetchUserSettings();
                this.styleInject();
            },
            immediate: true,
            deep: true,
        },
        loggedIn: {
            handler() {
                this.fetchUserSettings();
            },
            immediate: true,
        },
    },
    beforeDestroy() {
        this.unregisterNewContentListeners();
    },
    methods: {
        ...mapMutations({
            setHasUserNavigatedToAnIntranet: INTRANET_MODULE_NAME + SET_HAS_USER_NAVIGATED_TO_AN_INTRANET,
        }),
        ...mapActions({
            setup: INTRANET_MODULE_NAME + INITIALIZE_INTRANET,
            fetchTopicSubscription: TOPIC_SUBSCRIPTION_MODULE_NAME + FETCH_TOPIC_SUBSCRIPTION,
            registerNewContentListeners: LIVE_NEW_CONTENT_MODULE_NAME + REGISTER_LISTENERS,
            unregisterNewContentListeners: LIVE_NEW_CONTENT_MODULE_NAME + UNREGISTER_LISTENERS,
        }),
        async initIntranet() {
            this.isSetUp = false;
            try {
                await this.setup(this.$router.currentRoute.params.intranetId);
                localStorage.setItem(LAST_VISITED_INTRANET, this.intranetId);
                this.setHasUserNavigatedToAnIntranet();
                this.isSetUp = true;
            } catch (e) {
                console.error(e);
            }
        },
        async fetchUserSettings() {
            if (!this.loggedIn) {
                console.info("postpone loading user settings, user not logged in yet");
                return;
            }
            if (!this.intranetInitialized) {
                console.info("postpone loading user settings, intranet not initialized yet");
                return;
            }
            console.info("loading user settings");
            try {
                await this.fetchTopicSubscription();
                await this.registerNewContentListeners();
            } catch (e) {
                this.$nextTick(() => this.$notify({
                    group: "app",
                    type: "error",
                    text: this.$t("topic_subscription_loading_error"),
                }));
                console.warn("unable to fetch topic subscriptions", e);
            }
        },
    },
};
</script>

<style lang="scss">
#intranet-wrapper {
    --sidebar-width: 13rem;
    display: flex;
    flex-direction: column;

    .main-sidebar {
        flex: 0 0 auto;
    }

    .intranet-content {
        flex: 1 1 auto;
        min-width: 0;
        overflow-x: clip;
    }

}

.intranet-content {
    padding: 0 2rem 0 2rem;
}

.notifications.top-right {
    top: 65px !important;
    right: 30px !important;
    z-index: 999999;

    .notification-wrapper {
        overflow: unset;
    }

    .vue-notification.everest-notification {
        padding: 1rem;
        margin-bottom: 1rem;
        font-size: 1rem;
        border: none;

        &.info {
            background: var(--info);
            color: var(--info-text);
        }

        &.error {
            background: var(--warning);
            color: var(--warning-text);
        }

        &.success {
            background: var(--primary);
            color: var(--primary-text);
        }
    }
}

@media (max-width: 700px) {
    .intranet-content {
        padding: 0 2rem 0 2rem;
    }
}

@media (max-width: 480px) {
    .intranet-content {
        padding: 0 1rem 0 1rem;
    }
}

@media (max-width: 375px) {
    .intranet-content {
        padding: 0 0.5rem 0 0.5rem;
    }
}

</style>
