<template>
    <div>
        <a
            v-open-event-modal="event.uid"
            class="event-item-container"
            :data-mode="mode"
        >
            <div class="event-item-content">
                <EventDateTile :date="event.startTimestamp"/>
                <div class="event-info">
                    <h4 class="clamp">{{ event.title }}</h4>
                    <div class="time">
                        {{ displayTime() }}
                    </div>
                    <div
                        v-if="showMeta"
                        class="event-meta"
                    >
                        <div class="meta">
                            <Badge
                                v-if="isAttending && options.displayAttendeeStatus"
                                :text="$t('events_attending')"
                                class="display-attendee-status"
                                type="secondary"
                            />
                            <InlineIconText
                                v-if="options.displayAttendeesCount"
                                :title="event.attendees.toString()"
                                icon="users"
                            />
                            <InlineIconText
                                v-if="options.displayLocation && isFeaturePreviewActive"
                                title="London"
                                icon="pin"
                            />
                        </div>
                        <avatar-list
                            v-if="options.displayLastAttendees"
                            :user-uids="event.lastAttendees"
                            :user-total="event.attendees"
                            stacked
                            @click.prevent.stop.native
                        />
                    </div>
                </div>
            </div>
        </a>
        <hr v-if="!isLastItem"/>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import EventDateTile from "@web/components/events/EventDateTile";
import InlineIconText from "@web/components/InlineIconText";
import Badge from "@web/components/Badge";
import { timeForRange } from "@web/lib/time-utils";
import { EVENT_MODULE_NAME } from "@web/store/event/event";
import { GET_CURRENT_USER_ATTENDEE_STATUS } from "@web/store/event/getters";
import { EverestEventAttendeeStatus } from "@backend/event/types";
import AvatarList from "@web/components/AvatarList";
import { INTRANET_MODULE_NAME } from "@/store/intranet/intranet";
import { FEATURE_PREVIEW_ACTIVE } from "@/store/intranet/getters";

export default {
    components: { AvatarList, InlineIconText, EventDateTile, Badge },
    props: {
        event: { required: true, type: Object },
        options: {
            type: Object,
            default: () => ({
                displayAttendeeStatus: true,
                displayLastAttendees: true,
                displayAttendeesCount: true,
                displayLocation: true,
            }),
        },
        mode: { type: String },
        isLastItem: { type: Boolean },
    },
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            isFeaturePreviewActive: INTRANET_MODULE_NAME + FEATURE_PREVIEW_ACTIVE,
            getAttendeeStatus: EVENT_MODULE_NAME + GET_CURRENT_USER_ATTENDEE_STATUS,
        }),
        isAttending() {
            return this.getAttendeeStatus(this.event) === EverestEventAttendeeStatus.attending;
        },
        showMeta() {
            return this.options.display || (this.options.displayLastAttendees && this.event.attendees) || (this.isAttending && this.options.displayAttendeeStatus);
        }
    },
    methods: {
        displayTime() {
            return timeForRange(this.event.startTimestamp, this.event.endTimestamp);
        },
    },
};
</script>

<style lang="scss" scoped>

hr {
    margin: 1rem 0 1.5rem;
}

.event-item-content {
    display: flex;
    flex-wrap: nowrap;

    .date-tile + .event-info {
        margin-left: 1rem;
    }

    .event-info {
        flex: 4 1 auto;
        min-width: 0;

        h4 {
            margin: 0;
        }

        .time {
            display: flex;
            align-items: center;
            color: var(--high-contrast);

            .button {
                margin-left: 0.5rem;
            }
        }

    }

    .event-meta {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
    }

    .display-attendee-status {
        margin-right: 0.75rem;
        vertical-align: top;
    }

    ::v-deep  {
        .avatar, .hidden-users {
            width: 1.75rem;
            height: 1.75rem;
        }
        .avatar-list.inline {
            margin-left: auto;
        }
    }
}

.event-item-container[data-mode="dashboard"] {
    .event-item-content {
        h4 {
            font-size: 1rem;
        }

        .time {
            font-size: 0.75rem;
        }

        ::v-deep {
            .display-attendee-status {
                .button-badge {
                    font-size: 0.625rem;
                    vertical-align: sub;
                }
            }

            .avatar-list.inline .avatar, .hidden-users {
                width: 1.5rem;
                height: 1.5rem;
            }
        }
    }
}
</style>
