<template>
    <div>
        <div
            v-if="remainingCommentsCount > 0"
            class="comment-load-more mbottom-small"
        >
            <app-button
                :busy="loadingMoreComments"
                class="comment-action reply-action"
                type=""
                @click="loadMoreComments"
            >
                {{ $tc("more_comments_to_view", remainingCommentsCount) }}
            </app-button>
        </div>

        <Comment
            v-for="comment in reversedComments"
            :key="comment.uid"
            :comment="comment"
            :comment-route="routeToComment(comment)"
            :content-creator-uid="contentCreatorUid"
            :content-uid="contentUid"
            :on-create-reply="onCreateReply"
            :on-delete-comment="onDeleteComment"
            :on-edit-comment="onEditComment"
            :on-save-fail="onSaveFail"
            :root-entity-type="contentType"
        >
            <template #comments-container>
                <CommentTree
                    :key="comment.uid"
                    :comment-count="comment.commentCount"
                    :comments="comment.comments"
                    :content-creator-uid="contentCreatorUid"
                    :content-type="contentType"
                    :content-uid="contentUid"
                    :parent-comment-path="comment._path"
                />
            </template>
        </Comment>
    </div>
</template>

<script>
import {
    mapGetters,
    mapMutations,
} from "vuex";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import Comment from "@web/components/comments/Comment";
import { commentService } from "@web/services";
import { CommentService } from "@web/services/comments";
import { CommentWithAttachmentReference } from "@web/services/attachments";
import { AUTH_MODULE_NAME } from "@web/store/auth/auth";
import { CURRENT_USER } from "@web/store/auth/getters";
import {
    TIMESTAMP_MODULE_NAME,
    timestampEventForContentType,
} from "@/store/timestamping/timestamping";
import { IGNORE_NEXT_EVENT } from "@/store/timestamping/mutations";
import { CommentableEntityType } from "@backend/comment/types";
import { INTRANET } from "@web/store/intranet/getters";
import {
    ADD_IGNORE_CONTENT_ENTITY_UPDATE,
    REMOVE_IGNORE_CONTENT_ENTITY_UPDATE,
} from "@web/store/live-new-content/mutations";
import { LIVE_NEW_CONTENT_MODULE } from "@web/store/live-new-content/live-new-content";

export default {
    name: "CommentTree",
    components: {
        Comment,
    },
    props: {
        comments: {
            type: Array,
            required: true,
        },
        parentCommentPath: {
            type: String,
            default: "",
        },
        contentUid: {
            type: String,
            required: true,
        },
        contentType: {
            type: String,
            required: true,
            validator(value) {
                return Object.values(CommentableEntityType).indexOf(value) >= 0;
            },
        },
        contentCreatorUid: {
            type: String,
            required: true,
        },
        commentCount: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            loadingMoreComments: false,
        };
    },
    computed: {
        ...mapGetters({
            intranet: INTRANET_MODULE_NAME + INTRANET,
            currentUser: AUTH_MODULE_NAME + CURRENT_USER,
        }),
        remainingCommentsCount() {
            return this.commentCount - this.comments.length;
        },
        reversedComments() {
            return this.comments.slice().reverse();
        },
    },
    methods: {
        ...mapMutations({
            // older timestamp based variant
            ignoreContentUpdateEvent: TIMESTAMP_MODULE_NAME + IGNORE_NEXT_EVENT,
            // new variant with topic awareness
            addIgnoreContentEntityUpdate: LIVE_NEW_CONTENT_MODULE + ADD_IGNORE_CONTENT_ENTITY_UPDATE,
            removeIgnoreContentEntityUpdate: LIVE_NEW_CONTENT_MODULE + REMOVE_IGNORE_CONTENT_ENTITY_UPDATE,
        }),
        routeToComment(comment) {
            if (this.contentType !== CommentableEntityType.post) {
                return "";
            }
            return `/intranet/${this.intranet.uid}/${this.contentType}/id/${this.contentUid}/${comment._path}`;
        },
        async onEditComment(comment, newContent) {
            const updatedComment = await commentService.editComment(
                this.intranet.uid,
                this.contentType,
                this.contentUid,
                newContent,
                comment._path,
            );
            Object.assign(comment, updatedComment);
        },
        async onDeleteComment(comment) {
            await commentService.deleteComment(
                this.intranet.uid,
                this.contentType,
                this.contentUid,
                comment._path,
            );
            comment.isDeleted = true;
        },
        async onCreateReply(parentComment, content, attachmentManager) {
            try {
                // ignore update event to show not new content indicator
                this.ignoreContentUpdateEvent({ timestampEvent: timestampEventForContentType(this.contentType), ignore: true });
                this.addIgnoreContentEntityUpdate(this.contentUid);
                const newComment = await commentService.createComment(
                    this.intranet.uid,
                    this.contentType,
                    this.contentUid,
                    content,
                    parentComment._path,
                );
                newComment.comments = [];
                // adds an arbitrary flag to indicate self created comments
                newComment._new = true;
                await attachmentManager.save(
                    new CommentWithAttachmentReference({
                        intranetUid: this.intranet.uid,
                        entityType: this.contentType,
                        entityUid: this.contentUid,
                        commentPath: newComment._path,
                        entity: newComment,
                    }),
                    newComment.creatorUid,
                    true,
                );
                parentComment.comments.unshift(newComment);
                parentComment.commentCount++;
            } catch (e) {
                this.removeIgnoreContentEntityUpdate(this.contentUid);
                throw e;
            }
        },
        onSaveFail() {
            this.ignoreContentUpdateEvent({ timestampEvent: timestampEventForContentType(this.contentType), ignore: false }); // Reset ignore state in case of errors
        },
        async loadMoreComments() {
            this.loadingMoreComments = true;
            try {
                const previousComments = await commentService.getComments(
                    this.intranet.uid,
                    this.contentType,
                    this.contentUid,
                    this.parentCommentPath,
                    CommentService.LOAD_MORE_PAGE_SIZE,
                    this.comments[this.comments.length - 1].uid,
                );
                this.comments.push(...previousComments);
            } catch (e) {
                this.$notify({
                    group: "app",
                    type: "error",
                    text: this.$t("error_load_more_comments"),
                });
                console.error(e);
            }
            this.loadingMoreComments = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.comment-load-more {
    font-size: 14px;

    &::v-deep {
        .button {
            padding: 2px 8px;
            border-radius: 3px;
            background: var(--low-contrast, #{$light-grey});
            color: var(--highest-contrast, #{$dark-grey});
        }
    }
}
</style>
