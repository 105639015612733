import { render, staticRenderFns } from "./NewsActionsFooter.vue?vue&type=template&id=fe31b892&scoped=true&"
import script from "./NewsActionsFooter.vue?vue&type=script&lang=js&"
export * from "./NewsActionsFooter.vue?vue&type=script&lang=js&"
import style0 from "./NewsActionsFooter.vue?vue&type=style&index=0&id=fe31b892&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fe31b892",
  null
  
)

export default component.exports