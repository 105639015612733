<template>
    <div class="v-card">
        <pu-skeleton
            class="background-skeleton"
            :loading="loading"
            tag="div"
        >
            <img :src="backgroundUrl" />
        </pu-skeleton>
        <pu-skeleton
            class="avatar-skeleton"
            :class="{
                avatar: loading
            }"
            circle
            :loading="loading"
        >
            <router-link
                :to="linkTo"
                class="hover-underline"
            >
                <avatar
                    :photoURL="photoUrl"
                />
            </router-link>
        </pu-skeleton>
        <div class="centered pleft-medium pright-medium pbottom-medium ptop-xsmall">
            <pu-skeleton
                class="pbottom-xxsmall"
                :loading="loading"
                tag="div"
                width="8rem"
            >
                <h4 class="mbottom-none">
                    <router-link
                        :to="linkTo"
                        class="hover-underline"
                    >
                        {{ name }}
                    </router-link>
                </h4>
            </pu-skeleton>
            <div class="text-highest-contrast">
                <pu-skeleton :loading="loading">
                    <slot name="meta"></slot>
                </pu-skeleton>
            </div>
            <div
                v-if="$slots.actions"
                class="ptop-xsmall"
            >
                <slot name="actions"></slot>
            </div>
        </div>
    </div>
</template>

<script>
import Avatar from "@web/components/Avatar";

export default {
    name: "VCard",
    components: {
        Avatar,
    },
    props: {
        loading: {
            type: Boolean,
            default: false,
        },
        /**
         * Object which is passed into the router-link component wrapping links to the person/team the VCard is for
         */
        linkTo: Object,
        photoUrl: String,
        backgroundUrl: String,
        name: String,
    },
};
</script>

<style lang="scss" scoped>
$avatar-size: 6rem;
$avatar-offset: 1rem;
$background-size: 5.5rem;

.v-card {
    width: 15rem;
    position: relative;

    .background-skeleton {
        overflow: hidden;
        height: $background-size;
        margin-bottom: calc(#{$avatar-size} - #{$background-size} + #{$avatar-offset});

        ::v-deep.pu-skeleton {
            border-radius: unset;
            line-height: unset;
        }

        img {
            object-fit: cover;
            width: 100%;
            height: $background-size;
        }
    }

    .avatar-skeleton {
        position: absolute;
        left: calc(50% - #{$avatar-size} / 2);
        top: $avatar-offset;
        width: $avatar-size;
        height: $avatar-size;
        .avatar {
            width: 100%;
            height: 100%;
        }
    }
}
</style>

<docs>
### Loading
```vue
<v-card
    :loading="true"
/>
```

### Loaded
```vue
<v-card
    photo-url="https://picsum.photos/200"
    background-url="https://picsum.photos/200/300"
    name="Name"
    :link-to="{ name: 'user-profile', params: { userUid: '123' }}"
>
    <template v-slot:meta>
        <span>Meta Information</span>
    </template>
    <template v-slot:actions>
        <app-button>Action Button</app-button>
    </template>
</v-card>
```
</docs>
