<template>
    <a
        :href="url"
        target="_blank"
        :class="{
            'single': multi === 1,
            'two': multi === 2,
            'three': multi > 2,
            'clickable': !editMode && !loading,
        }"
        @click="interceptClick"
    >
        <SystemMessage
            v-if="error"
            asset="searching"
            title="Image not available"
            text="Image could not be found"
        />
        <DeletableWrapper
            v-else
            class="attachment media-attachment"
            :class="{ 'half': multi === 2, 'w-100': multi === 1 }"
            :edit-mode="editMode && !loading"
            transition-name="moveInFromRight"
            @delete="$emit('delete')"
        >
            <pu-skeleton
                v-if="multi === 1 && loading"
                height="30rem"
            />
            <div
                v-else-if="isImage && multi === 1 && displayMode === DisplayMode.fillHeight"
                class="image fill-height-container"
            >
                <img
                    class="blurred-background-image image-crop"
                    :src="url"
                    loading="lazy"
                    alt="attachment image"
                    @error="error = true"
                />
                <img
                    :src="url"
                    class="image-fill-height"
                    loading="lazy"
                    alt="attachment image"
                    @error="error = true"
                />
            </div>
            <img
                v-else-if="isImage && multi === 1"
                class="image"
                loading="lazy"
                :class="{
                    'image-crop': displayMode === DisplayMode.crop,
                }"
                :src="url"
                alt="attachment image"
                @error="error = true"
            />
            <div
                v-else-if="isImage && multi > 1"
                class="image image-attachment-div"
                :class="{ loading }"
            >
                <PuSkeleton
                    :loading="loading || displayMode === null"
                    class="skeleton"
                    height="100%"
                >
                    <img
                        :src="url"
                        alt="attachment image"
                        loading="lazy"
                    />
                </PuSkeleton>
            </div>
        </DeletableWrapper>
    </a>
</template>

<script>
import { AttachmentType } from "@backend/attachment/types";
import DeletableWrapper from "@web/components/attachments/DeletableFrame";
import {
    AttachmentReference,
    LoadingAttachmentReference
} from "@web/components/attachments/attachment-manager";
import {
    DisplayMode,
    getDisplayMode,
} from "@web/components/attachments/attachment-utils";
import { inIframe } from "@web/lib/is-in-iframe";
import { ScaledImageSize } from "@backend/common/image-resizing/types";

export default {
    name: "SingleMediaAttachment",
    components: {
        DeletableWrapper,
    },
    props: {
        attachment: AttachmentReference,
        editMode: Boolean,
        multi: Number,
    },
    data() {
        return {
            error: false,
            displayMode: null,
            DisplayMode: DisplayMode,
        };
    },
    computed: {
        loading() {
            return this.attachment instanceof LoadingAttachmentReference;
        },
        isImage() {
            return this.attachment.type === AttachmentType.image;
        },
        url() {
            if (this.loading) return;
            return this.attachment.getUrl(ScaledImageSize.m);
        }
    },
    watch: {
        attachment: {
            immediate: true,
            async handler() {
                if (!this.loading) {
                    this.displayMode = await getDisplayMode(this.url);
                }
            },
        }
    },
    methods: {
        interceptClick(event) {
            if (!inIframe()) {
                event.preventDefault();
            } else {
                event.stopPropagation();
            }
        }
    }
};
</script>

<style lang="scss" scoped>
$max-height: 300px;

.single {
    display: flex;
    .skeleton {
        display: flex;
        flex-direction: column;
        width: 100%;
    }
}

.image {
    width: 100%;
    border-radius: var(--border-radius);

    &:not(.loading) {
        border: 1px solid var(--lowest-contrast);
    }

    @media screen and (prefers-color-scheme: dark) {
        border: 1px solid var(--low-contrast);
    }

    &.image-crop {
        object-fit: cover;
        max-height: 40rem;
    }

    &.fill-height-container {
        display: flex;
        justify-content: center;
        overflow: hidden;
        position: relative;
        transform: translate3d(0, 0, 0);

        .blurred-background-image {
            position: absolute;
            height: 100%;
            width: 100%;
            object-fit: cover;
            transform: scale(1.5);
            filter: blur(50px);
        }

        .image-fill-height {
            height: 40rem;
            z-index: 1;
        }
    }
}

.image-attachment-div {
    //padding-top: 100%;
    //background-position: center;
    //background-size: cover;

    aspect-ratio: 1;
    overflow: hidden;

    img{
        object-fit: cover;
        height: 100%;
        width: 100%;
        object-position: center;
    }
}

.two {
    width: 49%;
    .half {
        width: auto;
        overflow: hidden;
    }
}

.three {
    .deletable {
        width: 100%;
    }
}

.loading-skeleton {
    min-height: $max-height;
}
</style>

<docs>
### Test image without edit mode
```vue
<single-media-attachment
    :attachment="{
        url: 'https://media.giphy.com/media/hrWWmOdGlxYpFErRtD/giphy.gif',
        name: 'test gif 1',
        type: 'IMAGE',
    }"
    :multi="1"
/>
```
### Test image with edit mode
```vue
<single-media-attachment
    edit-mode
    :attachment="{
        url: 'https://media.giphy.com/media/hrWWmOdGlxYpFErRtD/giphy.gif',
        name: 'test gif 1',
        type: 'IMAGE',
    }"
    :multi="1"
/>
```
</docs>
