<template>
    <div class="icon-text-box">
        <Icon
            :name="icon"
            color="var(--secondary)"
        />
        <div class="text">
            <h6>{{ title }}</h6>
            <slot ></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "IconTextBox",
    props: {
        title: {
            type: String,
            default: "Headline"
        },
        icon: {
            type: String,
            default: "briefcase"
        }
    }
};
</script>

<style lang="scss" scoped>
    .icon-text-box {
        display: flex;
        flex-wrap: nowrap;
        margin-bottom: 1rem;

        .text {
            margin-left: 0.5rem;

            h6 {
                line-height: 100%;
                margin-bottom: 0.25rem;
            }
        }

        .icon {
            align-items: start;
        }
    }
</style>

<docs>
```vue
<icon-text-box/>
```
</docs>
