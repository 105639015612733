
import Vue,
{ VNode } from "vue";
import {
    observeForTimestampEvent,
    stopObserveForTimestampEvent,
    TIMESTAMP_MODULE_NAME
} from "@web/store/timestamping/timestamping";
import { TimestampEvent } from "@web/store/timestamping/types";
import { UNREAD_NEWS } from "@web/store/timestamping/getters";
import {
    mapGetters,
    mapMutations
} from "vuex";
import { newsService } from "@web/services";
import { RESET_PUBLISHED_NEWS } from "@web/store/news/mutations";
import { NEWS_MODULE_NAME } from "@web/store/news/news";

/**
 * this is a renderless component which registers observers exposed from vuex and cleans states
 * (un)-registration is implemented using a component as its easy to use lifecycle hooks to ensure observing
 * and un-observing at right moment especially to ensure that listeners are stopped if user changes intranet
 */
export default Vue.extend({
    name: "ContentUpdateObserver",
    computed: {
        ...mapGetters(TIMESTAMP_MODULE_NAME, [UNREAD_NEWS]),
    },
    created() {
        observeForTimestampEvent(this.$store, TimestampEvent.newsPublished);
        observeForTimestampEvent(this.$store, TimestampEvent.newsCommentPublished);
        observeForTimestampEvent(this.$store, TimestampEvent.postPublished);
        observeForTimestampEvent(this.$store, TimestampEvent.postCommentPublished);
    },
    beforeDestroy() {
        stopObserveForTimestampEvent(this.$store, TimestampEvent.newsPublished);
        stopObserveForTimestampEvent(this.$store, TimestampEvent.newsCommentPublished);
        stopObserveForTimestampEvent(this.$store, TimestampEvent.postPublished);
        stopObserveForTimestampEvent(this.$store, TimestampEvent.postCommentPublished);
        this.reset();
    },
    render() {
        return null as unknown as VNode;
    },
    methods: {
        ...mapMutations({
            reset: NEWS_MODULE_NAME + RESET_PUBLISHED_NEWS,
        }),
    },
    watch: {
        [UNREAD_NEWS](active: boolean) {
            if (active) {
                newsService.refreshCacheKey();
            }
        }
    }
});
