import {
    Commentable,
    DeltaPresentation,
    EntityWithAttachments,
    EverestContentEntity,
    Publishable,
    PublishableEntityStatus,
    Reactable,
    Schedulable,
} from "@backend/entity/types";
import { Operation } from "@backend/entity/delta/types";

export interface EverestNews extends EverestNewsInFireStore {
    uid: string;
}

export interface EverestNewsContent {
    content?: DeltaPresentation;
}

export interface EverestNewsLike {
    userHasLiked: boolean;
}


export enum EverestNewsInFireStoreFieldKeys {
    title = "title",
    subtitle = "subtitle",
    creatorUid = "creatorUid",
    coverPictureRef = "coverPictureRef",
    pictureRefs = "pictureRefs",
    status = "status",
    creationDate = "creationDate",
    lastEditedDate = "lastEditedDate",
    publishDate = "publishDate",
    unpublishDate = "unpublishDate",
    formatVersion = "formatVersion",
    tags = "tags",
    likes = "likes",
    lastLikes = "lastLikes",
    unsplashAuthors = "unsplashAuthors",
    overallCommentCount = "overallCommentCount",
    commentCount = "commentCount",
    reactions = "reactions",
    readingTime = "readingTime",
    attachmentCounts = "attachmentCounts",
    externalContentCount = "externalContentCount",
}

export interface EverestNewsInFireStore
    extends EverestContentEntity, Publishable, Schedulable, Commentable, Reactable, EntityWithAttachments {
    [EverestNewsInFireStoreFieldKeys.title]: string;
    [EverestNewsInFireStoreFieldKeys.subtitle]: string;
    [EverestNewsInFireStoreFieldKeys.pictureRefs]: PictureRef[];
    [EverestNewsInFireStoreFieldKeys.coverPictureRef]: PictureRef;
    [EverestNewsInFireStoreFieldKeys.likes]?: number;
    [EverestNewsInFireStoreFieldKeys.lastLikes]?: string[]; // list of userUid
    [EverestNewsInFireStoreFieldKeys.unsplashAuthors]: { [key: string]: UnsplashPhotoAuthor | null }; // map of photoId to author
    [EverestNewsInFireStoreFieldKeys.overallCommentCount]: number;
    /**
     *  we'll have to solve categories / tags using this pattern: https://stackoverflow.com/a/54987884/12123484
     *  and a composite index
     */
    [EverestNewsInFireStoreFieldKeys.tags]: { [key: string]: boolean };
    [EverestNewsInFireStoreFieldKeys.readingTime]: number;
}

export type PictureRef = string;

export interface UnsplashPhotoAuthor {
    username: string;
    displayName: string;
    id: string;
    url: string;
    imageDescription: string;
}

export interface EverestNewsUpdate {
    title?: string;
    subtitle?: string;
    status?: PublishableEntityStatus;
    publishDate?: Timestamp;
    unpublishDate?: Timestamp;
    formatVersion?: number; // to eventually handle upcoming changes
    tags?: { [key: string]: boolean };
    pictureRefs?: PictureRef[];
    coverPictureRef?: PictureRef;
}

export type PublishingDate = Date; // UTC!
/* delta operations as JSON string */
export type ZephyrDeltaOperations = Operation;
/* delta operations as JSON string */
export type QuillDeltaOperations = Operation;

interface Timestamp {
    _seconds: number;
    _nanoseconds: number;
}
