<template>
    <div
        class="hoverlay fill"
        @mouseover="hovering = true"
        @mouseleave="hovering = false"
    >
        <transition name="fade">
            <div
                v-if="showOverlay"
                class="fill-absolutely overlay"
            >
            </div>
        </transition>
        <transition name="fade">
            <div
                v-if="showOverlay"
                class="fill-absolutely"
            >
                <slot name="on-hover"></slot>
            </div>
        </transition>
        <slot></slot>
    </div>
</template>

<script>
export default {
    name: "Hoverlay",
    props: {
        /**
         * Overwrites the overlay being shown or not depending on the hover state. If this is true the overlay always is
         * shown, if it is false it will never be shown. When set to undefined the component will return to its default
         * behaviour.
         */
        forceOverlayState: {
            type: Boolean,
            default: undefined,
        },
    },
    data: () => ({
        hovering: false,
    }),
    computed: {
        showOverlay() {
            if (this.forceOverlayState !== undefined) {
                return this.forceOverlayState;
            }
            return this.hovering;
        },
    },
};
</script>

<style lang="scss" scoped>
.hoverlay {
    position: relative;

    .fill-absolutely {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        &.overlay {
            background: black;
            opacity: .5;
        }
    }
}
</style>

<docs>
### Hoverlay™

This component renders a overlay over its children on hover:

> Hover + Overlay -> 🔥🔥🔥 HOVERLAY™ 🔥🔥🔥

All credits for this naming masterpiece go to Jonas Depoix. Using this name without his permission will most likely get
you sued.

```vue
<hoverlay>
    <template v-slot:on-hover>
        <p>🔥🔥🔥 HOVERLAY™ 🔥🔥🔥</p>
    </template>
    <p>Show me the HOVERLAY™!</p>
</hoverlay>
```
</docs>
