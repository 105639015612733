<template>
    <div
        class="centered"
        :class="type"
    >
        <div
            v-if="selectedType.asset"
            class="image-container"
            v-html="imageUrl(asset || selectedType.asset)"
        ></div>
        <h4>{{ title || selectedType.title }}</h4>
        <div class="message">
            <slot>
                <p>
                    {{ text || selectedType.text }}
                </p>
            </slot>
        </div>
        <div
            v-if="hasAction"
            class="action"
        >
            <Button
                type="primary"
                @click="$emit('click')"
            >
                {{ buttonText || selectedType.buttonText }}
            </Button>
        </div>
    </div>
</template>

<script>
import Button from "@web/components/Button";

export default {
    components: {
        Button,
    },
    props: {
        /** One of the predefined types. Can be overridden by `title`, `text` and `asset`. */
        type: { type: String, default: "unknown" },
        /** Bold title */
        title: { type: String },
        /** Text beneath the title */
        text: { type: String },
        /** Dynamic switch to show the call to action button.
         * The button is always displayed when a `@click` handler ist defined. */
        showCallToAction: { type: Boolean, default: true },
        /** Text of the call to action button */
        buttonText: { type: String },
        /** SVG name inside `/assets/system/` */
        asset: { type: String }
    },
    data() {
        return {
            types: {
                unknown: {
                    title: this.$i18n.t("unknown_error"),
                    text: this.$i18n.t("unknown_error_text"),
                    asset: "shrugging",
                },
                drafts: {
                    title: this.$i18n.t("no_articles"),
                    text: this.$i18n.t("no_articles_text"),
                    asset: "drafts-arrow",
                },
                invites: {
                    title: this.$i18n.t("no_invites"),
                    text: this.$i18n.t("no_invites_text"),
                    asset: "drafts-arrow",
                },
                topRightArrow: {
                    title: this.$i18n.t("no_invites"),
                    text: this.$i18n.t("no_invites_text"),
                    asset: "arrow-top-right",
                },
                people: {
                    title: this.$i18n.t("no_people"),
                    text: this.$i18n.t("no_people_text"),
                    asset: "connecting",
                },
                news: {
                    title: this.$i18n.t("no_news"),
                    text: this.$i18n.t("no_news_text"),
                    buttonText: this.$i18n.t("no_news_button_text"),
                    asset: "typewriter",
                },
                events: {
                    title: this.$i18n.t("events_no_events_title"),
                    text: this.$i18n.t("events_no_events_text"),
                    buttonText: this.$i18n.t("create"),
                    asset: "arriving",
                },
                theming: {
                    title: this.$i18n.t("create_theme"),
                    text: this.$i18n.t("create_theme_text"),
                    buttonText: this.$i18n.t("create_theme_button_text"),
                    asset: "artist",
                },
                profile: {
                    title: this.$i18n.t("fill_out_profile"),
                    text: this.$i18n.t("fill_out_profile_text"),
                    buttonText: this.$i18n.t("fill_out_profile_button_text"),
                    asset: "clipboard",
                },
                maintenance: {
                    title: this.$i18n.t("maintenance"),
                    text: this.$i18n.t("maintenance_text"),
                    buttonText: this.$i18n.t("maintenance_button_text"),
                    asset: "wrench",
                },
                liftoff: {
                    title: this.$i18n.t("lift_off"),
                    text: this.$i18n.t("lift_off_text"),
                    buttonText: this.$i18n.t("lift_off_button_text"),
                    asset: "flying",
                },
                yay: {
                    title: this.$i18n.t("yay"),
                    text: this.$i18n.t("yay_text"),
                    asset: "fist",
                },
                results: {
                    title: this.$i18n.t("no_results"),
                    text: this.$i18n.t("no_results_text"),
                    buttonText: this.$i18n.t("no_results_text"),
                    asset: "searching",
                },
                success: {
                    title: this.$i18n.t("success"),
                    text: this.$i18n.t("success_text"),
                    asset: "thumbs-up",
                },
                timeout: {
                    title: this.$i18n.t("timeout"),
                    text: this.$i18n.t("timeout_text"),
                    buttonText: this.$i18n.t("refresh"),
                    asset: "watering",
                },
                403: {
                    title: this.$i18n.t("403"),
                    text: this.$i18n.t("403_text"),
                    buttonText: this.$i18n.t("return_to_dashboard"),
                    asset: "locked",
                },
                404: {
                    title: this.$i18n.t("404"),
                    text: this.$i18n.t("404_text"),
                    buttonText: this.$i18n.t("return_to_dashboard"),
                    asset: "404",
                },
                fetchData: {
                    title: this.$i18n.t("error"),
                    text: this.$i18n.t("error_fetching_data"),
                    asset: "crossed",
                },
            }
        };
    },
    computed: {
        selectedType() {
            return this.types[this.type];
        },
        hasAction() {
            return this.$listeners.click && this.showCallToAction && (this.buttonText || this.selectedType.buttonText);
        },
    },
    methods: {
        imageUrl(imageName) {
            return require(`@web/assets/system/${imageName}.svg`);
        }
    },
};
</script>

<style lang="scss" scoped>
/* * {
    outline: 1px solid red;
} */

.image-container {
    padding: 2rem 2rem 0;
    margin: 0 auto 1rem;
    position: relative;

    ::v-deep {
        img, svg {
            max-height: 350px;
            max-width: 400px;
        }
    }
}

h4 {
    color: var(--highest-contrast, #{$dark-grey});
    margin-bottom: 0.5rem;
}

.message {
    color: var(--medium-contrast, #{$mid-grey});
}

.drafts {
    .image-container {
        padding: 0 1rem 1rem;
        margin: 0 auto 1rem;
        position: relative;

        img, svg {
            flex: 0 0 auto;
            width: 100%;
            height: auto;

        }
    }

}
</style>

<docs>
### Minimal
```vue
<system-message
    type="yay"
    title="Yay"
    text="You did it!"
/>
```

### Call to action button
```vue
<system-message
    type="results"
    button-text="Create something!"
    @click=""
/>
```

### All types with default texts
```vue noeditor
<template>
    <div>
        <div
            v-for="type in types"
            class="card depth-3 padding-small mbottom-small"
        >
            <h4><code>{{type}}</code></h4>
            <system-message
                :type="type"
            />
        </div>
    </div>
</template>
<script>
    import SystemMessage from "./SystemMessage";

    export default {
        data() {
            return {
                types: Object.keys(SystemMessage.data.call(this).types),
            };
        },
    };
</script>
```
</docs>
