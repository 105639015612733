<template>
    <div
        v-if="!maintenanceModeWebEnabled"
        id="app"
        v-global-page-title="$router.currentRoute"
    >
        <transition
            name="fade"
            mode="out-in"
        >
            <splash-screen v-if="!authStateResolved"/>
            <router-view v-if="authStateResolved"/>
        </transition>
        <modals-container/>
        <ApplicationInformation/>
    </div>
    <MaintenanceView v-else/>
</template>

<script>
import {
    mapActions,
    mapGetters,
    mapMutations,
    mapState
} from "vuex";
import { AUTH_MODULE_NAME } from "./store/auth/auth";
import { RESET_INTRANET_MODULE } from "@web/store/intranet/mutations";
import { MAINTENANCE_MODULE_NAME } from "@web/store/maintenance/maintenance";
import MaintenanceView from "@web/views/MaintenanceView.vue";
import { OBSERVE_WEB_MAINTENANCE_MODE } from "@web/store/maintenance/actions";
import ApplicationInformation from "@web/ApplicationInformation.vue";
import SplashScreen from "@web/components/SplashScreen";

export default {
    name: "App",
    components: {
        MaintenanceView,
        ApplicationInformation,
        SplashScreen,
    },
    methods: {
        ...mapActions({
            observeWebMaintenanceMode: MAINTENANCE_MODULE_NAME + OBSERVE_WEB_MAINTENANCE_MODE,
            fetchGlobalConfig: MAINTENANCE_MODULE_NAME + "fetchGlobalConfig"
        }),
        ...mapMutations({ resetIntranets: "intranet/" + RESET_INTRANET_MODULE }),
        setColorThemeClass() {
            if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
                document.body.classList.add("dark-mode");
            }
        }
    },
    computed: {
        ...mapGetters({
            maintenanceModeWebEnabled: "maintenance/" + "maintenanceModeWebEnabled"
        }),
        ...mapState(AUTH_MODULE_NAME, ["authStateResolved"])
    },
    created() {
        this.observeWebMaintenanceMode();
        // Enable for System driven Dark Mode
        // this.setColorThemeClass();
    },
};
</script>

<style lang="scss" scoped>
</style>
