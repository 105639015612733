<template>
    <div class="form-group">
        <p class="mbottom-medium">
            {{ $t("social_notification_send_hey_five_description") }}
        </p>
        <textarea
            v-model="message"
            :maxlength="messageMaxLength"
            :placeholder="$t('social_notification_send_hey_five_message_placeholder')"
            autofocus
        ></textarea>
        <div class="meta">
            {{ message.length }} / {{ messageMaxLength }}
        </div>
    </div>
</template>

<script>
import { SocialNotificationType } from "@backend/common/notification/activity-types";
import { activityDispatcherService } from "@/services";
import { mapGetters } from "vuex";
import { INTRANET_MODULE_NAME } from "@/store/intranet/intranet";
import { INTRANET_UID } from "@/store/intranet/getters";
import { getGlobalConfiguration } from "@/global-config";

const MESSAGE_MAX_LENGTH = getGlobalConfiguration().activity_message_max_length;

export default {
    name: "SendHeyFiveForm",
    props: {
        /**
         * user the social notification should be sent to
         *
         * @type {BaseUserProfile}
         */
        recipient: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            message: "",
            messageMaxLength: MESSAGE_MAX_LENGTH,
        };
    },
    computed: {
        ...mapGetters({
            intranetUid: INTRANET_MODULE_NAME + INTRANET_UID,
        }),
    },
    methods: {
        async confirm() {
            try {
                await activityDispatcherService.dispatchSocialNotification({
                    intranetUid: this.intranetUid,
                    recipientUid: this.recipient.uid,
                    socialNotificationType: SocialNotificationType.heyFive,
                    message: this.message,
                });
                this.$notify({
                    group: "app",
                    type: "success",
                    text: this.$t("social_notification_send_hey_success_message")
                });
            } catch (e) {
                this.$notify({
                    group: "app",
                    type: "error",
                    text: this.$t("error")
                });
            }
        },
    }
};
</script>

<style lang="scss" scoped>
.meta {
    text-align: right;
    margin-top: 0.1rem;
}
</style>
