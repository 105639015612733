<template>
    <modal
        ref="modal"
        name="modal"
        v-bind="modalProps"
        :hide-footer="hideFooter"
        :unavoidable="unavoidable"
        @before-open="init"
        @before-close="cancel"
        @modal-confirm="confirm"
        @keydown.native.meta.enter="triggerConfirm"
        @keydown.native.ctrl.enter="triggerConfirm"
    >
        <component
            :is="component"
            v-if="component"
            ref="component"
            v-bind="props"
            v-on="events"
        />
    </modal>
</template>

<script>
import Modal from "@web/components/modals/Modal";

export default {
    name: "GenericModal",
    components: {
        Modal,
    },
    data() {
        return {
            component: undefined,
            events: undefined,
            props: undefined,
            modalProps: undefined,
            callback: undefined,
        };
    },
    computed: {
        hideFooter() {
            if (this.modalProps && this.modalProps.hideFooter !== undefined) {
                return this.modalProps.hideFooter;
            }
            return this.callback === undefined;
        },
        unavoidable() {
            if (this.modalProps && this.modalProps.unavoidable !== undefined) {
                return this.modalProps.unavoidable;
            }
            return this.callback === undefined;
        },
    },
    methods: {
        cancel() {
            if (this.$refs.component.cancel) {
                this.$refs.component.cancel();
            }
            if (this.callback) {
                this.callback(false);
            }
            this.reset();
        },
        confirm() {
            if (this.$refs.component.confirm) {
                this.$refs.component.confirm();
            }
            if (this.callback) {
                this.callback(true);
            }
            this.reset();
        },
        init(event) {
            this.component = event.params.component;
            this.events = event.params.events;
            this.props = event.params.props;
            this.modalProps = event.params.modalProps;
            this.callback = event.params.callback;
        },
        reset() {
            this.component = undefined;
            this.events = undefined;
            this.props = undefined;
            this.modalProps = undefined;
            this.callback = undefined;
        },
        triggerConfirm() {
            this.$refs.modal.confirmModal();
        },
    },
};
</script>

<style scoped>
[data-modal="modal"] {
    z-index: $z-max;
}
</style>
