import { Module } from "vuex";
import { VuexRootState } from "@web/store";
import { firestore } from "@web/firebase-instance";
import {
    GLOBALS_COLLECTION_IN_FIRESTORE,
    MAINTENANCE_MODE_WEB_DOCUMENT_IN_FIRESTORE,
} from "@web/store/maintenance/types";
import {
    MAINTENANCE_MODE_WEB_ENABLED,
    MAINTENANCE_MODE_WEB_MESSAGE,
} from "@web/store/maintenance/getters";
import { OBSERVE_WEB_MAINTENANCE_MODE } from "@web/store/maintenance/actions";
import {
    SET_MAINTENANCE_MODE_WEB,
    SET_UNSUBSCRIBE,
} from "@web/store/maintenance/mutations";

export const MAINTENANCE_MODULE_NAME = "maintenance/";

export interface MaintenanceModuleState {
    unsubscribe?: () => void;
    maintenanceModeWeb: {
        enabled: boolean;
        message: string
    };
}

export const MAINTENANCE_MODULE: Module<MaintenanceModuleState, VuexRootState> = {
    namespaced: true,
    state: {
        unsubscribe: undefined,
        maintenanceModeWeb: {
            enabled: false,
            message: "",
        },
    },
    getters: {
        [MAINTENANCE_MODE_WEB_ENABLED]: (state): boolean => state.maintenanceModeWeb.enabled,
        [MAINTENANCE_MODE_WEB_MESSAGE]: (state): string => state.maintenanceModeWeb.message,
    },
    mutations: {
        [SET_MAINTENANCE_MODE_WEB](state, payload): void {
            state.maintenanceModeWeb = payload;
        },
        [SET_UNSUBSCRIBE](state, unsubscribe): void {
            state.unsubscribe = unsubscribe;
        },
    },
    actions: {
        async [OBSERVE_WEB_MAINTENANCE_MODE]({ commit }): Promise<void> {
            const unsubscribe = await firestore.collection(GLOBALS_COLLECTION_IN_FIRESTORE).doc(MAINTENANCE_MODE_WEB_DOCUMENT_IN_FIRESTORE).onSnapshot(async(docSnapshot) => {
                if (!docSnapshot.exists) {
                    return;
                }
                const maintenanceModeWeb = docSnapshot.data();
                if (maintenanceModeWeb === undefined) {
                    return;
                }
                commit(SET_MAINTENANCE_MODE_WEB, maintenanceModeWeb);
            });
            commit(SET_UNSUBSCRIBE, unsubscribe);
        },
    },
};
