<template>
    <!--  click.stop to stop the click event on images below modal  -->
    <div
        v-if="show"
        class="lightbox-swiper-modal"
        @click.stop
    >
        <transition name="fade">
            <div class="lightbox-swiper">
                <swiper
                    ref="swiperTop"
                    class="swiper gallery-top"
                    :options="swiperOptionTop"
                >
                    <swiper-slide
                        v-for="(imageUrl, index) in imageUrls"
                        :key="imageUrl"
                    >
                        <div class="header">
                            <a
                                class="filename"
                                :href="downloadLink(imageUrl, imageNames[index])"
                                download
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                {{ imageNames[index] }}
                            </a>
                            <div>
                                <a
                                    :href="downloadLink(imageUrl, imageNames[index])"
                                    download
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <Icon
                                        class="icon"
                                        name="download"
                                        color="white"
                                    />
                                </a>
                                <Icon
                                    class="icon"
                                    name="x"
                                    color="white"
                                    @click.native.stop="close"
                                />
                            </div>
                        </div>
                        <div
                            class="swiper-zoom-container"
                            @keyup.esc="close"
                            @click="close"
                        >
                            <img
                                :src="imageUrl"
                                alt="attachment"
                                @click.stop.prevent
                            />
                        </div>
                    </swiper-slide>
                    <div
                        slot="button-next"
                        class="swiper-button-next"
                        :class="{'hide': imageUrls.length === 1}"
                    ></div>
                    <div
                        slot="button-prev"
                        class="swiper-button-prev"
                        :class="{'hide': imageUrls.length === 1}"
                    ></div>
                </swiper>
                <swiper
                    ref="swiperThumbs"
                    class="swiper gallery-thumbs"
                    :options="swiperOptionThumbs"
                    @click.native="closeIfNotSlide"
                >
                    <swiper-slide
                        v-for="imageUrl in imageUrls"
                        :key="imageUrl"
                        class="cursor-pointer"
                        :style="{'background-image': `url(${imageUrl})`}"
                    >
                    </swiper-slide>
                </swiper>
            </div>
        </transition>
    </div>
</template>

<script>
import { v4 as uuidV4 } from "uuid";
import {
    SwiperSlide,
    Swiper,
} from "vue-awesome-swiper";

import "swiper/css/swiper.css";

export default {
    name: "LightboxSwiper",
    components: {
        Swiper,
        SwiperSlide,
    },
    props: {
        initialIndex: { type: Number, default: 0 },
        imageUrls: { type: Array, required: true },
        imageNames: { type: Array, default: () => [] },
        show: { type: Boolean, default: true },
    },
    data() {
        return {
            swiperOptionTop: {
                loopedSlides: this.imageUrls.length,
                spaceBetween: 10,
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
                keyboard: {
                    enabled: true,
                },
                grabCursor: true,
                zoom: true,
                longSwipesMs: 500,
                threshold: 10,
            },
            swiperOptionThumbs: {
                loopedSlides: this.imageUrls.length,
                spaceBetween: 10,
                centeredSlides: true,
                slidesPerView: "auto",
                touchRatio: 0.2,
                slideToClickedSlide: true,
                threshold: 10,
            },
        };
    },
    watch: {
        show(newShow) {
            if (newShow) {
                this.init();
            } else {
                this.close();
            }
        },
    },
    created() {
        if (this.show) {
            this.init();
        }

        this.imageUrls.forEach((_, index) => {
            if (!this.imageNames[index]) {
                this.imageNames[index] = `${uuidV4()}.jpeg`;
            }
        });
    },
    destroyed() {
        // safety net removal of event listener, if the component is unexpectedly destroyed
        document.removeEventListener("keyup", this.closeOnEsc);
    },
    methods: {
        downloadLink(url, name) {
            return `${url}&response-content-disposition=attachment; ` +
                `filename=${name.replace(",", " ")}`;
        },
        closeIfBackgroundClick(event) {
            if (event.target.classList.contains("lightbox-swiper-modal")) {
                this.close();
            }
        },
        closeIfNotSlide(event) {
            const slideClicked = event.target.classList.contains("swiper-slide");
            if (!slideClicked) {
                this.close();
            }
        },
        closeOnEsc(event) {
            if (event.keyCode && event.keyCode === 27) {
                this.close();
            }
        },
        async init() {
            await this.$nextTick(() => {
                const swiperTop = this.$refs.swiperTop.$swiper;
                const swiperThumbs = this.$refs.swiperThumbs.$swiper;
                swiperTop.controller.control = swiperThumbs;
                swiperThumbs.controller.control = swiperTop;
            });

            if (this.initialIndex > -1) {
                this.$refs.swiperTop.$swiper.slideTo(this.initialIndex, 0);
            }

            document.addEventListener("keyup", this.closeOnEsc);
        },
        close() {
            document.removeEventListener("keyup", this.closeOnEsc);
            this.$emit("close-swiper-lightbox");
        },
    },
};
</script>

<style lang="scss" scoped>

.lightbox-swiper-modal {
    position: fixed;
    z-index: 999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.lightbox-swiper {
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
}

.swiper {
    .swiper-button-next, .swiper-button-prev {
        color: var(--primary);
    }
    &.gallery-top {
        height: 90%;
        width: 100%;
        .swiper-slide {
            display: flex;
            align-items: center;
            justify-content: center;
            .header {
                position: absolute;
                top: 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 3rem;
                width: 100%;
                color: white;
                background-color: black;
                cursor: default;

                .filename {
                    margin-left: 1rem;
                    color: white;
                }

                .icon {
                    // necessary to prevent zoom effect of swiper affecting icon svg
                    ::v-deep * {
                        transform: none !important;
                    }
                    height: 3rem;
                    width: 3rem;
                    cursor: pointer;
                    &:hover {
                        background-color: #5e5e5e;
                    }
                }
            }
        }
    }
    &.gallery-thumbs {
        height: 10%;
        box-sizing: border-box;
        padding: 0.5rem 0;
        cursor: default;
    }
    &.gallery-thumbs .swiper-slide {
        background-position: center;
        background-size: cover;
        width: 15%;
        height: 100%;
        border: 1px solid var(--background);
    }
    &.gallery-thumbs .swiper-slide-active {
        border: 1px solid var(--primary);
    }
}

::v-deep {
    .v--modal {
        .controls {
            display: none;
        }
        .modal-body {
            height: 100%;
        }
    }
}
</style>

<style lang="scss">
// v-deep unfortunately does not work
.hide {
    display: none !important;
}
</style>
