<template>
    <featured-content
        v-if="team"
        :to="route"
        feature-image-url="/team-bg.jpg"
    >
        <template #featuring>
            <div class="center-container">
                <avatar
                    icon="users"
                    size="large"
                />
            </div>
        </template>
        <template #title>
            {{ team.name }}
        </template>
        <template #content>
            {{ team.description }}
        </template>
        <template #meta>
            {{ $tc("members_tc", memberCount, memberCount) }}
        </template>
    </featured-content>
    <featured-content-skeleton v-else-if="loading"/>
    <missing-featured-content v-else/>
</template>

<script>
import FeaturedContent from "@web/components/attachments/shared-content/FeaturedContent";
import FeaturedContentSkeleton from "@web/components/attachments/shared-content/FeaturedContentSkeleton";
import MissingFeaturedContent from "@web/components/attachments/shared-content/MissingFeaturedContent";
import Avatar from "@web/components/Avatar";
import {
    teamService,
    userService,
} from "@web/services";
import { mapGetters } from "vuex";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import { INTRANET_UID } from "@web/store/intranet/getters";
import { DeltaFormat } from "@backend/entity/types";

export default {
    name: "SharedTeam",
    components: {
        Avatar,
        MissingFeaturedContent,
        FeaturedContentSkeleton,
        FeaturedContent,
    },
    inheritAttrs: false,
    props: {
        uid: String,
    },
    data() {
        return {
            loading: false,
            missing: false,
            /** @type {TeamProfile} */
            team: undefined,
            memberCount: undefined,
        };
    },
    computed: {
        ...mapGetters({
            intranetUid: INTRANET_MODULE_NAME + INTRANET_UID,
        }),
        route() {
            return { name: "team-profile", params: { teamUid: this.uid } };
        },
    },
    mounted() {
        this.fetchTeam();
    },
    methods: {
        async fetchTeam() {
            if (!this.uid) {
                return;
            }
            this.loading = true;
            try {
                this.team = await teamService.getTeam(this.intranetUid, this.uid, DeltaFormat.plain);
                const searchResult = await userService.search.searchTeamMembers({ intranetUid: this.intranetUid, teamUid: this.uid, hitsPerPage: 0 });
                this.memberCount = searchResult.nbHits;
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>

</style>
