import { newsService } from "@web/services";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import store from "@web/store";
import { INTRANET } from "@web/store/intranet/getters";
import Vue from "vue";
import Spinner from "@web/components/Spinner.vue";
import { WEB_URL_STORAGE_REF_PREFIX } from "@web/store/news/NewsService";
import { preventHandlerInEditMode } from "@web/lib/quill/is-in-edit-mode";
import LightboxSwiper from "@web/components/swiper/LightboxSwiper.vue";
import { wait } from "@web/lib/wait";

// @ts-ignore
const Quill: any = window.Quill;
const QuillImage = Quill.import("blots/block/embed");

export class ImageBlot extends QuillImage {
    public static create(data: { storageref?: string, unsplashUrl?: string, unsplashAuthor?: { name: string, username: string, description: string }, asyncUpload?: Promise<{ downloadUrl: string, storageref: string }> } | string) {
        const node: HTMLElement = super.create();

        if (!data) {
            return node;
        }

        let imgLoadedFromCache = false;

        const placeholder = document.createElement("div");

        placeholder.classList.add("placeholder");

        const spinner = document.createElement("div");

        const img = document.createElement("img");
        img.classList.add("everest-image");
        // @ts-ignore
        img.loading = "lazy";

        img.addEventListener("load", e => {
            node.style.height = img.offsetHeight + "px";
            setTimeout(() => {
                node.classList.add("loaded");
                node.style.height = "auto";
            }, imgLoadedFromCache ? 0 : 300);
        });
        img.addEventListener("error", e => {
            node.classList.add("loaded");
            node.classList.add("error");
            node.style.height = "auto";
        });

        placeholder.appendChild(spinner);

        node.appendChild(placeholder);
        node.appendChild(img);

        new Vue({
            render: h => h(Spinner)
        }).$mount(spinner);

        if (typeof data === "string") {
            // blot is created from copying a web img
            img.setAttribute("src", data);
            node.setAttribute("data-storageref", WEB_URL_STORAGE_REF_PREFIX + data);
        } else if (data.unsplashUrl) {
            // blot is created from inserting an unsplashPhoto (from dialog)
            img.setAttribute("src", data.unsplashUrl);
            node.setAttribute("data-storageref", WEB_URL_STORAGE_REF_PREFIX + data.unsplashUrl);
            node.setAttribute("contentEditable", "false");
        } else if (data.storageref) {
            // blot is created from existing image
            try {
                newsService.getDownloadUrlForImage(store.getters[INTRANET_MODULE_NAME + INTRANET].uid, store.state.news.current.news.uid, data.storageref).then(downloadUrl => {
                    img.setAttribute("src", downloadUrl as string);
                    node.setAttribute("data-storageref", data!.storageref || "");
                    imgLoadedFromCache = img.complete;
                });
            } catch (e) {
                ImageBlot.handleError(img, node);
            }
        } else if (data.asyncUpload) {
            // blot is created from upload
            try {
                data.asyncUpload.then((data) => {
                    if (!data) {
                        return;
                    }
                    img.setAttribute("src", data.downloadUrl as string);
                    node.setAttribute("data-storageref", data.storageref);
                });
            } catch (e) {
                ImageBlot.handleError(img, node);
            }
        } else {
            ImageBlot.handleError(img, node);
        }

        // insert unsplash author information (image was already added before, so we're using the verified author information from backend)
        if (typeof data !== "string" && typeof data.storageref === "string" && data.storageref.startsWith("weburl://https://source.unsplash.com")) {
            const photoId = data.storageref.split("weburl://https://source.unsplash.com")[1].split("/")[1];
            if (photoId) {
                const author = (store.state.news.current.news.unsplashAuthors || {})[photoId];
                if (author) {
                    this.appendUnplashAuthorInformation(node, author.displayName, author.username);
                }
            }
        }
        // insert unsplash author information (image was just added via dialog, author information will be handles in client)
        if (typeof data !== "string" && data.unsplashUrl && data.unsplashAuthor) {
            this.appendUnplashAuthorInformation(node, data.unsplashAuthor.name, data.unsplashAuthor.username);
        }

        node.addEventListener("click", preventHandlerInEditMode(() => {
            const nodeImageUrl = node.querySelector(".everest-image")?.getAttribute("src");
            const imageSources = [...document.querySelectorAll(".everest-image")].map((image) => image.getAttribute("src"));
            const nodeIndex = imageSources.indexOf(nodeImageUrl || "");

            const lightboxDiv = document.createElement("div");
            lightboxDiv.classList.add("lightbox");

            node.appendChild(lightboxDiv);

            const lightboxSwiperInstance = new Vue({
                ...LightboxSwiper,
                propsData: {
                    initialIndex: nodeIndex > -1 ? nodeIndex : 0,
                    imageUrls: imageSources,
                },
            });
            lightboxSwiperInstance.$mount(lightboxDiv);
            lightboxSwiperInstance.$on("close-swiper-lightbox", async() => {
                // @ts-ignore
                lightboxSwiperInstance.show = false;
                await wait(100); // not sure why wait is necessary, but immediately calling destroy breaks the swiper
                lightboxSwiperInstance.$destroy();
                lightboxDiv.remove();
            });
        }));

        return node;
    }

    public static appendUnplashAuthorInformation(target: Node, name: string, username: string) {
        const el = document.createElement("span");
        el.classList.add("unsplash-information");
        const anchor = document.createElement("a");
        anchor.setAttribute("href", `https://unsplash.com/@${username}?utm_source=linchpin_cloud&utm_medium=referral`);
        anchor.setAttribute("target", "_blank");
        anchor.text = "Photo by " + name + " from Unsplash.com";
        el.appendChild(anchor);

        target.appendChild(el);
    }

    public static value(domNode: HTMLElement) {
        const storageref = domNode.getAttribute("data-storageref");
        return { storageref };
    }

    private static handleError(img: Element, node: Element) {
        console.warn("Error loading image.");
        img.setAttribute("src", "error");
    }

    public optimize(ctx: any) {
        // if blot below this image is another image, we're adding an empty line between them as these gluing images
        // are hard to edit using the ui and mobile client does not support this
        const sticksToNextImageBlot = this.next instanceof ImageBlot;
        if (sticksToNextImageBlot) {
            this.quillInstance.insertText(this.offset(this.scroll.domNode, 1) + 1, "");
        }
        super.optimize(ctx);
    }

    get quillInstance() {
        // @ts-ignore
        return document.getElementsByClassName("quill-editor")[0].__vue__.quill;
    }
}

ImageBlot.blotName = "image";
ImageBlot.tagName = "div";
ImageBlot.className = "everest-image-wrapper";
