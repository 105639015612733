<template>
    <div id="create-content-fab-root">
        <transition name="fade">
            <div
                v-if="isDialogOpen"
                id="overlay"
            ></div>
        </transition>
        <v-popover
            id="create-content-fab"
            :open.sync="isDialogOpen"
            popover-arrow-class="tooltip-arrow popover-arrow arrow-left create-content-arrow"
            offset="8"
            placement="right-start"
            :popover-class="'no-padding'"
            :class="{compact: isCompact}"
        >
            <EverestButton
                v-fade-in.after="600"
                class="fab"
                :class="{'sidebar-bg': isDialogOpen, 'depth-2': isDialogOpen, compact: isCompact,}"
                @click="toggleCreateDialog()"
            >
                <div class="fab-content">
                    <Icon
                        class="add-icon"
                        :class="{tilted: isDialogOpen}"
                        name="plus"
                        :color="isDialogOpen ? 'var(--sidebar-active)' : 'var(--primary-text)'"
                        size="large"
                    />
                    <transition
                        name="fade"
                        :duration="2000"
                    >
                        <span
                            v-if="!isCompact"
                            class="fab-content-label"
                            :class="{'sidebar-active': isDialogOpen}"
                        >
                            {{ $t("create_content_label") }}
                        </span>
                    </transition>
                </div>
            </EverestButton>

            <template slot="popover">
                <div class="create-items">
                    <a
                        v-for="(contentCategory, index) in contentCategories"
                        :key="contentCategory.routeName"
                        href="#"
                        class="item"
                        @click.prevent="onClick(contentCategory)"
                        @mouseover="setArrowHoverColor(index)"
                        @mouseleave="unsetArrowHoverColor(index)"
                    >
                        <div class="flex-container icon-container">
                            <Icon
                                class="icon"
                                color="var(--primary-text)"
                                :name="contentCategory.icon"
                            />
                        </div>
                        <div class="flex-container">
                            <span class="caption">{{ $t(contentCategory.caption) }}</span>
                            <span class="description">{{ $t(contentCategory.description) }}</span>
                        </div>
                    </a>
                </div>
            </template>
        </v-popover>
    </div>
</template>

<script>
import Vue from "vue";
import Icon from "@web/components/Icon";
import EverestButton from "@web/components/Button";
import { analytics } from "@web/analytics";
import { getGlobalConfiguration } from "@web/global-config";

const CONTENT_CATEGORIES = [
    {
        routeName: "posts",
        intent: "create-post",
        icon: "message-square",
        caption: "create_post_caption",
        description: "create_post_description",
    },
    {
        routeName: "news",
        intent: "create-news",
        icon: "newspaper",
        caption: "create_news_caption",
        description: "create_news_description",
    },
    {
        routeName: "event",
        intent: "create-event",
        icon: "event",
        caption: "create_event_caption",
        description: "create_event_description",
    },
];

export default Vue.extend({
    name: "CreateContentFAB",
    components: {
        EverestButton,
        Icon,
    },
    props: {
        isCompact: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            isDialogOpen: false,
            contentCategories: CONTENT_CATEGORIES,
        };
    },
    methods: {
        onClick(contentCategory) {
            this.sendAnalyticsEvent(contentCategory);
            this.$router.push({
                name: contentCategory.routeName,
                query: { intent: contentCategory.intent },
            });
            this.toggleCreateDialog();
        },
        sendAnalyticsEvent(contentCategory) {
            analytics.log(getGlobalConfiguration().analytics_event_name_create_fab_clicked, {
                intent: contentCategory.intent,
            });
        },
        toggleCreateDialog() {
            this.isDialogOpen = !this.isDialogOpen;
        },
        setArrowHoverColor(listIndex) {
            // unfortunately this is the only way to color the arrow of the popover on hover
            if (listIndex === 0) {
                document.querySelector(".create-content-arrow").style.background = "var(--lowest-contrast)";
            }
        },
        unsetArrowHoverColor(listIndex) {
            // unfortunately this is the only way to color the arrow of the popover on hover
            if (listIndex === 0) {
                document.querySelector(".create-content-arrow").style.background = "var(--background)";
            }
        },
    },
});
</script>

<style lang="scss" scoped>
@import "src/style/variables";

$fab-size: 2.5rem;

#overlay {
    position: fixed;
    background: var(--background, #{$white});
    opacity: .7;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    &.fade-enter-active, &.fade-leave-active {
        transition: opacity .2s ease-out;
    }

    &.fade-enter, &.fade-leave-to {
        opacity: 0;
    }
}

#create-content-fab-root {
    padding: .85rem 0;
}

#create-content-fab {
    display: flex;
    justify-content: center;
    transition: background .2s ease-out;
}

.add-icon {
    transition: transform .2s ease-out;
    width: $fab-size;

    &.tilted {
        transform: rotate(45deg);
    }

    &.icon {
        --icon-size: 1.6rem;
    }
}

.create-items {
    padding: .6375rem 0;

    .item {
        display: flex;
        padding: .425rem 1.7rem .425rem .85rem;
        margin: 0.2125rem 0;
        outline: none;

        &:hover {
            background: var(--lowest-contrast, #{$off-white});
        }

        .icon {
            background: var(--primary);
            border-radius: 50%;
            padding: .6rem;
            --icon-size: 1.4rem;
        }

        .caption {
            font-size: 1rem;
        }

        .description {
            color: var(--highest-contrast, #{$dark-grey});
            font-weight: normal;
        }

        .flex-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            line-height: 1.3;

            &:not(.icon-container) {
                margin-left: .65rem;
            }
        }
    }
}

.fab {
    z-index: 2;
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 0;
    border-radius: 1.45rem;
    width: $fab-size;
    height: $fab-size;
    background: var(--primary) !important;
    transition: box-shadow .4s ease-out, width .4s ease-out;

    &.sidebar-bg {
        background: var(--sidebar-bg) !important;
    }

    &:not(.compact) {
        width: calc(var(--sidebar-width) - .85rem);

        @media (min-width: 2160px) {
            width: calc(var(--sidebar-width) - .7rem);
        }
    }

    .fab-content {
        display: flex;
        flex-direction: row;
        align-items: center;
        line-height: 0;
    }

    .fab-content-label {
        margin-left: 0.15rem;
        transition: color .2s ease-out;
        color: var(--primary-text);

        &.sidebar-active {
            color: var(--sidebar-active);
        }
    }
}
</style>
