import {
    AxiosInstance,
    AxiosRequestConfig,
    AxiosResponse,
} from "axios";
import * as firebase from "firebase";
import merge from "lodash/merge";

/**
 * handles api calls with either a cached axios instance or a normal one
 */
export class ApiClient {
    private api: AxiosInstance;

    constructor(axiosInstance: AxiosInstance) {
        this.api = axiosInstance;
    }

    public async get<T>(url: string, config: AxiosRequestConfig = {}): Promise<AxiosResponse<T>> {
        return this.api.get<T>(url, await this.getMergedConfig(config));
    }

    public async delete<T>(url: string, config: AxiosRequestConfig = {}): Promise<AxiosResponse<T>> {
        return this.api.delete(url, await this.getMergedConfig(config));
    }

    public async post<T>(url: string, data?: unknown, config: AxiosRequestConfig = {}): Promise<AxiosResponse<T>> {
        return this.api.post<T>(
            url,
            data,
            await this.getMergedConfig(config)
        );
    }

    public async put<T>(url: string, data?: unknown, config: AxiosRequestConfig = {}): Promise<AxiosResponse<T>> {
        return this.api.put<T>(
            url,
            data,
            await this.getMergedConfig(config)
        );
    }

    public async patch<T>(url: string, data?: unknown, config: AxiosRequestConfig = {}): Promise<AxiosResponse<T>> {
        return this.api.patch<T>(
            url,
            data,
            await this.getMergedConfig(config)
        );
    }

    private async requireToken(): Promise<string | undefined> {
        const currentUser = await waitForCurrentUser();

        if (currentUser === null) {
            throw new Error("no user is currently logged in...");
        }
        return await currentUser.getIdToken();
    }

    private async getMergedConfig(config: AxiosRequestConfig = {}): Promise<AxiosRequestConfig> {
        return merge(await this.getDefaultConfig(), config);
    }

    private async getDefaultConfig(): Promise<AxiosRequestConfig> {
        const token = await this.requireToken();
        return {
            headers: { Authorization: "Bearer " + token }
        };
    }
}

function waitForCurrentUser(): Promise<firebase.User | null> {
    return new Promise<firebase.User | null>((resolve, reject) => {
        const authenticatedCurrentUser = firebase.auth().currentUser;
        if (authenticatedCurrentUser) {
            resolve(authenticatedCurrentUser);
            return;
        }
        const beforeSubscribe = new Date();
        const unsubscribe = firebase.auth().onAuthStateChanged(user => {
            unsubscribe();
            const secondsWaiting: number = (new Date().valueOf() - beforeSubscribe.valueOf()) / 1000;
            console.log(`Waited ${secondsWaiting} seconds for authenticated user`);
            resolve(user);
        }, reject);
    });
}
