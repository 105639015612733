<template>
    <modal
        :data-edit-mode="isEditMode"
        :unavoidable="true"
        fullscreen
        name="news-renderer"
        @close="confirmClose"
        @before-open="beforeOpen"
        @before-close="beforeClose"
    >
        <div
            v-global-page-title="title || $t('news_loading')"
            class="news-layout"
        >
            <transition
                mode="out-in"
                name="open-from-left"
            >
                <EditorSidebar v-if="isEditMode">
                    <CoverPictureSelect :picture-refs="pictureRefsWithWebImages"/>
                    <PostIt>
                        <h4>Don't worry!</h4>
                        <p>There are a lot more metadata settings coming.</p>
                    </PostIt>
                </EditorSidebar>
            </transition>

            <article class="news-wrapper">
                <div
                    id="quill-editor-scrolling-container"
                    class="news-container"
                    tabindex="0"
                >
                    <transition name="fade">
                        <a
                            v-if="!isEditMode"
                            @click="closeNewsAndNavigateToDashboard"
                        >
                            <Logo class="logo-in-news"/>
                        </a>
                    </transition>
                    <LoadingWrapper
                        :is-loading="loading"
                        class="full-height"
                    >
                        <div
                            v-if="!newsNotFound"
                            class="container focussed pbottom-large"
                        >
                            <editor-toolbar
                                id="toolbar"
                                class="ql-toolbar-news-editor sticky"
                                :edit-mode="isEditMode"
                                :show-header-buttons="true"
                                :show-attachment-upload-button="hasUid"
                                :show-embed-uploaded-image-button="hasUid"
                                :show-embed-unsplash-image-button="hasUid"
                                @attachment-selected="onAttachmentSelected"
                                @embed-image-selected="onAttachmentSelected"
                                @unsplash-select="$modal.show('unsplash')"
                            />
                            <resizable-textarea>
                                <textarea
                                    v-model="title"
                                    :disabled="!isEditMode"
                                    :maxlength="MAX_TITLE_LENGTH"
                                    :placeholder="$t('insert_title_here')"
                                    class="news-title news-input"
                                    rows="1"
                                    type="text"
                                    @keypress.enter.prevent=""
                                ></textarea>
                            </resizable-textarea>
                            <resizable-textarea>
                                <textarea
                                    v-model="subtitle"
                                    :class="!subtitle ? 'empty' : ''"
                                    :disabled="!isEditMode"
                                    :maxlength="MAX_SUBTITLE_LENGTH"
                                    :placeholder="isEditMode ? $t('insert_subtitle_here') : ''"
                                    class="news-sub-title news-input"
                                    rows="1"
                                    type="text"
                                    @keypress.enter.prevent=""
                                ></textarea>
                            </resizable-textarea>

                            <NewsMetaAndStats
                                :current-news="currentNews"
                                :is-edit-mode="isEditMode"
                                :is-editing-allowed="isEditingAllowed"
                                :to-edit-mode="toEditMode"
                            />

                            <editor
                                ref="editor"
                                v-model="currentContent"
                                :editor-classes="['everest-news-text']"
                                :edit-mode="isEditMode"
                                :place-holder="$t('editor_placeholder')"
                                :enable-shared-content="false"
                                :attachment-manager="attachmentManager"
                                :image-embed-upload-handler="uploadImageEmbed"
                                toolbar-id="toolbar"
                                @input="searchForDeletedCoverPictures"
                                @files-added="onFileAdded"
                            />

                            <transition name="fade">
                                <section
                                    v-if="!isEditMode"
                                    class="comments"
                                >
                                    <CommentSection
                                        v-show="!loadedAsDraft"
                                        :comment-count="news.commentCount"
                                        :comments="comments"
                                        :content-creator-uid="news.creatorUid"
                                        :content-uid="news.uid"
                                        :selected-reaction-type="news.currentUsersReaction"
                                        :total-reactions="news.reactions"
                                        content-type="news"
                                    />
                                </section>
                            </transition>
                        </div>
                        <div v-else-if="newsNotFound">
                            <SystemMessage
                                :title="$t('news_not_found')"
                                type="unknown"
                            >
                                {{ $t("news_deleted_or_link_broken") }}
                            </SystemMessage>
                        </div>
                        <UnsplashModal :on-selected="insertUnsplashPicture"/>
                    </LoadingWrapper>
                </div>

                <NewsActionsFooter
                    :confirm-close="confirmClose"
                    :is-edit-mode="isEditMode"
                    :is-published="isPublished"
                    :is-removable="isRemovable"
                    :loaded-as-draft="loadedAsDraft"
                    :open-delete-confirm="openDeleteConfirm"
                    :publishable="publishable"
                    :save-and-leave="saveAndLeave"
                    :loading="$store.state.news.loadingCurrent"
                />
                <DeleteNewsModal
                    :deletion-in-progress="deletionInProgress"
                    @delete="remove"
                ></DeleteNewsModal>
                <CloseNewsModal
                    @close="close()"
                ></CloseNewsModal>
            </article>
        </div>

        <template v-slot:controls>
            <div
                v-if="!isEditMode && !loading && isEditingAllowed"
                class="control"
                @click="toEditMode"
            >
                <Icon name="edit"/>
            </div>
            <div
                class="control close"
                @click="confirmClose()"
            >
                <Icon name="x"/>
            </div>
        </template>
    </modal>
</template>

<script>
import Vue from "vue";
import isEqual from "lodash/isEqual";
import Logo from "@web/components/Logo.vue";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import { INTRANET } from "@web/store/intranet/getters";
import {
    mapActions,
    mapGetters,
    mapMutations,
} from "vuex";
import { NEWS_MODULE_NAME } from "@web/store/news/news";
import {
    CREATE_NEWS,
    DELETE_NEWS,
    FETCH_MY_DRAFTS,
    FETCH_PUBLISHED_NEWS,
    OPEN_NEWS,
    SAVE_NEWS,
    UPDATE_PICTURE_REFS,
} from "@web/store/news/actions";
import { RESET_CURRENT_NEWS } from "@web/store/news/mutations";
import {
    ALL_IMAGES_IN_CONTENT,
    CURRENT_NEWS,
} from "@web/store/news/getters";
import Modal from "@web/components/modals/Modal.vue";
import Icon from "@web/components/Icon.vue";
import { ResizableTextarea } from "@web/views/news/ResizableTextarea";
import CloseNewsModal from "@web/views/news/CloseNewsModal";
import DeleteNewsModal from "@web/views/news/DeleteNewsModal";
import EditorSidebar from "@web/views/news/sidebar/EditorSidebar.vue";
import CoverPictureSelect from "@web/views/news/sidebar/CoverPictureSelect.vue";
import { RESOLVE_EVENT } from "@web/store/timestamping/mutations";
import { TIMESTAMP_MODULE_NAME } from "@web/store/timestamping/timestamping";
import { TimestampEvent } from "@web/store/timestamping/types";
import { analytics } from "@web/analytics";
import { getGlobalConfiguration } from "@web/global-config";
import {
    commentService,
    newsService,
} from "@web/services";
import { WEB_URL_STORAGE_REF_PREFIX } from "@web/store/news/NewsService";
import UnsplashModal from "@web/views/news/UnsplashModal.vue";
import {
    asTimestamp,
    getNowAsUtc,
} from "@web/lib/time-utils";
import { PublishableEntityStatus } from "@backend/entity/types";
import StyleMixin from "@web/views/StyleMixin.vue";
import { MentionEntityType } from "@backend/mention/mention-types";
import CommentSection from "@web/components/comments/CommentSection";
import { ContentType } from "@web/services/comments";
import {
    emptyContent,
} from "@web/lib/quill/quill-utils";
import {
    MentionAction,
    mentionAnalytics,
} from "@web/lib/quill/mention/everest-mention";
import NewsMetaAndStats from "@web/views/news/NewsMetaAndStats";
import NewsActionsFooter from "@web/views/news/NewsActionsFooter";
import Editor from "@web/components/editor/Editor";
import { AttachmentManager } from "@web/components/attachments/attachment-manager";
import {
    AttachmentRootEntityType,
    EntityWithAttachmentReference
} from "@web/services/attachments";
import { AUTH_MODULE_NAME } from "@web/store/auth/auth";
import { CURRENT_USER } from "@web/store/auth/getters";
import EditorToolbar from "@web/components/editor/EditorToolbar";
import { i18n } from "@web/i18n";

export const NewsRendererMode = {
    READ: "READ",
    EDIT: "EDIT",
    CREATE: "CREATE",
};

/**
 * this components handles the news editing and rendering functionality
 * it's wrapped in a modal/popup hence it can be opened from everywhere using:
 * this.$modal.show("news-renderer", {mode: NewsRendererMode.READ, newsUid});
 */
export default Vue.extend({
    name: "NewsRenderer",
    components: {
        EditorToolbar,
        Editor,
        NewsActionsFooter,
        NewsMetaAndStats,
        UnsplashModal,
        ResizableTextarea,
        Modal,
        Icon,
        CoverPictureSelect,
        EditorSidebar,
        Logo,
        CloseNewsModal,
        DeleteNewsModal,
        CommentSection,
    },
    mixins: [
        StyleMixin,
    ],
    props: {
        isNewsOpenedUsingADirectLink: {
            type: Boolean,
            default: false,
        },
        isIntegration: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            loading: true,
            saving: false,
            autoSaveIntervalId: null,
            deletionInProgress: false,
            newsUid: undefined,
            lastNewsUid: undefined,
            mode: "",
            title: "",
            subtitle: "",
            openedToCreateANewNews: false,
            loadedAsDraft: false,
            sidebarMinimized: false,
            currentContent: emptyContent(),
            coverPictureRef: null,
            comments: [],
            attachmentManager: new AttachmentManager(),
        };
    },
    computed: {
        ...mapGetters({
            oldPicturesRefsWithWebItems: NEWS_MODULE_NAME + ALL_IMAGES_IN_CONTENT,
            currentNews: NEWS_MODULE_NAME + CURRENT_NEWS,
            intranet: INTRANET_MODULE_NAME + INTRANET,
            currentUser: AUTH_MODULE_NAME + CURRENT_USER,
        }),
        MAX_SUBTITLE_LENGTH: () => 130,
        MAX_TITLE_LENGTH: () => 130,
        publishable() {
            return this.title.trim() !== "";
        },
        hasChanged() {
            return this.news.title !== this.title || this.news.subtitle !== this.subtitle || JSON.stringify(this.news.content) !== JSON.stringify(this.currentContent) || this.coverPictureRef !== this.news.coverPictureRef;
        },
        hasUid() {
            return !!this.newsUid;
        },
        newsNotFound() {
            return !this.currentNews.uid && !this.saving;
        },
        isEditMode() {
            return this.mode === NewsRendererMode.EDIT;
        },
        isEditingAllowed() {
            const currentUser = this.$store.state.auth.currentUser;
            const isAdmin = this.$store.getters["intranet/isAdmin"];
            return isAdmin || (this.currentNews && currentUser && (this.currentNews.creatorUid === currentUser.uid));
        },
        isRemovable() {
            return !this.openedToCreateANewNews;
        },
        pictureRefs() {
            return this.pictureRefsWithWebImages
                .filter((storageRef) => !storageRef.startsWith(WEB_URL_STORAGE_REF_PREFIX));
        },
        pictureRefsWithWebImages() {
            return this.currentContent
                .filter((o) => o.insert.image !== undefined && o.insert.image.storageref !== null)
                .map((o) => o.insert.image.storageref);
        },
        news() {
            return this.currentNews;
        },
        isDraft() {
            return this.currentNews.status === PublishableEntityStatus.draft;
        },
        isPublished() {
            return this.currentNews.status === PublishableEntityStatus.published;
        },
    },
    watch: {
        news() {
            this.initializeNews();
            this.loadComments();
        },
        hasChanged(newValue, oldValue) {
            if (newValue) {
                // prevent tab close after first edit
                this.enablePreventTabClose();
            } else {
                this.disablePreventTabClose();
            }
        },
    },
    mounted() {
        this.loadComments();
    },
    methods: {
        ...mapActions({
            saveNews: NEWS_MODULE_NAME + SAVE_NEWS,
            removeNews: NEWS_MODULE_NAME + DELETE_NEWS,
            loadDrafts: NEWS_MODULE_NAME + FETCH_MY_DRAFTS,
            loadPublishedNews: NEWS_MODULE_NAME + FETCH_PUBLISHED_NEWS,
            openNews: NEWS_MODULE_NAME + OPEN_NEWS,
            createNews: NEWS_MODULE_NAME + CREATE_NEWS,
            updatePictureRefs: NEWS_MODULE_NAME + UPDATE_PICTURE_REFS,
        }),
        ...mapMutations({
            resetCurrentNews: NEWS_MODULE_NAME + RESET_CURRENT_NEWS,
            resolveEvent: TIMESTAMP_MODULE_NAME + RESOLVE_EVENT,
        }),
        closeNewsAndNavigateToDashboard() {
            this.$modal.hide("news-renderer");
            this.$router.push({ name: "dashboard" }, () => {
            });
        },
        allImagesInContent(delta) {
            return delta
                .filter((o) => o.insert.image)
                .map((o) => o.insert.image.storageref);
        },
        onFileAdded(files) {
            this.$notify({
                group: "app",
                type: "success",
                text: files.length > 1
                    ? this.$t("news_attachments_added")
                    : this.$t("news_attachment_added", [files[0].name]),
            });
        },
        async loadComments() {
            if (!this.news.uid || this.isDraft) {
                return;
            }
            this.comments = await commentService.getComments(this.intranet.uid, ContentType.news, this.news.uid);
        },
        async uploadImageEmbed({ file, quill }) {
            try {
                const uploadData = await newsService
                    .uploadImageInNews(this.intranet.uid, this.newsUid, this.$store.state.auth.currentUser.uid, file);
                analytics.log(getGlobalConfiguration().analytics_event_name_news_uploaded_image_inserted);
                return uploadData;
            } catch (error) {
                console.error(error);
                if (error.response && error.response.status === 413) {
                    this.$notify({
                        group: "app",
                        type: "info",
                        text: this.$t("storage_limit_reached"),
                    });
                }
                quill.deleteText(range.index, 1);
            }
        },
        async saveAndLeave({ asDraft }) {
            try {
                this.saving = true;
                await this.save(asDraft);
                this.$notify({
                    group: "app",
                    type: "success",
                    text: asDraft ? this.$t("news_saved_as_draft") : this.$t("news_published"),
                });
                this.loadedAsDraft = asDraft;
                this.toReadMode();
                await Promise.all([this.loadPublishedNews(), this.loadDrafts()]);
                if (!this.loadedAsDraft && asDraft) {
                    this.$modal.hide("news-renderer");
                }
                this.resolveEvent(TimestampEvent.newsPublished);
                this.openendToCreateANewNews = false;
            } catch (e) {
                console.error(e);
                this.$notify({
                    group: "app",
                    type: "error",
                    text: asDraft ? this.$t("news_saved_as_draft_failed") : this.$t("news_published_failed"),
                });
            } finally {
                this.saving = false;
            }
        },
        async autoSave() {
            if (!this.isDraft && this.autoSaveIntervalId !== null) {
                this.stopAutoSave();
                return;
            }
            if (this.saving) {
                return;
            }
            try {
                this.saving = true;
                console.log("Saving with autoSave()");
                await this.save(true, true);
            } finally {
                this.saving = false;
            }
        },
        startAutoSave() {
            console.log("Start auto save");
            this.autoSaveIntervalId = setInterval(
                () => this.autoSave(),
                30000,
            );
            document.addEventListener("visibilitychange", this.autoSave);
        },
        stopAutoSave() {
            console.log("Stop auto save");
            document.removeEventListener("visibilitychange", this.autoSave);
            clearInterval(this.autoSaveIntervalId);
            this.autoSaveIntervalId = null;
        },
        async save(asDraft, silent) {
            const changeRequest = {
                title: this.title,
                subtitle: this.subtitle,
                content: this.currentContent,
                status: asDraft ? PublishableEntityStatus.draft : PublishableEntityStatus.published,
                pictureRefs: this.pictureRefs,
                coverPictureRef: this.currentNews.coverPictureRef,
                publishDate: this.loadedAsDraft && !asDraft ? asTimestamp(getNowAsUtc()) : undefined,
            };
            await Promise.all([
                this.saveNews({
                    news: changeRequest,
                    newsUid: this.newsUid,
                    silent: silent,
                }),
                this.attachmentManager.save(
                    new EntityWithAttachmentReference({
                        intranetUid: this.intranet.uid,
                        entityType: AttachmentRootEntityType.news,
                        entityUid: this.newsUid,
                        entity: this.news,
                    }),
                    this.currentUser.uid,
                ),
            ]);

            if (silent !== true) {
                this.triggerNewsAnalytics(this.currentContent);
            }
        },
        async remove() {
            try {
                this.deletionInProgress = true;
                await this.removeNews();
                this.$notify({
                    group: "app",
                    type: "success",
                    text: this.$t("news_deleted"),
                });
                this.deletionInProgress = false;
                this.close({ afterRemoval: true });
            } catch (e) {
                this.$notify({
                    group: "app",
                    type: "error",
                    text: this.$t("news_deleted_failed"),
                });
                this.deletionInProgress = false;
            }
        },
        initializeNews() {
            if (!this.currentNews || !this.currentNews.uid) {
                this.attachmentManager = new AttachmentManager();
                return;
            }

            const { uid, title, subtitle, content, status, coverPictureRef } = this.currentNews;
            if (this.lastNewsUid === uid) {
                return;
            }

            this.title = title;
            this.subtitle = subtitle;
            this.coverPictureRef = coverPictureRef;
            this.loadedAsDraft = status === PublishableEntityStatus.draft;
            this.currentContent = content;

            this.attachmentManager = AttachmentManager.load({
                entityWithAttachmentReference: new EntityWithAttachmentReference({
                    intranetUid: this.intranet.uid,
                    entityType: AttachmentRootEntityType.news,
                    entityUid: uid,
                    entity: this.currentNews,
                }),
                onError: error => {
                    this.$notify({
                        group: "app",
                        type: "error",
                        title: this.$t("attachment_loading_error_title"),
                        text: this.$t("attachment_loading_error_text", [this.$t("news")]),
                    });
                    console.error(error);
                },
            });

            this.lastNewsUid = uid;
        },
        enablePreventTabClose() {
            console.log("disablePreventTabClose");
            if (!window.onbeforeunload) {
                window.onbeforeunload = () => {
                    return this.$t("discard_editor_content");
                };
            }
        },
        disablePreventTabClose() {
            console.log("enablePreventTabClose");
            window.onbeforeunload = undefined;
        },
        async beforeOpen(event) {
            // initial values
            this.openedToCreateANewNews = false;
            this.loading = true;
            const { mode, newsUid } = event.params;

            // loading existing news
            if (newsUid) {
                try {
                    await this.openNews(event.params.newsUid);
                } catch (e) {
                    console.error(e);
                }
                this.newsUid = newsUid;
            } else {
                // loading new news
                const newUid = await this.createNews();
                await this.openNews(newUid);
                this.newsUid = newUid;
            }

            if (mode === NewsRendererMode.READ || mode === NewsRendererMode.EDIT) {
                this.mode = mode;
            }
            if (mode === NewsRendererMode.CREATE) {
                this.toCreateMode();
            }
            if (this.isIntegration) {
                this.styleInject();
            }
            this.loading = false;
        },
        async beforeClose(event) {
            this.loading = true;
            this.mode = NewsRendererMode.READ;
            this.stopAutoSave();
        },
        confirmClose() {
            // Just leave it the news is in read mode the news or has not changed
            if (this.mode === NewsRendererMode.READ || !this.hasChanged) {
                this.close();
                return;
            }
            if (this.isDraft) {
                this.saveAndLeave({ asDraft: true });
                return;
            }
            this.$modal.show("close-news-modal");
        },
        /**
         * Is called when opening the create mode.
         */
        toCreateMode() {
            this.mode = NewsRendererMode.EDIT;
            this.openedToCreateANewNews = true;
            this.startAutoSave();
        },
        /**
         * Transition from view to edit.
         * It is not called when a new news is created
         */
        toEditMode() {
            this.mode = NewsRendererMode.EDIT;
            this.startAutoSave();
        },
        /**
         * Transition from edit ro read mode.
         */
        toReadMode() {
            this.mode = NewsRendererMode.READ;
            this.disablePreventTabClose();
            this.stopAutoSave();
        },
        resetNews() {
            this.title = this.news.title;
            this.subtitle = this.news.subtitle;
            this.currentContent = this.news.content;
            this.attachmentManager.reset();
        },
        close({ afterRemoval } = {}) {
            this.disablePreventTabClose();
            if (!this.isEditMode || afterRemoval) {
                if (this.isPublished || afterRemoval) {
                    this.$modal.hide("news-drawer");// hide news-drawer in background if a news has been published or deleted using the component. not hiding when closing a draft.
                }
                this.$emit("onNewsClosed");
                if (!this.isNewsOpenedUsingADirectLink) {
                    this.$modal.hide("news-renderer");
                }
                return;
            }
            if (this.isEditMode && this.openedToCreateANewNews) {
                if (!this.isNewsOpenedUsingADirectLink) {
                    this.$modal.hide("news-renderer");
                    // Dont use this.$router.back() because news dont have uid until saving
                } else {
                    this.$emit("onNewsClosed");
                }
                return;
            }
            if (!this.isEditMode) {
                this.$modal.hide("news-renderer");
                this.$emit("onNewsClosed");
                this.resetCurrentNews();
                return;
            }
            if (this.isEditMode) {
                this.resetNews();
                this.toReadMode();
            }
        },
        searchForDeletedCoverPictures() {
            const newPictureRefs = this.allImagesInContent(this.currentContent);
            const oldPictureRefs = this.oldPicturesRefsWithWebItems;
            if (!isEqual(oldPictureRefs, newPictureRefs)) {
                this.updatePictureRefs(newPictureRefs);
            }
        },
        insertUnsplashPicture({ url, username, name, description }) {
            const quill = this.$refs.editor.quill;
            const range = quill.getSelection(true);
            analytics.log(getGlobalConfiguration().analytics_event_name_news_unsplash_photo_inserted);
            quill.insertEmbed(range.index, "image", {
                unsplashUrl: url,
                unsplashAuthor: { username, name, description },
            }, "user");
        },
        openModal(name) {
            this.$modal.show(name);
        },
        closeDeleteConfirm() {
            this.saveAndLeave({ asDraft: true });
            this.$modal.hide("delete-news-modal");
        },
        openDeleteConfirm() {
            this.$modal.show("delete-news-modal");
        },
        triggerNewsAnalytics(content) {
            // See news state diagram https://seibertmedia-cloud.atlassian.net/wiki/spaces/LC/pages/51085313/Firebase+Analytics+Usage+Tracking
            if (this.isPublished) {
                if (this.loadedAsDraft) {
                    analytics.log(getGlobalConfiguration().analytics_event_name_news_published);
                    mentionAnalytics.trackMentions(content, MentionEntityType.news, MentionAction.create);
                } else {
                    analytics.log(getGlobalConfiguration().analytics_event_name_news_edited);
                    mentionAnalytics.trackMentions(content, MentionEntityType.news, MentionAction.edit);
                }
            }
            if (this.isDraft) {
                if (this.loadedAsDraft) {
                    analytics.log(getGlobalConfiguration().analytics_event_name_save_news_as_draft);
                } else {
                    analytics.log(getGlobalConfiguration().analytics_event_name_news_unpublished);
                }
            }
        },
        onAttachmentSelected(event) {
            return this.$refs.editor.onAttachmentSelected(event);
        }
    },
});
</script>

<style lang="scss">
[data-fullscreen="true"] .modal-footer {
    padding: 0 !important;
}
</style>

<style lang="scss" scoped>
.logo {
    top: 0.75em;
    left: 1.5rem;
    position: absolute;
    user-select: none;
    @media(max-width: 680px) {
        display: none;
    }
}

.news-layout {
    display: flex;
    align-items: stretch;

    .news-wrapper {
        display: flex;
        flex-direction: column;
        height: 100vh;

        position: relative;
        flex: 4 1 auto;
        overflow: hidden;

        .news-container {
            flex: 1 1 auto;
            height: 100vh;
            overflow: auto;
            padding-right: 7rem;
            padding-left: 7rem;
            @media(max-width: 680px) {
                padding-left: 2rem;
                padding-right: 2rem;
            }
            @media(max-width: 480px) {
                padding-left: 1rem;
                padding-right: 1rem;
            }

        }

    }

}
</style>

<style lang="scss">
.logo-in-news {
    z-index: 1;
    cursor: pointer;
}

.quill-editor {
    .everest-image-wrapper {
        cursor: pointer;
        height: 300px;
        overflow: hidden;
        position: relative;
        display: flex;
        justify-content: center;
        transition: height 0.3s ease-out;
        flex-direction: column;
        align-items: center;

        .placeholder {
            height: 100%;
            background: var(--lowest-contrast, #{$off-white});
            display: flex;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            transition: opacity 0.3s ease-out;
        }

        &:not(.loaded) img {
            visibility: hidden;
        }

        &.error img {
            height: 300px;
            background: var(--lowest-contrast, #{$off-white});
        }

        &.loaded {
            .placeholder {
                opacity: 0;
            }

            .unsplash-information {
                opacity: 1;
            }
        }

        &.fast {
            transition-duration: 0s;

            .placeholder {
                transition-duration: 0s;
            }
        }

        .unsplash-information {
            z-index: 10;
            opacity: 0;

            a {
                color: grey;
            }
        }
    }

    .everest-image {
        object-fit: cover;
        max-width: 100%;
        display: block;
        margin: 0 auto;
        border-radius: 3px;
    }
}

.ql-container {
    font-family: inherit;
}

// handles edge cases if subtitle is empty
[data-modal="news-renderer"]:not([data-edit-mode]) textarea.news-sub-title.empty {
    margin-top: -30px;
    opacity: 0;
}

.ql-container {
    font-family: inherit;
}

.ql-clipboard {
    position: fixed !important;
    left: 50% !important;
    top: 50% !important;
}

.comments {
    margin-top: 3rem;

    .content-footer {
        border-bottom: 1px solid var(--lowest-contrast, #{$off-white});
        padding-bottom: 1.5rem;
        margin-bottom: 2.5rem;

        .icon {
            --icon-size: 24px;
        }

        .reply-button {
            .title {
                font-size: .8rem;
            }
        }

        .trigger {
            .reaction-icon {
                width: 24px;
                height: 24px;
            }

            .title {
                margin-left: 24px;
                font-size: .8rem;
            }
        }

        .reaction-graph {
            .reaction-icon {
                width: 24px;
                height: 24px;
            }

            .total-count {
                font-size: .8rem;
            }
        }

        .comment-counter {
            font-size: .8rem;
        }
    }
}

#toolbar {
    box-shadow: 0px 5px 20px 5px var(--background, #{$white});
}

.ql-toolbar-news-editor {
    opacity: 0;
}

.ql-toolbar-news-editor.ql-fuji {
    z-index: 5;
    top: 0;
    padding: 2rem 1px 0.6rem;
    margin: 0 -1px 0;
    background-color: var(--background, #{$white});
    border-bottom: 1px solid var(--lowest-contrast, #{$off-white});

    transition: opacity .5s ease, transform .5s ease;

    pointer-events: none;
    opacity: 0;
    transform: translate(0, -200%);

    &[data-edit-mode] {
        pointer-events: all;
        opacity: 1;
        transform: translate(0, 0);
        @media(max-width: 680px) {
            padding: 1rem 1px 0.6rem 1rem;
        }
    }
}
</style>
