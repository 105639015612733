export class CachingService {
    private readonly DEFAULT_EXPIRE_SECONDS: number = 86400;

    localStorageKey: string;

    constructor(localStorageKey: string) {
        this.localStorageKey = localStorageKey;
    }

    private getStorage(): Storage {
        return (sessionStorage) || localStorage;
    }

    createCacheKey(intranetUid: string, storyUid: string, storageRef: string): string {
        return this.localStorageKey + "-" + intranetUid + "-" + storyUid + "-" + storageRef;
    }

    saveCacheKey(cacheKey: string, value: any, expires: number): void {
        const seconds = (expires > 0) ? expires : this.DEFAULT_EXPIRE_SECONDS;
        const data = { data: value, expires: new Date(new Date().getTime() + seconds).getTime() };
        this.getStorage().setItem(cacheKey, btoa(JSON.stringify(data)));
    }

    loadContent(cacheKey: string): any {
        const data = this.getStorage().getItem(cacheKey);
        if (data) {
            const result = JSON.parse(atob(data));
            if ((result.expires - new Date().getTime()) > 0) {
                return result.data;
            }
        }
        return null;
    }
}
