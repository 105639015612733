<template>
    <div class="news-meta">
        <transition
            mode="in-out"
            name="pushIn"
        >
            <div v-if="!isEditMode">
                <NewsMeta
                    :news="currentNews"
                />
                <NewsStatsAndActions
                    :is-edit-mode="isEditMode"
                    :is-editing-allowed="isEditingAllowed"
                    :news="currentNews"
                    @to-edit-mode="toEditMode"
                />
            </div>
        </transition>
    </div>
</template>
<script>
import NewsMeta from "@web/views/news/NewsMeta.vue";
import NewsStatsAndActions from "@web/components/news/NewsStatsAndActions.vue";

export default {
    name: "NewsMetaAndStats",
    components: { NewsMeta, NewsStatsAndActions },
    props: {
        currentNews: { type: Object, required: true },
        isEditMode: { type: Boolean, required: true },
        isEditingAllowed: { type: Boolean, required: true },
        toEditMode: { type: Function, required: true },
    },
};
</script>

<style lang="scss" scoped>

.news-meta > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 1rem 0;
    margin: 0 0 3rem 0;
    border-bottom: 1px solid var(--lowest-contrast, #{$off-white});

    @media(max-width: 680px) {
        padding: 0 0 1rem 0;
        margin: 0 0 1rem 0;
    }
}

</style>
