<template>
    <transition name="fade">
        <div
            v-if="totalReactions"
            class="reaction-graph"
            @click="$emit('open', 'all')"
        >
            <div class="graph">
                <ReactionIcon
                    v-for="(reaction, idx) in displayedReactions"
                    :key="reaction"
                    :class="{
                        stacked: idx !== 0,
                    }"
                    :reaction="reaction"
                    :amount="reactions[reaction]"
                />
            </div>
            <div class="total-count">
                {{ totalReactions }}
            </div>
        </div>
    </transition>
</template>

<script>
import Vue from "vue";
import ReactionIcon from "@web/components/reaction/ReactionIcon";
import { reactionOrder } from "@backend/reaction/types";

export default Vue.extend({
    name: "ReactionGraph",
    components: {
        ReactionIcon
    },
    props: {
        reactions: {
            type: Object
        },
    },
    computed: {
        totalReactions() {
            const reactionsArray = Object.values(this.reactions);
            return reactionsArray.reduce((t, c) => t + c);
        },
        displayedReactions() {
            return Object.keys(this.reactions)
                .filter(reaction => this.reactions[reaction] > 0)
                .sort((a, b) => {
                    const countComparison = this.reactions[a] - this.reactions[b];
                    if (countComparison !== 0) return countComparison;
                    return reactionOrder.indexOf(b) - reactionOrder.indexOf(a);
                })
                .slice(-3);
        },
    },
});
</script>

<style lang="scss" scoped>
.reaction-graph {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 1rem;

    .graph {
        display: flex;
        .stacked {
            margin-left: -8px;
        }
    }

    .total-count {
        margin-left: 0.1rem;
        font-size: 0.7rem;
        font-weight: bold;
        cursor: pointer;
    }
}
</style>

<docs>
```vue
<reaction-graph :reactions="{
    smile: 10,
    dislike: 9,
    like: 8,
    love: 6,
    clap: 5,
}"/>
```
</docs>
