<template>
    <section class="tab-group">
        <nav class="tabs">
            <router-link
                v-for="(tab, i) in links"
                :key="'link' + i"
                :to="{ name: tab.to }"
                class="tab"
            >
                {{ tab.title }}
            </router-link>
        </nav>
        <nav
            v-if="linksRight.length > 0"
            class="tabs"
        >
            <router-link
                v-for="(tab, i) in linksRight"
                :key="'link-right' + i"
                :to="{ name: tab.to }"
                class="tab critical"
            >
                {{ tab.title }}
            </router-link>
        </nav>
    </section>
</template>

<script>
export default {
    name: "Tabs",
    props: {
        links: {
            type: Array,
            default: () => [
                {
                    title: "Tab",
                    to: ""
                }
            ]
        },
        linksRight: {
            type: Array,
            default: () => []
        }
    },
};
</script>

<style lang="scss" scoped>
    $tab-height: 0.75rem;
    $stroke-width: 2px;

    .tab-group {
        border-bottom: $stroke-width solid var(--low-contrast, #{$light-grey});
        padding: calc(#{$tab-height} - #{$stroke-width}) 0;
        margin: 0 0 2rem;
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-between;

        .tabs {
            flex: 0 0 auto;
        }

        .tab {
            text-decoration: none;
            padding: $tab-height 0;
            font-weight: 500;
            border-bottom: $stroke-width solid transparent;
            user-select: none;
            -webkit-user-drag: none;
            transition: all 0.15s ease-out;

            &:hover {
                color: var(--primary);
                border-bottom: $stroke-width solid var(--primary);
            }

            &:focus {
                outline: none;
            }

            &.active,
            &.router-link-exact-active {
                color: var(--primary);
                border-bottom: $stroke-width solid var(--primary);
            }

            & + .tab {
                margin-left: 1rem;
            }
        }
        .critical{
            margin-right: 2rem;
        }
    }
</style>
