<template>
    <div
        :class="{show, fetching}"
        class="new-content-indicator flex flex-center flex-hcenter small-text px-medium py-xsmall depth-2"
        @click="triggerFetchContent"
    >
        <Spinner
            class="spinner pos-absolute"
            inline
            size="1rem"
        />
        <div class="content-available">
            <icon
                class="new-content-indicator-icon mright-xsmall"
                name="arrow-up"
                size="small"
            />
            {{ text }}
        </div>
    </div>
</template>

<script>
import Spinner from "@web/components/Spinner";
import { wait } from "@web/lib/wait";

export default {
    name: "StickyNewContentIndicator",
    components: {
        Spinner,
    },
    props: {
        text: { type: String, default: "" },
        show: { type: Boolean },
        fetchContent: { type: Function, required: true },
        scrollIntoViewSelector: { type: String },
    },
    data() {
        return {
            fetching: false,
        };
    },
    methods: {
        async triggerFetchContent() {
            this.fetching = true;
            try {
                // Scroll to top and wait to allow user to recognize the new content depending on the current position.
                const scrollGoal = document.querySelector(this.scrollIntoViewSelector) || this.$el.parentElement;
                const scrollPosition = scrollGoal.offsetTop;
                if (window.scrollY > scrollPosition) {
                    scrollGoal.scrollIntoView({
                        block: "start",
                        behavior: "smooth",
                    });
                    const waitingTime = Math.min(Math.round(window.scrollY - scrollPosition), 500);
                    await wait(waitingTime);
                }
                // fetch the new content
                await this.fetchContent();
                // align fetching state toggle with slide/fade-out animation
                await wait(500);
                this.fetching = false;
            } catch (e) {
                // Wait to prevent a blinking spinner
                await wait(250);
                this.fetching = false;
                this.$notify({
                    group: "app",
                    type: "error",
                    text: this.$t("new_content_loading_failed"),
                });
                console.error(e);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.new-content-indicator {
    width: fit-content;
    cursor: pointer;
    background-color: #ffffff;
    text-align: center;
    border-radius: 2rem;
    margin: -1rem auto;
    position: sticky;
    top: 1rem;
    z-index: 500;
    transition: box-shadow 0.2s ease-in-out, top 0.5s ease-in-out, opacity 0.3s ease-in-out;

    &:hover {
        box-shadow: $depth-3;
        transition: box-shadow 0.2s ease-in-out;
    }

    &.show {
        opacity: 1;
        top: 1rem;
    }

    &:not(.show) {
        opacity: 0;
        top: -100px;
        pointer-events: none;
    }

    .spinner {
        margin-top: -0.9rem;
    }

    &.fetching {
        .spinner {
            opacity: 1;
        }

        .content-available {
            opacity: 0;
        }
    }

    &:not(.fetching) {
        .spinner {
            opacity: 0;
        }

        .content-available {
            opacity: 1;
        }
    }
}
</style>
