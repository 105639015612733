import { VuexRootState } from "@web/store";
import { Module } from "vuex";
import { Team } from "@backend/team/types";
import {
    FETCHING,
    GET_ERROR,
    GET_TEAMS,
    HAS_MORE,
    INITIALLY_LOADED,
    IS_EMPTY,
} from "@web/store/team/getters";
import {
    SET_FETCHING,
    SET_TEAM_TEAMS,
} from "@web/store/team/mutations";
import {
    CREATE_TEAM,
    DELETE_TEAM,
    FETCH_TEAMS,
    RESET_TEAMS,
    UPDATE_TEAM,
} from "@web/store/team/actions";
import { teamService } from "@web/services";
import { UPDATE_INTRANET_TEAM_COUNT } from "@web/store/intranet/actions";

export const TEAM_MODULE_NAME = "team/";
// Backend sets a hard max limit of 20. Consider to increase value if backend supports it.
const TEAM_PAGE_SIZE = 19;

export interface TeamModuleState {
    teams: Team[];
    initiallyLoaded: boolean;
    hasMore: boolean;
    fetching: boolean;
    hasError: boolean;
}

function getDefaultState(): TeamModuleState {
    return {
        teams: [],
        initiallyLoaded: false,
        hasMore: false,
        fetching: false,
        hasError: false,
    };
}

export const TEAM_MODULE = {
    strict: true,
    namespaced: true,
    state: getDefaultState(),
    getters: {
        [GET_TEAMS]: state => state.teams,
        [HAS_MORE]: state => state.hasMore,
        [FETCHING]: state => state.fetching,
        [INITIALLY_LOADED]: state => state.initiallyLoaded,
        [GET_ERROR]: state => state.hasError,
        [IS_EMPTY]: state => state.teams.length === 0 && !state.fetching && state.initiallyLoaded,
    },
    mutations: {
        [SET_TEAM_TEAMS](state, {
            initiallyLoaded,
            teams,
            hasNextPage,
            error,
        }: { initiallyLoaded: boolean, teams: Team[], hasNextPage: boolean, error: boolean }) {
            state.initiallyLoaded = initiallyLoaded;
            state.teams = teams;
            state.hasMore = hasNextPage;
            state.hasError = error;
        },
        [SET_FETCHING](state, { fetching }: { fetching: boolean }) {
            state.fetching = fetching;
        },
    },
    actions: {
        async [RESET_TEAMS]({ commit }) {
            commit(SET_TEAM_TEAMS, { initiallyLoaded: false, teams: [], hasNextPage: false, error: false });
        },
        async [FETCH_TEAMS]({ commit, dispatch, rootState, state }) {
            commit(SET_FETCHING, { fetching: true });
            const intranetUid = rootState.intranet.intranet!.uid;
            const previouslyFetchedTeams = state.teams;
            try {
                const fetchedTeams: Team[] = await teamService.getTeams(intranetUid, {
                    limit: TEAM_PAGE_SIZE + 1,
                    startAfter: state.teams[state.teams.length - 1]?.uid,
                });
                const hasNextPage = fetchedTeams.length > TEAM_PAGE_SIZE;
                if (hasNextPage) {
                    fetchedTeams.pop();
                }
                const allFetchedTeams = previouslyFetchedTeams.concat(fetchedTeams);
                commit(SET_TEAM_TEAMS, {
                    initiallyLoaded: true,
                    teams: allFetchedTeams,
                    hasNextPage,
                    error: false,
                });
                dispatch(UPDATE_INTRANET_TEAM_COUNT, fetchedTeams.length, { root: true });
            } catch (e) {
                console.error("unable to fetch teams", e);
                commit(SET_TEAM_TEAMS, {
                    initiallyLoaded: false,
                    teams: [],
                    hasNextPage: false,
                    error: true,
                });
            } finally {
                commit(SET_FETCHING, { fetching: false });
            }
        },
        async [CREATE_TEAM]({ commit, dispatch, rootState, state }, { name }) {
            const intranetUid = rootState.intranet.intranet!.uid;
            const teams = state.teams;
            const createdTeam = await teamService.createTeam(intranetUid, {
                name,
            });
            teams.push(createdTeam);
            teams.sort((team1: Team, team2: Team) => team1.name.localeCompare(team2.name));
            commit(SET_TEAM_TEAMS, {
                teams,
                hasNextPage: state.hasMore,
                error: state.hasError,
            });
            dispatch(UPDATE_INTRANET_TEAM_COUNT, teams.length, { root: true });
        },
        async [DELETE_TEAM]({ commit, dispatch, rootState, state }, { teamUid }) {
            const intranetUid = rootState.intranet.intranet!.uid;
            const previousTeams = state.teams;
            await teamService.deleteTeam(intranetUid, teamUid);
            const updatedTeams = previousTeams.filter((team) => team.uid !== teamUid);
            commit(SET_TEAM_TEAMS, {
                teams: updatedTeams,
                hasNextPage: state.hasMore,
                error: state.hasError,
            });
            dispatch(UPDATE_INTRANET_TEAM_COUNT, updatedTeams.length, { root: true });
        },
        async [UPDATE_TEAM]({ commit, rootState, state }, { teamUid, teamUpdatePayload }) {
            const intranetUid = rootState.intranet.intranet!.uid;
            const teams = state.teams;
            const updatedTeam = await teamService.updateTeam(intranetUid, teamUid, teamUpdatePayload);
            const index = teams.findIndex((team) => team.uid === teamUid);
            teams.splice(index, 1, updatedTeam);
            teams.sort((team1: Team, team2: Team) => team1.name.localeCompare(team2.name));
            commit(SET_TEAM_TEAMS, {
                teams,
                hasNextPage: state.hasMore,
                error: state.hasError,
            });
        },
    },
} as Module<TeamModuleState, VuexRootState>;
