/**
 * Initialize (firestore-aware) Services
 */
import { UserLoginService } from "@web/store/auth/UserLoginService";
import { IntranetService } from "@web/store/intranet/IntranetService";
import { ThemeService } from "@web/services/ThemeService";
import {
    CodeBasedIntranetAccessService,
    InviteService
} from "@web/services/intranet-access";
import { UserService } from "@web/store/people/UserService";
import { ProfileService } from "@web/store/profile/ProfileService";
import { NewsService } from "@web/store/news/NewsService";
import { AtlassianConnectService } from "@web/store/atlassian/AtlassianConnectService";
import { AtlassianContentService } from "@web/views/atlassian/AtlassianContentService";
import { GoogleCalendarConnectService } from "@web/store/google/GoogleCalendarConnectService";
import { GoogleContentService } from "@web/views/google/GoogleContentService";
import { UnsplashService } from "@web/store/unsplash/UnsplashService";
import { ConfluenceConfigurationService } from "@web/views/atlassian-cloud/confluence/admin/ConfluenceConfigurationService";
import { EventService } from "@web/store/event/EventService";
import { FeatureFlagService } from "@web/store/intranet/FeatureFlagService";
import { DomainBasedIntranetAccessService } from "@web/store/intranet/DomainBasedIntranetAccessService";
import { LaunchpadService } from "@web/store/launchpad/LaunchpadService";
import { ReactionService } from "@web/store/reaction/ReactionService";
import { ResizingService } from "@web/views/atlassian-cloud/confluence/macro/ResizingService";
import { InboxService } from "@web/services/InboxService";
import { FollowService } from "@web/services/FollowService";
import { i18n } from "@web/i18n";
import { CommentService } from "@web/services/comments";
import { NotificationSettingsService } from "@web/services/NotificationSettingsService";
import { StoryService } from "@web/services/StoryService";
import { AttachmentService } from "@web/services/attachments";
import { SharedContentService } from "@web/services/shared-content";
import { ProfileAuxiliaryService } from "@web/services/ProfileAuxiliaryService";
import { FileUploadService } from "@web/services/file-upload";
import { SocialHappeningsService } from "@web/services/social-happenings";
import { ActivityDispatcherService } from "@web/services/activity-dispatching";
import { SocialNotificationService } from "@web/services/social-notifications";
import { HappinessService } from "@web/store/happiness/HappinessService";
import { HubspotService } from "@web/store/hubspot/HubspotService";
import { TeamService } from "@web/store/team/TeamService";
import { SocialOverviewService } from "@web/services/social-overview";
import { ViewService } from "@web/store/view-tracking/ViewService";
import { ProductSubscriptionService } from "@web/services/product-subscription";

export const userLoginService = new UserLoginService();
export const intranetService = new IntranetService();
export const inviteService = new InviteService();
export const codeBasedIntranetAccessService = new CodeBasedIntranetAccessService();
export const themeService = new ThemeService();
export const userService = new UserService();
export const teamService = new TeamService();
export const newsService = new NewsService();
export const atlassianConnectService = new AtlassianConnectService();
export const atlassianContentService = new AtlassianContentService();
export const googleCalendarConnectService = new GoogleCalendarConnectService();
export const googleContentService = new GoogleContentService();
export const unsplashService = new UnsplashService();
export const confluenceConfigurationService = new ConfluenceConfigurationService();
export const commentService = new CommentService();
export const featureFlagService = new FeatureFlagService();
export const domainBasedIntranetAccessService = new DomainBasedIntranetAccessService();
export const launchpadService = new LaunchpadService();
export const reactionService = new ReactionService();
export const resizingService = new ResizingService();
export const storyService = new StoryService();
export const inboxService = new InboxService();
export const followService = new FollowService();
export const notificationSettingsService = new NotificationSettingsService();
export const fileUploadService = new FileUploadService();
export const sharedContentService = new SharedContentService();
export const activityDispatcherService = new ActivityDispatcherService();
export const socialNotificationService = new SocialNotificationService();
export const happinessService = new HappinessService();
export const hubspotService = new HubspotService();
export const socialOverviewService = new SocialOverviewService();
export const viewService = new ViewService();
export const productSubscriptionService = new ProductSubscriptionService();

export const socialHappeningsService = new SocialHappeningsService(socialNotificationService);
export const eventService = new EventService(fileUploadService);
export const attachmentService = new AttachmentService(fileUploadService);

export const profileService = new ProfileService(i18n, fileUploadService);
export const profileAuxiliaryService = new ProfileAuxiliaryService(fileUploadService, profileService);
