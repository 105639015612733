<template>
    <modal
        name="confirm-modal"
        :confirm-text="confirmText"
        :cancel-text="cancelText"
        :warning="warning"
        :title="title"
        :hide-footer="callback === undefined"
        :unavoidable="callback !== undefined"
        @before-open="init"
        @before-close="cancel"
        @modal-confirm="confirm"
    >
        {{ text }}
    </modal>
</template>

<script>
import Modal from "@/components/modals/Modal";

export default {
    name: "ConfirmModal",
    components: {
        Modal,
    },
    data: () => ({
        title: undefined,
        text: undefined,
        confirmText: undefined,
        cancelText: undefined,
        callback: undefined,
        warning: undefined,
    }),
    methods: {
        cancel() {
            if (this.callback) {
                this.callback(false);
            }
            this.reset();
        },
        confirm() {
            if (this.callback) {
                this.callback(true);
            }
            this.reset();
        },
        init(event) {
            this.title = event.params.title;
            this.text = event.params.text;
            this.confirmText = event.params.confirmText || this.$t("confirm");
            this.cancelText = event.params.cancelText || this.$t("cancel");
            this.callback = event.params.callback;
            this.warning = event.params.warning;
        },
        reset() {
            this.title = undefined;
            this.text = undefined;
            this.confirmText = undefined;
            this.cancelText = undefined;
            this.callback = undefined;
            this.warning = undefined;
        },
    },
};
</script>

<style scoped>

</style>
