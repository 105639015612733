<template>
    <span
        :class="sizeClass"
        :title="disableAlt ? '' : displayName"
        class="avatar"
    >
        <span v-if="$slots.default">
            <!-- @slot Insert a text instead of a photo to display in the avatar -->
            <slot></slot>
        </span>
        <span v-if="!$slots.default">
            <img
                v-if="photoURL && !fallback"
                :alt="disableAlt ? '' : displayName"
                :src="photoURL"
                @error="fallback = true"
            />
            <icon
                v-if="!photoURL || fallback"
                :name="icon"
            />
        </span>
    </span>
</template>

<script>
/**
 * Displays a photo url as avatar in a circle shape.
 * Can be used next to usernames or content to indicate a contribution.
 */
export default {
    name: "Avatar",
    inheritAttrs: false,
    props: {
        /** Url to the avatar photo. If non is provided the avatar displays a placeholder circle. Meets with the `EverestUser` interface. */
        photoURL: String,
        /** Used as `title` attribute on the avatar and also as `alt` attribute for the `img`-Tag. Meets with the `EverestUser` interface. */
        displayName: String,
        /** Use to get a different size of the avatar than the default.
         * @values xxlarge, xlarge, large, medium, small */
        size: { type: String, validator: (value) => ["xxlarge", "xlarge", "large", "medium", "small", "xsmall"].includes(value) },
        /**
         * Icon to use if img cannot be loaded
         */
        icon: { type: String, default: "user" },
        disableAlt: { type: Boolean, default: false },
    },
    data() {
        return {
            fallback: false,
        };
    },
    computed: {
        sizeClass() {
            if (this.size) return `avatar-${this.size}`;
            return undefined;
        },
    },
};
</script>

<style lang="scss" scoped>
</style>

<docs>
With a photo
```vue
<avatar
    photoURL="https://i.pravatar.cc/256"
    displayName="Roon Mayerdorfer"
/>
```

Empty:
```vue
<avatar/>
```

Broken `photoURL`:
```vue
<avatar photoURL="https://localhost/img.png"/>
```

With text:
```vue
<avatar>+11</avatar>
```

Easy use with `EverestUser` interface:
```js static
<template>
    <avatar v-bind="user"/>
</template>
<script>
    export default {
        data() {
            return {
                /** @type {EverestUser} */
                user: {
                    photoURL: "https://i.pravatar.cc/256",
                    displayName: "Roon Mayerdorfer",
                },
            };
        },
    };
</script>
```

On a dark background:
```vue
<div class="padding-small" style="background: var(--low-contrast)">
    <avatar/>
    <avatar photoURL="https://i.pravatar.cc/256"/>
    <avatar>+11</avatar>
</div>
```

All sizes
```vue
<div class="padding-small">
    <avatar photoURL="https://i.pravatar.cc/256" size="xxlarge"/>
    <avatar photoURL="https://i.pravatar.cc/256" size="xlarge"/>
    <avatar photoURL="https://i.pravatar.cc/256" size="large"/>
    <avatar photoURL="https://i.pravatar.cc/256" size="medium"/>
    <avatar photoURL="https://i.pravatar.cc/256"/>
    <avatar photoURL="https://i.pravatar.cc/256" size="small"/>
    <avatar photoURL="https://i.pravatar.cc/256" size="xsmall"/>
</div>
<div class="padding-small">
    <avatar size="xxlarge"/>
    <avatar size="xlarge"/>
    <avatar size="large"/>
    <avatar size="medium"/>
    <avatar/>
    <avatar size="small"/>
    <avatar size="xsmall"/>
</div>
<div class="padding-small" style="background: var(--low-contrast)">
    <avatar photoURL="https://i.pravatar.cc/256" size="xxlarge"/>
    <avatar photoURL="https://i.pravatar.cc/256" size="xlarge"/>
    <avatar photoURL="https://i.pravatar.cc/256" size="large"/>
    <avatar photoURL="https://i.pravatar.cc/256" size="medium"/>
    <avatar photoURL="https://i.pravatar.cc/256"/>
    <avatar photoURL="https://i.pravatar.cc/256" size="small"/>
    <avatar photoURL="https://i.pravatar.cc/256" size="xsmall"/>
</div>
```

Use different fallback icon:
```vue
<div class="padding-small">
    <avatar icon="calendar"/>
</div>
</docs>
