import { render, staticRenderFns } from "./LoadingTopicList.vue?vue&type=template&id=68631a2a&scoped=true&"
import script from "./LoadingTopicList.vue?vue&type=script&lang=js&"
export * from "./LoadingTopicList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68631a2a",
  null
  
)

export default component.exports