<template>
    <div>
        <div class="container focussed">
            <div class="card depth-4 centered padding-medium">
                <div class="padding-medium">
                    <SystemMessage
                        :title="$t('is_in_maintenance')"
                        type="maintenance"
                    >
                        <p>{{ maintenanceModeMessage }}</p>
                    </SystemMessage>
                </div>
            </div>
        </div>
        <Bubbles />
    </div>
</template>

<script>
import { MAINTENANCE_MODULE_NAME } from "@web/store/maintenance/maintenance";
import { mapGetters } from "vuex";
import Bubbles from "@web/views/portal/Bubbles";

export default {
    name: "MaintenanceView",
    components: {
        Bubbles
    },
    computed: {
        ...mapGetters({
            maintenanceModeMessage: MAINTENANCE_MODULE_NAME + "maintenanceModeMessage"
        })
    }
};
</script>

<style lang="scss" scoped>
    .card {
        position: relative;
        z-index: 5;
    }
    .bubbles-container {
        .bubbles {
            transform: translate(0, 50%) !important;
        }
    }
    h3 {
        margin: 0;
        padding: 0;
    }
    .container {
        height: 95vh;
        display: flex;
        align-items: center;
    }
</style>

<style lang="scss" scoped>
    body {
        background-color: var(--lowest-contrast, #{$off-white});
    }
</style>
