<template>
    <div
        class="loader"
        v-html="require('@web/assets/splash_screen/bubble_outline.svg')"
    >
    </div>
</template>

<script>
export default {
    name: "SplashScreen",
    mounted() {
        const path = this.$el.querySelector("svg path");
        const pathLength = path.getTotalLength();
        path.style["stroke-dasharray"] = pathLength;
        path.style["stroke-dashoffset"] = pathLength;
    },
};
</script>

<style lang="scss" scoped>
.loader {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 100%;
    width: 100vw;
    height: 100vh;

    ::v-deep svg {
        max-width: 10vw;
        max-height: 10vh;

        path {
            animation: 2.5s ease-in-out forwards infinite dash;
        }

        @keyframes dash {
            90% {
                opacity: 1;
                stroke-dashoffset: 0;
            }
            100% {
                opacity: 0;
                stroke-dashoffset: 0;
            }
        }
    }
}
</style>

<docs>
```vue
<splash-screen/>
```
</docs>
