var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{class:{
        'single': _vm.multi === 1,
        'two': _vm.multi === 2,
        'three': _vm.multi > 2,
        'clickable': !_vm.editMode && !_vm.loading,
    },attrs:{"href":_vm.url,"target":"_blank"},on:{"click":_vm.interceptClick}},[(_vm.error)?_c('SystemMessage',{attrs:{"asset":"searching","title":"Image not available","text":"Image could not be found"}}):_c('DeletableWrapper',{staticClass:"attachment media-attachment",class:{ 'half': _vm.multi === 2, 'w-100': _vm.multi === 1 },attrs:{"edit-mode":_vm.editMode && !_vm.loading,"transition-name":"moveInFromRight"},on:{"delete":function($event){return _vm.$emit('delete')}}},[(_vm.multi === 1 && _vm.loading)?_c('pu-skeleton',{attrs:{"height":"30rem"}}):(_vm.isImage && _vm.multi === 1 && _vm.displayMode === _vm.DisplayMode.fillHeight)?_c('div',{staticClass:"image fill-height-container"},[_c('img',{staticClass:"blurred-background-image image-crop",attrs:{"src":_vm.url,"loading":"lazy","alt":"attachment image"},on:{"error":function($event){_vm.error = true}}}),_c('img',{staticClass:"image-fill-height",attrs:{"src":_vm.url,"loading":"lazy","alt":"attachment image"},on:{"error":function($event){_vm.error = true}}})]):(_vm.isImage && _vm.multi === 1)?_c('img',{staticClass:"image",class:{
                'image-crop': _vm.displayMode === _vm.DisplayMode.crop,
            },attrs:{"loading":"lazy","src":_vm.url,"alt":"attachment image"},on:{"error":function($event){_vm.error = true}}}):(_vm.isImage && _vm.multi > 1)?_c('div',{staticClass:"image image-attachment-div",class:{ loading: _vm.loading }},[_c('PuSkeleton',{staticClass:"skeleton",attrs:{"loading":_vm.loading || _vm.displayMode === null,"height":"100%"}},[_c('img',{attrs:{"src":_vm.url,"alt":"attachment image","loading":"lazy"}})])],1):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }