<template>
    <div
        :class="{
            'reaction-icon': true,
            'hidden': amount < 1,
        }"
        :aria-label="ariaLabel"
        :data-amount="amount"
        :v-if="amount < 1"
        @click="$emit('open', reaction)"
        v-html="reactionIcons[reaction]"
    ></div>
</template>

<script>
import reactionIcons from "@web/components/reaction/reaction-icons";

export default {
    name: "ReactionIcon",
    props: {
        reaction: { type: String },
        amount: { type: Number, default: 0 },
    },
    data() {
        return {
            reactionIcons: reactionIcons
        };
    },
    computed: {
        ariaLabel() {
            return this.$t(`reaction_${this.reaction}`) + " " + this.$t("count") + ": " + this.amount.toString();
        }
    }
};
</script>

<style lang="scss" scoped>
.content-footer {
    .reaction-icon {
        border-color: var(--background, #{$white});
    }
}

.reaction-icon {
    border: 2px solid var(--low-contrast, #{$light-grey});
    border-radius: 50%;
    width: 22px;
    cursor: pointer;
    transition: .2s ease;
    position: relative;

    &:after{
        position: absolute;
        pointer-events: none;
        left: 50%;
        opacity: 0;
        transform: translate(-50%, 20%);
        transition: transform .3s $ease-in-out-fast-slow, opacity .3s ease;
        top: -2rem;
        padding: 0.25rem 0.5rem;
        border-radius: 0.25rem;
        font-size: 0.75rem;
        font-weight: bold;
        white-space: nowrap;
        background: var(--highest-contrast, #{$dark-grey});
        color: var(--background, #{$white});
        box-shadow: $depth-3;
        content: attr(data-amount);
    }

    &:hover, &:focus {
        transform: scale(1.05);
        z-index: 2;

        &:after{
            opacity: 1;
            transform: translate(-50%, 0);
        }
    }

    &::v-deep {
        svg {
            display: block;
        }
    }
}
</style>

<docs>
```vue
<reaction-icon reaction="clap"/>
<reaction-icon reaction="dislike" :amount="10"/>
<reaction-icon reaction="like" :amount="100"/>
<reaction-icon reaction="love" :amount="1000"/>
<reaction-icon reaction="smile" :amount="10000"/>
```
</docs>
