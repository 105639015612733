import { v4 as uuidV4 } from "uuid";
import {
    Attachment,
    AttachmentType,
    SignedStorageUrls
} from "@backend/attachment/types";
import { EntityWithAttachmentReference } from "@web/services/attachments";
import {
    attachmentService,
    sharedContentService
} from "@web/services";
import {
    AnySharedContentData,
    SharedContent
} from "@backend/attachment/shared-content/types";
import { ScaledImageSize } from "@backend/common/image-resizing/types";
import {
    blobToBase64,
    blobToFile
} from "@web/services/file-upload";
import { compressImage } from "@web/lib/image-compression";
import { SharedContentRefData } from "@web/services/shared-content";
import { AttachmentCounts } from "@backend/entity/types";

export class UnsupportedImageSize extends Error {
    constructor(size: ScaledImageSize) {
        super(`image size ${size} is not supported for this entity!`);
    }
}

export class FileSizeExceeded extends Error {
    constructor(fileName: string) {
        super(`${fileName} exceeds the maximum file size of ${AttachmentManager.MAX_FILE_SIZE_MB}`);
    }
}

export class AttachmentCountExceeded extends Error {
    constructor() {
        super(
            `the maximum number of attachments (${AttachmentManager.MAX_FILE_COUNT}) has been exceeded`
        );
    }
}

export abstract class AttachmentReference {
    readonly localUid: string;

    protected constructor(readonly name: string, readonly type: AttachmentType) {
        this.localUid = uuidV4();
    }

    public abstract getUrl(size: ScaledImageSize): string;
}

class UnpersistedAttachmentReference extends AttachmentReference {
    constructor(private readonly url: string, readonly file: File, readonly type: AttachmentType) {
        super(file.name, type);
    }

    getUrl(): string {
        return this.url;
    }
}

class PersistedAttachmentReference extends AttachmentReference {
    constructor(
        private readonly urls: SignedStorageUrls,
        readonly uid: string,
        readonly name: string,
        readonly type: AttachmentType,
    ) {
        super(name, type);
    }

    getUrl(size: ScaledImageSize): string {
        if (this.urls.resized == null) {
            return this.urls.original;
        }
        const url = this.urls.resized[size];
        if (url === undefined) {
            throw new UnsupportedImageSize(size);
        }
        return url;
    }
}

export class LoadingAttachmentReference extends AttachmentReference {
    constructor(readonly type: AttachmentType) {
        super("", type);
    }

    getUrl(size: ScaledImageSize): string {
        throw new Error("can not retrieve URL for a loading attachment");
    }
}

interface LoadParams {
    entityWithAttachmentReference: EntityWithAttachmentReference;
    onError: (error: Error) => undefined;
}

interface ConstructorParams {
    currentSharedContent: Promise<SharedContent[] | undefined>;
    currentAttachments: Promise<Attachment[] | undefined>;
    expectedAttachmentCounts: AttachmentCounts;
    expectedSharedContentCount: number;
}

export class AttachmentManager {
    public static readonly MAX_FILE_COUNT: number = 30;
    public static readonly MAX_SHARED_CONTENT_COUNT: number = 1;
    public static readonly MAX_FILE_SIZE_MB = 10;

    private initialAttachments: PersistedAttachmentReference[] = [];
    private _attachments!: AttachmentReference[];
    private initialSharedContent: SharedContentRefData[] = [];
    private _sharedContent: SharedContentRefData[] = [];

    public constructor(params?: ConstructorParams) {
        if (params) {
            this._attachments = [
                ...Array.from(Array(params.expectedAttachmentCounts.file))
                    .map(() => new LoadingAttachmentReference(AttachmentType.file)),
                ...Array.from(Array(params.expectedAttachmentCounts.media))
                    .map(() => new LoadingAttachmentReference(AttachmentType.image)),
            ];
            Promise.all([params.currentAttachments, params.currentSharedContent])
                .then(([currentAttachmentsPromise, currentSharedContentPromise]) =>
                    this.initialize(currentAttachmentsPromise ?? [], currentSharedContentPromise ?? [])
                );
        } else {
            this.initialize([], []);
        }
    }

    public addSharedContent(data: AnySharedContentData): void {
        if (this._sharedContent.length === AttachmentManager.MAX_SHARED_CONTENT_COUNT) {
            this.sharedContent.shift();
        }
        this._sharedContent.push({ uid: uuidV4(), ...data });
    }

    public removeSharedContent(sharedContentToRemove: SharedContentRefData): void {
        this._sharedContent = this._sharedContent.filter(sharedContentReference => sharedContentReference.uid !== sharedContentToRemove.uid);
    }

    public addFiles(fileList: File[]): Promise<AttachmentReference[] | void> {
        if (this.attachments.length + fileList.length > AttachmentManager.MAX_FILE_COUNT) {
            throw new AttachmentCountExceeded();
        }
        return Promise.all(fileList.map((file) => this.add(file)));
    }

    public async add(file: File): Promise<AttachmentReference> {
        const maxFileSizeByte = AttachmentManager.MAX_FILE_SIZE_MB * 1000000;
        if (file.size >= maxFileSizeByte) {
            throw new FileSizeExceeded(file.name);
        }

        const attachmentType = getAttachmentType(file);
        let base64 = "";
        if (attachmentType === AttachmentType.image) {
            const compressedImage = await compressImage(file);
            file = blobToFile(compressedImage, uuidV4());
            base64 = await blobToBase64(file);
        }
        const newAttachmentUpload = new UnpersistedAttachmentReference(base64, file, attachmentType);
        this._attachments.push(newAttachmentUpload);
        return newAttachmentUpload;
    }

    public async remove(thisAttachment: AttachmentReference): Promise<void> {
        const attachmentIndex = this._attachments
            .findIndex((thatAttachment) => thisAttachment.localUid === thatAttachment.localUid);
        if (attachmentIndex === -1) {
            return;
        }
        this._attachments.splice(attachmentIndex, 1);
    }

    public async save(
        entityWithAttachmentReference: EntityWithAttachmentReference,
        creatorUid: string,
        resetWhenDone: boolean = false,
    ): Promise<void> {
        const attachmentsQueuedForCreation = this.attachmentsQueuedForCreation;
        const attachmentsQueuedForDeletion = this.attachmentsQueuedForDeletion;
        const removedSharedContent = this.initialSharedContent.filter(
            (sharedContent) => !this.sharedContent.includes(sharedContent),
        );
        const addedSharedContent = this.sharedContent.filter(
            (sharedContent) => !this.initialSharedContent.includes(sharedContent),
        );

        if (
            !attachmentsQueuedForCreation.length &&
            !attachmentsQueuedForDeletion.length &&
            !removedSharedContent.length &&
            !addedSharedContent.length
        ) return;

        await Promise.all([
            ...attachmentsQueuedForDeletion.map(
                attachment => attachmentService.delete(entityWithAttachmentReference, attachment.uid) as Promise<unknown>,
            ),
            attachmentService.createMany(
                entityWithAttachmentReference,
                attachmentsQueuedForCreation.map(attachment => attachment.file),
                creatorUid,
            ),
        ]);

        await Promise.all([
            ...removedSharedContent.map(
                sharedContentRef => sharedContentService.deleteSharedContent(
                    entityWithAttachmentReference,
                    sharedContentRef,
                ),
            ),
            ...addedSharedContent.map(
                sharedContentRef => sharedContentService.addSharedContent(
                    entityWithAttachmentReference,
                    sharedContentRef,
                ),
            ),
        ]);

        if (resetWhenDone) {
            this.reset();
        } else {
            const [newAttachments, newSharedContent] = await Promise.all([
                attachmentService.list(entityWithAttachmentReference),
                sharedContentService.getSharedContents(entityWithAttachmentReference),
            ]);
            this.initialize(newAttachments, newSharedContent);
        }
    }

    public static load({ entityWithAttachmentReference, onError }: LoadParams): AttachmentManager {
        return new AttachmentManager({
            currentSharedContent: sharedContentService.getSharedContents(entityWithAttachmentReference).catch(onError),
            currentAttachments: attachmentService.list(entityWithAttachmentReference).catch(onError),
            expectedSharedContentCount: entityWithAttachmentReference.entity.externalContentCount || 0,
            expectedAttachmentCounts:
                entityWithAttachmentReference.entity.attachmentCounts || { file: 0, media: 0, total: 0 },
        });
    }

    public reset(): void {
        this._attachments = [...this.initialAttachments];
        this._sharedContent = [...this.initialSharedContent];
    }

    public get sharedContent(): SharedContentRefData[] {
        return this._sharedContent;
    }

    public get attachments(): AttachmentReference[] {
        return this._attachments;
    }

    private get attachmentsQueuedForDeletion(): PersistedAttachmentReference[] {
        const attachmentUidSet = new Set(
            this._attachments
                .filter(attachment => attachment instanceof PersistedAttachmentReference)
                .map(attachment => (attachment as PersistedAttachmentReference).uid),
        );
        return this.initialAttachments.filter(attachment => !attachmentUidSet.has(attachment.uid));
    }

    private get attachmentsQueuedForCreation(): UnpersistedAttachmentReference[] {
        return this._attachments.filter(
            attachment => attachment instanceof UnpersistedAttachmentReference,
        ) as UnpersistedAttachmentReference[];
    }

    private initialize(attachments: Attachment[], currentSharedContent: SharedContent[]): void {
        this.initialAttachments = attachments.map(attachment => new PersistedAttachmentReference(
            attachment.urls,
            attachment.uid,
            attachment.fileName,
            attachment.attachmentType,
        ));
        this._attachments = [...this.initialAttachments];
        this.initialSharedContent = [...currentSharedContent];
        this._sharedContent = [...this.initialSharedContent];
    }

    public get isEmpty(): boolean {
        return this._attachments.length === 0 && this._sharedContent.length === 0;
    }
}

function getAttachmentType(file: File): AttachmentType {
    if (file.type.startsWith("image/")) {
        return AttachmentType.image;
    }
    return AttachmentType.file;
}
