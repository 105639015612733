<template>
    <div
        ref="image"
        class="image"
        :style="imageStyling"
    >
        <div v-if="cropperActive">
            <vue-cropper
                ref="cropper"
                :aspect-ratio="aspectRatio"
                :src="imageUrl"
                :container-style="{'max-height': doubledImageHeight}"
                :zoomable="false"
                :auto-crop-area="1"
                :view-mode="1"
            />
            <div class="actions">
                <everest-button
                    type="primary"
                    @click.native="onConfirm"
                >
                    {{ $t("button_ok") }}
                </everest-button>
                <everest-button
                    type="link"
                    @click.native="onCancel"
                >
                    {{ $t("cancel") }}
                </everest-button>
            </div>
        </div>
        <hoverlay
            v-else-if="enableHoverlay"
            :force-overlay-state="imageUploadOptionsOpened || loading ? true : undefined"
        >
            <template #on-hover>
                <div class="center-container">
                    <image-upload-button
                        :busy="loading"
                        :cropper-aspect-ratio="aspectRatio"
                        :cropper-rounded="cropperRounded"
                        :text="uploadButtonText"
                        :as-modal="cropperModal"
                        :upload-image="uploadFunction"
                        :delete-image="imageUrl !== defaultImagePath ? onDelete : undefined"
                        @change="onFileChange"
                        @open-upload-options="imageUploadOptionsOpened = true"
                        @close-upload-options="imageUploadOptionsOpened = false"
                    />
                </div>
            </template>
        </hoverlay>
    </div>
</template>

<script>
import Hoverlay from "@/components/Hoverlay";
import ImageUploadButton from "@/components/image-upload/ImageUploadButton";
import { blobToBase64 } from "@web/services/file-upload";
import VueCropper from "vue-cropperjs/VueCropper";
import "cropperjs/dist/cropper.css";
import EverestButton from "@web/components/Button";
import { mapGetters } from "vuex";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import { INTRANET } from "@web/store/intranet/getters";

export default {
    name: "EditableImage",
    components: {
        EverestButton,
        Hoverlay,
        ImageUploadButton,
        VueCropper,
    },
    props: {
        initialImage: { type: String },
        uploadFunction: { type: Function, required: true },
        deleteFunction: { type: Function, required: true },
        defaultImagePath: { type: String, required: true },
        enableHoverlay: { type: Boolean, default: true },
        aspectRatio: { type: Number, default: 1 },
        cropperRounded: { type: Boolean, default: false },
        uploadButtonText: { type: String, default: "Upload image" },
        cropperModal: { type: Boolean, default: true },
    },
    data() {
        return {
            imageUrl: this.initialImage || this.defaultImagePath,
            imageUploadOptionsOpened: false,
            loading: false,
            imageHeight: "20vh",
            cropperActive: false,
        };
    },
    watch: {
        cropperActive(newCropperActive) {
            this.$emit("cropper-state-changed", newCropperActive);
        },
    },
    computed: {
        ...mapGetters({
            intranet: INTRANET_MODULE_NAME + INTRANET,
        }),
        imageStyling() {
            return {
                "background-image": this.cropperActive ? "" : `url(${this.imageUrl})`,
                "height": this.cropperActive ? "unset" : this.imageHeight,
            };
        },
        doubledImageHeight() {
            const heightNumber = parseInt(this.imageHeight.substring(0, this.imageHeight.length - 2));
            return `${heightNumber * 2}px`;
        },
    },
    created() {
        window.addEventListener("resize", this.updateImageHeight);
    },
    destroyed() {
        window.removeEventListener("resize", this.updateImageHeight);
    },
    mounted() {
        this.updateImageHeight();
    },
    methods: {
        async onFileChange(blob) {
            this.loading = true;
            this.imageUrl = await blobToBase64(blob);
            this.cropperActive = true;
        },
        async onUpload(blob) {
            this.loading = true;
            this.imageUrl = await this.uploadFunction(blob);
            this.loading = false;
        },
        async onDelete() {
            this.loading = true;
            await this.deleteFunction();
            this.imageUrl = this.defaultImagePath;
            this.loading = false;
        },
        updateImageHeight() {
            this.imageHeight = this.$refs.image ? `${this.$refs.image.clientWidth / this.aspectRatio}px` : "20vh";
        },
        onCancel() {
            this.cropperActive = false;
            this.loading = false;
            this.imageUrl = this.defaultImagePath;
        },
        onConfirm() {
            // max image size: pixel count x channel count
            // 1600 x 1600 x 3 = 7.680.000 Byte
            // 7.680.000 / (1024 * 1024) = ~7.32MB
            this.$refs.cropper.getCroppedCanvas({
                maxWidth: 1600,
                maxHeight: 1600,
                fillColor: this.intranet.colors.primaryColor,
            }).toBlob(async(blob) => {
                await this.onUpload(blob);
                this.imageUrl = await blobToBase64(blob);
            });

            this.cropperActive = false;
            this.loading = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.info-text {
    align-items: center;
    margin-bottom: 0;
}

.actions {
    margin-top: 1rem;
    width: 100%;
    display: flex;
    justify-content: flex-end;
}

.image {
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}
</style>
