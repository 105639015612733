




















































































import { analytics } from "@web/analytics";
import Icon from "@web/components/Icon.vue";
import Modal from "@web/components/modals/Modal.vue";
import { getGlobalConfiguration } from "@web/global-config";
import {
    INTRANET,
    IS_ADMIN
} from "@web/store/intranet/getters";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import {
    CREATE_DESTINATION,
    DELETE_DESTINATION,
    FETCH_DESTINATIONS,
    RESET_DESTINATIONS
} from "@web/store/launchpad/actions";
import {
    GET_ERROR,
    IS_EMPTY
} from "@web/store/launchpad/getters";
import { LAUNCHPAD_MODULE_NAME } from "@web/store/launchpad/launchpad";
import Destination from "@web/views/launchpad/Destination.vue";
import Vue from "vue";
import {
    mapActions,
    mapGetters
} from "vuex";

export default Vue.extend<any, any, any, any>({
    components: {
        Modal,
        Destination,
        Icon,
    },
    computed: {
        ...mapGetters(INTRANET_MODULE_NAME, [INTRANET]),
        ...mapGetters({
            isAdmin: INTRANET_MODULE_NAME + IS_ADMIN,
            hasError: LAUNCHPAD_MODULE_NAME + GET_ERROR,
            isEmpty: LAUNCHPAD_MODULE_NAME + IS_EMPTY,
        }),
        destinations() {
            return this.$store.state.launchpad.destinations;
        },
        hasMore() {
            return this.$store.state.launchpad.hasMore;
        },
        fetching() {
            return this.$store.state.launchpad.fetching;
        },
    },
    methods: {
        ...mapActions({
            loadDestinations: LAUNCHPAD_MODULE_NAME + FETCH_DESTINATIONS,
            createDestination: LAUNCHPAD_MODULE_NAME + CREATE_DESTINATION,
            deleteDestination: LAUNCHPAD_MODULE_NAME + DELETE_DESTINATION,
            resetDestinations: LAUNCHPAD_MODULE_NAME + RESET_DESTINATIONS,
        }),
        showLaunchpad() {
            analytics.log(getGlobalConfiguration().analytics_event_name_launchpad_view_opened);
            this.$modal.show("launchpad");
        },
        hideLaunchpad() {
            this.$modal.hide("launchpad");
        },
        openAdministration() {
            const launchpadAdminRoute = `/intranet/${this.$store.state.intranet.intranet.uid}/admin/launchpad`;
            if (this.$router.currentRoute.fullPath !== launchpadAdminRoute) {
                this.$router.push(launchpadAdminRoute);
            }
            this.hideLaunchpad();
        },
    },
    created() {
        this.resetDestinations()
            .then(() => this.loadDestinations())
            .catch(() => this.$notify({
                group: "app",
                type: "error",
                text: "An error occured while loading destinations.",
                duration: 5000,
            }));
    }
});
