
export enum ScaledImageSize {
    s = "s", m = "m", l = "l"
}

export enum SupportedTargetContentTypes {
    webp = "image/webp"
}

export interface ImageResizingRequest {
    intranetUid: string,
    originalImageIntranetStoragePath: string,
    requestedSizes: ScaledImageSize[],
    requestedContentType?: SupportedTargetContentTypes,
}

export type SignedResizedImageUrls = {
    [key in ScaledImageSize]?: string
}
