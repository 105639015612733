<template>
    <date-picker
        v-bind="mergedProps"
        v-on="$listeners"
    >
        <template #default="{ inputValue, inputEvents }">
            <span class="input-ref">
                <input
                    type="text"
                    autocomplete="off"
                    :value="inputValue"
                    :placeholder="placeholder"
                    :disabled="disabled"
                    v-on="inputEvents"
                />
                <Icon
                    class="calendar-icon"
                    name="calendar"
                />
            </span>
        </template>
    </date-picker>
</template>

<script>
import DatePicker from "v-calendar/lib/components/date-picker.umd";
import defaultLocales from "v-calendar/src/utils/defaults/locales";
import { rootMixin } from "v-calendar/src/utils/mixins/root";

export default {
    name: "DateInput",
    components: {
        DatePicker,
    },
    inheritAttrs: false,
    props: {
        ...rootMixin.props,
        ...DatePicker.props,
        /** when set, disables the input */
        disabled: Boolean,
    },
    computed: {
        detectedLocale() {
            if (this.locale) return this.locale;
            if (!navigator.languages) {
                return "en";
            }
            const primaryLocale = navigator.languages[0];
            if (primaryLocale.startsWith("de")) {
                return "de";
            }
            if (primaryLocale.startsWith("en")) {
                return primaryLocale;
            }
            return "en";
        },
        mergedProps() {
            /* leave color out as it does not inherit well */
            const { popover, color, ...$props } = this.$props;
            return {
                locale: this.detectedLocale,
                popover: {
                    visibility: "focus",
                    placement: "bottom-end",
                    ...popover,
                },
                ...$props,
                // these are enforced
                updateOnInput: true,
                formatInput: true,
                color: "green",
            };
        },
        placeholder() {
            const defaultLocale = defaultLocales[this.detectedLocale] || defaultLocales["en-GB"];
            return defaultLocale.masks.L.toLowerCase();
        },
    },
};
</script>

<style lang="scss" scoped>
.input-ref {
    /* helps to position the popover correctly */
    display: inline-block;
    /* just like inputs */
    width: 100%;
    /* positions icon inside */
    position: relative;
}

.calendar-icon {
    position: absolute;
    right: 1ex;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
}

input[type=text] {
    padding-right: calc(var(--icon-size, 24px) + 2ex);
}
</style>

<docs>
A prestyled version of the `date-picker` component in [v-calendar](https://vcalendar.io/).
It applies all of its props.

Date with Browser locale between `de` and `en` and derivates `en-GB` or `en-US`:
```vue
<template>
    <div>
        <div>
            <date-input v-model="value" mode="date"/>
        </div>
        <div>{{ value }}</div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                value: "",
            };
        },
    };
</script>
```

Overlapping input:
```vue
<div style="width: 100px;">
    <date-input value=""/>
</div>
```

Date with locale `en` (same as `en-US`):
```vue
<date-input value="" locale="en" mode="date"/>
```

Date with locale `en-gb`:
```vue
<date-input value="" locale="en-gb" mode="date"/>
```

----
**Unsupported yet**

DateTime is not perfect yet!
```vue
<date-input value="" mode="dateTime"/>
```

Time does not work well yet!
```vue
<date-input value="" mode="time"/>
```
</docs>
