<template>
    <router-link
        :to="{name:'team-profile', params: {teamUid: uid}}"
        class="hover-underline"
    >
        <!-- @slot Content wrapped by the link -->
        <slot></slot>
    </router-link>
</template>

<script>
export default {
    name: "TeamLink",
    inheritAttrs: false,
    props: {
        /** A user's uid. Meets with the `EverestUser` interface. */
        uid: String,
    },
};
</script>

<style scoped>

</style>

<docs>
Use with `Team`:
```vue
<team-link v-bind="{uid: 'team uid'}">link to team profile</team-link>
```

Use with teamUid
```vue
<team-link uid="team uid">link to team profile</team-link>
```
</docs>
