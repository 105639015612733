export function secondsToDate(seconds: number): Date {
    return new Date(seconds * 1000);
}

export function dateToTimestamp(date: Date): { _seconds: number, _nanoseconds: number } {
    const milliseconds = date.getTime();
    const millisecondsString = milliseconds.toString();
    const secondsString = millisecondsString.substr(0, millisecondsString.length - 3);
    const _seconds = parseInt(secondsString, 10);
    return {
        _seconds,
        _nanoseconds: (milliseconds % 1000) * 1_000_000,
    };
}

/**
 * Calculates the milliseconds of a Firestore `timestamp` with higher precision by considering its nanoseconds.
 * Types have been removed from imports because of dependency issues on Bamboo tests:
 * { Timestamp } from "@backend/entity/types"
 * { Timestamp as FirestoreTimestamp } from "@google-cloud/firestore"
 * timestamp: Timestamp | FirestoreTimestamp
 */
export function timestampToMillis(timestamp: { _seconds: number, _nanoseconds: number } | {seconds: number, nanoseconds : number}): number {
    // Cut off precision by string to avoid weird rounding errors because of IEEE floating point standard implementation within javascript (https://docs.oracle.com/cd/E19957-01/806-3568/ncg_goldberg.html).
    // `new FirestoreTimestamp(timestamp._seconds, timestamp._nanoseconds).toMillis()` still has rounding errors because it adds a division by 1e6.
    const nano = "_nanoseconds" in timestamp ? timestamp._nanoseconds : timestamp.nanoseconds;
    const sec = "_seconds" in timestamp ? timestamp._seconds : timestamp.seconds;
    const millisecondsString = nano.toString().padStart(9, "0").substr(0, 3);
    const milliseconds = parseInt(millisecondsString, 10);
    return sec * 1000 + milliseconds;
}
