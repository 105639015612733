import { Ping } from "@backend/common/notification/inbox/types";
import { ActivitySocialNotification } from "@backend/common/notification/activity-types";
import { BaseUserProfile } from "@backend/user-profile/types";
import { VuexRootState } from "@web/store";
import { Module } from "vuex";
import {
    LOAD_MORE,
    MARK_AS_READ,
    SUBSCRIBE,
    UNSUBSCRIBE
} from "@web/store/social-notifications/actions";
import firebase from "firebase";
import QueryDocumentSnapshot = firebase.firestore.QueryDocumentSnapshot;
import {
    profileService,
    socialNotificationService
} from "@web/services";
import {
    GET_SELECTED_FOR_INTERACTION,
    GET_SOCIAL_NOTIFICATIONS,
    IS_LOADING
} from "@web/store/social-notifications/getters";
import { SET_SELECTED_FOR_INTERACTION } from "@web/store/social-notifications/mutations";
import { analytics } from "@web/analytics";
import { getGlobalConfiguration } from "@web/global-config";

export type SocialNotification = Ping<ActivitySocialNotification> & { creator: BaseUserProfile, id: string };

export interface SocialNotificationModuleState {
    socialNotifications: SocialNotification[];
    loading: boolean;
    unsubscribeHandler?: () => void;
    startNextPageAt?: QueryDocumentSnapshot<Ping<ActivitySocialNotification>>;
    selectedForInteraction: SocialNotification | null;
}

export const SOCIAL_NOTIFICATION_MODULE_NAME = "socialNotification/";

function getDefaultState(): SocialNotificationModuleState {
    return {
        socialNotifications: [],
        loading: false,
        selectedForInteraction: null,
    };
}

const PAGE_SIZE = 10;

const SET_UNSUBSCRIBE_HANDLER = "setUnsubscribeHandler";
const SET_NOTIFICATIONS = "setNotifications";
const SET_LOADING = "setLoading";
const SET_START_NEXT_PAGE_AT = "setStartNextPageAt";
const PREPEND_NOTIFICATION = "prependNotification";
const APPEND_NOTIFICATIONS = "appendNotifications";
const RESET_STATE = "resetState";

export const SOCIAL_NOTIFICATION_MODULE = {
    strict: true,
    namespaced: true,
    state: getDefaultState(),
    getters: {
        [GET_SOCIAL_NOTIFICATIONS](state): SocialNotification[] {
            return state.socialNotifications;
        },
        [IS_LOADING](state): boolean {
            return state.loading;
        },
        [GET_SELECTED_FOR_INTERACTION](state): SocialNotification | null {
            return state.selectedForInteraction;
        }
    },
    mutations: {
        [SET_NOTIFICATIONS](state, notifications: SocialNotification[]) {
            state.socialNotifications = notifications;
        },
        [SET_UNSUBSCRIBE_HANDLER](state, handler: () => void) {
            state.unsubscribeHandler = handler;
        },
        [SET_LOADING](state, loading: boolean) {
            state.loading = loading;
        },
        [SET_START_NEXT_PAGE_AT](state, startNextPageAt: QueryDocumentSnapshot<Ping<ActivitySocialNotification>>) {
            state.startNextPageAt = startNextPageAt;
        },
        [APPEND_NOTIFICATIONS](state, notifications: SocialNotification[]) {
            state.socialNotifications = state.socialNotifications.concat(notifications);
        },
        [PREPEND_NOTIFICATION](state, notification: SocialNotification) {
            state.socialNotifications.unshift(notification);
        },
        [RESET_STATE](state) {
            Object.assign(state, getDefaultState());
        },
        [SET_SELECTED_FOR_INTERACTION](state, notification: SocialNotification | null) {
            state.selectedForInteraction = notification;
        }
    },
    actions: {
        [SUBSCRIBE]({ state, commit, dispatch, rootState }) {
            const unsubscribeHandler = socialNotificationService
                .getInboxQuery({
                    intranetUid: rootState.intranet.intranet!.uid,
                    userUid: rootState.auth.currentUser!.uid,
                    limit: 1,
                })
                .onSnapshot(
                    async(snapshot) => {
                        if (snapshot.empty) {
                            return;
                        }

                        // initial load
                        if (state.socialNotifications.length === 0) {
                            await dispatch(LOAD_MORE);
                            return;
                        }

                        // first item was set to unread and removed from the list
                        if (state.socialNotifications[0].id === snapshot.docs[0].id) {
                            return;
                        }

                        const notification = await parseSocialNotificationDoc(
                            snapshot.docs[0],
                            rootState.intranet.intranet!.uid,
                        );
                        commit(PREPEND_NOTIFICATION, notification);
                    },
                );
            commit(SET_UNSUBSCRIBE_HANDLER, unsubscribeHandler);
        },
        [UNSUBSCRIBE]({ state, commit }) {
            if (state.unsubscribeHandler) {
                state.unsubscribeHandler();
            }
            commit(RESET_STATE, []);
        },
        async [LOAD_MORE]({ state, commit, rootState }) {
            if (state.socialNotifications.length !== 0 && !state.startNextPageAt) {
                return;
            }

            commit(SET_LOADING, true);
            try {
                const snapshot = await socialNotificationService
                    .getInboxQuery({
                        intranetUid: rootState.intranet.intranet!.uid,
                        userUid: rootState.auth.currentUser!.uid,
                        limit: PAGE_SIZE + 1,
                        startAt: state.startNextPageAt,
                    }).get();

                let docs = snapshot.docs;
                if (docs.length > PAGE_SIZE) {
                    commit(SET_START_NEXT_PAGE_AT, docs[docs.length - 1]);
                    docs = docs.slice(0, PAGE_SIZE);
                } else {
                    commit(SET_START_NEXT_PAGE_AT, undefined);
                }
                commit(
                    APPEND_NOTIFICATIONS,
                    await Promise.all(
                        docs.map(
                            (doc) =>
                                parseSocialNotificationDoc(doc, rootState.intranet.intranet!.uid)
                        )
                    )
                );
            } finally {
                commit(SET_LOADING, false);
            }
        },
        async [MARK_AS_READ]({ state, commit, rootState }, notificationUid: string) {
            commit(
                SET_NOTIFICATIONS,
                state.socialNotifications.filter(notification => notification.id !== notificationUid),
            );
            await socialNotificationService.markAsRead(
                rootState.intranet.intranet!.uid,
                rootState.auth.currentUser!.uid,
                notificationUid,
            ).then(() => analytics.log(getGlobalConfiguration().analytics_event_name_social_notification_read));
        }
    },
} as Module<SocialNotificationModuleState, VuexRootState>;

async function parseSocialNotificationDoc(
    doc: QueryDocumentSnapshot<Ping<ActivitySocialNotification>>,
    intranetUid: string,
): Promise<SocialNotification> {
    const data = doc.data();
    return {
        ...data,
        id: doc.id,
        creator: await profileService.getBaseUserProfile(intranetUid, data.activity.creatorUid)
    };
}
