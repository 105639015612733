<template>
    <div
        class="react-button"
        :class="{'react-in-iframe': inIframe, 'button': hasButtonStyle}"
        @mouseenter="handleHover"
        @mouseleave="handleLeave"
    >
        <transition name="overlay">
            <div
                v-if="open"
                :class="{
                    overlay: true,
                    center: centerOverlay
                }"
            >
                <div
                    v-for="reaction in reactions"
                    :key="reaction"
                    class="reaction-wrapper"
                    :class="reaction"
                >
                    <div
                        class="reaction-icon"
                        @click="handleSelect(reaction)"
                        v-html="reactionIcons[reaction]"
                    ></div>
                </div>
                <div class="arrow-shadow-blocker"></div>
                <div class="arrow"></div>
                <div class="hover-stop-blocker"></div>
            </div>
        </transition>
        <div
            class="trigger"
            @click="handleClick"
        >
            <transition name="scale-in">
                <Icon
                    v-if="!selected"
                    name="smile"
                    class="animated-icon"
                />
            </transition>
            <template v-for="reaction in reactions">
                <transition
                    :key="reaction"
                    name="scale-in"
                >
                    <div
                        v-if="reaction === selected"
                        class="reaction-icon animated-icon"
                        v-html="reactionIcons[selected]"
                    ></div>
                </transition>
            </template>
            <span
                class="title"
                :class="{
                    [selected]: selected !== null
                }"
            >{{ selected === null ? $t("react") : $t(`reaction_${selected}`) }}</span>
        </div>
    </div>
</template>

<script>
import reactionIcons from "@web/components/reaction/reaction-icons";
import { inIframe } from "@web/lib/is-in-iframe";

export default {
    name: "ReactionButton",
    components: {},
    props: {
        mode: {
            type: String,
            default: "inline"
        },
        initSelection: {
            type: String,
            default: null,
        },
        centerOverlay: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            open: false,
            selected: null,
            reactions: [
                "like",
                "clap",
                "love",
                "smile",
                "dislike",
            ],
            reactionIcons,
            debounceId: null,
            inIframe: inIframe(),
        };
    },
    computed: {
        hasButtonStyle() {
            return this.mode === "button";
        }
    },
    created() {
        this.selected = this.initSelection;
    },
    methods: {
        handleSelect(r) {
            if (this.selected === r) {
                this.selected = null;
                this.$emit("unreact");
            } else {
                this.selected = r;
                this.$emit("react", r);
            }
            this.handleClose();
        },
        handleClick() {
            if (this.selected === null) {
                this.handleSelect("like");
            } else {
                this.handleSelect(this.selected);
            }
        },
        handleHover() {
            if (this.debounceId !== null) {
                clearTimeout(this.debounceId);
                this.debounceId = null;
            }
            this.open = true;
        },
        handleLeave() {
            if (this.debounceId === null) {
                this.debounceId = setTimeout(() => this.handleClose(), 200);
            }
        },
        handleClose() {
            this.open = false;
        },
    },
};
</script>

<style lang="scss" scoped>
$arrow-size: 12px;
$arrow-small-screen-offset: 20%;

.trigger {
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;

    .reaction-icon {
        width: 22px;
        height: 22px;
    }

    .icon {
        --icon-size: 22px;
        --icon-color: var(--highest-contrast, #{$dark-grey});
    }

    .title {
        padding-left: .3rem;
        font-weight: 600;
        font-size: .7rem;
        margin-left: 22px;

        &.like {
            color: #00C270;
        }

        &.clap {
            color: #2684FF;
        }

        &.love {
            color: #EB2226;
        }

        &.smile {
            color: #EFAC00;
        }

        &.dislike {
            color: #2F92CA;
        }
    }

    .animated-icon {
        position: absolute;
    }
}

.reaction-icon {
    width: 32px;
    height: 32px;
    cursor: pointer;
    transition: .2s ease;
}

.react-button {
    position: relative;

    &.button {
        background: var(--background, #{$white});
        color: var(--foreground, #{$darkest-grey});
    }

    .overlay {
        display: flex;
        background: var(--background, #{$white});
        cursor: pointer;
        box-shadow: $depth-3;
        padding: .8rem .4rem;
        border-radius: var(--border-radius);
        position: absolute;
        left: 11px;
        top: -$arrow-size;
        transform: translate(-50%, -100%);
        z-index: 500;

        &.center {
            left: 50%;
        }

        @media screen and (max-width: $breakpoint-tv) {
            &:not(.center) {
                transform: translate(-$arrow-small-screen-offset, -100%);
            }
        }

        .reaction-wrapper{
            &:hover {
                z-index: 1;
            }
        }

        .reaction-icon {
            margin: 0 .4rem;

            &:hover {
                transform: scale(1.5);
            }
        }
    }

    .arrow {
        height: $arrow-size;
        width: $arrow-size;
        background-color: var(--background, #{$white});
        position: absolute;
        transform: rotate(45deg);
        top: calc(100% - #{$arrow-size} / 2);
        left: calc(50% - #{$arrow-size} / 2);
        @media screen and (max-width: $breakpoint-tv) {
            *:not(.center) {
                left: calc(#{$arrow-small-screen-offset} - #{$arrow-size} / 2);
            }
        }
        z-index: -2;
        box-shadow: .2rem 0.2rem 0.75rem rgba(var(--shadows, $shadows), $opacity-twenty);
    }

    .arrow-shadow-blocker {
        position: absolute;
        width: 30px;
        height: 30px;
        background-color: var(--background, #{$white});
        bottom: 0;
        left: calc(50% - 15px);
        @media screen and (max-width: $breakpoint-tv) {
            left: calc(#{$arrow-small-screen-offset} - 15px);
        }
        z-index: -1;
    }

    .hover-stop-blocker {
        position: absolute;
        background-color: transparent;
        width: 100%;
        height: $arrow-size;
        top: 100%;
        left: 0;
    }

    /* Styles for iframes (HALO-3959) */
    &.react-in-iframe {
        .overlay {
            transform: translate(-10%, -100%);
        }
        .arrow {
            left: calc(10% - #{$arrow-size} / 2);
        }
        .arrow-shadow-blocker {
            left: calc(10% - 15px);
        }
    }
}

.overlay-enter-active, .overlay-leave-active {
    transition: 0.2s ease-in-out;
}

.overlay-enter,
.overlay-leave-to {
    opacity: 0;
    transform: translate(-50%, -50%) scale(0.3) !important;
}

</style>

<docs>
```vue
<reaction-button/>
```

```vue
<reaction-button init-selection="smile"/>
```
</docs>
