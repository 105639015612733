interface ResizeParams {
    integration?: Integrations;
    integrationUuid?: string;
    width?: string;
    height?: string;
    sizeToParent?: boolean;
}

enum Integrations {
    confluenceCloud = "confluence_cloud",
    confluenceServer = "confluence_server",
}

export class ResizingService {
    resize({ integration, integrationUuid, width = "100%", height = "300px", sizeToParent = false }: ResizeParams): void {
        console.info("resizing", integration, integrationUuid, width, height);
        if (integration === Integrations.confluenceServer) {
            if (!integrationUuid || !width || !height) {
                console.error("please add all parameters `resize({integration: Integrations.confluenceServer, integrationUuid, width, height})`");
                return;
            }
            window.parent.postMessage({ width: width, height: height, integrationUuid: integrationUuid }, "*");
            return;
        }
        if (!sizeToParent && (!width || !height)) {
            console.error("please add all parameters `resize({width, height})` or `resize({sizeToParent: true})`");
            return;
        }
        if (!("AP" in window)) {
            console.error("AP is not defined, initial script for Confluence Cloud Connect is missing.");
            return;
        }
        try {
            if (sizeToParent) {
                // @ts-ignore
                AP.env.sizeToParent();
            } else {
                // @ts-ignore
                AP.resize(width, height);
            }
        } catch (e) {
            console.error("resizing with AP failed", e);
        }
    }
}
