import {Activity} from "@backend/common/notification/activity-types";

/**
 * Pings are in-app notifications. SinglePings represent one activity.
 * MultiPings represent 1-n activities (for activities like: Person A and 10+ others commented your post).
 * Until a specific limit is reached (MULTI_PING_ORIGINATOR_USER_UIDS_LIMIT) pings keep track which users caused an activity within
 * the array originatorUserUids.
 */
export type PingInFireStore = SinglePingInFireStore | MultiPingInFireStore;

export enum PingType {
    singlePing = "SINGLE_PING",
    multiPing = "MULTI_PING"
}

export interface Ping<T extends Activity = Activity> {
    unread: boolean;
    activity: T; // only/first activity that lead to this ping
    created: Date;
    updated: Date;
}

export interface SinglePingInFireStore<T extends Activity = Activity> extends Ping<T> {
    type: PingType.singlePing;
    originatorUserUid: string;
}

export interface MultiPingInFireStore<T extends Activity = Activity> extends Ping<T> {
    type: PingType.multiPing;
    occurrences: number;
    originatorUserUids: string[];
    originatorUserUidsLimitReached: boolean;
}
