<template>
    <div class="avatar-editor-wrapper">
        <avatar
            class="comment-avatar"
            :photoURL="currentUserProfile.photoURL"
            :display-name="currentUserProfile.displayName"
        />
        <div class="card">
            <PostEditor
                ref="editor"
                class="mx-xsmall my-xxsmall"
                :edit-mode="true"
                :busy="loading"
                :collapsable="true"
                :place-holder="$t('enter_your_comment_here')"
                :attachment-manager="attachmentManager"
                no-topics
                @save="onSaveBtnClick"
            />
        </div>
    </div>
</template>

<script>
import PostEditor from "@web/components/editor/PostEditor";
import { AttachmentManager } from "@web/components/attachments/attachment-manager";
import Avatar from "@web/components/Avatar";
import { mapGetters } from "vuex";
import { PROFILE_MODULE_NAME } from "@web/store/profile/profile";
import { CURRENT_USER_PROFILE } from "@web/store/profile/getters";

export default {
    name: "NewCommentEditor",
    components: {
        Avatar,
        PostEditor,
    },
    props: {
        onCreate: {
            type: Function,
            required: true,
        },
        onSaveFail: {
            type: Function,
            required: false,
        }
    },
    data() {
        return {
            loading: false,
            attachmentManager: new AttachmentManager(),
        };
    },
    computed: {
        ...mapGetters({
            currentUserProfile: PROFILE_MODULE_NAME + CURRENT_USER_PROFILE,
        }),
    },
    methods: {
        async onSaveBtnClick({ content, onSave }) {
            this.loading = true;
            try {
                await this.onCreate(content, this.attachmentManager);
                onSave();
            } catch (e) {
                this.$notify({
                    group: "app",
                    type: "error",
                    text: this.$t("error_creating_comment")
                });
                console.error(e);
                if (this.onSaveFail) {
                    this.onSaveFail();
                }
            }
            this.loading = false;
        },
        focusEditor() {
            this.$refs.editor.focus();
        }
    }
};
</script>

<style lang="scss" scoped>
.avatar-editor-wrapper {
    display: grid;
    align-items: stretch;
    justify-content: stretch;
    grid: auto auto/2rem auto;
    gap: 0 0.75rem;
    grid-template-areas: "avatar content";

    .card {
        $background: $lowest-contrast;
        background: $background;
        border: 2px solid $low-contrast;

        ::v-deep{
            .ql-toolbar.ql-fuji {
                background: $background;
            }
        }
    }
}
</style>
