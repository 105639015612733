<template>
    <div
        class="infinite-scroll-spinner"
        :class="{ inactive: !loading }"
    >
        <transition name="fade">
            <Spinner
                v-if="loading"
                size="32px"
            />
        </transition>
    </div>
</template>
<script>
import Spinner from "@web/components/Spinner.vue";

export default {
    name: "InfiniteScrollSpinner",
    components: {
        Spinner,
    },
    props: {
        /** When set, displays a spinner. */
        loading: {
            required: true,
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            intersectionObserver: undefined,
        };
    },
    mounted() {
        this.registerObserver();
    },
    destroyed() {
        this.unregisterObserver();
    },
    methods: {
        registerObserver() {
            if (this.intersectionObserver) return;
            this.intersectionObserver = new IntersectionObserver((entries) => {
                // If intersectionRatio is 0, the target is out of view
                // and we do not need to do anything.
                if (entries[0].intersectionRatio <= 0) return;
                console.log("InfiniteScrollSpinner is visible -> emitting \"load-more\"");
                /** Emitted when the element is visible in the viewport. */
                this.$emit("load-more");
            });
            this.intersectionObserver.observe(this.$el);
        },
        unregisterObserver() {
            if (!this.intersectionObserver) return;
            this.intersectionObserver.unobserve(this.$el);
            this.intersectionObserver = undefined;
        },
    },
};
</script>

<style lang="scss" scoped>
.infinite-scroll-spinner {
    padding-top: 1rem;
    height: 4rem;

    &.inactive {
        padding: 0 !important;
        height: 0 !important;
    }
}
</style>

<docs>
An example which asynchronously loads 10 items up to a max of 100.
```vue
<template>
    <div>
        <button @click="items = []">clear</button>
        Items: {{ items.length }}
        <div style="max-height: 100px; overflow-y: auto;">
            <div v-for="item in items">{{ item }}</div>
            <infinite-scroll-spinner v-if="items.length < 100" :loading="loading" @load-more="loadMore"/>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                items: [],
                loading: false,
            };
        },
        methods: {
            loadMore() {
                this.loading = true;
                setTimeout(
                    () => {
                        this.items.push(...Array.from(Array(10)).map((value, index) => (this.items.length + index).toString(16)));
                        this.loading = false;
                    },
                    1000,
                );
            },
        },
    };
</script>
```

Same example within a absolutely positioned object:
```vue
<template>
    <div class="pos-relative" style="height: 50vh;">
        <button @click="items = []">clear</button>
        Items: {{ items.length }}
        <div class="pos-absolute card depth-1" style="max-height: 100px; overflow-y: auto; top: 2rem; width: 100%;">
            <div v-for="item in items">{{ item }}</div>
            <infinite-scroll-spinner v-if="items.length < 100" :loading="loading" @load-more="loadMore"/>
        </div>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                items: [],
                loading: false,
            };
        },
        methods: {
            loadMore() {
                this.loading = true;
                setTimeout(
                    () => {
                        this.items.push(...Array.from(Array(10)).map((value, index) => (this.items.length + index).toString(16)));
                        this.loading = false;
                    },
                    1000,
                );
            },
        },
    };
</script>
```
</docs>
