<template>
    <span class="inline-icon-text">
        <Icon
            :name="icon"
            size="small"
            color="var(--secondary)"
        />
        {{ title }}
    </span>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: "Headline"
        },
        icon: {
            type: String,
            default: "briefcase"
        }
    }
};
</script>

<style lang="scss" scoped>
    .inline-icon-text {
        display: inline-flex;
        align-items: center;
        & + & {
            margin-left: 0.75rem;
        }
        .icon {
            margin-right: 0.3rem;
        }
    }
</style>

<docs>
```vue
<inline-icon-text/>
```
</docs>
