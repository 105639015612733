<template>
    <div
        v-resize:compact="300"
        class="card depth-1 post-create-editor padding-small"
        @click.self="focusEditor"
    >
        <div
            class="avatar avatar-medium cursor-pointer"
            @click="focusEditor"
        >
            <img
                :alt="$t('avatar_of', [$store.state.profile.currentUserProfile.displayName])"
                :src="$store.state.profile.currentUserProfile.photoURL"
            />
        </div>
        <PostEditor
            ref="createEditor"
            :attachment-manager="attachmentManager"
            :busy="saving"
            :collapsable="true"
            :edit-mode="true"
            :place-holder="$t('what_is_on_your_mind')"
            class="create-editor"
            @save="onSaveBtnClickInNewPostEditor"
        />
    </div>
</template>

<script>
import {
    mapGetters,
    mapMutations,
} from "vuex";
import PostEditor from "@web/components/editor/PostEditor";
import { wait } from "@web/lib/wait";
import IntentHandlerMixin from "@web/mixins/IntentHandlerMixin";
import {
    MentionAction,
    mentionAnalytics,
} from "@web/lib/quill/mention/everest-mention";
import { MentionEntityType } from "@backend/mention/mention-types";
import { AttachmentManager } from "@web/components/attachments/attachment-manager";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import { analytics } from "@web/analytics";
import { getGlobalConfiguration } from "@web/global-config";
import { TIMESTAMP_MODULE_NAME } from "@web/store/timestamping/timestamping";
import { TimestampEvent } from "@web/store/timestamping/types";
import { IGNORE_NEXT_EVENT } from "@web/store/timestamping/mutations";
import { INTRANET } from "@web/store/intranet/getters";
import { length } from "@web/lib/quill/quill-utils";

/**
 * Editor component at the top of a timeLine to create a new post.
 */
export default {
    name: "PostCreateEditor",
    components: {
        PostEditor,
    },
    mixins: [IntentHandlerMixin],
    props: {
        /** @type {CreatePostFunction} */
        createPost: Function,
    },
    data() {
        return {
            saving: false,
            attachmentManager: new AttachmentManager(),
        };
    },
    computed: {
        ...mapGetters({
            intranet: INTRANET_MODULE_NAME + INTRANET,
        }),
    },
    methods: {
        ...mapMutations({
            ignoreContentUpdateEvent: TIMESTAMP_MODULE_NAME + IGNORE_NEXT_EVENT,
        }),
        async onSaveBtnClickInNewPostEditor({ topicUids, content, onSave }) {
            this.saving = true;
            try {
                // ignore update event to show not new content indicator
                this.ignoreContentUpdateEvent({ timestampEvent: TimestampEvent.postPublished, ignore: true });
                await this.createPost({ content, topics: topicUids, attachmentManager: this.attachmentManager });
                analytics.log(getGlobalConfiguration().analytics_event_name_post_created, { length: length(content) });
                mentionAnalytics.trackMentions(content, MentionEntityType.post, MentionAction.create);
                onSave();
                this.$refs.createEditor.collapseCommentBox();
            } catch (e) {
                // Reset ignore state in case of errors
                this.ignoreContentUpdateEvent({ timestampEvent: TimestampEvent.postPublished, ignore: false });
                let errorMessage = e.message;
                if (e.response && e.response.data) {
                    errorMessage += `: ${JSON.stringify(e.response.data)}`;
                }
                this.$notify({
                    group: "app",
                    type: "error",
                    title: this.$t("error_creating_post"),
                    text: errorMessage,
                });
                console.error(e);
            }
            this.saving = false;
        },
        async focusEditor() {
            // without a short wait the PostEditor component will notice the click event used to show the editor
            // and cause the PostEditor's blur handler to react
            // Vue.nextTick() is unfortunately too fast and does not prevent this issue
            await wait(10);
            this.$refs.createEditor.focus();
        },
        handleCreatePostIntent() {
            this.focusEditor();
        },
    },
};

/**
 * @typedef CreatePostFunction
 * @param {object} options
 * @param {QuillDeltaOperations[]} options.content
 * @param {string[]} options.topics
 * @param {AttachmentManager} options.attachmentManager
 * @returns Promise<void>
 */
</script>

<style lang="scss" scoped>
.post-create-editor {
    display: flex;
    transition: all 0.2s ease-in-out;
    margin-bottom: 2rem;
    padding: 1rem;
    cursor: text;

    .create-editor {
        width: 100%;
        align-self: center;
    }

    .avatar {
        flex: 0 0 auto;
        margin-right: 1rem;
    }

    &.compact {
        .avatar {
            display: none;
        }
    }
}
</style>
