<template>
    <news-list-vue
        class="news-magazine-wrapper"
        :intranet-uid="intranetUid"
    />
</template>

<script>
import Vue from "vue";
import NewsListVue from "@web/views/atlassian-cloud/confluence/macro/NewsList.vue";

export default Vue.extend({
    name: "NewsMagazineVue",
    components: {
        NewsListVue
    },
    props: {
        intranetUid: String
    },
});
</script>

<style lang="scss" scoped>
.news-magazine-wrapper {
    padding: 20px 10px 20px 20px;
}
</style>
