import { TopicSubscriptionInFirestore } from "@backend/topic-subscription/types";
import { firestore } from "@web/firebase-instance";
import {
    INTRANET_COLLECTION_IDENTIFIER,
    PROFILE_COLLECTION_IDENTIFIER,
} from "@web/firestore/collections";
import { castConverter } from "@web/lib/firestore-converter";
import {
    TOPIC_SUBSCRIPTION_COLLECTION,
    TOPIC_SUBSCRIPTION_DOC_IDENTIFIER,
} from "@backend/topic-subscription/collections";
import { cloudRunUrl } from "@web/cloud-run-url";
import { ApiClient } from "@web/api/ApiClient";
import { ApiClientBuilder } from "@web/api/ApiClientBuilder";

interface TopicSubscriptionParams {
    intranetUid: string;
    topicUid: string;
}

const topicSubscriptionEndpoint = `${cloudRunUrl.topicSubscription}/api/topic-subscription`;

class TopicSubscriptionService {
    private readonly api: Promise<ApiClient>;

    constructor() {
        this.api = new ApiClientBuilder().build();
    }

    public async fetchTopicSubscriptions({ intranetUid, userUid }: { intranetUid: string, userUid: string }): Promise<TopicSubscriptionInFirestore> {
        const topicSubscriptionSnap = await firestore.collection(INTRANET_COLLECTION_IDENTIFIER)
            .doc(intranetUid)
            .collection(PROFILE_COLLECTION_IDENTIFIER)
            .doc(userUid)
            .collection(TOPIC_SUBSCRIPTION_COLLECTION)
            .doc(TOPIC_SUBSCRIPTION_DOC_IDENTIFIER)
            .withConverter(castConverter<TopicSubscriptionInFirestore>())
            .get();
        return topicSubscriptionSnap.data() ?? { topicUids: [] };
    }

    public async subscribeTopic({ intranetUid, topicUid }: TopicSubscriptionParams): Promise<void> {
        const api = await this.api;
        await api.post<void>(`${topicSubscriptionEndpoint}/${intranetUid}/${topicUid}`);
    }

    public async unsubscribeTopic({ intranetUid, topicUid }: TopicSubscriptionParams): Promise<void> {
        const api = await this.api;
        await api.delete<void>(`${topicSubscriptionEndpoint}/${intranetUid}/${topicUid}`);
    }
}

export const topicSubscriptionService = new TopicSubscriptionService();
