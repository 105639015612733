<template>
    <Modal
        :title="$t('search_image')"
        name="unsplash"
        @before-open="beforeOpen"
    >
        <div class="form-group form-group-search">
            <input
                ref="searchInput"
                v-model="query"
                type="text"
                :placeholder="$t('enter_search_term')"
                @keyup.enter.prevent="search"
            />
            <div
                class="search-icon"
                @click="search"
            >
                <Icon name="search" />
            </div>
        </div>
        <div class="unsplash-modal">
            <div class="unsplash-images ">
                <Spinner v-if="loading" />
                <div
                    v-for="image in images"
                    class="unsplash-image"
                    @click="selectedImage=image"
                >
                    <img
                        class=" thumbnail depth-1"
                        :selected="selectedImage.id===image.id"
                        :src="image.thumbnail"
                    />
                    <a
                        class="meta"
                        :href="'https://unsplash.com/@'+image.username+'?utm_source=linchpin_cloud&utm_medium=referral'"
                        target="_blank"
                    >{{ $t('by') }}
                        {{ image.name }}</a>
                </div>
            </div>
        </div>
        <template v-slot:footer>
            <div class="grid search-footer">
                <div class="left">
                    <div class="meta">
                        Powered by <a
                            href="http://unsplash.com"
                            target="_blank"
                        >Unsplash.com</a>
                    </div>
                </div>
                <div class="right">
                    <div class="button-group">
                        <app-button
                            type="primary"
                            @click="onSelected(selectedImage), closeModal()"
                        >
                            {{
                                $t("button_ok") }}
                        </app-button>
                        <app-button
                            type="link primary"
                            @click="closeModal"
                        >
                            {{ $t("button_cancel") }}
                        </app-button>
                    </div>
                </div>
            </div>
        </template>
    </Modal>
</template>

<script>
import Modal from "@web/components/modals/Modal";
import { unsplashService } from "@web/services";
import Spinner from "@web/components/Spinner";

export default {
    name: "UnsplashModal",
    components: {
        Modal, Spinner
    },
    props: {
        onSelected: {
            Type: Function
        }
    },
    data() {
        return {
            images: [],
            query: "",
            selectedImage: {},
            loading: false,
        };
    },
    methods: {
        async search() {
            if (this.query.length > 2) {
                this.loading = true;
                const res = await unsplashService.getImagesFromUnsplash(this.query, 1, 10);
                this.loading = false;
                this.images = res.data.results.map(this.mapUnsplashImages);
            }
        },
        closeModal() {
            this.selectedImage = {};
            this.$modal.hide("unsplash");
        },

        mapUnsplashImages({ user, id, urls, width, height, description }) {
            const defaultWidth = 800;
            const ratio = defaultWidth / width;
            const { username, name } = user;
            const thumbnail = urls.thumb;
            const url = `https://source.unsplash.com/${id}/${defaultWidth}x${Math.round(height * ratio)}`;
            return { username, name, url, thumbnail, id, description };
        },

        async beforeOpen() {
            if (this.images.length > 0) {
                return;
            }
            setTimeout(() => this.$refs.searchInput.focus(), 200);
            this.loading = true;
            const res = await unsplashService.getRandomPhoto();
            this.loading = false;
            this.images = res.data.map(this.mapUnsplashImages);
        }
    },
};
</script>

<style lang="scss" scoped>
    $selected-border: 2px;
    .thumbnail {

        position: relative;
        border: 2px solid transparent;
        border-radius: var(--border-radius);
        overflow: hidden;
        transition: border 0.2s ease;
        cursor: pointer;

        &[selected] {
            border: $selected-border var(--primary) solid;

            &:after {
                content: "✓";
                transform: translate(0, 0);
                opacity: 1;
            }
        }
    }

    .unsplash-images {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin: 0 10px;

        .unsplash-image {
            flex: 1 0 auto;
            width: calc(50% - 20px);
            margin: 10px;

            p {
                color: grey;
                text-underline: grey;
            }

            img {
                width: 100%;
            }
        }
    }

    .unsplash-modal {
        max-height: 60vh;
        overflow-y: scroll;
        justify-content: space-between;
    }

    .form-group {
        margin-left: 20px;
        margin-right: 20px;
        display: flex;
        justify-content: space-between;

        &.form-group-search {
            position: relative;

            > input {
                height: 46px;
            }

            .search-icon {
                position: absolute;
                cursor: pointer;
                right: 12px;
                top: 50%;
                transform: translate(0, -50%);
                --icon-color: var(--primary);
            }
        }
    }

    .search-footer {
        display: flex;
        align-items: center;
        padding-top: 1.5rem;
        margin-top: -1.5rem;
        border-top: 1px solid var(--lowest-contrast, #{$off-white});
    }
</style>
