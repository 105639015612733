<script>
/**
 * This mixins consumes the `intent` get param and calls a handler method implemented by your component.
 * For example: if the URL contains `...?intent=create-post&...` this mixin calls the method `handleCreatePostIntent` of
 * components using this mixin.
 */
export default {
    name: "IntentHandlerMixin",
    watch: {
        $route() {
            this._consumeIntent();
        },
    },
    mounted() {
        this._consumeIntent();
    },
    methods: {
        _consumeIntent() {
            if (this.$route.query.intent == null) {
                return;
            }
            this._triggerIntentHandler(this.$route.query.intent);
            const { intent, ...remainingQuery } = this.$route.query;
            this.$router.replace({ query: remainingQuery });
        },
        _triggerIntentHandler(intent) {
            const camelCasedIntent = intent
                .toLowerCase()
                .split("-")
                .map(s => s.charAt(0).toUpperCase() + s.slice(1))
                .join("");
            const handlerName = `handle${camelCasedIntent}Intent`;
            const handlerMethod = this[handlerName];
            if (typeof handlerMethod === "function") {
                handlerMethod();
            } else {
                console.warn(`handler "${handlerName}" for intent "${intent}" is missing!`);
            }
        },
    },
};
</script>
