import { ApiClient } from "@web/api/ApiClient";
import { ApiClientBuilder } from "@web/api/ApiClientBuilder";
import { hubspot } from "@web/firebaseConfig";
import firebase from "firebase";
import {
    firstnameFromDisplayName,
    lastnameFromDisplayName,
} from "@web/lib/user-displayname-detection";
import { analytics } from "@web/analytics";
import { getGlobalConfiguration } from "@web/global-config";

interface HubspotFormParams {
    currentUser: firebase.UserInfo,
    intranetName: string,
    intranetUid: string,
    companyName: string,
    pageUri: string,
    pageName: string,
    isDemonstrationIntranet: boolean,
    companySize?: string
}

const hubspotLeadInformationLocalStorageKey = "hubspotLeadInformation";

interface HubspotLeadInformation {
    leadChannel: string,
    pageUrl: string,
    pageName: string,
    leadDate: string,
    leadTouchpointCount: number,
}

export class HubspotService {
    private api: Promise<ApiClient>;

    constructor() {
        this.api = new ApiClientBuilder().build();
    }

    public setLeadInformation({ leadChannel, pageUrl, pageName }: Pick<HubspotLeadInformation, "leadChannel" | "pageUrl" | "pageName">) {
        const leadDate = todayAsIsoDate();
        const exitingLeadInformation = this.getLeadInformation();
        const leadTouchpointCount = (exitingLeadInformation?.leadTouchpointCount || 0) + 1;

        if (exitingLeadInformation) {
            analytics.log(
                getGlobalConfiguration().analytics_event_name_lead_update_touchpoint_count,
                {
                    leadChannel: leadChannel,
                    touchpointCount: leadTouchpointCount,
                    prevLeadChannel: exitingLeadInformation.leadChannel,
                },
            );
        }
        const leadInformation: HubspotLeadInformation = { leadChannel, pageUrl, pageName, leadDate, leadTouchpointCount };
        localStorage.setItem(hubspotLeadInformationLocalStorageKey, JSON.stringify(leadInformation));
    }

    public getLeadInformation(): HubspotLeadInformation | null {
        const leadInformationJson = localStorage.getItem(hubspotLeadInformationLocalStorageKey);
        if (!leadInformationJson) {
            return null;
        }
        try {
            return JSON.parse(leadInformationJson) as HubspotLeadInformation;
        } catch {
            return null;
        }
    }

    public async submitHubspotForm({
        currentUser, intranetName, intranetUid, companyName, pageName, pageUri, isDemonstrationIntranet, companySize,
    }: HubspotFormParams): Promise<any> {
        if (!hubspot) {
            console.log("Hubspot not configured");
            return;
        }
        const hubspotPortalId = (hubspot as any).portalId;
        const hubspotFormGuid = (hubspot as any).formGuid;
        const hubspotFormSubmissionEndpoint = `https://api.hsforms.com/submissions/v3/integration/submit/${hubspotPortalId}/${hubspotFormGuid}`;
        const client = await this.api;
        const displayName = currentUser.displayName || "";

        const fields = [
            {
                name: "email",
                value: currentUser.email,
            },
            {
                name: "firstname",
                value: firstnameFromDisplayName(displayName),
            },
            {
                name: "lastname",
                value: lastnameFromDisplayName(displayName),
            },
            {
                name: "company",
                value: companyName,
            },
            {
                name: "hs_language",
                value: navigator.language.slice(0, 2),
            },
            {
                name: "hey___intranet_name",
                value: `${intranetName} (${intranetUid})`,
            },
            {
                name: "hey___workspace_erstellungsdatum",
                value: todayAsIsoDate(),
            },
            {
                name: "heydemo_content",
                value: isDemonstrationIntranet || false,
            },
        ];

        const leadInformation = this.getLeadInformation();

        if (leadInformation?.leadChannel) {
            fields.push({
                name: "lead_channel",
                value: leadInformation.leadChannel,
            });
        }

        if (leadInformation?.leadDate) {
            fields.push({
                name: "lead_date",
                value: leadInformation.leadDate,
            });
        }

        if (leadInformation?.leadTouchpointCount) {
            fields.push({
                name: "lead_touchpoint_count",
                value: `${leadInformation.leadTouchpointCount}`,
            });
        }

        if (companySize && companySize.length > 0) {
            fields.push({
                name: "company_size",
                value: `${companySize}`,
            });
        }

        const response = await client.post<any>(hubspotFormSubmissionEndpoint, {
            fields,
            context: {
                pageUri: leadInformation?.pageUrl || pageUri,
                pageName: leadInformation?.pageName || pageName,
            },
        });
        return response.data;
    }
}

function todayAsIsoDate(): string {
    return new Date().toISOString().slice(0, 10);
}
