import { analytics } from "@web/analytics";
import { getGlobalConfiguration } from "@web/global-config";
import { userService } from "@web/services";
import { MentionEntityType } from "@backend/mention/mention-types";
import {
    KnownInsertsQuill,
    Operation
} from "@backend/entity/delta/types";
import { i18n } from "@web/i18n";
import Vue from "vue";
import Spinner from "@web/components/Spinner.vue";
import { Intranet } from "@backend/intranet/types";

const PAGE_SIZE = 5;

export const countMentions = (content: Operation[]) => {
    return content.filter((o) => typeof o.insert === "object" && (o.insert as KnownInsertsQuill).mention !== undefined).length;
};

export const mentionAnalytics = {
    trackMentions(content: Operation[], entityType: MentionEntityType, mentionAction: MentionAction) {
        const mentionCount = countMentions(content);
        if (mentionCount === 0) {
            return;
        }
        analytics.log(getGlobalConfiguration().analytics_event_name_mention_on_entity, {
            entityType,
            mentionAction,
            mentionCount,
        });
    }
};

export enum MentionAction {
    create = "create",
    edit = "edit"
}

const ENTER_KEY = 13;

export function editorMentionModule(intranet: Intranet) {
    return {
        customState: {
            isLoading: false,
            error: null,
            latestSearchTerm: "",
        },
        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
        mentionDenotationChars: ["@"],
        isolateCharacter: true,
        blotName: "mention",
        dataAttributes: ["id", "value", "denotationChar", "userUid", "intranetUid"],
        selectKeys: [ENTER_KEY], // This option does not disable the tab key (see https://github.com/afry/quill-mention/issues/191)
        onSelect(item: any, insertItem: any) {
            insertItem({
                ...item,
                type: "user-mention",
                userUid: item.userUid,
                intranetUid: item.intranetUid,
            });
        },
        onClose() {
            this.customState.isLoading = false;
            this.customState.latestSearchTerm = "";
            this.customState.error = null;
        },
        renderItem(item: any, searchTerm: any) {
            // Use document.createElement to prevent XSS vulnerabilities without Vue
            const container = document.createElement("div");

            const img = document.createElement("img");
            img.className = "avatar";
            img.alt = item.displayName;
            img.src = item.photoURL;
            container.appendChild(img);

            const displayName = document.createElement("div");
            displayName.className = "mention-display-name";
            displayName.innerText = item.displayName;
            container.appendChild(displayName);

            return `<div>${container.innerHTML}</div>`;
        },
        renderLoading() {
            if (!this.customState.isLoading && !this.customState.error) {
                return null;
            }
            if (this.customState.error) {
                const errorMessage = (this.customState.error as any)?.name === "RetryError" ? i18n.t("error_connection_lost") : i18n.t("mention_loading_error");
                return `<div>${errorMessage}</div>`;
            }

            const spinner = new Vue({
                render: h => h(Spinner)
            }).$mount(document.createElement("div"));

            return `${spinner.$el.innerHTML}`;
        },
        async source(query: string, renderList: any, mentionChar: string) {
            this.customState.latestSearchTerm = query;
            this.customState.isLoading = true;
            const intranetUid = intranet.uid;
            try {
                const response = await userService.search.search({ intranetUid, query, pageNumber: 0, pageSize: PAGE_SIZE });
                this.customState.error = null;

                // Only render the list, if the search term matches the latest search term
                if (query.startsWith(this.customState.latestSearchTerm)) {
                    // manually limit the page size as the algolia parameter hitsPerPage seems to be ingored
                    const users = response.hits.slice(0, PAGE_SIZE).map((user: any) => {
                        return {
                            ...user,
                            userUid: user.uid,
                            intranetUid: intranetUid,
                            value: user.displayName
                        };
                    });
                    renderList(users);
                }
            } catch (e) {
                this.customState.error = e;
            } finally {
                this.customState.isLoading = false;
            }
        }
    };
}
