<template>
    <v-modal
        :name="name"
        classes="drawer depth-4"
        :scrollable="true"
        :class="size"
        :width="width"
        :min-width="380"
        :adaptive="true"
        :height="'100%'"
        :pivot-x="1"
        :pivot-y="1"
        transition="moveInFromRight"
    >
        <slot>
            <b>Side Drawer</b>
        </slot>
    </v-modal>
</template>

<script>
export default {
    name: "Drawer",
    props: {
        name: {
            type: String,
            default: "drawer"
        },
        size: {
            type: String,
            default: "small"
        }
    },
    computed: {
        width() {
            const sizes = {
                small: "25%",
                medium: "34%",
                large: "50%"
            };
            return sizes[this.size];
        }
    },

    methods: {
        openModal(name) {
            this.$modal.close(this.name);
        },
        closeModal() {
            this.$modal.hide(this.name);
        }
    }
};
</script>

<style lang="scss" scoped>
</style>
