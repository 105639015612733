import { ApiClient } from "@web/api/ApiClient";
import { ApiClientBuilder } from "@web/api/ApiClientBuilder";
import { cloudRunUrl } from "@web/cloud-run-url";
import { ConfluenceCqlSearchResult } from "@web/views/atlassian/atlassian-types";

export class AtlassianContentService {
    private api: Promise<ApiClient>;

    public static readonly atlassianConnectionEndpoint = `${cloudRunUrl.thirdPartyAtlassian}/api/atlassian/`;

    constructor() {
        this.api = new ApiClientBuilder().build();
    }

    public async fetchRecentConfluencePagesAndBlogposts(intranetUid: string): Promise<ConfluenceCqlSearchResult> {
        const client = await this.api;
        const path = "rest/api/search?cql=lastmodified <= endOfYear() and type in (blogpost,page) order by lastModified desc&limit=5&expand=content.history.lastUpdated&excerpt=indexed";
        const response = await client.get<ConfluenceCqlSearchResult>(`${AtlassianContentService.atlassianConnectionEndpoint + intranetUid}/confluence/api?path=${encodeURIComponent(path)}`);
        return response.data;
    }

    public async fetchRecentJiraTickets(intranetUid: string): Promise<ConfluenceCqlSearchResult> {
        const client = await this.api;
        const path = "rest/api/2/search?jql=ORDER BY created DESC&fields=issuetype,key,summary,assignee,reporter,priority,status,created,updated,duedate,fixVersions,project&maxResults=25&startAt=0";
        const response = await client.get<ConfluenceCqlSearchResult>(`${AtlassianContentService.atlassianConnectionEndpoint + intranetUid}/jira/api?path=${encodeURIComponent(path)}`);
        return response.data;
    }

    public async fetchTicketsCurrentUserIsWatching(intranetUid: string): Promise<ConfluenceCqlSearchResult> {
        const client = await this.api;
        const path = "rest/api/2/search?jql=watcher = currentUser() ORDER BY updated&maxResults=5&startAt=0&expand=changelog";
        const response = await client.get<ConfluenceCqlSearchResult>(`${AtlassianContentService.atlassianConnectionEndpoint + intranetUid}/jira/api?path=${encodeURIComponent(path)}`);
        return response.data;
    }
}
