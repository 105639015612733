import {
    configuredLocalForage,
    memoryDriver,
} from "@web/lib/localforage";

export interface EntityCacheParams {
    intranetUid: string;
    entityUid: string;
}

export class MemoryEntityCache<T extends { uid: string }> {
    private _localForage: LocalForage | undefined;

    private async localForage(): Promise<LocalForage> {
        if (!this._localForage) {
            const localForage = await configuredLocalForage();
            this._localForage = localForage.createInstance({
                driver: memoryDriver._driver,
            });
        }
        return this._localForage;
    }

    public async get(params: EntityCacheParams): Promise<T | null> {
        const localForage = await this.localForage();
        return localForage.getItem<T>(MemoryEntityCache.cacheKey(params));
    }

    public async set({ intranetUid, entity }: { intranetUid: string, entity: T }): Promise<void> {
        const localForage = await this.localForage();
        await localForage.setItem<T>(MemoryEntityCache.cacheKey({ intranetUid, entityUid: entity.uid }), entity);
    }

    public async remove(params: EntityCacheParams): Promise<void> {
        const localForage = await this.localForage();
        await localForage.removeItem(MemoryEntityCache.cacheKey(params));
    }

    private static cacheKey({ intranetUid, entityUid }: EntityCacheParams): string {
        return `${intranetUid}-${entityUid}`;
    }
}
