<template>
    <user-loading-component :user-uid="userUid">
        <template #default="{userLoading, user}">
            <pu-skeleton
                :class="sizeClass"
                :loading="userLoading"
                circle
            >
                <template v-if="!userLoading">
                    <user-link
                        v-if="linked"
                        v-bind="user"
                    >
                        <avatar
                            v-bind="user"
                            :size="size"
                        />
                    </user-link>
                    <template v-else>
                        <avatar
                            v-bind="user"
                            :size="size"
                        />
                    </template>
                </template>
            </pu-skeleton>
        </template>
    </user-loading-component>
</template>

<script>
import Avatar from "@web/components/Avatar";
import UserLoadingComponent from "@web/components/user/UserLoadingComponent";
import UserLink from "@web/components/user/UserLink";

/**
 * A dynamically loaded avatar of a user with an optional linking to the user profile.
 */
export default {
    name: "LoadingAvatar",
    components: {
        UserLink,
        Avatar,
        UserLoadingComponent,
    },
    props: {
        userUid: String,
        linked: Boolean,
        size: Avatar.props.size,
    },
    computed: {
        sizeClass() {
            if (this.size) return `avatar-skeleton-${this.size}`;
            return "avatar-skeleton-small";
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .pu-skeleton {
        vertical-align: bottom;
    }
}

@each $name, $size in $avatar-sizes {
    .avatar-skeleton-#{$name} {
        ::v-deep {
            .pu-skeleton {
                width: $size;
                height: $size;
            }
        }
    }
}
</style>
