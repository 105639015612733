<template>
    <section class="page-header">
        <div class="headline">
            <h1>{{ title }}</h1>
        </div>
        <div class="actions right">
            <slot ></slot>
        </div>
    </section>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: "Headline"
        }
    }
};
</script>

<style lang="scss" scoped>
    .page-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 2rem;
        @media (max-width: 768px) {
            &::v-deep {
                .button {
                    .icon + .content {
                        display: none;
                    }
                }
            }
        }
    }
    .actions {
        flex: 0;
        display: flex;
        flex-wrap: nowrap;
        align-items: center;
        margin-top: 0.2rem;
        & > * {
            margin-left: 1rem;
        }
    }
    .headline {
        flex: 1;
        width: 0;
    }
    h1 {
        margin: 0;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }
</style>

<docs>
```vue
<page-header/>
```
</docs>
