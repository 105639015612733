import { ApiClient } from "@web/api/ApiClient";
import { ApiClientBuilder } from "@web/api/ApiClientBuilder";
import { Attachment } from "@backend/attachment/types";
import { AxiosResponse } from "axios";
import { analytics } from "@web/analytics";
import { getGlobalConfiguration } from "@web/global-config";
import { cloudRunUrl } from "@web/cloud-run-url";
import { buildUrl } from "@web/lib/entity-path-builder";
import { FileUploadService } from "@web/services/file-upload";
import {
    EntityWithAttachments,
    EverestContentEntity
} from "@backend/entity/types";

export enum AttachmentRootEntityType {
    news = "news",
    post = "post",
}

enum ActionSuffix {
    created = "created",
    deleted = "deleted",
}

const maxExpectedBackendDelayInSeconds = 120;

interface EntityWithAttachmentReferenceConstructorParams {
    intranetUid: string;
    entityType: AttachmentRootEntityType;
    entityUid: string;
    entity: EntityWithAttachments & EverestContentEntity;
}

export class EntityWithAttachmentReference {
    readonly intranetUid: string;
    readonly entityType: AttachmentRootEntityType;
    readonly entityUid: string;
    readonly entity: EntityWithAttachments & EverestContentEntity;

    constructor({ intranetUid, entityType, entityUid, entity }: EntityWithAttachmentReferenceConstructorParams) {
        this.intranetUid = intranetUid;
        this.entityType = entityType;
        this.entityUid = entityUid;
        this.entity = entity;
    }

    editedWithinTheExpectedBackendDelay() {
        return (this.entity.lastEditedDate._seconds + maxExpectedBackendDelayInSeconds) * 1000 > Date.now();
    }

    hasNoSharedContent(): boolean {
        // always load external content if the content was changed recently to prevent race conditions
        if (this.editedWithinTheExpectedBackendDelay()) {
            return false;
        }

        // Always load if externalContentCount is undefined for older entities
        if (this.entity.externalContentCount === undefined) {
            return false;
        }

        return this.entity.externalContentCount === 0;
    }

    hasNoAttachments(): boolean {
        // always load external content if the content was changed recently to prevent race conditions
        if (this.editedWithinTheExpectedBackendDelay()) {
            return false;
        }

        // Always load if externalContentCount is undefined for older entities
        if (this.entity.attachmentCounts === undefined) {
            return false;
        }

        return this.entity.attachmentCounts.total === 0;
    }
}

interface CommentWithAttachmentReferenceConstructorParams {
    intranetUid: string;
    entityType: AttachmentRootEntityType;
    entityUid: string;
    commentPath: string;
    entity: EntityWithAttachments & EverestContentEntity;
}

export class CommentWithAttachmentReference extends EntityWithAttachmentReference {
    readonly commentPath: string;

    constructor(params: CommentWithAttachmentReferenceConstructorParams) {
        super(params);
        this.commentPath = params.commentPath;
    }
}

export class AttachmentService {
    private static readonly ATTACHMENT_SERVICE_ENDPOINT = `${cloudRunUrl.attachment}/api/attachment`;

    private readonly api: Promise<ApiClient>;

    constructor(private readonly fileUploadService: FileUploadService) {
        this.api = new ApiClientBuilder().build();
    }

    async list(rootEntityReference: EntityWithAttachmentReference): Promise<Attachment[]> {
        if (rootEntityReference.hasNoAttachments()) {
            return [];
        }
        const api = await this.api;
        const response: AxiosResponse = await api.get(
            buildUrl(AttachmentService.ATTACHMENT_SERVICE_ENDPOINT, rootEntityReference)
        );
        return response.data as Attachment[];
    }

    async createMany(
        rootEntityReference: EntityWithAttachmentReference, files: File[], uploaderUid: string
    ): Promise<Attachment[]> {
        const api = await this.api;

        const uploadRequests = files.map(
            file => ({
                file,
                promise: this.fileUploadService.uploadFile(
                    `${buildUrl(AttachmentService.ATTACHMENT_SERVICE_ENDPOINT, rootEntityReference)}/upload`,
                    file,
                    uploaderUid,
                    rootEntityReference.intranetUid,
                )
            })
        );

        const newAttachments: Attachment[] = [];
        for (const uploadRequest of uploadRequests) {
            const uploadTarget = await uploadRequest.promise;
            const createResponse: AxiosResponse = await api.post(
                buildUrl(AttachmentService.ATTACHMENT_SERVICE_ENDPOINT, rootEntityReference),
                { intranetStoragePath: uploadTarget.path, fileName: uploadRequest.file.name },
            );
            analytics.log(this.loadAnalyticsEventName(rootEntityReference.entityType, ActionSuffix.created));
            newAttachments.push(createResponse.data as Attachment);
        }
        return newAttachments;
    }

    async delete(rootEntityReference: EntityWithAttachmentReference, attachmentUid: string): Promise<void> {
        const api = await this.api;
        await api.delete(buildUrl(AttachmentService.ATTACHMENT_SERVICE_ENDPOINT, rootEntityReference, attachmentUid));
        analytics.log(this.loadAnalyticsEventName(rootEntityReference.entityType, ActionSuffix.deleted));
    }

    private loadAnalyticsEventName(rootEntityType: AttachmentRootEntityType, eventNameSuffix: ActionSuffix) {
        const globalConfiguration: any = getGlobalConfiguration();
        return globalConfiguration[`analytics_event_name_${rootEntityType}_attachments_${eventNameSuffix}`];
    }
}
