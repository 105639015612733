import { cloudRunUrl } from "@web/cloud-run-url";
import { ApiClientBuilder } from "@web/api/ApiClientBuilder";
import { ApiClient } from "@web/api/ApiClient";
import { EverestUser } from "@backend/user/types";
import {
    IntranetPermissionInFireStore,
    IntranetRole,
} from "@backend/intranet/types";
import { analytics } from "@web/analytics";
import { getGlobalConfiguration } from "@web/global-config";
import { UserSearchService } from "@web/services/UserSearchService";
import { AxiosPromise } from "axios";

export class UserService {
    public static readonly userEndpoint = `${cloudRunUrl.user}/api/user/user/`;
    public static readonly userListEndpoint = `${cloudRunUrl.user}/api/user/users/`;
    public static readonly permissionEndpoint = `${cloudRunUrl.user}/api/user/permission/`;

    private readonly api: Promise<ApiClient>;
    public readonly search: UserSearchService;

    constructor() {
        this.api = new ApiClientBuilder().build();
        this.search = new UserSearchService();
    }

    public async getSingleUserFromIntranet(intranetUid: string, userUid: string): Promise<AxiosPromise<EverestUser>> {
        const client = await this.api;
        return client.get<EverestUser>(UserService.userEndpoint + intranetUid + "/" + userUid);
    }

    public async removeUserFromIntranet(intranetUid: string, userUid: string): Promise<void> {
        const client = await this.api;
        await client.delete(UserService.userEndpoint + intranetUid + "/" + userUid);
    }

    public async getUserPermissionsForIntranet(intranetUid: string, userUid: string): Promise<IntranetPermissionInFireStore> {
        const client = await this.api;
        const response = await client.get<IntranetPermissionInFireStore>(UserService.permissionEndpoint + intranetUid + "/" + userUid);
        return response.data;
    }

    public async saveUserPermissionsForIntranet(intranetUid: string, userUid: string, permissions: IntranetPermissionInFireStore): Promise<void> {
        const client = await this.api;
        await client.post<IntranetPermissionInFireStore>(UserService.permissionEndpoint + intranetUid + "/" + userUid, permissions.roles).then(() => {
            if (permissions.roles.includes(IntranetRole.admin)) {
                analytics.log(getGlobalConfiguration().analytics_event_name_admin_permissions_assigned);
            }
        });
    }
}
