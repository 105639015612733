export const SET_DRAFTS = "set-drafts";
export const SET_MY_NEWS = "set-my-news";
export const SET_CURRENT_NEWS = "setCurrentNews";
export const UPDATE_CURRENT_NEWS = "updateCurrentNews";
export const STOP_LOADING_CURRENT_NEWS = "stopLoadingCurrentNews";
export const RESET_CURRENT_NEWS = "resetCurrentNews";
export const RESET_PUBLISHED_NEWS = "resetPublishedNews";
export const SET_PUBLISHED_NEWS = "setPublishedNews";
export const SET_PUBLISHED_NEWS_LAST_RESULT_LENGTH = "setPublishedNewsLastResultLength";
export const SET_LOADING_DRAFTS = "setLoadingDrafts";
export const SET_LOADING_CURRENT = "setLoadingCurrent";
export const SET_LOADING_PUBLISHED = "setLoadingPublished";
export const RESET_NEWS_MODULE = "resetNewsModule";
export const SET_ALL_IMAGES_IN_CONTENT = "setAllImagesInContent";
export const SET_LOADING_MY_NEWS = "setLoadingMyNews";
