import { userLoginService } from "@web/services";
import { VuexRootState } from "@web/store";
import {
    LOGOUT_FROM_FIREBASE,
    OPEN_LOGIN_POPUP_APPLE,
    OPEN_LOGIN_POPUP_GOOGLE,
    RELOAD_CURRENT_USER,
    SETUP,
    SIGN_IN_WITH_EMAIL_AND_PASSWORD,
    SIGN_UP_WITH_EMAIL_AND_PASSWORD
} from "@web/store/auth/actions";
import {
    AUTH_STATE,
    BUSY_LOGGING_IN,
    BUSY_LOGGING_IN_WITH_APPLE,
    BUSY_LOGGING_IN_WITH_EMAIL_AND_PASSWORD,
    BUSY_LOGGING_IN_WITH_GOOGLE,
    CURRENT_USER,
    LOGGED_IN
} from "@web/store/auth/getters";
import {
    LOGIN,
    LOGOUT,
    RESOLVE_AUTH_STATE
} from "@web/store/auth/mutations";
import { EverestUser } from "@backend/user/types";
import { Module } from "vuex";

export const AUTH_MODULE_NAME = "auth/";

export interface AuthModuleState {
    currentUser: EverestUser | null;
    authState: AuthState;
    authStateResolved: boolean;
    provider: LoginProvider | null;
}

export enum AuthState {
    authenticated,
    unauthenticated,
    unknown,
}

enum LoginProvider {
    apple,
    google,
    emailAndPassword
}

export const AUTH_MODULE = {
    namespaced: true,
    state: {
        authState: AuthState.unknown,
        authStateResolved: false,
        currentUser: null,
        provider: null
    },
    mutations: {
        [LOGIN](state, { user, provider }) {
            state.currentUser = user;
            state.authStateResolved = true;
            if (provider) {
                state.provider = provider;
            }
            state.authState = AuthState.authenticated;
        },
        [LOGOUT](state) {
            state.currentUser = null;
            state.authState = AuthState.unauthenticated;
        },
        [RESOLVE_AUTH_STATE](state) {
            state.authStateResolved = true;
        },
    },
    actions: {
        async [OPEN_LOGIN_POPUP_GOOGLE]({ commit }) {
            const user = await userLoginService.loginWithGoogleInPopup();
            if (user) {
                commit(LOGIN, { user, provider: LoginProvider.google });
            }
        },
        async [OPEN_LOGIN_POPUP_APPLE]({ commit }) {
            const user = await userLoginService.loginWithAppleInPopup();
            if (user) {
                commit(LOGIN, { user, provider: LoginProvider.apple });
            }
        },
        async [SIGN_IN_WITH_EMAIL_AND_PASSWORD]({ commit }, { email, password }) {
            const user = await userLoginService.signInWithEmailAndPassword(email, password);
            if (user) {
                commit(LOGIN, { user, provider: LoginProvider.emailAndPassword });
            }
        },
        async [SIGN_UP_WITH_EMAIL_AND_PASSWORD](
            { commit }, { email, displayName, password, requireEmailVerification, verificationForwardUrl }
        ) {
            const user = await userLoginService.signUpWithEmailAndPassword(
                displayName,
                email,
                password,
                requireEmailVerification,
                verificationForwardUrl,
            );
            if (user) {
                commit(LOGIN, { user, provider: LoginProvider.emailAndPassword });
            }
        },
        async [RELOAD_CURRENT_USER]({ commit }) {
            const user = await userLoginService.reloadCurrentUser();
            if (user) {
                commit(LOGIN, { user });
            }
        },
        [SETUP]({ commit, rootState }, next) {
            userLoginService.registerLoginStateChangeHandler((user, provider) => {
                commit(LOGIN, { user, provider });
            }, () => {
                commit(LOGOUT);
            }, () => {
            });
            // we're resolving the auth state no later than some milliseconds
            // logged in users get resolved by firebase callback
            setTimeout(() => commit(RESOLVE_AUTH_STATE), 2500);
        },
        async [LOGOUT_FROM_FIREBASE]({ commit, dispatch }) {
            await userLoginService.logout();
            commit(LOGOUT);
        },
    },
    getters: {
        [LOGGED_IN]: (state) => state.authState === AuthState.authenticated,
        [AUTH_STATE]: (state) => state.authState,
        [BUSY_LOGGING_IN]: (state) => state.authState === AuthState.unknown,
        [CURRENT_USER]: (state) => state.currentUser,
        [BUSY_LOGGING_IN_WITH_EMAIL_AND_PASSWORD]: (state) => state.provider === LoginProvider.emailAndPassword && state.authState === AuthState.unknown,
        [BUSY_LOGGING_IN_WITH_GOOGLE]: (state) => state.provider === LoginProvider.google && state.authState === AuthState.unknown,
        [BUSY_LOGGING_IN_WITH_APPLE]: (state) => state.provider === LoginProvider.apple && state.authState === AuthState.unknown,
    },
} as Module<AuthModuleState, VuexRootState>;
