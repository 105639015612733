import {
    Commentable,
    DeltaPresentation,
    EntityWithAttachments,
    EntityWithCurrentUsersReaction,
    EverestContentEntity,
    Publishable,
    Reactable,
    Timestamp,
} from "@backend/entity/types";
import { EverestComment } from "@backend/comment/types";
import { Operation } from "@backend/entity/delta/types";
import { ViewTrackable } from "@backend/view-tracking/types";

export enum EverestPostInFireStoreFieldKeys {
    overallCommentCount = "overallCommentCount",
    topics = "topics",
    lastActivityDate = "lastActivityDate",
}

export interface EverestPostInFireStore extends EverestContentEntity, Commentable, Reactable, Publishable, EntityWithAttachments {
    [EverestPostInFireStoreFieldKeys.overallCommentCount]: number;
    [EverestPostInFireStoreFieldKeys.topics]: string[];
    [EverestPostInFireStoreFieldKeys.lastActivityDate]: Timestamp;
}

export interface EverestPost extends EverestPostInFireStore, EntityWithCurrentUsersReaction, ViewTrackable {
    uid: string;
    comments?: EverestComment[];
    content?: DeltaPresentation;
}

export interface PostPayload {
    content: Operation[];
    topics?: string[];
}
