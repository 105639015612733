




































import Vue from "vue";
import { mapGetters } from "vuex";
import { NEWS_MODULE_NAME } from "@web/store/news/news";
import { CURRENT_NEWS } from "@web/store/news/getters";
import Modal from "@web/components/modals/Modal.vue";
import Button from "@web/components/Button.vue";

export default Vue.extend({
    name: "DeleteNewsModal",
    components: {
        Button,
        Modal
    },
    props: {
        deletionInProgress: {
            type: Boolean,
        }
    },
    data() {
        return {};
    },
    computed: {
        ...mapGetters({
            currentNews: NEWS_MODULE_NAME + CURRENT_NEWS,
        }),
    },
    methods: {
        triggerDelete() {
            this.$emit("delete");
        },
        triggerCancel() {
            this.$modal.hide("delete-news-modal");
            this.$emit("cancel");
        }
    }
});

