import {
    ReactionsOnEntity,
    ReactionType,
} from "@backend/reaction/types";
import { Operation } from "@backend/entity/delta/types";

export enum EverestContentEntityInFireStoreFieldKeys {
    creationDate = "creationDate",
    lastEditedDate = "lastEditedDate",
    creatorUid = "creatorUid",
    formatVersion = "formatVersion"
}

export interface EverestContentEntity {
    [EverestContentEntityInFireStoreFieldKeys.creationDate]: Timestamp;
    [EverestContentEntityInFireStoreFieldKeys.lastEditedDate]: Timestamp;
    [EverestContentEntityInFireStoreFieldKeys.creatorUid]: string;
    [EverestContentEntityInFireStoreFieldKeys.formatVersion]: number;
}

export interface Timestamp {
    _seconds: number;
    _nanoseconds: number;
}

export const WEB_CONTENT_COLLECTION_IDENTIFIER = "web-content";
export const MOBILE_CONTENT_COLLECTION_IDENTIFIER = "mobile-content";

export enum CommentableFieldKeys {
    commentCount = "commentCount"
}

export enum ReactableFieldKeys {
    reactions = "reactions"
}

export interface EntityWithAttachments {
    /**
     * @deprecated if you want to know the total attachment count use the sum of `attachmentCounts.total`
     */
    attachmentCount?: number;
    attachmentCounts?: AttachmentCounts;
    externalContentCount?: number;
}

export interface AttachmentCounts {
    media: number;
    file: number;
    total: number;
}

export interface Reactable {
    [ReactableFieldKeys.reactions]: ReactionsOnEntity;
}

export interface EntityWithCurrentUsersReaction {
    currentUsersReaction?: ReactionType;
}

export interface Commentable {
    [CommentableFieldKeys.commentCount]: number;
}

export enum PublishableFieldKeys {
    status = "status",
    publishDate = "publishDate",
    unpublishDate = "unpublishDate"
}

export interface Publishable {
    [PublishableFieldKeys.status]: PublishableEntityStatus;
}

export interface Schedulable extends Publishable {
    [PublishableFieldKeys.publishDate]?: Timestamp;
    [PublishableFieldKeys.unpublishDate]?: Timestamp;
}

export enum PublishableEntityStatus {
    draft = "DRAFT", /* before a news is visible */
    published = "PUBLISHED", /* when a news is visible */
    /* TODO: consider deleting or using this when news can be "de-published" depending on date or sth like that */
    unpublished = "UNPUBLISHED" /* when a news is not visible but not a draft, for example when gets un-published due to a un-publishing date */
}

export enum DeltaFormat {
    web = "web",
    mobile = "mobile",
    plain = "plain",
}

export type EditableDeltaFormat = DeltaFormat.web | DeltaFormat.mobile;
export type DeltaDoc = Operation[];
export type DeltaPresentation = DeltaDoc | string;

export interface EverestEntityContentUpdate {
    web: QuillDeltaOperations[];
    mobile: ZephyrDeltaOperations[];
}

export type ZephyrDeltaOperations = string;
export type QuillDeltaOperations = string;

export interface EverestContentInFirestore {
    web: string[];
    mobile: string[];
}
