<template>
    <v-card
        :loading="!profile"
        :name="profile ? profile.displayName : undefined"
        :photo-url="profile ? profile.photoURL : undefined"
        :background-url="backgroundUrl"
        :link-to="profile ? { name: 'user-profile', params: { userUid: profile.uid }} : undefined"
    >
        <template v-slot:meta>
            <div
                v-if="jobTitle || team"
                class="small-text"
            >
                <span v-if="jobTitle">{{ jobTitle }}</span>
                <span v-if="jobTitle && team"> • </span>
                <team-link
                    v-if="team"
                    :uid="team.uid"
                >
                    <span class="text-high-contrast">{{ team.name }}</span>
                </team-link>
            </div>
        </template>
        <template v-slot:actions>
            <send-hey-five-button
                :recipient="profile"
                type="primary"
            />
        </template>
    </v-card>
</template>

<script>
import VCard from "@web/components/v-card/VCard";
import SendHeyFiveButton from "@web/components/social-notifications/SendHeyFiveButton";
import { profileService } from "@web/services";
import { mapGetters } from "vuex";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import { INTRANET_UID } from "@web/store/intranet/getters";
import TeamLink from "@web/components/team/TeamLink";

export default {
    name: "UserVCard",
    components: { TeamLink, SendHeyFiveButton, VCard },
    props: {
        userUid: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            profile: undefined,
        };
    },
    computed: {
        ...mapGetters({
            intranetUid: INTRANET_MODULE_NAME + INTRANET_UID,
        }),
        backgroundUrl() {
            if (!this.profile) {
                return;
            }
            return this.profile.profileCoverImagePath || "/profile-bg.jpg";
        },
        jobTitle() {
            if (!this.profile) {
                return;
            }
            return this.profile.jobTitle;
        },
        team() {
            if (!this.profile || !this.profile.team) {
                return;
            }
            return this.profile.team;
        },
    },
    async mounted() {
        this.profile = await profileService.getFullUserProfile(this.intranetUid, this.userUid);
        this.$emit("loaded");
    }
};
</script>

<style lang="scss" scoped>

</style>
