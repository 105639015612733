import {
    AnySharedContentData,
    DisplayType,
    EverestContentData,
    SharableContentEntity,
    VideoContentData,
    VideoPlatform,
} from "@backend/attachment/shared-content/types";
import {
    urlPattern,
    youtubeUrlPattern,
} from "@web/lib/regexp-collection";
import Router, {
    Route,
} from "vue-router";

export function handleTextPaste(text: string, router: Router): AnySharedContentData | undefined {
    text = text.trim();
    return handleYoutubeUrlPaste(text) ||
        handleEverestContentEntityUrl(text, router) ||
        handleUrlPaste(text);
}

function handleEverestContentEntityUrl(text: string, router: Router): EverestContentData | undefined {
    if (!isInternalUrl(text)) return undefined;
    const url = new URL(text);
    const { route } = router.resolve(url.pathname);
    const metaData = routeToSharedContentMetadata(route);
    if (!metaData) return undefined;
    return {
        url: text,
        displayType: DisplayType.contentEntity,
        metaData,
    };
}

function routeToSharedContentMetadata(route: Route): EverestContentData["metaData"] | undefined {
    if (route.name === "event-modal-route") {
        return {
            type: SharableContentEntity.event,
            uid: route.params.eventUid,
        };
    }
    if (route.name === "news-route") {
        return {
            type: SharableContentEntity.news,
            uid: route.params.newsUid,
            path: route.params.commentPath,
        };
    }
    if (route.name === "team-profile") {
        return {
            type: SharableContentEntity.team,
            uid: route.params.teamUid,
        };
    }
    return undefined;
}

function handleUrlPaste(text: string): undefined {
    const isUrl = urlPattern().test(text);
    // TODO: will be implemented with HALO-5960
    if (isUrl) {
        //     return {
        //         uid: "external",
        //         url: text,
        //         displayType: DisplayType.link,
        //         metaData: {},
        //     };
    }
    return undefined;
}

function handleYoutubeUrlPaste(text: string): VideoContentData | undefined {
    const youtubeUrlPatternMatch = youtubeUrlPattern().exec(text);
    if (youtubeUrlPatternMatch) {
        return {
            url: text,
            displayType: DisplayType.video,
            metaData: {
                platform: VideoPlatform.youtube,
                videoID: youtubeUrlPatternMatch[1],
            },
        };
    }
    return undefined;
}

function isInternalUrl(url: string): boolean {
    return url.startsWith(HOST_URL + "/");
}
