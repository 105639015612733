export const INITIAL_FETCH_EVENTS = "initialFetchEvents";
export const FETCH_EVENTS = "fetchEvents";
export const FETCH_MY_EVENTS = "fetchMyEvents";
export const FETCH_ATTENDEES_FOR_CURRENT_EVENT = "fetchAttendeesForCurrentEvent";
export const FETCH_ATTENDEE_STATUS_FOR_CURRENT_USER = "fetchAttendeeStatusForCurrentUser";
export const RELOAD_EXISTING_EVENT_RANGE = "reloadExistingEventRange";
export const RELOAD_EVENT_IN_LIST = "reloadEventInList";
export const LOAD_EVENT = "loadEvent";
export const LOAD_CURRENT_EVENT = "loadCurrentEvent";
export const CREATE_EVENT = "createEvent";
export const UPDATE_EVENT = "updateEvent";
export const DELETE_EVENT = "deleteEvent";
export const JOIN_EVENT = "joinEvent";
export const LEAVE_EVENT = "leaveEvent";
