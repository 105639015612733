<template>
    <a
        class="button"
        role="button"
        :class="[{ disabled: busy || disabled, 'is-fab': fab, center }, type]"
        :style="`color: ${color}; background:${bg};`"
        :href="href"
        :target="openInNewTab ? '_blank' : undefined"
        @click="$emit('click', $event)"
    >
        <div
            class="inner"
            :class="{ center }"
        >
            <div
                v-if="badge"
                class="badge depth-2"
                :style="{opacity: busy ? 0 : 1}"
            >{{ badge }}
            </div>
            <Icon
                v-if="icon"
                :name="icon"
                :color="color"
                :style="{opacity: busy ? 0 : 1}"
                size="in-button"
            />
            <div
                class="content"
                :style="{opacity: busy ? 0 : 1}"
            >
                <!-- @slot button content -->
                <slot></slot>
            </div>
            <transition
                name="fade"
                mode="out-in"
                :duration="200"
            >
                <Spinner v-if="busy" />
            </transition>
        </div>
    </a>
</template>

<script>
import Spinner from "@web/components/Spinner.vue";
import Icon from "@web/components/Icon.vue";
import Vue from "vue";

export default Vue.extend({
    name: "EverestButton",
    components: {
        Spinner,
        Icon,
    },
    props: {
        /** disables the button if set */
        disabled: {
            type: Boolean,
            default: false,
        },
        /** display a loading indicator if set */
        busy: {
            type: Boolean,
            default: false,
        },
        /** display variants
         * @see ButtonVariants
         */
        type: {
            type: String,
            default: "",
        },
        /** icon to use
         * @see Icon
         */
        icon: {
            type: String,
        },
        /** displays a number in a badge in top right */
        badge: {
            type: Number,
        },
        /** override to set a CSS `color` */
        color: {
            type: String,
        },
        /** override to set a CSS `background` */
        bg: {
            type: String,
        },
        /** make button a Floating Action Button */
        fab: {
            type: Boolean,
            default: false,
        },
        /** makes the button full width and center its content  */
        center: {
            type: Boolean,
            default: false,
        },
        href: {
            type: String,
        },
        /**
         * Open links in new tab. Only relevant if `href` is set!
         */
        openInNewTab: Boolean,
    },
});
</script>

<style scoped lang="scss">
.button {
    &.is-fab {
        padding: 1rem;
    }

    &.center {
        width: 100%;
    }

    &.link {
        .badge {
            position: relative;
            top: 0;
            right: 0;
            margin-right: 0.4rem;
        }
    }

    &.primary {
        .icon-svg svg path {
            --icon-color: var(--primary-text);
        }
    }

    &.small-padding {
        padding: $spacing-xsmall;
    }
}

.inner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: stretch;

    &.center {
        justify-content: center;
    }

    .icon {
        flex: 0 0 auto;
        display: flex;
        align-items: center;

        & + .content:not(:empty) {
            margin-left: 0.4rem;
        }
    }

    .content {
        border: none;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 100%;
        line-height: 1.25;
    }

    .spinner-container {
        position: absolute;
    }
}
</style>

<docs>
### Busy button
```vue
<template>
    <div>
        <EverestButton busy>Busy button</EverestButton>
        <EverestButton :busy="busyButton" @click="busyButton = !busyButton">click me</EverestButton>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                busyButton: false,
            };
        },
    };
</script>
```

### Icon button
For icons see [Icon](#/Components/Icon).
```vue
<everest-button icon="clock">Icon button</everest-button>
```

### Badge button
```vue
<template>
    <everest-button :badge="badgeCounter" @click="badgeCounter++">
        Button with badge
    </everest-button>
</template>
<script>
    export default {
        data() {
            return {
                badgeCounter: 12,
            };
        },
    };
</script>
```

### Round icon button
```vue
<template>
    <div>
        <everest-button type="small-padding" icon="mail" />
        <everest-button type="small-padding" icon="plus" />
    </div>
</template>
```

### Button variants
All variants are available in `_elements.scss` and `ButtonVariants.
```vue
<template>
    <div>
        <div v-for="type in types" :style="{background: (type.includes('text-white') ? 'lightgray' : '')}" class="mbottom-small">
            <everest-button :type="type">type: {{ type }}</everest-button>
        </div>
    </div>
</template>
<script>
    import {ButtonVariants} from "@web/components/ButtonVariants";

    export default {
        data() {
            return {
                types: ButtonVariants,
            };
        },
    };
</script>
```
</docs>
