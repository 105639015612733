<template>
    <div
        ref="containerbox"
        class="macro-wrapper"
    >
        <PublishedNewsList
            v-if="intranetLoaded"
            :can-create-news="false"
            :is-integration="true"
            :locale="locale"
            @open-news="openNews"
        />
        <ContentUpdateObserver v-if="intranetLoaded"/>
        <MacroError
            v-if="hasError"
            :is-permission-denied-for-intranet="isPermissionDeniedForIntranet"
            :is-user-logged-in="isUserLoggedIn"
            i18n-feature-name="news"
        >
            <div class="news-row blurred">
                <div class="dummy-news">
                    <div class="dummy-teaser-image grey-box"></div>
                    <div class="dummy-teaser-text">
                        <div class="dummy-headline first grey-box"></div>
                        <div class="dummy-headline second grey-box"></div>
                        <div class="dummy-headline third grey-box"></div>
                    </div>
                </div>
                <div class="dummy-news">
                    <div class="dummy-teaser-image grey-box"></div>
                    <div class="dummy-teaser-text">
                        <div class="dummy-headline first grey-box"></div>
                        <div class="dummy-headline second grey-box"></div>
                        <div class="dummy-headline third grey-box"></div>
                    </div>
                </div>
                <div class="dummy-news">
                    <div class="dummy-teaser-image grey-box"></div>
                    <div class="dummy-teaser-text">
                        <div class="dummy-headline first grey-box"></div>
                        <div class="dummy-headline second grey-box"></div>
                        <div class="dummy-headline third grey-box"></div>
                    </div>
                </div>
            </div>
        </MacroError>
    </div>
</template>

<script>
import PublishedNewsList from "@web/views/news/PublishedNewsList.vue";
import Vue from "vue";
import ConfluenceMacroMixin from "@web/views/atlassian-cloud/confluence/macro/ConfluenceMacroMixin.vue";
import MacroError from "@web/views/atlassian-cloud/confluence/macro/MacroError.vue";
import { resizingService } from "@web/services";
import ContentUpdateObserver from "@/components/renderless/ContentUpdateObserver";

export default Vue.extend({
    name: "NewsListVue",
    components: {
        PublishedNewsList,
        MacroError,
        ContentUpdateObserver,
    },
    mixins: [
        ConfluenceMacroMixin,
    ],
    data() {
        return {
            locale: "",
            intranetLoaded: false,
            resizeObserver: new ResizeObserver(entries => {
                this.handleResize();
            }),
        };
    },
    mounted() {
        this.resizeObserver.observe(this.$refs.containerbox);
    },
    destroyed() {
        this.resizeObserver.disconnect();
    },
    async created() {
        this.appendAtlassianScriptToHead(async() => {
            this.isUserLoggedIn = this.isUserLoggedInIntoFirebase();
            this.locale = await this.getCurrentLocale(this.$route.query.integration);
            if (!this.isUserLoggedIn) {
                resizingService.resize({
                    integration: this.$route.query.integration,
                    integrationUuid: this.$route.query.integrationUuid,
                    height: "470px",
                });
                return;
            }
            this.setup(this.intranetUid)
                .then(this.handleSuccess, this.handleError)
                .finally(() => {
                    this.handleResize();
                });
        }, false);
    },
    methods: {
        handleError(error) {
            if (error.response.status === 403) {
                this.isPermissionDeniedForIntranet = true;
                return;
            }
            this.isUnknownError = true;
        },
        handleSuccess() {
            this.intranetLoaded = true;
            this.styleInject();
        },
        handleResize() {
            const height = document.body.scrollHeight;
            const width = "100%";
            resizingService.resize({
                integration: this.$route.query.integration,
                integrationUuid: this.$route.query.integrationUuid,
                width: width,
                height: height + "px",
            });
        },
    },
});
</script>

<style>
body {
    padding: 20px 20px 0;
}
</style>
<style lang="scss" scoped>

.error-wrapper {
    position: relative;
}

.error-overlay {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    max-width: 400px;
}

.grid {
    .col.half {
        .card {
            height: 100%;
        }
    }
}

.grey-box {
    background: var(--lowest-contrast, #{$off-white});
    border-radius: var(--border-radius);
}

.blurred {
    filter: blur(5px);
}

.news-row {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding-bottom: 1rem;

    > div, > article {
        flex: 0 1 auto;

        &.news-teaser.cover {
            padding-bottom: 0;
        }
    }

    .dummy-news {
        margin: 2rem 2rem 0;
        display: flex;
        width: calc(100% - 4rem);

        .dummy-teaser-text {
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            .dummy-headline {
                height: 1.5rem;
                width: 95%;

                &.second {
                    width: 60%;
                }

                &.third {
                    width: 80%;
                }
            }
        }

        .dummy-teaser-image {
            padding-top: 0;
            width: 10rem;
            height: 7rem;
            margin: 0 1rem 0 0;
        }
    }
}
</style>
