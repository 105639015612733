<template>
    <article
        v-resize:cover-compact="379"
        v-resize:main-compact="380"
        :class="type"
        class="news-teaser"
        @click="$emit('click')"
    >
        <div class="text">
            <div
                v-fade-in
                :class="{ read: hasBeenRead }"
                class="titles"
            >
                <h1 class="clamp">
                    {{ news.title }}
                </h1>
                <h2
                    v-if="news.subtitle"
                    class="ellipsis"
                >
                    {{ news.subtitle }}
                </h2>
            </div>
            <div
                v-fade-in:push.when="authorDisplayName.length"
                class="meta"
            >
                <div
                    v-if="showAuthor && isPublished"
                    class="author"
                >
                    <div class="ellipsis">
                        {{ authorDisplayName }}
                    </div>
                </div>
                <div class="meta-info">
                    <span
                        v-if="isPublished"
                        class="text-nowrap"
                    >
                        {{ formatDate(news.publishDate) }}
                    </span>
                    <span
                        v-if="!isPublished"
                        class="text-nowrap"
                    >
                        {{ $t("last_edited") }}{{ formatLastEditedDate(news.lastEditedDate) }}
                    </span>
                    <UnreadIndicator
                        v-if="isNew && !hasBeenRead"
                        class="mleft-xsmall"
                    />
                    <MetaDivider v-if="readingTime"/>
                    <span
                        v-if="readingTime"
                        class="text-nowrap"
                    >
                        {{ readingTime }}
                    </span>
                    <Icon
                        v-if="onReadingList"
                        color="var(--secondary)"
                        name="bookmark"
                        size="small"
                    />
                    <MetaDivider v-if="news.commentCount > 0 || totalReactionCount > 0"/>
                    <span
                        v-if="news.commentCount > 0"
                        class="mright-xsmall text-nowrap"
                    >
                        <Icon name="message-square"/>
                        {{ news.commentCount }}
                    </span>
                    <span
                        v-if="totalReactionCount > 0"
                        class="text-nowrap"
                    >
                        <Icon name="smile"/>
                        {{ totalReactionCount }}
                    </span>
                </div>
            </div>
        </div>
        <div class="image-wrapper">
            <div
                :style="`background-image: url('${coverPictureSrc}')`"
                class="image depth-2"
            >
                <img
                    v-if="news.coverPictureRef === COVER_PICTURE_NOT_SET || news.coverPictureRef === AUTHOR_IS_COVER_PICTURE"
                    v-fade-in.when="authorDisplayPhotoURL"
                    :alt="authorDisplayName"
                    :src="authorDisplayPhotoURL"
                    class="avatar"
                />
            </div>
        </div>
    </article>
</template>

<script>
import Icon from "@web/components/Icon.vue";
import {
    newsService,
    profileService,
} from "@web/services";
import { mapGetters } from "vuex";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import {
    friendlyFormatDateFromDayJs,
    toDayJs,
} from "@web/lib/time-utils";
import UnreadIndicator from "@/components/pings/UnreadIndicator";
import { INTRANET } from "@web/store/intranet/getters";
import { totalReactionCount } from "@web/lib/total-reaction-count";
import MetaDivider from "@web/components/news/MetaDivider";
import { registerSnapshotListenerOnReactableEntity } from "@web/lib/reactable-entity-snapshot-listener";
import { ReactableRootEntityType } from "@backend/reaction/types";
import { ScaledImageSize } from "@backend/common/image-resizing/types";

export const COVER_PICTURE_NOT_SET = "";
export const AUTHOR_IS_COVER_PICTURE = "AUTHOR_IS_COVER_PICTURE";

export default {
    name: "NewsTeaser",
    components: {
        MetaDivider,
        Icon,
        UnreadIndicator,
    },
    props: {
        /** @type {EverestNewsInFireStore} */
        news: {
            type: Object,
            default: () => ({}),
        },
        /**
         * @values big, small, type, reverse
         */
        type: {
            type: String,
            default: "small",
        },
        showAuthor: {
            type: Boolean,
            default: true,
        },
        isNew: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            imageUrl: "",
            authorDisplayName: "",
            authorDisplayPhotoURL: "",
            coverPictureSrc: "",
            COVER_PICTURE_NOT_SET,
            AUTHOR_IS_COVER_PICTURE,
            updatedReactions: this.news.reactions,
            unsubscribeReactionsListener: () => {},
        };
    },
    computed: {
        ...mapGetters(INTRANET_MODULE_NAME, [INTRANET]),
        isDraft() {
            return this.news.status === "DRAFT";
        },
        isPublished() {
            return this.news.status === "PUBLISHED";
        },
        hasBeenRead() {
            return this.news.status === "PUBLISHED" && this.news.readAt;
        },
        onReadingList() {
            return this.news.onReadingList;
        },
        readingTime() {
            if (!this.news.readingTime) {
                return null;
            }
            const time = Math.ceil(this.news.readingTime);
            return `${time} min ${this.$t("read_time")}`;
        },
        totalReactionCount() {
            return totalReactionCount(this.updatedReactions);
        },
    },
    mounted() {
        this.fetchAuthorName();
        this.loadCoverPicture();
        this.unsubscribeReactionsListener = registerSnapshotListenerOnReactableEntity(
            this.intranet.uid,
            ReactableRootEntityType.news,
            this.news.uid,
            undefined,
            this.onReactableDocSnapshot);
    },
    destroyed() {
        this.unsubscribeReactionsListener();
    },
    methods: {
        onReactableDocSnapshot(snapshot) {
            const docData = snapshot.data();
            if (docData && docData.reactions) {
                this.updatedReactions = docData.reactions;
            }
        },
        formatDate(inputDate) {
            if (!inputDate) {
                return "";
            }
            return friendlyFormatDateFromDayJs(toDayJs(inputDate));
        },
        formatLastEditedDate(inputDate) {
            if (!inputDate) {
                return "";
            }
            const dayJs = toDayJs(inputDate);
            return friendlyFormatDateFromDayJs(dayJs);
        },
        async loadCoverPicture() {
            if (this.news.coverPictureRef && this.news.coverPictureRef !== AUTHOR_IS_COVER_PICTURE && this.news.coverPictureRef !== COVER_PICTURE_NOT_SET) {
                this.coverPictureSrc = await newsService.getDownloadUrlForImage(
                    this.intranet.uid,
                    this.news.uid,
                    this.news.coverPictureRef,
                    ScaledImageSize.m,
                );
            }
        },
        async fetchAuthorName() {
            if (this.intranet && this.intranet.uid && this.news.creatorUid) {
                const { displayName, photoURL } = await profileService.getBaseUserProfile(
                    this.intranet.uid,
                    this.news.creatorUid,
                ).catch(() => profileService.fallbackProfile);
                this.authorDisplayName = displayName;
                this.authorDisplayPhotoURL = photoURL;
            }
        },
    },
};
</script>

<style lang="scss" scoped>

.aui-layout .news-teaser,
:host-context(.aui-layout) .news-teaser {
    margin-bottom: 4rem;
}

.news-teaser {
    display: flex;
    flex-direction: row;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
    border-bottom: 1px solid var(--low-contrast, #{$light-grey});
    animation-delay: var(--stagger);

    &:last-child,
    &:last-of-type {
        border-bottom: none;
        margin-bottom: 0;
    }

    .image-wrapper {
        position: relative;
        z-index: 1;
        transition: transform 0.2s ease;
        flex: 0 0 auto;
        width: 10rem;
        height: 7rem;
    }

    .image {
        background: no-repeat center/cover;
        background-color: var(--primary);
        border-radius: var(--border-radius);
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        .avatar {
            width: 4rem;
            height: 4rem;
        }

    }

    .text {
        flex: 4 1 auto;
        display: flex;
        flex-direction: column;
        width: 200px;
        min-width: 0;
    }

    .text + .image-wrapper {
        margin: 0 0 0 1rem;
    }

    .meta {
        $color: var(--high-contrast, #{$dark-mid-grey});
        $font-size: 0.75rem;
        font-weight: 500;
        font-size: $font-size;
        line-height: 1.4;
        letter-spacing: 0.03em;
        opacity: 1;
        padding-bottom: 0.5rem;
        color: $color;
        margin: 0 0 0.5rem 0;
        --icon-size: #{$font-size};
        --icon-color: #{$color};

        .author {
            margin-right: 4px;
            color: var(--foreground, #{$black});
            min-width: 0;
            flex: 1;
        }

        .meta-info {
            flex: 1 1 auto;
            display: flex;
            align-items: center;

            .icon {
                vertical-align: middle;
            }
        }
    }

    h1 {
        font-size: 1.5rem;
        line-height: 1.2;
        letter-spacing: -0.0001em;
        margin-bottom: 0.2rem;

        .pill {
            vertical-align: middle;
            position: relative;
            bottom: 1px;
        }
    }

    h2 {
        font-weight: normal;
        font-size: 1rem;
        line-height: 1.4;
        color: var(--high-contrast, #{$dark-mid-grey});
        margin-bottom: 0.5rem;
    }

    .read h1 {
        color: var(--high-contrast, #{$dark-mid-grey});

        &:after {
            content: "✓";
            margin-left: 5px;
        }
    }

    &.reverse {
        flex-direction: row-reverse;

        .text + .image-wrapper {
            margin: 0 1rem 0 0;
        }
    }

    &.type {
        h1 {
            font-weight: bold;
            font-size: 32px;
            line-height: 1.3;
            margin-bottom: 0.3rem;
        }

        .image {
            display: none;
        }
    }

    &.cover {
        flex-direction: column-reverse;
        border: none;
        padding-bottom: 1rem;
        box-shadow: none !important;

        .image-wrapper {
            width: 100%;
            height: auto;
            padding-top: 60%;
        }

        .text {
            flex: 4 1 auto;
            display: flex;
            flex-direction: column;
            width: 100%;
        }

        .text + .image-wrapper {
            margin: 0 0 1.5rem 0;
        }

        .meta {
            margin: 0 0 0 0;

            .author {
                flex: none;
            }
        }

        h1 {
            margin-bottom: 0.3rem;
        }

        // Image "Shadow"
        .image:before {
            content: "";
            position: absolute;
            background: no-repeat top/cover;
            background-image: inherit;
            top: 10px;
            width: 100%;
            height: 100%;
            z-index: -5;
            filter: blur(10px);
            transform: translate3d(0, 0, 0);
            opacity: $opacity-fourty;
            transition: all 0.3s ease;
        }

        &.cover-compact {
            h1 {
                font-size: 20px;
                line-height: 1.2;
                margin-bottom: 0;
            }

            h2 {
                font-size: 14px;
            }

            .text {
                flex-direction: column;
                justify-content: flex-end;

                & + .image-wrapper {
                    margin: 0 0 0.75rem 0;
                }
            }

            .meta {
                flex-direction: column;
                align-items: flex-start;
                margin-top: 0;
            }

            .image {
                .avatar {
                    width: 3rem;
                    height: 3rem;
                }
            }
        }

        &:hover {
            .image-wrapper {
                transform: scale(1.02) translate3d(0, -4px, 0);
            }

            .image:before {
                top: 20px;
                opacity: 0.7;
                filter: blur(20px);
            }
        }

    }

    &.small, &:not(.cover).main-compact {
        .image-wrapper {
            width: 6rem;
            height: 4rem;

            .avatar {
                width: 2.5rem;
                height: 2.5rem;
            }

        }

        h1 {
            font-weight: bold;
            font-size: 1rem;
            line-height: 1.3;
            margin-bottom: 0.2rem;
        }

        h2 {
            font-size: 0.75rem;
        }

        .meta {
            margin: 0;
        }

        &.cover-compact {
            .image-wrapper {
                width: 4rem;
                height: 3rem;

                .avatar {
                    width: 1.5rem;
                    height: 1.5rem;
                }

            }

        }
    }
}

.new-bubble {
    position: relative;
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background-color: var(--primary);
    margin-bottom: 0.1rem;
    margin-left: 0.35rem;

    &:after {
        content: "";
        display: block;
        width: inherit;
        height: inherit;
        border-radius: inherit;
        background-color: inherit;

        position: absolute;
        top: 0;
        left: 0;
        animation: pulseBubble 1s ease-in-out 0s infinite both;
    }
}

@keyframes pulseBubble {
    from {
        opacity: 0.5;
        transform: scale(0);
    }
    to {
        opacity: 0;
        transform: scale(3);
    }
}
</style>
