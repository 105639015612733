import { IntranetAccessInfo } from "@backend/invite/types";
import {
    codeBasedIntranetAccessService,
    inviteService
} from "@web/services";
import { analytics } from "@web/analytics";
import { getGlobalConfiguration } from "@web/global-config";
import {
    CodeBasedIntranetAccessService
} from "@web/services/intranet-access";
import { hostUrl } from "@web/firebaseConfig";

export enum IntranetAccessMethod {
    invite, accessCode
}

interface ForInviteParams {
    intranetUid?: string;
    inviteId?: string;
}

interface ForAccessCodeParams {
    intranetUid?: string;
    accessCode?: string;
}

interface ConstructorParams {
    intranetUid?: string;
    inviteId?: string;
    accessCode?: string;
}

export class IntranetAccessController {
    private readonly intranetUid?: string;
    private readonly inviteId?: string;
    private readonly accessCode?: string;

    private constructor({ intranetUid, inviteId, accessCode }: ConstructorParams) {
        this.accessCode = accessCode;
        this.inviteId = inviteId;
        this.intranetUid = intranetUid;
    }

    public static forInvite({ intranetUid, inviteId }: ForInviteParams): IntranetAccessController {
        return new IntranetAccessController({ intranetUid, inviteId });
    }

    public static forAccessCode({ intranetUid, accessCode }: ForAccessCodeParams): IntranetAccessController {
        return new IntranetAccessController({ intranetUid, accessCode });
    }

    public get accessMethod(): IntranetAccessMethod {
        if (this.inviteId) {
            return IntranetAccessMethod.invite;
        }
        if (this.accessCode) {
            return IntranetAccessMethod.accessCode;
        }
        throw new Error("neither inviteId nor accessCode is set");
    }

    public get verificationForwardUrl(): string {
        this.validateState();

        if (this.accessCode) {
            return CodeBasedIntranetAccessService.buildJoinUrl({
                intranetUid: this.intranetUid!,
                accessCode: this.accessCode,
                verifiedUser: true,
            });
        }
        return `${hostUrl}/login`;
    }

    public async getAccessInfo(): Promise<IntranetAccessInfo> {
        this.validateState();

        const accessMethod = this.accessMethod;
        if (accessMethod === IntranetAccessMethod.invite) {
            return inviteService.getInviteAccessInfo(this.intranetUid!, this.inviteId!);
        }
        if (accessMethod === IntranetAccessMethod.accessCode) {
            return codeBasedIntranetAccessService.getCodeAccessInfo(
                this.intranetUid!,
                this.accessCode!,
            );
        }
        throw new Error(`Unsupported access method: ${accessMethod}`);
    }

    public async joinIntranet(): Promise<void> {
        this.validateState();

        const accessMethod = this.accessMethod;
        if (accessMethod === IntranetAccessMethod.invite) {
            await inviteService.redeemInvite(this.intranetUid!, this.inviteId!);
            analytics.log(getGlobalConfiguration().analytics_event_name_invite_accepted);
            return;
        }
        if (accessMethod === IntranetAccessMethod.accessCode) {
            await codeBasedIntranetAccessService.joinWithAccessCode(
                this.intranetUid!,
                this.accessCode!,
            );
            analytics.log(getGlobalConfiguration().analytics_event_name_joined_with_access_code);
            return;
        }
        throw new Error(`Unsupported access method: ${accessMethod}`);
    }

    private validateState() {
        if (!this.intranetUid) {
            throw new Error("intranetUid must be set");
        }
        if (!this.inviteId && !this.accessCode) {
            throw new Error("neither inviteId nor accessCode is set");
        }
        if (this.inviteId && this.accessCode) {
            throw new Error("inviteId and accessCode can't both be set");
        }
    }
}
