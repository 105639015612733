import {
    MultiPingInFireStore,
    PingType,
    SinglePingInFireStore
} from "@backend/common/notification/inbox/types";
import { i18n } from "@web/i18n";
import {
    ActivityEntityType,
    ActivityType
} from "@backend/common/notification/activity-types";
import { friendlyFormatDateFromDayJs } from "@web/lib/time-utils";
import dayjs from "dayjs";
import { MentionEntityType } from "@backend/mention/mention-types";

const maxTitleLength = 50;
function ellipsisTitle(title: string): string {
    return title.length > maxTitleLength
        ? title.replace(/\n+/g, " ").substring(0, maxTitleLength - 3) + "…"
        : title;
}

export const getMultiPingOccurrencesLabelForPing = (ping: SinglePingInFireStore | MultiPingInFireStore) => {
    if (ping.type === PingType.singlePing || !ping.originatorUserUids || !ping.originatorUserUids || ping.occurrences < 2) {
        return "";
    }
    return ` ${i18n.t("ping_and_n_others", [ping.occurrences - 1])}`;
};

export const getActivityInformationLabelForPing = (ping: SinglePingInFireStore | MultiPingInFireStore) => {
    if (ping.activity.type === ActivityType.commentPublished) {
        return `${
            i18n.t(`ping_commented_on_a_${ping.activity.meta.rootEntityType}`)
        } ${
            ping.activity.meta.rootEntityType == ActivityEntityType.news
                ? `"${ellipsisTitle(ping.activity.meta.trimmedContentTitle)}"`
                : ""
        }`;
    }
    if (ping.activity.type === ActivityType.reactionCreated) {
        if (ping.activity.meta.rootEntityType === ActivityEntityType.post) {
            return i18n.t("ping_reacted_to_your_post");
        }
        if (ping.activity.meta.entityLocation != null) {
            return i18n.t("ping_reacted_to_your_comment");
        }
        if (ping.activity.meta.rootEntityType === ActivityEntityType.news) {
            return `${i18n.t("ping_reacted_to")} "${ellipsisTitle(ping.activity.meta.trimmedContentTitle)}"`;
        }
        return `${i18n.t("ping_reacted_to")} ${i18n.t("your")} ${i18n.t(ping.activity.meta.rootEntityType)}`;
    }
    if (ping.activity.type === ActivityType.newsPublished) {
        return `${i18n.t("ping_published_a_news")} "${ellipsisTitle(ping.activity.meta.trimmedTitle)}"`;
    }
    /* see HALO-2499
    if (ping.activity.type === ActivityType.EVENT_PUBLISHED) {
        return `${i18n.t("ping_published_a_event")}`;
    } */
    if (ping.activity.type === ActivityType.postPublished) {
        return `${i18n.t("ping_published_a_post")}`;
    }
    if (ping.activity.type === ActivityType.userMentioned) {
        if (ping.activity.meta.entityType === MentionEntityType.news) {
            return `${i18n.t("ping_mentioned_you_news", [ellipsisTitle(ping.activity.meta.trimmedTitle)])}`;
        }
        if (ping.activity.meta.entityType === MentionEntityType.newsComment) {
            return `${i18n.t("ping_mentioned_you_news_comment", [ellipsisTitle(ping.activity.meta.trimmedTitle)])}`;
        }
        if (ping.activity.meta.entityType === MentionEntityType.post) {
            return `${i18n.t("ping_mentioned_you_post")}`;
        }
        if (ping.activity.meta.entityType === MentionEntityType.postComment) {
            return `${i18n.t("ping_mentioned_you_post_comment")}`;
        }
    }
    return "";
};

export const getMetaLabelForPing = (ping: SinglePingInFireStore | MultiPingInFireStore) => {
    const friendlyDate = friendlyFormatDateFromDayJs(dayjs((ping.updated as any).seconds * 1000));
    return `${friendlyDate}`;
};
