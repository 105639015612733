<template>
    <div class="flex flex-wrap">
        <file-attachment
            v-for="attachment in attachments"
            :key="attachment.localUid"
            :attachment="attachment"
            :edit-mode="editMode"
            @delete="$emit('delete', attachment)"
        />
    </div>
</template>

<script>
import FileAttachment from "@web/components/attachments/FileAttachment";

export default {
    name: "FileAttachments",
    components: { FileAttachment },
    props: {
        /**
         * @type {AttachmentReference[]}
         */
        attachments: Array,
        editMode: Boolean,
    },
};
</script>

<style lang="scss" scoped>
</style>
