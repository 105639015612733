import { ApiClient } from "@web/api/ApiClient";
import { ApiClientBuilder } from "@web/api/ApiClientBuilder";
import { UserSearchService } from "@web/services/UserSearchService";
import dayjs from "dayjs";
import {
    asTimestamp,
    dayjsFromDateYMD,
    findClosestAnniversary,
    getSortDateByClosenessComparator,
} from "@web/lib/time-utils";
import { DateYMD } from "@backend/user-profile/types";
import { SocialNotificationType } from "@backend/common/notification/activity-types";
import { SocialHappening } from "@web/services/social-happenings";
import { timestampToMillis } from "@backend/common/time-format-conversion";

export class SocialOverviewService {
    private static readonly PAGE_SIZE = 1000; // maximum allowed by algolia, we should probably not reach this limit any time soon

    private readonly api: Promise<ApiClient>;
    private readonly searchService: UserSearchService;

    constructor() {
        this.api = new ApiClientBuilder().build();
        this.searchService = new UserSearchService();
    }

    public async list(intranetUid: string, month: number, year: number): Promise<SocialHappening[]> {
        const results = await Promise.all([
            this.listNewEmployees(intranetUid, month, year),
            this.listBirthdays(intranetUid, month),
            this.listAnniversaries(intranetUid, month),
        ]);
        return this.sortHappenings(results.flat());
    }

    private async listNewEmployees(intranetUid: string, month: number, year: number): Promise<SocialHappening[]> {
        const minMonth = timestampToMillis(asTimestamp(new Date(year, month)));
        const maxMonth = timestampToMillis(asTimestamp(new Date(year, month + 1, 0)));
        const results = await this.searchService.search({
            intranetUid,
            filters: `hiringDateTimestamp:${minMonth} TO ${maxMonth}`,
            pageSize: SocialOverviewService.PAGE_SIZE,
        });
        return results.hits.map(result => ({
            type: SocialNotificationType.newEmployee,
            date: dayjsFromDateYMD(result.hiringDate as DateYMD),
            user: result,
        }));
    }

    private async listBirthdays(intranetUid: string, month: number): Promise<SocialHappening[]> {
        const now = dayjs();
        const results = await this.searchService.filterAnniversaryRange({
            facetName: "birthdayDatestampMD",
            intranetUid,
            from: dayjs(new Date(now.year(), month)),
            to: dayjs(new Date(now.year(), month + 1, 0)), // setting the day to 0 fetches the last day of the previous month
            pageSize: SocialOverviewService.PAGE_SIZE,
        });
        return results.map(result => ({
            type: SocialNotificationType.birthday,
            date: findClosestAnniversary(dayjsFromDateYMD(result.birthday)),
            user: result,
        }));
    }

    private async listAnniversaries(intranetUid: string, month: number): Promise<SocialHappening[]> {
        const now = dayjs();
        const results = await this.searchService.filterAnniversaryRange({
            intranetUid,
            facetName: "hiringDateDatestampMD",
            from: dayjs(new Date(now.year(), month)),
            to: dayjs(new Date(now.year(), month + 1, 0)), // setting the day to 0 fetches the last day of the previous month
            pageSize: SocialOverviewService.PAGE_SIZE,
        });
        return results
            .map(result => ({
                type: SocialNotificationType.anniversary,
                date: findClosestAnniversary(dayjsFromDateYMD(result.hiringDate)),
                user: result,
            }))
            .filter(result => result.date.year() - dayjsFromDateYMD(result.user.hiringDate).year() > 0);
    }

    private sortHappenings(happenings: SocialHappening[]): SocialHappening[] {
        const now = dayjs();
        const sortDateByClosenessComparator = getSortDateByClosenessComparator(now);
        return happenings.sort((a, b) => sortDateByClosenessComparator(a.date, b.date));
    }
}
