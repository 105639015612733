import { render, staticRenderFns } from "./PostIt.vue?vue&type=template&id=3c6dc26a&scoped=true&"
import script from "./PostIt.vue?vue&type=script&lang=js&"
export * from "./PostIt.vue?vue&type=script&lang=js&"
import style0 from "./PostIt.vue?vue&type=style&index=0&id=3c6dc26a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c6dc26a",
  null
  
)

export default component.exports