



















import Logo from "@web/components/Logo.vue";
import { AUTH_MODULE_NAME } from "@web/store/auth/auth";
import { LOGGED_IN } from "@web/store/auth/getters";
import Vue from "vue";
import {
    mapActions,
    mapGetters,
} from "vuex";
import AvatarMenu from "@web/views/intranet/AvatarMenu.vue";
import InboxMenu from "@web/views/intranet/InboxMenu.vue";
import Launchpad from "@web/views/launchpad/Launchpad.vue";
import { PROFILE_MODULE_NAME } from "@web/store/profile/profile";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import { INTRANET_UID } from "@web/store/intranet/getters";
import { APPLY_CURRENT_USER_PROFILE_OF_INTRANET } from "@web/store/profile/actions";

export default Vue.extend({
    components: {
        AvatarMenu,
        InboxMenu,
        Logo,
        Launchpad,
    },
    computed: {
        ...mapGetters({ loggedIn: AUTH_MODULE_NAME + LOGGED_IN }),
        ...mapGetters({ intranetUid: INTRANET_MODULE_NAME + INTRANET_UID }),
    },
    mounted() {
        this.$watch("intranetUid", () => {
            this.applyCurrentUserProfileOfIntranet();
        }, { immediate: true });
    },
    methods: {
        ...mapActions({
            applyCurrentUserProfileOfIntranet: PROFILE_MODULE_NAME + APPLY_CURRENT_USER_PROFILE_OF_INTRANET,
        }),
    },
});
