import router from "@web/router";
import { Location } from "vue-router";
import { preventHandlerInEditMode } from "@web/lib/quill/is-in-edit-mode";

// @ts-ignore
const Quill: any = window.Quill;
const Embed = Quill.import("blots/embed");

export interface EverestMentionHoverEventData {
    userUid: string;
}

export class EverestMentionBlot extends Embed {
    static create(data: any) {
        const node = super.create();

        // Use document.createElement to prevent XSS vulnerabilities without Vue
        const link = document.createElement("a");

        const denotationChar = document.createElement("span");
        denotationChar.className = "ql-mention-denotation-char";
        denotationChar.innerText = data.denotationChar;
        link.appendChild(denotationChar);

        const location: Location = { name: "user-profile", params: { intranetUid: data.intranetUid, userUid: data.userUid } };
        link.href = router.resolve(location).href;
        link.innerText += data.value;
        link.addEventListener("click", preventHandlerInEditMode(() => router.push(location)));
        link.addEventListener("mouseenter", () => {
            link.dispatchEvent(new CustomEvent<EverestMentionHoverEventData>("everest-mention-mouseenter", {
                bubbles: true,
                detail: {
                    userUid: data.userUid,
                },
            }));
        });
        link.addEventListener("mouseleave", () => {
            link.dispatchEvent(new Event("everest-mention-mouseleave", { bubbles: true }));
        });

        node.appendChild(link);
        return EverestMentionBlot.setDataValues(node, data);
    }

    static setDataValues(domNode: HTMLElement, data: any) {
        Object.keys(data).forEach(key => {
            domNode.dataset[key] = data[key];
        });
        return domNode;
    }

    static value(domNode: HTMLElement) {
        return domNode.dataset;
    }
}

EverestMentionBlot.blotName = "mention";
EverestMentionBlot.tagName = "span";
EverestMentionBlot.className = "mention";
