<template>
    <div class="grid">
        <div class="col two-thirds">
            <single-media-attachment
                :attachment="firstAttachment"
                :edit-mode="editMode"
                :multi="attachments.length"
                @delete="$emit('delete', firstAttachment)"
                @click.native="$emit('open-lightbox', 0)"
            />
        </div>
        <div class="col third">
            <single-media-attachment
                class="right-top"
                :attachment="secondAttachment"
                :edit-mode="editMode"
                :multi="attachments.length"
                @delete="$emit('delete', secondAttachment)"
                @click.native="$emit('open-lightbox', 1)"
            />
            <div class="show-more-wrapper">
                <single-media-attachment
                    :attachment="thirdAttachment"
                    :edit-mode="editMode"
                    :multi="attachments.length"
                    @delete="$emit('delete', thirdAttachment)"
                    @click.native="$emit('open-lightbox', 2)"
                />
                <div
                    v-if="!editMode && attachments.length > 3 && lastVisibleAttachmentHasLoaded"
                    class="more-indicator"
                    @click="$emit('open-lightbox', 2)"
                >
                    <h2>{{ attachments.length - 3 }}+</h2>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SingleMediaAttachment from "@web/components/attachments/SingleMediaAttachment";
import { LoadingAttachmentReference } from "@web/components/attachments/attachment-manager";
export default {
    name: "ThreeMediaAttachments",
    components: { SingleMediaAttachment },
    props: {
        attachments: Array,
        editMode: Boolean,
    },
    data() {
        return {
            firstAttachment: this.attachments[0],
            secondAttachment: this.attachments[1],
            thirdAttachment: this.attachments[2],
        };
    },
    computed: {
        lastVisibleAttachmentHasLoaded() {
            return !(this.thirdAttachment instanceof LoadingAttachmentReference);
        },
    },
    watch: {
        attachments() {
            this.firstAttachment = this.attachments[0];
            this.secondAttachment = this.attachments[1];
            this.thirdAttachment = this.attachments[2];
        }
    }
};
</script>

<style lang="scss" scoped>
.more-indicator {
    cursor: pointer;
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    width: calc(100% - 2px);
    height: calc(100% - 2px);
    bottom: 1px;
    right: 1px;
    border-radius: calc(var(--border-radius) - 1px);
    color: white;
    background-color: #000000A5;
}

.grid {
    margin: 0;
}

.col.two-thirds {
    margin: 0 0.5rem 0 0;
}
.col.third {
    margin: 0;
    width: calc(33.32% - 1.7rem);
    position: relative;
}

.right-top {
    display: block;
    margin-bottom: 0.5rem;
}

.show-more-wrapper {
    position: relative;
}
</style>
