import { mapGetters } from "vuex";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import { INTRANET_UID } from "@web/store/intranet/getters";
import { ContentSearchService } from "@web/services/ContentSearchService";
import {
    resultAccessor,
    textHighlighter,
} from "@web/components/content-search/result-formatting";

const contentSearchService = new ContentSearchService();
const highlightPreTag = "%strong%";
const highlightPostTag = "%/strong%";

/**
 * Renderless component with logic to load content search results.
 * The main slot gets all necessary information and must contain a single element.
 * See `render()` method for the list of available fields in the slot context.
 */
export default {
    inheritAttrs: false,
    props: {
        pageSize: { type: Number, default: 25 },
        searchTerm: { type: String, default: "" },
    },
    data() {
        return {
            pageNumber: 0,
            totalResults: 0,
            results: [],
            lastQuery: undefined,
            /** @synced */
            loading: false,
            hasResult: false,
            loadingMore: false,
        };
    },
    computed: {
        ...mapGetters({
            intranetUid: INTRANET_MODULE_NAME + INTRANET_UID,
        }),
        hasMore() {
            return this.totalResults > this.results.length;
        },
        /** @synced */
        displayedResults() {
            return this.results.length;
        },
    },
    watch: {
        searchTerm: {
            async handler(searchTerm) {
                await this.fetchResults(searchTerm);
            },
            immediate: true,
        },
        loading(loading) {
            this.$emit("update:loading", loading);
        },
        displayedResults(displayedResults) {
            this.$emit("update:displayedResults", displayedResults);
        },
    },
    methods: {
        async performSearch({ term = this.searchTerm, pageNumber = 0, append = false }) {
            if (!term) {
                this.totalResults = 0;
                this.results = [];
                return;
            }
            this.loading = true;
            try {
                const response = await contentSearchService.search({
                    intranetUid: this.intranetUid,
                    query: term,
                    pageNumber,
                    pageSize: this.pageSize,
                    attributesToSnippet: ["content:20"],
                    highlightPreTag,
                    highlightPostTag,
                });
                if (append) {
                    this.results.push(...response.hits);
                } else {
                    this.results = response.hits;
                }
                this.totalResults = response.nbHits;
                this.pageNumber = response.page;
                this.hasResult = true;
                this.lastQuery = response.query;
            } finally {
                this.loading = false;
            }
        },
        async fetchResults(term) {
            try {
                await this.performSearch({ term });
            } catch (e) {
                this.notifyError();
            }
        },
        async fetchMoreResults() {
            if (!this.hasMore) return;
            this.loadingMore = true;
            try {
                await this.performSearch({ pageNumber: this.pageNumber + 1, append: true });
            } catch (e) {
                this.notifyError();
            } finally {
                this.loadingMore = false;
            }
        },
        notifyError() {
            this.$notify({
                group: "app",
                type: "error",
                title: this.$t("unknown_error"),
                text: this.$t("unknown_error_text"),
            });
        },
    },
    render() {
        const {
            pageNumber,
            totalResults,
            results,
            lastQuery,
            loadingMore,
            hasMore,
            hasResult,
            fetchMoreResults,
        } = this;
        return this.$scopedSlots.default({
            pageNumber,
            totalResults,
            results,
            lastQuery,
            loadingMore,
            hasMore,
            hasResult,
            fetchMoreResults,
            highlightedText: textHighlighter({ highlightPreTag, highlightPostTag }),
            resultAccessor,
        });
    },
};
