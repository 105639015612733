<template>
    <a
        :class="['button', 'button-badge', type]"
        @click="$emit('click')"
    >
        {{ text }}
    </a>
</template>

<script>
export default {
    name: "Badge",
    props: {
        /** displayed text inside the badge */
        text: String,
        /** display variants
         * @see ButtonVariants
         */
        type: String,
    },
};
</script>

<style scoped lang="scss">
.button-badge {
    font-size: 12px;
    line-height: 1.5;
    border: 1rem;
    padding: 0 10px;
}
</style>

<docs>
### Simple badges
```vue
<badge />
<badge text="A badge with custom text" />
<badge text="Click me!" @click="window.alert('hi')" />
```

### Badge variants
All variants are available in `_elements.scss` and `ButtonVariants.
```vue
<template>
    <div>
        <div v-for="type in types"
            :style="{background: (type.includes('text-white') ? 'lightgray' : '')}"
        >
            <badge :type="type" :text="'type: ' + type" />
        </div>
    </div>
</template>
<script>
    import {ButtonVariants} from "@web/components/ButtonVariants";

    export default {
        data() {
            return {
                types: ButtonVariants,
            };
        },
    };
</script>
```
</docs>
