import { ApiClientBuilder } from "@web/api/ApiClientBuilder";
import { ApiClient } from "@web/api/ApiClient";
import { AxiosResponse } from "axios";
import { cloudRunUrl } from "@web/cloud-run-url";
import { PaginationSearchParametersWithOffset } from "@backend/common/pagination-types";
import {
    LaunchpadDestination,
    LaunchpadDestinationPayload
} from "@backend/launchpad/types";

export class LaunchpadService {
    public static readonly launchpadServiceEndpoint = `${cloudRunUrl.launchpad}/api/launchpad`;

    private api: Promise<ApiClient>;
    constructor() {
        this.api = new ApiClientBuilder().build();
    }

    public async getDestinations(intranetUid: string, paginationParams: PaginationSearchParametersWithOffset<string>) {
        const client = await this.api;
        const response: AxiosResponse = await client.get(`${LaunchpadService.launchpadServiceEndpoint}/${intranetUid}`, {
            params: {
                ...paginationParams,
            }
        });
        return response.data;
    }

    public async createDestination(intranetUid: string, destinationData: LaunchpadDestinationPayload) {
        const client = await this.api;
        const response: AxiosResponse = await client.post(`${LaunchpadService.launchpadServiceEndpoint}/${intranetUid}`, {
            ...destinationData
        });
        return response.data;
    }

    public async updateDestination(intranetUid: string, destinationUpdateData: LaunchpadDestination) {
        const client = await this.api;
        const response: AxiosResponse = await client.put(`${LaunchpadService.launchpadServiceEndpoint}/${intranetUid}/${destinationUpdateData.uid}`, {
            ...destinationUpdateData
        });
        return response.data;
    }

    public async deleteDestination(intranetUid: string, destinationUid: string) {
        const client = await this.api;
        const response: AxiosResponse = await client.delete(`${LaunchpadService.launchpadServiceEndpoint}/${intranetUid}/${destinationUid}`);
        return response.data;
    }
}
