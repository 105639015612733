import { ApiClientBuilder } from "@web/api/ApiClientBuilder";
import { ApiClient } from "@web/api/ApiClient";
import { AxiosResponse } from "axios";
import { Intranet } from "@backend/intranet/types";
import {
    HappinessSurveyTypes,
    HappinessUserSubmission
} from "@backend/happiness/types";
import { cloudRunUrl } from "@web/cloud-run-url";

export class HappinessService {
    public static readonly happinessServiceEndpoint = `${cloudRunUrl.happiness}/api/happiness`; // dev mode: `http://localhost:9123/api/happiness`

    private api: Promise<ApiClient>;

    constructor() {
        this.api = new ApiClientBuilder().build();
    }

    public async userHasPermission(intranet: Intranet, happinessSurveyType: HappinessSurveyTypes) {
        const client = await this.api;
        const response: AxiosResponse = await client.get(`${HappinessService.happinessServiceEndpoint}/${intranet.uid}/permission/${happinessSurveyType}`);
        return response.data;
    }

    public async submitHappinessSurvey(intranet: Intranet, happiness: HappinessUserSubmission) {
        const client = await this.api;
        const response: AxiosResponse = await client.post(`${HappinessService.happinessServiceEndpoint}/${intranet.uid}`, happiness);
        return response.data;
    }

    public async getHappinessSurveyForType(intranet: Intranet, happinessSurveyType: HappinessSurveyTypes) {
        const client = await this.api;
        const response: AxiosResponse = await client.get(`${HappinessService.happinessServiceEndpoint}/${intranet.uid}/${happinessSurveyType}`);
        return response.data;
    }
}
