import { firestore } from "@web/firebase-instance";
import { getGlobalConfiguration } from "@web/global-config";
import { analytics } from "@web/analytics";

const INBOX_PING_FETCH_LIMIT = 30;

export class InboxService {
    getUsersInboxQuery(intranetUid: string, userUid: string) {
        return firestore
            .collection(`/intranet/${intranetUid}/notification/${userUid}/inbox`)
            .orderBy("updated", "desc")
            .limit(INBOX_PING_FETCH_LIMIT);
    }

    updatePingByDocumentId(intranetUid: string, userUid: string, pingDocumentId: string, update: { unread: boolean } /* more fields are not allowed */) {
        return firestore
            .doc(`/intranet/${intranetUid}/notification/${userUid}/inbox/${pingDocumentId}`)
            .update(update);
    }

    deletePingByDocumentId(intranetUid: string, userUid: string, pingDocumentId: string) {
        analytics.log(getGlobalConfiguration().analytics_event_name_inbox_ping_deleted);
        return firestore
            .doc(`/intranet/${intranetUid}/notification/${userUid}/inbox/${pingDocumentId}`)
            .delete();
    }

    updateUserInboxLastOpenedDate(intranetUid: string, userUid: string) {
        analytics.log(getGlobalConfiguration().analytics_event_name_inbox_open);
        return firestore
            .doc(`/intranet/${intranetUid}/notification/${userUid}`)
            .set({
                inboxLastOpenedTimestamp: new Date(),
            }, { merge: true });
    }

    getUserInboxDocument(intranetUid: string, userUid: string) {
        return firestore
            .doc(`/intranet/${intranetUid}/notification/${userUid}`);
    }
}
