<template>
    <LoadingWrapper :is-loading="isLoadingEvent">
        <SystemMessage
            v-if="error"
            :type="error.type"
        />

        <section
            v-if="!error && event && event.uid"
            v-global-page-title="event.title"
            class="event container"
            :class="{modal: modal}"
        >
            <section
                v-if="!isLoadingEvent"
                class="event-main"
            >
                <h2 v-if="isDraft">
                    {{ $t("events_create_title") }}
                </h2>
                <h2 v-if="!isDraft">
                    {{ $t("events_edit_title") }}
                </h2>
                <editable-image
                    class="coverImage"
                    default-image-path="/event-bg.jpg"
                    :initial-image="event.coverImagePath"
                    :upload-function="updateCoverImageBlob"
                    :delete-function="deleteCoverImage"
                    :aspect-ratio="4"
                    :cropper-modal="false"
                    @cropper-state-changed="cropperActive = $event"
                />
                <div class="form-group grid">
                    <div class="col half">
                        <label>{{ $t("events_form_title") }}</label>
                        <input
                            v-model="event.title"
                            :placeholder="$t('event_title_placeholder')"
                            type="text"
                            :maxlength="maxTitleLength"
                        />

                        <label>{{ $t("description") }}</label>
                        <RichTextEditor
                            v-model="event.description"
                            class="input editor"
                            :edit-mode="true"
                            :place-holder="$t('event_description_placeholder')"
                        />
                    </div>
                    <div class="col half">
                        <div class="date">
                            <label>{{ $t("events_form_date") }}</label>
                            <date-input
                                v-model="inputs.startDate"
                                class="start-date"
                            />
                        </div>

                        <div class="time-inputs">
                            <div class="time">
                                <label>{{ $t("events_form_start_time") }}</label>
                                <Timepicker v-model="inputs.startTime"/>
                            </div>
                            <div class="time">
                                <label>{{ $t("events_form_end_time") }}</label>
                                <Timepicker
                                    v-model="inputs.endTime"
                                    :start-after="inputs.startTime"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <hr/>
                <div class="event-edit-footer grid">
                    <div
                        v-if="!isDraft"
                        class="left"
                    >
                        <Button
                            v-if="!deletionConfirmation"
                            type="link warning"
                            @click="triggerDeleteConfirmation"
                        >
                            {{ $t("button_delete") }}
                        </Button>
                        <Button
                            v-if="deletionConfirmation"
                            type="link warning"
                            :busy="deleting"
                            @click="triggerDelete"
                        >
                            {{ $t("events_delete_confirmation") }}
                        </Button>
                    </div>
                    <div class="right">
                        <Button
                            class="button primary"
                            :disabled="!isValid"
                            :busy="updating"
                            @click.stop="triggerUpdateEvent"
                        >
                            <span v-if="isDraft">{{ $t("events_publish") }}</span>
                            <span v-else>{{ $t("events_update") }}</span>
                        </Button>
                        <button
                            class="button link"
                            @click="triggerCancel()"
                        >
                            {{ $t("button_cancel") }}
                        </button>
                    </div>
                </div>
            </section>
        </section>
    </LoadingWrapper>
</template>

<script>
import { analytics } from "@web/analytics";
import { getGlobalConfiguration } from "@web/global-config";
import { eventService } from "@web/services";
import Button from "@web/components/Button";
import { INTRANET } from "@web/store/intranet/getters";
import {
    mapActions,
    mapGetters,
} from "vuex";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import { EVENT_MODULE_NAME } from "@web/store/event/event";

import {
    DELETE_EVENT,
    UPDATE_EVENT,
} from "@web/store/event/actions";
import {
    asTimestamp,
    inputToDayjs,
    localizedDayjs,
    TIME_FORMAT,
    toDate,
    toDayJs,
} from "@web/lib/time-utils";
import { PublishableEntityStatus } from "@backend/entity/types";
import Timepicker from "@web/views/events/Timepicker";
import DateInput from "@web/components/forms/DateInput";
import RichTextEditor from "@web/components/editor/RichTextEditor";
import EditableImage from "@web/components/image-upload/EditableImage";
import { AUTH_MODULE_NAME } from "@web/store/auth/auth";
import { CURRENT_USER } from "@web/store/auth/getters";

export default {
    name: "EditEvent",
    components: {
        EditableImage,
        Timepicker,
        DateInput,
        Button,
        RichTextEditor,
    },
    props: {
        eventUid: {
            type: String,
            default: null,
        },
        modal: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            event: {
                name: "",
                startTimestamp: {},
                endTimestamp: {},
                coverImagePath: "/event-bg.jpg",
            },
            inputs: {
                startDate: "",
                startTime: "",
                endTime: "",
            },
            updating: false,
            deleting: false,
            deletionConfirmation: false,
            deletionConfirmationExpired: false,
            errorMessage: "",
            isLoadingEvent: false,
            error: null,
            imageUploadOpened: false,
            loading: false,
            coverImageBlob: undefined,
            cropperActive: false,
            maxTitleLength: getGlobalConfiguration().input_validation_event_title_max_length,
        };
    },
    computed: {
        ...mapGetters({
            currentUser: AUTH_MODULE_NAME + CURRENT_USER,
            intranet: INTRANET_MODULE_NAME + INTRANET,
        }),
        isDraft() {
            return this.event.status === PublishableEntityStatus.draft;
        },
        isValid() {
            return this.event.title.trim() !== "" && !this.cropperActive;
        },
    },
    watch: {
        inputs: {
            handler() {
                this.updateEventTimestampsFromInputs();
            },
            deep: true,
        },
    },
    async created() {
        this.isLoadingEvent = true;
        /*
         * Important: The event must not be loaded from store current event to prevent side-effects.
         * The event is just a temporary object and it is only persisted if the users saves it.
         */
        this.event = await eventService.getEvent(this.intranet, this.eventUid);
        // TODO: Move defaults for new event to backend
        if (this.event.status === PublishableEntityStatus.draft) {
            const initialStartDate = localizedDayjs().add(1, "hour").set("minute", 0);
            const initialEndDate = initialStartDate.add(1, "hour");
            this.event.startTimestamp = asTimestamp(initialStartDate.toDate());
            this.event.endTimestamp = asTimestamp(initialEndDate.toDate());
        }
        this.updateInputsFromEvent(this.event);
        this.isLoadingEvent = false;
        analytics.log(getGlobalConfiguration().analytics_event_name_event_edit_view_opened);
    },
    mounted() {
        window.scrollTo(0, 0);
    },
    methods: {
        ...mapActions({
            updateEvent: EVENT_MODULE_NAME + UPDATE_EVENT,
            deleteEvent: EVENT_MODULE_NAME + DELETE_EVENT,
        }),
        deleteCoverImage() {
            this.event.coverImagePath = null;
            this.event.coverImageStoragePath = null;
            this.coverImageBlob = undefined;
        },
        updateCoverImageBlob(blob) {
            this.coverImageBlob = blob;
        },
        closeModal() {
            if (this.modal) {
                this.$modal.hide("event-modal");
            }
        },
        updateInputsFromEvent(event) {
            this.inputs.startDate = toDate(event.startTimestamp);
            this.inputs.startTime = toDayJs(event.startTimestamp).format(TIME_FORMAT);
            this.inputs.endTime = toDayJs(event.endTimestamp).format(TIME_FORMAT);
        },
        updateEventTimestampsFromInputs() {
            const startDateTime = inputToDayjs(this.inputs.startDate, this.inputs.startTime);
            const endDateTime = inputToDayjs(this.inputs.startDate, this.inputs.endTime);

            if (!startDateTime || !endDateTime) {
                console.error("Invalid start or end date");
                return;
            }

            const startTimestampSeconds = startDateTime.valueOf() / 1000;
            const endTimestampSeconds = endDateTime.valueOf() / 1000;

            if (startTimestampSeconds === this.event.startTimestamp._seconds) {
                // startTimestamp not changed
                this.event.endTimestamp._seconds = endTimestampSeconds;
                return;
            }

            /*
             * Calculate the seconds offset to keep the duration when the start date is changed.
             */
            const secondsOffset = startTimestampSeconds - this.event.startTimestamp._seconds;
            this.event.startTimestamp._seconds = startTimestampSeconds;

            // keep event duration
            this.event.endTimestamp._seconds = this.event.endTimestamp._seconds + secondsOffset;

            // Cut off end times on next day
            let newEndDate = toDayJs(this.event.endTimestamp);
            let newStartDate = toDayJs(this.event.startTimestamp);

            if (!newEndDate.isSame(startDateTime, "day")) {
                newEndDate = newEndDate.date(startDateTime.date()).hour(23);
            }
            if (!startDateTime.isBefore(newEndDate)) {
                newEndDate = newEndDate.minute(45);
            }
            if (!startDateTime.isBefore(newEndDate)) {
                newEndDate = newEndDate.minute(59);
            }
            if (!startDateTime.isBefore(newEndDate)) {
                newStartDate = newStartDate.minute(58);
            }
            this.event.endTimestamp._seconds = newEndDate.valueOf() / 1000;
            this.event.startTimestamp._seconds = newStartDate.valueOf() / 1000;

            this.updateInputsFromEvent(this.event);
        },
        triggerCloseOrViewMode() {
            if (this.event.status === PublishableEntityStatus.draft) {
                this.closeModal();
            } else {
                this.leaveEditMode();
            }
        },
        async triggerUpdateEvent() {
            this.updateEventTimestampsFromInputs();
            this.updating = true;

            let analyticsEventName;

            try {
                const updatedEvent = await this.updateEvent({ event: this.event });
                if (this.coverImageBlob) {
                    this.event = await eventService.updateEventCoverImage(this.intranet, this.currentUser.uid, updatedEvent, this.coverImageBlob);
                }
                this.triggerCloseOrViewMode();
                analyticsEventName = this.isDraft ? "analytics_event_name_event_created" : "analytics_event_name_event_updated";
            } catch (error) {
                this.$notify({
                    group: "app",
                    type: "error",
                    text: `${this.$t("events_publish_failed")}: ${error.message}`,
                });
                analyticsEventName = this.isDraft ? "analytics_event_name_event_create_failed" : "analytics_event_name_event_update_failed";
            } finally {
                this.updating = false;
                analytics.log(getGlobalConfiguration()[analyticsEventName], this.event);
            }
        },
        triggerCancel() {
            this.triggerCloseOrViewMode();
        },
        triggerDeleteConfirmation() {
            this.deletionConfirmation = true;
            setTimeout(() => this.deletionConfirmationDelayExpired = true, 500);
        },
        async triggerDelete() {
            if (!this.deletionConfirmationDelayExpired) {
                return;
            }
            this.deleting = true;

            try {
                await this.deleteEvent({ event: this.event });
                this.closeModal();
                analytics.log(getGlobalConfiguration().analytics_event_name_event_deleted);
            } catch (error) {
                this.$notify({
                    group: "app",
                    type: "error",
                    text: `${this.$t("events_delete_failed")}: ${error.message}`,
                });
                analytics.log(getGlobalConfiguration().analytics_event_name_event_delete_failed);
            } finally {
                this.deleting = false;
            }
        },
        leaveEditMode() {
            this.$emit("on-leave-edit-mode");
        },
    },
};
</script>

<style lang="scss" scoped>
.coverImage {
    margin-bottom: 2rem;
}

.event-title-container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: flex-start;

    h2 {
        padding-left: 20px;
        line-height: 2rem;
        order: 2;
        flex: 4 1 auto;
    }
}

.event-main {
    .editor {
        max-height: 500px;
        overflow: auto;
    }
}

input {
    margin-bottom: 10px;
}

.time {
    /* Use inline-block instead of flexbox to avoid side-effects with vue-select */
    width: 50%;
    display: inline-block;
}

.time:not(:last-child) {
    padding-right: 20px;
}

.event-edit-footer {
    padding-top: 15px;
    justify-content: flex-end;
}

.icon::v-deep {
    display: inline-block;
    vertical-align: middle;
}

.event-infos {
    margin-top: 2rem;
}

.event {
    max-width: 900px;
}

.modal {
    &.event {
        background: var(--background, #{$white});
        color: var(--foreground, #{$black});
        margin-top: 50px;
        padding: 30px 0 0 0;
        border-radius: var(--border-radius);
        overflow: hidden;
    }

    .main, .event-main {
        padding: 0 40px 30px;
    }
}

.date {
    margin-bottom: 10px;
}

</style>
