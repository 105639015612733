<template>
    <div>
        <CommentableContentFooter
            class="content-footer"
            :content-type="contentType"
            :content-uid="contentUid"
            :comment-count="totalCommentCount"
            :total-reactions="totalReactions"
            :selected-reaction-type="selectedReactionType"
            @reply="focusEditor"
        />
        <CommentTree
            :comments="comments"
            :content-uid="contentUid"
            :content-type="contentType"
            :content-creator-uid="contentCreatorUid"
            :comment-count="totalCommentCount"
        />
        <NewCommentEditor
            ref="newCommentEditor"
            :on-create="onCreateComment"
            :on-save-fail="onSaveFail"
        />
    </div>
</template>

<script>
import CommentTree from "@web/components/comments/CommentTree";
import NewCommentEditor from "@web/components/comments/NewCommentEditor";
import { commentService } from "@web/services";
import {
    mapMutations,
    mapState,
} from "vuex";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import CommentableContentFooter from "@web/components/comments/CommentableContentFooter";
import { CommentWithAttachmentReference } from "@web/services/attachments";
import {
    TIMESTAMP_MODULE_NAME,
    timestampEventForContentType,
} from "@/store/timestamping/timestamping";
import { IGNORE_NEXT_EVENT } from "@/store/timestamping/mutations";
import { CommentableEntityType } from "@backend/comment/types";
import { LIVE_NEW_CONTENT_MODULE } from "@web/store/live-new-content/live-new-content";
import {
    ADD_IGNORE_CONTENT_ENTITY_UPDATE,
    REMOVE_IGNORE_CONTENT_ENTITY_UPDATE,
} from "@web/store/live-new-content/mutations";

export default {
    name: "CommentSection",
    components: { NewCommentEditor, CommentTree, CommentableContentFooter },
    props: {
        comments: { type: Array, required: true },
        contentUid: { type: String, required: true },
        contentType: {
            type: String,
            required: true,
            validator(value) {
                return Object.values(CommentableEntityType).indexOf(value) >= 0;
            }
        },
        contentCreatorUid: { type: String, required: true },
        commentCount: { type: Number, required: true },
        totalReactions: { type: Object, default: null },
        selectedReactionType: { type: String, default: null },
    },
    data() {
        return {
            newCommentCount: 0,
        };
    },
    computed: {
        ...mapState(INTRANET_MODULE_NAME, ["intranet"]),
        totalCommentCount() {
            return this.commentCount + this.newCommentCount;
        },
    },
    methods: {
        ...mapMutations({
            // older timestamp based variant
            ignoreContentUpdateEvent: TIMESTAMP_MODULE_NAME + IGNORE_NEXT_EVENT,
            // new variant with topic awareness
            addIgnoreContentEntityUpdate: LIVE_NEW_CONTENT_MODULE + ADD_IGNORE_CONTENT_ENTITY_UPDATE,
            removeIgnoreContentEntityUpdate: LIVE_NEW_CONTENT_MODULE + REMOVE_IGNORE_CONTENT_ENTITY_UPDATE,
        }),
        async onCreateComment(content, attachmentManager) {
            try {
                // ignore update event to show not new content indicator
                this.ignoreContentUpdateEvent({ timestampEvent: timestampEventForContentType(this.contentType), ignore: true });
                this.addIgnoreContentEntityUpdate(this.contentUid);
                const newComment = await commentService.createComment(
                    this.intranet.uid,
                    this.contentType,
                    this.contentUid,
                    content,
                );
                newComment.comments = [];
                // adds an arbitrary flag to indicate self created comments
                newComment._new = true;
                await attachmentManager.save(
                    new CommentWithAttachmentReference({
                        intranetUid: this.intranet.uid,
                        entityType: this.contentType,
                        entityUid: this.contentUid,
                        commentPath: newComment._path,
                        entity: newComment,
                    }),
                    newComment.creatorUid,
                    true,
                );
                this.comments.unshift(newComment);
                this.newCommentCount++;
            } catch (e) {
                this.removeIgnoreContentEntityUpdate(this.contentUid);
                throw e;
            }
        },
        onSaveFail() {
            this.ignoreContentUpdateEvent({ timestampEvent: timestampEventForContentType(this.contentType), ignore: false }); // Reset ignore state in case of errors
        },
        focusEditor() {
            // Postpone the editor focussing in the DOM event queue to let all body blur triggers hit before actually applying the focus.
            // Otherwise, the click would call this function first and then due to event bubbling later calls all v-click-blur handlers on `document.body`.
            setTimeout(() => this.$refs.newCommentEditor.focusEditor(), 0);
        }
    },
    watch: {
        commentCount() {
            /* If the comment count is updated from outside of the component,
             * we can assume that the post was reloaded completely and the value
             * of newCommentCount is not valid anymore. Therefore we reset it.
             */
            this.newCommentCount = 0;
        }
    },
};
</script>

<style lang="scss" scoped>
.content-footer {
    margin-bottom: 1.5rem;
}
</style>
