import { ApiClient } from "@web/api/ApiClient";
import { ApiClientBuilder } from "@web/api/ApiClientBuilder";
import { firebaseCloudFunctionUrl } from "@web/firebaseConfig";
import { SocialNotificationType } from "@backend/common/notification/activity-types";
import { analytics } from "@web/analytics";
import { getGlobalConfiguration } from "@web/global-config";

export interface DispatchSocialNotificationParams {
    intranetUid: string;
    recipientUid: string;
    socialNotificationType: SocialNotificationType;
    message?: string;
}

export class ActivityDispatcherService {
    private static readonly functionUrl = `${firebaseCloudFunctionUrl}/activityDispatcher`;

    private readonly api: Promise<ApiClient>;

    constructor() {
        this.api = new ApiClientBuilder().build();
    }

    public async dispatchSocialNotification(
        { intranetUid, recipientUid, socialNotificationType, message }: DispatchSocialNotificationParams
    ): Promise<void> {
        const api = await this.api;
        await api.post(
            `${ActivityDispatcherService.functionUrl}/${intranetUid}/social-notifications`,
            {
                recipientUid,
                socialNotificationType,
                message: message || undefined,
            }
        );
        analytics.log(getGlobalConfiguration().analytics_event_name_social_notification_sent, {
            socialNotificationType
        });
    }
}
