import {
    blotName,
    TruncateClickCallback,
} from "@web/lib/quill/quill-read-more";

// @ts-ignore
const Quill: any = window.Quill;
const Inline = Quill.import("blots/inline");

export class QuillReadMore extends Inline {
    static blotName = blotName;
    static className = "ql-read-more";
    static tagName = "A";

    static create(callback: TruncateClickCallback): any {
        const node = super.create();
        node.addEventListener("click", callback);
        return node;
    }
}
