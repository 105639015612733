<template>
    <div class="timepicker">
        <PatchedVueSelect
            ref="vSelectTimepicker"
            v-model="time"
            :clearable="false"
            :taggable="true"
            :options="timeOptions"
        >
            <template #open-indicator="{ attributes }">
                <Icon
                    class="open-timepicker"
                    name="clock"
                />
            </template>
        </PatchedVueSelect>
    </div>
</template>

<script>
import PatchedVueSelect from "@web/views/events/PatchedVueSelect";
import {
    localizedDayjs,
    TIME_FORMAT
} from "@web/lib/time-utils";

const DEFAULT_MINUTES_INTERVAL = 15;
export default {
    name: "Timepicker",
    components: {
        PatchedVueSelect
    },
    props: {
        value: {
            type: String
        },
        startAt: {
            type: String,
            default: ""
        },
        startAfter: {
            type: String,
            default: ""
        },
        minutesInterval: {
            type: Number,
            default: DEFAULT_MINUTES_INTERVAL
        }
    },
    data() {
        return {
            time: null
        };
    },
    computed: {
        dayjsStartTime() {
            if (this.startAt || this.startAfter) {
                return localizedDayjs(this.startAt || this.startAfter, TIME_FORMAT);
            }
            return localizedDayjs("00:00", "hh:mm");
        },
        startSlice() {
            return this.startAfter ? 1 : 0;
        },
        timeOptions() {
            const timeOptions = [];
            let minutes = 0;
            let dayjsTimeOption = this.dayjsStartTime;

            while (dayjsTimeOption.isSame(this.dayjsStartTime, "day")) {
                dayjsTimeOption = this.dayjsStartTime.add(minutes, "minute");
                timeOptions.push(dayjsTimeOption.format("LT"));
                minutes += Math.abs(this.minutesInterval) || DEFAULT_MINUTES_INTERVAL;
            }
            return timeOptions.slice(this.startSlice, -1);
        }
    },
    watch: {
        time() {
            this.$emit("input", this.time);
        },
        value() {
            this.time = this.value;
        },
    },
    created() {
        this.time = this.value;
    }
};
</script>

<style lang="scss" scoped>
    .timepicker {
        position: relative;

        ::v-deep .icon {
            position: absolute;
            right: 10px;
        }
    }
</style>
