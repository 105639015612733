












import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import Vue from "vue";
import { INTRANET } from "@web/store/intranet/getters";
import { mapGetters } from "vuex";

export default Vue.extend({
    name: "Logo",
    computed: {
        ...mapGetters({
            intranet: INTRANET_MODULE_NAME + INTRANET,
        }),
        logoUrl(): string {
            if (this.intranet) {
                return this.intranet.logoUrl;
            }
            return "";
        },
        displayName() {
            if (this.intranet) {
                return this.intranet.displayName;
            }
            return "";
        },
    },
});
