import { VuexRootState } from "@web/store";
import { Module } from "vuex";
import { EverestPost } from "@backend/post/types";
import {
    CLEAR_STATE,
    DELETE_POST,
    EDIT_POST,
    EXPAND_COMMENT_PARENTS,
    INIT_SINGLE_COMMENT_VIEW,
    INIT_SINGLE_POST_VIEW,
    SWITCH_FROM_COMMENT_TO_POST_VIEW,
} from "@web/store/post/single-post/actions";
import {
    APPEND_COMMENTS,
    CLEAN_STATE,
    MARK_COMMENT_AS_DELETED,
    MARK_POST_AS_DELETED,
    PREPEND_COMMENTS,
    REPLACE_COMMENT,
    REPLACE_POST_CONTENT,
    SET_MODE,
    SET_SINGLE_POST,
    SET_SINGLE_POST_COMMENT_TREE,
    SET_SINGLE_POST_WITH_COMMENT,
} from "@web/store/post/single-post/mutations";
import { commentService } from "@web/services";
import Vue from "vue";
import {
    GET_COMMENT_BY_PATH,
    IS_SHOWN_COMMENT_ROOT_COMMENT,
} from "@web/store/post/single-post/getters";
import { EverestComment } from "@backend/comment/types";
import { ContentType } from "@web/services/comments";
import { postService } from "@web/store/post/PostService";
import {
    AttachmentRootEntityType,
    EntityWithAttachmentReference,
} from "@web/services/attachments";

export interface SinglePostModuleState {
    mode?: "post" | "comment";
    post?: EverestPost;
    commentPath?: string;
    isDeleted: boolean;
}

function getDefaultState(): SinglePostModuleState {
    return {
        mode: undefined,
        post: undefined,
        commentPath: undefined,
        isDeleted: false,
    };
}

export const SINGLE_POST_MODULE_NAME = "singlePost/";

const INITIAL_COMMENTS_PAGE_SIZE = 5;

/**
 * reddit-like pagination logic for viewing a single post or a single comment via direct-url
 * allow default CRUD operations on entities, showing the parent comments and switching from comment to post view
 */
export const SINGLE_POST_MODULE: Module<SinglePostModuleState, VuexRootState> = {
    namespaced: true,
    state: () => getDefaultState(),
    getters: {
        [GET_COMMENT_BY_PATH]: state => (commentPath: string): EverestComment | EverestPost | undefined => {
            if (commentPath === "") {
                return state.post;
            }
            const comments = [] as EverestComment[];
            const getComments = (elementWithComments: { comments?: EverestComment[] } | undefined): void => {
                if (!elementWithComments?.comments?.length) {
                    return;
                }
                comments.push(...elementWithComments.comments);
                elementWithComments.comments.forEach(getComments);
            };
            getComments(state.post);
            return comments.find((c: EverestComment) => c._path === commentPath);
        },
        [IS_SHOWN_COMMENT_ROOT_COMMENT]: (state): boolean => {
            return state.mode === "comment" && !state.commentPath!.includes(".");
        },
    },
    mutations: {
        [SET_SINGLE_POST](state, { post }): void {
            state.post = post;
            state.mode = "post";
        },
        [SET_SINGLE_POST_WITH_COMMENT](state, { post, comment }): void {
            post.comments = [comment];
            post.commentCount = 1;
            state.post = post;
            state.commentPath = comment._path;
            state.mode = "comment";
        },
        [SET_MODE](state, { mode }): void {
            state.mode = mode;
        },
        [SET_SINGLE_POST_COMMENT_TREE](state, { comments }): void {
            Vue.set(state.post!, "comments", comments);
        },
        [REPLACE_POST_CONTENT](state, { content, topics }): void {
            Vue.set(state.post!, "content", content);
            Vue.set(state.post!, "topics", topics);
        },
        [MARK_POST_AS_DELETED](state): void {
            state.isDeleted = true;
        },
        [PREPEND_COMMENTS](state, { comments, parentComment }): void {
            parentComment.comments = [...comments, ...(parentComment.comments || [])];
        },
        [APPEND_COMMENTS](state, { comments, parentComment }): void {
            parentComment.comments = [...(parentComment.comments || []), ...comments];
        },
        [REPLACE_COMMENT](state, { comment, parentComment }): void {
            const commentIndex = parentComment.comments.findIndex((c: EverestComment) => c.uid === comment.uid);
            Vue.set(parentComment.comments, commentIndex, { ...parentComment.comments[commentIndex], ...comment });
        },
        [MARK_COMMENT_AS_DELETED](state, { comment }): void {
            comment.isDeleted = true;
        },
        [CLEAN_STATE](state): void {
            Object.assign(state, getDefaultState());
        },
    },
    actions: {
        async [INIT_SINGLE_POST_VIEW]({ commit, rootState }, { postUid }): Promise<void> {
            const intranet = rootState.intranet.intranet;
            commit(SET_SINGLE_POST, { post: undefined }); // Reset to post to have an empty posts in error cases (HALO-2962)
            const { post, lastViewed } = await postService.getPost(intranet!.uid, postUid, INITIAL_COMMENTS_PAGE_SIZE);
            commit(SET_SINGLE_POST, { post });
        },
        async [INIT_SINGLE_COMMENT_VIEW]({ commit, rootState }, { postUid, commentPath }): Promise<void> {
            const intranet = rootState.intranet.intranet;
            commit(SET_SINGLE_POST, { post: undefined }); // Reset to post to have an empty posts in error cases (HALO-2962)
            const { post, lastViewed } = await postService.getPost(intranet!.uid, postUid, INITIAL_COMMENTS_PAGE_SIZE);
            const { droppedSegment, trimmedCommentPath } = moveUpHierarchyOnCommentPath(commentPath);
            const comments = await commentService.getComments(intranet!.uid, ContentType.post, postUid, trimmedCommentPath, INITIAL_COMMENTS_PAGE_SIZE, undefined, droppedSegment);
            const comment = comments[0];
            commit(SET_SINGLE_POST_WITH_COMMENT, { post, comment });
        },
        async [SWITCH_FROM_COMMENT_TO_POST_VIEW]({ commit, state, rootState }): Promise<void> {
            const intranet = rootState.intranet.intranet;
            const { post, lastViewed } = await postService.getPost(intranet!.uid, state.post!.uid, INITIAL_COMMENTS_PAGE_SIZE);
            const comments = post.comments;
            commit(SET_SINGLE_POST_COMMENT_TREE, { comments });
            commit(SET_MODE, { mode: "post" });
        },
        async [EXPAND_COMMENT_PARENTS]({ state, rootState, commit }): Promise<void> {
            const intranet = rootState.intranet.intranet;
            const parentCommentUids = state.commentPath!.split(".").filter(uid => uid !== state.post!.comments![0].uid);
            const length = parentCommentUids.length;
            const parentCommentsPromises = [];
            for (let i = 0; i < length; i++) {
                const commentUid = parentCommentUids.pop();
                parentCommentsPromises.push(commentService.getComments(intranet!.uid, ContentType.post, state.post!.uid, parentCommentUids.join("."), 1, undefined, commentUid));
            }
            const comments = await Promise.all(parentCommentsPromises);
            const plainComments = comments.map(c => {
                return { ...c[0], comments: [], commentCount: 1 };
            });
            plainComments.reverse();
            const firstLevelComment = JSON.parse(JSON.stringify(plainComments[0]));
            [...plainComments, state.post!.comments![0]].forEach((c, idx) => {
                if (idx === 0) {
                    return;
                }
                let commentRef = firstLevelComment;
                for (let i = 0; i < idx - 1; i++) {
                    commentRef = commentRef.comments[0];
                }
                commentRef.comments = [c];
            });
            commit(SET_SINGLE_POST_COMMENT_TREE, { comments: [firstLevelComment] });
        },
        async [EDIT_POST]({ commit, state, rootState }, { content, topics, attachmentManager }): Promise<void> {
            const intranetUid = rootState.intranet.intranet!.uid;
            const updatedPost = await postService.editPost({ intranetUid, postUid: state.post!.uid, content, topics });
            await attachmentManager.save(
                new EntityWithAttachmentReference({
                    intranetUid,
                    entityType: AttachmentRootEntityType.post,
                    entityUid: updatedPost.uid,
                    entity: updatedPost,
                }),
                updatedPost.creatorUid,
            );
            commit(REPLACE_POST_CONTENT, updatedPost);
        },
        async [DELETE_POST]({ commit, state, rootState }): Promise<void> {
            const intranet = rootState.intranet.intranet;
            await postService.deletePost(intranet!.uid, state.post!.uid);
            commit(MARK_POST_AS_DELETED);
        },
        [CLEAR_STATE]({ commit }): void {
            commit(CLEAN_STATE);
        },
    },
};

function moveUpHierarchyOnCommentPath(commentPath: string): { droppedSegment?: string, trimmedCommentPath: string } {
    const segments = commentPath.split(".");
    const droppedSegment = segments.pop();
    const trimmedCommentPath = segments.join(".");
    return { droppedSegment, trimmedCommentPath };
}
