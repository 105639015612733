<template>
    <pu-skeleton
        :loading="loading"
        height="calc(16px + 1rem)"
        width="12rem"
        class="border-radius-small mright-xsmall mbottom-xsmall"
    >
        <deletable-wrapper
            :key="attachment.localUid"
            v-tooltip.bottom="tooltipText"
            :edit-mode="editMode"
            transition-name="moveInFromRight"
            class="attachment file-attachment border-radius-small background-lowest-contrast attachment"
            @delete="$emit('delete', attachment)"
        >
            <a
                :href="url"
                download
                target="_blank"
                rel="noopener noreferrer"
                class="flex padding-xsmall small-text"
            >
                <icon
                    name="paperclip"
                    size="x-small"
                    class="mright-xxsmall"
                    color="var(--highest-contrast)"
                />
                <span>{{ name }}</span>
            </a>
        </deletable-wrapper>
    </pu-skeleton>
</template>

<script>
import { LoadingAttachmentReference } from "@/components/attachments/attachment-manager";
import DeletableWrapper from "@web/components/attachments/DeletableFrame";

const maxFileNameLength = 25;
const trimmedFileNameOffset = 3;

export default {
    name: "FileAttachment",
    components: { DeletableWrapper },
    props: {
        /**
         * @type {AttachmentReference}
         */
        attachment: Object,
        editMode: Boolean,
    },
    computed: {
        name() {
            const extensionStartIndex = this.attachment.name.lastIndexOf(".");
            if (extensionStartIndex < 0) {
                return this.attachment.name.length <= maxFileNameLength
                    ? this.attachment.name
                    : this.attachment.name.substring(0, maxFileNameLength) + "...";
            }

            if (extensionStartIndex > maxFileNameLength) {
                const fileName = this.attachment.name.substring(0, maxFileNameLength - trimmedFileNameOffset) + "...";
                const extension = this.attachment.name.substring(extensionStartIndex - trimmedFileNameOffset);
                return fileName + extension;
            }

            return this.attachment.name;
        },
        loading() {
            return this.attachment instanceof LoadingAttachmentReference;
        },
        url() {
            if (this.editMode || this.loading) return;
            return `${this.attachment.getUrl()}&response-content-disposition=filename=${this.attachment.name.replace(",", " ")};`;
        },
        tooltipText() {
            if (this.editMode || this.attachment.name === this.name) return;
            return this.attachment.name;
        }
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .delete-button-container {
        .remove-icon {
            width: 18px !important;
            height: 18px !important;
        }

        svg {
            margin: auto;
            width: 14px;
            height: inherit;
        }
    }
}

.file-attachment {
    transition: background-color .2s linear;

    &:hover {
        background-color: var(--low-contrast);
    }
}
</style>
