import { ApiClient } from "@web/api/ApiClient";
import { ApiClientBuilder } from "@web/api/ApiClientBuilder";
import { analytics } from "@web/analytics";
import { getGlobalConfiguration } from "@web/global-config";
import { cloudRunUrl } from "@web/cloud-run-url";

export class GoogleCalendarConnectService {
    public static readonly googleCalendarConnectionEndpoint = `${cloudRunUrl.thirdPartyGoogleCalendar}/api/googleCalendar/`;

    private readonly api: Promise<ApiClient>;

    constructor() {
        this.api = new ApiClientBuilder().build();
    }

    public async getIntranetConnectionStatus(intranetUid: string): Promise<boolean> {
        const client = await this.api;
        const response = await client.get<boolean>(`${GoogleCalendarConnectService.googleCalendarConnectionEndpoint + intranetUid}/connect-intranet-status`);
        return response.data;
    }

    public async connect(intranetUid: string): Promise<void> {
        const client = await this.api;
        await client.post(`${GoogleCalendarConnectService.googleCalendarConnectionEndpoint + intranetUid}/connect-intranet`);
        analytics.log(getGlobalConfiguration().analytics_event_name_google_calendar_connected_by_admin);
    }

    public async removeConnection(intranetUid: string): Promise<void> {
        const client = await this.api;
        await client.delete<void>(`${GoogleCalendarConnectService.googleCalendarConnectionEndpoint + intranetUid}/connect-intranet`);
        analytics.log(getGlobalConfiguration().analytics_event_name_google_calendar_revoked_by_admin);
    }

    /**
     * Starts the OAuth2 flow with a popup window.
     * The returned promise resolves when the flow was completed and access granted and rejects on errors and when the user aborts.
     * This is listening for a message at the {@link BroadcastChannel} named `connect-google-user` to complete the flow.
     * @see google-management.ts implementing the callback endpoint to finish the OAuth2 flow.
     * @see BroadcastChannel.vue redirect by the callback endpoint which sends a message via {@link BroadcastChannel}.
     */
    public async startUserConnectionFlow(intranetUid: string): Promise<void> {
        const client = await this.api;
        const response = await client.get<string>(`${GoogleCalendarConnectService.googleCalendarConnectionEndpoint + intranetUid}/connect-user`);
        analytics.log(getGlobalConfiguration().analytics_event_name_google_calendar_connected_by_user);
        return new Promise((resolve, reject) => {
            const popup = window.open(response.data);
            // Reject when window does not open.
            if (popup === null) return reject();
            // Reject when user closes the window. But sadly it does not work as it is immediately called when the page loads (maybe from Google).
            // popup.onunload = () => reject();
            const channel = new BroadcastChannel("connect-google-user");
            channel.addEventListener("message", ({ data }) => {
                // popup.onunload = null;
                popup.close();
                channel.close();
                // Resolve when flow was completed.
                if (data.data === "connect_with_google_calendar_connection_success") resolve();
                // Reject when access was denied and flow completed.
                else reject();
            });
            // Reject on unexpected errors while using the BroadcastChannel.
            channel.addEventListener("messageerror", () => reject());
        });
    }

    public async getUserConnectionStatus(intranetUid: string): Promise<boolean> {
        const client = await this.api;
        const response = await client.get<boolean>(`${GoogleCalendarConnectService.googleCalendarConnectionEndpoint + intranetUid}/connect-user-status`);
        return response.data;
    }

    public async removeUserConnection(intranetUid: string) {
        const client = await this.api;
        await client.delete<void>(`${GoogleCalendarConnectService.googleCalendarConnectionEndpoint + intranetUid}/connect-user`);
        analytics.log(getGlobalConfiguration().analytics_event_name_google_calendar_revoked_by_user);
    }
}
