






























import Vue from "vue";
import Modal from "@web/components/modals/Modal.vue";
import Button from "@web/components/Button.vue";

export default Vue.extend({
    name: "CloseNewsModal",
    components: {
        Button,
        Modal
    },
    methods: {
        triggerClose() {
            this.$emit("close");
        },
        triggerCancel() {
            this.$modal.hide("close-news-modal");
            this.$emit("cancel");
        }
    }
});

