<template>
    <section>
        <router-view />
    </section>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
    name: "AtlassianCloudWrapper",
});
</script>
