import { ApiClient } from "@web/api/ApiClient";
import { ApiClientBuilder } from "@web/api/ApiClientBuilder";
import {
    ReactableRootEntityType,
    ReactionInFirestore,
    ReactionsPaginationSearchParameters,
    ReactionType
} from "@backend/reaction/types";
import { AxiosResponse } from "axios";
import { cloudRunUrl } from "@web/cloud-run-url";
import { ReactionFilterKey } from "@web/store/reaction/reaction";
import { analytics } from "@web/analytics";
import { getGlobalConfiguration } from "@web/global-config";

export class ReactionService {
    public static readonly reactionServiceEndpoint = `${cloudRunUrl.reaction}/api/reaction`;

    private api: Promise<ApiClient>;

    constructor() {
        this.api = new ApiClientBuilder().build();
    }

    public async react(intranetUid: string, entityType: ReactableRootEntityType, reactableRootEntityUid: string, path: string, reactionType: ReactionType | null) {
        const client = await this.api;
        const url = `${ReactionService.reactionServiceEndpoint}/${intranetUid}/${entityType}/${reactableRootEntityUid}/` + path || "";
        const response: AxiosResponse = await client.put(url, { reactionType });
        analytics.log(getGlobalConfiguration().analytics_event_name_react_on_entity, { reactionType, entityType, intranetUid });
        return response.data;
    }

    public async getReactions(intranetUid: string, entityUid: string, searchParams: ReactionsPaginationSearchParameters, entityType: ReactionFilterKey): Promise<ReactionInFirestore[]> {
        const client = await this.api;
        const url = `${ReactionService.reactionServiceEndpoint}/${intranetUid}/${entityUid}/` + (entityType !== "all" ? entityType : "");
        const response: AxiosResponse = await client.get(url, { params: searchParams });
        return response.data;
    }
}
