<template>
    <span class="search-container">
        <input
            ref="input"
            v-model="searchTerm"
            :placeholder="placeholder"
            class="search-input"
            type="text"
            @focusin="debouncedFocussed(true)"
            @focusout="debouncedFocussed(false)"
            v-on="inputListeners"
        />
        <span class="search-input-actions">
            <a
                v-if="!isEmpty"
                class="mright-xsmall d-inline-block"
                href="#"
                @click.prevent="clear"
            >
                <Icon name="x"/>
            </a>
            <Icon
                v-if="!busy"
                :class="{primary: focussed}"
                name="search"
            />
            <spinner
                v-if="busy"
                inline
                size="24"
            />
        </span>
    </span>
</template>
<script>
import debounce from "lodash/debounce";
import Spinner from "@web/components/Spinner";

/** An acceptable delay for UI to recover lost focus on the input field without firing an event within Vue. */
const focusLostThresholdMs = 100;

/**
 * A search specialiced input.
 * Any listeners are directed to the input field.
 */
export default {
    name: "SearchInput",
    components: {
        Spinner,
    },
    inheritAttrs: false,
    props: {
        /** Placeholder for empty input. */
        placeholder: String,
        /** The search terms.
         * @model */
        value: { type: String, default: "" },
        /** Replaces the search icon with a spinner. */
        busy: Boolean,
        /** Synced prop to change focus and fires on focus changes.
         * @synced */
        focus: Boolean,
    },
    data() {
        return {
            searchTerm: this.value,
            focussed: false,
        };
    },
    computed: {
        isEmpty() {
            return this.searchTerm.length === 0;
        },
        // Listeners to the input field, which omits the `input` listener to still work with `v-model`.
        inputListeners() {
            const { input, ...listeners } = this.$listeners;
            return listeners;
        },
    },
    watch: {
        value(value) {
            this.searchTerm = value;
        },
        searchTerm: debounce(function(term) {
            /** Fired on press of enter or click on search icon.
             * @property {string} term */
            return this.$emit("input", term);
        }, 500),
        focus: {
            handler(focus) {
                if (!this.$refs.input) return;
                if (focus) {
                    this.$refs.input.focus();
                } else {
                    this.$refs.input.blur();
                }
            },
            immediate: true,
        },
        focussed(focussed) {
            /** Fired on focus change of the input.
             * @property {boolean} focussed */
            this.$emit("update:focus", focussed);
        },
    },
    mounted() {
        if (this.focus) this.$refs.input.focus();
    },
    created() {
        // The debounce function MUST be created once per instance. Otherwise the closure state of the debounce function is shared between all instances of the Vue component.
        this.debouncedFocussed = debounce((focussed) => {
            this.focussed = focussed;
        }, focusLostThresholdMs);
    },
    methods: {
        /** Used to queue the focus state for a tiny bit in order to not fire rapid changes. */
        debouncedFocussed() {
            // placeholder for IDE support, overwritten in created to be debounced per instance.
        },
        /** Clears and also undos lost focus and puts focus back to the input. */
        clear() {
            // undo lost focus state a tiny bit to refocus without firing an event.
            this.debouncedFocussed(true);
            this.$refs.input.focus();
            this.searchTerm = "";
        },
    },
};
</script>
<style lang="scss" scoped>
.search-container {
    font-size: 1rem;
    position: relative;

    .search-input {
        height: 2.5rem;
        width: 20rem;
        background: var(--lowest-contrast);
        border: none;
        padding-right: 2rem;
    }

    .search-input-actions {
        --icon-color: #{$medium-contrast};
        line-height: 1;
        transform: translate(0, -50%);
        position: absolute;
        top: 50%;
        right: $spacing-xsmall;
    }
}
</style>
<docs>
Minimal:
```vue
<search-input/>
```

With placeholder:
```vue
<search-input placeholder="Type to search"/>
```

Busy:
```vue
<search-input busy/>
```

Using `v-model`:
```vue
<template>
    <div>
        <p>
            <search-input
                v-model="value"
                placeholder="press Enter or click icon"
            />
        </p>
        <p>Value: {{ value }}</p>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                value: "",
            };
        },
    };
</script>
```

React to focus changes:
```vue
<template>
    <div>
        <p>
            <search-input
                :focus.sync="focus"
                placeholder="press Enter or click icon"
                @keydown.esc="focus = false"
            />
        </p>
        <p>
            <button class="button" @click="focus = true">set focus</button>
        </p>
        <p>Press esc to loose focus.</p>
        <p>Focus: {{ focus }}</p>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                focus: false,
            };
        },
    };
</script>
```
</docs>
