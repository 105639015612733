import extend from "extend";
import Emitter from "quill/core/emitter";
import BaseTheme,
{ BaseTooltip } from "quill/themes/base";
import LinkBlot from "quill/formats/link";
import { Range } from "quill/core/selection";
import icons from "quill/ui/icons";
import { i18n } from "@web/i18n";

const TOOLBAR_CONFIG = [
    [{ header: ["1", "2", "3", false] }],
    ["bold", "italic", "underline", "link"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["clean"]
];

class FujiTheme extends BaseTheme {
    constructor(quill, options) {
        if (options.modules.toolbar != null && options.modules.toolbar.container == null) {
            options.modules.toolbar.container = TOOLBAR_CONFIG;
        }
        super(quill, options);
        this.quill.container.classList.add("ql-fuji");
        this.addModule("image-selection");
    }

    extendToolbar(toolbar) {
        this.quill.container.classList.add("ql-fuji");
        toolbar.container.classList.add("ql-fuji");
        this.buildButtons([].slice.call(toolbar.container.querySelectorAll("button")), icons);
        this.buildPickers([].slice.call(toolbar.container.querySelectorAll("select")), icons);
        this.tooltip = new FujiTooltip(this.quill, this.options.bounds);
        if (toolbar.container.querySelector(".ql-link")) {
            this.quill.keyboard.addBinding({ key: "K", shortKey: true }, function(range, context) {
                toolbar.handlers.link.call(toolbar, !context.format.link);
            });
        }
    }
}

FujiTheme.DEFAULTS = extend(true, {}, BaseTheme.DEFAULTS, {
    modules: {
        toolbar: {
            handlers: {
                link: function(value) {
                    if (value) {
                        const range = this.quill.getSelection();
                        // this prevents opening the link dialog if no text is selected which especially necessary for images where we don't support links
                        const [text] = this.quill.scroll.descendant(Quill.imports["blots/text"], range.index);
                        if (range.length === 0 || text === null) return;
                        let preview = this.quill.getText(range);
                        if (/^\S+@\S+\.\S+$/.test(preview) && preview.indexOf("mailto:") !== 0) {
                            preview = "mailto:" + preview;
                        }
                        const tooltip = this.quill.theme.tooltip;
                        // tooltip.edit('link', preview); -> we don't want this default behaviour
                        tooltip.edit("link", "");
                    } else {
                        this.quill.format("link", false);
                    }
                }
            }
        }
    }
});

class FujiTooltip extends BaseTooltip {
    constructor(quill, bounds) {
        super(quill, bounds);
        this.preview = this.root.querySelector("a.ql-preview");
    }

    listen() {
        super.listen();
        this.root.querySelector("a.ql-action").addEventListener("click", (event) => {
            if (this.root.classList.contains("ql-editing")) {
                this.save();
            } else {
                this.edit("link", this.preview.textContent);
            }
            event.preventDefault();
        });
        this.root.querySelector("a.ql-remove").addEventListener("click", (event) => {
            if (this.linkRange != null) {
                const range = this.linkRange;
                this.restoreFocus();
                this.quill.formatText(range, "link", false, Emitter.sources.USER);
                delete this.linkRange;
            }
            event.preventDefault();
            this.hide();
        });
        this.root.querySelector("a.fuji-link-open").addEventListener("click", (event) => {
            this.root.querySelector("a.fuji-link-preview").click();
            event.preventDefault();
            this.hide();
        });
        this.root.querySelector("a.fuji-link-save").addEventListener("click", (event) => {
            this.save();
            event.preventDefault();
            this.hide();
        });
        this.quill.on(Emitter.events.SELECTION_CHANGE, (range, oldRange, source) => {
            if (range == null) return;
            if ((range.length === 0) && source === Emitter.sources.USER) {
                const [link, offset] = this.quill.scroll.descendant(Quill.imports["formats/link"], range.index);
                if (link != null) {
                    this.linkRange = new Range(range.index - offset, link.length());
                    const preview = LinkBlot.formats(link.domNode);
                    this.preview.textContent = preview;
                    this.preview.setAttribute("href", preview);
                    this.show();
                    this.position(this.quill.getBounds(this.linkRange));
                    return;
                }
            } else {
                delete this.linkRange;
            }
            this.hide();
        });
    }

    show() {
        super.show();
        this.root.removeAttribute("data-mode");
    }
}

FujiTooltip.TEMPLATE = [
    "<input class=\"fuji-link-input\" type=\"text\" data-link=\"https://example.com\" data-video=\"Embed URL\">",
    `<a class="ql-action fuji-link-action">${i18n.t("edit_link")}</a>`,
    `<a class="fuji-link-open">${require("@web/assets/icons/external-link.svg")}</a>`,
    `<a class="ql-remove fuji-link-remove">${require("@web/assets/icons/trash.svg")}</a>`,
    `<a class="ql-save fuji-link-save">${require("@web/assets/icons/save.svg")}</a>`,
    `<a class="ql-preview fuji-link-preview" rel="noopener noreferrer" target="_blank" href="about:blank">${require("@web/assets/icons/event.svg")}</a>`
].join("");

export default FujiTheme;
