
import Vue,
{ VNode } from "vue";
import {
    mapActions,
    mapMutations
} from "vuex";
import { PEOPLE_MODULE_NAME } from "@web/store/people/people";
import { RESET_PEOPLE_MODULE } from "@web/store/people/mutations";
import { SOCIAL_NOTIFICATION_MODULE_NAME } from "@web/store/social-notifications/social-notifications";
import {
    SUBSCRIBE,
    UNSUBSCRIBE
} from "@web/store/social-notifications/actions";

/**
 * see jsDocs of ContentUpdateObserver
 */
export default Vue.extend({
    name: "IntranetLifecycleObserver",
    created() {
        this.subscribeSocialNotifications();
    },
    beforeDestroy() {
        this.reset();
        this.unsubscribeSocialNotifications();
    },
    render() {
        return null as unknown as VNode;
    },
    methods: {
        ...mapMutations({
            reset: PEOPLE_MODULE_NAME + RESET_PEOPLE_MODULE,
        }),
        ...mapActions({
            subscribeSocialNotifications: SOCIAL_NOTIFICATION_MODULE_NAME + SUBSCRIBE,
            unsubscribeSocialNotifications: SOCIAL_NOTIFICATION_MODULE_NAME + UNSUBSCRIBE,
        }),
    },
});
