import { cloudRunUrl } from "@web/cloud-run-url";
import { ApiClientBuilder } from "@web/api/ApiClientBuilder";
import { ApiClient } from "@web/api/ApiClient";
import { analytics } from "@web/analytics";
import { getGlobalConfiguration } from "@web/global-config";
import {
    EverestPost,
    PostPayload,
} from "@backend/post/types";
import { AxiosResponse } from "axios";

export interface SearchPostsParams {
    intranetUid: string;
    limit?: number;
    withComments?: number;
    startAfter?: string;
    topics?: string[],
}

export interface StatefulEverestPost extends EverestPost {
    _new?: boolean;
}

export interface PostListingResponse {
    lastViewed?: number;
    posts: StatefulEverestPost[];
}

export class PostService {
    public static readonly postServiceEndpoint = `${cloudRunUrl.post}/api/post`;

    private readonly api: Promise<ApiClient>;

    constructor() {
        this.api = new ApiClientBuilder().build();
    }

    public async getPost(intranetUid: string, postUid: string, withComments?: number): Promise<{ lastViewed?: number, post: StatefulEverestPost }> {
        const client = await this.api;
        const response = await client.get<EverestPost>(`${PostService.postServiceEndpoint}/${intranetUid}/${postUid}`, {
            params: {
                format: "web",
                withComments,
            },
        });
        return {
            lastViewed: getLastViewedHeader(response),
            post: response.data,
        };
    }

    public async searchPosts({ intranetUid, topics, limit, withComments, startAfter }: SearchPostsParams): Promise<PostListingResponse> {
        const client = await this.api;
        const response = await client.post<EverestPost[]>(`${PostService.postServiceEndpoint}/${intranetUid}/search`, {
            topics,
        }, {
            params: {
                format: "web",
                limit,
                withComments,
                startAfter,
            },
        });
        return {
            lastViewed: getLastViewedHeader(response),
            posts: response.data,
        };
    }

    public async createPost(intranetUid: string): Promise<StatefulEverestPost> {
        const client = await this.api;
        const response = await client.post<EverestPost>(`${PostService.postServiceEndpoint}/${intranetUid}`);
        return response.data;
    }

    public async editPost({ intranetUid, postUid, content, topics }: { intranetUid: string, postUid: string } & PostPayload): Promise<StatefulEverestPost> {
        const client = await this.api;
        const response = await client.put<EverestPost>(`${PostService.postServiceEndpoint}/${intranetUid}/${postUid}?format=web`, { content, topics });
        return response.data;
    }

    public async deletePost(intranetUid: string, postUid: string): Promise<void> {
        const client = await this.api;
        await client.delete(`${PostService.postServiceEndpoint}/${intranetUid}/${postUid}`);
        analytics.log(getGlobalConfiguration().analytics_event_name_post_deleted);
    }
}

function getLastViewedHeader(response: AxiosResponse): number | undefined {
    const lastViewedHeader = response.headers["x-last-viewed"];
    return parseInt(lastViewedHeader) || undefined;
}

export const postService = new PostService();
