<template>
    <v-popover
        v-if="currentUser"
        ref="popover"
        placement="top-end"
        popover-arrow-class="tooltip-arrow popover-arrow arrow-top"
        popover-class="no-side-padding"
    >
        <avatar
            class="cursor-pointer"
            size="medium"
            v-bind="currentUser"
        />
        <template slot="popover">
            <div class="avatar-menu">
                <div class="ellipsis entry d-block">
                    👋&nbsp; {{ $t("hey", [currentUser.displayName]) }}
                </div>
                <div class="divider"></div>
                <user-link
                    class="entry"
                    v-bind="currentUser"
                    :v-card-on-hover="false"
                    @click.native="hideMenu"
                >
                    <icon name="user"/>
                    <div class="text">
                        {{ $t("my_profile") }}
                    </div>
                </user-link>
                <a
                    class="entry"
                    href="https://www.seibert.biz/cloudhelp"
                    target="_blank"
                >
                    <icon name="help-circle"/>
                    <div class="text">{{ $t("help") }}</div>
                </a>
                <div class="divider"></div>
                <router-link
                    :to="{ name: 'usersettings' }"
                    class="entry"
                    @click.native="hideMenu"
                >
                    <icon name="sliders"/>
                    <div class="text">
                        {{ $t("my_settings") }}
                    </div>
                </router-link>
                <router-link
                    v-if="isAdmin"
                    :to="{ name: 'general' }"
                    class="entry"
                    @click.native="hideMenu"
                >
                    <icon name="settings"/>
                    <div class="text">
                        {{ $t("title_administration") }}
                    </div>
                </router-link>
                <div class="divider"></div>
                <router-link
                    class="entry"
                    to="/"
                    @click.native="hideMenu"
                >
                    <icon name="log-out"/>
                    <div class="text">
                        {{ $t("other_intranets") }}
                    </div>
                </router-link>
            </div>
        </template>
    </v-popover>
</template>

<script>
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import {
    INTRANET_UID,
    IS_ADMIN,
} from "@web/store/intranet/getters";
import { mapGetters } from "vuex";
import { PROFILE_MODULE_NAME } from "@web/store/profile/profile";
import { CURRENT_USER_PROFILE } from "@web/store/profile/getters";
import Avatar from "@web/components/Avatar";
import UserLink from "@web/components/user/UserLink";

export default {
    name: "AvatarMenu",
    components: { UserLink, Avatar },
    computed: {
        ...mapGetters({
            isAdmin: INTRANET_MODULE_NAME + IS_ADMIN,
            currentUser: PROFILE_MODULE_NAME + CURRENT_USER_PROFILE,
            intranetUid: INTRANET_MODULE_NAME + INTRANET_UID,
        }),
    },
    methods: {
        openModal(name) {
            this.hideMenu();
            this.$modal.show(name);
        },
        hideMenu() {
            if (this.$refs.popover) {
                this.$refs.popover.hide();
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.avatar-menu {
    min-width: 10rem;
    max-width: 25rem;

    .divider {
        margin: 0.5rem 0;
    }

    .entry {
        text-decoration: none;
        padding: 0.5rem 1rem;
        align-items: center;
    }

    a.entry {
        display: flex;

        &:hover {
            background-color: var(--lowest-contrast, #{$off-white});
        }
    }

    .icon + .text {
        padding: 0 0 0 0.5rem;
    }

    .text {
        text-overflow: ellipsis;
    }
}
</style>
