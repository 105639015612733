import { render, staticRenderFns } from "./PageHeader.vue?vue&type=template&id=d87f876a&scoped=true&"
import script from "./PageHeader.vue?vue&type=script&lang=js&"
export * from "./PageHeader.vue?vue&type=script&lang=js&"
import style0 from "./PageHeader.vue?vue&type=style&index=0&id=d87f876a&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d87f876a",
  null
  
)

/* custom blocks */
import block0 from "./PageHeader.vue?vue&type=custom&index=0&blockType=docs"
if (typeof block0 === 'function') block0(component)

export default component.exports