<template ></template>

<script>

export default {
    name: "EventModalRoute",
    mounted() {
        this.$modal.show("event-modal", {
            eventUid: this.$route.params.eventUid,
        });
    },
    beforeDestroy() {
        this.$modal.hide("event-modal");
    }
};
</script>
