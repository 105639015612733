import { ApiClient } from "@web/api/ApiClient";
import { ApiClientBuilder } from "@web/api/ApiClientBuilder";
import { EntityWithAttachmentReference } from "@web/services/attachments";
import {
    AnySharedContentData,
    SharedContent,
} from "@backend/attachment/shared-content/types";
import { getGlobalConfiguration } from "@web/global-config";
import { analytics } from "@web/analytics";
import { buildUrl } from "@web/lib/entity-path-builder";
import { cloudRunUrl } from "@web/cloud-run-url";

export type SharedContentRefData = AnySharedContentData & { uid: string };

export class SharedContentService {
    private static readonly SHARED_CONTENT_SERVICE_ENDPOINT = `${cloudRunUrl.attachment}/api/external-content`;

    private readonly api: Promise<ApiClient>;

    constructor() {
        this.api = new ApiClientBuilder().build();
    }

    async getSharedContents(rootEntityReference: EntityWithAttachmentReference): Promise<SharedContent[]> {
        if (rootEntityReference.hasNoSharedContent()) {
            return [];
        }
        const api = await this.api;
        const sharedContents = await api.get<SharedContent[]>(buildUrl(SharedContentService.SHARED_CONTENT_SERVICE_ENDPOINT, rootEntityReference));
        return sharedContents.data;
    }

    async addSharedContent(rootEntityReference: EntityWithAttachmentReference, sharedContent: SharedContentRefData): Promise<void> {
        const api = await this.api;
        await api.post(buildUrl(SharedContentService.SHARED_CONTENT_SERVICE_ENDPOINT, rootEntityReference), sharedContent);
        trackAddedSharedContent(rootEntityReference, sharedContent);
    }

    async deleteSharedContent(rootEntityReference: EntityWithAttachmentReference, sharedContent: SharedContentRefData): Promise<void> {
        const api = await this.api;
        await api.delete(buildUrl(SharedContentService.SHARED_CONTENT_SERVICE_ENDPOINT, rootEntityReference, sharedContent.uid));
        trackRemovedSharedContent(rootEntityReference, sharedContent);
    }
}

function trackAddedSharedContent(rootEntityReference: EntityWithAttachmentReference, sharedContent: SharedContentRefData): void {
    const globalConfiguration = getGlobalConfiguration() as unknown as Record<string, string | undefined>;
    const { platform, type } = sharedContent.metaData as Record<string, string>;
    analytics.log(globalConfiguration.analytics_event_name_shared_content_created, {
        rootType: rootEntityReference.entityType,
        displayType: sharedContent.displayType,
        metadata: { platform, type },
    });
}

function trackRemovedSharedContent(rootEntityReference: EntityWithAttachmentReference, sharedContent: SharedContentRefData): void {
    const globalConfiguration = getGlobalConfiguration() as unknown as Record<string, string | undefined>;
    analytics.log(globalConfiguration.analytics_event_name_shared_content_deleted, {
        rootType: rootEntityReference.entityType,
        displayType: sharedContent.displayType,
    });
}
