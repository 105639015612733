import firebase from "firebase";
import FirestoreDataConverter = firebase.firestore.FirestoreDataConverter;
import QueryDocumentSnapshot = firebase.firestore.QueryDocumentSnapshot;
import DocumentData = firebase.firestore.DocumentData;
import SnapshotOptions = firebase.firestore.SnapshotOptions;

export function castConverter<T>(): FirestoreDataConverter<T> {
    return {
        toFirestore(modelObject: T): DocumentData {
            return modelObject;
        },
        fromFirestore(snapshot: QueryDocumentSnapshot, options: SnapshotOptions): T {
            return snapshot.data() as T;
        },
    };
}
