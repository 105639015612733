<template>
    <deletable-wrapper
        v-if="sharedContent"
        :edit-mode="editMode"
        @delete="$emit('delete')"
    >
        <component
            :is="component"
            v-bind="componentProps"
            :class="{'pointer-events-none': editMode}"
        />
    </deletable-wrapper>
</template>

<script>
import {
    DisplayType,
    SharableContentEntity,
    VideoPlatform,
} from "@backend/attachment/shared-content/types";
import YoutubeEmbed from "@/components/attachments/shared-content/YoutubeEmbed";
import SharedEvent from "@web/components/attachments/shared-content/SharedEvent";
import DeletableWrapper from "@web/components/attachments/DeletableFrame";
import SharedNews from "@web/components/attachments/shared-content/SharedNews";
import MissingFeaturedContent from "@web/components/attachments/shared-content/MissingFeaturedContent";
import SharedTeam from "@web/components/attachments/shared-content/SharedTeam";

export default {
    name: "SharedAttachmentComponent",
    components: {
        DeletableWrapper,
        YoutubeEmbed,
    },
    props: {
        /** @type {SharedContent} */
        sharedContent: Object,
        editMode: Boolean,
    },
    computed: {
        componentProps() {
            return {
                url: this.sharedContent.url,
                ...this.sharedContent.metaData,
            };
        },
        component() {
            if (this.sharedContent.displayType === DisplayType.video) {
                if (this.sharedContent.metaData.platform === VideoPlatform.youtube) {
                    return YoutubeEmbed;
                }
            }
            if (this.sharedContent.displayType === DisplayType.contentEntity) {
                switch (this.sharedContent.metaData.type) {
                    case SharableContentEntity.event:
                        return SharedEvent;
                    case SharableContentEntity.news:
                        return SharedNews;
                    case SharableContentEntity.team:
                        return SharedTeam;
                }
            }
            return MissingFeaturedContent;
        },
    },
};
</script>

<style lang="scss" scoped>

</style>
