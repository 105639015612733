import { ApiClientBuilder } from "@web/api/ApiClientBuilder";
import { ApiClient } from "@web/api/ApiClient";
import { cloudRunUrl } from "@web/cloud-run-url";
import { FollowableType } from "@backend/follow/types";
import { analytics } from "@web/analytics";
import { getGlobalConfiguration } from "@web/global-config";

export class FollowService {
    public static readonly followEndpoint = `${cloudRunUrl.follow}/api/follow`;

    private api: Promise<ApiClient>;

    constructor() {
        this.api = new ApiClientBuilder().build();
    }

    public async follow({ intranetUid, followableType, contentUid }: { intranetUid: string, followableType: FollowableType, contentUid: string }) {
        const client = await this.api;
        analytics.log(getGlobalConfiguration().analytics_event_name_follow_entity);
        return client.post(`${FollowService.followEndpoint}/${intranetUid}/${followableType}/${contentUid}`);
    }

    public async unfollow({ intranetUid, followableType, contentUid }: { intranetUid: string, followableType: FollowableType, contentUid: string }) {
        const client = await this.api;
        analytics.log(getGlobalConfiguration().analytics_event_name_unfollow_entity);
        return client.delete(`${FollowService.followEndpoint}/${intranetUid}/${followableType}/${contentUid}`);
    }

    public async isFollowing({ intranetUid, followableType, contentUid }: { intranetUid: string, followableType: FollowableType, contentUid: string }) {
        const client = await this.api;
        const axiosResponse = await client.get(`${FollowService.followEndpoint}/${intranetUid}/${followableType}/${contentUid}`);
        return axiosResponse.data as boolean;
    }
}
