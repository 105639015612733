<template>
    <div v-if="coverNews.length">
        <swiper
            ref="newsSwiper"
            :options="swiperOptions"
            :cleanup-styles-on-destroy="false"
        >
            <swiper-slide
                v-for="(news, newsIndex) in coverNews"
                :key="news.uid"
            >
                <router-link
                    :to="'/intranet/' + intranet.uid + '/news/id/' + news.uid"
                >
                    <NewsTeaser
                        :key="news.uid"
                        :news="news"
                        :is-new="newsIndex < delayedUnreadNewsCount"
                        class="cursor-pointer"
                        type="cover"
                    />
                </router-link>
            </swiper-slide>
            <div
                slot="pagination"
                class="swiper-pagination swiper-no-swiping"
            ></div>
        </swiper>
    </div>
</template>

<script>
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import Vue from "vue";
import {
    mapActions,
    mapGetters,
    mapMutations,
    mapState
} from "vuex";
import { NEWS_MODULE_NAME } from "@web/store/news/news";
import NewsTeaser from "@web/components/news/NewsTeaser.vue";
import { RESET_PUBLISHED_NEWS } from "@web/store/news/mutations";
import { FETCH_PUBLISHED_NEWS } from "@web/store/news/actions";
import { RESOLVE_EVENT } from "@web/store/timestamping/mutations";
import { TIMESTAMP_MODULE_NAME } from "@web/store/timestamping/timestamping";
import { TimestampEvent } from "@web/store/timestamping/types";
import { UNREAD_NEWS_COUNT } from "@web/store/timestamping/getters";

import {
    Swiper,
    SwiperSlide,
    directive
} from "vue-awesome-swiper";

import "swiper/css/swiper.css";

const breakpointMobile = 769; // $breakpoint-tablet + 1 from _breakpoint.scss
const breakpointRegular = 1025; // $breakpoint-regular + 1 from _breakpoint.scss

const slidesPerViewTv = 3;
const slidesPerViewRegular = 2;
const slidesPerViewMobile = 1;

const spaceBetweenSlides = 55;
const activePagination = {
    el: ".swiper-pagination",
    clickable: true,
};

export default Vue.extend({
    name: "DashboardNews",
    components: {
        NewsTeaser,
        Swiper,
        SwiperSlide
    },
    directives: {
        swiper: directive
    },
    data() {
        return {
            coverNewsTotal: 9,
            delayedUnreadNewsCount: 0,
            loading: true,
        };
    },
    computed: {
        ...mapState(INTRANET_MODULE_NAME, ["intranet"]),
        ...mapGetters({
            unreadNewsCount: TIMESTAMP_MODULE_NAME + UNREAD_NEWS_COUNT,
        }),
        coverNews() {
            return this.$store.state.news.publishedNews.slice(0, this.coverNewsTotal);
        },
        slidesPerView() {
            if (window.innerWidth < breakpointMobile) {
                return slidesPerViewMobile;
            }
            if (window.innerWidth < breakpointRegular) {
                return slidesPerViewRegular;
            }
            return slidesPerViewTv;
        },
        slideSpeed() {
            if (this.slidesPerView === 1) {
                return 200;
            }
            if (this.slidesPerView === 2) {
                return 400;
            }
            return 600;
        },
        swiperOptions() {
            const hasMultipleSlides = this.coverNews.length > this.slidesPerView;
            return {
                speed: this.slideSpeed,
                allowTouchMove: hasMultipleSlides,
                pagination: hasMultipleSlides ? activePagination : false,
                slidesPerView: slidesPerViewMobile,
                spaceBetween: spaceBetweenSlides,
                breakpoints: {
                    [breakpointMobile]: {
                        slidesPerView: slidesPerViewRegular,
                        slidesPerGroup: slidesPerViewRegular,
                        spaceBetween: spaceBetweenSlides
                    },
                    [breakpointRegular]: {
                        slidesPerView: slidesPerViewTv,
                        slidesPerGroup: slidesPerViewTv,
                        spaceBetween: spaceBetweenSlides
                    },
                },
                threshold: 10,
            };
        }
    },
    watch: {
        async unreadNewsCount() {
            if (this.unreadNewsCount > 0) {
                await this.fetchNews({ onlyUpdates: true });
                this.delayedUnreadNewsCount = this.unreadNewsCount;
            }
        }
    },
    async mounted() {
        this.resetNews();
        this.loading = true;
        await this.fetchNews({ limit: this.coverNewsTotal });
        this.loading = false;
        this.resolveEvent(TimestampEvent.newsPublished);
    },
    beforeDestroy() {
        this.resetNews();
    },
    methods: {
        ...mapActions({
            fetchNews: NEWS_MODULE_NAME + FETCH_PUBLISHED_NEWS,
        }),
        ...mapMutations({
            resetNews: NEWS_MODULE_NAME + RESET_PUBLISHED_NEWS,
            resolveEvent: TIMESTAMP_MODULE_NAME + RESOLVE_EVENT
        }),
        openNews(newsUid) {
            this.$router.push(`/intranet/${this.intranet.uid}/news/id/${newsUid}`);
        },
    },
});
</script>

<style lang="scss" scoped>

.swiper-container {
    /* necessary to show shadows around news teasers, but still keeping overflow: hidden to hide next news slides */
    margin: -1.5rem -3rem -1rem -3rem;
    padding: 3rem;
}

::v-deep {
    .swiper-pagination-bullets {
        bottom: auto;
    }

    .swiper-pagination-bullet {
        background: $dark-grey;
        width: 0.75rem;
        height: 0.75rem;
    }

    .swiper-pagination-bullet-active {
        background: var(--secondary);
    }

    .swiper-slide {
        opacity: 0;
        animation: opacityOn 1s forwards 0.5s;
    }

    @media (prefers-reduced-motion) {
        .swiper-slide {
            opacity: 1;
        }
    }

    @keyframes opacityOn {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
}
</style>
