<template>
    <div
        v-if="isUnreadState"
        class="unread-indicator"
        @click="dismiss()"
        @mouseover="hovered = true"
        @mouseleave="hovered = false"
    >
        <div class="outer-circle"></div>
        <div
            class="inner-circle"
            :class="{expanded: hovered}"
        >
            <span>{{ $t("new") }}</span>
        </div>
    </div>
</template>

<script>
export default {
    name: "UnreadIndicator",
    props: {
        isUnread: {
            type: Boolean,
            default: true,
        },
        onDismiss: {
            type: Function,
            default: null,
        }
    },
    data: function() {
        return {
            dismissed: false,
            hovered: false,
        };
    },
    computed: {
        isUnreadState() {
            return this.isUnread && !this.dismissed;
        }
    },
    methods: {
        dismiss() {
            if (this.onDismiss === null) {
                this.dismissed = true;
                return;
            }
            this.onDismiss();
        },
    },
};
</script>

<style lang="scss" scoped>
.unread-indicator {
    cursor: pointer;
    position: relative;

    .outer-circle {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background-color: var(--primary);
        opacity: .35;
    }

    .inner-circle {
        transition: all .2s ease-out;
        position: absolute;
        top: 6px;
        left: 6px;
        width: 8px;
        height: 8px;
        background: var(--primary);
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        span {
            transition: font-size .2s ease-out;
            line-height: 0;
            font-size: 0;
            font-weight: 600;
            color: var(--primary-text);
        }

        &.expanded {
            border-radius: 10px;
            top: 0;
            left: 0;
            width: 40px;
            height: 20px;

            span {
                font-size: 9px;
            }
        }
    }
}
</style>
