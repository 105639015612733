/*
 * Registers all relevant modules, blots and themes across the application.
 * To use the modules, themes and blots still have to be enabled by editor options of `quill` and `quill-vue`.
 */
import {
    Quill,
    quillEditor as QuillEditor,
} from "vue-quill-editor";
import "quill-mention";
import Fuji from "@web/lib/quill/theme/fuji";
import { ImageBlot } from "@web/lib/quill/image/image-blot";
import { ImageBlotSelection } from "@web/lib/quill/image/image-selection";
import { EverestMentionBlot } from "@web/lib/quill/mention/everest-mention-blot";
import { QuillReadMore } from "@web/lib/quill/quill-read-more-blot";
import { EventHandling } from "@web/lib/quill/event-handling/event-handling";
import "./theme/fuji.scss";
import "./theme/news-text.scss";
import "./theme/generic-text.scss";

console.info("registering quill modules");
Quill.register("themes/fuji", Fuji);
Quill.register("modules/image-selection", ImageBlotSelection);
Quill.register(ImageBlot, true);
Quill.register(EverestMentionBlot, true);
Quill.register(QuillReadMore);
Quill.register("modules/eventHandling", EventHandling);

export { Quill, QuillEditor };
