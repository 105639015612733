import {Timestamp} from "@backend/entity/types";

export enum MentionEntityType {
    news = "news",
    newsComment = "news-comment",
    post = "post",
    postComment = "post-comment"
}

export enum MentionDtoInFirestoreFieldKeys {
    mentionedUserUid = "mentionedUserUid",
    entityLocation = "entityLocation",
    rootEntityUid = "rootEntityUid",
    creatorUserUid = "creatorUserUid",
    entityType = "entityType",
    entityUid = "entityUid",
    changedTimestamp = "changedTimestamp"
}

export interface MentionEntityLocation {
    rootEntityUid: string;
    path?: string;
}

export interface MentionInFirestore {
    [MentionDtoInFirestoreFieldKeys.mentionedUserUid]: string;
    [MentionDtoInFirestoreFieldKeys.entityLocation]: MentionEntityLocation;
    [MentionDtoInFirestoreFieldKeys.creatorUserUid]: string;
    [MentionDtoInFirestoreFieldKeys.entityType]: MentionEntityType;
    [MentionDtoInFirestoreFieldKeys.entityUid]: string;
    [MentionDtoInFirestoreFieldKeys.changedTimestamp]: Timestamp;
}
