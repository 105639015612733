<template>
    <div class="reactions-modal">
        <Modal
            name="reactions-modal"
            hide-footer
            :title="$t('reactions')"
            @before-open="beforeOpenEvent"
            @before-close="beforeCloseEvent"
        >
            <div class="tab-bar">
                <div
                    class="reaction-tab"
                    :class="{active: currentFilterKey === 'all'}"
                    @click="currentFilterKey = 'all'"
                >
                    {{ $t("all") }}
                    <span>{{ totalReactionCount }}</span>
                </div>
                <div
                    v-for="type in reactionTypes"
                    :key="type"
                    class="reaction-tab"
                    :class="{active: currentFilterKey === type, disabled: !totalReactions[type]}"
                    @click="currentFilterKey = type"
                >
                    <div
                        class="reaction-icon"
                        v-html="svgIcons[type]"
                    ></div>
                    <span>{{ totalReactions[type] }}</span>
                </div>
            </div>

            <div
                v-if="visibleReactionList"
                class="reaction-list"
            >
                <avatar-list
                    ref="avatarList"
                    mode="list"
                    :use-spinner="false"
                    :user-uids="visibleReactions.map(r => r.userUid)"
                    @clickOnUser="onClickInAvatarList"
                >
                    <template #suffix="{user}">
                        <div
                            class="reaction-icon"
                            v-html="svgIcons[visibleReactions.find(r => r.userUid === user.uid).reactionType]"
                        ></div>
                    </template>
                </avatar-list>
                <InfiniteScrollSpinner
                    v-if="!isSpinnerHidden"
                    class="spinner"
                    :loading="isLoadingByFilterKey[currentFilterKey]"
                    @load-more="loadMore({filterKey: currentFilterKey})"
                />
            </div>
        </Modal>
    </div>
</template>

<script>
import Vue from "vue";
import {
    mapActions,
    mapMutations,
    mapState,
} from "vuex";
import Modal from "@web/components/modals/Modal";
import InfiniteScrollSpinner from "@web/components/InfiniteScrollSpinner";
import { INITIALIZE } from "@web/store/reaction/mutations";
import { REACTION_MODULE_NAME } from "@web/store/reaction/reaction";
import { FETCH_REACTIONS } from "@web/store/reaction/actions";
import AvatarList from "@web/components/AvatarList";
import smileIcon from "@web/assets/reactions/smile.svg";
import clapIcon from "@web/assets/reactions/clap.svg";
import loveIcon from "@web/assets/reactions/love.svg";
import likeIcon from "@web/assets/reactions/like.svg";
import dislikeIcon from "@web/assets/reactions/dislike.svg";
import { totalReactionCount } from "@web/lib/total-reaction-count";

export default Vue.extend({
    name: "ReactionsModal",
    components: {
        Modal,
        AvatarList,
        InfiniteScrollSpinner,
    },
    data() {
        return {
            entityUid: null,
            currentFilterKey: null,
            totalReactions: {},
            open: false,
            routeBeforeOpen: null,
            nativePopstateEventListener: null,
            reactionTypes: ["like", "dislike", "love", "smile", "clap"],
            isLoadingByFilterKey: {},
            avatarList: null,
            svgIcons: {
                smile: smileIcon,
                clap: clapIcon,
                love: loveIcon,
                like: likeIcon,
                dislike: dislikeIcon,
            },
        };
    },
    computed: {
        ...mapState(REACTION_MODULE_NAME, ["reactionListsByFilter"]),
        totalReactionCount() {
            return totalReactionCount(this.totalReactions);
        },
        visibleReactionList() {
            return this.reactionListsByFilter[this.currentFilterKey];
        },
        visibleReactions() {
            return this.visibleReactionList.reactions;
        },
        visibleUids() {
            return this.visibleReactions.map(r => r.userUid);
        },
        isSpinnerHidden() {
            if (!this.visibleReactionList) return false;
            if (this.currentFilterKey !== "all") {
                return this.visibleReactions.length >= this.totalReactions[this.currentFilterKey];
            }
            return this.visibleReactions.length >= this.totalReactionCount;
        },
    },
    methods: {
        ...mapActions({ fetchReactions: REACTION_MODULE_NAME + FETCH_REACTIONS }),
        ...mapMutations({ initialize: REACTION_MODULE_NAME + INITIALIZE }),
        beforeOpenEvent(event) {
            this.open = true;
            this.entityUid = event.params.entityUid;
            this.entityUid = event.params.entityUid;
            this.currentFilterKey = event.params.reactionType;
            this.totalReactions = event.params.totalReactions;
            this.initialize({ entityUid: this.entityUid });
            this.routeBeforeOpen = this.$router.currentRoute.path;
        },
        beforeCloseEvent() {
            this.open = false;
            this.entityUid = null;
            this.currentFilterKey = null;
        },
        async loadMore({ filterKey }) {
            if (this.isLoadingByFilterKey[filterKey]) {
                return;
            }
            Vue.set(this.isLoadingByFilterKey, filterKey, true);
            this.fetchReactions({ filterKey })
                .catch(() => {
                    this.$notify({
                        group: "app",
                        type: "error",
                        text: `${this.$t("reactions_failed_to_fetch_reactions")}`,
                    });
                })
                .finally(() => {
                    this.isLoadingByFilterKey[filterKey] = false;
                });
        },
        onClickInAvatarList() {
            this.$modal.hide("reactions-modal");
        },
    },
    mounted() {
        /*
         * React to browser back events without the Vue $router to be able bypass the normal routing and just close the modal.
         */
        window.addEventListener("popstate", this.nativePopstateEventListener);
    },
    destroyed() {
        window.removeEventListener("popstate", this.nativePopstateEventListener);
    },
});
</script>

<style lang="scss" scoped>
.reactions-modal {
    z-index: 2147483002;

    .reaction-list {
        padding: 1.5rem;
        margin: 0 -1.5rem -1.5rem;
        display: flex;
        height: 28rem;
        flex-direction: column;
        overflow-y: auto;

        @media screen and (max-width: $breakpoint-tablet) {
            height: 80vh;
        }

        .avatar-list {
            animation: 0.15s ease-out;
        }
    }

    .user-row {
        margin-bottom: 1rem;
    }

    .tab-bar {
        display: flex;

        .reaction-tab {
            text-transform: uppercase;
            flex: 1 1 auto;
            border-bottom: 2px solid var(--lowest-contrast, #{$off-white});
            display: flex;
            align-items: center;
            padding: 0.25rem 0 0.5rem;
            cursor: pointer;

            &.active {
                border-bottom: 2px solid var(--primary);
            }

            &.disabled {
                pointer-events: none;
            }

            .reaction-icon {
                height: 24px;
            }

            span {
                margin-left: 0.4rem;
                opacity: 0.5;
            }
        }
    }

    .reaction-icon {
        width: 24px;
    }
}
</style>
