import { cloudRunUrl } from "@web/cloud-run-url";
import { ApiClientBuilder } from "@web/api/ApiClientBuilder";
import { ApiClient } from "@web/api/ApiClient";
import { analytics } from "@web/analytics";
import { getGlobalConfiguration } from "@web/global-config";
import { IntranetFeatureFlag } from "@backend/common/feature-flags/types";

export class FeatureFlagService {
    private readonly api: Promise<ApiClient>;

    constructor() {
        this.api = new ApiClientBuilder().build();
    }

    public async setFeatureFlag(intranetUid: string, featureFlag: IntranetFeatureFlag, enabled: boolean): Promise<void> {
        const client = await this.api;
        await client.patch(`${cloudRunUrl.intranet}/api/intranet/${intranetUid}/flags/${featureFlag}/`, {
            enabled,
        });
        analytics.log(getGlobalConfiguration().analytics_event_name_admin_feature_flag_updated, {
            featureFlag,
            enabled,
        });
    }
}
