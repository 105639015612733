import { Module } from "vuex";
import { VuexRootState } from "@web/store";
import {
    GET_TOPIC_UIDS,
    HAS_ERROR,
    LOADED,
} from "@web/store/topic-subscription/getters";
import {
    SET_ERROR,
    SET_LOADED,
    SET_TOPIC_UIDS,
} from "@web/store/topic-subscription/mutations";
import {
    FETCH_TOPIC_SUBSCRIPTION,
    SUBSCRIBE_TOPIC,
    UNSUBSCRIBE_TOPIC,
} from "@web/store/topic-subscription/actions";
import { topicSubscriptionService } from "@web/store/topic-subscription/TopicSubscriptionService";

export interface TopicSubscriptionModuleState {
    topicUids: string[];
    loaded: boolean;
    hasError: boolean;
}

function getDefaultState(): TopicSubscriptionModuleState {
    return {
        topicUids: [],
        loaded: false,
        hasError: false,
    };
}

export const TOPIC_SUBSCRIPTION_MODULE_NAME = "topicSubscription/";
export const TOPIC_SUBSCRIPTION_MODULE: Module<TopicSubscriptionModuleState, VuexRootState> = {
    namespaced: true,
    state: () => getDefaultState(),
    getters: {
        [GET_TOPIC_UIDS]: (state): string[] => state.topicUids,
        [LOADED]: (state): boolean => state.loaded,
        [HAS_ERROR]: (state): boolean => state.hasError,
    },
    mutations: {
        [SET_TOPIC_UIDS](state, topicUids: string[]): void {
            state.topicUids = topicUids;
        },
        [SET_LOADED](state, loaded: boolean): void {
            state.loaded = loaded;
        },
        [SET_ERROR](state, hasError: boolean): void {
            state.hasError = hasError;
        },
    },
    actions: {
        async [FETCH_TOPIC_SUBSCRIPTION]({ commit, rootState }): Promise<string[]> {
            const userUid = rootState.auth.currentUser?.uid;
            const intranetUid = rootState.intranet.intranet?.uid;
            if (!userUid) {
                commit(SET_ERROR, true);
                commit(SET_LOADED, true);
                throw new Error("user is not logged in");
            }
            if (!intranetUid) {
                commit(SET_ERROR, true);
                commit(SET_LOADED, true);
                throw new Error("no current intranet set");
            }
            try {
                commit(SET_ERROR, false);
                const { topicUids } = await topicSubscriptionService.fetchTopicSubscriptions({ intranetUid, userUid });
                commit(SET_TOPIC_UIDS, topicUids);
                return topicUids;
            } catch (e) {
                commit(SET_ERROR, true);
                throw e;
            } finally {
                commit(SET_LOADED, true);
            }
        },
        async [SUBSCRIBE_TOPIC]({ commit, rootState, state }, topicUid: string): Promise<void> {
            const intranetUid = rootState.intranet.intranet?.uid;
            if (!intranetUid) throw new Error("user is not inside an intranet");
            await topicSubscriptionService.subscribeTopic({ intranetUid, topicUid });
            commit(SET_TOPIC_UIDS, [...state.topicUids, topicUid]);
        },
        async [UNSUBSCRIBE_TOPIC]({ commit, rootState, state }, topicUid: string): Promise<void> {
            const intranetUid = rootState.intranet.intranet?.uid;
            if (!intranetUid) throw new Error("user is not inside an intranet");
            await topicSubscriptionService.unsubscribeTopic({ intranetUid, topicUid });
            commit(SET_TOPIC_UIDS, state.topicUids.filter(uid => topicUid !== uid));
        },
    },
};
