<template>
    <v-popover
        ref="popover"
        popover-arrow-class="tooltip-arrow popover-arrow arrow-top"
        popover-class="inbox-popover no-padding"
        :auto-hide="!visible"
        :open="visible"
        offset="5"
        placement="top-center"
        :boundaries-element="documentElement"
        @show="onInboxOpen()"
        @hide="removeClickListener()"
    >
        <span
            class="d-inline-block pos-relative cursor-pointer"
            :class="{'inbox-new-indicator': hasNewPings }"
        >
            <icon name="bell"/>
        </span>
        <template slot="popover">
            <pings-list
                ref="pingsList"
                class="ptop-medium"
                @newPings="pings = $event"
                @hide="closePopover()"
            />
        </template>
    </v-popover>
</template>

<script>
import PingsList from "@web/components/pings/PingsList";
import { inboxService } from "@web/services";
import { mapState } from "vuex";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import { INTRANET } from "@web/store/intranet/getters";

export default {
    name: "InboxMenu",
    components: { PingsList },
    data() {
        return {
            documentElement: document.documentElement,
            inboxDocument: null,
            unsubscribeInboxDocument: null,
            visible: false,
            pings: [],
        };
    },
    computed: {
        ...mapState(INTRANET_MODULE_NAME, [INTRANET]),
        hasNewPings() {
            const firstUnreadPing = this.pings.find(ping => ping.unread);
            const inboxDocument = this.inboxDocument;
            if (!firstUnreadPing) {
                return false;
            }
            if (!inboxDocument || !inboxDocument.inboxLastOpenedTimestamp) {
                return true;
            }
            return firstUnreadPing.updated.seconds > this.inboxDocument.inboxLastOpenedTimestamp.seconds;
        },
    },
    mounted() {
        this.unsubscribeInboxDocument = inboxService.getUserInboxDocument(
            this.intranet.uid,
            this.$store.state.auth.currentUser.uid
        ).onSnapshot(async snapshot => {
            this.inboxDocument = snapshot.data();
        });
    },
    destroyed() {
        this.removeClickListener();
        this.unsubscribeInboxDocument();
    },
    methods: {
        onInboxOpen() {
            this.$refs.pingsList.updatePings();
            this.addClickListener();
        },
        clickOutsideEvent(event) {
            if (!(this.$refs.pingsList.$el === event.target || this.$refs.pingsList.$el.contains(event.target))) {
                this.visible = false;
            }
        },
        addClickListener() {
            document.body.addEventListener("click", this.clickOutsideEvent);
        },
        removeClickListener() {
            document.body.removeEventListener("click", this.clickOutsideEvent);
        },
        closePopover() {
            this.$refs.popover.hide();
        },
    },
};
</script>

<style lang="scss">
.inbox-new-indicator::after {
    content: " ";
    width: .6rem;
    height: .6rem;
    background-color: var(--primary);
    position: absolute;
    border-radius: 50%;
    right: 0;
    top: 0;
}
</style>
