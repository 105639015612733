import dayjs,
{ Dayjs } from "dayjs";
import "dayjs/locale/de";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localeData from "dayjs/plugin/localeData";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import updateLocale from "dayjs/plugin/updateLocale";
import {
    detectLocaleForDateFormats,
    i18n,
} from "@web/i18n";
import { Timestamp as EverestTimestamp } from "@backend/entity/types";
import { DateYMD } from "@backend/user-profile/types";
import { DatestampMD } from "@backend/index/types";

type Timestamp = EverestTimestamp | { seconds: number, nanoseconds: number };

function getSeconds(timestamp: Timestamp): number {
    if ("seconds" in timestamp) {
        return timestamp.seconds;
    } else {
        return timestamp._seconds;
    }
}

// @see https://day.js.org/docs/en/i18n/loading-into-nodejs
dayjs.extend(localeData);
// @see https://day.js.org/docs/en/display/format#list-of-localized-formats
dayjs.extend(localizedFormat);
// @see https://day.js.org/docs/en/parse/string-format#list-of-all-available-parsing-tokens
dayjs.extend(customParseFormat);
// @see https://day.js.org/docs/en/plugin/update-locale
dayjs.extend(updateLocale);
// @see https://day.js.org/docs/en/display/from-now#list-of-breakdown-range
// @see https://day.js.org/docs/en/customization/relative-time
dayjs.extend(relativeTime, {
    rounding: Math.floor,
    thresholds: [
        { l: "s", r: 1 },
        { l: "m", r: 1 },
        { l: "mm", r: 59, d: "minute" },
        { l: "h", r: 1 },
        { l: "hh", r: 23, d: "hour" },
        { l: "d", r: 1 },
        { l: "dd", r: 29, d: "day" },
        { l: "M", r: 1 },
        { l: "MM", r: 11, d: "month" },
        { l: "y", r: 1 },
        { l: "yy", d: "year" },
    ],
});
dayjs.updateLocale("en", {
    relativeTime: {
        future: "in %s",
        past: "%s ago",
        s: "%d seconds",
        m: "%d minute",
        mm: "%d minutes",
        h: "%d hour",
        hh: "%d hours",
        d: "%d day",
        dd: "%d days",
        M: "%d month",
        MM: "%d months",
        y: "%d year",
        yy: "%d years",
    },
});

// @see https://day.js.org/docs/en/i18n/changing-locale
const detectedLocaleForDateFormats = detectLocaleForDateFormats();
dayjs.locale(detectedLocaleForDateFormats);

export const localizedDayjs = dayjs;

/*
 * The localized formats L and LT don't work for date parsing.
 * Therefore, we need to extract them from the i18n configuration.
 */
export const DATE_FORMAT = dayjs.Ls[detectedLocaleForDateFormats].formats.L;
export const TIME_FORMAT = dayjs.Ls[detectedLocaleForDateFormats].formats.LT;
export const DATE_TIME_FORMAT = DATE_FORMAT + " " + TIME_FORMAT;

export function getNowAsUtc(): Date {
    const date = new Date();
    const nowUtc = Date.UTC(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(),
        date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
    return new Date(nowUtc);
}

export function asTimestamp(date: Date | number): EverestTimestamp {
    const secondsSinceEpoch = Math.floor(new Date(date).getTime() / 1000);
    return {
        _seconds: secondsSinceEpoch,
        _nanoseconds: 0, // drop nanoseconds
    };
}

export function friendlyFormatDateFromDayJs(dayJs: dayjs.Dayjs, defaultFormat: string = "DD. MMMM"): string {
    // TODO: Replace with relativeTime feature from dayjs https://day.js.org/docs/en/plugin/relative-time
    const pastMinutes = getPastMinutesSinceThen(dayJs);
    if (pastMinutes === 0) {
        return i18n.t("zero_minutes_ago") as string;
    }
    if (pastMinutes === 1) {
        return i18n.t("minute_ago") as string;
    }
    if (pastMinutes < 60) {
        return pastMinutes.toString() + " " + i18n.t("minutes_ago");
    }
    const pastHours = getPastHoursSinceThen(dayJs);
    if (pastHours === 1) {
        return i18n.t("hour_ago") as string;
    }
    if (pastHours < 24) {
        return pastHours.toString() + " " + i18n.t("hours_ago");
    }
    return dayJs.format(defaultFormat);
}

export function friendlyFormatedAtlassianDate(dayJs: dayjs.Dayjs, defaultFormat: string = "DD. MMM."): string {
    const pastMinutes = getPastMinutesSinceThen(dayJs);

    if (pastMinutes === 0) {
        return i18n.t("updated") + " " + i18n.t("zero_minutes_ago");
    }
    if (pastMinutes === 1) {
        return i18n.t("updated") + " " + i18n.t("minute_ago");
    }
    if (pastMinutes < 60) {
        return i18n.t("updated") + " " + pastMinutes.toString() + " " + i18n.t("minutes_ago");
    }
    const pastHours = getPastHoursSinceThen(dayJs);
    if (pastHours === 1) {
        return i18n.t("updated") + " " + i18n.t("hour_ago");
    }
    if (pastHours < 24) {
        return i18n.t("updated") + " " + pastHours.toString() + " " + i18n.t("hours_ago");
    }
    return i18n.t("updated_on") + " " + dayJs.format(defaultFormat);
}

export function toDayJs(timestamp: Timestamp | number): Dayjs {
    if (typeof timestamp === "object") {
        return dayjs(getSeconds(timestamp) * 1000);
    }
    return dayjs(timestamp);
}

export function dayjsFromDate(date: Date): Dayjs {
    return dayjs(date);
}

// Creates a DayJS instance to use further functionality.
export function dayjsFromDateYMD(dateYMD: DateYMD | undefined): Dayjs {
    return dayjs(dateYMDToDate(dateYMD));
}

export function toDate(timestamp: Timestamp): Date {
    return new Date(getSeconds(timestamp) * 1000);
}

export function getPastMinutesSinceThen(date: Dayjs): number {
    const now = dayjs();
    return now.diff(date, "minute");
}

export function getPastHoursSinceThen(date: Dayjs): number {
    const now = dayjs();
    return now.diff(date, "hour");
}

export function getPastDaysSinceThen(date: Dayjs): number {
    const now = dayjs();
    return now.diff(date, "day");
}

export function getPastYearsSinceThen(date: Dayjs): number {
    const now = dayjs();
    return now.diff(date, "year");
}

export function timeForRange(start: Timestamp | number, end: Timestamp | number): string {
    const startDayjs = toDayJs(start);
    const endDayjs = toDayJs(end);
    const timeRange = startDayjs.format("LT") + " – " + endDayjs.format("LT");
    if (!startDayjs.isSame(endDayjs, "day")) {
        /*
         * Although it's not possible for users to add events across multiple date,
         * it's still possible from the perspective of a different timezone
         */
        return `${timeRange} (${endDayjs.format("LL")})`;
    }
    return timeRange;
}

export function formatDate(date: Timestamp | number, format: string = "LL"): string {
    return toDayJs(date).format(format);
}

export function inputToDayjs(dateInput: Date, timeInput: string): Dayjs | undefined {
    const date = dayjs(dateInput);
    if (!date.isValid()) return undefined;
    const dateString = date.format(DATE_FORMAT);
    const dateTimeString = dateString + " " + timeInput;
    return dayjs(dateTimeString, DATE_TIME_FORMAT);
}

export function dateToDateYMD(value: Date | undefined): DateYMD | undefined {
    if (!value) return undefined;
    return {
        year: value.getFullYear(),
        month: value.getMonth() + 1,
        day: value.getDate(),
    };
}

export function dateYMDToDate(value: DateYMD | undefined): Date | undefined {
    if (!value) return undefined;
    const monthIndex = value.month - 1;
    return new Date(value.year, monthIndex, value.day);
}

export function dayJsToDatestampMD(value: dayjs.Dayjs): DatestampMD {
    return (value.month() + 1) * 100 + value.date();
}

interface DateSpan {
    years?: number;
    months?: number;
    days?: number;
}

export function dateMinus({ years = 0, months = 0, days = 0 }: DateSpan): Date {
    const date = new Date();
    date.setFullYear(date.getFullYear() + years);
    date.setMonth(date.getMonth() + months);
    date.setDate(date.getDate() + days);
    return date;
}

export function findClosestAnniversary(initialDate: dayjs.Dayjs): dayjs.Dayjs {
    const now = dayjs();
    return findClosestDate(
        now,
        [
            initialDate.set("year", now.year() - 1),
            initialDate.set("year", now.year()),
            initialDate.set("year", now.year() + 1),
        ],
    );
}

export function findClosestDate(to: dayjs.Dayjs, inDates: dayjs.Dayjs[]): dayjs.Dayjs {
    if (inDates.length === 0) {
        throw new Error("inDates should not be empty");
    }
    const toTimestamp = to.valueOf();
    let closestDate;
    for (const date of inDates) {
        if (
            !closestDate ||
            Math.abs(toTimestamp - date.valueOf()) < Math.abs(toTimestamp - closestDate.valueOf())
        ) {
            closestDate = date;
        }
    }
    return closestDate as dayjs.Dayjs;
}

/**
 * Returns a comparator which can be used to sort dates by how close they are to the provided date. If two dates are
 * equally close to the given date, the newest date will rank highest.
 *
 * @param dateToCompareTo the date to which the highest ranking date should be closest to.
 */
export function getSortDateByClosenessComparator(
    dateToCompareTo: dayjs.Dayjs,
): (a: dayjs.Dayjs, b: dayjs.Dayjs) => number {
    return (a, b) => {
        const aDiff = Math.abs(dateToCompareTo.valueOf() - a.valueOf());
        const bDiff = Math.abs(dateToCompareTo.valueOf() - b.valueOf());
        if (aDiff !== bDiff) {
            return aDiff - bDiff;
        }
        return b.valueOf() - a.valueOf();
    };
}
