import axios from "axios";
import * as firebase from "firebase";
import { cloudRunUrl } from "@web/cloud-run-url";
import { ApiClient } from "@web/api/ApiClient";
import { ApiClientBuilder } from "@web/api/ApiClientBuilder";
import { StorageLimitReachedError } from "@web/exceptions";
import { blobToBase64 } from "@web/services/file-upload";
import { IntranetColors } from "@backend/theming/types";

interface SignedUrlResponse {
    uploadUrl: string;
    filePath: string;
}

export class ThemeService {
    private readonly api: Promise<ApiClient>;

    constructor() {
        this.api = new ApiClientBuilder().build();
    }

    public async saveThemeSettings(intranetUid: string, intranetColors: IntranetColors) {
        const currentUser = firebase.auth().currentUser;
        if (currentUser === null) {
            return null;
        }
        const token = await currentUser.getIdToken();
        const colorsCloudFunctionPath = `${cloudRunUrl.theming}/api/theming/colors/${intranetUid}`;
        const config = {
            headers: { Authorization: "Bearer " + token },
        };
        return axios.put(
            colorsCloudFunctionPath,
            {
                intranetColors,
            },
            config,
        );
    }

    private async getSignedUrlForLogoUpload(intranetUid: string, contentType: string, fileSize: number): Promise<SignedUrlResponse> {
        const client = await this.api;
        const signedUrlCloudFunctionPath = `${cloudRunUrl.theming}/api/theming/signedUrl`;
        const signedUrlResponse = await client.post<{ uploadUrl: string, path: string }>(signedUrlCloudFunctionPath, {
            intranetUid,
            contentType,
            fileSize,
        });
        console.log(signedUrlResponse);
        return {
            uploadUrl: signedUrlResponse.data.uploadUrl,
            filePath: signedUrlResponse.data.path,
        };
    }

    public async uploadNewLogo(intranetUid: string, userUid: string, file: File): Promise<string | null> {
        const client = await this.api;
        let signedUrlResponse;
        try {
            signedUrlResponse = await this.getSignedUrlForLogoUpload(intranetUid, file.type, file.size);
        } catch (e) {
            if (e.response.status == 413) {
                throw new StorageLimitReachedError();
            }
            throw new Error();
        }
        await client.put(signedUrlResponse.uploadUrl, file, {
            headers: {
                "Content-Type": file.type,
                "x-goog-content-length-range": `0,${file.size}`,
                "x-goog-file-path": signedUrlResponse.filePath,
                "x-goog-meta-uploaded-to-intranet": intranetUid,
                "x-goog-meta-uploaded-by": userUid,
            },
        });
        return signedUrlResponse.filePath;
    }

    public async saveLogo(intranetUid: string, logo: string) {
        const logoCloudFunctionPath = `${cloudRunUrl.theming}/api/theming/logo/${intranetUid}`;

        const client = await this.api;
        return client.put(logoCloudFunctionPath, { logo });
    }

    public async getSuggestedThemeColors(intranetUid: string) {
        const client = await this.api;
        return client.get(`${cloudRunUrl.theming}/api/autotheme/autotheme/${intranetUid}`);
    }

    public async getSuggestedThemeColorsForImage(imageBlob: Blob): Promise<IntranetColors> {
        const base64String = await blobToBase64(imageBlob);
        const client = await this.api;
        const { data } = await client.post(`${cloudRunUrl.theming}/api/autotheme/autotheme/image`, { image: base64String });
        return data as IntranetColors;
    }
}
