<template>
    <NewsRenderer
        :is-news-opened-using-a-direct-link="true"
        :is-integration="true"
        @onNewsClosed="onNewsClosed"
    />
</template>

<script>
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import Vue from "vue";
import {
    mapActions,
    mapState
} from "vuex";
import { INITIALIZE_INTRANET } from "@web/store/intranet/actions";
import NewsRenderer from "@web/views/news/NewsRenderer.vue";
import ConfluenceMacroMixin from "@web/views/atlassian-cloud/confluence/macro/ConfluenceMacroMixin.vue";

export default Vue.extend({
    name: "NewsCreate",
    components: {
        NewsRenderer,
    },
    mixins: [
        ConfluenceMacroMixin
    ],
    computed: {
        ...mapState(INTRANET_MODULE_NAME, ["intranet"]),
    },
    methods: {
        ...mapActions({ setup: INTRANET_MODULE_NAME + INITIALIZE_INTRANET }),
        handleError(error) {
            if (error.response.status === 403) {
                this.isPermissionDeniedForIntranet = true;
                return;
            }
            this.isUnknownError = true;
        },
        handleSuccess() {
            this.intranetLoaded = true;
        },
        onNewsClosed() {
            this.$router.push("/intranet/" + this.intranetUid + "/dashboard");
        }
    },
    data() {
        return {
            intranetLoaded: false
        };
    },
    async created() {
        this.appendAtlassianScriptToHead(async() => {
            this.isUserLoggedIn = this.isUserLoggedInIntoFirebase();
            if (!this.isUserLoggedIn) {
                return;
            }
            this.setup(this.intranetUid).then(this.handleSuccess, this.handleError)
                .finally(() => {
                    this.$modal.show("news-renderer", {
                        newsUid: "",
                        mode: "CREATE"
                    });
                });
        }, false);
    }

});
</script>

<style>
    body {
        padding: 20px 20px 0;
    }
</style>
<style lang="scss" scoped>

</style>
