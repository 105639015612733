<template>
    <app-button
        :busy="updatingAttendeeStatus"
        :class="isAttending ? 'light' : 'primary'"
        @click.prevent="onClick"
    >
        {{ isAttending ? $t("events_leave") : $t("events_join") }}
    </app-button>
</template>

<script>
import { analytics } from "@web/analytics";
import { getGlobalConfiguration } from "@web/global-config";
import {
    mapActions,
    mapGetters,
} from "vuex";
import { EVENT_MODULE_NAME } from "@web/store/event/event";
import { GET_CURRENT_USER_ATTENDEE_STATUS } from "@web/store/event/getters";
import { EverestEventAttendeeStatus } from "@backend/event/types";
import {
    JOIN_EVENT,
    LEAVE_EVENT,
} from "@web/store/event/actions";

export default {
    name: "EventAttendButton",
    props: {
        /** @type {EverestEvent} */
        event: { type: Object, required: true },
    },
    data() {
        return {
            updatingAttendeeStatus: false,
        };
    },
    computed: {
        ...mapGetters({
            getAttendeeStatus: EVENT_MODULE_NAME + GET_CURRENT_USER_ATTENDEE_STATUS,
        }),
        isAttending() {
            return this.getAttendeeStatus(this.event) === EverestEventAttendeeStatus.attending;
        },
    },
    methods: {
        ...mapActions({
            joinEvent: EVENT_MODULE_NAME + JOIN_EVENT,
            leaveEvent: EVENT_MODULE_NAME + LEAVE_EVENT,
        }),
        async onClick() {
            this.updatingAttendeeStatus = true;
            try {
                await this.joinOrLeaveEvent();
            } finally {
                this.updatingAttendeeStatus = false;
            }
        },
        async joinOrLeaveEvent() {
            if (this.isAttending) {
                await this.leaveEvent({ event: this.event });
                analytics.log(getGlobalConfiguration().analytics_event_name_event_leave);
            } else {
                await this.joinEvent({ event: this.event });
                analytics.log(getGlobalConfiguration().analytics_event_name_event_join);
            }
        },
    },
};
</script>

<style lang="scss" scoped>

</style>
