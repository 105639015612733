<template>
    <article
        :id="`${contentUid}-comment-${commentPath}`"
        :class="{'deleted-comment': comment.isDeleted}"
        class="comment-wrapper"
    >
        <loading-avatar
            :user-uid="comment.creatorUid"
            class="comment-avatar"
            linked
        />
        <div
            ref="content-container"
            :class="{'depth-3': editMode, deleting}"
            class="content-container grey-box with-noupy"
        >
            <view-trigger
                :entity="comment"
                :entity-type="'comment'"
            />
            <CommentHeader
                :author-user-uid="comment.creatorUid"
                :creation-date="comment.creationDate"
                :comment-route="commentRoute"
                :is-comment-deleted="comment.isDeleted"
                :is-content-author="isContentAuthor"
                :is-just-edited="isJustEdited"
                :is-unread="isUnread"
                :last-edited-date="comment.lastEditedDate"
                :show-action-menu="!editMode"
                :waiting-for-delete="deleting"
                class="header"
                @delete="onClickOnDeleteBtn"
                @edit="toggleEditMode"
            />
            <div class="content">
                <PostEditor
                    v-if="!comment.isDeleted"
                    :attachment-manager="attachmentManager"
                    :busy="saving"
                    :collapsable="false"
                    :content="newContentForEditedComment"
                    :edit-mode="editMode"
                    :place-holder="$t('enter_your_comment_here')"
                    :truncate="!comment._new"
                    no-topics
                    @cancel="toggleEditMode"
                    @save="onSaveBtnClickInEditEditor"
                />
                <div v-else>
                    {{ $t("deleted_comment") }}
                </div>
            </div>
            <CommentableContentFooter
                v-if="!comment.isDeleted && !editMode"
                :comment-uid="comment.uid"
                :content-type="rootEntityType"
                :content-uid="contentUid"
                :hide-reply="depth >= maxCommentsThreadDepth"
                :path="commentPath"
                :selected-reaction-type="comment.currentUsersReaction"
                :total-reactions="comment.reactions"
                class="comment-footer"
                @reply="showReply = true"
            />
        </div>
        <div class="comments-container">
            <slot name="comments-container"></slot>
            <CommentReplyEditor
                v-if="showReply"
                :saving="savingReply"
                @cancel="showReply = false"
                @save="onSaveBtnClickInReplyEditor"
            />
        </div>
    </article>
</template>

<script>
import { mapGetters } from "vuex";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import CommentHeader from "./Comment/CommentHeader";
import PostEditor from "@web/components/editor/PostEditor";
import CommentReplyEditor from "@web/components/comments/CommentReplyEditor";
import CommentableContentFooter from "@web/components/comments/CommentableContentFooter";
import { AttachmentManager } from "@web/components/attachments/attachment-manager";
import { CommentWithAttachmentReference } from "@web/services/attachments";
import { getGlobalConfiguration } from "@web/global-config";
import { cloneDeepClean } from "@web/lib/cloneDeepClean";
import { INTRANET_UID } from "@web/store/intranet/getters";
import LoadingAvatar from "@web/components/user/LoadingAvatar";
import ViewTrigger from "@web/components/view-tracking/ViewTrigger";
import { viewService } from "@/services";
import { AUTH_MODULE_NAME } from "@/store/auth/auth";
import { CURRENT_USER } from "@/store/auth/getters";
import { LIVE_NEW_CONTENT_MODULE_NAME } from "@web/store/live-new-content/live-new-content";
import { GET_POST_TIMELINE_RELOAD } from "@web/store/live-new-content/getters";

/**
 * renders comment below a content entity
 * offers slot for further comments (one hierarchy level deeper) which allows custom pagination behaviour in different
 * contexts
 */
export default {
    name: "Comment",
    components: {
        ViewTrigger,
        LoadingAvatar,
        CommentableContentFooter,
        PostEditor,
        CommentHeader,
        CommentReplyEditor,
    },
    props: {
        contentUid: { type: String },
        comment: { type: Object },
        onEditComment: { type: Function },
        onDeleteComment: { type: Function },
        onCreateReply: { type: Function },
        onSaveFail: { type: Function },
        commentRoute: { type: String },
        contentCreatorUid: { type: String },
        rootEntityType: { type: String },
    },
    data() {
        return {
            newContentForEditedComment: cloneDeepClean(this.comment.content),
            saving: false,
            isUnread: false,
            deleting: false,
            editMode: false,
            isJustEdited: false,
            maxCommentsThreadDepth: getGlobalConfiguration().max_comments_thread_depth,
            isEditorEmpty: true,
            showReply: false,
            savingReply: false,
            attachmentManager: null,
        };
    },
    computed: {
        ...mapGetters({
            intranetUid: INTRANET_MODULE_NAME + INTRANET_UID,
            currentUser: AUTH_MODULE_NAME + CURRENT_USER,
            isPostTimelineReloading: LIVE_NEW_CONTENT_MODULE_NAME + GET_POST_TIMELINE_RELOAD,
        }),
        isContentAuthor() {
            return this.contentCreatorUid === this.comment.creatorUid;
        },
        commentPath() {
            return this.comment._path;
        },
        depth() {
            return this.commentPath.split(".").length;
        },
    },
    watch: {
        isPostTimelineReloading(_, newIsReloading) {
            if (!this.isUnread) {
                return;
            }
            if (newIsReloading) {
                viewService.viewed({
                    intranetUid: this.intranetUid,
                    entity: this.comment,
                    userUid: this.currentUser.uid,
                }).then((viewed) => {
                    this.isUnread = !viewed;
                });
            }
        }
    },
    mounted() {
        this.attachmentManager = AttachmentManager.load({
            entityWithAttachmentReference: new CommentWithAttachmentReference({
                intranetUid: this.intranetUid,
                entityType: this.rootEntityType,
                entityUid: this.contentUid,
                commentPath: this.commentPath,
                entity: this.comment,
            }),
            onError: error => {
                this.$notify({
                    group: "app",
                    type: "error",
                    title: this.$t("attachment_loading_error_title"),
                    text: this.$t("attachment_loading_error_text", ["comment"]),
                });
                console.error(error);
            },
        });
        viewService.viewed({
            intranetUid: this.intranetUid,
            entity: this.comment,
            userUid: this.currentUser.uid,
        }).then((viewed) => {
            this.isUnread = !viewed;
        });
    },
    methods: {
        async onSaveBtnClickInEditEditor({ content, onSave }) {
            this.saving = true;
            this.newContentForEditedComment = content;
            try {
                await this.onEditComment(this.comment, content);
                await this.attachmentManager.save(
                    new CommentWithAttachmentReference({
                        intranetUid: this.intranetUid,
                        entityType: this.rootEntityType,
                        entityUid: this.contentUid,
                        commentPath: this.commentPath,
                        entity: this.comment,
                    }),
                    this.comment.creatorUid,
                );
                onSave();
            } catch (e) {
                if (this.onSaveFail) {
                    this.onSaveFail();
                }
                this.$notify({
                    group: "app",
                    type: "error",
                    text: this.$t("error_editing_comment"),
                });
                console.error(e);
            }
            this.saving = false;
            this.editMode = false;
            this.isJustEdited = true;
        },
        async onClickOnDeleteBtn() {
            this.$modal.show("confirm-modal", {
                title: this.$t("comment_delete_confirmation_title"),
                text: this.$t("comment_delete_confirmation_text"),
                warning: true,
                callback: async(confirmed) => {
                    if (confirmed) {
                        this.deleting = true;
                        try {
                            await this.onDeleteComment(this.comment);
                        } catch (e) {
                            this.$notify({
                                group: "app",
                                type: "error",
                                text: this.$t("error_deleting_comment"),
                            });
                            console.error(e);
                        }
                        this.deleting = false;
                    }
                }
            });
        },
        async onSaveBtnClickInReplyEditor({ content, onSave, attachmentManager }) {
            this.savingReply = true;
            try {
                await this.onCreateReply(this.comment, content, attachmentManager);
                onSave();
                this.showReply = false;
            } catch (e) {
                this.$notify({
                    group: "app",
                    type: "error",
                    text: this.$t("error_creating_comment"),
                });
                console.error(e);
            }
            this.savingReply = false;
        },
        toggleEditMode() {
            if (this.editMode) {
                this.editMode = false;
                this.newContentForEditedComment = cloneDeepClean(this.comment.content);
                return;
            }
            this.editMode = true;
        },
    },
};
</script>

<style lang="scss">
.comment-wrapper, .reply-wrapper {
    display: grid;
    align-items: stretch;
    justify-content: stretch;

    grid: auto auto / 2rem minmax(0, 1fr);
    gap: 0.2rem 0.75rem;
    grid-template-areas:
            "avatar content"
            ". footer";
    transition: color 1s ease;

    &::v-deep {
        .comment-wrapper {
            margin: 0;
        }
    }

    .attachment-area {
        margin-bottom: .5rem;

        .file-attachment {
            background: var(--background);
        }

        .media-attachment img {
            border-color: var(--low-contrast);
        }
    }

    .content-container.deleting {
        opacity: 0.5;
        pointer-events: none;
    }

    &.new-comment.last-comment {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        margin-left: -0.5rem;
        margin-right: -0.5rem;
        transition: color 1s ease;
        animation: 3s ease-in-out 2s both colorfade-comment;

        .content.card {
            width: 100%;
        }

        @keyframes colorfade-comment {
            from {
                box-shadow: 0 0 0 2px var(--primary);
                color: var(--primary);
            }
            to {
                box-shadow: 0 0 0 0 transparent;
                color: var(--foreground, #{$black});
            }
        }
    }

}

.content-container {
    grid-area: content;
    margin: 0 0 1rem 0;
    word-break: break-word;
    position: relative;
}

.content-container,
.reply,
.editor-wrapper {
    &.grey-box {
        $background: var(--lowest-contrast, #{$off-white});
        position: relative;
        background: $background;
        padding: 0.75rem;
        border-radius: var(--border-radius);
        margin: 0 0 1rem 0;
        transition: box-shadow 0.3s ease-in-out;

        .ql-toolbar.ql-fuji {
            background: $background;
        }

        &.collapsed {
            padding: 0.1rem 0.75rem;
        }

        &.with-noupy:after {
            content: "";
            position: absolute;
            left: -0.3rem;
            top: 1.1rem;
            transform: rotate(45deg);
            width: 0.6rem;
            height: 0.6rem;
            background: inherit;
        }
    }
}

.editor-wrapper {
    margin: 0 !important;
}

.comments-container {
    grid-area: footer;
}

.comment-footer {
    margin-top: 0.5rem;
}

.content {
    border: 1px solid transparent;
    transition: padding 0.2s ease 0.2s, border 0.2s ease-in, margin 0.2s ease 0.2s;

    &.card {
        border-radius: var(--border-radius);
        background: none;
        border: none;
        transition: all 0.2s ease;
    }

    &.padding-small {
        margin-left: -1rem;
        margin-right: -1rem;
    }

}

.comment-avatar {
    border: none;
    grid-area: avatar;
    margin-top: 0.4rem;
    width: 2rem;
    height: 2rem;
}

.avatar-stack {
    .avatar {
        outline: 2px solid var(--background, #{$white});
        margin-left: -10px;
    }
}

.deleted-comment {
    > .content-container > .content {
        opacity: 0.5;
        pointer-events: none;
        font-size: 0.75rem;
    }

    > .content-container > .header {
        .v-popover {
            opacity: 0.5 !important;
        }
    }
}

</style>
