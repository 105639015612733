import Quill,
{ QuillOptionsStatic } from "quill";
import { ImageBlot } from "@web/lib/quill/image/image-blot";

// @ts-ignore
import Module from "quill/core/module";

const Parchment = Quill.import("parchment");

export class ImageBlotSelection extends Module {
    constructor(quill: Quill, options: QuillOptionsStatic) {
        super(quill, options);
        quill.root.addEventListener("dblclick", (e: MouseEvent) => {
            const target = e.target as Node;
            // necessary as image loading spinner receives clicks
            if (!target || !target.parentNode || !target.parentNode.parentNode) {
                return;
            }
            const img = Parchment.find(target.parentNode.parentNode);
            if (img instanceof ImageBlot) {
                // fixes selection to make image copy/pastable
                quill.setSelection(img.offset(quill.scroll), 1, "user");
            }
        });
    }
}
