import { VuexRootState } from "@web/store";
import { Module } from "vuex";
import {
    EverestNews,
    EverestNewsInFireStoreFieldKeys,
} from "@backend/news/types";
import {
    CREATE_NEWS,
    DELETE_NEWS,
    FETCH_MY_DRAFTS,
    FETCH_MY_NEWS,
    FETCH_PUBLISHED_NEWS,
    LOAD_NEXT_MY_DRAFTS,
    OPEN_NEWS,
    SAVE_NEWS,
    UPDATE_PICTURE_REFS,
} from "@web/store/news/actions";
import { newsService } from "@web/services";
import {
    RESET_CURRENT_NEWS,
    RESET_NEWS_MODULE,
    RESET_PUBLISHED_NEWS,
    SET_ALL_IMAGES_IN_CONTENT,
    SET_CURRENT_NEWS,
    SET_DRAFTS,
    SET_LOADING_CURRENT,
    SET_LOADING_DRAFTS,
    SET_LOADING_MY_NEWS,
    SET_LOADING_PUBLISHED,
    SET_MY_NEWS,
    SET_PUBLISHED_NEWS,
    SET_PUBLISHED_NEWS_LAST_RESULT_LENGTH,
    STOP_LOADING_CURRENT_NEWS,
    UPDATE_CURRENT_NEWS,
} from "@web/store/news/mutations";
import {
    ALL_IMAGES_IN_CONTENT,
    CURRENT_NEWS,
    CURRENT_NEWS_UID,
    IS_LOADING_PUBLISHED_NEWS,
    PUBLISHED_NEWS,
    PUBLISHED_NEWS_LAST_FETCH_WAS_EMPTY,
    SELECTED_COVER_PICTURE,
} from "@web/store/news/getters";
import {
    NewsService,
    PublishedNewsParams,
} from "@web/store/news/NewsService";
import {
    DeltaFormat,
    PublishableEntityStatus,
} from "@backend/entity/types";
import { TimestampEvent } from "@web/store/timestamping/types";

export const NEWS_MODULE_NAME = "news/";

export interface NewsModuleState {
    publishedNews: EverestNews[];
    publishedNewsLastResultLength: number;
    loadingPublishedNews: boolean;
    myDrafts: EverestNews[];
    myNews: EverestNews[];
    loadingMyDrafts: boolean;
    loadingMyNews: boolean;
    current: CurrentNews;
    loadingCurrent: boolean;
}

interface CurrentNews {
    news: EverestNews;
    allImagesInContent: string[];
}

const emptyNews = {
    [EverestNewsInFireStoreFieldKeys.title]: "",
    [EverestNewsInFireStoreFieldKeys.subtitle]: "",
    [EverestNewsInFireStoreFieldKeys.creatorUid]: "",
    [EverestNewsInFireStoreFieldKeys.status]: PublishableEntityStatus.draft,
    [EverestNewsInFireStoreFieldKeys.formatVersion]: 1,
    [EverestNewsInFireStoreFieldKeys.tags]: {},
    [EverestNewsInFireStoreFieldKeys.publishDate]: new Date() as any, // any-casting needed due to googles internal date -> timestamp conversion
    [EverestNewsInFireStoreFieldKeys.creationDate]: new Date() as any,
    [EverestNewsInFireStoreFieldKeys.lastEditedDate]: new Date() as any,
    [EverestNewsInFireStoreFieldKeys.coverPictureRef]: "",
    [EverestNewsInFireStoreFieldKeys.pictureRefs]: [],
    [EverestNewsInFireStoreFieldKeys.unsplashAuthors]: {},
    [EverestNewsInFireStoreFieldKeys.overallCommentCount]: 0,
    [EverestNewsInFireStoreFieldKeys.commentCount]: 0,
    [EverestNewsInFireStoreFieldKeys.readingTime]: 0,
    [EverestNewsInFireStoreFieldKeys.reactions]: { love: 0, like: 0, dislike: 0, smile: 0, clap: 0 },
    content: [],
    uid: "", // Empty uid is used to check if news exists in the NewsRenderer component for historic reasons
};

const getDefaultState = () => ({
    publishedNews: [],
    publishedNewsLastResultLength: 0,
    loadingPublishedNews: false,
    myDrafts: [],
    myNews: [],
    loadingMyDrafts: false,
    loadingMyNews: false,
    current: { news: emptyNews, allImagesInContent: [] },
    loadingCurrent: false,
});

export const NEWS_MODULE = {
    strict: true,
    namespaced: true,
    state: getDefaultState(),
    getters: {
        [PUBLISHED_NEWS](state) {
            return state.publishedNews;
        },
        [PUBLISHED_NEWS_LAST_FETCH_WAS_EMPTY](state) {
            return state.publishedNewsLastResultLength === 0;
        },
        [ALL_IMAGES_IN_CONTENT](state) {
            return state.current.allImagesInContent;
        },
        [SELECTED_COVER_PICTURE](state) {
            return state.current.news.coverPictureRef;
        },
        [CURRENT_NEWS](state) {
            return state.current.news;
        },
        [CURRENT_NEWS_UID](state) {
            return state.current.news.uid;
        },
        [IS_LOADING_PUBLISHED_NEWS](state) {
            return state.loadingPublishedNews;
        },
    },
    mutations: {
        [SET_DRAFTS](state, drafts) {
            state.myDrafts = drafts;
            state.loadingMyDrafts = false;
        },
        [SET_MY_NEWS](state, news) {
            state.myNews = news;
            state.loadingMyNews = false;
        },
        [SET_CURRENT_NEWS](state, { news }) {
            state.current.news = news;
            state.loadingCurrent = false;
        },
        [UPDATE_CURRENT_NEWS](state, newsUpdate) {
            state.current.news = { ...state.current.news, ...newsUpdate };
        },
        [STOP_LOADING_CURRENT_NEWS](state) {
            state.loadingCurrent = false;
        },
        [RESET_CURRENT_NEWS](state) {
            state.current = { news: emptyNews, allImagesInContent: [] };
            state.loadingCurrent = false;
        },
        [RESET_PUBLISHED_NEWS](state) {
            state.publishedNews = [];
        },
        [SET_PUBLISHED_NEWS](state, publishedNews) {
            state.publishedNews = publishedNews;
            state.loadingPublishedNews = false;
        },
        [SET_PUBLISHED_NEWS_LAST_RESULT_LENGTH](state, amount) {
            state.publishedNewsLastResultLength = amount;
        },
        [SET_LOADING_MY_NEWS](state) {
            state.loadingMyNews = true;
        },
        [SET_LOADING_DRAFTS](state) {
            state.loadingMyDrafts = true;
        },
        [SET_LOADING_CURRENT](state) {
            state.loadingCurrent = true;
        },
        [SET_LOADING_PUBLISHED](state) {
            state.loadingPublishedNews = true;
        },
        [SET_ALL_IMAGES_IN_CONTENT](state, allImagesInContent) {
            state.current.allImagesInContent = allImagesInContent;
        },
        [RESET_NEWS_MODULE](state) {
            Object.assign(state, getDefaultState());
        },
    },
    actions: {
        /**
         * fetches next bulk of given amount of published news, if no amount is provided the required amount for the the next bulk is fetched
         */
        async [FETCH_PUBLISHED_NEWS]({ commit, rootState, state }, { limit, onlyUpdates } = {}) {
            if (state.loadingPublishedNews) {
                return;
            }
            commit(SET_LOADING_PUBLISHED);

            if (onlyUpdates) {
                const unreadNewsCount = rootState.timestamp.count[TimestampEvent.newsPublished];
                const publishedNewsParam: PublishedNewsParams = {
                    intranetUid: rootState.intranet.intranet!.uid,
                    limit: unreadNewsCount || 1,
                    startBefore: state.publishedNews[0]?.uid
                };
                const newsUpdates = await newsService.getPublishedNews(publishedNewsParam);
                const newsUids = newsUpdates.map(newNews => newNews.uid);
                const publishedNewsWithoutNewNews = state.publishedNews.filter(publishedNews => newsUids.indexOf(publishedNews.uid) < 0);
                commit(SET_PUBLISHED_NEWS, [...newsUpdates, ...publishedNewsWithoutNewNews]);
            } else {
                const publishedNewsParam: PublishedNewsParams = {
                    intranetUid: rootState.intranet.intranet!.uid,
                    limit: limit || (state.publishedNews.length % NewsService.DEFAULT_PAGE_SIZE === 0 ? NewsService.DEFAULT_PAGE_SIZE : NewsService.DEFAULT_PAGE_SIZE - state.publishedNews.length % 10),
                    startAfter: state.publishedNews[state.publishedNews.length - 1]?.uid
                };
                const news = await newsService.getPublishedNews(publishedNewsParam);
                commit(SET_PUBLISHED_NEWS_LAST_RESULT_LENGTH, news.length);
                commit(SET_PUBLISHED_NEWS, [...state.publishedNews, ...news]);
            }
        },
        async [FETCH_MY_DRAFTS]({ state, rootState, commit }) {
            commit(SET_LOADING_DRAFTS);
            const intranet = rootState.intranet.intranet;
            const drafts = await newsService.getMyDrafts(intranet!.uid);
            commit(SET_DRAFTS, drafts);
        },
        async [FETCH_MY_NEWS]({ commit, rootState }) {
            commit(SET_LOADING_MY_NEWS);
            const intranet = rootState.intranet.intranet;
            const news = await newsService.getMyNews(intranet!.uid);
            commit(SET_MY_NEWS, news);
        },
        async [LOAD_NEXT_MY_DRAFTS]({ state, rootState, commit }) {
            // draft lists longer than 20 items are not supported yet.
        },
        async [OPEN_NEWS]({ state, commit, rootState }, uid) {
            const intranet = rootState.intranet.intranet;
            const currentUser = rootState.auth.currentUser;
            commit(SET_LOADING_CURRENT);
            if (!uid) {
                commit(RESET_CURRENT_NEWS);
            } else {
                const news = await newsService.getNews({ intranetUid: intranet!.uid, newsUid: uid, requestingUserUid: currentUser!.uid, format: DeltaFormat.web });
                commit(SET_CURRENT_NEWS, { news });
            }
        },
        async [SAVE_NEWS]({ state, rootState, commit, dispatch }, { silent, news, newsUid }) {
            console.log(`saveNews silent: ${silent}, news: ${JSON.stringify(news)}`);
            const intranet = rootState.intranet.intranet;
            if (!(silent ?? false)) {
                commit(SET_LOADING_CURRENT);
            }
            return new Promise(async(resolve, reject) => {
                try {
                    const currentUser = rootState.auth.currentUser;
                    await newsService.updateNews(intranet!.uid, newsUid || (await newsService.createNews(intranet!.uid)).uid, news);

                    // reload news because news from updateNews has no content
                    const newNews = await newsService.getNews({ intranetUid: intranet!.uid, newsUid: newsUid, requestingUserUid: currentUser!.uid, format: DeltaFormat.web });

                    commit(SET_CURRENT_NEWS, { news: newNews });
                    if (!silent) {
                        commit(RESET_PUBLISHED_NEWS);
                    }
                    newsService.refreshCacheKey();
                    resolve(newNews);
                } catch (e) {
                    reject(e);
                    commit(STOP_LOADING_CURRENT_NEWS);
                }
            });
        },
        async [CREATE_NEWS]({ dispatch, rootState, commit }) {
            commit(SET_LOADING_CURRENT);
            return new Promise(async(resolve, reject) => {
                const intranet = rootState.intranet.intranet;
                const uid = (await newsService.createNews(intranet!.uid)).uid;
                commit(STOP_LOADING_CURRENT_NEWS);
                resolve(uid);
            });
        },
        async [DELETE_NEWS]({ state, rootState, commit, dispatch }) {
            commit(SET_LOADING_CURRENT);
            const intranet = rootState.intranet.intranet;
            if (state.current.news.uid) {
                await newsService.deleteNews(intranet!.uid, state.current.news.uid);
            }
            commit(RESET_CURRENT_NEWS);
            commit(RESET_PUBLISHED_NEWS);
            newsService.refreshCacheKey();
            dispatch(FETCH_PUBLISHED_NEWS);
        },
        [UPDATE_PICTURE_REFS]({ state, commit }, newPictureRefs) {
            const { coverPictureRef } = state.current.news;
            commit(SET_ALL_IMAGES_IN_CONTENT, newPictureRefs);
            const { allImagesInContent } = state.current;
            // Set the first picture from the news as coverpicture, if the consisting coverpicutre was deleted
            if (allImagesInContent.length > 0 && !allImagesInContent.includes(coverPictureRef)) {
                return commit(UPDATE_CURRENT_NEWS, { coverPictureRef: allImagesInContent[0] });
            }
            // Set coverpicture back to none if the news have no pictures at all
            if (allImagesInContent.length === 0) {
                return commit(UPDATE_CURRENT_NEWS, { coverPictureRef: "" });
            }
        }
    },
} as Module<NewsModuleState, VuexRootState>;
