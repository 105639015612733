import { cloudRunUrl } from "@web/cloud-run-url";
import { ApiClientBuilder } from "@web/api/ApiClientBuilder";
import { ApiClient } from "@web/api/ApiClient";
import {
    EverestNews,
    EverestNewsContent,
    EverestNewsInFireStoreFieldKeys,
    EverestNewsUpdate,
} from "@backend/news/types";
import { analytics } from "@web/analytics";
import { getGlobalConfiguration } from "@web/global-config";
import { compressImage } from "@web/lib/image-compression";
import { ScaledImageSize } from "@backend/common/image-resizing/types";
import { DeltaFormat } from "@backend/entity/types";

export interface PublishedNewsParams {
    intranetUid: string;
    startAfter?: string;
    startBefore?: string;
    limit?: number;
}

interface GetNewsParams {
    intranetUid: string;
    newsUid: string;
    requestingUserUid?: string;
    format?: DeltaFormat.plain | DeltaFormat.web;
}

export class NewsService {
    public static readonly newsEndpoint = `${cloudRunUrl.news}/api/news/news/`;
    public static readonly publishedNewsEndpoint = `${cloudRunUrl.news}/api/news/published/`;
    public static readonly mydraftsEndpoint = `${cloudRunUrl.news}/api/news/mydrafts/`;
    public static readonly mynewsEndpoint = `${cloudRunUrl.news}/api/news/mynews/`;

    public static readonly DEFAULT_PAGE_SIZE = 10;

    private api: Promise<ApiClient>;

    private cacheKey!: string;
    private localStorageKey = "linchpin-cloud-news-service-cache-key";

    private uncachedNewsUids: string[] = [];

    constructor() {
        this.api = new ApiClientBuilder().build();
        this.loadCacheKey();
    }

    public async createNews(intranetUid: string): Promise<EverestNews & EverestNewsContent> {
        const client = await this.api;
        const response = await client.post<EverestNews & EverestNewsContent>(`${NewsService.newsEndpoint}${intranetUid}`);
        analytics.log(getGlobalConfiguration().analytics_event_name_news_created);
        return response.data;
    }

    public async updateNews(intranetUid: string, newsUid: string, changeRequest: EverestNewsUpdate & { content?: string }): Promise<EverestNews & EverestNewsContent> {
        const client = await this.api;
        const response = await client.put<EverestNews & EverestNewsContent>(`${NewsService.newsEndpoint}${intranetUid}/${newsUid}?format=web`, changeRequest);
        return response.data;
    }

    public async getNews({ intranetUid, newsUid, requestingUserUid, format }: GetNewsParams): Promise<EverestNews & EverestNewsContent> {
        const client = await this.api;
        const response = await client.get<EverestNews & EverestNewsContent>(`${NewsService.newsEndpoint}${intranetUid}/${newsUid}`, {
            params: {
                format: format,
                cacheKey: (this.isUncachedNews(newsUid)) ? NewsService.generateCacheKey() : this.cacheKey,
            },
        });
        if (requestingUserUid && response.data[EverestNewsInFireStoreFieldKeys.creatorUid] === requestingUserUid) {
            this.uncachedNewsUids.push(response.data.uid);
        }
        return response.data;
    }

    public async deleteNews(intranetUid: string, newsUid: string): Promise<void> {
        const client = await this.api;
        await client.delete(`${NewsService.newsEndpoint}${intranetUid}/${newsUid}`);
    }

    public async getPublishedNews({ intranetUid, startAfter, startBefore, limit }: PublishedNewsParams): Promise<EverestNews[]> {
        const client = await this.api;
        const response = await client.get<EverestNews[]>(`${NewsService.publishedNewsEndpoint}${intranetUid}`, {
            params: {
                cacheKey: this.cacheKey,
                startAfter,
                startBefore,
                limit: limit || NewsService.DEFAULT_PAGE_SIZE,
            },
        });
        return response.data;
    }

    public async getMyDrafts(intranetUid: string): Promise<EverestNews[]> {
        const client = await this.api;
        // draft lists longer than 20 items are not supported yet.
        const response = await client.get<EverestNews[]>(`${NewsService.mydraftsEndpoint}${intranetUid}?cacheKey=${this.cacheKey}`);
        return response.data;
    }

    public async getMyNews(intranetUid: string): Promise<EverestNews[]> {
        const client = await this.api;
        const response = await client.get<EverestNews[]>(`${NewsService.mynewsEndpoint}${intranetUid}?cacheKey=${this.cacheKey}`);
        return response.data;
    }

    public async uploadImageInNews(intranetUid: string, newsUid: string, uploaderUid: string, file: File): Promise<{ downloadUrl: string, storageref: string }> {
        const compressedFile = await compressImage(file);
        const client = await this.api;
        const signedUrlResponse = await client.post<{ uploadUrl: string, path: string }>(`${NewsService.newsEndpoint}${intranetUid}/${newsUid}/provide-upload-url`, {
            contentType: compressedFile.type,
            intranetUid,
            fileSize: compressedFile.size,
        });
        await client.put(signedUrlResponse.data.uploadUrl, compressedFile, {
            headers: {
                "Content-Type": compressedFile.type,
                "x-goog-content-length-range": `0,${compressedFile.size}`,
                "x-goog-file-path": signedUrlResponse.data.path,
                "x-goog-meta-uploaded-to-intranet": intranetUid,
                "x-goog-meta-uploaded-by": uploaderUid,
            },
        });

        const downloadUrl = await this.getDownloadUrlForImage(intranetUid, newsUid, signedUrlResponse.data.path);
        return {
            downloadUrl,
            storageref: signedUrlResponse.data.path,
        };
    }

    public async getDownloadUrlForImage(intranetUid: string, newsUid: string, storageRef: string, preferredSize?: ScaledImageSize): Promise<string> {
        const client = await this.api;
        if (!storageRef.startsWith(WEB_URL_STORAGE_REF_PREFIX)) {
            const downloadUrlResponse = await client.get<string>(
                `${NewsService.newsEndpoint}${intranetUid}/${newsUid}/valid-download-url-for-image`,
                {
                    params: {
                        filePath: storageRef,
                        preferredSize,
                    },
                },
            );
            return downloadUrlResponse.data;
        } else {
            const [, webUrl] = storageRef.split(WEB_URL_STORAGE_REF_PREFIX);
            return webUrl;
        }
    }

    public refreshCacheKey(): void {
        this.cacheKey = NewsService.generateCacheKey();
        localStorage.setItem(this.localStorageKey, this.cacheKey);
    }

    private static generateCacheKey(): string {
        return Math.random().toString(36).substring(7);
    }

    private isUncachedNews(newsUid: string): boolean {
        return this.uncachedNewsUids.some(uid => uid === newsUid);
    }

    private loadCacheKey(): void {
        const keyFromStorage = localStorage.getItem(this.localStorageKey);
        if (keyFromStorage !== null) {
            this.cacheKey = keyFromStorage;
        } else {
            this.refreshCacheKey();
        }
    }
}

export const WEB_URL_STORAGE_REF_PREFIX = "weburl://";
