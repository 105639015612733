



























































import Vue from "vue";
import {
    mapActions,
    mapGetters,
    mapMutations
} from "vuex";
import { NEWS_MODULE_NAME } from "@web/store/news/news";
import { FETCH_PUBLISHED_NEWS } from "@web/store/news/actions";
import NewsTeaser from "@web/components/news/NewsTeaser.vue";
import { NewsRendererMode } from "@web/views/news/NewsRenderer.vue";
import { RESET_PUBLISHED_NEWS } from "@web/store/news/mutations";
import { TIMESTAMP_MODULE_NAME } from "@web/store/timestamping/timestamping";
import { TimestampEvent } from "@web/store/timestamping/types";
import {
    UNREAD_NEWS,
    UNREAD_NEWS_COUNT
} from "@web/store/timestamping/getters";
import { RESOLVE_EVENT } from "@web/store/timestamping/mutations";
import {
    IS_LOADING_PUBLISHED_NEWS,
    PUBLISHED_NEWS,
    PUBLISHED_NEWS_LAST_FETCH_WAS_EMPTY
} from "@web/store/news/getters";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import { INTRANET } from "@web/store/intranet/getters";
import InfiniteScrollSpinner from "@web/components/InfiniteScrollSpinner.vue";
import { detectLocale } from "@web/i18n";
import StickyNewContentIndicator from "@web/components/StickyNewContentIndicator.vue";
import { analytics } from "@web/analytics";
import { getGlobalConfiguration } from "@web/global-config";

export default Vue.extend<any, any, any, any>({
    name: "PublishedNewsList",
    components: {
        NewsTeaser,
        InfiniteScrollSpinner,
        StickyNewContentIndicator,
    },
    props: {
        // macro users should not write news outside of linchpin
        canCreateNews: {
            type: Boolean,
            default: true
        },
        isIntegration: {
            type: Boolean,
            default: false
        },
        locale: {
            type: String,
            default: detectLocale,
            required: false
        },
    },
    computed: {
        ...mapGetters({
            unreadNews: TIMESTAMP_MODULE_NAME + UNREAD_NEWS,
            unreadNewsCount: TIMESTAMP_MODULE_NAME + UNREAD_NEWS_COUNT,
            publishedNews: NEWS_MODULE_NAME + PUBLISHED_NEWS,
            publishedNewsLastFetchWasEmpty: NEWS_MODULE_NAME + PUBLISHED_NEWS_LAST_FETCH_WAS_EMPTY,
            isLoadingPublishedNews: NEWS_MODULE_NAME + IS_LOADING_PUBLISHED_NEWS,
            intranet: INTRANET_MODULE_NAME + INTRANET,
        }),
        showLoadingState() {
            return this.isLoadingPublishedNews && this.publishedNews.length == 0;
        }
    },
    methods: {
        ...mapActions({
            fetch: NEWS_MODULE_NAME + FETCH_PUBLISHED_NEWS,
        }),
        ...mapMutations({
            reset: NEWS_MODULE_NAME + RESET_PUBLISHED_NEWS,
            resolveEvent: TIMESTAMP_MODULE_NAME + RESOLVE_EVENT
        }),
        openEditor() {
            this.$modal.show("news-renderer", {
                newsUid: "",
                mode: NewsRendererMode.CREATE
            });
        },
        fetchNewsUpdates() {
            this.fetch({ onlyUpdates: true });
            window.scrollTo({ top: 0, behavior: "smooth" });
            this.resolveEvent(TimestampEvent.newsPublished);
            analytics.log(getGlobalConfiguration().analytics_event_name_new_content_loaded_in_news);
        },
        triggerFetch() {
            if (!this.isLoadingPublishedNews && !this.publishedNewsLastFetchWasEmpty) {
                this.fetchNews();
            }
        },
        async fetchNews() {
            await this.fetch();
            this.$emit("load-more-news", this.publishedNews.length);
        }
    },
    created() {
        if (this[UNREAD_NEWS]) {
            this.reset();
        }
        this.fetchNews();
        this.resolveEvent(TimestampEvent.newsPublished);
    },
    beforeDestroy() {
        this.reset();
    }
});
