import { render, staticRenderFns } from "./EventModal.vue?vue&type=template&id=4ef3acbe&scoped=true&"
import script from "./EventModal.vue?vue&type=script&lang=js&"
export * from "./EventModal.vue?vue&type=script&lang=js&"
import style0 from "./EventModal.vue?vue&type=style&index=0&id=4ef3acbe&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ef3acbe",
  null
  
)

export default component.exports