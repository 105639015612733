import { TopicIndexEntity } from "@backend/topic/index-types";
import {
    TOPIC_BY_USAGE_INDEX_REPLICA,
    TOPIC_INDEX,
} from "@backend/index/types";
import { AbstractSearchService } from "@web/services/AbstractSearchService";
import { cloudRunUrl } from "@web/cloud-run-url";

const topicSearchTokenEndpoint = `${cloudRunUrl.thirdPartySearch}/api/search/topic-search-token/`;

class TopicSearchService extends AbstractSearchService<TopicIndexEntity> {
    constructor() {
        super(TOPIC_INDEX, topicSearchTokenEndpoint);
    }
}

export const topicSearchService = new TopicSearchService();

class TopicByUsageSearchService extends AbstractSearchService<TopicIndexEntity> {
    constructor() {
        super(TOPIC_BY_USAGE_INDEX_REPLICA, topicSearchTokenEndpoint);
    }
}

export const topicByUsageSearchService = new TopicByUsageSearchService();
