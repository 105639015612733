import { cloudRunUrl } from "@web/cloud-run-url";
import {
    TEAM_INDEX_NAME,
    TeamIndexEntity,
} from "@backend/index/types";
import { AbstractSearchService } from "@web/services/AbstractSearchService";

const teamSearchTokenEndpoint = `${cloudRunUrl.thirdPartySearch}/api/search/team-search-token/`;

export class TeamSearchService extends AbstractSearchService<TeamIndexEntity> {
    constructor() {
        super(TEAM_INDEX_NAME, teamSearchTokenEndpoint);
    }
}
