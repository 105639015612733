<template>
    <select-autocomplete
        :suggestions="offeredSuggestions"
        @input="onSelectSuggestion"
    >
        <template #input="{selectUpperEntry, selectLowerEntry, selectEntry}">
            <topic-label
                :label-classes="labelClasses"
                readonly
                @click.native="focusInput"
            >
                <input
                    ref="queryInput"
                    v-model="queryState"
                    v-input-auto-width
                    :maxlength="inputValidationTopicNameMaxLength"
                    autocomplete="off"
                    class="background-none margin-none padding-none border-none query-input"
                    @input="debouncedSearchSuggestions"
                    @keydown.esc="endQuery"
                    @keydown.prevent.up="selectUpperEntry"
                    @keydown.prevent.down="selectLowerEntry"
                    @keydown.prevent.enter="selectEntry"
                />
            </topic-label>
        </template>
        <template #suggestions="{selectedIndex, setSelectedIndex}">
            <template v-if="initialLoading">
                <pu-skeleton
                    v-for="i in 3"
                    :key="i"
                    class="mx-xxsmall"
                    width="10ch"
                />
            </template>
            <div
                v-for="(suggestion, index) in offeredSuggestions"
                :key="suggestion.uid"
                :class="{'background-low-contrast': selectedIndex === index}"
                class="text-nowrap padding-xxsmall cursor-pointer hover:background-low-contrast"
                @click="onSelectSuggestion(suggestion)"
                @mouseenter="setSelectedIndex(index)"
            >
                {{ suggestion.name }}
                <span
                    v-if="suggestion.usageCount !== undefined"
                    class="text-high-contrast"
                >
                    ({{ suggestion.usageCount }})
                </span>
            </div>
        </template>
    </select-autocomplete>
</template>

<script>
import debounce from "lodash/debounce";
import SelectAutocomplete from "@web/components/SelectAutocomplete";
import TopicLabel from "@web/components/topics/TopicLabel";
import { getGlobalConfiguration } from "@web/global-config";

const inputValidationTopicNameMaxLength = getGlobalConfiguration().input_validation_topic_name_max_length;
const createTopicSuggestionUid = "create-topic";

/**
 * Autocomplete of a single topic from a list of suggestions queried by an input value.
 * The styling is intended to only work when a query is started and otherwise invisible.
 * @see [TopicMultiSelect](#/Components/TopicMultiSelect), [SelectAutocomplete](#/Components/SelectAutocomplete)
 */
export default {
    name: "TopicSelectAutocomplete",
    components: {
        TopicLabel,
        SelectAutocomplete,
    },
    props: {
        /** Synced query string used to display suggestions.
         *  @synced */
        query: String,
        /** The selected topic from suggestions.
         *  @type {Topic | undefined} */
        value: Object,
        /** Search callback for autocompletion.
         * @type {(query: string) => Promise<Topic[]>} */
        search: { type: Function, required: true },
        /** Applied CSS classes to the label part */
        labelClasses: [String, Object, Array],
    },
    data() {
        return {
            /** @synced
             * @type {string | undefined} */
            queryState: this.query,
            /** @type {Topic[]} */
            suggestions: [],
            initialLoading: true,
            inputValidationTopicNameMaxLength,
        };
    },
    computed: {
        /** @return {TopicBase[]} */
        offeredSuggestions() {
            if (this.initialLoading) {
                return [];
            }
            if (this.suggestions.length === 0) {
                return [{
                    uid: createTopicSuggestionUid,
                    name: this.$t("topics_add"),
                }];
            }
            return this.suggestions;
        },
    },
    watch: {
        queryState(queryState) {
            if (this.queryState === this.query) return;
            /** Emitted when the query changes by typing in the input or selecting a topic.
             *  @property {string} query - the query string in the input field */
            this.$emit("update:query", queryState);
        },
    },
    created() {
        // The debounce function MUST be created once per instance. Otherwise the closure state of the debounce function is shared between all instances of the Vue component.
        this.debouncedSearchSuggestions = debounce(this.searchSuggestions, 200);
    },
    mounted() {
        this.startQuery();
    },
    methods: {
        startQuery() {
            this.queryState = this.query;
            this.searchSuggestions();
            this.$nextTick(() => this.focusInput());
        },
        endQuery() {
            this.queryState = undefined;
            this.suggestions = [];
        },
        focusInput() {
            this.$refs.queryInput.focus();
        },
        async searchSuggestions() {
            try {
                this.suggestions = await this.search(this.queryState);
            } catch (e) {
                console.error("unable to fetch suggestions", e);
            } finally {
                this.initialLoading = false;
            }
        },
        debouncedSearchSuggestions() {
            // placeholder for IDE support, overwritten in created to be debounced per instance.
        },
        onSelectSuggestion(suggestion) {
            if (suggestion.uid === createTopicSuggestionUid) {
                this.emitCreateTopic();
            } else {
                this.emitInput(suggestion);
            }
        },
        emitInput(topic) {
            this.endQuery();
            /** Emitted when a topic was selected from the suggestions.
             *  @property {Topic} topic - the selected topic from the suggestions */
            this.$emit("input", topic);
        },
        emitCreateTopic() {
            const query = this.queryState;
            this.endQuery();
            /** Emitted when no topic was selected but the option to create a new topic was selected.
             *  @property {string} query - the current query in the input */
            this.$emit("create-topic", query);
        },
    },
};
</script>

<style lang="scss" scoped>
.query-input {
    min-width: 10ch;
}
</style>
