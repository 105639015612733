
















































import Vue from "vue";
import { newsService } from "@web/services";
import store from "@web/store";
import { INTRANET } from "@web/store/intranet/getters";
import {
    mapGetters,
    mapMutations
} from "vuex";
import { NEWS_MODULE_NAME } from "@web/store/news/news";
import { UPDATE_CURRENT_NEWS } from "@web/store/news/mutations";
import {
    ALL_IMAGES_IN_CONTENT,
    SELECTED_COVER_PICTURE
} from "@web/store/news/getters";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import Icon from "@web/components/Icon.vue";

const AUTHOR_IS_COVER_PICTURE = "AUTHOR_IS_COVER_PICTURE";
const COVER_PICTURE_NOT_SET = "";

export default Vue.extend<any, any, any, any>({
    name: "CoverPictureSelect",
    components: {
        Icon,
    },
    props: {
        pictureRefs: {
            default: () => [],
            required: true,
            type: Array
        }
    },
    computed: {
        newsHasNoPictureRef() {
            return this.pictureRefs !== null && this.pictureRefs.length === 0;
        },
        ...mapGetters({
            selectedCoverPicture: NEWS_MODULE_NAME + SELECTED_COVER_PICTURE,
            allImagesInContent: NEWS_MODULE_NAME + ALL_IMAGES_IN_CONTENT,
        }),
    },
    watch: {
        allImagesInContent(newValue, oldValue) {
            if (oldValue.length === 1 && oldValue[0] === null) {
                this.updateCoverPicture({ coverPictureRef: this.pictureRefs[0] });
            }
        },
    },
    data() {
        return {
            imgSources: {},
            AUTHOR_IS_COVER_PICTURE: AUTHOR_IS_COVER_PICTURE,
            COVER_PICTURE_NOT_SET: COVER_PICTURE_NOT_SET
        };
    },
    methods: {
        ...mapMutations({
            updateCoverPicture: NEWS_MODULE_NAME + UPDATE_CURRENT_NEWS,
        }),
        getImageUrl: async(ref: string) => {
            if (ref === null) {
                return ref;
            }
            return await newsService.getDownloadUrlForImage(store.getters[INTRANET_MODULE_NAME + INTRANET].uid, store.state.news.current.news.uid, ref);
        },
        loadUrls: function() {
            this.pictureRefs.forEach(async(ref: string) => {
                if (!this.imgSources[ref]) {
                    const url = await this.getImageUrl(ref);
                    Vue.set(this.imgSources, ref, url);
                }
            });
        },
        select: function(ref: string) {
            this.updateCoverPicture({ coverPictureRef: ref });
        },
    },
    updated() {
        this.loadUrls();
    },
    mounted() {
        this.loadUrls();
    }
});
