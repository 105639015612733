<template>
    <router-link
        v-resize:force-flex-column="500"
        :class="{'pointer-events-none': !to, 'flex-column': columnLayout}"
        :to="to || '#'"
        class="focus-outline-none overflow-hidden border-radius-small border-low-contrast flex"
    >
        <span
            v-if="$slots.featuring || featureImageUrl"
            :class="featuringSideClass || 'padding-small'"
            :style="featuringStyle"
            class="featuring-side background-low-contrast"
        >
            <!-- @slot Optional content for the left side -->
            <slot name="featuring"></slot>
        </span>
        <span
            :class="mainSideClass || 'padding-small'"
            class="main-side background-background"
        >
            <!-- @slot Content for the right side -->
            <slot name="main">
                <h4
                    v-if="$slots.title"
                    class="mbottom-xxsmall ellipsis"
                >
                    <!-- @slot A prestyled title -->
                    <slot name="title"></slot>
                </h4>
                <div
                    v-if="$slots.content"
                    class="clamp"
                >
                    <!-- @slot A prestyled content -->
                    <slot name="content"></slot>
                </div>
                <div
                    v-if="$slots.meta"
                    class="meta mtop-xxsmall"
                >
                    <!-- @slot A prestyled meta line -->
                    <slot name="meta"></slot>
                </div>
            </slot>
        </span>
    </router-link>
</template>

<script>
const Direction = Object.freeze({
    row: "row",
    column: "column",
});

/**
 * A linkable container to display internal content within the application.
 *
 * @requires ./FeaturedContentSkeleton.vue
 * @requires ./MissingFeaturedContent.vue
 */
export default {
    name: "FeaturedContent",
    props: {
        /**
         * A URL or router location.
         * @type {string | Location}
         * @see https://router.vuejs.org/api/#to
         */
        to: [String, Object],
        /** Displayed as background image on the left. */
        featureImageUrl: String,
        /** Overrides css classes for the left side. */
        featuringSideClass: String,
        /** Overrides css classes for the right side. */
        mainSideClass: String,
        /** Display feature and main part in one row or underneath in a column
         *  @values row, column */
        direction: { type: String, default: Direction.row, validate: (value) => Object.values(Direction).includes(value) },
    },
    computed: {
        featuringStyle() {
            if (!this.featureImageUrl) return undefined;
            return {
                backgroundImage: `url(${this.featureImageUrl})`,
            };
        },
        columnLayout() {
            return this.direction === Direction.column;
        },
    },
};
</script>

<style lang="scss" scoped>
.featuring-side {
    background-position: center;
    background-size: cover;
    min-height: 5rem;
    min-width: 12rem;
    flex: 0 0 auto;
}

.force-flex-column {
    flex-direction: column;
}

.main-side {
    flex: 1 1 auto;
    overflow: hidden;

    p:last-child {
        margin: 0;
    }
}
</style>

<docs>
Using all prestyled slots and feature image:
```vue
<featured-content
    feature-image-url="https://picsum.photos/300?id=0"
    to="/"
>
    <template #featuring>
        <div class="center-container">
            <avatar
                size="large"
                v-bind="{photoURL: 'https://i.pravatar.cc/64?id=0'}"
            />
        </div>
    </template>
    <template #title>Überall dieselbe alte Leier. Das Layout ist fertig, der Text lässt auf sich warten. Damit das Layout nun nicht nackt im Raume steht und sich klein und leer vorkommt, springe ich ein: der Blindtext. Genau zu diesem Zwecke erschaffen, immer im Schatten meines großen Bruders »Lorem Ipsum«, freue ich mich jedes Mal, wenn Sie ein paar Zeilen lesen. Denn esse est percipi - Sein ist wahrgenommen werden. Und weil Sie nun schon die Güte haben, mich ein paar weitere Sätze lang zu begleiten, möchte ich die</template>
    <template #content>Überall dieselbe alte Leier. Das Layout ist fertig, der Text lässt auf sich warten. Damit das Layout nun nicht nackt im Raume steht und sich klein und leer vorkommt, springe ich ein: der Blindtext. Genau zu diesem Zwecke erschaffen, immer im Schatten meines großen Bruders »Lorem Ipsum«, freue ich mich jedes Mal, wenn Sie ein paar Zeilen lesen. Denn esse est percipi - Sein ist wahrgenommen werden. Und weil Sie nun schon die Güte haben, mich ein paar weitere Sätze lang zu begleiten, möchte ich die</template>
    <template #meta>meta</template>
</featured-content>
```

Using all prestyled slots and feature image in column layout:
```vue
<featured-content
    direction="column"
    feature-image-url="https://picsum.photos/1024?id=1"
    to="/"
>
    <template #featuring>
        <div class="center-container">
            <avatar
                size="large"
                v-bind="{photoURL: 'https://i.pravatar.cc/64?id=0'}"
            />
        </div>
    </template>
    <template #title>Überall dieselbe alte Leier. Das Layout ist fertig, der Text lässt auf sich warten. Damit das Layout nun nicht nackt im Raume steht und sich klein und leer vorkommt, springe ich ein: der Blindtext. Genau zu diesem Zwecke erschaffen, immer im Schatten meines großen Bruders »Lorem Ipsum«, freue ich mich jedes Mal, wenn Sie ein paar Zeilen lesen. Denn esse est percipi - Sein ist wahrgenommen werden. Und weil Sie nun schon die Güte haben, mich ein paar weitere Sätze lang zu begleiten, möchte ich die</template>
    <template #content>Überall dieselbe alte Leier. Das Layout ist fertig, der Text lässt auf sich warten. Damit das Layout nun nicht nackt im Raume steht und sich klein und leer vorkommt, springe ich ein: der Blindtext. Genau zu diesem Zwecke erschaffen, immer im Schatten meines großen Bruders »Lorem Ipsum«, freue ich mich jedes Mal, wenn Sie ein paar Zeilen lesen. Denn esse est percipi - Sein ist wahrgenommen werden. Und weil Sie nun schon die Güte haben, mich ein paar weitere Sätze lang zu begleiten, möchte ich die</template>
    <template #meta>meta</template>
</featured-content>
```

Using only feature image with content:
```vue
<featured-content
    feature-image-url="https://picsum.photos/300?id=2"
    to="/"
>
    <template #title>title</template>
    <template #content>content</template>
    <template #meta>meta</template>
</featured-content>
```

Breaks when not enough space is available at around `500px`:
```vue
<div style="width: 503px">
    <featured-content
        feature-image-url="https://picsum.photos/300?id=2"
        to="/"
    >
        <template #title>title</template>
        <template #content>content</template>
        <template #meta>meta</template>
    </featured-content>
</div>
<div style="width: 502px">
    <featured-content
        feature-image-url="https://picsum.photos/300?id=2"
        to="/"
    >
        <template #title>title</template>
        <template #content>content</template>
        <template #meta>meta</template>
    </featured-content>
</div>
```

Using only the main side:
```vue
<featured-content
    to="/"
>
    <template #title>title</template>
    <template #content>content</template>
    <template #meta>meta</template>
</featured-content>
```

By default with padding and fully customizable sides:
```vue
<featured-content
    to="/"
>
    <template #featuring>
        <div class="center-container" style="background: var(--low-contrast)">
            left
        </div>
    </template>
    <template #main>
        <div class="center-container" style="background: var(--low-contrast)">
            right
        </div>
    </template>
</featured-content>
```

No padding when css classes are overriden:
```vue
<featured-content
    featuring-side-class="padding-none"
    main-side-class="padding-none"
    to="/"
>
    <template #featuring>
        <div class="center-container" style="background: var(--low-contrast)">
            left
        </div>
    </template>
    <template #main>
        <div class="center-container" style="background: var(--low-contrast)">
            right
        </div>
    </template>
</featured-content>
```

Skeleton is available with `FeaturedContentSkeleton`:
```vue
<featured-content-skeleton/>
```

Skeleton as column:
```vue
<featured-content-skeleton direction="column"/>
```

Indicate a missing or unreachable shared content with `MissingFeaturedContent`:
```vue
<missing-featured-content/>
```

Missing indicator as column:
```vue
<missing-featured-content direction="column"/>
```
</docs>
