<template>
    <div class="more-area">
        <div class="small-gallery">
            <single-media-attachment
                v-for="(attachment, index) in attachments"
                :key="attachment.localUid"
                class="small-attachment"
                :attachment="attachment"
                :edit-mode="editMode"
                :multi="2"
                @delete="$emit('delete', attachment)"
                @click.native="$emit('open-lightbox', index + 3)"
            />
        </div>
    </div>
</template>

<script>
import SingleMediaAttachment from "@web/components/attachments/SingleMediaAttachment";
export default {
    name: "MoreMediaAttachments",
    components: { SingleMediaAttachment },
    props: {
        attachments: Array,
        editMode: Boolean,
    },
};
</script>

<style lang="scss" scoped>
.more-area {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
}

.small-gallery {
    display: grid;
    grid-template-columns: repeat(5, 20%);
    margin: -0.5rem;

    .small-attachment {
        width: 100%;
        padding: .5rem;
    }
}
</style>
