import {
    IAxiosCacheAdapterOptions,
    ISetupCache,
    setupCache,
} from "axios-cache-adapter";
import axios,
{ AxiosInstance } from "axios";
import { ApiClient } from "@web/api/ApiClient";
import { setupPendingCache } from "@web/api/pending-cache-adapter";
import { configuredLocalForage } from "@web/lib/localforage";

export class ApiClientBuilder {
    private cache: ISetupCache | undefined;
    private cachePromise: Promise<void> = Promise.resolve();

    public withCache(cacheOptions: IAxiosCacheAdapterOptions): ApiClientBuilder {
        this.cachePromise = configuredLocalForage().then(() => {
            this.cache = setupCache(cacheOptions);
        });
        return this;
    }

    public async build(): Promise<ApiClient> {
        await this.cachePromise;
        let instance: AxiosInstance;
        if (this.cache) {
            instance = axios.create({
                adapter: setupPendingCache(this.cache.adapter),
            });
        } else {
            instance = axios.create({
                adapter: setupPendingCache(axios.defaults.adapter!),
            });
        }
        return new ApiClient(instance);
    }
}
