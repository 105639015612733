import "ie11-custom-properties";
// tslint:disable-next-line:no-import-side-effect
import "quill/dist/quill.core.css";
// tslint:disable-next-line:no-import-side-effect
import "quill/dist/quill.snow.css";
// tslint:disable-next-line:no-import-side-effect
import "@web/style/main.scss";
import { i18n } from "@web/i18n";
import Vue from "vue";
import App from "@web/App.vue";
import router from "@web/router";
import store from "@web/store";
import AppPlugins from "@web/vue-plugins";
import {
    AUTH_MODULE_NAME,
    AuthState,
} from "@web/store/auth/auth";
import { SETUP } from "@web/store/auth/actions";
import { AUTH_STATE } from "@web/store/auth/getters";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import { RESET_INTRANETS } from "@web/store/intranet/actions";
import {
    analytics,
    DefaultAnalyticsParams,
} from "@web/analytics";

Vue.config.productionTip = false;

analytics.defaultParamsFetcher = (): DefaultAnalyticsParams => {
    const intranetUid = store?.state?.intranet?.intranet?.uid || undefined;
    const userMail = store?.state?.auth?.currentUser?.email || undefined;
    const seibertMedia = userMail ? (userMail.includes("@seibert-media.net")) : undefined;
    return {
        intranetUid,
        seibertMedia,
    };
};

store.dispatch(AUTH_MODULE_NAME + SETUP);
store.watch(
    (state, getters) => getters[AUTH_MODULE_NAME + AUTH_STATE],
    async authState => {
        if (authState === AuthState.unauthenticated) {
            console.warn("User lost session. Redirecting back to login screen.");
            if (router.currentRoute.meta?.allowUnauthenticatedAccess !== true &&
                // within confluence cloud iframes we don't want an auto-redirect as the user interface would be to small
                !router.currentRoute.path.includes("atlassian-cloud/confluence")) {
                router.push("/login?redirect=" + router.currentRoute.path);
            }
            // Reset intranets when session is lost
            store.dispatch(INTRANET_MODULE_NAME + RESET_INTRANETS);
        }
        if (authState === AuthState.authenticated && router.currentRoute.query.redirect !== undefined) {
            const redirect = router.currentRoute.query.redirect as string;
            const intranetPathMatch = redirect.match("/intranet/([^/]+)/");
            if (intranetPathMatch) {
                const intranetId = intranetPathMatch[1];
                /*
                 * Add dashboard route to history to add an navigation for close actions, that call this.$router.back().
                 * It's necessary to await the first redirect to ensure it's written correctly to the router history.
                 */
                await router.push({ name: "dashboard", params: { intranetId: intranetId } }); // , () => router.push(redirect));
                await router.push(redirect);
            } else {
                await router.push(redirect);
            }
        }
    },
);

Vue.use(AppPlugins);

new Vue({
    i18n,
    router,
    store,
    render: h => h(App),
}).$mount("#app");
