<template>
    <div class="sidebar-header">
        <div class="left">
            <h2>{{ title }}</h2>
        </div>
        <div class="right">
            <slot ></slot>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: {
            type: String,
            default: "Headline"
        }
    }
};
</script>

<style lang="scss" scoped>
    .sidebar-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: -2rem -2rem 1rem;
        padding: 2rem 2rem 1rem;
        background: var(--background, #{$white});
        position: sticky;
        top: -2rem;
        z-index: 5;

        h2 {
            margin: 0;
        }
    }
</style>

<docs>
```vue
<sidebar-header/>
```
</docs>
