import {
    CommentWithAttachmentReference,
    EntityWithAttachmentReference
} from "@web/services/attachments";

export function buildUrl(serviceEndpoint: string, rootEntityReference: EntityWithAttachmentReference, subEntityUid?: string): string {
    let url = `${serviceEndpoint}/${rootEntityReference.intranetUid}`;
    if (rootEntityReference instanceof CommentWithAttachmentReference) {
        url += `/comment/${rootEntityReference.entityType}` +
            `-${rootEntityReference.entityUid}` +
            `-${rootEntityReference.commentPath}`;
    } else {
        url += `/${rootEntityReference.entityType}/${rootEntityReference.entityUid}`;
    }

    if (subEntityUid !== undefined) {
        url += `/${subEntityUid}`;
    }

    return url;
}
