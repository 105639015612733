export const ResizableTextarea: any = {
    methods: {
        resizeTextarea(event: any) {
            event.target.style.height = "auto";
            event.target.style.height = (event.target.scrollHeight) + "px";
        },
    },
    data() {
        return {
            resizeObserver: null
        };
    },
    mounted() {
        this.$nextTick(() => {
            this.$el.setAttribute("style", "height:" + (this.$el.scrollHeight - 0) + "px;overflow-y:hidden;");
        });

        // @ts-ignore
        this.resizeObserver = new ResizeObserver(entries => {
            for (const entry of entries) {
                this.resizeTextarea(entry);
            }
        });

        this.resizeObserver.observe(this.$el);

        this.$el.addEventListener("input", this.resizeTextarea);
    },
    beforeDestroy() {
        this.$el.removeEventListener("input", this.resizeTextarea);
        this.resizeObserver && this.resizeObserver.disconnect();
    },
    render(): any {
        return this.$slots.default[0];
    },
};
