import { ApiClientBuilder } from "@web/api/ApiClientBuilder";
import { ApiClient } from "@web/api/ApiClient";
import { AxiosResponse } from "axios";
import { cloudRunUrl } from "@web/cloud-run-url";
import { PaginationSearchParametersWithOffset } from "@backend/common/pagination-types";
import {
    Team,
    TeamPayload,
    TeamProfile,
    TeamProfileUpdatePayload,
} from "@backend/team/types";
import { TeamSearchService } from "@web/services/TeamSearchService";
import { UserProfile } from "@backend/user-profile/types";
import { DeltaFormat } from "@backend/entity/types";

export class TeamService {
    public static readonly teamServiceEndpoint = `${cloudRunUrl.team}/api/team`;

    private readonly api: Promise<ApiClient>;
    public readonly search: TeamSearchService;

    constructor() {
        this.api = new ApiClientBuilder().build();
        this.search = new TeamSearchService();
    }

    public async getTeamMembers(intranetUid: string, teamUid: string): Promise<UserProfile[]> {
        const client = await this.api;
        const response: AxiosResponse = await client.get(`${TeamService.teamServiceEndpoint}/${intranetUid}/${teamUid}/members`);
        return response.data;
    }

    public async getTeam(intranetUid: string, teamUid: string, format?: DeltaFormat.web | DeltaFormat.plain): Promise<TeamProfile> {
        const client = await this.api;
        const response: AxiosResponse = await client.get(`${TeamService.teamServiceEndpoint}/${intranetUid}/${teamUid}`, {
            params: {
                format,
            },
        });
        return response.data;
    }

    public async getTeams(intranetUid: string, paginationParams: PaginationSearchParametersWithOffset<string>): Promise<Team[]> {
        const client = await this.api;
        const response: AxiosResponse = await client.get(`${TeamService.teamServiceEndpoint}/${intranetUid}`, {
            params: {
                ...paginationParams,
            },
        });
        return response.data;
    }

    public async createTeam(intranetUid: string, teamData: TeamPayload): Promise<Team> {
        const client = await this.api;
        const response: AxiosResponse = await client.post(`${TeamService.teamServiceEndpoint}/${intranetUid}`, {
            ...teamData,
        });
        return response.data;
    }

    public async updateTeam(intranetUid: string, teamUid: string, teamUpdateData: TeamProfileUpdatePayload): Promise<Team> {
        const client = await this.api;
        const response: AxiosResponse = await client.patch(`${TeamService.teamServiceEndpoint}/${intranetUid}/${teamUid}`, {
            ...teamUpdateData,
        }, { params: { format: DeltaFormat.web } });
        return response.data;
    }

    public async deleteTeam(intranetUid: string, teamUid: string): Promise<void> {
        const client = await this.api;
        await client.delete(`${TeamService.teamServiceEndpoint}/${intranetUid}/${teamUid}`);
    }
}
