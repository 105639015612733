import {
    DeltaDoc,
    DeltaPresentation,
    EverestContentEntity,
    Publishable,
    Timestamp,
} from "@backend/entity/types";

export const EVENT_FORMAT_VERSION = 1;

export enum EverestEventInFireStoreFieldKeys {
    title = "title",
    startTimestamp = "startTimestamp",
    endTimestamp = "endTimestamp",
    attendees = "attendees",
    lastAttendees = "lastAttendees",
    revision = "revision",
    coverImageStoragePath = "coverImageStoragePath",
}

export enum EverestEventAttendeeInFireStoreFieldKeys {
    userUid = "userUid",
    intranetUid = "intranetUid", // Add redundant intranetUid to allow multi-tenancy
    status = "status",
    changedTimestamp = "changedTimestamp"
}

export interface EverestEventInFireStore extends EverestContentEntity, Publishable /* Commentable */ {
    [EverestEventInFireStoreFieldKeys.title]: string;
    [EverestEventInFireStoreFieldKeys.startTimestamp]: Timestamp;
    [EverestEventInFireStoreFieldKeys.endTimestamp]: Timestamp;
    [EverestEventInFireStoreFieldKeys.attendees]: number;
    [EverestEventInFireStoreFieldKeys.lastAttendees]: string[]; // userUid list
    [EverestEventInFireStoreFieldKeys.revision]: number;
    [EverestEventInFireStoreFieldKeys.coverImageStoragePath]?: string;
}

export interface EverestEvent extends EverestEventInFireStore {
    uid: string;
    description?: DeltaPresentation,
    coverImagePath?: string,
}

export type EverestEventUpdatePayload = Pick<EverestEvent, EverestEventInFireStoreFieldKeys.title | EverestEventInFireStoreFieldKeys.startTimestamp | EverestEventInFireStoreFieldKeys.endTimestamp | EverestEventInFireStoreFieldKeys.coverImageStoragePath> & {
    description?: DeltaDoc,
};

/**
 * Only attendee interface for the client.
 *
 * Notice: The firestore ids should be exposed to the client,
 * because no client logic should depend on it.
 * The userUid should be used for all operations regarding attendees.
 */
export interface EverestEventAttendee<DATETYPE = Timestamp | undefined> {
    [EverestEventAttendeeInFireStoreFieldKeys.userUid]: string;
    [EverestEventAttendeeInFireStoreFieldKeys.intranetUid]: string;
    [EverestEventAttendeeInFireStoreFieldKeys.status]: EverestEventAttendeeStatus;
    [EverestEventAttendeeInFireStoreFieldKeys.changedTimestamp]: DATETYPE;
}

export enum EverestEventAttendeeStatus {
    attending = "ATTENDING",
    notAttending = "NOT_ATTENDING",
}
