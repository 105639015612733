<template>
    <topic-label
        v-if="!error"
        :subscribed="subscribed"
        @subscribe="subscribe(topicUid)"
        @unsubscribe="unsubscribe(topicUid)"
    >
        <pu-skeleton width="5ch">
            {{ displayName }}
        </pu-skeleton>
    </topic-label>
</template>

<script>
import {
    mapActions,
    mapGetters,
} from "vuex";
import TopicLabel from "@web/components/topics/TopicLabel";
import { TOPIC_SUBSCRIPTION_MODULE_NAME } from "@web/store/topic-subscription/topic-subscription";
import {
    SUBSCRIBE_TOPIC,
    UNSUBSCRIBE_TOPIC,
} from "@web/store/topic-subscription/actions";
import { GET_TOPIC_UIDS } from "@web/store/topic-subscription/getters";
import { topicService } from "@web/store/topic/TopicService";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import { INTRANET_UID } from "@web/store/intranet/getters";

export default {
    name: "LoadingTopicLabel",
    components: {
        TopicLabel,
    },
    props: {
        topicUid: { type: String, required: true },
        name: String,
    },
    data() {
        return {
            subscribedState: undefined,
            displayName: this.name,
            error: false,
        };
    },
    computed: {
        ...mapGetters({
            intranetUid: INTRANET_MODULE_NAME + INTRANET_UID,
            subscribedTopicUids: TOPIC_SUBSCRIPTION_MODULE_NAME + GET_TOPIC_UIDS,
        }),
        /** @return {Location} */
        route() {
            return { name: "topic-route", params: { topicUid: this.topicUid } };
        },
        subscribed() {
            if (this.subscribedState !== undefined) {
                return this.subscribedState;
            }
            return this.subscribedTopicUids.includes(this.topicUid);
        },
    },
    watch: {
        topicUid: {
            handler() {
                this.fetchDisplayName();
            },
            immediate: true,
        },
    },
    methods: {
        ...mapActions({
            requestSubscribeTopic: TOPIC_SUBSCRIPTION_MODULE_NAME + SUBSCRIBE_TOPIC,
            requestUnsubscribeTopic: TOPIC_SUBSCRIPTION_MODULE_NAME + UNSUBSCRIBE_TOPIC,
        }),
        async fetchDisplayName() {
            this.displayName = this.name;
            if (!this.displayName) {
                try {
                    const topic = await topicService.getTopic({ intranetUid: this.intranetUid, topicUid: this.topicUid });
                    this.displayName = topic.name;
                } catch (e) {
                    this.error = true;
                    console.warn(`unable to fetch topic with uid ${this.topicUid}`, e);
                }
            }
        },
        async subscribe(topicUid) {
            try {
                this.subscribedState = true;
                await this.requestSubscribeTopic(topicUid);
                this.$emit("subscribe");
                this.$notify({
                    group: "app",
                    type: "success",
                    title: this.$t("success"),
                    text: this.$t("topic_subscription_subscribe_success_message", [this.displayName]),
                });
            } catch (e) {
                this.subscribedState = false;
                this.$notify({
                    group: "app",
                    type: "error",
                    text: this.$t("topic_subscription_subscribe_error", [this.displayName]),
                });
            }
        },
        async unsubscribe(topicUid) {
            try {
                this.subscribedState = false;
                await this.requestUnsubscribeTopic(topicUid);
                this.$notify({
                    group: "app",
                    type: "success",
                    title: this.$t("success"),
                    text: this.$t("topic_subscription_unsubscribe_success_message", [this.displayName]),
                });
                this.$emit("unsubscribe");
            } catch (e) {
                this.subscribedState = true;
                this.$notify({
                    group: "app",
                    type: "error",
                    text: this.$t("topic_subscription_unsubscribe_error", [this.displayName]),
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>

</style>
