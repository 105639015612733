<template>
    <aside>
        <transition
            name="moveInFromLeft"
            appear
        >
            <div
                id="main-sidebar"
                v-click-blur="handleClickOutside"
                class="depth-2"
                :class="{compact: isCompact}"
            >
                <CreateContentFAB :is-compact="isCompact" />
                <div
                    class="views"
                    @click="handleClickOutside"
                >
                    <router-link
                        v-for="(link, i) in links"
                        :key="'link' + link.label"
                        v-tooltip.right="isCompact && link.label"
                        v-fade-in.after="(i + 1) * 100 + 600"
                        :to="link.path"
                        class="sidebar-link"
                        :class="link.class"
                        :aria-label="link.label"
                    >
                        <Icon
                            :name="link.icon"
                            :active="false"
                            :badge="link.badge"
                        />
                        <div class="nav-label">
                            {{ link.label }}
                        </div>
                    </router-link>
                    <!-- <div class="sidebar-divider"/> -->
                </div>

                <div class="integrations" ></div>

                <div
                    v-fade-in.after="1000"
                    class="bottom-zone"
                >
                    <button
                        class="sidebar-link collapse-icon"
                        @click="toggleCompact"
                    >
                        <div class="rotator">
                            <Icon name="chevron-left" />
                        </div>
                        <div class="nav-label">
                            {{ $t("minimize_sidebar") }}
                        </div>
                    </button>
                </div>
                <ContentUpdateObserver />
            </div>
        </transition>
        <div
            class="sidebar-layout-block"
            :class="{compact: isCompact}"
        ></div>
    </aside>
</template>

<script>
import { AUTH_MODULE_NAME } from "@web/store/auth/auth";
import Vue from "vue";
import {
    mapActions,
    mapGetters
} from "vuex";
import Icon from "@web/components/Icon.vue";
import ContentUpdateObserver from "@web/components/renderless/ContentUpdateObserver.vue";
import { TIMESTAMP_MODULE_NAME } from "@web/store/timestamping/timestamping";
import {
    UNREAD_NEWS,
    UNREAD_NEWS_COUNT,
} from "@web/store/timestamping/getters";

import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import {
    FEATURE_PREVIEW_ACTIVE,
    INTRANET
} from "@web/store/intranet/getters";
import CreateContentFAB from "@web/views/CreateContentFAB";
import {
    LIVE_NEW_CONTENT_MODULE_NAME,
    UnseenContentType,
} from "@web/store/live-new-content/live-new-content";
import { GET_COUNT } from "@web/store/live-new-content/getters";

const LOCALSTORAGE_SIDEBAR_PREFERENCE = "prefers-compact-sidebar";

export default Vue.extend({
    name: "Sidebar",
    components: {
        CreateContentFAB,
        Icon,
        ContentUpdateObserver,
    },
    data() {
        return {
            isCompact: false,
            ignoreResizeObserverOnce: false,
        };
    },
    computed: {
        ...mapGetters(TIMESTAMP_MODULE_NAME, [UNREAD_NEWS]),
        ...mapGetters(INTRANET_MODULE_NAME, [INTRANET]),
        ...mapGetters({
            isFeaturePreviewActive: INTRANET_MODULE_NAME + FEATURE_PREVIEW_ACTIVE,
            unreadNewsCount: TIMESTAMP_MODULE_NAME + UNREAD_NEWS_COUNT,
            getUnseenContentCount: LIVE_NEW_CONTENT_MODULE_NAME + GET_COUNT,
        }),
        links: function() {
            return [
                { label: this.$t("dashboard"), icon: "home", path: `/intranet/${this.intranet.uid}/dashboard` },
                { label: this.$t("news"), badge: this.unreadNewsCount, icon: "newspaper", path: `/intranet/${this.intranet.uid}/news` },
                { label: this.$t("directory"), icon: "users", path: `/intranet/${this.intranet.uid}/directory` },
                { label: this.$t("posts"), badge: this.getUnseenContentCount({ type: UnseenContentType.post }), icon: "message-square", path: `/intranet/${this.intranet.uid}/posts` },
                { label: this.$t("events"), icon: "event", path: `/intranet/${this.intranet.uid}/event` }
            ].filter(l => this.isFeaturePreviewActive || l.class !== "disabled");
        },
    },
    methods: {
        ...mapActions({ logOut: AUTH_MODULE_NAME }),
        setSidebarPreference(preference) {
            localStorage.setItem(LOCALSTORAGE_SIDEBAR_PREFERENCE, preference);
        },
        toggleCompact() {
            this.ignoreResizeObserverOnce = true;
            this.isCompact = !this.isCompact;
            this.setSidebarPreference(this.isCompact);
            this.$nextTick(() => {
                // Trigger resize to recalculate the news swiper in the DashboardNews component.
                window.dispatchEvent(new Event("resize"));
            });
        },
        handleClickOutside() {
            // if small window size collapse sidebar when clicked outside
            if (window.innerWidth < 1180 && !this.isCompact) {
                this.isCompact = true;
            }
        }
    },
    mounted() {
        if (!window._LinchpinSidebarResizeObserver) {
            window._LinchpinSidebarResizeObserver = new ResizeObserver(entries => {
                if (this.ignoreResizeObserverOnce) {
                    this.ignoreResizeObserverOnce = false;
                    return;
                }
                for (const entry of entries) {
                    let width = entry.contentBoxSize && entry.contentBoxSize.inlineSize ? entry.contentBoxSize.inlineSize : entry.contentRect.width; // replace with elvis once available
                    width = Math.round(width);
                    if (width < 1180) {
                        this.isCompact = true;
                    } else {
                        const sidebarPreference = localStorage.getItem(LOCALSTORAGE_SIDEBAR_PREFERENCE);
                        this.isCompact = sidebarPreference === "true";
                    }
                }
            });
        }
        window._LinchpinSidebarResizeObserver.observe(document.getElementById("intranet-wrapper"));
    },
    beforeDestroy() {
        window._LinchpinSidebarResizeObserver.unobserve(document.getElementById("intranet-wrapper"));
    },
});
</script>

<style lang="scss" scoped>
    @import "src/style/variables";

    .sidebar-layout-block {
        // width animations were too heavy... so layout shifts are via a fake element
        flex: 0 0 auto;
        width: var(--sidebar-width);
        max-width: var(--sidebar-width);
        &.compact {
            max-width: 2.75rem;
        }
    }

    #main-sidebar {
        position: fixed;
        height: 100vh;
        width: var(--sidebar-width);
        max-width: var(--sidebar-width);
        overflow: visible;
        top: 0;
        left: 0;
        z-index: 900;
        padding: 0.3rem 0 0 0;
        background: var(--sidebar-bg);
        display: flex;
        flex-direction: column;
        overflow: hidden;
        transition: max-width .4s ease-out;

        &.compact {
            max-width: 3.4rem;
            .nav-label {
                opacity: 0 !important;
            }
            .collapse-icon {
                .rotator {
                    transform: rotate(180deg);
                }
            }
        }

        .collapse-icon {
            .rotator {
                transition: transform .3s ease;
            }
        }

        .sidebar-link {
            display: flex;
            align-items: center;
            padding: 0.85rem 1rem;
            width: 100%;
            margin-bottom: 0;
            outline: none;
            background: none;
            border: none;
            .nav-label {
                flex: 1 1 auto;
                transition: color .2s ease-in-out, opacity .2s ease-in-out;
                color: var(--sidebar-text);
                margin: 0.1rem 0 0 0.75rem;
                font-weight: 500;
                line-height: 1;
                white-space: nowrap;
                overflow: visible;
                text-overflow: ellipsis;
                text-align: left;
            }
            .icon {
                flex: 0 0 auto;
                transition: all .1s ease-in-out;
                --icon-color: var(--sidebar-text);
            }

            &.disabled {
                * {
                    opacity: 0.2;
                }
            }

            &.router-link-exact-active, &.router-link-active {
                .nav-label {
                    color: var(--sidebar-active);
                }
                .icon {
                    --icon-color: var(--sidebar-active);
                }

            }

            &:hover {
                .nav-label {
                    color: var(--sidebar-active);
                }

                .icon {
                    --icon-color: var(--sidebar-active);
                    transform: scale(1.06);
                }
            }

            &:active {
                .icon {
                    transform: scale(0.95);
                }
            }

        }

        .sidebar-divider {
            margin: 10px 0;
            height: 1px;
            background: var(--sidebar-text);
            opacity: 0.3;
        }

        .views {
            flex: 0 0 auto;
        }

        .integrations {
            flex: 1 0 auto;
        }

        .bottom-zone {
            flex: 0 0 auto;
            align-self: flex-start;
            justify-self: flex-end;
        }
    }

</style>
