<template>
    <v-popover
        :open="open"
        :popover-class="popoverClasses"
        :auto-hide="false"
        trigger="manual"
        placement="left-start"
        offset="0"
        @mouseover.native="scheduleShow()"
        @mouseout.native="scheduleHide()"
    >
        <slot></slot>
        <template slot="popover">
            <user-v-card
                v-if="open"
                :user-uid="userUid"
                @mouseover.native="scheduleShow()"
                @mouseout.native="scheduleHide()"
            />
        </template>
    </v-popover>
</template>

<script>
import UserVCard from "@web/components/v-card/UserVCard";
import debounce from "lodash/debounce";
import { mapGetters } from "vuex";
import { AUTH_MODULE_NAME } from "@web/store/auth/auth";
import { CURRENT_USER } from "@web/store/auth/getters";

export default {
    name: "UserVCardOnHover",
    components: { UserVCard },
    props: {
        userUid: {
            type: String,
            required: true,
        },
    },
    data() {
        return {
            isInModal: false,
            open: false,
            showDebouncer: debounce(() => this.open = true, 400),
            hideDebouncer: debounce(() => this.open = false, 500),
        };
    },
    computed: {
        ...mapGetters({
            currentUser: AUTH_MODULE_NAME + CURRENT_USER,
        }),
        popoverClasses() {
            let classes = "no-padding no-text-style";
            if (!this.isInModal) {
                classes += " behind-modal";
            }
            return classes;
        }
    },
    mounted() {
        this.isInModal = this.$el.closest(".v--modal-overlay") !== null;
    },
    methods: {
        scheduleShow() {
            if (this.currentUser.uid === this.userUid || this.open) {
                this.hideDebouncer.cancel();
                return;
            }
            this.showDebouncer();
        },
        scheduleHide() {
            if (this.currentUser.uid === this.userUid || !this.open) {
                this.showDebouncer.cancel();
                return;
            }
            this.hideDebouncer();
        },
    }
};
</script>

<style scoped>
</style>
