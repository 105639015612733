<template>
    <div>
        <transition name="fade">
            <div
                v-if="attachments.length > 0 || sharedContent.length > 0"
                :class="{'edit-mode': editMode}"
                class="attachment-area"
            >
                <file-attachments
                    v-if="files"
                    :attachments="files"
                    :edit-mode="editMode"
                    :class="{'mbottom-small': images.length > 0}"
                    @delete="removeAttachment"
                />
                <single-media-attachment
                    v-if="images.length === 1"
                    :attachment="images[0]"
                    :edit-mode="editMode"
                    :multi="1"
                    @delete="removeAttachment(images[0])"
                    @click.native="openLightbox(0)"
                />
                <two-media-attachments
                    v-else-if="images.length === 2"
                    :edit-mode="editMode"
                    v-bind="attachmentComponentProps"
                    @delete="removeAttachment"
                    @open-lightbox="openLightbox"
                />
                <three-media-attachments
                    v-else-if="images.length >= 3"
                    :edit-mode="editMode"
                    v-bind="attachmentComponentProps"
                    @delete="removeAttachment"
                    @open-lightbox="openLightbox"
                />
                <more-media-attachments
                    v-if="images.length > 3 && editMode"
                    :attachments="images.slice(3, images.length)"
                    :edit-mode="editMode"
                    @delete="removeAttachment"
                    @open-lightbox="openLightbox"
                />
                <shared-attachment-component
                    v-if="sharedContent.length > 0"
                    :class="{'mtop-xsmall': attachments.length > 0}"
                    :shared-content="sharedContent[0]"
                    :edit-mode="editMode"
                    @delete="attachmentManager.removeSharedContent(sharedContent[0])"
                />
            </div>
        </transition>
        <transition name="fade">
            <lightbox-swiper
                :image-names="imageNames"
                :image-urls="imageUrls"
                :initial-index="previewIndex"
                :show="showLightbox"
                @close-swiper-lightbox="showLightbox = false"
            />
        </transition>
    </div>
</template>

<script>
import SingleMediaAttachment from "@web/components/attachments/SingleMediaAttachment";
import TwoMediaAttachments from "@web/components/attachments/TwoMediaAttachments";
import ThreeMediaAttachments from "@web/components/attachments/ThreeMediaAttachments";
import MoreMediaAttachments from "@web/components/attachments/MoreMediaAttachments";
import {
    AttachmentCountExceeded,
    AttachmentManager,
    FileSizeExceeded,
    LoadingAttachmentReference
} from "@web/components/attachments/attachment-manager";
import { inIframe } from "@web/lib/is-in-iframe";
import { ScaledImageSize } from "@backend/common/image-resizing/types";
import LightboxSwiper from "@web/components/swiper/LightboxSwiper";
import SharedAttachmentComponent from "@web/components/attachments/shared-content/SharedAttachmentComponent";
import { AttachmentType } from "@backend/attachment/types";
import FileAttachments from "@web/components/attachments/FileAttachments";

export default {
    name: "AttachmentArea",
    components: {
        FileAttachments,
        SharedAttachmentComponent,
        LightboxSwiper,
        MoreMediaAttachments,
        ThreeMediaAttachments,
        TwoMediaAttachments,
        SingleMediaAttachment,
    },
    props: {
        editMode: { type: Boolean },
        showUploadHint: { type: Boolean },
        attachmentManager: { type: AttachmentManager, required: true },
    },
    data() {
        return {
            previewIndex: 0,
            showLightbox: false,
            MAX_FILE_COUNT: AttachmentManager.MAX_FILE_COUNT,
        };
    },
    computed: {
        sharedContent() {
            return this.attachmentManager.sharedContent;
        },
        attachments() {
            return this.attachmentManager.attachments;
        },
        images() {
            return this.attachments.filter(attachment => attachment.type === AttachmentType.image);
        },
        files() {
            return this.attachments.filter(attachment => attachment.type === AttachmentType.file);
        },
        attachmentComponentProps() {
            if (this.images.length === 2) {
                return {
                    firstAttachment: this.images[0],
                    secondAttachment: this.images[1],
                };
            } else if (this.images.length >= 3) {
                return {
                    attachments: [...this.images],
                };
            }
            return undefined;
        },
        imageUrls() {
            return this.images
                .map((attachment) =>
                    (attachment instanceof LoadingAttachmentReference) ? undefined : attachment.getUrl(ScaledImageSize.l)
                );
        },
        imageNames() {
            return this.images.map((attachment) => attachment.name);
        },
    },
    methods: {
        openLightbox(index) {
            if (this.editMode || inIframe() || !this.imageUrls[index]) {
                return;
            }
            this.previewIndex = index;
            this.showLightbox = true;
        },
        removeAttachment(attachment) {
            this.attachmentManager.remove(attachment);
        },
        async handleUpload({ files, reset }) {
            try {
                await this.attachmentManager.addFiles(files);
            } catch (e) {
                console.error(e);
                if (e instanceof AttachmentCountExceeded) {
                    this.$notify({
                        group: "app",
                        type: "error",
                        title: this.$t("attachment_upload_limit_reached_title"),
                        text: this.$t("attachment_upload_limit_reached_text", [AttachmentManager.MAX_FILE_COUNT]),
                    });
                } else if (e instanceof FileSizeExceeded) {
                    this.$notify({
                        group: "app",
                        type: "error",
                        title: this.$t("attachment_file_size_exceeded_title"),
                        text: this.$t("attachment_file_size_exceeded_text", [AttachmentManager.MAX_FILE_SIZE_MB]),
                    });
                } else {
                    this.$notify({
                        group: "app",
                        type: "error",
                        title: this.$t("attachment_upload_error_title"),
                        text: this.$t("attachment_upload_error_text"),
                    });
                }
            }
            reset();
        },
    },
};
</script>

<style lang="scss" scoped>
.attachment-area {
    cursor: default;
    transition: padding-top ease-in 100ms;

    &:not(:empty) {
        padding-top: 0.25rem;
    }

    &.edit-mode {
        padding-top: 1rem;
        padding-bottom: .7rem;
    }
}
</style>
