import axios,
{ AxiosRequestConfig } from "axios";
import { ApiClient } from "@web/api/ApiClient";
import { ApiClientBuilder } from "@web/api/ApiClientBuilder";
import * as firebase from "firebase";
import { hostUrl } from "@web/firebaseConfig";
import { analytics } from "@web/analytics";
import { getGlobalConfiguration } from "@web/global-config";

export class ConfluenceConfigurationService {
    private readonly api: Promise<ApiClient>;
    private static readonly SHOW_IN_SYSTEM_HEADER_URL = `${hostUrl}/atlassian-connect/confluence-cloud/feature/show-in-system-header`;
    private static readonly CONNECT_INTRANET_URL = `${hostUrl}/atlassian-connect/confluence-cloud/feature/connect-intranet`;
    // for developing purpose:
    // private static readonly SHOW_IN_SYSTEM_HEADER_URL = `https://743f8a5f.ngrok.io/linchpin-everest-jwolf/europe-west1/confluenceCloud/confluenceCloud/feature/show-in-system-header`;
    // private static readonly CONNECT_INTRANET_URL = `https://743f8a5f.ngrok.io/linchpin-everest-jwolf/europe-west1/confluenceCloud/confluenceCloud/feature/connect-intranet`;

    constructor() {
        this.api = new ApiClientBuilder().build();
    }

    public async getShowInSystemHeader() {
        const { config } = this.buildAxiosConfigForAuthentication();
        const response = await axios.get(
            ConfluenceConfigurationService.SHOW_IN_SYSTEM_HEADER_URL,
            config
        );
        return response.data;
    }

    public async setShowInSystemHeader(show: boolean) {
        const { config } = this.buildAxiosConfigForAuthentication();
        return axios.post(
            `${ConfluenceConfigurationService.SHOW_IN_SYSTEM_HEADER_URL}?show=${show}`,
            {},
            config
        ).then(() => {
            analytics.log(getGlobalConfiguration().analytics_event_name_atl_confluence_cloud_system_header_web_items_enabled);
        });
    }

    public async setIntranet(intranetUid: string) {
        // for this request we need "two authorization headers" to authenticate against google AND atlassian
        // as we can not set 2 headers (JWT / BEARER), we provide firebase bearer via header and atlassian jwt in request body
        const { jwt } = this.buildAxiosConfigForAuthentication();
        const config = {
            headers: {
                Authorization: "Bearer " + await this.getFirebaseBearer(),
            },
        };
        return axios.post(
            `${ConfluenceConfigurationService.CONNECT_INTRANET_URL}`,
            { intranetUid, jwt },
            config
        ).then(() => {
            analytics.log(getGlobalConfiguration().analytics_event_name_atl_confluence_cloud_intranet_connected);
        });
    }

    public async removeIntranet() {
        // for this request we need "two authorization headers" to authenticate against google AND atlassian
        // as we can not set 2 headers (JWT / BEARER), we provide firebase bearer via header and atlassian jwt in query
        const { jwt } = this.buildAxiosConfigForAuthentication();
        const config = {
            headers: {
                Authorization: "Bearer " + await this.getFirebaseBearer(),
            },
        };
        return axios.delete(
            `${ConfluenceConfigurationService.CONNECT_INTRANET_URL}?jwt=${jwt}`,
            config
        );
    }

    public async getIntranet() {
        const { config } = this.buildAxiosConfigForAuthentication();
        const response = await axios.get(
            ConfluenceConfigurationService.CONNECT_INTRANET_URL,
            config
        );
        return response.data;
    }

    private buildAxiosConfigForAuthentication(configBuilder: (jwt: string) => AxiosRequestConfig = jwt => ({ headers: { Authorization: "JWT " + jwt } })) {
        const atlassianJwt = (new URLSearchParams(window.location.search)).get("jwt") || "";
        if (atlassianJwt === "") {
            console.warn("Could not get atlassian jwt from URL");
        }
        return { config: configBuilder(atlassianJwt), jwt: atlassianJwt };
    }

    private async getFirebaseBearer(): Promise<string | null> {
        const currentUser = firebase.auth().currentUser;
        if (currentUser === null) {
            return null;
        }
        return await currentUser.getIdToken();
    }
}
