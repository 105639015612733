









































import Vue from "vue";
import { analytics } from "@web/analytics";
import { getGlobalConfiguration } from "@web/global-config";
import { mapGetters } from "vuex";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import { INTRANET } from "@web/store/intranet/getters";

export default Vue.extend<any, any, any, any>({
    name: "Destination",
    props: {
        icon: { type: String, default: "" },
        title: { type: String, default: "" },
        url: { type: String, default: "" },
        uid: { type: String, default: "" },
    },
    data() {
        return {
            imgNotFound: false,
        };
    },
    computed: {
        ...mapGetters(INTRANET_MODULE_NAME, [INTRANET]),
    },
    methods: {
        destinationOpened() {
            analytics.log(getGlobalConfiguration().analytics_event_name_launchpad_destination_opened, {
                intranetUid: this.intranet.uid,
            });
        },
    },
});
