import {SupportedAtlassianCloudProducts} from "@backend/atlassian/types";

export type KnownIntranetFeatureFlagConfiguration = Atlassian3LOConfiguration | GoogleCalendarConfiguration | IntegrationHintConfiguration | HideFeaturePreviews | DomainBasedIntranetAccessConfiguration | CompanyHappinessConfiguration;

export interface Atlassian3LOConfiguration {
    cloudId: string;
    instanceUrl: string;
    products: SupportedAtlassianCloudProducts[];
}

export interface GoogleCalendarConfiguration {
}

export interface IntegrationHintConfiguration {}

export interface CompanyHappinessConfiguration {}

export interface HideFeaturePreviews {}

export interface DomainBasedIntranetAccessConfiguration {
    domains: string[];
}

export interface IntranetFeatureFlagConfigurationInFireStore<C extends KnownIntranetFeatureFlagConfiguration> {
    enabled: boolean;
    config: C | null;
}

export interface IntranetFeatureFlagHandler<C extends KnownIntranetFeatureFlagConfiguration> {
    configure: IntranetFeatureFlagConfigurationFunction<C>;
    getConfig: IntranetFeatureFlagConfigurationRetrievalFunction<C>;
    defaultConfig: IntranetFeatureFlagConfigurationInFireStore<C>;
}

export type IntranetFeatureFlagConfigurationFunction<C extends KnownIntranetFeatureFlagConfiguration> = (db: any /*Firestore*/, intranetUid: string) => (enable: boolean, config?: C) => Promise<void>;

export type IntranetFeatureFlagConfigurationRetrievalFunction<C extends KnownIntranetFeatureFlagConfiguration> = (db: any /*Firestore*/, intranetUid: string) => Promise<IntranetFeatureFlagConfigurationInFireStore<C> | null>;

export enum IntranetFeatureFlag {
    atlassian3L0Connected = "atlassian-3lo",
    googleCalendarConnected = "google-calendar",
    integrationHint = "integration-hint",
    hideFeaturePreviews = "hide-feature-previews",
    domainBasedIntranetAccess = "domain-based-intranet-access",
    companyHappiness = "company-happiness",
}
