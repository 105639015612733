<template>
    <transition
        name="slideInTop"
        appear
    >
        <div
            v-if="show"
            class="card depth-2 padding-medium centered"
        >
            <router-link
                :to="{ name: 'user-profile', params: { userUid: notification.activity.creatorUid }}"
                @click.native="$emit('update:show', false)"
            >
                <img
                    :src="notification.creator.photoURL"
                    class="avatar zoom-small zoom-on-hover-trigger mbottom-small"
                />
            </router-link>
            <p><strong>{{ title }}</strong></p>
            <h4
                v-if="notification.activity.meta.message"
                class="mbottom-xsmall centered"
            >
                "{{ notification.activity.meta.message }}"
            </h4>
            <span class="small-text">{{ description }}</span>
        </div>
    </transition>
</template>

<script>
import { SocialNotificationType } from "@backend/common/notification/activity-types";

const socialNotificationI18nKeyMap = {
    [SocialNotificationType.heyFive]: {
        title: "social_notification_interaction_title_hey_five",
        description: "social_notification_interaction_description_hey_five",
    },
};

export default {
    name: "SocialNotificationInteractionCard",
    props: {
        show: Boolean,
        /**
         * @type {SocialNotification}
         */
        notification: {
            type: Object,
            required: true,
        },
    },
    computed: {
        title() {
            return this.$t(socialNotificationI18nKeyMap[this.notification.activity.meta.socialNotificationType].title, [this.notification.creator.displayName]);
        },
        description() {
            return this.$t(socialNotificationI18nKeyMap[this.notification.activity.meta.socialNotificationType].description);
        },
    }
};
</script>

<style lang="scss" scoped>
.card {
    max-width: 35rem;
    margin-top: 5rem;
    padding-top: 0rem;

    .avatar {
        width: 10rem;
        height: 10rem;
        margin-top: -5rem;
        border-width: 4px;
    }
}
</style>
