/**
 * Checks if the current view is opened in a window.
 *
 * Catches an exception an returns true if the same-origin policy is violated.
 */
export const inIframe = () => {
    try {
        return window.self !== window.top;
    } catch (e) {
        return true;
    }
};
