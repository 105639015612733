<template>
    <featured-content
        featuring-side-class="padding-none"
        :direction="direction"
    >
        <template #featuring>
            <pu-skeleton class="featured-skeleton"/>
        </template>
        <template #title>
            <pu-skeleton/>
        </template>
        <template #content>
            <pu-skeleton
                :count="2"
                width="90%"
            />
        </template>
    </featured-content>
</template>

<script>
import FeaturedContent from "@/components/attachments/shared-content/FeaturedContent";

/**
 * Skeleton for a `FeaturedContent`.
 *
 * @see [FeaturedContent](#/Components/FeaturedContent)
 */
export default {
    name: "FeaturedContentSkeleton",
    components: {
        FeaturedContent,
    },
    props: {
        direction: FeaturedContent.props.direction,
    },
};
</script>

<style lang="scss" scoped>
.featured-skeleton {
    ::v-deep {
        .pu-skeleton {
            height: 120px;
            border-radius: 0;
            vertical-align: bottom;
        }
    }
}
</style>
