<template>
    <div class="pings-list">
        <div class="header-row">
            <h3 class="title">
                {{ $t("ping_list_title") }}
                <router-link
                    :to="{ name: 'notifications' }"
                >
                    <icon
                        class="icon"
                        name="sliders"
                        @click.native="$emit('hide')"
                    />
                </router-link>
            </h3>
            <Button
                icon="doublecheck"
                type="link"
                :disabled="!hasUnreadPings"
                class="no-pad"
                :class="{
                    'primary': hasUnreadPings,
                    'hidden': !hasUnreadPings,
                }"
                @click="markAllAsRead"
            >
                {{ $t('mark_all_as_read') }}
            </Button>
        </div>
        <div v-if="!loading">
            <div
                v-if="pings.length"
                class="pings"
            >
                <transition-group name="fade">
                    <div
                        v-for="ping in pingsWithDividers"
                        :key="ping.id"
                    >
                        <div
                            v-if="'dateDivider' in ping"
                            :class="'date-divider small-text ' + ping.dateDivider.toLowerCase()"
                        >
                            {{ ping.dateDivider }}
                        </div>
                        <inbox-ping
                            v-else
                            class="inbox-ping-wrapper"
                            :ping="ping"
                            :menu-boundary="$el"
                        />
                    </div>
                </transition-group>
            </div>
            <div
                v-else
                class="empty"
            >
                <div
                    class="empty-image"
                    v-html="thumbsUpAstro"
                ></div>
                <h4>
                    {{ $t("all_caught_up") }}.
                </h4>
                <i18n
                    path="notification_settings_hint"
                    tag="span"
                    class="hint"
                >
                    <router-link
                        class="link"
                        :to="{ name: 'notifications' }"
                        @click.native="$emit('hide')"
                    >
                        {{ $t("user_settings") }}
                    </router-link>
                </i18n>
            </div>
        </div>
        <spinner
            v-else
            class="spinner"
        />
    </div>
</template>

<script>
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import {
    INTRANET,
} from "@web/store/intranet/getters";
import { PROFILE_MODULE_NAME } from "@web/store/profile/profile";
import { FETCH_PROFILES_FOR_USER_UIDS } from "@web/store/profile/actions";
import {
    mapState,
    mapActions
} from "vuex";
import { inboxService } from "@web/services";
import InboxPing from "@web/components/pings/InboxPing";
import Spinner from "@web/components/Spinner";
import Button from "@web/components/Button";
import {
    dayjsFromDate,
    getPastDaysSinceThen,
} from "@web/lib/time-utils";
import thumbsUpAstro from "@web/assets/system/astrobot.svg";

export default {
    name: "PingsList",
    components: { Button, InboxPing, Spinner },
    data() {
        return {
            unsubscribeInboxQuery: null,
            pings: [],
            pingsWithDividers: [],
            loading: true,
            thumbsUpAstro
        };
    },
    computed: {
        ...mapState(INTRANET_MODULE_NAME, [INTRANET]),
        hasUnreadPings() {
            return (this.pings.length && this.pings.some(ping => ping.unread));
        }
    },
    methods: {
        updatePings() {
            inboxService.updateUserInboxLastOpenedDate(this.intranet.uid, this.$store.state.auth.currentUser.uid);
        },
        markAllAsRead() {
            try {
                this.pings.forEach(ping =>
                    inboxService.updatePingByDocumentId(
                        this.intranet.uid,
                        this.$store.state.auth.currentUser.uid,
                        ping.id,
                        { unread: false }
                    )
                );
            } catch {
                this.$notify({
                    group: "app",
                    type: "error",
                    text: `${this.$t("ping_failed_to_read_all")}`,
                });
            }
        },
        ...mapActions({
            fetchProfilesForUids: PROFILE_MODULE_NAME + FETCH_PROFILES_FOR_USER_UIDS,
        }),
        groupPingsByDate() {
            const pingsDivided = [...this.pings];
            const indexOlderThanToday = this.pings.findIndex(ping => {
                const date = "updated" in ping ? ping.updated : ping.created;
                return getPastDaysSinceThen(dayjsFromDate(date.toDate())) > 0;
            });
            if (indexOlderThanToday > -1) {
                pingsDivided.splice(indexOlderThanToday, 0, { dateDivider: this.$t("previous"), id: "previous" });
            }
            if (indexOlderThanToday > 0 && this.pings.length) pingsDivided.splice(0, 0, { dateDivider: this.$t("today"), id: "today" });
            return pingsDivided;
        },
    },
    mounted() {
        this.unsubscribeInboxQuery = inboxService.getUsersInboxQuery(this.intranet.uid, this.$store.state.auth.currentUser.uid).onSnapshot(async snapshot => {
            const newPings = snapshot.docs.map(d => ({ id: d.id, ...d.data() }));
            const userUids = newPings.flatMap(ping => ping.originatorUserUid || ping.originatorUserUids);
            await this.fetchProfilesForUids(userUids);
            this.pings = newPings;
            this.$emit("newPings", newPings);
            this.pingsWithDividers = this.groupPingsByDate();
            this.loading = false;
        });
    },
    destroyed() {
        this.unsubscribeInboxQuery();
    }
};
</script>

<style lang="scss" scoped>
@keyframes pingsListIn{
    0%{
        transform: scale(0);
    }
    100%{
        transform: scale(1);
    }
}

.spinner {
    padding-bottom: 1.5rem;
}

.pings-list {
    transform-origin: top center;
    width: 30.25rem;
    font-size: 1rem;
    font-weight: normal;
    white-space: pre-wrap;
}

.header-row {
    padding: 0 1.5rem 1.5rem;
    h3 {
        margin: 0;
    }
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
        display: flex;
        align-items: center;
        .icon {
            margin-left: 0.5rem;
        }
    }
    border-bottom: 1px solid var(--lowest-contrast, #{$off-white});
}
.pings {
    max-height: 80vh;
    overflow-y: auto;
    padding: 1.5rem 0;
}

.ping-settings {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin: -1rem -1.5rem;

    .button{
        margin-left: 0;
    }
}

.no-pad {
    padding: 0;
}

.date-divider {
    text-transform: uppercase;
    font-weight: bold;
    margin-left: 1.5rem;
    opacity: 0.5;

    &:nth-child(2) {
        margin-top: 1rem;
    }
}

.empty {
    padding: 1.5rem;
    color: var(--primary-text);
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .hint {
        color: var(--foreground);
        .link {
            color: var(--primary);
        }
    }
}

.empty-image{
    width: 30%;
    padding: .5rem 0;
}

::v-deep {
    .ping-menu-popover {
        line-height: 0;
    }
}
</style>
