<template>
    <div v-click-blur="onBlur">
        <editor
            ref="editor"
            v-model="newContent"
            :attachment-manager="attachmentManager"
            :class="{
                'collapsable': collapsable,
                'collapsed': collapsable && !isThisEditorActive,
                'closing': editorClosing === true,
            }"
            :edit-mode="editMode"
            :hide-editor="!editMode && isEditorEmpty"
            :is-empty.sync="isEditorEmpty"
            :place-holder="placeHolder"
            :truncate="truncate"
            @save="saveContent"
            @file-select="uploadDialogOpened = true"
            @click.native="expandCommentBox"
        />
        <loading-topic-list
            v-if="!editMode || !isThisEditorActive"
            :class="{'mtop-xsmall': hasAttachments}"
            :topic-uids="newTopicUids"
        />
        <keep-alive>
            <div
                v-if="editMode && isThisEditorActive"
                class="editor-controls mtop-xxsmall flex flex-center flex-space-between"
            >
                <!-- keep for flex layout -->
                <div>
                    <loading-topic-multi-select
                        v-if="!noTopics"
                        v-model="newTopicUids"
                    />
                </div>
                <div class="mleft-xsmall">
                    <app-button
                        :busy="busy"
                        :disabled="!hasContent"
                        type="primary"
                        @click="saveContent"
                    >
                        {{ $t("save") }}
                    </app-button>
                    <app-button
                        type="link"
                        class="no-padding"
                        @click.stop="cancel"
                    >
                        {{ $t("cancel") }}
                    </app-button>
                </div>
            </div>
        </keep-alive>
    </div>
</template>

<script>
import {
    mapActions,
    mapGetters,
} from "vuex";
import { wait } from "@web/lib/wait";
import { emptyContent } from "@web/lib/quill/quill-utils";
import { EDITOR_MODULE_NAME } from "@web/store/editor/editor";
import {
    CLOSE_OPEN_EDITOR,
    REPLACE_OPEN_EDITOR,
} from "@web/store/editor/actions";
import { GET_OPEN_EDITOR } from "@web/store/editor/getters";
import { AttachmentManager } from "@web/components/attachments/attachment-manager";
import Editor from "@web/components/editor/Editor";
import LoadingTopicList from "@web/components/topics/LoadingTopicList";
import LoadingTopicMultiSelect from "@web/components/topics/LoadingTopicMultiSelect";

/**
 * Uses `Editor` to create an editor specific for Posts and Comments. The main difference being that it is collapsable
 * and opening the editor collapses any other currently opened editors.
 */
export default {
    name: "PostEditor",
    components: {
        LoadingTopicMultiSelect,
        LoadingTopicList,
        Editor,
    },
    props: {
        busy: { type: Boolean, default: false },
        collapsable: { type: Boolean, default: false },
        content: { type: Array, default: () => emptyContent() },
        editMode: { type: Boolean, default: false },
        initiallyOpen: { type: Boolean, default: false },
        placeHolder: { type: String, required: true },
        attachmentManager: { type: AttachmentManager, default: null },
        /** @type {string[]} */
        topicUids: { type: Array, default: () => [] },
        noTopics: Boolean,
        truncate: Boolean,
    },
    data() {
        return {
            /** @type {QuillDeltaOperations[]} */
            newContent: this.content,
            /** @type {string[]} */
            newTopicUids: this.topicUids,
            /** @type {boolean} */
            isEditorEmpty: true,
            editorClosing: { type: Boolean, default: false },
            uploadDialogOpened: false,
        };
    },
    computed: {
        ...mapGetters({
            openEditorUid: EDITOR_MODULE_NAME + GET_OPEN_EDITOR,
        }),
        isThisEditorActive() {
            return this.openEditorUid === this._uid;
        },
        hasAttachments() {
            if (!this.attachmentManager) return false;
            return !this.attachmentManager.isEmpty;
        },
        hasContent() {
            return !this.isEditorEmpty || this.hasAttachments;
        },
    },
    watch: {
        topicUids(topicUids) {
            this.newTopicUids = topicUids;
        },
        editMode(newEditMode) {
            if (!newEditMode) return;
            this.focus();
        },
        openEditorUid(val, oldVal) {
            if (!this.editMode) return;
            if (oldVal !== this._uid) return;
            if (val === this._uid) return;
            if (this.hasContent) return;
            this.$emit("cancel", { close: this.closeEditor });
        },
    },
    mounted() {
        this.openingAnimation();
    },
    methods: {
        ...mapActions({
            replaceEditor: EDITOR_MODULE_NAME + REPLACE_OPEN_EDITOR,
            closeEditor: EDITOR_MODULE_NAME + CLOSE_OPEN_EDITOR,
        }),
        saveContent() {
            if (!this.hasContent) return;
            this.$emit("save", {
                content: this.newContent,
                topicUids: this.newTopicUids,
                attachmentManager: this.attachmentManager,
                onSave: this.reset,
                close: this.collapseEditor,
            });
        },
        cancel() {
            this.$emit("cancel", { close: this.collapseEditor });
            this.reset();
        },
        reset() {
            this.collapseCommentBox();
            this.newTopicUids = this.topicUids;
            this.$refs.editor.reset(this.content);
        },
        async expandCommentBox() {
            this.replaceEditorIfNecessary();
        },
        collapseCommentBox() {
            if (!this.collapsable) {
                return;
            }
            if (this.uploadDialogOpened) {
                this.uploadDialogOpened = false;
                return;
            }
            if (this.isThisEditorActive) {
                this.closeEditor();
                if (!this.hasContent) {
                    this.cancel();
                }
            }
        },
        focus() {
            this.expandCommentBox();
            this.$refs.editor.focus();
            this.replaceEditorIfNecessary();
        },
        onBlur(event) {
            if (!this.editMode || !this.isThisEditorActive) return;
            // TODO: this is not very stable -> stable solution becomes viable after unifying comment components
            const ignoreBlurSelectors = [".comment-action.reply-action", ".post-create-editor", ".remove-icon"];
            const ignoredElementClicked = event.path.some((htmlNode) => {
                if (!htmlNode.matches) {
                    return false;
                }
                return ignoreBlurSelectors.some((ignoreBlurSelector) => htmlNode.matches(ignoreBlurSelector));
            });
            if (ignoredElementClicked) return;
            this.collapseCommentBox();
        },
        async collapseEditor() {
            this.$refs.editor.blur();
            this.collapseCommentBox();
            await wait(50);
            this.editorClosing = true;
        },
        async openingAnimation() {
            this.editorClosing = true;
            await wait(50);
            this.editorClosing = false;
            await wait(50);
            if (this.initiallyOpen) {
                this.focus();
            }
        },
        replaceEditorIfNecessary() {
            if (this.editMode) {
                this.replaceEditor(this._uid);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
$animation-timing: 0.4s;

.collapsable {
    ::v-deep .editor {
        transition: all $animation-timing ease-in-out;
        min-height: 2rem;
        display: flex;
        align-items: flex-start;
        justify-content: stretch;
        margin: 0;

        input {
            background: none;
        }
    }

    &.collapsed {
        ::v-deep .editor {
            max-height: 3.7rem;
            overflow: hidden;

            ::v-deep {
                .generic-quill-editor {
                    transform: translateY(0.2rem);
                }
            }

            .editor-controls {
                display: none;
                z-index: -1;
            }
        }
    }
}

.closing {
    ::v-deep {
        .editor {
            transition: all $animation-timing ease;
            min-height: 0;
            max-height: 0;
            margin-top: 0;
            margin-bottom: 0;
            padding: 0;

            .rich-text-editor {
                transition: max-height 0.4s ease;
                max-height: 0;
            }
        }
    }
}
</style>
