import Compressor from "compressorjs";

const defaultCompressorOptions = {
    maxWidth: 1600,
    maxHeight: 1600,
    quality: 0.8,
    mimeType: "image/jpeg",
    checkOrientation: true,
    // @ts-ignore
    beforeDraw: (context, canvas) => {
        // Remove in case transparent PNGs are needed
        context.fillStyle = "#fff";
        context.fillRect(0, 0, canvas.width, canvas.height);
    },
};

const compressableMimeTypes = [
    "image/jpeg",
    "image/jpg",
    "image/png",
];

export function compressImage(blob: Blob, compressorOptions: Compressor.Options = {}): Promise<Blob> {
    if (!compressableMimeTypes.includes(blob.type)) {
        return Promise.resolve(blob);
    }
    return new Promise((resolve, reject) => {
        // eslint-disable-next-line no-new
        new Compressor(blob, { ...defaultCompressorOptions, ...compressorOptions, success: resolve, error: reject });
    });
}
