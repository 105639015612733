import localforage from "localforage";
import memoryDriver from "localforage-memoryStorageDriver";

let _configuredLocalForage: LocalForage | undefined;

/**
 * Provides access to a fully configured localForage object.
 * It is necessary to register third party drivers asynchronously like the `localforage-memoryStorageDriver`.
 * It is preferred to not use the global instance but create separate instances as necessary to decide between the cache persistence backends.
 *
 * @example
 * import {
 *     configuredLocalForage,
 *     memoryDriver,
 * } from "@web/lib/localforage";
 *
 * const localForage = await configuredLocalForage();
 * const cache = localForage.createInstance({driver: memoryDriver._driver});
 * cache.setItem("key", "value");
 * cache.getItem("key");
 * cache.removeItem("key");
 */
export async function configuredLocalForage(): Promise<Omit<LocalForage, keyof LocalForageDbMethods>> {
    if (!_configuredLocalForage) {
        await localforage.defineDriver(memoryDriver);
        _configuredLocalForage = localforage;
    }
    return _configuredLocalForage;
}

export { memoryDriver };
