<script>
import vSelect from "vue-select";

export default {
    name: "PatchedVueSelect",
    extends: vSelect,
    mixins: [{
        watch: {
            open(newValue) {
                if (newValue === true) {
                    // Just Opened dropdown
                    for (let i = 0; i < this.filteredOptions.length; i++) {
                        if (this.isOptionSelected(this.filteredOptions[i])) {
                            // Highlight the first selected option
                            this.typeAheadPointer = i;
                            this.$nextTick(function() {
                                // Scroll to it if there is a lot of options.
                                this.adjustScrollOnOpen();
                            });
                            break;
                        }
                    }
                }
            },
        },
        methods: {
            adjustScrollOnOpen() {
                if (!this.$refs.dropdownMenu) {
                    return;
                }
                const optionEl = this.$refs.dropdownMenu.children[this.typeAheadPointer] || false;

                if (optionEl) {
                    const bounds = this.getDropdownViewport();
                    const { bottom, height } = optionEl.getBoundingClientRect();

                    if (bottom > bounds.bottom) {
                        return (this.$refs.dropdownMenu.scrollTop =
                                optionEl.offsetTop - (bounds.height / 2 - height));
                    }
                }
            },
        }
    }],
};
</script>
