<template>
    <featured-content
        v-if="news"
        :feature-image-url="coverPictureSrc"
        :to="route"
        direction="column"
        featuring-side-class="news-cover"
    >
        <template #featuring>
            <div
                v-if="!coverPictureSrc"
                class="center-container background-primary"
            >
                <loading-avatar
                    :user-uid="news.creatorUid"
                    size="xlarge"
                />
            </div>
        </template>
        <template #title>
            {{ news.title }}
        </template>
        <template
            v-if="news.subtitle"
            #content
        >
            {{ news.subtitle }}
        </template>
        <template #meta>
            <loading-content-author
                :user-uid="news.creatorUid"
                class="text-foreground"
            />
            <meta-divider/>
            {{ formatDate(news.publishDate) }}
            <meta-divider/>
            {{ readingTime }}
        </template>
    </featured-content>
    <featured-content-skeleton
        v-else-if="loading"
        direction="column"
    />
    <missing-featured-content
        v-else
        direction="column"
    />
</template>

<script>
import FeaturedContent from "@/components/attachments/shared-content/FeaturedContent";
import { mapGetters } from "vuex";
import { newsService } from "@/services";
import { INTRANET_UID } from "@/store/intranet/getters";
import { INTRANET_MODULE_NAME } from "@/store/intranet/intranet";
import { AUTH_MODULE_NAME } from "@/store/auth/auth";
import { CURRENT_USER } from "@/store/auth/getters";
import { AUTHOR_IS_COVER_PICTURE } from "@/components/news/NewsTeaser";
import FeaturedContentSkeleton from "@/components/attachments/shared-content/FeaturedContentSkeleton";
import MissingFeaturedContent from "@/components/attachments/shared-content/MissingFeaturedContent";
import LoadingAvatar from "@/components/user/LoadingAvatar";
import LoadingContentAuthor from "@web/components/user/LoadingContentAuthor";
import MetaDivider from "@web/components/news/MetaDivider";
import { formatDate } from "@web/lib/time-utils";

export default {
    name: "SharedNews",
    components: {
        MetaDivider,
        LoadingContentAuthor,
        LoadingAvatar,
        MissingFeaturedContent,
        FeaturedContentSkeleton,
        FeaturedContent,
    },
    inheritAttrs: false,
    props: {
        uid: String,
        path: String,
    },
    data() {
        return {
            loading: false,
            missing: false,
            /** @type {EverestNews | undefined} */
            news: undefined,
            /** @type {string | undefined} */
            coverPictureSrc: undefined,
        };
    },
    computed: {
        ...mapGetters({
            intranetUid: INTRANET_MODULE_NAME + INTRANET_UID,
            currentUser: AUTH_MODULE_NAME + CURRENT_USER,
        }),
        route() {
            return { name: "news-route", params: { newsUid: this.uid, commentPath: this.path } };
        },
        readingTime() {
            if (!this.news.readingTime) {
                return null;
            }
            const time = Math.ceil(this.news.readingTime);
            return `${time} min ${this.$t("read_time")}`;
        },
    },
    mounted() {
        this.fetchNews();
    },
    methods: {
        formatDate,
        async fetchNews() {
            if (!this.uid) {
                return;
            }
            this.loading = true;
            try {
                this.news = await newsService.getNews({ intranetUid: this.intranetUid, newsUid: this.uid, requestingUserUid: this.currentUser.uid });
                if (!!this.news.coverPictureRef && this.news.coverPictureRef !== AUTHOR_IS_COVER_PICTURE) {
                    this.coverPictureSrc = await newsService.getDownloadUrlForImage(this.intranetUid, this.news.uid, this.news.coverPictureRef);
                }
            } finally {
                this.loading = false;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .news-cover {
        height: 200px;
    }
}
</style>
