export enum TimestampEvent {
    newsPublished = "news-published",
    postPublished = "post-published",
    newsCommentPublished = "news-comment-published",
    postCommentPublished = "post-comment-published",
}

export interface TimestampInFirestore {
    timestamp: {
        _seconds: number,
        _nanoseconds: number
    };
}
