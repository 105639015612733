import { ALGOLIA_ID } from "@web/firebaseConfig";
import { ApiClientBuilder } from "@web/api/ApiClientBuilder";
import { ApiClient } from "@web/api/ApiClient";
import algoliasearch,
{ SearchIndex } from "algoliasearch/lite";
import { getGlobalConfiguration } from "@web/global-config";
import {
    IndexEntity,
    IndexName,
} from "@backend/index/types";
import {
    SearchOptions,
    SearchResponse,
} from "@algolia/client-search";
import { TopicIndexEntity } from "@backend/topic/index-types";
import { Topic } from "@backend/topic/types";
import { asTimestamp } from "@web/lib/time-utils";

const MAX_TOKEN_LIFETIME_MINUTES = getGlobalConfiguration().user_search_token_max_age_minutes - 1;

export interface QueryParams extends SearchOptions {
    intranetUid: string;
    // @deprecated use `hitsPerPage` instead
    pageSize?: number;
    // @deprecated use `page` instead
    pageNumber?: number;
}

export abstract class AbstractSearchService<T extends IndexEntity> {
    private readonly api: Promise<ApiClient>;

    protected constructor(
        private readonly indexName: IndexName,
        private readonly searchTokenEndpoint: string,
    ) {
        this.api = new ApiClientBuilder().build();
    }

    public async search(
        { intranetUid, query, pageNumber, pageSize, ...searchOptions }: QueryParams,
    ): Promise<SearchResponse<T>> {
        const index = await this.getSearchIndex(intranetUid);
        return index.search(query ?? "", {
            page: pageNumber,
            hitsPerPage: pageSize,
            ...searchOptions,
        });
    }

    public async getSearchIndex(intranetUid: string): Promise<SearchIndex> {
        const searchToken = await this.getSearchToken(intranetUid);
        const client = algoliasearch(ALGOLIA_ID, searchToken);
        return client.initIndex(this.indexName);
    }

    private async getSearchToken(intranetUid: string): Promise<string> {
        const localStorageKey = `${this.indexName}-search-token-${intranetUid}`;
        const tokenSerialized = localStorage.getItem(localStorageKey);
        const nowMs = (new Date()).getTime();

        if (tokenSerialized) {
            try {
                const tokenDeserialized: { token: string, created: number } = JSON.parse(tokenSerialized);
                if ((tokenDeserialized.created + MAX_TOKEN_LIFETIME_MINUTES * 60 * 1000) > nowMs) {
                    console.debug(`Return search token for '${this.indexName}' from localStorage.`);
                    return tokenDeserialized.token;
                }
            } catch (e) {
                console.error(`Invalid json object for search token of index '${this.indexName}'.`);
            }
        }
        console.debug(`Creating a new search token for index '${this.indexName}'.`);
        const newToken = await this.fetchNewSearchToken(intranetUid);
        localStorage.setItem(localStorageKey, JSON.stringify({ token: newToken, created: nowMs }));
        return newToken;
    }

    private async fetchNewSearchToken(intranetUid: string): Promise<string> {
        const client = await this.api;
        const response = await client.get<string>(`${this.searchTokenEndpoint + intranetUid}`);
        return response.data;
    }
}

export function indexEntityToTopic(indexEntity: TopicIndexEntity): Topic {
    return {
        uid: indexEntity.uid,
        name: indexEntity.name,
        comparableName: indexEntity.name.toLowerCase(),
        creationDate: asTimestamp(indexEntity.creationDate),
        lastModified: asTimestamp(indexEntity.lastModified),
        subscriptionCount: indexEntity.subscriptionCount,
        usageCount: indexEntity.usageCount,
    };
}
