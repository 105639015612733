import { AxiosResponse } from "axios";
import { ApiClient } from "@web/api/ApiClient";
import { ApiClientBuilder } from "@web/api/ApiClientBuilder";

export interface UploadTarget {
    uploadUrl: string;
    path: string;
}

export async function blobToBase64(blob: Blob): Promise<string> {
    const fileReader = new FileReader();
    return new Promise((resolve, reject) => {
        fileReader.addEventListener("load", () => {
            resolve(fileReader.result as string);
        });
        fileReader.addEventListener("error", () => {
            reject("Error while reading file");
        });
        fileReader.readAsDataURL(blob);
    });
}

const base64ImagePattern = /^data:.+\/.+;base64/;

export function base64toBlob(dataUrl: string): Blob {
    if (!base64ImagePattern.test(dataUrl)) {
        throw Error("Invalid base64 string.");
    }
    const splitUrl = dataUrl.split(",");
    const mimeType = splitUrl[0].match(/:(.*?);/)![1];
    const byteString = atob(splitUrl[1]);
    let n = byteString.length;
    const byteArray = new Uint8Array(n);

    while (n--) {
        byteArray[n] = byteString.charCodeAt(n);
    }
    return new Blob([byteArray], { type: mimeType });
}

export function blobToFile(inputBlob: Blob, fileName: string): File {
    if (("name" in inputBlob && "lastModified" in inputBlob) || inputBlob instanceof File) {
        return inputBlob as File;
    }
    // cast to any to add props but keep existing props
    const file = inputBlob as any;
    file.name = file.name ?? fileName;
    file.lastModified = file.lastModified ?? new Date().getTime();
    return file as File;
}

export class FileUploadService {
    private readonly api: Promise<ApiClient>;

    constructor() {
        this.api = new ApiClientBuilder().build();
    }

    public async uploadFile(
        uploadUrlRetrievalPath: string,
        file: Blob,
        uploaderUid: string,
        intranetUid: string,
    ): Promise<UploadTarget> {
        const api = await this.api;

        const fileType = file.type ? file.type : "application/octet-stream";

        const uploadResponse: AxiosResponse = await api.post(
            uploadUrlRetrievalPath,
            { fileSize: file.size, contentType: fileType },
        );
        const uploadTarget = uploadResponse.data as UploadTarget;

        await api.put(uploadTarget.uploadUrl, file, {
            headers: {
                "Content-Type": fileType,
                "x-goog-content-length-range": `0,${file.size}`,
                "x-goog-file-path": `intranet/${intranetUid}/${uploadTarget.path}`,
                "x-goog-meta-uploaded-to-intranet": intranetUid,
                "x-goog-meta-uploaded-by": uploaderUid,
            },
        });
        return uploadTarget;
    }
}
