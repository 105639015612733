<template>
    <div class="meta-info">
        <user-link
            :uid="profile.uid"
        >
            <div
                :style="avatarStyle"
                class="avatar"
            ></div>
        </user-link>
        <div
            v-fade-in.when="profile.displayName"
            class="text"
        >
            <div>
                <user-link
                    :uid="profile.uid"
                    class="display-name cursor-pointer"
                >
                    {{ profile.displayName }}
                </user-link>
            </div>
            <span class="date text-nowrap text-highest-contrast">{{ formattedDate }}</span>
            <MetaDivider/>
            <div
                v-if="readingTime"
                class="reading-time text-nowrap text-highest-contrast"
            >
                {{ readingTime }}
            </div>
            <MetaDivider v-if="news.commentCount > 0 || totalReactionCount > 0"/>
            <span
                v-if="news.commentCount > 0"
                class="mright-xsmall text-nowrap text-highest-contrast"
            >
                <Icon name="message-square"/>
                {{ news.commentCount }}
            </span>
            <span
                v-if="totalReactionCount > 0"
                class="text-nowrap text-highest-contrast"
            >
                <Icon name="smile"/>
                {{ totalReactionCount }}
            </span>
        </div>
    </div>
</template>

<script>
import {
    mapGetters,
    mapState,
} from "vuex";
import { profileService } from "@web/services";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import { NEWS_MODULE_NAME } from "@web/store/news/news";
import { AUTH_MODULE_NAME } from "@web/store/auth/auth";
import { toDayJs } from "@web/lib/time-utils";
import MetaDivider from "@web/components/news/MetaDivider.vue";
import { totalReactionCount } from "@web/lib/total-reaction-count";
import { INTRANET_UID } from "@web/store/intranet/getters";
import UserLink from "@web/components/user/UserLink";
import { registerSnapshotListenerOnReactableEntity } from "@web/lib/reactable-entity-snapshot-listener";
import { ReactableRootEntityType } from "@backend/reaction/types";

export default {
    name: "NewsMeta",
    components: {
        UserLink,
        MetaDivider,
    },
    props: {
        news: {
            type: Object,
        },
        timeFormat: {
            type: String,
            default: "DD. MMMM YYYY",
        },
    },
    data() {
        return {
            profile: {},
            updatedReactions: this.news.reactions,
            unsubscribeReactionsListener: () => {},
        };
    },
    computed: {
        ...mapState(AUTH_MODULE_NAME, ["currentUser"]),
        ...mapState(NEWS_MODULE_NAME, ["current"]),
        ...mapGetters({
            intranetUid: INTRANET_MODULE_NAME + INTRANET_UID,
        }),
        avatarStyle() {
            return {
                "background-image": "url(" + this.profile.photoURL + ")",
            };
        },
        formattedDate() {
            return toDayJs(this.news.publishDate).format(this.timeFormat);
        },
        readingTime() {
            if (!this.news.readingTime) {
                return null;
            }
            const time = Math.ceil(this.news.readingTime);
            return `${time} min ${this.$t("read_time")}`;
        },
        totalReactionCount() {
            return totalReactionCount(this.updatedReactions);
        },
    },
    async created() {
        this.profile = { photoURL: "", displayName: "" };
        this.profile = await profileService.getBaseUserProfile(this.intranetUid, this.news.creatorUid).catch(() => profileService.fallbackProfile);
    },
    mounted() {
        this.unsubscribeReactionsListener = registerSnapshotListenerOnReactableEntity(
            this.intranetUid,
            ReactableRootEntityType.news,
            this.news.uid,
            undefined,
            this.onReactableDocSnapshot);
    },
    destroyed() {
        this.unsubscribeReactionsListener();
    },
    methods: {
        onReactableDocSnapshot(snapshot) {
            const docData = snapshot.data();
            if (docData && docData.reactions) {
                this.updatedReactions = docData.reactions;
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.meta-info {
    flex: 1;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    --icon-size: 0.8rem;

    .avatar {
        flex: 0 0 auto;
    }

    .text {
        display: flex;
        flex-wrap: wrap;
        --icon-color: var(--highest-contrast);

        .display-name {
            font-weight: bold;
            font-size: 16px;
            margin-right: 10px;
            white-space: nowrap;
        }
    }

    .icon {
        vertical-align: middle;
    }
}

.avatar {
    width: 40px;
    height: 40px;
    background-size: cover;
    background-position: top center;
    border-radius: 50%;
    margin-right: 10px;
}

</style>
