import { VuexRootState } from "@web/store";
import { Module } from "vuex";
import {
    ReactionDtoInFirestoreFieldKeys,
    ReactionInFirestore,
    ReactionType
} from "@backend/reaction/types";
import { reactionService } from "@web/services";
import { GET_REACTIONS } from "@web/store/reaction/getters";
import {
    INITIALIZE,
    APPEND_REACTIONS
} from "@web/store/reaction/mutations";
import { FETCH_REACTIONS } from "@web/store/reaction/actions";

const getDefaultState = (): Pick<ReactionModuleState, "reactionListsByFilter"> => ({
    reactionListsByFilter: {
        all: { reactions: [], isNextPage: false },
        [ReactionType.clap]: { reactions: [], isNextPage: false },
        [ReactionType.dislike]: { reactions: [], isNextPage: false },
        [ReactionType.like]: { reactions: [], isNextPage: false },
        [ReactionType.love]: { reactions: [], isNextPage: false },
        [ReactionType.smile]: { reactions: [], isNextPage: false },
    }
});

interface ReactionsByFilter {
    all: ReactionList;
    [ReactionType.clap]: ReactionList;
    [ReactionType.dislike]: ReactionList;
    [ReactionType.like]: ReactionList;
    [ReactionType.love]: ReactionList;
    [ReactionType.smile]: ReactionList;
}

interface ReactionList {
    reactions: ReactionInFirestore[];
    isNextPage: boolean;
}

export interface ReactionModuleState {
    entityUid: string;
    reactionListsByFilter: ReactionsByFilter;
}

export type ReactionFilterKey = ReactionType | "all";

export const REACTION_MODULE_NAME = "reaction/";
const REACTION_PAGE_SIZE = 10;

export const REACTION_MODULE = {
    strict: true,
    namespaced: true,
    state: { entityUid: "", ...getDefaultState() },
    getters: {
        [GET_REACTIONS]: state => (filterKey: ReactionFilterKey) => {
            return state.reactionListsByFilter[filterKey].reactions;
        },
    },
    mutations: {
        [INITIALIZE](state, { entityUid }: { entityUid: string }) {
            state.entityUid = entityUid;
            state.reactionListsByFilter = getDefaultState().reactionListsByFilter;
        },
        [APPEND_REACTIONS](state, { reactions, isNextPage, filterKey }: { reactions: ReactionInFirestore[], isNextPage: boolean, filterKey: ReactionFilterKey }) {
            state.reactionListsByFilter[filterKey] = { reactions: [...state.reactionListsByFilter[filterKey].reactions, ...reactions], isNextPage };
        },
    },
    actions: {
        async [FETCH_REACTIONS]({ dispatch, commit, rootState, state }, { filterKey = "all" }: { filterKey: ReactionFilterKey }) {
            const intranet = rootState.intranet.intranet!;
            const startAfterReaction: ReactionInFirestore | undefined = [...state.reactionListsByFilter[filterKey].reactions].pop();
            const startAfterSeconds = startAfterReaction ? startAfterReaction[ReactionDtoInFirestoreFieldKeys.changedTimestamp]._seconds : undefined;

            const reactions = await reactionService.getReactions(intranet.uid, state.entityUid, {
                limit: REACTION_PAGE_SIZE + 1,
                startAfter: startAfterSeconds
            }, filterKey);

            const isNextPage = reactions.length === REACTION_PAGE_SIZE + 1;

            if (isNextPage) {
                reactions.pop();
            }

            commit(APPEND_REACTIONS, { reactions, isNextPage, filterKey });
        },
    }
} as Module<ReactionModuleState, VuexRootState>;
