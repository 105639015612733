import { MinPaginationSearchParameters } from "@backend/common/pagination-types";
import { EverestContentEntity } from "@backend/entity/types";

export enum ViewableEntityType {
    story = "story",
    post = "post",
    comment = "comment"
}

export function viewableEntityTypeFromString(viewableEntityType: string): ViewableEntityType | null {
    if (viewableEntityType === ViewableEntityType.story) {
        return ViewableEntityType.story;
    }
    if (viewableEntityType === ViewableEntityType.post) {
        return ViewableEntityType.post;
    }
    if (viewableEntityType === ViewableEntityType.comment) {
        return ViewableEntityType.comment;
    }
    return null;
}

export enum ViewInFirestoreFieldKeys {
    userUid = "userUid",
    creatorUid = "creatorUid",
    entityType = "entityType",
    entityUid = "entityUid",
    entityReference = "entityReference",
    viewedTimestamp = "viewedTimestamp"
}

export interface View {
    [ViewInFirestoreFieldKeys.userUid]: string;
    [ViewInFirestoreFieldKeys.creatorUid]: string;
    [ViewInFirestoreFieldKeys.entityType]: ViewableEntityType;
    [ViewInFirestoreFieldKeys.entityUid]: string;
    [ViewInFirestoreFieldKeys.viewedTimestamp]: Timestamp;
}

export enum ViewsOnEntityInFirestoreFieldKeys {
    views = "views",
}

export interface ViewTrackableInFirestore extends EverestContentEntity {
    [ViewsOnEntityInFirestoreFieldKeys.views]?: number;
}

export type ViewTrackable = ViewTrackableInFirestore & {
    uid: string;
    docPath: string;
}

export interface ViewWriteResult {
    updated: boolean;
    viewDocPath: string;
}

interface Timestamp {
    _seconds: number;
    _nanoseconds: number;
}

export type ViewPaginationSearchParameters = MinPaginationSearchParameters<number>;
