/**
 * Internal representation of the status a stripe subscription is in. You can find more information on the lifecycle
 * of a stripe subscription here https://stripe.com/docs/billing/subscriptions/overview
 */
import { Timestamp } from "@backend/entity/types";

export enum ProductSubscriptionStatus {
    /**
     * Initial 30 day trial
     */
    trialing = "trialing",
    /**
     * The subscription is in good standing and the most recent payment is successful. It’s safe to provision your
     * product for your customer.
     */
    active = "active",
    /**
     * While creating the subscription the required payment has not been made yet or failed. User has 23 hours to pay.
     */
    incomplete = "incomplete",
    /**
     * User has not finished an `incomplete` payment within 23 hours, after creating a subscription.
     */
    incompleteExpired = "incomplete_expired",
    /**
     * Payment on the latest invoice either failed or wasn’t attempted. Behaves the same as an active subscription with
     * regard to invoice creation. The subscription’s next state is based the subscription settings. Paying the most
     * recent invoice before its due date reverts the status of the subscription to active.
     */
    pastDue = "past_due",
    /**
     * The subscription has been cancelled. This is a terminal state that can not be updated.
     */
    canceled = "canceled",
    /**
     * The latest invoice hasn’t been paid but the subscription remains in place. The latest invoice remains open and
     * invoices continue to be generated but payments aren’t attempted. Pay the most recent invoice before its due date
     * to update the status of the subscription to active.
     */
    unpaid = "unpaid",
    /**
     * The trial will end in 3 days
     */
    trialWillEnd = "trial_will_end",
}

export interface StripeSubscription {
    id: string;
    itemId: string;
    customerId: string;
}

export interface ProductSubscription {
    stripeSubscription?: StripeSubscription;
    status: ProductSubscriptionStatus;
    expirationDate: Timestamp;
    creationDate: Timestamp;
    lastUpdatedDate: Timestamp;
    lastSubscriptionMailNotificationDate: Timestamp;
}

export interface StripeSessionInfo {
    url: string;
}
