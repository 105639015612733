<template>
    <article
        :id="'post-'+post.uid"
        v-resize:big-post.gt="600"
        v-resize:compact-post="480"
        v-resize:small-post="380"
        :class="{deleting, 'depth-3': editMode}"
        class="card depth-1 post-wrapper padding-small"
    >
        <view-trigger
            :entity="post"
            :entity-type="'post'"
        />
        <PostHeader
            :author-user-uid="post.creatorUid"
            :creation-date="post.creationDate"
            :is-just-edited="isJustEdited"
            :is-post-author="isPostAuthor"
            :is-unread="isUnread"
            :last-edited-date="post.lastEditedDate"
            :post-route="routeToPost"
            :show-actions="!editMode"
            class="header"
        >
            <template #action-menu="{isOpen}">
                <div>
                    <div class="post-actions">
                        <app-button
                            class="edit-action"
                            icon="link"
                            type="link no-padding small"
                        >
                            <div v-clipboard="directLinkToPost">
                                {{ $t("copy_direct_link") }}
                            </div>
                        </app-button>
                        <app-button
                            v-if="isModificationAllowed"
                            class="edit-action"
                            icon="edit"
                            type="link no-padding small"
                            @click="toggleEditMode"
                        >
                            {{ $t("edit") }}
                        </app-button>
                        <app-button
                            v-if="isModificationAllowed"
                            :busy="deleting"
                            class="delete-action"
                            icon="trash"
                            type="link no-padding small"
                            @click="onClickOnDeleteBtn"
                        >
                            {{ $t("delete") }}
                        </app-button>
                        <PostWatchMenuButton
                            v-if="isOpen"
                            ref="followButton"
                            :post-uid="post.uid"
                        />
                    </div>
                </div>
            </template>
        </PostHeader>
        <div class="content-container">
            <div
                :class="{'editing-post': editMode}"
                class="content mbottom-small"
            >
                <PostEditor
                    :attachment-manager="attachmentManager"
                    :busy="saving"
                    :content="newContentForEditedPost"
                    :edit-mode="editMode"
                    :place-holder="$t('enter_your_comment_here')"
                    :post="true"
                    :topic-uids="topics"
                    :truncate="!post._new"
                    @cancel="toggleEditMode"
                    @save="onSaveBtnClickInEditEditor"
                />
            </div>
            <div class="divider comment-divider"></div>
            <div
                :class="{'disabled': editMode}"
                class="comment-container"
            >
                <slot name="comment-container"></slot>
            </div>
        </div>
    </article>
</template>

<script>
import { mapGetters } from "vuex";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import PostHeader from "@web/views/posts/PostHeader";
import { analytics } from "@web/analytics";
import { getGlobalConfiguration } from "@web/global-config";
import PostEditor from "@web/components/editor/PostEditor";
import PostWatchMenuButton from "@web/views/posts/PostWatchMenuButton";
import { AttachmentManager } from "@web/components/attachments/attachment-manager";
import {
    AttachmentRootEntityType,
    EntityWithAttachmentReference,
} from "@web/services/attachments";
import {
    MentionAction,
    mentionAnalytics,
} from "@web/lib/quill/mention/everest-mention";
import { MentionEntityType } from "@backend/mention/mention-types";
import { cloneDeepClean } from "@web/lib/cloneDeepClean";
import { length } from "@web/lib/quill/quill-utils";
import { AUTH_MODULE_NAME } from "@web/store/auth/auth";
import { CURRENT_USER } from "@web/store/auth/getters";
import {
    INTRANET,
    IS_ADMIN,
} from "@web/store/intranet/getters";
import ViewTrigger from "@web/components/view-tracking/ViewTrigger";
import { viewService } from "@web/services";
import { LIVE_NEW_CONTENT_MODULE_NAME } from "@web/store/live-new-content/live-new-content";
import { GET_POST_TIMELINE_RELOAD } from "@web/store/live-new-content/getters";

/**
 * renders a community post
 * offers slot for comments which allows custom pagination behaviour in different contexts
 */
export default {
    name: "Post",
    components: {
        PostWatchMenuButton,
        PostEditor,
        PostHeader,
        ViewTrigger,
    },
    props: {
        /** @type {EverestPost} */
        post: Object,
        /** @type {EditPostFunction} */
        editPost: Function,
        /** @type {DeletePostFunction} */
        deletePost: Function,
    },
    data() {
        return {
            isUnread: false,
            deleting: false,
            saving: false,
            editMode: false,
            isJustEdited: false,
            newContentForEditedPost: cloneDeepClean(this.post.content),
            topics: cloneDeepClean(this.post.topics),
            isNewContentForEditedPostEmpty: true,
            isEditorEmpty: true,
            attachmentManager: null,
        };
    },
    computed: {
        ...mapGetters({
            intranet: INTRANET_MODULE_NAME + INTRANET,
            currentUser: AUTH_MODULE_NAME + CURRENT_USER,
            isAdmin: INTRANET_MODULE_NAME + IS_ADMIN,
            isPostTimelineReloading: LIVE_NEW_CONTENT_MODULE_NAME + GET_POST_TIMELINE_RELOAD,
        }),
        isPostAuthor() {
            return this.currentUser && this.currentUser.uid === this.post.creatorUid;
        },
        isModificationAllowed() {
            return (this.isPostAuthor || this.isAdmin) && !this.post.isDeleted;
        },
        routeToPost() {
            return { name: "post-route", params: { postUid: this.post.uid } };
        },
        directLinkToPost() {
            const route = this.$router.resolve(this.routeToPost);
            return window.location.origin + route.href;
        },
    },
    watch: {
        isPostTimelineReloading(_, newIsReloading) {
            if (!this.isUnread) {
                return;
            }
            if (newIsReloading) {
                viewService.viewed({
                    intranetUid: this.intranet.uid,
                    entity: this.post,
                    userUid: this.currentUser.uid,
                }).then((viewed) => {
                    this.isUnread = !viewed;
                });
            }
        }
    },
    mounted() {
        this.attachmentManager = AttachmentManager.load({
            entityWithAttachmentReference: new EntityWithAttachmentReference({
                intranetUid: this.intranet.uid,
                entityType: AttachmentRootEntityType.post,
                entityUid: this.post.uid,
                entity: this.post,
            }),
            onError: error => {
                this.$notify({
                    group: "app",
                    type: "error",
                    title: this.$t("attachment_loading_error_title"),
                    text: this.$t("attachment_loading_error_text", ["post"]),
                });
                console.error(error);
            },
        });
        viewService.viewed({
            intranetUid: this.intranet.uid,
            entity: this.post,
            userUid: this.currentUser.uid,
        }).then((viewed) => {
            this.isUnread = !viewed;
        });
    },
    methods: {
        async onSaveBtnClickInEditEditor({ topicUids, content, onSave }) {
            this.saving = true;
            this.newContentForEditedPost = content;
            try {
                await this.editPost({ post: this.post, content, topics: topicUids, attachmentManager: this.attachmentManager });
                analytics.log(getGlobalConfiguration().analytics_event_name_post_edited, { length: length(content) });
                mentionAnalytics.trackMentions(content, MentionEntityType.post, MentionAction.create);
                this.topics = topicUids;
                onSave();
            } catch (e) {
                this.showError("error_editing_post", e);
            }
            this.saving = false;
            this.editMode = false;
            this.isJustEdited = true;
        },
        async onClickOnDeleteBtn() {
            this.$modal.show("confirm-modal", {
                title: this.$t("post_delete_confirmation_title"),
                text: this.$t("post_delete_confirmation_text"),
                warning: true,
                callback: async(confirmed) => {
                    if (confirmed) {
                        this.deleting = true;
                        try {
                            await this.deletePost(this.post);
                        } catch (e) {
                            this.showError("error_deleting_post", e);
                        } finally {
                            this.deleting = false;
                        }
                    }
                },
            });
        },
        toggleEditMode() {
            if (this.editMode) {
                this.editMode = false;
                this.newContentForEditedPost = cloneDeepClean(this.post.content);
                return;
            }
            this.editMode = true;
        },
        showError(i18n, exception) {
            console.error(exception);
            this.$notify({
                group: "app",
                type: "error",
                text: this.$t(i18n),
            });
        },
    },
};

/**
 * @typedef EditPostFunction
 * @param {object} options
 * @param {EverestPostInFireStore} options.post
 * @param {QuillDeltaOperations[]} options.content
 * @param {string[]} options.topics
 * @param {AttachmentManager} options.attachmentManager
 * @returns Promise<void>
 */
/**
 * @typedef DeletePostFunction
 * @param {EverestPostInFireStore} post
 * @returns Promise<void>
 */
</script>

<style lang="scss" scoped>
.post-wrapper {
    display: flex;
    flex-direction: column;
    margin: 0 0 2rem;
    word-break: break-word;

    &.deleting {
        opacity: 0.5;
        pointer-events: none;
    }

    .post-header {
        margin-bottom: 0.5rem;
    }

    .footer {
        display: flex;
        flex-wrap: nowrap;
        padding-bottom: 0.5rem;
        justify-content: space-between;
        align-items: center;

        .button {
            font-size: 1rem;
            font-weight: 500;

            & + .button {
                margin-left: 1rem;
            }
        }
    }

    .comment-divider {
        margin: 1rem 0;
    }

    &.compact-post {
        .content-container {
            padding: 0;
        }

        &::v-deep {
            .comments-container {
                gap: 0.1rem 0.2rem;
                margin-left: -1rem;
            }
        }
    }

    &.small-post {
        .content-container {
            padding: 0;
        }

        &::v-deep {
            .comment-wrapper {
                gap: 0.2rem 0.4rem;

                .grey-box.with-noupy:after {
                    display: none;
                }
            }

            .comments-container {
                margin-left: -1.5rem;
            }

            .post-comment-create-editor {
                .avatar {
                    display: none;
                }
            }

        }
    }

    &.big-post {
        &::v-deep {
            .post-header:not(.comment-header) {
                margin-bottom: 1rem;
            }

            .post-editor {
                // TODO: Clamp based Type Scale evaluation
                /* TO BE RE-INTRODUCED APP-WIDE LATER */
                /* h1 {
                    font-size: clamp(1.4rem, 2vw, 2.1rem);
                    margin-bottom: clamp(0.75rem, 1vw, 1.5rem);
                }
                h2 {
                    font-size: clamp(1.2rem, 1.5vw, 1.8rem);
                    margin-bottom: clamp(0.75rem, 1vw, 1.5rem);
                }
                h3 {
                    font-size: clamp(1rem, 1.2vw, 1.5rem);
                    margin-bottom: clamp(0.75rem, 1vw, 1.5rem);
                }

                p {
                    font-size: clamp(1rem, 1.2vw, 1.5rem);
                    line-height: clamp(1.5rem, 1.6vw, 2.2rem);
                    margin-bottom: clamp(0.75rem, 1vw, 1.5rem);
                    letter-spacing:-0.01em;
                } */
            }

        }
    }

}

.post-actions {
    display: flex;
    flex-direction: column;
}

</style>
