// @ts-ignore
import clap_svg from "@web/assets/reactions/clap.svg";
// @ts-ignore
import dislike_svg from "@web/assets/reactions/dislike.svg";
// @ts-ignore
import like_svg from "@web/assets/reactions/like.svg";
// @ts-ignore
import love_svg from "@web/assets/reactions/love.svg";
// @ts-ignore
import smile_svg from "@web/assets/reactions/smile.svg";

export default {
    clap: clap_svg,
    dislike: dislike_svg,
    like: like_svg,
    love: love_svg,
    smile: smile_svg,
};
