import { ApiClientBuilder } from "@web/api/ApiClientBuilder";
import { ApiClient } from "@web/api/ApiClient";
import { AxiosResponse } from "axios";
import { Intranet } from "@backend/intranet/types";
import { EverestUser } from "@backend/user/types";
import {
    EverestEvent,
    EverestEventAttendee
} from "@backend/event/types";
import { cloudRunUrl } from "@web/cloud-run-url";
import {
    DeltaFormat,
    PublishableEntityStatus
} from "@backend/entity/types";
import { Timestamp } from "@web/store/invite/types";
import { PaginationSearchParameters } from "@backend/common/pagination-types";
import { FileUploadService } from "@web/services/file-upload";

interface GetEventsParams {
    intranetUid: string;
    creatorUid?: string;
    entityStatus?: PublishableEntityStatus;
    periodStart?: Timestamp;
    periodEnd?: Timestamp;
    startAt?: string;
    startAfter?: string;
    endAt?: string;
    endBefore?: string;
    limit?: number;
    offset?: number;
}

export class EventService {
    public static readonly eventServiceEndpoint = `${cloudRunUrl.event}/api/event`; // dev mode: `http://localhost:9123/api/event`

    private api: Promise<ApiClient>;

    constructor(private readonly fileUploadService: FileUploadService) {
        this.api = new ApiClientBuilder().build();
    }

    public async updateEventCoverImage(intranet: Intranet, userUid: string, event: EverestEvent, file: Blob): Promise<EverestEvent> {
        const uploadTarget = await this.fileUploadService.uploadFile(
            `${EventService.eventServiceEndpoint}/${intranet.uid}/${event.uid}/upload-event-cover-image`,
            file,
            userUid,
            intranet.uid,
        );
        return this.updateEvent(intranet, {
            ...event,
            coverImageStoragePath: uploadTarget.path,
        } as EverestEvent);
    }

    public async getEvent(intranet: Intranet, eventUid: string): Promise<EverestEvent> {
        const client = await this.api;
        const response: AxiosResponse = await client.get(
            `${EventService.eventServiceEndpoint}/${intranet.uid}/events/${eventUid}`,
            { params: { format: DeltaFormat.web } },
        );
        return response.data as EverestEvent;
    }

    public async getEvents({ intranetUid, creatorUid, periodStart, periodEnd, limit, offset, startAt, startAfter, endAt, endBefore, entityStatus }: GetEventsParams) {
        const client = await this.api;
        const response: AxiosResponse = await client.get(`${EventService.eventServiceEndpoint}/${intranetUid}/published`, {
            params: {
                format: DeltaFormat.web,
                periodStartSeconds: periodStart?._seconds,
                periodEndSeconds: periodEnd?._seconds,
                creatorUid,
                limit,
                offset,
                startAt,
                startAfter,
                endAt,
                endBefore,
                entityStatus
            }
        });
        return response.data;
    }

    public async getMyEvents(intranet: Intranet) {
        const client = await this.api;
        const response: AxiosResponse = await client.get(`${EventService.eventServiceEndpoint}/${intranet.uid}/myevents`, {
            params: {
                format: DeltaFormat.web,
            }
        });
        return response.data;
    }

    public async getAttendeeForEvent(intranet: Intranet, event: EverestEvent, searchParams: PaginationSearchParameters<number>) {
        const client = await this.api;
        const response: AxiosResponse = await client.get(`${EventService.eventServiceEndpoint}/${intranet.uid}/events/${event.uid}/attendees`, {
            params: searchParams
        });
        // analytics.log(getGlobalConfiguration().analytics_event_name_event_created);
        return response.data;
    }

    public async createEvent(intranet: Intranet): Promise<EverestEvent> {
        const client = await this.api;
        const response: AxiosResponse = await client.post(`${EventService.eventServiceEndpoint}/${intranet.uid}`);
        // analytics.log(getGlobalConfiguration().analytics_event_name_event_created);
        return response.data;
    }

    public async updateEvent(intranet: Intranet, event: EverestEvent): Promise<EverestEvent> {
        const client = await this.api;
        const response: AxiosResponse = await client.put(
            `${EventService.eventServiceEndpoint}/${intranet.uid}/events/${event.uid}`,
            event,
            { params: { format: DeltaFormat.web } },
        ).catch((error) => {
            if (error.response.data.type === "ValidationError") {
                throw new Error(error.response.data.message);
            }
            throw error;
        });
        // analytics.log(getGlobalConfiguration().analytics_event_name_event_created);
        return response.data;
    }

    public async joinEvent(intranet: Intranet, user: EverestUser, event: EverestEvent) {
        const client = await this.api;
        const response: AxiosResponse = await client.post(`${EventService.eventServiceEndpoint}/${intranet.uid}/events/${event.uid}/attendee`);
        return response.data;
    }

    public async leaveEvent(intranet: Intranet, user: EverestUser, event: EverestEvent) {
        const client = await this.api;
        const response: AxiosResponse = await client.delete(`${EventService.eventServiceEndpoint}/${intranet.uid}/events/${event.uid}/attendee`);
        return response.data;
    }

    // TODO: Add type for event
    public async getAttendeeStatus(intranet: Intranet, user: EverestUser, event: any): Promise<EverestEventAttendee> {
        const client = await this.api;
        const response: AxiosResponse = await client.get(`${EventService.eventServiceEndpoint}/${intranet.uid}/events/${event.uid}/attendee/${user.uid}`);
        return response.data as EverestEventAttendee;
    }

    public async deleteEvent(intranet: Intranet, event: any) {
        const client = await this.api;
        const response: AxiosResponse = await client.delete(`${EventService.eventServiceEndpoint}/${intranet.uid}/events/${event.uid}`);
        // analytics.log(getGlobalConfiguration().analytics_event_name_event_deleted);
        return response.data;
    }
}
