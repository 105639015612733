import router from "@web/router";

export default {
    bind(el, binding, vnode) {
        // Style element as clickable
        el.style.cursor = "pointer";

        // Check required expression
        if (!binding.expression) {
            throw new Error(`Missing parameter for directive '${binding.rawName}'`);
        }

        updateBoundEventUid(el, binding, vnode);
        setHrefToEventRoute(el, binding, vnode);

        el.addEventListener("click", (event) => {
            const eventUid = el.dataset.boundEventUid;
            if (!eventUid) {
                /*
                 * Registering an event listener to open a event is only useful and valid,
                 * if a eventUid exists.
                 */
                return;
            }
            router.push("/intranet/" + vnode.context.$store.getters["intranet/intranet"].uid + "/event/" + eventUid);
            // Prevent to open links directly
            event.preventDefault();
        });
    },
    update(el, binding, vnode) {
        updateBoundEventUid(el, binding, vnode);
        setHrefToEventRoute(el, binding, vnode);
    },
};

function updateBoundEventUid(el, binding, vnode) {
    el.dataset.boundEventUid = binding.value;
}

function setHrefToEventRoute(el, binding, vnode) {
    const eventUid = el.dataset.boundEventUid;
    if (!eventUid) {
        // Setting a event link as href is only useful and valid, if a eventUid exists.
        return;
    }
    // Add current link
    if (el.tagName === "A") {
        el.href = eventRoute({
            eventUid: eventUid,
            intranetUid: vnode.context.$store.getters["intranet/intranet"].uid
        });
    }
}

function eventRoute({ eventUid, intranetUid }) {
    return `/intranet/${intranetUid}/event/${eventUid}`;
}
