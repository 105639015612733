<template>
    <pu-skeleton
        height="2ex"
        :loading="isLoading"
    >
        <app-button
            :icon="isFollowing ? 'bell-off' : 'bell'"
            type="link no-padding small"
            @click="setFollowStatus(!isFollowing)"
        >
            <div class="menu-item">
                {{ $t(isFollowing ? "activity_unfollow" : "activity_follow") }}
            </div>
        </app-button>
    </pu-skeleton>
</template>
<script>
import { mapState } from "vuex";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import { followService } from "@web/services";
import { i18n } from "@web/i18n";

export default {
    name: "PostWatchMenuButton",
    props: {
        postUid: {
            default: null,
            type: String,
        },
    },
    data() {
        return {
            isFollowing: false,
            isLoading: true,
        };
    },
    computed: {
        ...mapState(INTRANET_MODULE_NAME, ["intranet"]),
        params() {
            return {
                intranetUid: this.intranet.uid,
                followableType: "post",
                contentUid: this.postUid,
            };
        },
    },
    async mounted() {
        try {
            this.isFollowing = await followService.isFollowing(this.params);
        } catch {
            this.$notify({
                group: "app",
                type: "error",
                text: this.$t("ping_failed_fetch_follow_status"),
            });
        } finally {
            this.isLoading = false;
        }
    },
    methods: {
        async setFollowStatus(status) {
            try {
                await followService[status ? "follow" : "unfollow"](this.params);
                this.$notify({
                    group: "app",
                    type: "success",
                    text: i18n.t(status ? "ping_success_follow" : "ping_success_unfollow", ["post"]),
                });
                this.isFollowing = status;
            } catch {
                this.$notify({
                    group: "app",
                    type: "error",
                    text: i18n.t("ping_failed_to_set_follow_state"),
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .pu-skeleton {
        margin: $spacing-small 0;
    }
}
</style>
