/**
 * Predicate to filter out all fulfilled promises returned from `Promise.allSettled`.
 * Usually used together with {@link value}.
 *
 * @example
 * const fetching = […].map(params => fetch(…));
 * const fetchResults = await Promise.allSettled(fetching);
 * return fetchResults.filter(isFulfilled).map(value);
 */
export function isFulfilled<T>(promise: PromiseSettledResult<T>): promise is PromiseFulfilledResult<T> {
    return promise.status === "fulfilled";
}

/**
 * Mapping function to get the value of a filfulled promise result from `Promise.allSettled`.
 * A list is usually filtered by {@link isFulfilled} before.
 *
 * @example
 * const fetching = […].map(params => fetch(…));
 * const fetchResults = await Promise.allSettled(fetching);
 * return fetchResults.filter(isFulfilled).map(value);
 */
export function value<T>(promise: PromiseFulfilledResult<T>): T {
    return promise.value;
}
