export default {
    bind(el, binding) {
        el.__ClickBlur__ = e => {
            if (!(el === e.target || el.contains(e.target))) {
                binding.value(e);
            }
        };
        document.body.addEventListener("click", el.__ClickBlur__);
    },
    unbind(el) {
        document.body.removeEventListener("click", el.__ClickBlur__);
    }
};
