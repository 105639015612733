import { VuexRootState } from "@web/store";
import {
    Module,
    Store,
} from "vuex";
import {
    TimestampEvent,
    TimestampInFirestore,
} from "@web/store/timestamping/types";
import {
    UNREAD_NEWS,
    UNREAD_NEWS_COUNT,
    UNREAD_POST,
    UNREAD_POST_COMMENT,
    UNREAD_POST_COMMENT_COUNT,
    UNREAD_POST_COUNT,
    UNREAD_POST_OR_COMMENT,
    UNREAD_POST_OR_COMMENT_COUNT,
} from "@web/store/timestamping/getters";
import {
    START_OBSERVING_EVENT,
    STOP_OBSERVING_EVENT,
} from "@web/store/timestamping/actions";
import { firestore } from "@web/firebase-instance";
import { INTRANET_COLLECTION_IDENTIFIER } from "@web/firestore/collections";
import {
    HANDLE_EVENT,
    IGNORE_NEXT_EVENT,
    RESOLVE_EVENT,
    SET_UNSUBSCRIBE,
} from "@web/store/timestamping/mutations";
import router from "@web/router";
import { CommentableEntityType } from "@backend/comment/types";

/**
 * vuex module working as a bridge between firebase timestamp documents observing and web-client ui
 * components can inject the state to know if e.g. a news has been created to clear caches or to show this information to user
 *
 * @see action jsDocs
 * @see jsDocs under timestamping-operations.ts
 */
export const TIMESTAMP_MODULE_NAME = "timestamp/";

export interface TimestampingModuleState {
    timestamps: {
        [TimestampEvent.newsPublished]: TimestampInFirestore;
        [TimestampEvent.postPublished]: TimestampInFirestore;
        [TimestampEvent.newsCommentPublished]: TimestampInFirestore;
        [TimestampEvent.postCommentPublished]: TimestampInFirestore;
    };
    unsubscribe: {
        [TimestampEvent.newsPublished]?: () => void;
        [TimestampEvent.postPublished]?: () => void;
        [TimestampEvent.newsCommentPublished]?: () => void;
        [TimestampEvent.postCommentPublished]?: () => void;
    };
    count: {
        [TimestampEvent.newsPublished]: number;
        [TimestampEvent.postPublished]: number;
        [TimestampEvent.newsCommentPublished]: number;
        [TimestampEvent.postCommentPublished]: number;
    };
    ignoreCount: {
        [TimestampEvent.newsPublished]: number;
        [TimestampEvent.postPublished]: number;
        [TimestampEvent.newsCommentPublished]: number;
        [TimestampEvent.postCommentPublished]: number;
    },
    ignoreNextEvent: {
        [TimestampEvent.newsPublished]: boolean;
        [TimestampEvent.postPublished]: boolean;
        [TimestampEvent.newsCommentPublished]: boolean;
        [TimestampEvent.postCommentPublished]: boolean;
    }
}

const unknownTimestamp = {
    timestamp: {
        _seconds: 0,
        _nanoseconds: 0
    }
};

export const TIMESTAMP_MODULE: Module<TimestampingModuleState, VuexRootState> = {
    namespaced: true,
    state: {
        timestamps: {
            [TimestampEvent.newsPublished]: unknownTimestamp,
            [TimestampEvent.postPublished]: unknownTimestamp,
            [TimestampEvent.newsCommentPublished]: unknownTimestamp,
            [TimestampEvent.postCommentPublished]: unknownTimestamp,
        },
        unsubscribe: {
            [TimestampEvent.newsPublished]: undefined,
            [TimestampEvent.postPublished]: undefined,
            [TimestampEvent.newsCommentPublished]: undefined,
            [TimestampEvent.postCommentPublished]: undefined,
        },
        count: {
            [TimestampEvent.newsPublished]: 0,
            [TimestampEvent.postPublished]: 0,
            [TimestampEvent.newsCommentPublished]: 0,
            [TimestampEvent.postCommentPublished]: 0,
        },
        ignoreCount: {
            [TimestampEvent.newsPublished]: 0,
            [TimestampEvent.postPublished]: 0,
            [TimestampEvent.newsCommentPublished]: 0,
            [TimestampEvent.postCommentPublished]: 0,
        },
        ignoreNextEvent: {
            [TimestampEvent.newsPublished]: false,
            [TimestampEvent.postPublished]: false,
            [TimestampEvent.newsCommentPublished]: false,
            [TimestampEvent.postCommentPublished]: false,
        }
    },
    getters: {
        // exposes if there were any news published
        [UNREAD_NEWS](state): boolean {
            return count(state, TimestampEvent.newsPublished) > 0;
        },
        [UNREAD_NEWS_COUNT](state, { overallCount }: {overallCount: boolean}): number {
            return count(state, TimestampEvent.newsPublished, overallCount);
        },
        [UNREAD_POST](state): boolean {
            return count(state, TimestampEvent.postPublished) > 0;
        },
        [UNREAD_POST_COUNT](state, { overallCount }: {overallCount: boolean}): number {
            return count(state, TimestampEvent.postPublished, overallCount);
        },
        [UNREAD_POST_COMMENT](state): boolean {
            return count(state, TimestampEvent.postCommentPublished) > 0;
        },
        [UNREAD_POST_COMMENT_COUNT](state, { overallCount }: {overallCount: boolean}): number {
            return count(state, TimestampEvent.postCommentPublished, overallCount);
        },
        [UNREAD_POST_OR_COMMENT](state): boolean {
            return (count(state, TimestampEvent.postPublished) + count(state, TimestampEvent.postCommentPublished)) > 0;
        },
        [UNREAD_POST_OR_COMMENT_COUNT](state, { overallCount }: {overallCount: boolean}): number {
            return count(state, TimestampEvent.postPublished, overallCount) + count(state, TimestampEvent.postCommentPublished, overallCount);
        },
    },
    mutations: {
        // consider this as private mutation handling callbacks from firestore api
        [HANDLE_EVENT](state, { timestampEvent, timestampInFirestore }: { timestampEvent: TimestampEvent, timestampInFirestore: TimestampInFirestore }): void {
            if (!timestampEvent) throw new Error("Param timestampEvent is required");
            if (state.ignoreNextEvent[timestampEvent]) {
                state.ignoreCount[timestampEvent] += 1;
                state.ignoreNextEvent[timestampEvent] = false;
            }
            state.timestamps[timestampEvent] = timestampInFirestore;
            state.count[timestampEvent] += 1;
        },
        // this mutation "resolves" a specific timestamp event. commit this if you want to mark a certain event as "finished" or "done".
        // this for example gets called when a reload induced by the event is finished or the user triggered an according action
        [RESOLVE_EVENT](state, timestampEvent: TimestampEvent): void {
            if (!timestampEvent) throw new Error("Param timestampEvent is required");
            state.count[timestampEvent] = 0;
            state.ignoreCount[timestampEvent] = 0;
            state.ignoreNextEvent[timestampEvent] = false;
        },
        [IGNORE_NEXT_EVENT](state, { timestampEvent, ignore }: {timestampEvent: TimestampEvent, ignore: boolean}): void {
            if (!timestampEvent) throw new Error("Param timestampEvent is required");
            state.ignoreNextEvent[timestampEvent] = ignore !== undefined ? ignore : true;
        },
        [SET_UNSUBSCRIBE](state, { timestampEvent, unsubscribe }: { timestampEvent: TimestampEvent, unsubscribe: () => void }): void {
            state.unsubscribe[timestampEvent] = unsubscribe;
        }
    },
    actions: {
        // start observing a certain timestamp document, mapped using the TimestampEvent enum
        // should be call in a lifecycle hook of a vue component
        [START_OBSERVING_EVENT]({ state, rootState, commit }, timestampEvent: TimestampEvent): void {
            const intranet = rootState.intranet.intranet;
            let firstImmediateCall = true;
            const unsubscribe = getTimestampDocRef(intranet!.uid, timestampEvent).onSnapshot(docSnapshot => {
                if (firstImmediateCall) {
                    firstImmediateCall = false;
                    return;
                }
                if (!docSnapshot.exists) {
                    return;
                }
                commit(HANDLE_EVENT, { timestampEvent, timestampInFirestore: docSnapshot.data() });
            }, () => {
                console.warn("Could not register 'onSnapshot' for intranet. Intranet may have been deleted or user lost permission. Redirecting to login.");
                router.push("/");
            });
            commit(SET_UNSUBSCRIBE, { timestampEvent, unsubscribe });
        },
        // stop observing. especially important to stop listening to something intranet specific!!
        // should be call in a lifecycle hook of a vue component
        [STOP_OBSERVING_EVENT]({ state, commit }, timestampEvent: TimestampEvent): void {
            state.unsubscribe[timestampEvent]?.();
            commit(SET_UNSUBSCRIBE, { timestampEvent, unsubscribe: undefined });
        }
    },
};

function count(state: TimestampingModuleState, timestampEvent: TimestampEvent, overallCount?: boolean): number {
    if (overallCount) {
        return state.count[timestampEvent];
    }
    return state.count[timestampEvent] - state.ignoreCount[timestampEvent];
}

function getTimestampDocRef(intranetUid: string, timestampEvent: TimestampEvent) {
    return firestore.collection(INTRANET_COLLECTION_IDENTIFIER).doc(intranetUid).collection("timestamp").doc(timestampEvent);
}

// helper-methods to use in vue components lifecycle hooks
export function observeForTimestampEvent(s: Store<any>, timestampEvent: TimestampEvent): Promise<void> {
    return s.dispatch(TIMESTAMP_MODULE_NAME + START_OBSERVING_EVENT, timestampEvent);
}

export function stopObserveForTimestampEvent(s: Store<any>, timestampEvent: TimestampEvent): Promise<void> {
    return s.dispatch(TIMESTAMP_MODULE_NAME + STOP_OBSERVING_EVENT, timestampEvent);
}

export function timestampEventForContentType(contentType: "news" | "post"): TimestampEvent | undefined {
    if (contentType === CommentableEntityType.news) {
        return TimestampEvent.newsCommentPublished;
    }
    if (contentType === CommentableEntityType.post) {
        return TimestampEvent.postCommentPublished;
    }
}
