import { ApiClient } from "@web/api/ApiClient";
import { ApiClientBuilder } from "@web/api/ApiClientBuilder";
import { UserSearchService } from "@web/services/UserSearchService";
import {
    USER_INDEX_NAME,
    UserIndexEntity,
} from "@backend/index/types";
import dayjs from "dayjs";
import {
    dayjsFromDateYMD,
    findClosestAnniversary,
    getSortDateByClosenessComparator,
} from "@web/lib/time-utils";
import { DateYMD } from "@backend/user-profile/types";
import { SocialNotificationService } from "@web/services/social-notifications";
import { SocialNotificationType } from "@backend/common/notification/activity-types";

export interface SocialHappening {
    type: SocialNotificationType,
    date: dayjs.Dayjs,
    user: UserIndexEntity,
}

export class SocialHappeningsService {
    // no pagination is implemented as I don't expect more than 100 new employees to join within 2 weeks
    private static readonly PAGE_SIZE = 100;

    private readonly api: Promise<ApiClient>;
    private readonly searchService: UserSearchService;

    constructor(private readonly socialNotificationService: SocialNotificationService) {
        this.api = new ApiClientBuilder().build();
        this.searchService = new UserSearchService();
    }

    public async list(intranetUid: string, userUid: string): Promise<SocialHappening[]> {
        const results = await Promise.all([
            this.listNewEmployees(intranetUid, userUid),
            this.listBirthdays(intranetUid, userUid),
            this.listAnniversaries(intranetUid, userUid),
        ]);
        return this.sortHappenings(results.flat());
    }

    private async listNewEmployees(intranetUid: string, userUid: string): Promise<SocialHappening[]> {
        const now = dayjs();
        const results = await this.searchService.search({
            intranetUid,
            filters: `hiringDateTimestamp:${now.subtract(14, "days").valueOf()} TO ${now.valueOf()} ` +
                `AND NOT uid:"${userUid}"`,
            pageSize: SocialHappeningsService.PAGE_SIZE,
        });
        return this.filterByPreviousInteractions(
            intranetUid,
            userUid,
            results.hits.map(result => ({
                type: SocialNotificationType.newEmployee,
                date: dayjsFromDateYMD(result.hiringDate as DateYMD),
                user: result,
            })),
        );
    }

    private async listBirthdays(intranetUid: string, userUid: string): Promise<SocialHappening[]> {
        const now = dayjs();
        const results = await this.searchService.filterAnniversaryRange({
            facetName: "birthdayDatestampMD",
            intranetUid,
            from: now,
            to: now.add(1, "days"),
            pageSize: SocialHappeningsService.PAGE_SIZE,
            filterExtension: `NOT uid:"${userUid}"`,
        });
        return this.filterByPreviousInteractions(
            intranetUid,
            userUid,
            results.map(result => ({
                type: SocialNotificationType.birthday,
                date: findClosestAnniversary(dayjsFromDateYMD(result.birthday)),
                user: result,
            })),
        );
    }

    private async listAnniversaries(intranetUid: string, userUid: string): Promise<SocialHappening[]> {
        const now = dayjs();
        const results = await this.searchService.filterAnniversaryRange({
            intranetUid,
            facetName: "hiringDateDatestampMD",
            from: now.subtract(3, "days"),
            to: now.add(3, "days"),
            pageSize: SocialHappeningsService.PAGE_SIZE,
            filterExtension: `NOT uid:"${userUid}"`,
        });
        return this.filterByPreviousInteractions(
            intranetUid,
            userUid,
            results
                .map(result => ({
                    type: SocialNotificationType.anniversary,
                    date: findClosestAnniversary(dayjsFromDateYMD(result.hiringDate)),
                    user: result,
                }))
                .filter(result => result.date.year() - dayjsFromDateYMD(result.user.hiringDate).year() > 0),
        );
    }

    private sortHappenings(happenings: SocialHappening[]): SocialHappening[] {
        const now = dayjs();
        const sortDateByClosenessComparator = getSortDateByClosenessComparator(now);
        return happenings.sort((a, b) => sortDateByClosenessComparator(a.date, b.date));
    }

    private async filterByPreviousInteractions(
        intranetUid: string, userUid: string, happenings: SocialHappening[],
    ): Promise<SocialHappening[]> {
        const hasHappeningBeenInteractedWith = await Promise.all(
            happenings.map(async(happening) =>
                this.socialNotificationService
                    .getRecentlySentNotificationQuery({
                        intranetUid,
                        senderUid: userUid,
                        recipientUid: happening.user.uid,
                        notificationType: happening.type
                    })
                    .get()
                    .then(snapshot => !snapshot.empty),
            ),
        );
        return happenings.filter((_, index) => !hasHappeningBeenInteractedWith[index]);
    }
}
