<template>
    <div
        :class="{drag}"
        @dragover.prevent.stop.capture="onDragEnter"
        @dragleave.prevent.stop.capture="drag = false"
        @drop.prevent.stop.capture="onDrop"
    >
        <div class="editor">
            <RichTextEditor
                v-show="!hideEditor"
                ref="editor"
                :value="value"
                :edit-mode="editMode"
                :toolbar-id="toolbarId"
                :editor-classes="editorClasses"
                :embed-images="embedImages"
                :show-attachment-upload-button="attachmentManager != null"
                :show-header-buttons="true"
                :show-embed-unsplash-image-button="embedImages"
                :show-embed-uploaded-image-button="embedImages"
                :place-holder="editMode ? placeHolder : ''"
                class="rich-text-editor w-100 animated"
                :prevent-auto-focus="true"
                :truncate="truncate"
                @update:isEmpty="$emit('update:isEmpty', $event)"
                @file-paste="onFilePaste"
                @text-paste="onTextPaste"
                @input="$emit('input', $event)"
                @keydown.native.meta.s.prevent="$emit('save')"
                @keydown.native.ctrl.s.prevent="$emit('save')"
                @keydown.native.meta.enter.prevent="$emit('save')"
                @keydown.native.ctrl.enter.prevent="$emit('save')"
                @attachment-selected="onAttachmentSelected"
                @file-select="$emit('file-select')"
            />
        </div>
        <attachment-area
            v-if="attachmentManager"
            :attachment-manager="attachmentManager"
            :edit-mode="editMode"
            class="attachment-area"
        />
    </div>
</template>

<script>
import { emptyContent } from "@web/lib/quill/quill-utils";
import {
    AttachmentCountExceeded,
    AttachmentManager,
    FileSizeExceeded
} from "@web/components/attachments/attachment-manager";
import { handleTextPaste } from "@web/lib/text-paste-handling";
import RichTextEditor from "@web/components/editor/RichTextEditor";
import AttachmentArea from "@web/components/attachments/AttachmentArea";

function isImage(file) {
    return /^image\//.test(file.type);
}

/**
 * Wraps the `RichTextEditor` and adds an `AttachmentArea`. Takes care of handling file pasting and drag&drop.
 */
export default {
    name: "Editor",
    components: { RichTextEditor, AttachmentArea },
    props: {
        value: { type: Array, default: emptyContent },
        editMode: { type: Boolean, default: false },
        placeHolder: { type: String, required: true },
        /** @type {AttachmentManager} */
        attachmentManager: { type: Object, default: null },
        truncate: Boolean,
        hideEditor: { type: Boolean, default: false },
        isEmpty: Boolean,
        enableSharedContent: {
            type: Boolean,
            default: true,
        },
        toolbarId: String,
        editorClasses: Array,
        /** @type {(file: File) => Promise<{ downloadUrl: string }>} */
        imageEmbedUploadHandler: Function,
    },
    data() {
        return {
            drag: false,
        };
    },
    computed: {
        quill() {
            return this.$refs.editor.quill;
        },
        embedImages() {
            return !!this.imageEmbedUploadHandler;
        },
    },
    methods: {
        async addFiles(files) {
            if (!files.length) {
                return;
            }

            try {
                await this.attachmentManager.addFiles(files);
                this.$emit("files-added", files);
            } catch (e) {
                console.error(e);
                if (e instanceof AttachmentCountExceeded) {
                    this.$notify({
                        group: "app",
                        type: "error",
                        title: this.$t("attachment_upload_limit_reached_title"),
                        text: this.$t("attachment_upload_limit_reached_text", [AttachmentManager.MAX_FILE_COUNT]),
                    });
                } else if (e instanceof FileSizeExceeded) {
                    this.$notify({
                        group: "app",
                        type: "error",
                        title: this.$t("attachment_file_size_exceeded_title"),
                        text: this.$t("attachment_file_size_exceeded_text", [AttachmentManager.MAX_FILE_SIZE_MB]),
                    });
                } else {
                    this.$notify({
                        group: "app",
                        type: "error",
                        title: this.$t("attachment_upload_error_title"),
                        text: this.$t("attachment_upload_error_text"),
                    });
                }
            }
        },
        async onAttachmentSelected({ files, reset }) {
            if (this.embedImages) {
                const imageFiles = files.filter(file => isImage(file));
                imageFiles.forEach(file => this.embedImage(file));
                files = files.filter(file => !isImage(file));
            }
            await this.addFiles(files);
            if (reset) {
                reset();
            }
        },
        onDragEnter(event) {
            if (!this.editMode || !this.attachmentManager) return;
            this.drag = [...event.dataTransfer.items].map(item => item.kind).includes("file");
        },
        onTextPaste(text) {
            if (!this.enableSharedContent || !this.attachmentManager) return;

            const pasteResult = handleTextPaste(text, this.$router);
            if (pasteResult) {
                this.attachmentManager.addSharedContent(pasteResult);
            }
        },
        onFilePaste(files) {
            // For some reason this timeout is needed after pasting a file and for some reason $nextTick won't do the job...
            setTimeout(() => this.onAttachmentSelected({ files: files }), 1);
        },
        async onDrop(event) {
            if (!this.editMode) return;
            this.drag = false;
            const files = Array.from(event.dataTransfer.files);
            if (files.length > 0) {
                await this.onAttachmentSelected({ files });
            }
        },
        embedImage(file) {
            const quill = this.quill;
            const range = quill.getSelection();
            const upload = this.imageEmbedUploadHandler({ file, quill });
            quill.insertEmbed(range ? range.index : 0, "image", {
                asyncUpload: upload,
            });
        },
        reset(initialValue) {
            this.$refs.editor.setContents(initialValue || emptyContent());
            this.attachmentManager.reset();
        },
        focus() {
            this.$refs.editor.focus();
        },
        blur() {
            this.$refs.editor.blur();
        },
    }
};
</script>

<style lang="scss" scoped>
.drag {
    opacity: 0.5;
}

.editor {
    flex-direction: column;
    word-break: break-word;
}
</style>
