<template>
    <div
        v-tooltip.bottom="tooltipMessage"
        class="button-background hey-five-button"
    >
        <app-button
            :type="type"
            :disabled="!recipient || !isUserEligibleToSend"
            @click="openCreateModal"
        >
            <span>{{ emoji }}</span>
            <span class="mleft-xsmall hey-five-button-text">{{ $t("user_profile_head_send_hey_five") }}</span>
        </app-button>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { AUTH_MODULE_NAME } from "@/store/auth/auth";
import { CURRENT_USER } from "@/store/auth/getters";
import { socialNotificationService } from "@/services";
import { INTRANET_MODULE_NAME } from "@/store/intranet/intranet";
import { INTRANET_UID } from "@/store/intranet/getters";
import SendHeyFiveForm from "@/components/social-notifications/SendHeyFiveForm";
import { SocialNotificationType } from "@backend/common/notification/activity-types";
import { SOCIAL_NOTIFICATION_TYPE_EMOJI_MAP } from "@/components/social-notifications/config";

export default {
    name: "SendHeyFiveButton",
    props: {
        /**
         * user the social notification should be sent to
         *
         * @type {BaseUserProfile}
         */
        recipient: {
            type: Object,
        },
        /**
         * EverestButton type which is used
         */
        type: {
            type: String,
            default: "light",
        },
    },
    data() {
        return {
            isUserEligibleToSend: false,
            unsubscribeHandler: undefined,
        };
    },
    computed: {
        ...mapGetters({
            currentUser: AUTH_MODULE_NAME + CURRENT_USER,
            intranetUid: INTRANET_MODULE_NAME + INTRANET_UID,
        }),
        emoji() {
            return SOCIAL_NOTIFICATION_TYPE_EMOJI_MAP[SocialNotificationType.heyFive];
        },
        tooltipMessage() {
            if (!this.recipient || this.isUserEligibleToSend) {
                return "";
            }
            return this.$t("user_profile_hey_five_already_sent", [this.recipient.displayName]);
        },
    },
    watch: {
        recipient: {
            handler() {
                this.init();
            },
            immediate: true,
        },
    },
    destroyed() {
        if (this.unsubscribeHandler) {
            this.unsubscribeHandler();
        }
    },
    methods: {
        async init() {
            if (this.unsubscribeHandler) {
                this.unsubscribeHandler();
            }
            if (!this.recipient) {
                return;
            }
            if (this.currentUser.uid === this.recipient.uid) {
                return;
            }
            this.unsubscribeHandler = await socialNotificationService
                .getRecentlySentNotificationQuery({
                    intranetUid: this.intranetUid,
                    senderUid: this.currentUser.uid,
                    recipientUid: this.recipient.uid,
                    notificationType: SocialNotificationType.heyFive,
                    onlyUnread: true,
                })
                .onSnapshot(snapshot => this.isUserEligibleToSend = snapshot.empty);
        },
        openCreateModal() {
            this.$modal.show("modal", {
                component: SendHeyFiveForm,
                props: {
                    recipient: this.recipient,
                },
                modalProps: {
                    title: this.$t("social_notification_send_hey_five_title", [this.recipient.displayName]),
                    confirmText: this.$t("send"),
                },
                callback: (confirmed) => {
                    if (confirmed) {
                        this.isUserEligibleToSend = false;
                    }
                },
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.button-background {
    background: var(--background);
    border-radius: 2rem;
}
</style>
