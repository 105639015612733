<template>
    <div
        v-if="activated"
        id="application-information"
        :open="opened"
        @click="opened = !opened"
    >
        <div v-if="!opened">
            <span v-if="gitIsDirty">DEV BUILD (rev unknown)</span>
            <span v-else>{{ everestVersion }}</span>
        </div>
        <div
            v-else
        >
            <span><b>Linchpin Everest Web Client ({{ everestVersion }})</b></span>
            <br/>
            <span v-if="gitIsDirty">DEV BUILD (rev unknown), uncommited changes on {{ gitCommitShort }} ({{ gitCommitDate }})</span>
            <span v-else>Commit: {{ gitCommit }} (#{{ gitCommitCount }}) ({{ gitCommitDate }})</span>
            <br/>
            <span>ProjectId: {{ projectId }}</span>
            <br/>
            <span>HostUrl: {{ hostUrl }}</span>
        </div>
    </div>
</template>

<script>
/**
 * shows application information like commit hash in web applications footer
 */
import Vue from "vue";
import {
    firebaseConfig,
    hostUrl,
} from "@web/firebaseConfig";

export default Vue.extend({
    name: "ApplicationInformation",
    data() {
        return {
            activated: false,
            opened: true,
        };
    },
    computed: {
        everestVersion() {
            if (this.branch === "master") {
                return EVEREST_VERSION;
            }
            return this.branch;
        },
        gitCommit: () => EVEREST_GIT_COMMIT,
        gitCommitShort: () => EVEREST_GIT_COMMIT_SHORT,
        gitCommitDate: () => EVEREST_GIT_COMMIT_DATE,
        gitCommitCount: () => EVEREST_GIT_COMMIT_COUNT,
        branch: () => EVEREST_GIT_BRANCH,
        gitIsDirty: () => EVEREST_GIT_IS_DIRTY,
        hostUrl: () => hostUrl,
        projectId: () => `${firebaseConfig.projectId}`,
    },
    mounted() {
        document.addEventListener("keydown", event => {
            // shortcut: alt + I
            if (event.altKey && event.keyCode === 73) {
                this.activated = true;
            }
        });
    },
});
</script>

<style lang="scss" scoped>
#application-information {
    position: fixed;
    background-color: var(--background, #{$white});
    opacity: 0.8;
    z-index: 10000;
    width: auto;
    text-align: right;
    bottom: 10px;
    right: 0;
    font-size: 11px;
    user-select: none;
    padding: 10px 15px 10px 10px;
    transform: translateX(100%);
    transition: transform .3s ease;

    &::before {
        content: "i";
        position: absolute;
        left: -30px;
        top: 0;
        padding: 10px 6px 4px 6px;
        background: var(--foreground, #{$black});
        color: var(--background, #{$white});
        font-weight: bold;
        font-size: 20px;
        width: 20px;
        height: 30px;
        text-align: center;
        border-radius: var(--border-radius) 0 0 5px;
    }

    &:hover, &[open] {
        opacity: 1;
        transform: translateX(0);
    }

    &[open] {
        background-color: var(--highest-contrast, #{$dark-grey});
        color: var(--background, #{$white});
    }
}
</style>
