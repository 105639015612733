<template>
    <div class="page">
        <div class="container padded">
            <page-header :title="$t('events')">
                <app-button
                    v-if="activeTab === 'event-overview'"
                    :class="{ disabled: loading }"
                    type="primary"
                    icon="plus"
                    @click="triggerCreateEvent"
                >
                    {{ $t("events_create") }}
                </app-button>
                <app-button
                    v-if="activeTab === 'event-overview'"
                    type="light"
                    icon="calendar"
                    @click="openDrawer"
                >
                    {{ $t("events_your_events") }}
                </app-button>
            </page-header>
            <tabs :links="tabs"/>
            <router-view
                key="tabs"
                pbottom-large
                @tab-changed="activeTab = $event"
            />
        </div>
        <Drawer
            class="events-drawer"
            name="events-drawer"
            size="medium"
        >
            <SidebarHeader :title="$t('events_created_by_you')">
                <app-button
                    :class="{ disabled: loading }"
                    type="primary"
                    icon="plus"
                    @click="triggerCreateEvent"
                >
                    {{ $t("events_create") }}
                </app-button>
            </SidebarHeader>
            <EventList
                mode="sidebar"
                event-list-key="myCreatedEvents"
            >
                <template v-slot:no-events>
                    <SystemMessage
                        v-if="true"
                        type="drafts"
                        :title="$t('events_no_events_title')"
                        :text="$t('events_no_events_text')"
                    />
                </template>
            </EventList>
        </Drawer>
    </div>
</template>

<script>

import PageHeader from "@web/components/PageHeader";
import Tabs from "@web/components/Tabs";
import SidebarHeader from "@web/components/SidebarHeader";
import Drawer from "@web/components/modals/Drawer";
import IntentHandlerMixin from "@web/mixins/IntentHandlerMixin";
import {
    mapActions,
    mapGetters,
} from "vuex";
import { EVENT_MODULE_NAME } from "@web/store/event/event";
import { GET_CURRENT_EVENT } from "@web/store/event/getters";
import { CREATE_EVENT } from "@web/store/event/actions";
import { analytics } from "@web/analytics";
import { getGlobalConfiguration } from "@web/global-config";
import EventList from "@web/views/events/EventList";

export default {
    name: "Events",
    components: {
        PageHeader,
        Tabs,
        SidebarHeader,
        Drawer,
        EventList,
    },
    mixins: [IntentHandlerMixin],
    data() {
        return {
            tabs: [
                { to: "event-overview", title: this.$t("overview") },
                { to: "social", title: this.$t("social_happenings") },
            ],
            loading: false,
            activeTab: "",
        };
    },
    computed: {
        ...mapGetters({
            currentEvent: EVENT_MODULE_NAME + GET_CURRENT_EVENT,
        }),
    },
    methods: {
        ...mapActions({
            createEvent: EVENT_MODULE_NAME + CREATE_EVENT,
        }),
        async triggerCreateEvent() {
            await this.createEvent();
            const eventUid = this.currentEvent.uid;
            await this.$router.push("/intranet/" + this.$store.getters["intranet/intranet"].uid + "/event/" + eventUid + "?edit=true");
        },
        async openDrawer() {
            this.openModal("events-drawer");
            analytics.log(getGlobalConfiguration().analytics_event_name_events_created_overview_opened);
        },
        openModal(name) {
            this.$modal.show(name);
        },
        handleCreateEventIntent() {
            this.triggerCreateEvent();
        },
        changeTab(activeTab) {
            this.activeTab = activeTab;
            if (activeTab === "event-overview") {
                analytics.log(getGlobalConfiguration().analytics_event_name_events_overview_opened);
            } else {
                analytics.log(getGlobalConfiguration().analytics_event_name_social_overview_opened_from_event_page);
            }
        }
    },
};
</script>

<style lang="scss" scoped>
</style>
