import { hostUrl } from "@web/firebaseConfig";
import { MailInvite } from "@web/store/invite/types";
import { ApiClientBuilder } from "@web/api/ApiClientBuilder";
import { ApiClient } from "@web/api/ApiClient";
import {
    IntranetAccessCredentials,
    IntranetAccessInfo,
} from "@backend/invite/types";
import axios,
{ AxiosResponse } from "axios";
import { cloudRunUrl } from "@web/cloud-run-url";

const publicBasePath = `${cloudRunUrl.invite}/api/public/invite`;
const basePath = `${cloudRunUrl.invite}/api/invite`;

export class InviteService {
    public static readonly inviteCreationEndpoint = `${cloudRunUrl.invite}/api/invite/`;
    public static readonly PAGE_SIZE = 7;

    private readonly api: Promise<ApiClient>;

    constructor() {
        this.api = new ApiClientBuilder().build();
    }

    private buildRedeemPath(intranetUid: string, token: string): string {
        return `${basePath}/redeem?intranetUid=${intranetUid}&token=${token}`;
    }

    public async redeemInvite(intranetUid: string, token: string): Promise<void> {
        const client = await this.api;
        await client.get(this.buildRedeemPath(intranetUid, token));
    }

    public async createMailInviteForIntranet(intranetUid: string, email: string): Promise<AxiosResponse> {
        const client = await this.api;
        return client.post(InviteService.inviteCreationEndpoint + intranetUid, { email });
    }

    public async revokeInviteByIdForIntranet(intranetUid: string, uid: string): Promise<void> {
        const client = await this.api;
        await client.delete(InviteService.inviteCreationEndpoint + intranetUid + "/" + uid);
    }

    public async getInvitesForIntranet(intranetUid: string, offset: number, searchTerm: string | undefined): Promise<MailInvite[]> {
        const client = await this.api;
        const response = await client.get<MailInvite[]>(InviteService.inviteCreationEndpoint + "list/" + intranetUid, {
            params: {
                offset: offset || 0,
                limit: InviteService.PAGE_SIZE,
                searchTerm,
            },
        });
        return response.data;
    }

    public async getInviteAccessInfo(intranetUid: string, inviteId: string): Promise<IntranetAccessInfo> {
        const response = await axios.get<IntranetAccessInfo>(
            `${publicBasePath}/access-info/${intranetUid}/invite/${inviteId}`,
        );
        return response.data;
    }

    // this is the url to invite an user with. It will lead the user to a page where the above url will be called to actually redeem the invite
    public static buildInviteUrl(invite: MailInvite, intranetUid: string): string {
        return `${hostUrl}/redeem-invite?intranetUid=${intranetUid}&token=${invite.inviteId}`;
    }
}

interface BuildJoinUrlParams {
    intranetUid: string;
    accessCode: string;
    verifiedUser?: boolean;
}

interface BuildPublicAccessCodeParams {
    intranetUid: string;
    accessCode: string;
}

export class CodeBasedIntranetAccessService {
    private readonly api: Promise<ApiClient>;

    constructor() {
        this.api = new ApiClientBuilder().build();
    }

    public async getCodeAccessInfo(intranetUid: string, accessCode: string): Promise<IntranetAccessInfo> {
        const response = await axios.get<IntranetAccessInfo>(
            `${publicBasePath}/access-info/${intranetUid}/code/${accessCode}`,
        );
        return response.data;
    }

    public async getIntranetAccessCredentials(intranetUid: string): Promise<IntranetAccessCredentials> {
        const client = await this.api;
        const response = await client.get<IntranetAccessCredentials>(buildAccessCredentialsPath(intranetUid));
        return response.data;
    }

    public async enableCodeBasedAccess(intranetUid: string): Promise<IntranetAccessCredentials> {
        const client = await this.api;
        const response = await client.post<IntranetAccessCredentials>(buildAccessCredentialsPath(intranetUid));
        return response.data;
    }

    public async disableCodeBasedAccess(intranetUid: string): Promise<void> {
        const client = await this.api;
        await client.delete(buildAccessCredentialsPath(intranetUid));
    }

    public async joinWithAccessCode(intranetUid: string, accessCode: string): Promise<void> {
        const client = await this.api;
        await client.post(buildCodeBasedAccessPath(intranetUid, accessCode));
    }

    public static buildJoinUrl({ intranetUid, accessCode, verifiedUser = false }: BuildJoinUrlParams): string {
        let url = `${hostUrl}/join?intranetUid=${intranetUid}&accessCode=${accessCode}`;
        if (verifiedUser) {
            url += "&verified=true";
        }
        return url;
    }

    public static buildPublicAccessCode({ intranetUid, accessCode }: BuildPublicAccessCodeParams): string {
        return `${intranetUid}/${accessCode}`;
    }
}

function buildCodeBasedAccessPath(intranetUid: string, accessCode: string): string {
    return `${basePath}/code-based-intranet-access/${intranetUid}/${accessCode}`;
}

function buildAccessCredentialsPath(intranetUid: string): string {
    return `${basePath}/access-codes/${intranetUid}`;
}
