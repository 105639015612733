export function validateFileInputEvent(event: Event): Array<File> {
    const target = event.target as HTMLInputElement;
    if (target == null || target.files == null || target.files.length <= 0) {
        return [];
    }
    return Array.from(target.files);
}

const FILL_HEIGHT_THRESHOLD = 1;
const CROP_THRESHOLD = 1.8;

export enum DisplayMode {
    fillWidth = "_FILL_WIDTH",
    fillHeight = "_FILL_HEIGHT",
    crop = "_CROP",
}

export async function getDisplayMode(imageSrc: string): Promise<DisplayMode> {
    const img = await getImage(imageSrc);
    return calcDisplayMode(img);
}

export function getImage(src: string): Promise<HTMLImageElement> {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.onload = () => resolve(img);
        img.onerror = reject;
        img.src = src;
    });
}

function calcDisplayMode(img: HTMLImageElement): DisplayMode {
    const { height, width } = img;
    const ratio = height / width;
    if (ratio > CROP_THRESHOLD) {
        return DisplayMode.crop;
    }
    if (ratio > FILL_HEIGHT_THRESHOLD) {
        return DisplayMode.fillHeight;
    }
    return DisplayMode.fillWidth;
}
