<template>
    <LoadingWrapper :is-loading="isLoadingEvent">
        <SystemMessage
            v-if="error"
            :type="error.type"
        />
        <section
            v-if="!error && event"
            v-click-blur="closeModal"
            :class="modal ? 'modal' : ''"
            class="event container"
        >
            <header
                v-global-page-title="event.title"
                class="event-header"
            >
                <div
                    :style="`background-image:url(${coverImageUrl})`"
                    class="event-visual background-low-contrast"
                ></div>
                <a
                    v-if="isEditingAllowed"
                    class="edit-event"
                    @click="triggerEdit()"
                >
                    <Icon
                        color="white"
                        name="edit"
                    />
                </a>

                <section
                    v-if="!isLoadingEvent"
                    class="event-meta"
                >
                    <div class="title-container">
                        <EventDateTile
                            :date="event.startTimestamp"
                            size="big"
                        />
                        <h3>{{ event.title }}</h3>
                    </div>
                    <div class="attending-container">
                        <event-attend-button :event="event"/>
                    </div>
                </section>
            </header>
            <div class="main">
                <div class="grid">
                    <div class="col two-thirds">
                        <h3>{{ $t("events_about") }}</h3>
                        <RichTextEditor
                            v-show="!isDescriptionEmpty"
                            :is-empty.sync="isDescriptionEmpty"
                            :value="event.description"
                        />
                        <div class="event-infos">
                            <IconTextBox
                                :icon="'clock'"
                                :title="displayDate"
                            >
                                {{ displayTime }}
                                <event-attend-badge :event="event"/>
                            </IconTextBox>
                            <IconTextBox
                                v-if="isFeaturePreviewActive"
                                :icon="'pin'"
                                :title="'London HQ'"
                            >
                                London HQ10 Whitehall Place, SW1A 2BD London
                            </IconTextBox>
                            <IconTextBox
                                :icon="'user'"
                                :title="$t('events_created_by')"
                            >
                                <loading-content-author
                                    :user-uid="event.creatorUid"
                                    linked
                                />
                            </IconTextBox>
                        </div>
                    </div>
                    <div class="col third">
                        <div class="attendee-header">
                            <h3>{{ $t("events_attendees") }} ({{ event.attendees }})</h3>
                        </div>
                        <EventAttendeeList :event="event"/>
                    </div>
                </div>
            </div>
        </section>
    </LoadingWrapper>
</template>

<script>
import { analytics } from "@web/analytics";
import { getGlobalConfiguration } from "@web/global-config";
import {
    FEATURE_PREVIEW_ACTIVE,
    IS_ADMIN,
} from "@web/store/intranet/getters";
import { AUTH_MODULE_NAME } from "@web/store/auth/auth";
import {
    mapActions,
    mapGetters,
} from "vuex";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import IconTextBox from "@web/components/IconTextBox";
import EventDateTile from "@web/components/events/EventDateTile";
import { EVENT_MODULE_NAME } from "@web/store/event/event";
import {
    GET_CURRENT_EVENT,
    GET_CURRENT_USER_ATTENDEE_STATUS,
} from "@web/store/event/getters";
import {
    formatDate,
    timeForRange,
} from "@web/lib/time-utils";
import { EverestEventAttendeeStatus } from "@backend/event/types";
import {
    JOIN_EVENT,
    LEAVE_EVENT,
    LOAD_CURRENT_EVENT,
} from "@web/store/event/actions";
import EventAttendeeList from "@web/views/events/EventAttendeeList";
import { CURRENT_USER } from "@web/store/auth/getters";
import { PROFILE_MODULE_NAME } from "@/store/profile/profile";
import { GET_USER_PROFILE } from "@/store/profile/getters";
import { FETCH_PROFILES_FOR_USER_UIDS } from "@/store/profile/actions";
import RichTextEditor from "@web/components/editor/RichTextEditor";
import EventAttendButton from "@web/components/events/EventAttendButton";
import LoadingContentAuthor from "@web/components/user/LoadingContentAuthor";
import EventAttendBadge from "@web/components/events/EventAttendBadge";

export default {
    components: {
        EventAttendBadge,
        LoadingContentAuthor,
        EventAttendButton,
        EventAttendeeList,
        EventDateTile,
        IconTextBox,
        RichTextEditor,
    },
    props: {
        eventUid: String,
        modal: Boolean,
    },
    data() {
        return {
            attendeeStatus: "NOT_ATTENDING",
            isLoadingEvent: false,
            error: null,
            isDescriptionEmpty: true,
        };
    },
    computed: {
        ...mapGetters({
            isFeaturePreviewActive: INTRANET_MODULE_NAME + FEATURE_PREVIEW_ACTIVE,
            getAttendeeStatus: EVENT_MODULE_NAME + GET_CURRENT_USER_ATTENDEE_STATUS,
            event: EVENT_MODULE_NAME + GET_CURRENT_EVENT,
            isAdmin: INTRANET_MODULE_NAME + IS_ADMIN,
            currentUser: AUTH_MODULE_NAME + CURRENT_USER,
            getUser: PROFILE_MODULE_NAME + GET_USER_PROFILE,
        }),
        isAttending() {
            return this.getAttendeeStatus(this.event) === EverestEventAttendeeStatus.attending;
        },
        displayTime() {
            return timeForRange(this.event.startTimestamp, this.event.endTimestamp);
        },
        displayDate() {
            return formatDate(this.event.startTimestamp, "LL");
        },
        isEditingAllowed() {
            return this.isAdmin || (this.event && this.currentUser && (this.event.creatorUid === this.currentUser.uid));
        },
        coverImageUrl() {
            if (this.event && this.event.coverImagePath) {
                return this.event.coverImagePath;
            }
            return "/event-bg.jpg";
        },
    },
    async mounted() {
        this.isLoadingEvent = true;
        try {
            await this.loadCurrentEvent({ eventUid: this.eventUid });
            analytics.log(getGlobalConfiguration().analytics_event_name_event_single_view_opened);
        } finally {
            this.isLoadingEvent = false;
        }
    },
    methods: {
        ...mapActions({
            loadCurrentEvent: EVENT_MODULE_NAME + LOAD_CURRENT_EVENT,
            joinEvent: EVENT_MODULE_NAME + JOIN_EVENT,
            leaveEvent: EVENT_MODULE_NAME + LEAVE_EVENT,
            fetchUserProfiles: PROFILE_MODULE_NAME + FETCH_PROFILES_FOR_USER_UIDS,
        }),
        triggerEdit() {
            this.$emit("on-edit");
        },
        closeModal() {
            if (this.modal) {
                this.$modal.hide("event-modal");
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.event-header {
    position: sticky;
    top: -1.5rem;
    margin-bottom: 2rem;
    background: var(--background, #{$white});
    z-index: 1;

    .event-meta {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        padding-bottom: 1.5rem;
        border-bottom: 1px solid #eee;

        .title-container {
            display: flex;
            align-items: flex-start;
            flex-wrap: nowrap;
            flex: 1 1 auto;
            width: 100%;

            .date-tile {
                margin-right: 1rem;
            }

            h3 {
                padding: 0 1rem 0 0;
                margin: 0;
            }
        }

        .attending-container {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            flex: 0 1 auto;
        }
    }

    .event-visual {
        width: calc(100% + 3rem);
        height: clamp(6rem, 25vh, 14rem);
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        filter: brightness(0.8);
        margin: -1.5rem -1.5rem 1.5rem;
    }

    .edit-event {
        cursor: pointer;
        position: absolute;
        top: 1.5rem;
        right: 0;
    }
}

.main {
    h3 {
        margin-bottom: .4rem;
    }
}

.event-infos {
    margin-top: 2rem;
}

.attendee-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;

    h3 {
        margin: 0;
    }
}

.attending-text {
    display: flex;
    align-items: center;
    padding-right: 1rem;
    color: var(--primary);
    --icon-color: var(--primary);

    .icon {
        margin-right: 0.3rem;
    }
}

.modal.event {
    background: var(--background, #{$white});
    color: var(--foreground, #{$black});
    margin-top: 50px;
    padding: 1.5rem;
    max-width: 800px;
    border-radius: var(--border-radius);
    overflow: hidden;
}

</style>
