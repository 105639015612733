<template>
    <div class="grid">
        <single-media-attachment
            :key="firstAttachment.localUid"
            class="attachment"
            :attachment="firstAttachment"
            :edit-mode="editMode"
            :multi="2"
            @delete="$emit('delete', firstAttachment)"
            @click.native="openLightbox(0)"
        />
        <single-media-attachment
            :key="secondAttachment.localUid"
            class="attachment"
            :attachment="secondAttachment"
            :edit-mode="editMode"
            :multi="2"
            @delete="$emit('delete', secondAttachment)"
            @click.native="openLightbox(1)"
        />
    </div>
</template>

<script>
import SingleMediaAttachment from "@web/components/attachments/SingleMediaAttachment";
export default {
    name: "TwoMediaAttachments",
    components: { SingleMediaAttachment },
    props: {
        firstAttachment: Object,
        secondAttachment: Object,
        editMode: Boolean,
    },
    methods: {
        openLightbox(index) {
            this.$emit("open-lightbox", index);
        }
    }
};
</script>

<style lang="scss" scoped>
.grid {
    margin-left: 0;
    margin-right: 0;
    .attachment {
        margin: 0;
    }
}
</style>
