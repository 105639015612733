import { base64toBlob } from "@web/services/file-upload";
import { compressImage } from "@web/lib/image-compression";

export async function filesFromPaste(event: ClipboardEvent): Promise<Array<Blob | File>> {
    if (event.clipboardData && event.clipboardData.items && event.clipboardData.items.length) {
        const imageFiles = [...event.clipboardData.items].filter((item) => item.kind === "file");
        if (imageFiles.length > 0) {
            return imageFiles.map((dataTransferItem) => dataTransferItem.getAsFile()!);
        }

        const text = event.clipboardData?.getData("text");
        if (!text || !text.length) {
            return [];
        }

        try {
            const imageBlob = base64toBlob(text);
            return [imageBlob];
        } catch (e) {
            return [];
        }
    }
    return [];
}
