<template>
    <div>
        <audio
            ref="sound"
            src="@web/assets/sounds/slap.mp3"
            preload="auto"
        ></audio>

        <transition
            name="slideInBottom"
            appear
            @after-leave="$emit('after-interaction', { interactionCompleted: slap })"
        >
            <div
                v-show="show"
                class="hey-five-container"
            >
                <div
                    v-show="slapFinished"
                    class="slap-effect"
                    v-html="require('@web/assets/interactions/hey_five_slap_effect.svg')"
                ></div>
                <div
                    class="hand clickable"
                    @click="startSlap"
                    v-html="require('@web/assets/interactions/hey_five_receiver.svg')"
                ></div>
                <transition
                    name="slap"
                    @after-enter="teardown"
                >
                    <div
                        v-show="slap"
                        class="hand giver"
                        v-html="require('@web/assets/interactions/hey_five_giver.svg')"
                    ></div>
                </transition>
            </div>
        </transition>
    </div>
</template>

<script>
import { wait } from "@web/lib/wait";

export default {
    name: "HeyFiveInteraction",
    props: {
        show: {
            type: Boolean,
            default: true,
        },
    },
    data() {
        return {
            slap: false,
            slapFinished: false,
        };
    },
    methods: {
        async startSlap() {
            const originalDocumentOverflowX = document.body.style.overflowX;
            try {
                document.body.style.overflowX = "hidden";
                this.slap = true;
                await wait(5650);
                this.$refs.sound.play();
                await wait(150);
                this.slapFinished = true;
            } finally {
                document.body.style.overflowX = originalDocumentOverflowX;
            }
        },
        async teardown() {
            await wait(800);
            this.$emit("update:show", false);
        },
    },
};
</script>

<style lang="scss" scoped>
.slap-enter-active,
.slap-leave-active {
    transform-origin: bottom left;
}

.slap-enter,
.slap-leave-to {
    transform: rotate(60deg) translateY(50vh) translateX(80vw) scale(8);
}

@keyframes slapEffect {
    0% {
        transform: translate(-5rem, 15rem) scale(0);
    }
    20% {
        opacity: 1;
        transform: initial;
    }
    100% {
        transform: translate(1rem, -3rem) scale(1.2);
        opacity: 0;
    }
}

.hey-five-container {
    display: flex;
    position: relative;
    transition: all 0.5s cubic-bezier(0.5, -0.25, 0.5, 1.25);

    @media (min-width: $breakpoint-giant) {
        margin-top: $spacing-medium;
    }

    ::v-deep svg {
        filter: drop-shadow(0px 0px 1px rgb(0 0 0));
    }

    .hand {
        transition: all 0.8s ease-in, filter 0.2s linear;

        &:hover {
            filter: drop-shadow(0px 0px 10px #fad94fc0);
        }

        ::v-deep svg {
            height: 30rem;

            @media (min-width: $breakpoint-giant) {
                height: 35rem;
            }
        }

        &.giver {
            position: absolute;
        }
    }

    .slap-effect {
        position: absolute;
        height: 13rem;
        top: -8rem;
        right: 1rem;
        animation: slapEffect 1s linear forwards;

        ::v-deep svg {
            height: 10rem;

            @media (min-width: $breakpoint-giant) {
                height: 13rem;
            }
        }
    }
}

</style>
