<template>
    <div>
        <avatar-list
            mode="list"
            :user-uids="attendeesForEvent"
        />
        <Button
            v-if="nextPage"
            type="outline"
            :busy="loadingMore"
            @click="triggerLoadMoreEvents"
        >
            {{ $t('load_more') }}
        </Button>
    </div>
</template>

<script>
import {
    mapActions,
    mapGetters
} from "vuex";
import { EVENT_MODULE_NAME } from "@web/store/event/event";
import {
    GET_ATTENDEES_FOR_EVENT,
    HAS_NEXT_ATTENDEES_FOR_EVENT
} from "@web/store/event/getters";
import { FETCH_ATTENDEES_FOR_CURRENT_EVENT } from "@web/store/event/actions";
import AvatarList from "@web/components/AvatarList";
import Button from "@web/components/Button";
import { analytics } from "@web/analytics";
import { getGlobalConfiguration } from "@web/global-config";

export default {
    name: "EventAttendeeList",
    components: { AvatarList, Button },
    props: {
        event: {
            type: Object
        }
    },
    data() {
        return {
            loadingMore: false,
            isNextPage: true
        };
    },
    computed: {
        ...mapGetters({
            getAttendeesForEvent: EVENT_MODULE_NAME + GET_ATTENDEES_FOR_EVENT,
            hasNextPage: EVENT_MODULE_NAME + HAS_NEXT_ATTENDEES_FOR_EVENT,
        }),
        nextPage() {
            return this.hasNextPage(this.event);
        },
        attendeesForEvent() {
            const attendees = this.getAttendeesForEvent(this.event).map(attendee => attendee.userUid);
            return attendees || [];
        }
    },
    methods: {
        ...mapActions({
            fetchAttendeesForEvent: EVENT_MODULE_NAME + FETCH_ATTENDEES_FOR_CURRENT_EVENT,
        }),
        async triggerLoadMoreEvents() {
            await this.loadMoreEvents();
            analytics.log(getGlobalConfiguration().analytics_event_name_event_attendees_load_more);
        },
        async loadMoreEvents({ reloadAttendees = false } = {}) {
            this.loadingMore = true;
            await this.fetchAttendeesForEvent({ event: this.event, reloadAttendees: reloadAttendees });
            this.loadingMore = false;
        }
    },
    async created() {
        await this.loadMoreEvents({ reloadAttendees: true });
    }
};
</script>

<style scoped>

</style>
