
export class SubscriptionOnIntersection {
    private intersectionObserver: IntersectionObserver;
    private readonly element: Element;
    private readonly subscribe: () => void;
    private readonly unsubscribe: () => void;

    constructor({ element, subscribe, unsubscribe }: { element: Element, subscribe: () => void, unsubscribe: () => void }) {
        this.element = element;
        this.subscribe = subscribe;
        this.unsubscribe = unsubscribe;
        this.intersectionObserver = new IntersectionObserver((entries) => {
            // If intersectionRatio is 0, the target is out of view
            // and the snapshot listener should be deactivated.
            if (entries[0].intersectionRatio <= 0) {
                this.unsubscribe();
            }
            /** Activate snapshot listener when entering viewport. */
            try {
                this.subscribe();
            } catch (e) {
                console.error("failed to register live update listener for reactions: ", e);
            }
        });
    }

    init(): void {
        this.intersectionObserver.observe(this.element);
    }

    destroy(): void {
        this.intersectionObserver.unobserve(this.element);
    }
}
