<template>
    <div :class="['avatar-list', 'half', mode]">
        <Spinner v-if="showLoadingSpinner && useSpinner" />
        <ul v-if="!showLoadingSpinner">
            <transition-group :name="transitionName">
                <li
                    v-for="(user, idx) in userProfiles"
                    :key="user.uid"
                    v-tooltip.bottom="{ content: userToolTip(user), html: true }"
                    class="user-row"
                    :class="{'zoom-on-hover-trigger': zoomOnHover}"
                    @click="$emit('clickOnUser')"
                >
                    <user-link
                        :uid="user.uid"
                        :v-card-on-hover="vCardOnHover"
                        class="cursor-pointer zoom-medium"
                        :class="{'zoom-medium': mode === 'inline', 'zoom-small': mode === 'list', stacked}"
                        @click.native="$emit('avatar-clicked', user)"
                    >
                        <avatar
                            v-bind="user"
                            :size="avatarSize"
                            :disable-alt="showToolTips"
                        />
                    </user-link>
                    <user-link
                        v-if="mode === 'list'"
                        :uid="user.uid"
                        :v-card-on-hover="vCardOnHover"
                        class="user-name-link"
                    >
                        <span class="user-name ellipsis">
                            {{ user.displayName }}
                        </span>
                    </user-link>
                    <slot
                        name="suffix"
                        :user="user"
                    ></slot>
                </li>
            </transition-group>
            <li
                v-if="hasHiddenUsers"
                :class="{'zoom-on-hover-trigger': zoomOnHover}"
                class="user-row hidden-users zoom-medium cursor-pointer"
                @click="$emit('click-on-hidden-users')"
            >
                <avatar :size="avatarSize">
                    {{ hiddenUsers() }}
                </avatar>
            </li>
        </ul>
    </div>
</template>

<script>
import Spinner from "@web/components/Spinner.vue";
import {
    mapActions,
    mapGetters
} from "vuex";
import { PROFILE_MODULE_NAME } from "@web/store/profile/profile";
import { GET_USER_PROFILE } from "@web/store/profile/getters";
import { FETCH_PROFILES_FOR_USER_UIDS } from "@web/store/profile/actions";
import Avatar from "@web/components/Avatar";
import UserLink from "@web/components/user/UserLink";

export default {
    name: "AvatarList",
    components: {
        UserLink,
        Avatar,
        Spinner,
    },
    props: {
        showToolTips: {
            type: Boolean,
            default: false,
        },
        tooltipGenerator: {
            type: Function,
            default: undefined,
        },
        zoomOnHover: {
            type: Boolean,
            default: true,
        },
        loadedUserProfiles: {
            type: Array,
            default: undefined,
        },
        userUids: {
            type: Array,
            default: () => [],
        },
        userTotal: {
            type: Number,
            default: 0,
        },
        /**
         * The size of the button
         * @values inline, list
         */
        mode: {
            type: String,
            default: "inline",
        },
        stacked: {
            type: Boolean,
            default: true,
        },
        transitionName: {
            type: String,
            default: "",
        },
        useSpinner: {
            type: Boolean,
            default: true,
        },
        /** if true a UserVCard will be rendered on hover */
        vCardOnHover: {
            type: Boolean,
            default: true,
        },
        avatarSize: Avatar.props.size,
    },
    computed: {
        ...mapGetters({
            getProfileForUserUid: PROFILE_MODULE_NAME + GET_USER_PROFILE,
        }),
        userProfiles() {
            if (this.loadedUserProfiles) return this.loadedUserProfiles;
            return this.userUids.map(this.getProfileForUserUid).filter(profile => !!profile);
        },
        hasHiddenUsers() {
            if (this.loadedUserProfiles) return this.userTotal > this.loadedUserProfiles.length;
            return this.userTotal > this.userUids.length;
        },
        showLoadingSpinner() {
            // Only show the spinner in the inline mode.
            return this.mode === "inline" && this.avatarsAreLoading();
        },
    },
    watch: {
        userUids() {
            this.loadProfiles();
        },
    },
    mounted() {
        if (!this.loadedUserProfiles) this.loadProfiles();
    },
    methods: {
        ...mapActions({
            fetchProfilesForUids: PROFILE_MODULE_NAME + FETCH_PROFILES_FOR_USER_UIDS,
        }),
        async loadProfiles() {
            if (this.userUids.length > 0) {
                this.fetchProfilesForUids(this.userUids);
            }
        },
        avatarsAreLoading() {
            if (this.loadedUserProfiles) return false;
            return this.userUids.some(userUid => this.getProfileForUserUid(userUid) === undefined);
        },
        hiddenUsers() {
            if (this.loadedUserProfiles) return "+" + (this.userTotal - this.loadedUserProfiles.length);
            return "+" + (this.userTotal - this.userUids.length);
        },
        userToolTip(user) {
            if (!this.showToolTips) return "";
            return this.tooltipGenerator ? this.tooltipGenerator(user) : user.displayName;
        },
    },
};
</script>

<style lang="scss" scoped>

.avatar-list.inline {
    ul, li {
        display: inline-block;
    }

    .stacked {
        margin-right: -0.4rem;
    }
}

.avatar-list.list {
    .user-row {
        margin-bottom: $spacing-small;
    }
    .avatar, .user-name {
        margin-right: $spacing-small;
    }
    .user-name-link {
        flex: 1 1 auto;
    }
}

.avatar-list {
    .user-row {
        display: flex;
        align-items: center;
    }
}

</style>

<style lang="scss">
// not ideal, but better solution not found
.tooltip-arrow {
    width: 15px !important;
    height: 15px !important;
}
</style>
