import { cloudRunDomainId } from "@web/firebaseConfig";

export const cloudRunUrl = {
    productSubscription: `https://linchpin-product-subscription-${cloudRunDomainId}`,
    attachment: `https://linchpin-attachment-${cloudRunDomainId}`,
    comment: `https://linchpin-comment-${cloudRunDomainId}`,
    event: `https://linchpin-event-${cloudRunDomainId}`,
    follow: `https://linchpin-follow-${cloudRunDomainId}`,
    happiness: `https://linchpin-happiness-${cloudRunDomainId}`,
    intranet: `https://linchpin-intranet-${cloudRunDomainId}`,
    invite: `https://linchpin-invite-${cloudRunDomainId}`,
    launchpad: `https://linchpin-launchpad-${cloudRunDomainId}`,
    like: `https://linchpin-like-${cloudRunDomainId}`,
    news: `https://linchpin-news-${cloudRunDomainId}`,
    notificationSettings: `https://linchpin-notification-settings-${cloudRunDomainId}`,
    pageMetadataResolver: `https://linchpin-page-metadata-resolver-${cloudRunDomainId}`,
    post: `https://linchpin-post-${cloudRunDomainId}`,
    reaction: `https://linchpin-reaction-${cloudRunDomainId}`,
    story: `https://linchpin-story-${cloudRunDomainId}`,
    team: `https://linchpin-team-${cloudRunDomainId}`,
    theming: `https://linchpin-theming-${cloudRunDomainId}`,
    thirdPartyAtlassian: `https://linchpin-third-party-atlassian-${cloudRunDomainId}`,
    thirdPartyConfluenceCloud: `https://linchpin-third-party-confluence-cloud-${cloudRunDomainId}`,
    thirdPartyGoogleCalendar: `https://linchpin-third-party-google-calendar-${cloudRunDomainId}`,
    thirdPartySearch: `https://linchpin-third-party-search-${cloudRunDomainId}`,
    thirdPartyUnsplash: `https://linchpin-third-party-unsplash-${cloudRunDomainId}`,
    topic: `https://linchpin-topic-${cloudRunDomainId}`,
    topicSubscription: `https://linchpin-topic-subscription-${cloudRunDomainId}`,
    user: `https://linchpin-user-${cloudRunDomainId}`,
    userProfile: `https://linchpin-user-profile-${cloudRunDomainId}`,
    viewTracking: `https://linchpin-view-tracking-${cloudRunDomainId}`,
};
