<template>
    <transition
        mode="out-in"
        name="fade"
    >
        <div
            v-if="loaded"
            class="async-content"
        >
            <slot></slot>
        </div>
        <slot
            v-else
            name="indicator"
        >
            <Spinner
                key="spinner"
                class="indicator"
                size="32px"
            />
        </slot>
    </transition>
</template>

<script>
import Spinner from "@web/components/Spinner.vue";

export default {
    name: "LoadingWrapper",
    components: {
        Spinner,
    },
    props: {
        isLoading: {
            type: [Boolean, Object],
            required: true,
        },
    },
    computed: {
        loaded() {
            if (typeof this.isLoading === "object") {
                return Object.keys(this.isLoading).length;
            }
            if (typeof this.isLoading === "boolean") {
                return !this.isLoading;
            }
            return false;
        },
    },
};
</script>

<style lang="scss" scoped>
.async-content {
    width: 100%;
    display: inherit;
    flex: inherit;
    transition: max-height 0.5s ease;
}

.loader {
    min-height: 30vh;
    position: relative;
}

.spinner-container.site-wide {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.fade-enter {
    &.async-content {
        max-height: 0;
        opacity: 0;
        overflow: hidden;
    }
}

.fade-enter-to {
    &.async-content {
        max-height: 200vh;
        opacity: 1;
        overflow: hidden;
    }
}

.fade-enter-active {
    &.async-content {
        transition: max-height 200ms ease-out, opacity 200ms ease-in-out;
        overflow: hidden;
    }
}
</style>

<docs>
### Default usage
```vue
<loading-wrapper is-loading>
    content
</loading-wrapper>
```

### Customized indicator
```vue
<loading-wrapper is-loading>
    <template #indicator>
        <icon name="clock"></icon>
    </template>
    content
</loading-wrapper>
```
</docs>
