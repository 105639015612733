import {
    Topic,
    TopicInFirestore,
} from "@backend/topic/types";
import { ApiClient } from "@web/api/ApiClient";
import { ApiClientBuilder } from "@web/api/ApiClientBuilder";
import { cloudRunUrl } from "@web/cloud-run-url";
import { firestore } from "@web/firebase-instance";
import { INTRANET_COLLECTION_IDENTIFIER } from "@web/firestore/collections";
import { TOPIC_COLLECTION_IDENTIFIER } from "@backend/topic/collections";
import firebase from "firebase";
import { castConverter } from "@web/lib/firestore-converter";
import sortBy from "lodash/sortBy";
import { MemoryEntityCache } from "@web/lib/MemoryEntityCache";
import {
    isFulfilled,
    value,
} from "@web/lib/promises-utils";
import { PendingRequestCache } from "@web/lib/PendingRequestCache";
import Firestore = firebase.firestore.Firestore;
import CollectionReference = firebase.firestore.CollectionReference;

interface CreateTopicParams {
    intranetUid: string;
    topicName: string;
}

interface DeleteTopicParams {
    intranetUid: string;
    topicUid: string;
}

interface GetTopicParams {
    intranetUid: string;
    topicUid: string;
}

class TopicService {
    public static readonly topicServiceEndpoint = `${cloudRunUrl.topic}/api/topic`;
    private readonly api: Promise<ApiClient>;

    constructor() {
        this.api = new ApiClientBuilder().build();
    }

    public async createTopic({ intranetUid, topicName }: CreateTopicParams): Promise<Topic> {
        const client = await this.api;
        const response = await client.post<Topic>(`${TopicService.topicServiceEndpoint}/${intranetUid}`, {
            name: topicName,
        });
        return response.data;
    }

    public async getTopics({ intranetUid, topicUids }: { intranetUid: string, topicUids: string[] }): Promise<Topic[]> {
        const fetchingTopics = topicUids.map(topicUid => this.getTopic({ intranetUid: intranetUid, topicUid: topicUid }));
        const fetchResults = await Promise.allSettled(fetchingTopics);
        const topics = fetchResults.filter(isFulfilled).map(value);
        return sortBy(topics, "comparableName");
    }

    public async getTopic({ intranetUid, topicUid }: GetTopicParams): Promise<Topic> {
        const topicSnap = await getTopicCollectionRef({ db: firestore, intranetUid })
            .doc(topicUid)
            .get();
        const topic = topicSnap.data();
        if (!topic) {
            throw new Error(`topic ${topicUid} does not exist`);
        }
        return {
            uid: topicSnap.id,
            ...topic,
        };
    }

    public async deleteTopic({ intranetUid, topicUid }: DeleteTopicParams): Promise<void> {
        await getTopicCollectionRef({ db: firestore, intranetUid })
            .doc(topicUid)
            .delete();
    }
}

class CachingTopicService implements Omit<TopicService, "api"> {
    private readonly client: TopicService;
    private readonly cache: MemoryEntityCache<Topic>;
    private readonly pendingCache: PendingRequestCache<Topic>;

    constructor() {
        this.client = new TopicService();
        this.cache = new MemoryEntityCache<Topic>();
        this.pendingCache = new PendingRequestCache<Topic>();
    }

    async createTopic(params: CreateTopicParams): Promise<Topic> {
        const topic = await this.client.createTopic(params);
        await this.cache.set({ intranetUid: params.intranetUid, entity: topic });
        return topic;
    }

    async deleteTopic(params: DeleteTopicParams): Promise<void> {
        await this.client.deleteTopic(params);
        await this.cache.remove({ intranetUid: params.intranetUid, entityUid: params.topicUid });
    }

    async getTopics({ intranetUid, topicUids }: { intranetUid: string; topicUids: string[] }): Promise<Topic[]> {
        const fetchingTopics = topicUids.map(topicUid => this.getTopic({ intranetUid: intranetUid, topicUid: topicUid }));
        const fetchResults = await Promise.allSettled(fetchingTopics);
        const topics = fetchResults.filter(isFulfilled).map(value);
        return sortBy(topics, "comparableName");
    }

    async getTopic(params: GetTopicParams): Promise<Topic> {
        const cachedTopic = await this.cache.get({ intranetUid: params.intranetUid, entityUid: params.topicUid });
        if (cachedTopic) {
            return cachedTopic;
        }
        return this.pendingCache.waitFor(params.topicUid, async() => {
            const topic = await this.client.getTopic(params);
            await this.cache.set({ intranetUid: params.intranetUid, entity: topic });
            return topic;
        });
    }
}

function getTopicCollectionRef({ db, intranetUid }: { db: Firestore, intranetUid: string }): CollectionReference<TopicInFirestore> {
    return db.collection(INTRANET_COLLECTION_IDENTIFIER)
        .doc(intranetUid)
        .collection(TOPIC_COLLECTION_IDENTIFIER)
        .withConverter(castConverter<TopicInFirestore>());
}

export const topicService = new CachingTopicService();
