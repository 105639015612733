export default {
    inserted(el, binding) {
        // If window has no ResizeObserver, attach one to window.
        if (!window._LinchpinResizeObserver) {
            window._LinchpinResizeObserver = new ResizeObserver(entries => {
                for (const entry of entries) {
                    let width = entry.contentBoxSize && entry.contentBoxSize.inlineSize ? entry.contentBoxSize.inlineSize : entry.contentRect.width; // replace with elvis once available
                    width = Math.round(width);
                    const breakpoints = entry.target._breakpoints;
                    const greaterBreakpoints = entry.target._greaterBreakpoints;

                    for (const breakpoint in breakpoints) {
                        if (width <= breakpoints[breakpoint]) {
                            entry.target.classList.add(breakpoint);
                        } else {
                            entry.target.classList.remove(breakpoint);
                        }
                    }
                    for (const breakpoint in greaterBreakpoints) {
                        if (width > greaterBreakpoints[breakpoint]) {
                            entry.target.classList.add(breakpoint);
                        } else {
                            entry.target.classList.remove(breakpoint);
                        }
                    }
                }
            });
        }

        if (!el._breakpoints) el._breakpoints = {}; // Create Breakpoints
        if (!el._greaterBreakpoints) el._greaterBreakpoints = {}; // Create Greater Breakpoints

        if (binding.modifiers.gt) {
            el._greaterBreakpoints[binding.arg] = binding.expression;
        } else {
            el._breakpoints[binding.arg] = binding.expression;
        }

        window._LinchpinResizeObserver.observe(el);
    },
    unbind(el) {
        window._LinchpinResizeObserver.unobserve(el);
    }
};
