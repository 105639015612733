<template>
    <product-fruits
        v-if="currentUser && intranet"
        :language="language"
        :project-code="projectCode"
        :role="userRole"
        :sign-up-at="userCreationTime"
        :username="currentUser.uid"
        :firstname="firstname"
        :props="userProps"
    />
</template>

<script>
import { mapGetters } from "vuex";
import { AUTH_MODULE_NAME } from "@web/store/auth/auth";
import { CURRENT_USER } from "@web/store/auth/getters";
import { userLoginService } from "@web/services";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import {
    INTRANET,
    IS_ADMIN,
} from "@web/store/intranet/getters";
import ProductFruits from "@web/components/ProductFruits";
import { firstnameFromDisplayName } from "@web/lib/user-displayname-detection";
import { productFruitsProjectCode } from "@/firebaseConfig";

/**
 * Initializes [Product Fruits](https://productfruits.com/) with necessary user information.
 */
export default {
    name: "EverestProductFruits",
    components: {
        ProductFruits,
    },
    data() {
        return {
            projectCode: productFruitsProjectCode,
            // only use 'en' for now
            language: "en", // detectLocale(),
            userCreationTime: new Date(userLoginService.userCreationTime()).toISOString(),
        };
    },
    computed: {
        ...mapGetters({
            /** @type {Intranet} */
            intranet: INTRANET_MODULE_NAME + INTRANET,
            /** @type {boolean} */
            isAdmin: INTRANET_MODULE_NAME + IS_ADMIN,
            /** @type {EverestUser} */
            currentUser: AUTH_MODULE_NAME + CURRENT_USER,
        }),
        userRole() {
            if (!this.currentUser || !this.intranet) {
                return undefined;
            }
            if (this.intranet.creatorUid === this.currentUser.uid) {
                return "creator";
            }
            if (this.isAdmin) {
                return "admin";
            }
            return "user";
        },
        firstname() {
            if (this.currentUser) {
                return firstnameFromDisplayName(this.currentUser.displayName) || " ";
            }
            return undefined;
        },
        userProps() {
            return {
                intranetUid: this.intranet.uid
            };
        },
    },
};
</script>

<style lang="scss" scoped>

</style>
