import { inviteService } from "@web/services/index";
import { VuexRootState } from "@web/store";
import {
    FETCH_INVITES,
    INVITE_USER_BY_MAIL,
    REVOKE_INVITE,
    SEARCH_BY_MAIL
} from "@web/store/invite/actions";
import { Module } from "vuex";
import {
    MailInvite,
    MailInviteInFireStoreFieldKeys
} from "@web/store/invite/types";
import {
    DECREASE_PAGE,
    INCREASE_PAGE,
    RESET_INVITE_MODULE,
    SET_INVITES,
    SET_IS_NEXT_PAGE,
    SET_LOADING,
    SET_SEARCH_TERM
} from "@web/store/invite/mutations";
import { InviteService } from "@web/services/intranet-access";

export const INVITE_MODULE_NAME = "invite/";

export interface InviteModuleState {
    invites: MailInvite[];
    offset: number;
    page: number;
    loading: boolean;
    searchTerm: string;
    isNextPage: boolean;
}

const getDefaultState = () => ({
    invites: [],
    offset: 0,
    page: -1,
    loading: true,
    isNextPage: false,
    searchTerm: "",
});

export const INVITE_MODULE = {
    namespaced: true,
    state: getDefaultState(),
    mutations: {
        [SET_LOADING](state, isLoading) {
            state.loading = isLoading;
        },
        [SET_INVITES](state, invites) {
            state.invites = invites;
            state.loading = false;
        },
        [INCREASE_PAGE](state) {
            state.page++;
        },
        [DECREASE_PAGE](state) {
            state.page--;
        },
        [SET_IS_NEXT_PAGE](state, isNext) {
            state.isNextPage = isNext;
        },
        [SET_SEARCH_TERM](state, searchTerm) {
            state.searchTerm = searchTerm;
        },
        [RESET_INVITE_MODULE](state) {
            Object.assign(state, getDefaultState());
        },
    },
    actions: {
        async [SEARCH_BY_MAIL]({ commit, dispatch }, searchTerm) {
            commit(RESET_INVITE_MODULE);
            commit(SET_SEARCH_TERM, searchTerm);
            dispatch(FETCH_INVITES, "next");
        },
        async [REVOKE_INVITE]({ commit, dispatch, state, rootState }, id) {
            const { intranet } = rootState;
            if (intranet.intranet) {
                await inviteService.revokeInviteByIdForIntranet(intranet.intranet.uid, id);
                commit(SET_INVITES, state.invites.filter((i: MailInvite) => i[MailInviteInFireStoreFieldKeys.inviteId] !== id));
            }
        },
        async [INVITE_USER_BY_MAIL]({ commit, dispatch, rootState }, email) {
            const { intranet } = rootState;
            if (intranet.intranet) {
                return inviteService.createMailInviteForIntranet(intranet.intranet.uid, email);
            }
        },
        async [FETCH_INVITES]({ commit, rootState, state }, mode: "next" | "prev" | "reload") {
            if (mode === "next") {
                commit(INCREASE_PAGE);
            }
            if (mode === "prev") {
                commit(DECREASE_PAGE);
            }

            const { intranet } = rootState;
            commit(SET_LOADING, true);

            if (intranet.intranet) {
                const invites = await inviteService.getInvitesForIntranet(
                    intranet.intranet.uid, state.page * InviteService.PAGE_SIZE, state.searchTerm);
                if (mode !== "reload") {
                    commit(SET_IS_NEXT_PAGE, mode === "prev" ? true : invites.length >= InviteService.PAGE_SIZE);
                }
                if (invites.length !== 0) {
                    commit(SET_INVITES, invites);
                } else {
                    // edge-case where results exactly match a multiplier of page_size
                    commit(SET_IS_NEXT_PAGE, false);
                    commit(DECREASE_PAGE);
                }
                commit(SET_LOADING, false);
            }
        }
    },
} as Module<InviteModuleState, VuexRootState>;
