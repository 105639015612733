<template>
    <div
        v-resize:compact-header="450"
        class="post-header"
    >
        <div class="metadata">
            <loading-avatar
                :user-uid="authorUserUid"
                linked
                size="medium"
                class="mright-xsmall"
            />
            <div class="text">
                <div class="author-row">
                    <div class="author ellipsis">
                        <loading-content-author
                            :user-uid="authorUserUid"
                            linked
                        />
                    </div>
                    <div
                        v-if="isPostAuthor"
                        v-tooltip.bottom="$t('is_author')"
                        class="author-pill"
                    >
                        <Icon
                            name="user"
                            size="small"
                        />
                    </div>
                    <!--
                    for later usage when different topic streams are supported
                    <div class="target ellipsis" v-if="topic &&">
                        <span class="arrow">→</span>{{ topic }}
                    </div>
                    -->
                </div>
                <div class="meta">
                    <div class="ellipsis-wrapper">
                        <router-link
                            class="ellipsis hover-underline"
                            :to="postRoute"
                        >
                            {{ formattedCreationDate }}
                        </router-link>
                        <Icon
                            v-if="isEdited"
                            v-tooltip="formattedLastEditedDate"
                            name="edit"
                            size="small"
                            :title="detailedLastEditedDate"
                            class="d-inline-block"
                        />
                    </div>
                    <UnreadIndicator
                        :is-unread="isUnread"
                        class="unread-indicator"
                    />
                </div>
            </div>
        </div>
        <transition
            name="fade"
            mode="out-in"
        >
            <div
                v-if="showActions"
                class="interactions"
            >
                <slot name="actions" ></slot>
                <v-popover
                    ref="popover"
                    popover-arrow-class="tooltip-arrow popover-arrow arrow-top"
                    hide-on-target-click="true"
                    placement="top-end"
                    @show="onShowMenuPopover"
                    @hide="onHideMenuPopover"
                >
                    <app-button
                        type="light only-icon"
                        icon="ellipsis"
                        bg="none"
                    />
                    <template #popover>
                        <div
                            v-close-popover
                            class="actions"
                        >
                            <slot
                                name="action-menu"
                                :isOpen="isMenuPopoverOpen"
                            ></slot>
                        </div>
                    </template>
                </v-popover>
            </div>
        </transition>
    </div>
</template>

<script>
import {
    friendlyFormatDateFromDayJs,
    toDayJs,
} from "@web/lib/time-utils";
import UnreadIndicator from "@web/components/pings/UnreadIndicator";
import { mapGetters } from "vuex";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import { INTRANET_UID } from "@web/store/intranet/getters";
import LoadingAvatar from "@web/components/user/LoadingAvatar";
import LoadingContentAuthor from "@web/components/user/LoadingContentAuthor";

/**
 * meta data view for posts and comments
 */
export default {
    components: {
        LoadingContentAuthor,
        LoadingAvatar,
        UnreadIndicator,
    },
    props: {
        postRoute: Object,
        authorUserUid: String,
        topic: String,
        creationDate: Object,
        lastEditedDate: Object,
        isJustEdited: Boolean,
        showActions: Boolean,
        isPostAuthor: Boolean,
        isUnread: Boolean,
    },
    data() {
        return {
            isMenuPopoverOpen: false,
        };
    },
    computed: {
        ...mapGetters({
            intranetUid: INTRANET_MODULE_NAME + INTRANET_UID,
        }),
        formattedCreationDate() {
            return friendlyFormatDateFromDayJs(toDayJs(this.creationDate));
        },
        detailedCreationDate() {
            return `${this.$t("created")}: ${toDayJs(this.creationDate).toString()}`;
        },
        formattedLastEditedDate() {
            return friendlyFormatDateFromDayJs(toDayJs(this.lastEditedDate));
        },
        detailedLastEditedDate() {
            return `${this.$t("edited")}: ${toDayJs(this.lastEditedDate).toString()}`;
        },
        isEdited() {
            return this.isJustEdited || (!toDayJs(this.lastEditedDate).isSame(toDayJs(this.creationDate)));
        },
    },
    methods: {
        onShowMenuPopover() {
            this.isMenuPopoverOpen = true;
        },
        onHideMenuPopover() {
            this.isMenuPopoverOpen = false;
        },
    },
};
</script>

<style lang="scss" scoped>
.actions {
    min-width: 18ch;
    display: flex;
    flex-direction: column;

    &::v-deep {
        .button + .button {
            margin: 0;
        }
    }
}

.post-header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    position: relative;

    .metadata {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        flex: 1;

        .text {
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            user-select: none;
            flex: 1 1 auto;

            .author-row {
                display: flex;
                flex: 0 1 auto;
                flex-direction: row;
                flex-wrap: nowrap;
                margin-right: 0.3rem;
                min-width: 0;

                .author {
                    font-weight: bold;
                    flex: 0 1 auto;
                }

                .author-pill {
                    margin: 0 0.3rem 0;
                    padding: 0.2rem 0.3rem;
                    background: $lowest-contrast;
                    border-radius: $border-radius-medium;
                    font-weight: 600;
                    font-size: 0.6rem;
                    text-transform: uppercase;
                    letter-spacing: -0.03rem;
                    position: relative;
                    top: -0.04rem;
                    --icon-color: #{$medium-contrast};
                }

                .target {
                    font-size: 0.75rem;
                    font-weight: 500;
                    line-height: 1.33;

                    .arrow {
                        margin: 0 0.3rem;
                        font-weight: 500;
                        opacity: 0.3;
                        font-size: 0.75rem;
                        line-height: 2;
                    }
                }
            }

            .ellipsis-wrapper {
                display: flex;
                overflow: hidden;
                align-items: center;
            }

            .meta {
                flex: 1 1 auto;
                display: flex;
                align-items: center;

                .icon {
                    margin-left: 0.4rem;
                    opacity: 0.4;
                    flex: 0 0 auto;
                }

                .unread-indicator {
                    margin-left: .5rem;
                }
            }
        }

        .avatar {
            margin-right: 0.75rem;
            pointer-events: none;
        }
    }

    .interactions {
        flex: 0 0 auto;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
        > div {
            margin-left: 0.5rem;
        }

        .actions {
            margin-left: 0.5rem;
        }
    }

    .avatar {
        margin-right: 0.5rem;
        flex: 0 0 auto;
    }

    &.post-header.compact-header {
        .author-row {
            flex: 1 1 auto;

            .author {
                width: 0;
                flex: 1;
            }
        }
    }
}
</style>
