import { ApiClient } from "@web/api/ApiClient";
import { ApiClientBuilder } from "@web/api/ApiClientBuilder";
import { analytics } from "@web/analytics";
import { getGlobalConfiguration } from "@web/global-config";
import { cloudRunUrl } from "@web/cloud-run-url";

export class DomainBasedIntranetAccessService {
    public static readonly domainBasedIntranetAccessConfigurationEndpoint = (intranetUid: string) => `${cloudRunUrl.invite}/api/invite/domain-based-intranet-access/configuration/${intranetUid}`;
    public static readonly domainBasedIntranetAccessRedeemEndpoint = (intranetUid: string) => `${cloudRunUrl.invite}/api/invite/domain-based-intranet-access/redeem/${intranetUid}`;
    public static readonly domainBasedIntranetAccessAvailableEndpoint = `${cloudRunUrl.invite}/api/invite/domain-based-intranet-access/available`;

    private api: Promise<ApiClient>;

    constructor() {
        this.api = new ApiClientBuilder().build();
    }

    public async updateDomains(intranetUid: string, domains: string[]) {
        const client = await this.api;
        analytics.log(domains.length ? getGlobalConfiguration().analytics_event_name_domain_based_intranet_access_enabled : getGlobalConfiguration().analytics_event_name_domain_based_intranet_access_disabled);
        return client.post(DomainBasedIntranetAccessService.domainBasedIntranetAccessConfigurationEndpoint(intranetUid), { domains });
    }

    public async getAvailableIntranets() {
        const client = await this.api;
        return client.get(DomainBasedIntranetAccessService.domainBasedIntranetAccessAvailableEndpoint);
    }

    public async redeemAccess(intranetUid: string) {
        const client = await this.api;
        return client.post(DomainBasedIntranetAccessService.domainBasedIntranetAccessRedeemEndpoint(intranetUid));
    }
}
