import { ApiClient } from "@web/api/ApiClient";
import { ApiClientBuilder } from "@web/api/ApiClientBuilder";
import { cloudRunUrl } from "@web/cloud-run-url";
import { GoogleCalendarApiResponse } from "@web/views/google/google-types";

export class GoogleContentService {
    private api: Promise<ApiClient>;

    public static readonly googleCalendarApi = `${cloudRunUrl.thirdPartyGoogleCalendar}/api/googleCalendar/`;

    constructor() {
        this.api = new ApiClientBuilder().build();
    }

    public async fetchMyEvents(intranetUid: string): Promise<GoogleCalendarApiResponse> {
        const client = await this.api;
        const response = await client.get<GoogleCalendarApiResponse>(`${GoogleContentService.googleCalendarApi + intranetUid}/myevents`);
        return response.data;
    }
}
