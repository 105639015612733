import {
    firestore,
    firebase
} from "@web/firebase-instance";
import { ReactableRootEntityType } from "@backend/reaction/types";

function docRefForReactableEntity(
    intranetUid: string,
    contentType: string,
    contentUid: string,
    path: string,
): firebase.firestore.DocumentReference<firebase.firestore.DocumentData> {
    const prunedType = contentType.replace("-comment", "");

    if (contentType === ReactableRootEntityType.story) {
        const userUid = path;
        const docPath = `/intranet/${intranetUid}/stories/${userUid}/story/${contentUid}`;
        return firestore.doc(docPath);
    }

    let docPath = `/intranet/${intranetUid}/${prunedType}/${contentUid}`;
    if (path && path.length > 0) {
        path.split(".").forEach((commentUid) => docPath += `/comments/${commentUid}`);
    }

    return firestore.doc(docPath);
}

export function registerSnapshotListenerOnReactableEntity(
    intranetUid: string,
    contentType: string,
    contentUid: string,
    path: string,
    onSnapshot: (snapshot: firebase.firestore.DocumentSnapshot) => void,
): () => void {
    const docRef = docRefForReactableEntity(intranetUid, contentType, contentUid, path);
    return docRef.onSnapshot(onSnapshot);
}
