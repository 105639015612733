import {
    IntranetFeatureFlag,
    IntranetFeatureFlagConfigurationInFireStore,
    KnownIntranetFeatureFlagConfiguration,
} from "@backend/common/feature-flags/types";
import { Timestamp } from "@backend/entity/types";
import { ProductSubscription } from "@backend/product-subscription/types";
import { IntranetColors } from "@backend/theming/types";

export interface IntranetInFireStore {
    creatorUid: string;
    contactUids?: string[];
    creationDate?: Timestamp;
    displayName: string;
    colors: IntranetColors;
    logo: string;
    userCount: number;
    teamCount: number;
    flags: {
        [F in IntranetFeatureFlag]?: IntranetFeatureFlagConfigurationInFireStore<KnownIntranetFeatureFlagConfiguration>
    };
    subscription?: ProductSubscription;
}

export interface BaseIntranet {
    uid: string;
    logoUrl?: string;
    displayName: string;
    colors: IntranetColors;
}

export interface Intranet extends IntranetInFireStore {
    uid: string;
    logoUrl?: string;
}

export interface IntranetStorageInfoInFirestore {
    limitInGb: number;
    usedInGb: number;
}

export interface PermissionsForIntranetUid {
    intranetUid: string;
    roles: IntranetRole[];
}

export interface PermissionsForIntranet {
    roles: IntranetRole[];
    intranet: Intranet;
}

export interface IntranetPermissionInFireStore {
    roles: IntranetRole[];
}

export enum IntranetRole {
    user = "user",
    admin = "admin",
}

export interface IntranetUpdateRequest {
    displayName: string;
}

export interface IntranetTemplate {
    data: IntranetInFireStore;
    templateUid: string;
}
