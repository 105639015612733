import { firebase } from "@web/firebase-instance";
import { firebaseConfig } from "@web/firebaseConfig";

export type DefaultAnalyticsParams = Record<string, string | number | boolean | undefined>;
export type DefaultAnalyticsParamsFetcher = () => DefaultAnalyticsParams;

// usage information: https://seibertmedia-cloud.atlassian.net/wiki/spaces/LC/pages/51085313/Firebase+Analytics
class Analytics {
    private firebaseAnalytics: firebase.analytics.Analytics | null = null;
    /**
     * Set this to define default params for every analytics event.
     */
    public defaultParamsFetcher: DefaultAnalyticsParamsFetcher = () => ({});

    constructor(firebaseAnalyticsFn: (app?: firebase.app.App) => firebase.analytics.Analytics) {
        if ((firebaseConfig as any).measurementId) {
            this.firebaseAnalytics = firebaseAnalyticsFn();
        } else {
            console.warn("Analytics disabled due to missing measurementId. " +
                "Enable analytics in your firebase console and add attribute to your everest environment.");
        }
    }

    public log(eventName: string | undefined, additionalParameters: { [key: string]: any } = {}): void {
        if (this.firebaseAnalytics && eventName) {
            try {
                const params = { ...this.defaultParamsFetcher(), ...additionalParameters };
                this.firebaseAnalytics.logEvent(eventName, params);
                console.log(`Sent analytics event ${eventName} with params ${JSON.stringify(params)}`);
            } catch (e) {
                // pokemon pattern to prevent error occurring in client code
                console.warn(`Error sending analytics event ${eventName}`);
            }
        }
    }
}

export const analytics = new Analytics(firebase.analytics);
