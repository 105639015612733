import Delta from "quill-delta";
import Quill,
{ DeltaOperation } from "quill";

declare module "quill" {
    interface Quill {
        container: HTMLElement;
        isEnabled(): boolean;
    }
}

export function quillPasteHandler(text: string): Delta {
    const urlPatternString = "^https?://(www.)?[-a-zA-Z0-9@:%._+~#=]{2,256}(:\\d{1,5}|.[a-zA-Z]{2,})?\\b([-a-zA-Z0-9@:%_+.~#?&/=()]*)$";
    const urlPattern = new RegExp(urlPatternString, "g"); // adding the `g` flag may add a state to the regex

    const isUrl = urlPattern.test(`${text}`.trim());

    return isUrl ? new Delta().insert(text, { link: text }) : new Delta().insert(text);
}

/**
 * HALO-4004: While Quill is in read-only mode it still reacts to DOM changes. Therefore, it tries to parse the HTML
 * which Google Translate inserts when translating text. As Google Translate adds `<font>` tags around the text, Quill
 * can't parse the content anymore and replaces it with `<br>`.
 * This fix works around this behaviour by disabling updates while quill is in read-only mode.
 *
 * A proper solution to this has been submitted as a PR in https://github.com/quilljs/quill/pull/3414
 * Once this PR gets merged, we should remove this monkey patch and upgrade to a newer quill version
 */
export function monkeyPatchGoogleTranslateFix(quill: Quill): void {
    const scrollUpdate = quill.scroll.update.bind(quill.scroll);
    quill.scroll.update = (mutations, context) => {
        if (!quill.isEnabled()) {
            return;
        }
        scrollUpdate(mutations, context);
    };
    // @ts-ignore internal override
    const scrollEnable = quill.scroll.enable.bind(quill.scroll);
    // @ts-ignore internal override
    quill.scroll.enable = (enabled = true) => {
        quill.container.classList.toggle("notranslate", enabled);
        scrollEnable(enabled);
    };
    quill.container.classList.toggle("notranslate", quill.isEnabled());
}

/**
 * Convenience method to bundle dependency to `quill-delta` within this module.
 * @returns length of the text within all operations.
 */
export function length(deltaOperations: DeltaOperation[]): number {
    return new Delta(deltaOperations).length();
}

/**
 * Always creates a fresh array to avoid pollution with reactivity from Vue.
 */
export function emptyContent(): DeltaOperation[] {
    return [{ insert: "\n" }];
}
