<template>
    <div>
        <v-popover
            ref="popover"
            popover-arrow-class="tooltip-arrow popover-arrow arrow-top"
            hide-on-target-click="true"
            placement="top-end"
            @show="openMenu"
        >
            <app-button
                type="no-background only-icon"
                icon="ellipsis"
            />
            <template
                slot="popover"
            >
                <Spinner v-if="loading" />
                <div
                    v-else
                    v-close-popover
                    class="news-actions"
                >
                    <app-button
                        v-if="!isEditMode"
                        v-clipboard="directLinkToNews()"
                        icon="link"
                        type="link no-padding small"
                    >
                        {{ $t('copy_direct_link') }}
                    </app-button>
                    <app-button
                        v-if="!isEditMode && isEditingAllowed"
                        icon="edit"
                        type="link no-padding small"
                        @click="$emit('to-edit-mode')"
                    >
                        {{ $t('edit') }}
                    </app-button>
                    <app-button
                        :icon="isFollowing? 'bell-off' : 'bell'"
                        type="link no-padding small"
                        @click="followNews(!isFollowing)"
                    >
                        {{ $t(isFollowing ? "activity_unfollow" : "activity_follow") }}
                    </app-button>
                    <!-- Function will be available later on -->
                    <!-- <app-button
                        icon="flag"
                        type="link"
                    >
                        {{ $t('report_entity', [$t('news')]) }}
                    </app-button> -->
                </div>
            </template>
        </v-popover>
    </div>
</template>

<script>
import { followService } from "@/services";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import { INTRANET_UID } from "@web/store/intranet/getters";
import { mapGetters } from "vuex";
import Spinner from "@web/components/Spinner";
import { i18n } from "@/i18n";

export default {
    name: "NewsStatsAndActions",
    components: {
        Spinner
    },
    props: {
        news: {
            type: Object,
            default: null
        },
        isEditMode: {
            type: Boolean,
            default: false
        },
        isEditingAllowed: {
            type: Boolean,
            default: false
        },
    },
    data() {
        return {
            isFollowing: false,
            loading: true,
        };
    },
    computed: {
        ...mapGetters({
            intranetUid: INTRANET_MODULE_NAME + INTRANET_UID
        }),
        params() {
            return {
                intranetUid: this.intranetUid,
                followableType: "news",
                contentUid: this.news.uid,
            };
        }
    },
    methods: {
        async openMenu() {
            try {
                this.isFollowing = await followService.isFollowing(this.params);
            } catch {
                this.$notify({
                    group: "app",
                    type: "error",
                    text: i18n.t("ping_failed_fetch_follow_status"),
                });
            }
            this.loading = false;
        },
        async followNews(status) {
            try {
                await followService[status ? "follow" : "unfollow"](this.params);
                this.$notify({
                    group: "app",
                    type: "success",
                    text: i18n.t(status ? "ping_success_follow" : "ping_success_unfollow", ["news"]),
                });
                this.isFollowing = status;
            } catch {
                this.$notify({
                    group: "app",
                    type: "error",
                    text: i18n.t("ping_failed_to_set_follow_state"),
                });
            }
        },
        directLinkToNews() {
            return `${window.location.origin}/intranet/${this.$store.state.intranet.intranet.uid}/news/id/${this.news.uid}`;
        }
    },
};
</script>

<style lang="scss" scoped>
.news-actions::v-deep{
    // {
        margin: -.5rem;
        min-width: 18ch;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
            > a {
                padding: .5rem 0;
                margin: 0 .5rem;
            }
    // }
}
</style>
