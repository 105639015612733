<template>
    <div>
        <spinner
            v-if="busy"
            size="50"
        ></spinner>
        <v-popover
            v-else
            ref="popover"
            popover-arrow-class="tooltip-arrow popover-arrow arrow-top"
            offset="4"
            popover-class="no-padding"
            placement="bottom-center"
            @show="$emit('open-upload-options')"
            @hide="$emit('close-upload-options')"
        >
            <app-button
                icon="camera"
                :fab="text === undefined"
                bg="var(--background)"
                color="var(--foreground)"
            >
                <template v-if="text">
                    {{ text }}
                </template>
            </app-button>

            <template slot="popover">
                <input
                    ref="fileUpload"
                    class="hidden"
                    type="file"
                    accept="image/jpeg,image/jpg,image/png"
                    @change="updateSelectedFile"
                />
                <ul class="image-edit-options">
                    <li @click="$refs.fileUpload.click()">
                        <icon name="upload"></icon>
                        <span>{{ $t("profile_picture_upload") }}</span>
                    </li>
                    <li
                        v-if="deleteImage"
                        @click="closePopUpAndDelete"
                    >
                        <icon
                            name="trash"
                            color="var(--warning)"
                        ></icon>
                        <span class="text-warning">{{ $t("profile_picture_delete") }}</span>
                    </li>
                </ul>
            </template>
        </v-popover>
    </div>
</template>

<script>
import { blobToBase64 } from "@/services/file-upload";
import Spinner from "@/components/Spinner";

export default {
    name: "ImageUploadButton",
    components: { Spinner },
    props: {
        text: String,
        uploadImage: {
            type: Function,
        },
        deleteImage: {
            type: Function,
        },
        busy: {
            type: Boolean,
            default: false,
        },
        cropperAspectRatio: {
            type: Number,
            default: 1,
        },
        cropperRounded: {
            type: Boolean,
            default: true,
        },
        asModal: {
            type: Boolean,
            default: true,
        }
    },
    methods: {
        async updateSelectedFile() {
            if (this.asModal) {
                this.$modal.show("image-cropper-modal", {
                    cropperAspectRatio: this.cropperAspectRatio,
                    cropperRounded: this.cropperRounded,
                    image: blobToBase64(this.$refs.fileUpload.files[0]),
                    cropHandler: this.uploadImage,
                });
            } else {
                this.$emit("change", this.$refs.fileUpload.files[0]);
            }
            this.$emit("close-upload-options");
        },
        closePopUpAndDelete() {
            this.$emit("close-upload-options");
            this.deleteImage();
        }
    }
};
</script>

<style lang="scss" scoped>
.image-edit-options {
    padding: .5rem 0;

    li {
        transition: background .3s;
        display: flex;
        align-items: center;
        padding: .5rem 1rem;
        cursor: pointer;
        font-size: 1rem;
        font-weight: 400;

        &:hover {
            background: var(--lowest-contrast);
        }

        .icon {
            margin-right: 1rem;
        }
    }
}
</style>
