<template>
    <featured-content
        class="text-high-contrast"
        :direction="direction"
    >
        <template #featuring>
            <div class="center-container">
                <icon
                    name="alert-triangle"
                    size="64"
                    color="var(--high-contrast)"
                />
            </div>
        </template>
        <template #title>
            {{ $t("shared_content_error_title") }}
        </template>
        <template #content>
            {{ $t("shared_content_error_description") }}
        </template>
    </featured-content>
</template>

<script>
import FeaturedContent from "@/components/attachments/shared-content/FeaturedContent";

/**
 * Display for a missing `FeaturedContent` if the data for more details are not reachable or deleted.
 *
 * @see [FeaturedContent](#/Components/FeaturedContent)
 */
export default {
    name: "MissingFeaturedContent",
    components: { FeaturedContent },
    inheritAttrs: false,
    props: {
        direction: FeaturedContent.props.direction,
    },
};
</script>

<style lang="scss" scoped>

</style>
