<template>
    <div>
        <quill-editor
            ref="quillEditor"
            :class="editorClassList"
            :options="editorOptions"
            @everest-mention-mouseenter.native="triggerVCard"
            @everest-mention-mouseleave.native="hideVCardAfterTime"
            @change="onEditorChange"
        >
            <template #toolbar>
                <editor-toolbar
                    v-if="!toolbarId"
                    v-show="editMode"
                    :id="actualToolbarId"
                    :edit-mode="editMode"
                    :show-attachment-upload-button="showAttachmentUploadButton"
                    :show-embed-uploaded-image-button="showEmbedUploadedImageButton"
                    :show-embed-unsplash-image-button="showEmbedUnsplashImageButton"
                    :show-link-button="showLinkButton"
                    :show-header-buttons="showHeaderButtons"
                    @attachment-selected="$emit('attachment-selected', $event)"
                    @embed-image-selected="$emit('embed-image-selected', $event)"
                    @file-select="$emit('file-select')"
                />
            </template>
        </quill-editor>
        <div
            v-if="mentionedUserUid && !editMode"
            ref="vcard"
            class="padded-vcard"
            @mouseenter="keepVCardOpen = true"
            @mouseleave="hideVCard"
        >
            <user-v-card
                :key="mentionedUserUid"
                :user-uid="mentionedUserUid"
                class="card depth-3 overflow-hidden"
                @loaded="updateVCardPosition"
            />
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import { INTRANET } from "@web/store/intranet/getters";
import { countMentions } from "@web/lib/mention-count";
import {
    emptyContent,
    monkeyPatchGoogleTranslateFix,
    quillPasteHandler,
} from "@web/lib/quill/quill-utils";
import { QuillEditor } from "@web/lib/quill/register-quill-modules";
import { editorMentionModule } from "@web/lib/quill/mention/everest-mention";
import { truncateDelta } from "@web/lib/quill/quill-read-more";
import { getGlobalConfiguration } from "@web/global-config";
import { filesFromPaste } from "@web/lib/quill/event-handling/files-from-paste";
import { ImageBlot } from "@web/lib/quill/image/image-blot";
import EditorToolbar from "@web/components/editor/EditorToolbar";
import { createPopper } from "@popperjs/core";
import UserVCard from "@web/components/v-card/UserVCard";
import { wait } from "@web/lib/wait";

const quillEditorScrollingContainerId = "#quill-editor-scrolling-container";

/**
 * Minimal rich-text editor setup. Basically just wraps quill, adds the toolbar (which can be overwritten using the
 * `toolbar` slot, or by passing in a `toolbarId`) and adds handler for text/file pasting.
 */
export default {
    name: "RichTextEditor",
    components: {
        UserVCard,
        EditorToolbar,
        QuillEditor,
    },
    props: {
        /** @type {DeltaOperation[]} */
        value: { type: Array, default: emptyContent },
        editMode: Boolean,
        placeHolder: String,
        preventAutoFocus: Boolean,
        isEmpty: Boolean,
        showHeaderButtons: Boolean,
        showAttachmentUploadButton: {
            type: Boolean,
            default: false,
        },
        showLinkButton: {
            type: Boolean,
            default: true,
        },
        showEmbedUploadedImageButton: {
            type: Boolean,
            default: false,
        },
        showEmbedUnsplashImageButton: {
            type: Boolean,
            default: false,
        },
        truncate: Boolean,
        embedImages: {
            type: Boolean,
            default: false,
        },
        toolbarId: String,
        editorClasses: {
            type: Array,
            default: () => ["generic-quill-editor", "everest-generic-text"],
        },
    },
    data() {
        return {
            truncated: this.truncate,
            mentionedUserUid: "",
            keepVCardOpen: false,
            popper: null,
        };
    },
    computed: {
        ...mapGetters({ intranet: INTRANET_MODULE_NAME + INTRANET }),
        actualToolbarId() {
            if (this.toolbarId) {
                return this.toolbarId;
            }
            return `toolbar-${this._uid}`;
        },
        editorClassList() {
            if (!this.editMode) {
                return this.editorClasses;
            }
            return ["edit", ...this.editorClasses];
        },
        scrollingContainer() {
            /* Prevents scroll jumping bugs when the scroll container is not "html, body"
             * This id must be set to the scroll container with overflow: auto
             *
             * The fixed id #quill-editor-scrolling-container the correct behaviour a different scroll container.
             * Otherwise the correct scrollingContainer must be passed through all layers of the component.
             * Therefore, we have this id and a view shouldn't have more than one scrollingContainer for UX reasons.
             *
             * See: https://quilljs.com/docs/configuration/ */
            if (document.querySelector(quillEditorScrollingContainerId)) {
                return quillEditorScrollingContainerId;
            }
            return "html, body";
        },
        editorOptions() {
            const options = {
                theme: "fuji",
                placeholder: this.placeHolder,
                scrollingContainer: this.scrollingContainer,
                modules: {
                    toolbar: "#" + this.actualToolbarId,
                    mention: editorMentionModule(this.intranet),
                    clipboard: {
                        matchers: [
                            [Node.TEXT_NODE, (node) => {
                                this.$emit("text-paste", node.data);
                                return quillPasteHandler(node.data);
                            }],
                        ],
                    },
                    eventHandling: {
                        eventHandlers: {
                            paste: [
                                (event) => {
                                    filesFromPaste(event).then((files) => {
                                        if (files.length > 0) {
                                            event.preventDefault();
                                            this.$emit("file-paste", files);
                                        }
                                    });
                                },
                            ],
                        },
                    },
                },
                // this whitelists all editor formatting features. this also prevents unsupported copy/pasting
                // extend this to support more features.
                formats: [
                    "bold",
                    "italic",
                    "link",
                    "list",
                    "header",
                    "mention",
                    "read-more",
                ],
            };

            if (this.embedImages) {
                options.imageHandler = ImageBlot.selectImage;
                options.formats.push("image");
            }

            return options;
        },
        /** @returns {Quill} */
        quill() {
            return this.$refs.quillEditor.quill;
        },
    },
    watch: {
        editMode() {
            this.changeQuillDependingOnEditMode();
            this.focus();
        },
    },
    mounted() {
        this.initialize();
    },
    methods: {
        /**
         * @public
         * @param {QuillDeltaOperations[]} value
         */
        setContents(value) {
            this.quill.setContents(value);
        },
        onEditorChange({ text, quill }) {
            const mentionCount = countMentions(quill.getContents());
            const isEmpty = text.trim().length === 0 && mentionCount === 0;
            this.$emit("update:isEmpty", isEmpty);
            if (isEmpty) {
                this.quill.getModule("mention").hideMentionList();
            }
        },
        focus() {
            if (this.editMode) {
                this.quill.focus();
            }
        },
        blur() {
            if (this.editMode) {
                this.quill.blur();
            }
        },
        initialize() {
            monkeyPatchGoogleTranslateFix(this.quill);
            // disable default image handler
            this.quill.getModule("toolbar").addHandler("image", () => {
            });
            this.changeQuillDependingOnEditMode();
            if (!this.preventAutoFocus) this.focus();
        },
        changeQuillDependingOnEditMode() {
            if (this.editMode) {
                this.truncated = false;
                this.quill.on("text-change", this.onQuillTextChange);
                this.quill.setContents(this.value);
                this.quill.enable();
            } else {
                this.quill.disable();
                this.quill.off("text-change", this.onQuillTextChange);
                if (this.truncated) {
                    const truncatedDeltaOps = truncateDelta({
                        ops: this.value,
                        maxLength: getGlobalConfiguration().posts_comments_truncated_length,
                        readMoreLabel: this.$t("read_more_label"),
                        onClick: () => {
                            this.truncated = false;
                            this.quill.setContents(this.value);
                        },
                    });
                    this.quill.setContents(truncatedDeltaOps);
                } else {
                    this.quill.setContents(this.value);
                }
            }
        },
        onQuillTextChange() {
            const { ops } = this.quill.getContents();
            this.$emit("input", ops);
        },
        async triggerVCard(e) {
            if (!this.editMode) {
                await wait(400);
                this.mentionedUserUid = e.detail.userUid;
                this.$nextTick(() => {
                    this.popper = createPopper(e.target, this.$refs.vcard, {
                        placement: "left-start",
                        modifiers: [
                            {
                                name: "preventOverFlow",
                                options: {
                                    padding: "1rem",
                                    boundary: document.querySelector(".page"),
                                },
                            },
                            {
                                name: "flip",
                                options: {
                                    padding: "1rem",
                                },
                            },
                        ],
                    });
                });
            }
        },
        async hideVCardAfterTime() {
            await wait(500);
            if (!this.keepVCardOpen) {
                this.hideVCard();
            }
        },
        hideVCard() {
            if (this.popper) this.popper.destroy();
            this.popper = null;
            this.keepVCardOpen = false;
            this.mentionedUserUid = "";
        },
        updateVCardPosition() {
            this.popper.update();
        }
    },
};
</script>

<style lang="scss" scoped>
input {
    display: none;
}

.toolbar-enter-active, .toolbar-leave-active {
    transition: transform .5s;
    transform: translateY(0);
}

.toolbar-enter, .toolbar-leave-to {
    transform: translateY(-4rem);
}

.ql-container {
    font-family: inherit;
}

.ql-toolbar {
    opacity: 0;
}

.ql-toolbar.ql-fuji {
    position: sticky;
    z-index: 5;
    top: 0;
    padding: 0 0 0.6rem;
    margin: 0;
    background: var(--background, #{$white});

    transition: opacity .5s ease, transform .5s ease;

    pointer-events: none;
    opacity: 0;
    transform: translate(0, -200%);

    &[data-edit-mode] {
        pointer-events: all;
        opacity: 1;
        transform: translate(0, 0);
    }
}

// handles edge cases if subtitle is empty
[data-modal="news-renderer"]:not([data-edit-mode]) textarea.news-sub-title.empty {
    margin-top: -30px;
    opacity: 0;
}

.ql-container {
    font-family: inherit;
}

.ql-clipboard {
    position: fixed !important;
    left: 50% !important;
    top: 50% !important;
}

.generic-quill-editor {
    width: 100%;

    &::v-deep {
        .ql-editor {
            min-height: 30px;

            &.ql-blank {
                min-height: 30px;

                &::before {
                    font-family: inherit;
                    font-size: 1rem;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
        }

        .ql-tooltip {
            left: 0 !important;
            z-index: 2100;
        }
    }
}

// ANIMATABLES
.animated {
    transition: all .3s ease-in-out;

    .ql-toolbar.ql-fuji {
        transition: all .3s ease;
        animation: fadeInFromTop .5s ease-in-out 0s normal both;
    }

    .editor-controls {
        transition: all .3s ease;
        overflow: visible;
        animation: fadeInFromBottom .5s ease-in-out 0s normal both;
    }
}

//ANIMATED COLLAPSED OPENING
$maxHeight: 1.7rem;

.open {
    .animated {

        .ql-toolbar.ql-fuji {
            opacity: 1;
            max-height: 40px;
            animation: none;
        }
    }
}

.collapsed {
    .animated {
        max-height: $maxHeight;
        overflow: hidden;

        &::v-deep {
            .ql-toolbar.ql-fuji {
                opacity: 0;
                margin-top: -0.4rem;
                margin-bottom: 0;
                max-height: 0;
                animation: none;
                pointer-events: none;
            }
        }
    }
}

@keyframes fadeInFromTop {
    from {
        transform: translateY(-100%);
        opacity: 0;
        max-height: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
        max-height: 100vh;

    }
}

@keyframes fadeInFromBottom {
    from {
        transform: translateY(100%);
        max-height: 0;
        opacity: 0;
    }
    to {
        transform: translateY(0);
        max-height: 100vh;
        opacity: 1;

    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
        max-height: 0;
    }
    to {
        opacity: 1;
        max-height: 100vh;

    }
}

@keyframes collapseOpen {
    from {
        max-height: $maxHeight;
    }
    to {
        max-height: 100vh;

    }
}

.padded-vcard {
    z-index: 5;
}

</style>
