import { VuexRootState } from "@web/store";
import { Module } from "vuex";
import {
    SET_HAS_MORE,
    SET_STORIES,
    SET_USER_STORIES
} from "@web/store/story/mutations";
import { storyService } from "@web/services";
import {
    DELETE_STORY_FROM_USER,
    FETCH_STORIES,
    FETCH_USER_STORIES,
    RESET_USER_STORIES
} from "@web/store/story/actions";
import { EverestStory } from "@backend/story/types";
import {
    GET_STORIES,
    GET_USER_STORIES,
    HAS_MORE
} from "@web/store/story/getters";

const getDefaultState = (): StoryModuleState => ({
    stories: [],
    userStories: [],
    hasMore: false
});

export interface StoryModuleState {
    stories: EverestStory[];
    userStories: EverestStory[];
    hasMore: boolean
}

export const STORY_MODULE_NAME = "story/";

export const STORY_MODULE = {
    strict: true,
    namespaced: true,
    state: getDefaultState(),
    getters: {
        [GET_STORIES]: state => {
            return state.stories;
        },
        [GET_USER_STORIES]: state => {
            return state.userStories;
        },
        [HAS_MORE]: state => {
            return state.hasMore;
        }
    },
    mutations: {
        [SET_STORIES](state, stories: EverestStory[]) {
            state.stories = stories;
        },
        [SET_USER_STORIES](state, stories: EverestStory[]) {
            state.userStories = stories;
        },
        [SET_HAS_MORE](state, hasMore: boolean) {
            state.hasMore = hasMore;
        }
    },
    actions: {
        async [FETCH_STORIES]({ commit, state, rootState }, { limit, initial = false } = {}) {
            const intranetUid = rootState.intranet.intranet!.uid;
            const previousStories = (initial || !state.stories) ? [] : state.stories;
            const story: EverestStory = previousStories?.[previousStories.length - 1];

            const loadedStories = await storyService.fetchStories({ intranetUid, story, limit: limit + 1 });
            const hasMore = loadedStories.length > limit;

            commit(SET_STORIES, previousStories.concat(loadedStories));
            commit(SET_HAS_MORE, hasMore);
        },
        async [FETCH_USER_STORIES]({ commit, state, rootState }, { userUid, limit } = {}) {
            const intranetUid = rootState.intranet.intranet!.uid;
            const loadedStories = await storyService.fetchStoriesForUser({ intranetUid, userUid, limit });
            commit(SET_USER_STORIES, loadedStories);
        },
        async [RESET_USER_STORIES]({ commit }) {
            commit(SET_USER_STORIES, []);
        },
        async [DELETE_STORY_FROM_USER]({ rootState }, { userUid, story }) {
            const intranetUid = rootState.intranet.intranet!.uid;
            await storyService.deleteStoryFromUser({ intranetUid, userUid, story });
        }
    }
} as Module<StoryModuleState, VuexRootState>;
