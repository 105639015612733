export default {
    bind(el, binding, vnode) {
        switch (binding.arg) {
            case "text":
                el.style.setProperty("--icon-color", `var(--${binding.value})`);
                el.style.color = `var(--${binding.value})`;
                break;

            case "outline":
                el.style.setProperty("--icon-color", `var(--${binding.value})`);
                el.style.color = `var(--${binding.value})`;
                el.style.border = `1px solid var(--${binding.value})`;
                break;

            default:
                el.style.setProperty("--icon-color", `var(--${binding.value}-text)`);
                el.style.backgroundColor = `var(--${binding.value})`;
                el.style.color = `var(--${binding.value}-text)`;
                break;
        }
    }
};
