import { VuexRootState } from "@web/store";
import { Module } from "vuex";
import { googleCalendarConnectService } from "@web/services";
import {
    SET_USER_CONNECTION_IS_FETCHING,
    SET_USER_CONNECTION_STATUS,
} from "@web/store/google/mutations";
import { FETCH_USER_CONNECTION_STATUS } from "@web/store/google/actions";

export interface GoogleCalendarModuleState {
    isFetching: boolean;
    isUserConnected: boolean;
}

export const GOOGLE_CALENDAR_MODULE_NAME = "googleCalendar/";

const getDefaultState = () => ({
    isFetching: false,
    isUserConnected: false,
});

export const GOOGLE_CALENDAR_MODULE = {
    namespaced: true,
    state: getDefaultState(),
    getters: {},
    mutations: {
        [SET_USER_CONNECTION_IS_FETCHING](state, isFetching) {
            state.isFetching = isFetching;
        },
        [SET_USER_CONNECTION_STATUS](state, isConnected) {
            state.isUserConnected = isConnected;
        },
    },
    actions: {
        async [FETCH_USER_CONNECTION_STATUS]({ state, commit, rootState }) {
            if (state.isFetching) {
                return;
            }
            const intranet = rootState.intranet.intranet;
            if (!intranet) {
                return;
            }
            commit(SET_USER_CONNECTION_IS_FETCHING, true);
            try {
                const isConnected = await googleCalendarConnectService.getUserConnectionStatus(intranet.uid);
                commit(SET_USER_CONNECTION_STATUS, isConnected);
            } finally {
                commit(SET_USER_CONNECTION_IS_FETCHING, false);
            }
        },
    },
} as Module<GoogleCalendarModuleState, VuexRootState>;
