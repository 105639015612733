import { VuexRootState } from "@web/store";
import { Module } from "vuex";
import { userService } from "@web/services";
import { EverestUser } from "@backend/user/types";
import {
    ADD_PERMISSION_INFORMATION_TO_USER,
    UPDATE_SEARCH_RESULT,
    RESET_PEOPLE_MODULE,
    SET_LOADING,
    REMOVE_USER_FROM_SEARCH_RESULT,
    SET_INITIAL_LOADING
} from "@web/store/people/mutations";
import {
    FETCH_INITIAL_PAGE,
    FETCH_NEXT_PAGE,
    REMOVE_USER,
    SET_PERMISSIONS_FOR_USER
} from "@web/store/people/actions";
import Vue from "vue";
import {
    IS_NEXT_PAGE
} from "@web/store/people/getters";

export const PEOPLE_MODULE_NAME = "people/";

export interface PeopleModuleState {
    query: string,
    users: EverestUser[];
    initialLoading: boolean;
    loading: boolean;
    nbHits: number; // algolia attributes, see https://www.algolia.com/doc/api-reference/widgets/stats/js/#widget-param-text
    nbPages: number;
    page: number;
}

const getDefaultState = () => ({
    query: "",
    users: [],
    initialLoading: false,
    loading: false,
    nbHits: 0,
    nbPages: 0,
    page: 0,
});

const PAGE_SIZE = 20;

export const PEOPLE_MODULE = {
    strict: true,
    namespaced: true,
    state: getDefaultState(),
    getters: {
        [IS_NEXT_PAGE]({ page, nbPages }) {
            return page + 1 < nbPages;
        }
    },
    mutations: {
        [UPDATE_SEARCH_RESULT](state, { hits, nbHits, nbPages, page, query, clearList }) {
            if (state.query !== query || clearList) {
                state.users = [];
                state.query = query;
            }
            state.users = state.users.concat(hits);
            state.nbHits = nbHits;
            state.nbPages = nbPages;
            state.page = page;
            state.loading = false;
            state.initialLoading = false;
        },
        [REMOVE_USER_FROM_SEARCH_RESULT](state, { userUid }) {
            state.users = state.users.filter(user => user.uid !== userUid);
        },
        [ADD_PERMISSION_INFORMATION_TO_USER](state, { userUid, permissions }) {
            const userIdx = state.users.findIndex(u => u.uid === userUid);
            Vue.set(state.users, userIdx, { ...state.users[userIdx], "@intranetPermissions": permissions });
        },
        [SET_LOADING](state) {
            state.loading = true;
        },
        [SET_INITIAL_LOADING](state) {
            state.initialLoading = true;
        },
        [RESET_PEOPLE_MODULE](state) {
            Object.assign(state, getDefaultState());
        },
    },
    actions: {
        async [FETCH_INITIAL_PAGE]({ commit, dispatch, rootState, state }, { query }) {
            const intranet = rootState.intranet.intranet;
            if (!intranet || state.initialLoading || state.loading) {
                return;
            }
            commit(SET_INITIAL_LOADING);
            const searchResult = await userService.search.search({ intranetUid: intranet.uid, query: query, pageNumber: 0, pageSize: PAGE_SIZE });
            commit(UPDATE_SEARCH_RESULT, {
                ...searchResult,
                clearList: true
            });
        },
        async [FETCH_NEXT_PAGE]({ commit, dispatch, rootState, state }, { query }) {
            const intranet = rootState.intranet.intranet;
            if (!intranet || state.initialLoading || state.loading) {
                return;
            }
            commit(SET_LOADING);
            const searchResult = await userService.search.search({ intranetUid: intranet.uid, query: query, pageNumber: state.page + 1, pageSize: PAGE_SIZE });
            commit(UPDATE_SEARCH_RESULT, searchResult);
        },
        async [SET_PERMISSIONS_FOR_USER]({ commit, dispatch, rootState }, { userUid, intranetPermissions }) {
            const intranet = rootState.intranet.intranet;
            if (!intranet) {
                return;
            }
            await userService.saveUserPermissionsForIntranet(intranet.uid, userUid, intranetPermissions);
        },
        async [REMOVE_USER]({ commit, dispatch, rootState }, userUid) {
            const intranet = rootState.intranet.intranet;
            if (!intranet) {
                return;
            }
            await userService.removeUserFromIntranet(intranet.uid, userUid);
            commit(REMOVE_USER_FROM_SEARCH_RESULT, { userUid });
        },
    }
} as Module<PeopleModuleState, VuexRootState>;
