import { VuexRootState } from "@web/store";
import { Module } from "vuex";
import {
    GET_OPEN_EDITOR,
    IS_EDITOR_OPEN,
} from "@web/store/editor/getters";
import { SET_OPEN_EDITOR } from "@web/store/editor/mutations";
import {
    REPLACE_OPEN_EDITOR,
    CLOSE_OPEN_EDITOR,
} from "@web/store/editor/actions";

export const EDITOR_MODULE_NAME = "editor/";

export interface EditorModuleState {
    openEditorUid?: string,
}

function getDefaultState(): EditorModuleState {
    return {
        openEditorUid: undefined,
    };
}

export const EDITOR_MODULE: Module<EditorModuleState, VuexRootState> = {
    namespaced: true,
    state: getDefaultState(),
    getters: {
        [GET_OPEN_EDITOR]: (state): string | undefined => {
            return state.openEditorUid;
        },
        [IS_EDITOR_OPEN]: (state): boolean => {
            return state.openEditorUid !== undefined;
        },
    },
    mutations: {
        [SET_OPEN_EDITOR](state, editorUid: string | undefined): void {
            state.openEditorUid = editorUid;
        },
    },
    actions: {
        [REPLACE_OPEN_EDITOR]({ commit }, editorUid: string): void {
            commit(SET_OPEN_EDITOR, editorUid);
        },
        [CLOSE_OPEN_EDITOR]({ commit }): void {
            commit(SET_OPEN_EDITOR, undefined);
        },
    },
};
