import { ApiClient } from "@web/api/ApiClient";
import { EverestStory } from "@backend/story/types";
import { ApiClientBuilder } from "@web/api/ApiClientBuilder";
import { cloudRunUrl } from "@web/cloud-run-url";

export class StoryResource {
    private readonly STORY_ENDPOINT = `${cloudRunUrl.story}/api/story/`;

    private api: Promise<ApiClient>;

    constructor() {
        this.api = new ApiClientBuilder().build();
    }

    public async fetchStories(intranetUid: string, limit: number, startAfter?: string): Promise<Array<EverestStory>> {
        const client = await this.api;
        const response = await client.get<Array<EverestStory>>(`${this.STORY_ENDPOINT}${intranetUid}/stories/`, { params: { limit, startAfter } });
        return response.data.filter((story) => story !== null && story !== undefined) as Array<EverestStory>;
    }

    public async fetchStoriesForUser(intranetUid: string, userUid: string, limit: number): Promise<Array<EverestStory>> {
        const client = await this.api;
        const response = await client.get<Array<EverestStory>>(`${this.STORY_ENDPOINT}${intranetUid}/stories/user/${userUid}?limit=${limit}&userReaction=true`);
        return response.data.filter((story) => story !== null && story !== undefined) as Array<EverestStory>;
    }

    public async fetchNextUser(intranetUid: string, userUid: string, limit: number, order: "asc" | "desc"): Promise<string> {
        const client = await this.api;
        const response = await client.get<{ userUid: string }>(`${this.STORY_ENDPOINT}${intranetUid}/user/next?startAfter=${userUid}&limit=${limit}&order=${order}`);
        return response.data.userUid;
    }

    public async deleteStoryFromUser(intranetUid: string, userUid: string, storyUid: string): Promise<void> {
        const client = await this.api;
        await client.delete(`${this.STORY_ENDPOINT}${intranetUid}/user/${userUid}/${storyUid}`);
        return Promise.resolve();
    }

    public async downloadUrlForStoryImage(intranetUid: string, storyUid: string, storageRef: string): Promise<{ url: string, expire: number }> {
        const client = await this.api;
        const response = await client.get<string>(`${this.STORY_ENDPOINT}${intranetUid}/download-url/${storyUid}?filePath=${storageRef}`);
        const expire: number = this.convertExpireFromHeaders(response);
        return { url: response.data, expire: expire };
    }

    private convertExpireFromHeaders(response: any) {
        if (response.headers["cache-control"]) {
            const values = response.headers["cache-control"].split("=");
            if (values.length > 0) {
                try {
                    return Number(values[1]);
                } catch (e) {
                    console.log("No number");
                }
            }
        }
        return 0;
    }
}
