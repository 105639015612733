<template>
    <div class="viewers-modal">
        <Modal
            name="viewers-modal"
            hide-footer
            :title="$t('viewers')"
            @before-open="onOpen"
            @before-close="onClose"
        >
            <div class="viewer-list">
                <avatar-list
                    mode="list"
                    :use-spinner="false"
                    :user-uids="viewerUids"
                    @clickOnUser="onClickInAvatarList"
                />
                <InfiniteScrollSpinner
                    v-if="hasNext"
                    class="spinner"
                    :loading="loading"
                    @load-more="loadMore"
                />
            </div>
        </Modal>
    </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import Modal from "@web/components/modals/Modal";
import InfiniteScrollSpinner from "@web/components/InfiniteScrollSpinner";
import AvatarList from "@web/components/AvatarList";
import { viewService } from "@/services";
import { INTRANET_MODULE_NAME } from "@/store/intranet/intranet";
import { INTRANET } from "@/store/intranet/getters";
import { analytics } from "@/analytics";
import { getGlobalConfiguration } from "@/global-config";

const VIEWERS_PAGE_LIMIT = 10;

export default Vue.extend({
    name: "ViewersModal",
    components: {
        Modal,
        AvatarList,
        InfiniteScrollSpinner,
    },
    data() {
        return {
            entity: null,
            loading: false,
            hasNext: true,
            viewers: [],
            liveUpdatedViewers: [],
            unsubscribeFromLiveUpdates: undefined,
            open: false,
            routeBeforeOpen: null,
            nativePopstateEventListener: null,
        };
    },
    computed: {
        ...mapGetters({ intranet: INTRANET_MODULE_NAME + INTRANET }),
        allViewers() {
            return this.liveUpdatedViewers.concat(this.viewers);
        },
        viewerUids() {
            return this.allViewers.map(r => r.userUid);
        },
        isSpinnerHidden() {
            // TODO: Fix spinner
            return true;
        },
    },
    mounted() {
        /*
         * React to browser back events without the Vue $router to be able bypass the normal routing and just close the modal.
         */
        window.addEventListener("popstate", this.nativePopstateEventListener);
    },
    destroyed() {
        window.removeEventListener("popstate", this.nativePopstateEventListener);
    },
    methods: {
        async onOpen(event) {
            this.open = true;
            this.loading = false;
            this.hasNext = true;
            this.entity = event.params.entity;

            analytics.log(getGlobalConfiguration().analytics_event_name_story_viewers_viewed);

            this.liveUpdatedViewers = [];
            this.viewers = await viewService.getViewers({
                intranetUid: this.intranet.uid,
                creatorUid: this.entity.creatorUid,
                entityUid: this.entity.uid,
                searchParams: {
                    limit: VIEWERS_PAGE_LIMIT,
                    startAfter: null,
                },
            });

            this.unsubscribeFromLiveUpdates = viewService.onViewersUpdates({
                intranetUid: this.intranet.uid,
                creatorUid: this.entity.creatorUid,
                entityUid: this.entity.uid,
                onCollectionUpdate: (views) => {
                    this.liveUpdatedViewers = views;
                },
            });

            this.routeBeforeOpen = this.$router.currentRoute.path;
        },
        onClose() {
            this.open = false;
            this.entityUid = null;
            this.currentFilterKey = null;
            if (this.unsubscribeFromLiveUpdates) this.unsubscribeFromLiveUpdates();
            this.unsubscribeFromLiveUpdates = undefined;
        },
        async loadMore() {
            if (this.viewers.length === 0) return;

            // viewedTimestamp of last viewers entry
            const startAfter = new Date(this.viewers[this.viewers.length - 1].viewedTimestamp.seconds * 1000);

            this.loading = true;
            try {
                const viewerNextPage = await viewService.getViewers({
                    intranetUid: this.intranet.uid,
                    creatorUid: this.entity.creatorUid,
                    entityUid: this.entity.uid,
                    searchParams: {
                        limit: VIEWERS_PAGE_LIMIT + 1,
                        startAfter: startAfter,
                    },
                });
                if (viewerNextPage.length <= VIEWERS_PAGE_LIMIT) {
                    this.hasNext = false;
                }
                this.viewers = this.viewers.concat(viewerNextPage.slice(0, VIEWERS_PAGE_LIMIT));
            } finally {
                this.loading = false;
            }
        },
        onClickInAvatarList() {
            this.$modal.hide("viewers-modal");
        },
    },
});
</script>

<style lang="scss" scoped>
.viewers-modal {
    z-index: 2147483002;

    .viewer-list {
        padding: 1.5rem;
        margin: 0 -1.5rem -1.5rem;
        display: flex;
        height: 28rem;
        flex-direction: column;
        overflow-y: auto;

        .avatar-list {
            animation: 0.15s ease-out;
        }
    }

    .user-row {
        margin-bottom: 1rem;
    }

    .viewer-icon {
        width: 24px;
    }
}
</style>
