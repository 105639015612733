<template>
    <LoadingWrapper :is-loading="loading">
        <EventListItem
            v-for="(event, index) in events"
            :key="event.uid"
            :options="optionsForMode"
            :event="event"
            :mode="mode"
            :is-last-item="index === events.length - 1"
        />
        <InfiniteScrollSpinner
            v-if="isNextPage && !disableLoadMore"
            :loading="loadingMore"
            @load-more="triggerLoadMoreEvents"
        />
        <slot
            v-if="events.length === 0"
            name="no-events"
        ></slot>
    </LoadingWrapper>
</template>

<script>
import EventListItem from "@web/views/events/EventListItem";

import {
    mapActions,
    mapGetters
} from "vuex";
import {
    EVENT_MODULE_NAME,
    EventListFilterKeys
} from "@web/store/event/event";
import {
    FETCH_EVENTS,
    INITIAL_FETCH_EVENTS
} from "@web/store/event/actions";
import {
    GET_EVENTS,
    IS_NEXT_PAGE
} from "@web/store/event/getters";
import { analytics } from "@web/analytics";
import { getGlobalConfiguration } from "@web/global-config";
import InfiniteScrollSpinner from "@web/components/InfiniteScrollSpinner";

export default {
    components: {
        EventListItem,
        InfiniteScrollSpinner,
    },
    props: {
        mode: {
            type: String,
            default: "default"
        },
        eventListKey: {
            type: String
        },
        limit: {
            type: Number
        },
        disableLoadMore: {
            type: Boolean
        }
    },
    data() {
        return {
            loading: false,
            loadingMore: false,
        };
    },
    methods: {
        ...mapActions({
            initialFetchEvents: EVENT_MODULE_NAME + INITIAL_FETCH_EVENTS,
            fetchEvents: EVENT_MODULE_NAME + FETCH_EVENTS,
        }),
        async triggerLoadMoreEvents() {
            this.loadingMore = true;
            await this.fetchEvents({ eventListKey: this.eventListKey });
            this.loadingMore = false;
            analytics.log(getGlobalConfiguration().analytics_event_name_events_overview_load_more);
        },
        myEventsButtonType(active) {
            return "secondary " + (active ? "outline" : "link");
        },
    },
    computed: {
        ...mapGetters({
            getEventsByListKey: EVENT_MODULE_NAME + GET_EVENTS,
            isNextPageByListKey: EVENT_MODULE_NAME + IS_NEXT_PAGE,
        }),
        isMyEventsList() {
            return this.eventListKey === EventListFilterKeys.myEvents;
        },
        events() {
            const events = this.getEventsByListKey({ eventListKey: this.eventListKey });
            const now = new Date().valueOf() / 1000;
            return events.filter(event => event.endTimestamp._seconds >= now).slice(0, this.limit);
        },
        isNextPage() {
            return this.isNextPageByListKey({ eventListKey: this.eventListKey });
        },
        optionsForMode() {
            if (this.mode === "sidebar") {
                return {
                    displayAttendeeStatus: false,
                    displayLastAttendees: false,
                    displayAttendeesCount: false,
                    displayLocation: false,
                };
            }
            if (this.mode === "dashboard") {
                return {
                    displayAttendeeStatus: true,
                    displayLastAttendees: true,
                    displayAttendeesCount: false,
                    displayLocation: false,
                };
            }
            return {
                displayAttendeeStatus: true,
                displayLastAttendees: true,
                displayAttendeesCount: true,
                displayLocation: true,
            };
        }
    },
    async created() {
        this.loading = true;
        await this.initialFetchEvents({ eventListKey: this.eventListKey, limit: this.limit });
        this.loading = false;
    },
};
</script>

<style lang="scss" scoped>
</style>
