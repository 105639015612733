import { ApiClientBuilder } from "@web/api/ApiClientBuilder";
import { ApiClient } from "@web/api/ApiClient";
import {
    BaseUserProfile,
    UserProfile,
    UserProfileUpdatePayload,
} from "@backend/user-profile/types";
import VueI18n from "vue-i18n";
import { FileUploadService } from "@web/services/file-upload";
import { DeltaFormat } from "@backend/entity/types";
import { EverestUser } from "@backend/user/types";
import { cloudRunUrl } from "@web/cloud-run-url";

export class ProfileService {
    public static readonly userEndpoint = `${cloudRunUrl.user}/api/user/user/`;
    public readonly fallbackProfile: Omit<EverestUser, "uid">;

    private readonly api: Promise<ApiClient>;

    private cacheKey!: string;
    private localStorageKey = "linchpin-cloud-profile-service-cache-key";
    private i18n: VueI18n;

    constructor(i18n: VueI18n, private readonly fileUploadService: FileUploadService) {
        this.api = new ApiClientBuilder().build();
        this.i18n = i18n;
        this.fallbackProfile = {
            displayName: this.i18n.t("unknown_user") as string,
            photoURL: null,
            email: null,
            emailVerified: false,
        };
        this.loadCacheKey();
    }

    public async getBaseUserProfile(intranetUid: string, userUid: string): Promise<BaseUserProfile> {
        const client = await this.api;
        const response = await client.get<BaseUserProfile>(this.getUserProfileUrl(intranetUid, userUid, false, true));
        return response.data;
    }

    public async getFullUserProfile(intranetUid: string, userUid: string): Promise<UserProfile> {
        const client = await this.api;
        const response = await client.get<UserProfile>(
            this.getUserProfileUrl(intranetUid, userUid, true, true),
            { params: { format: DeltaFormat.web } },
        );
        return response.data;
    }

    public async updateUserProfile(intranetUid: string, userUid: string, update: UserProfileUpdatePayload): Promise<void> {
        const client = await this.api;
        await client.patch<BaseUserProfile>(
            this.getUserProfileUrl(intranetUid, userUid),
            update,
            { params: { format: DeltaFormat.web } },
        );
        this.refreshCacheKey();
    }

    public async updateProfileImage(intranetUid: string, userUid: string, file: Blob): Promise<void> {
        const uploadTarget = await this.fileUploadService.uploadFile(
            `${this.getUserProfileUrl(intranetUid, userUid)}/upload-profile-image`,
            file,
            userUid,
            intranetUid,
        );
        await this.updateUserProfile(intranetUid, userUid, { profileImageStoragePath: uploadTarget.path });
    }

    public refreshCacheKey() {
        this.cacheKey = Math.random().toString(36).substring(7);
        localStorage.setItem(this.localStorageKey, this.cacheKey);
    }

    private getUserProfileUrl(intranetUid: string, userUid: string, fullProfile: boolean = false, withCacheKey: boolean = false) {
        let userProfileUrl = ProfileService.userEndpoint + intranetUid + "/" + userUid;
        if (fullProfile) {
            userProfileUrl += "/details";
        }
        if (withCacheKey) {
            userProfileUrl += "?cacheKey=" + this.cacheKey;
        }
        return userProfileUrl;
    }

    private loadCacheKey() {
        const keyFromStorage = localStorage.getItem(this.localStorageKey);
        if (keyFromStorage !== null) {
            this.cacheKey = keyFromStorage;
        } else {
            this.refreshCacheKey();
        }
    }
}
