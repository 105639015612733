<template>
    <div class="macro-error">
        <div class="blurred">
            <slot ></slot>
        </div>
        <div class="error-overlay card padding-medium centered depth-3">
            <p
                v-if="!isUserLoggedIn"
                class="bold"
            >
                {{ $t('confluence_cloud_macro_error_user_not_logged_in') }}
            </p>
            <p
                v-if="!isUserLoggedIn"
                class="meta"
            >
                {{ $t('confluence_cloud_macro_error_user_not_logged_in_subline', [$t(i18nFeatureName).toLowerCase()]) }}
            </p>
            <p
                v-if="isUserLoggedIn && isPermissionDeniedForIntranet"
                class="bold"
            >
                {{ $t('confluence_cloud_macro_error_user_no_permissions') }}
            </p>
            <p
                v-if="isUserLoggedIn && isPermissionDeniedForIntranet"
                class="meta"
            >
                {{ $t('confluence_cloud_macro_error_user_no_permissions_subline') }}
            </p>
            <div>
                <a
                    :href="hostUrl"
                    target="_blank"
                >
                    <app-button type="link primary">{{ $t("confluence_cloud_macro_error_open_linchpin_cloud") }}
                    </app-button>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import { hostUrl } from "@web/firebaseConfig";

export default Vue.extend({
    name: "MacroError",
    props: {
        isUserLoggedIn: {
            type: Boolean
        },
        isPermissionDeniedForIntranet: {
            type: Boolean
        },
        i18nFeatureName: {
            type: String
        },
    },
    data() {
        return {
            hostUrl
        };
    },
});
</script>

<style lang="scss" scoped>

.blurred {
    filter: blur(5px);
}

.grey-box {
    background: var(--lowest-contrast, #{$off-white});
    border-radius: 5px;

    &.dummy-headline {
        height: 1.5rem;
        width: 80%;
    }

    &.dummy-teaser-image {
        padding-top: 60%;
        width: 100%;
        margin-bottom: 0.75rem;
    }
}

.error-overlay {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    left: 50%;
    transform: translate(-50%, -50%);
    top: 50%;
    max-width: 400px;
}
</style>
