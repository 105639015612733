import { ApiClient } from "@web/api/ApiClient";
import { ApiClientBuilder } from "@web/api/ApiClientBuilder";
import { analytics } from "@web/analytics";
import { getGlobalConfiguration } from "@web/global-config";
import { cloudRunUrl } from "@web/cloud-run-url";

export class AtlassianConnectService {
    private api: Promise<ApiClient>;

    public static readonly atlassianConnectionEndpoint = `${cloudRunUrl.thirdPartyAtlassian}/api/atlassian/`;

    constructor() {
        this.api = new ApiClientBuilder().build();
    }

    public async getIntranetConnectionStatus(intranetUid: string): Promise<any> {
        const client = await this.api;
        const response = await client.get<any>(`${AtlassianConnectService.atlassianConnectionEndpoint + intranetUid}/connect-intranet-status`);
        return response.data;
    }

    public async getUserConnectionStatus(intranetUid: string): Promise<boolean> {
        const client = await this.api;
        const response = await client.get<any>(`${AtlassianConnectService.atlassianConnectionEndpoint + intranetUid}/connect-user-status`);
        return response.data;
    }

    public async removeConnection(intranetUid: string): Promise<any> {
        const client = await this.api;
        const response = await client.delete<any>(`${AtlassianConnectService.atlassianConnectionEndpoint + intranetUid}/connect-intranet`);
        analytics.log(getGlobalConfiguration().analytics_event_name_atl_cloud_revoked_by_admin);
        return response.data;
    }

    public async removeUserConnection(intranetUid: string) {
        const client = await this.api;
        const response = await client.delete<any>(`${AtlassianConnectService.atlassianConnectionEndpoint + intranetUid}/connect-user`);
        analytics.log(getGlobalConfiguration().analytics_event_name_atl_cloud_revoked_by_user);
        return response.data;
    }

    public async startConnectionFlow(intranetUid: string, instanceUrl: string, withConfluence: boolean, withJira: boolean): Promise<any> {
        const client = await this.api;
        const response = await client.get<any>(`${AtlassianConnectService.atlassianConnectionEndpoint + intranetUid}/connect-intranet`, {
            params: { confluence: withConfluence || undefined, jira: withJira || undefined, instanceUrl }
        });
        return window.open(response.data);
    }

    public async startUserConnectionFlow(intranetUid: string): Promise<any> {
        const client = await this.api;
        const response = await client.get<any>(`${AtlassianConnectService.atlassianConnectionEndpoint + intranetUid}/connect-user`);
        return window.open(response.data);
    }
}
