import firebase from "firebase/app";
import {
    firebaseConfig,
    activatePerformanceStats,
} from "@web/firebaseConfig";
import "firebase/performance";

firebase.initializeApp(firebaseConfig);
export { firebase };
export const firestore = firebase.firestore();

// Initialize Performance Monitoring and get a reference to the service
if (activatePerformanceStats) {
    const perf = firebase.performance();
}
