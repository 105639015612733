<template>
    <div>
        <slot
            :changedTotalReactions="changedTotalReactions"
            :onReact="onReact"
            :openReactionsModal="openReactionsModal"
        ></slot>
    </div>
</template>

<script>
import { registerSnapshotListenerOnReactableEntity } from "@/lib/reactable-entity-snapshot-listener";
import { reactionService } from "@/services";
import { mapGetters } from "vuex";
import { INTRANET_MODULE_NAME } from "@/store/intranet/intranet";
import { INTRANET_UID } from "@/store/intranet/getters";
import { SubscriptionOnIntersection } from "@/lib/subscription-on-intersection";

export default {
    name: "ReactionContainer",
    props: {
        contentType: { type: String, required: true },
        rootEntityUid: { type: String, required: true },
        entityUid: { type: String },
        path: { type: String, default: null },
        selectedReactionType: { type: String, default: null },
        totalReactions: { type: Object },
    },
    data() {
        return {
            reactionTypeChanges: [],
            changedTotalReactions: {
                dislike: 0, clap: 0, love: 0, like: 0, smile: 0
            },
            unsubscribeReactionsListener: () => {}, // placeholder for unsubscribe callback set in watchForLiveReactions
            subscriptionOnIntersection: undefined,
        };
    },
    computed: {
        ...mapGetters({
            intranetUid: INTRANET_MODULE_NAME + INTRANET_UID,
        }),
    },
    mounted() {
        this.changedTotalReactions = { ...this.changedTotalReactions, ...this.totalReactions };
        this.registerObserver();
    },
    destroyed() {
        this.unregisterObserver();
        this.unsubscribeReactionsListener();
    },
    methods: {
        onReactableDocSnapshot(snapshot) {
            const docData = snapshot.data();
            if (docData && docData.reactions) {
                this.changedTotalReactions = docData.reactions;
            }
        },
        subscribeReactionListener() {
            this.unsubscribeReactionsListener = registerSnapshotListenerOnReactableEntity(
                this.intranetUid,
                this.contentType,
                this.rootEntityUid,
                this.path,
                this.onReactableDocSnapshot);
        },
        registerObserver() {
            if (this.subscriptionOnIntersection) return;
            this.subscriptionOnIntersection = new SubscriptionOnIntersection({
                element: this.$el,
                subscribe: this.subscribeReactionListener,
                unsubscribe: this.unsubscribeReactionsListener
            });
            this.subscriptionOnIntersection.init();
        },
        unregisterObserver() {
            if (!this.subscriptionOnIntersection) return;
            this.subscriptionOnIntersection.destroy();
            this.subscriptionOnIntersection = undefined;
        },
        openReactionsModal(reactionType) {
            this.$modal.show("reactions-modal", {
                reactionType,
                entityUid: this.entityUid,
                totalReactions: this.changedTotalReactions,
            });
        },
        async onReact(reactionType) {
            const lastChange = [...this.reactionTypeChanges].pop();
            const lastReactionType = lastChange === null ? lastChange : lastChange || this.selectedReactionType;
            const isReactionRemoval = reactionType === null;
            if (reactionType === lastReactionType) {
                return;
            }
            if (!isReactionRemoval) {
                this.changedTotalReactions[reactionType]++;
            }
            if (lastReactionType) {
                this.changedTotalReactions[lastReactionType]--;
            }
            this.$emit("react", reactionType);
            this.reactionTypeChanges.push(reactionType);
            try {
                await reactionService.react(
                    this.intranetUid,
                    this.contentType,
                    this.rootEntityUid,
                    this.path,
                    reactionType,
                );
                if (this.reactionTypeChanges.length > 1) {
                    this.reactionTypeChanges.shift();
                }
            } catch {
                if (!isReactionRemoval) {
                    this.changedTotalReactions[reactionType]--;
                }
                this.reactionTypeChanges.pop();
                if (lastReactionType) {
                    this.changedTotalReactions[lastReactionType]++;
                }
                this.$notify({
                    group: "app",
                    type: "error",
                    text: `${this.$t("reactions_failed_to_send_reaction")}`,
                });
            }
        },
    }
};
</script>
