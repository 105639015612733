<template>
    <div>
        <topic-multi-select
            v-model="topicStore.state.topics"
            :search="search"
            @input="emitInput"
            @create-topic="openCreateTopicModal"
        />
        <create-topic-modal
            :name="createTopicModalName"
            :topic-store="topicStore"
            follow
            @topic-created="emitInput"
        />
    </div>
</template>

<script>
import TopicMultiSelect from "@web/components/topics/TopicMultiSelect";
import {
    getTopics,
    useTopics,
} from "@web/store/topic/topic-local-store";
import { topicByUsageSearchService } from "@web/store/topic/TopicSearchService";
import { indexEntityToTopic } from "@web/services/AbstractSearchService";
import CreateTopicModal from "@web/components/topics/CreateTopicModal";
import { mapGetters } from "vuex";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import { INTRANET_UID } from "@web/store/intranet/getters";

/**
 * A variant of [TopicMultiSelect](#/Components/TopicMultiSelect) which uses topic uids as `v-model` and fetches topics for displaying, editing and autocompletion.
 */
export default {
    name: "LoadingTopicMultiSelect",
    components: {
        CreateTopicModal,
        TopicMultiSelect,
    },
    props: {
        /** List of topic uids.
         * @model
         * @type {string[]} */
        value: { type: Array, default: () => [] },
    },
    computed: {
        ...mapGetters({
            intranetUid: INTRANET_MODULE_NAME + INTRANET_UID,
        }),
        createTopicModalName() {
            return `create-topic-modal-${this._uid}`;
        },
        /** the store containg all selected topics */
        topicStore() {
            return useTopics(this.$store);
        },
    },
    watch: {
        value: {
            handler() {
                this.fetchTopics();
            },
            immediate: true,
        },
    },
    methods: {
        openCreateTopicModal(query) {
            this.$modal.show(this.createTopicModalName, { topicName: query });
        },
        /** fetches all topics based on the current value */
        fetchTopics() {
            this.topicStore.methods.fetchTopics(() => getTopics(this.value));
        },
        /** search to provide suggestions for the autocompletion */
        async search(query) {
            // always search 5 suggestions more, to filter out possible topics that are already in the value
            const result = await topicByUsageSearchService.search({
                intranetUid: this.intranetUid,
                query,
                pageSize: 10,
            });
            return result.hits
                .filter(topicIndexEntity => !this.value.includes(topicIndexEntity.uid))
                .map(indexEntityToTopic)
                .slice(0, 5);
        },
        emitInput() {
            const topicUids = this.topicStore.state.topics.map(topic => topic.uid);
            /** emitted when the topic selection changes
             *  @property {string[]} value - the selected topic uids */
            this.$emit("input", topicUids);
        },
    },
};
</script>

<style lang="scss" scoped>

</style>
