<template>
    <div class="flex flex-center flex-wrap">
        <topic-label
            v-if="isEmpty"
            :label-classes="labelClasses"
            readonly
            to="#"
            @click.native.prevent="startQuery"
        >
            {{ $t("topic_select_add") }}
        </topic-label>
        <topic-label
            v-for="topic in value"
            :key="topic.uid"
            :label-classes="labelClasses"
        >
            {{ topic.name }}
            <template #action>
                <a
                    class="px-xxsmall"
                    @click="removeValue(topic)"
                >
                    <icon
                        color="var(--high-contrast)"
                        name="x"
                        size="x-small"
                    />
                </a>
            </template>
        </topic-label>
        <!--
        Emitted when no topic was selected but the option to create a new topic was selected.
        @event create-topic
        @property {string} query - the current query in the input
        -->
        <topic-select-autocomplete
            v-if="query !== undefined"
            :label-classes="labelClasses"
            :query.sync="query"
            :search="search"
            class="topic-autocomplete mright-xsmall"
            @input="addValue"
            @create-topic="$emit('create-topic', $event)"
        />
        <topic-label
            v-if="showPlus"
            :label-classes="labelClasses"
            readonly
            to="#"
            @click.native.prevent="toggleQuery"
        >
            <icon
                name="plus"
                size="x-small"
            />
        </topic-label>
        <span class="text-medium-contrast mleft-xsmall small-text text-nowrap">
            {{ value.length }} / {{ inputValidationContentEntityMaxTopics }}
        </span>
    </div>
</template>

<script>
import TopicLabel from "@web/components/topics/TopicLabel";
import { getGlobalConfiguration } from "@web/global-config";
import TopicSelectAutocomplete from "@web/components/topics/TopicSelectAutocomplete";

const inputValidationContentEntityMaxTopics = getGlobalConfiguration().input_validation_content_entity_max_topics;
const labelClasses = "padding-xxsmall small-text";

/**
 * A multiselect for topics on content entities.
 * It is based on [LoadingTopicMultiSelect](#/Components/TopicMultiSelect?id=topicselectautocomplete).
 * Consider preferring [LoadingTopicMultiSelect](#/Components/TopicMultiSelect?id=loadingtopicmultiselect).
 *
 * @requires ./TopicSelectAutocomplete.vue
 * @requires ./LoadingTopicMultiSelect.vue
 */
export default {
    name: "TopicMultiSelect",
    components: {
        TopicSelectAutocomplete,
        TopicLabel,
    },
    props: {
        /** List of selected topics.
         * @model
         * @type {Topic[]} */
        value: { type: Array, default: () => [] },
        /** Search callback for autocompletion.
         * @type {(query: string) => Promise<Topic[]>} */
        search: { type: Function, required: true },
    },
    data() {
        return {
            /** @type {string | undefined} */
            query: undefined,
            labelClasses,
            inputValidationContentEntityMaxTopics,
        };
    },
    computed: {
        isEmpty() {
            return this.value.length === 0 && this.query === undefined;
        },
        showPlus() {
            const topicNum = this.value.length;
            return (inputValidationContentEntityMaxTopics > topicNum && topicNum > 0) || this.query !== undefined;
        },
    },
    methods: {
        startQuery() {
            this.query = "";
        },
        endQuery() {
            this.query = undefined;
        },
        toggleQuery() {
            if (this.query === undefined) {
                this.startQuery();
            } else {
                this.endQuery();
            }
        },
        addValue(newValue) {
            const value = [...this.value, newValue];
            /** emitted when the topic selection changes
             *  @property {Topic[]} value - the selected topics */
            this.$emit("input", value);
        },
        removeValue(topic) {
            const value = this.value.filter(valueTopic => valueTopic.uid !== topic.uid);
            this.$emit("input", value);
        },
    },
};
</script>

<style lang="scss" scoped>
.topic-autocomplete {
    * + & {
        margin-left: $spacing-xsmall;
    }
}
</style>

<docs>
Empty input:
```vue
<template>
    <div>
        <topic-multi-select
            v-model="value"
            :search="search"
        />
        <pre class="mtop-small"><code>{{ value }}</code></pre>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                value: [],
            };
        },
        methods: {
            async search(query) {
                return globalThis.demo.topics.filter(topic => topic.name.includes(query));
            },
        },
    };
</script>
```

Prefilled input:
```vue
<template>
    <div>
        <topic-multi-select
            v-model="value"
            :search="search"
        />
        <pre class="mtop-small"><code>{{ value }}</code></pre>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                value: globalThis.demo.topics.slice(0, 3),
            };
        },
        methods: {
            async search(query) {
                return globalThis.demo.topics.filter(topic => topic.name.includes(query));
            },
        },
    };
</script>
```

Max filled:
```vue
<template>
    <div>
        <topic-multi-select
            v-model="value"
            :search="search"
        />
        <pre class="mtop-small"><code>{{ value }}</code></pre>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                value: globalThis.demo.topics.slice(0, 5),
            };
        },
        methods: {
            async search(query) {
                return globalThis.demo.topics.filter(topic => topic.name.includes(query));
            },
        },
    };
</script>
```

Max filled with limited width:
```vue
<template>
    <div>
        <div style="width: 15rem">
            <topic-multi-select
                v-model="value"
                :search="search"
            />
        </div>
        <pre class="mtop-small"><code>{{ value }}</code></pre>
    </div>
</template>
<script>
    export default {
        data() {
            return {
                value: globalThis.demo.topics.slice(0, 5),
            };
        },
        methods: {
            async search(query) {
                return globalThis.demo.topics.filter(topic => topic.name.includes(query));
            },
        },
    };
</script>
```

---

All examples use this demo data for topics:
```vue noeditor
const demoVar = "globalThis.demo.topics";
const demoData = eval(demoVar)
<pre class="lang-js"><code>{{ demoVar }} = {{ demoData }}</code></pre>
```
</docs>
