import {
    AxiosAdapter,
    AxiosResponse,
} from "axios";
import {
    PendingRequestCache,
    PendingRequestCacheParams,
} from "@web/lib/PendingRequestCache";

/**
 * An dapter to allow calling the same resource parallelly in code but actually perform only one request.
 *
 * With this it is possible to call the same URL several times without waiting for each call, but only for one.
 * This example performs only one HTTP call to `http://example.de/whatever`:
 * ```
 * const api = axios.create({
 *   adapter: setupPendingCache(axios.defaults.adapter!),
 * });
 * await Promise.allSettled([
 *   api.get("http://example.de/whatever"),
 *   api.get("http://example.de/whatever"),
 *   api.get("http://example.de/whatever"),
 * ]);
 * ```
 * While this performs 3 HTTP calls:
 * ```
 * const api = axios.create();
 * await Promise.allSettled([
 *   api.get("http://example.de/whatever"),
 *   api.get("http://example.de/whatever"),
 *   api.get("http://example.de/whatever"),
 * ]);
 * ```
 *
 * In order to work this way, the implementation holds the first running promise for 10ms and reuses the same promise for following calls to the same URL.
 * The responses are cloned for each call to maintain completely stateless outside the axios call.
 */
export function setupPendingCache(defaultAdapter: AxiosAdapter, cacheOptions: PendingRequestCacheParams = {}): AxiosAdapter {
    const pendingGetRequestCache = new PendingRequestCache<AxiosResponse>(cacheOptions);

    return async function cachePendingGetRequestsAdapter(config): Promise<AxiosResponse> {
        const { method, baseURL, url, params } = config;
        if (method?.toLowerCase() === "get") {
            const key = JSON.stringify({ baseURL, url, params });
            return pendingGetRequestCache.waitFor(key, () => defaultAdapter(config));
        }
        return defaultAdapter(config);
    };
}
