import { cloudRunUrl } from "@web/cloud-run-url";
import {
    Intranet,
    IntranetUpdateRequest,
    PermissionsForIntranet,
    PermissionsForIntranetUid
} from "@backend/intranet/types";
import axios,
{ AxiosRequestConfig } from "axios";
import firebase from "firebase";
import { ApiClient } from "@web/api/ApiClient";
import { ApiClientBuilder } from "@web/api/ApiClientBuilder";
import localforage from "localforage";

export class IntranetService {
    private readonly intranetListApi: Promise<ApiClient>;
    private readonly intranetApi: Promise<ApiClient>;
    private clearIntranetApiCacheFn: (callback?: (err: any) => void) => Promise<void>;

    constructor() {
        this.intranetListApi = new ApiClientBuilder().build();
        const intranetApiLocalforage = localforage.createInstance({
            driver: [
                localforage.INDEXEDDB,
                localforage.LOCALSTORAGE,
            ],
            name: "linchpin-everest-intranet-cache"
        });
        this.clearIntranetApiCacheFn = intranetApiLocalforage.clear;
        this.intranetApi = new ApiClientBuilder().withCache({
            maxAge: 5 * 60 * 1000,
            store: intranetApiLocalforage,
            key(req: AxiosRequestConfig): string {
                return req.url as string;
            }
        }).build();
    }

    public async getIntranet(intranetUid: string, killCache: boolean = false): Promise<Intranet | null> {
        if (killCache) {
            await this.clearIntranetApiCacheFn();
        }
        const client = await this.intranetApi;
        const { data } = await client.get<PermissionsForIntranet>(`${cloudRunUrl.intranet}/api/intranetRetrieval/${intranetUid}`);
        if (data) {
            return { ...data.intranet, uid: intranetUid };
        }
        return null;
    }

    public async getPermissionsForIntranetForUser(intranetUid: string, userId: string, killCache: boolean = false): Promise<PermissionsForIntranetUid | null> {
        if (killCache) {
            await this.clearIntranetApiCacheFn();
        }
        const client = await this.intranetApi;
        const { data } = await client.get<PermissionsForIntranet>(`${cloudRunUrl.intranet}/api/intranetRetrieval/${intranetUid}`);
        if (data) {
            return {
                intranetUid, roles: data.roles
            };
        }
        return null;
    }

    public async getAvailableIntranetsForCurrentUser(): Promise<PermissionsForIntranet[]> {
        const client = await this.intranetListApi;
        const { data } = await client.get<PermissionsForIntranet[]>(`${cloudRunUrl.intranet}/api/intranetRetrieval`);
        return data;
    }

    public async registerNewIntranet(intranetDisplayName: string, demonstrationIntranet: boolean = false): Promise<string> {
        return new Promise(async(resolve, reject) => {
            const firebaseUser = firebase.auth().currentUser;
            if (!firebaseUser) {
                reject();
                return;
            }
            const idToken = await firebaseUser.getIdToken();
            const config = {
                headers: { Authorization: "Bearer " + idToken },
                params: { demo: demonstrationIntranet }
            };
            try {
                const response = await axios.post(`${cloudRunUrl.intranet}/api/intranet/create`, {
                    newIntranetName: intranetDisplayName
                }, config);
                if (response.status === 200) {
                    resolve(response.data.id);
                    return;
                }
                reject();
            } catch (e) {
                reject();
            }
        });
    }

    public async clearIntranetApiCache() {
        await this.clearIntranetApiCacheFn();
    }

    public async removeIntranet(intranetUid: string) {
        const client = await this.intranetApi;
        return client.delete(`${cloudRunUrl.intranet}/api/intranetRemoval/${intranetUid}`);
    }

    public async updateIntranet(intranetUid: string, intranetUpdateRequest: IntranetUpdateRequest) {
        const client = await this.intranetApi;
        await client.put(`${cloudRunUrl.intranet}/api/intranet/update/${intranetUid}`, {
            ...intranetUpdateRequest
        });
        return this.getIntranet(intranetUid, true);
    }
}
