import { EverestContentEntity } from "@backend/entity/types";

export enum DisplayType {
    video = "video",
    contentEntity = "content-entity",
}

export enum SharableContentEntity {
    event = "event",
    news = "news",
    team = "team",
}

export enum VideoPlatform {
    youtube = "youtube",
}

export interface SharedContentData<DISPLAYTYPE extends DisplayType, METADATA> {
    url: string;
    displayType: DISPLAYTYPE;
    metaData: METADATA;
}

export type VideoContentData = SharedContentData<typeof DisplayType.video, { platform: VideoPlatform, videoID: string }>;
export type EverestContentData = SharedContentData<typeof DisplayType.contentEntity, { type: SharableContentEntity, uid: string, path?: string }>;
export type AnySharedContentData = VideoContentData | EverestContentData;
// firestore collection is `external-content`
export type SharedContentInFirestore = AnySharedContentData & EverestContentEntity;

export type SharedContent = SharedContentInFirestore & {
    uid: string;
}
