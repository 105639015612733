<template>
    <div
        :class="[size]"
        class="date-tile"
    >
        <div class="day">
            {{ day }}
        </div>
        <div class="month">
            {{ month }}
        </div>
    </div>
</template>

<script>
import dayjs from "dayjs";

/**
 * Displays a calendar as squared tile.
 */
export default {
    name: "EventDateTile",
    props: {
        /** @type {Date | Timestamp} */
        date: {
            type: [Object, Date],
            default: new Date(),
        },
        /** @values "", "big" */
        size: { type: String, validator: value => ["", "big"].includes(value) },
    },
    computed: {
        internalDate() {
            if (this.date instanceof Date) {
                return this.date;
            }
            if (Number.isInteger(this.date._seconds)) {
                return new Date(this.date._seconds * 1000);
            }
        },
        day() {
            return dayjs(this.internalDate).format("DD");
        },
        month() {
            return dayjs(this.internalDate).format("MMM");
        },
    },
};
</script>

<style lang="scss" scoped>
.date-tile {
    position: relative;
    flex: 0 0 auto;
    width: 3rem;
    height: 3rem;
    background-color: var(--lowest-contrast, #{$off-white});
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .day, .month {
        text-align: center;
        line-height: 1;
    }

    .day {
        font-size: 1.25em;
        font-weight: 700;
        margin: 0 0 0.1rem;
        padding: 0;
    }

    .month {
        font-size: 0.75em;
        font-weight: 500;
        opacity: 0.5;
        margin: 0;
        padding: 0;
    }

    &.big {
        width: 4rem;
        height: 4rem;

        .day {
            font-size: 2rem;
        }

        .month {
            font-size: 1rem;
        }
    }
}
</style>

<docs>
```vue
<event-date-tile/>
<hr/>
<event-date-tile size="big"/>
```
</docs>
