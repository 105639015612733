<template>
    <div class="pos-relative">
        <!--
        @slot Slot to maintain an input with its own state
        @binding {() => void} selectUpperEntry - function to call in order to select the currently next upper entry
        @binding {() => void} selectLowerEntry - function to call in order to select the currently next lower entry
        @binding {() => void} selectEntry - function to call in order to select the current entry
        -->
        <slot
            name="input"
            v-bind="{selectUpperEntry, selectLowerEntry, selectEntry}"
        >
        </slot>
        <div
            :class="dropdownClasses"
            class="pos-absolute background-background"
        >
            <!--
            @slot Slot to list all available suggestions to navigate through. Is displayed absolutely positioned beneath the input slot.
            @binding {number} selectedIndex - the current selected index within the suggestions list
            @binding {(number) => void} setSelectedIndex - function to change the selected index i.e. through mouse navigation
            -->
            <slot
                name="suggestions"
                v-bind="{selectedIndex, setSelectedIndex}"
            >
            </slot>
        </div>
    </div>
</template>

<script>
/**
 * Basic wrapper component for a vertical autocompletion limited to key navigation, i.e. by the arrow keys.
 * Selections made by mouse must be handled manually outside the component.
 */
export default {
    name: "SelectAutocomplete",
    props: {
        /** The available suggestions for arrow navigation and selection.
         *  Use the suggestions slot to render. */
        suggestions: Array,
        /** Override of the dropdown classes to add more custom styling aove the default positioning classes. */
        dropdownClasses: { type: [String, Array, Object], default: "depth-1 border-radius-small" },
    },
    data() {
        return {
            selectedIndex: undefined,
        };
    },
    methods: {
        /**
         * A necessary method to make manipulation of the data state possible.
         * Slot scopes pass a copy instead of a reactive reference.
         * @link https://v2.vuejs.org/v2/guide/reactivity.html
         */
        setSelectedIndex(selectedIndex) {
            this.selectedIndex = selectedIndex;
        },
        async fetchSuggestions() {
            await this.search();
        },
        selectUpperEntry() {
            if (this.suggestions.length === 0) return;
            if (this.selectedIndex === undefined) return;
            if (this.selectedIndex <= 0) {
                this.selectedIndex = 0;
                return;
            }
            this.selectedIndex--;
        },
        selectLowerEntry() {
            if (this.suggestions.length === 0) return;
            if (this.selectedIndex >= this.suggestions.length - 1) return;
            if (this.selectedIndex === undefined) {
                this.selectedIndex = 0;
                return;
            }
            this.selectedIndex++;
        },
        selectEntry() {
            if (this.selectedIndex === undefined) return;
            const suggestion = this.suggestions[this.selectedIndex];
            /** Emitted when an entry is activly selected by calling the slot's function `selectEntry`.
             *  @property {any} suggestion - the selected suggestion */
            this.$emit("input", suggestion);
        },
    },
};
</script>

<style lang="scss" scoped>
.pos-absolute {
    z-index: 1;
}
</style>

<docs>
The most simple example.
Click in the input and use arrow keys and enter to select the suggestions.
The example also shows how to implement a mouse navigation.
```vue
<template>
    <select-autocomplete
        :suggestions="suggestions"
        @input="select"
    >
        <template #input="{selectLowerEntry, selectUpperEntry, selectEntry}">
            <input
                v-model="value"
                @click="suggest"
                @keydown.prevent.up="selectUpperEntry"
                @keydown.prevent.down="selectLowerEntry"
                @keydown.prevent.enter="selectEntry"
            />
        </template>
        <template #suggestions="{selectedIndex, setSelectedIndex}">
            <div
                v-for="(i, index) in suggestions"
                :key="i"
                :class="{'background-low-contrast': index === selectedIndex}"
                class="hover:background-low-contrast cursor-pointer"
                @click="select(i)"
                @mouseenter="setSelectedIndex(index)"
            >
                {{ i }}
            </div>
        </template>
    </select-autocomplete>
</template>
<script>
    export default {
        data() {
            return {
                value: "",
                suggestions: [],
            };
        },
        methods: {
            suggest() {
                this.suggestions = [1, 2, 3, 4, 5];
            },
            select(value) {
                this.value = value;
                this.suggestions = [];
            },
        },
    };
</script>
```
</docs>
