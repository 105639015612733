<template>
    <badge
        v-if="isAttending"
        :text="$t('events_attending')"
        type="secondary"
    />
</template>

<script>
import { mapGetters } from "vuex";
import { EVENT_MODULE_NAME } from "@/store/event/event";
import { GET_CURRENT_USER_ATTENDEE_STATUS } from "@/store/event/getters";
import { EverestEventAttendeeStatus } from "@backend/event/types";
import Badge from "@/components/Badge";

export default {
    name: "EventAttendBadge",
    components: {
        Badge,
    },
    props: {
        /** @type {EverestEvent} */
        event: { type: Object, required: true },
    },
    computed: {
        ...mapGetters({
            getAttendeeStatus: EVENT_MODULE_NAME + GET_CURRENT_USER_ATTENDEE_STATUS,
        }),
        isAttending() {
            return this.getAttendeeStatus(this.event) === EverestEventAttendeeStatus.attending;
        },
    },
};
</script>

<style lang="scss" scoped>

</style>
