





















import Vue from "vue";
import Bubbles from "@web/views/portal/Bubbles.vue";

export default Vue.extend({
    name: "Portal",
    components: { Bubbles }
});
