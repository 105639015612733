var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('select-autocomplete',{attrs:{"suggestions":_vm.offeredSuggestions},on:{"input":_vm.onSelectSuggestion},scopedSlots:_vm._u([{key:"input",fn:function(ref){
var selectUpperEntry = ref.selectUpperEntry;
var selectLowerEntry = ref.selectLowerEntry;
var selectEntry = ref.selectEntry;
return [_c('topic-label',{attrs:{"label-classes":_vm.labelClasses,"readonly":""},nativeOn:{"click":function($event){return _vm.focusInput.apply(null, arguments)}}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.queryState),expression:"queryState"},{name:"input-auto-width",rawName:"v-input-auto-width"}],ref:"queryInput",staticClass:"background-none margin-none padding-none border-none query-input",attrs:{"maxlength":_vm.inputValidationTopicNameMaxLength,"autocomplete":"off"},domProps:{"value":(_vm.queryState)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.queryState=$event.target.value},_vm.debouncedSearchSuggestions],"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.endQuery.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"])){ return null; }$event.preventDefault();return selectUpperEntry.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"])){ return null; }$event.preventDefault();return selectLowerEntry.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return selectEntry.apply(null, arguments)}]}})])]}},{key:"suggestions",fn:function(ref){
var selectedIndex = ref.selectedIndex;
var setSelectedIndex = ref.setSelectedIndex;
return [(_vm.initialLoading)?_vm._l((3),function(i){return _c('pu-skeleton',{key:i,staticClass:"mx-xxsmall",attrs:{"width":"10ch"}})}):_vm._e(),_vm._l((_vm.offeredSuggestions),function(suggestion,index){return _c('div',{key:suggestion.uid,staticClass:"text-nowrap padding-xxsmall cursor-pointer hover:background-low-contrast",class:{'background-low-contrast': selectedIndex === index},on:{"click":function($event){return _vm.onSelectSuggestion(suggestion)},"mouseenter":function($event){return setSelectedIndex(index)}}},[_vm._v(" "+_vm._s(suggestion.name)+" "),(suggestion.usageCount !== undefined)?_c('span',{staticClass:"text-high-contrast"},[_vm._v(" ("+_vm._s(suggestion.usageCount)+") ")]):_vm._e()])})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }