<template>
    <div class="event-modal-wrapper">
        <Modal
            name="event-modal"
            fullscreen
            dark
            hide-footer
            @before-open="beforeOpenEvent"
            @before-close="beforeCloseEvent"
        >
            <EventDetail
                v-if="mode === 'view'"
                :event-uid="eventUid"
                :modal="true"
                @on-edit="onEdit"
            />
            <EventEdit
                v-if="mode === 'edit'"
                :event-uid="eventUid"
                :modal="true"
                @on-leave-edit-mode="onLeaveEditMode"
            />
        </Modal>
    </div>
</template>

<script>
import Modal from "@web/components/modals/Modal.vue";
import EventDetail from "@web/views/events/EventDetail";
import EventEdit from "@web/views/events/EventEdit";
import {
    mapActions,
    mapGetters
} from "vuex";
import { INTRANET } from "@web/store/intranet/getters";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import { GET_CURRENT_EVENT } from "@web/store/event/getters";
import { PublishableEntityStatus } from "@backend/entity/types";
import { EVENT_MODULE_NAME } from "@web/store/event/event";
import { DELETE_EVENT } from "@web/store/event/actions";

export default {
    components: {
        EventDetail,
        EventEdit,
        Modal,
    },
    data() {
        return {
            eventUid: null,
            mode: "view",
            open: false,
            routeBeforeOpen: null,
        };
    },
    computed: {
        ...mapGetters({
            intranet: INTRANET_MODULE_NAME + INTRANET,
            currentEvent: EVENT_MODULE_NAME + GET_CURRENT_EVENT
        }),

    },
    methods: {
        ...mapActions({
            deleteEvent: EVENT_MODULE_NAME + DELETE_EVENT
        }),
        onEdit() {
            this.mode = "edit";
        },
        onLeaveEditMode() {
            this.mode = "view";
        },
        beforeOpenEvent(event) {
            this.open = true;
            this.routeBeforeOpen = this.$route.path;
            this.mode = this.$route.query.edit === "true" ? "edit" : "view";
            if (event && event.params) {
                this.eventUid = event.params.eventUid;
            }
            this.logAnalyticsEvent();
        },
        beforeCloseEvent() {
            // modal closed due to action (not due to browser btn) why we fix browser url here
            if (this.routeBeforeOpen === this.$route.path && this.$router.isOpenedFromDirectLink) {
                if (this.$router.isOpenedFromDirectLink.triggered && this.$router.isOpenedFromDirectLink.pathName === location.pathname) {
                    this.$router.push(`/intranet/${this.intranet.uid}/event`);
                } else {
                    this.$router.go(-1);
                }
            }
            this.open = false;
            this.routeBeforeOpen = null;
            this.mode = "view";

            // Cleanup drafts
            if (this.currentEvent && (this.currentEvent.status === PublishableEntityStatus.draft && this.currentEvent.title.trim() === "")) {
                this.deleteEvent({
                    event: this.currentEvent
                });
            }
        },
        logAnalyticsEvent() {
            // TODO: Enabled and add analytics events to global configutation
            // if (this.eventUid === this.currentEvent.uid) {
            //     analytics.log(getGlobalConfiguration().analytics_event_name_event_opened_own);
            // } else {
            //     analytics.log(getGlobalConfiguration().analytics_event_name_event_opened);
            // }
        },
    }
};
</script>

<style lang="scss" scoped>

    .event-modal-wrapper {
        &::v-deep {
            .modal.event {
                overflow-x: hidden;
                overflow-y: auto;
                max-height: 90vh;
            }
            .v--modal-overlay[data-fullscreen="true"] .modal-body[data-fullscreen="true"] {
                margin: 2rem;
            }
        }
    }

</style>
