import { EverestUser } from "@backend/user/types";
import firebase from "firebase";
import { hostUrl } from "@web/firebaseConfig";
import { ApiClient } from "@web/api/ApiClient";
import { ApiClientBuilder } from "@web/api/ApiClientBuilder";
import { analytics } from "@web/analytics";
import { getGlobalConfiguration } from "@web/global-config";
import { cloudRunUrl } from "@web/cloud-run-url";
import AuthProvider = firebase.auth.AuthProvider;
import GoogleAuthProvider = firebase.auth.GoogleAuthProvider;
import AdditionalUserInfo = firebase.auth.AdditionalUserInfo;

const APPLE_OAUTH_PROVIDER_ID = "apple.com";

class AuthError extends Error {
    constructor(public readonly code: string) {
        super();
    }
}

export interface EverestFirebaseUser extends EverestUser {
    emailVerified: boolean;
}

interface FetchProfileOfCurrentUserParams {
    user: firebase.User;
    refreshToken?: boolean;
}

export class UserLoginService {
    private api: Promise<ApiClient>;

    constructor() {
        this.api = new ApiClientBuilder().build();
    }

    public userCreationTime(): string | undefined {
        return firebase.auth().currentUser?.metadata.creationTime;
    }

    public loginWithGoogleInPopup(): Promise<EverestFirebaseUser | null> {
        const provider = new firebase.auth.GoogleAuthProvider();
        return this.loginWithProvider(provider);
    }

    public loginWithAppleInPopup(): Promise<EverestFirebaseUser | null> {
        const provider = new firebase.auth.OAuthProvider(APPLE_OAUTH_PROVIDER_ID);
        provider.addScope("email");
        provider.addScope("name");
        return this.loginWithProvider(provider);
    }

    public async sendResetPasswordEmail(email: string): Promise<void> {
        return firebase.auth().sendPasswordResetEmail(email);
    }

    public async signInWithEmailAndPassword(email: string, password: string): Promise<EverestFirebaseUser> {
        try {
            const result = await firebase.auth().signInWithEmailAndPassword(email, password);
            if (result.user === null) {
                throw new AuthError("SIGN_IN_FAILED");
            }
            if (!result.user.emailVerified) {
                await this.logout();
                throw new AuthError("EMAIL_NOT_VERIFIED");
            }
            analytics.log(
                getGlobalConfiguration().analytics_event_name_auth_login,
                { method: getGlobalConfiguration().analytics_param_auth_with_email_and_password }
            );
            return await this.fetchProfileOfCurrentUser({ user: result.user, refreshToken: true });
        } catch (error) {
            throw error?.code || error;
        }
    }

    public async signUpWithEmailAndPassword(
        name: string, email: string, password: string, requireEmailVerification: boolean = true, verificationForwardUrl?: string,
    ): Promise<EverestFirebaseUser> {
        try {
            const result = await firebase.auth().createUserWithEmailAndPassword(email, password);
            if (result.user === null) {
                throw new AuthError("SIGN_UP_FAILED");
            }
            const firebaseUser = result.user;
            await firebaseUser.updateProfile({
                displayName: name,
            });
            if (requireEmailVerification === true) {
                try {
                    await firebaseUser.sendEmailVerification({
                        url: verificationForwardUrl ?? `${hostUrl}/login`,
                    });
                } catch (e) {
                    console.error("sending email verification failed", e);
                }
            }
            analytics.log(
                getGlobalConfiguration().analytics_event_name_auth_sign_up,
                { method: getGlobalConfiguration().analytics_param_auth_with_email_and_password }
            );
            return await this.fetchProfileOfCurrentUser({ user: result.user, refreshToken: true });
        } catch (e) {
            if (e.code !== undefined) {
                throw e.code;
            }
            throw e;
        }
    }

    private async loginWithProvider(provider: AuthProvider): Promise<EverestFirebaseUser | null> {
        return await (new Promise<EverestFirebaseUser | null>(resolve =>
            firebase.auth().signInWithPopup(provider).then(async result => {
                if (!result.credential || result.user === null) {
                    console.error("Login failed!");
                    resolve(null);
                    return;
                }
                if (result.additionalUserInfo) {
                    const authMethod = this.authMethodAnalyticsKey(result.additionalUserInfo);
                    if (result.additionalUserInfo.isNewUser) {
                        analytics.log(getGlobalConfiguration().analytics_event_name_auth_sign_up, { method: authMethod });
                    } else {
                        analytics.log(getGlobalConfiguration().analytics_event_name_auth_login, { method: authMethod });
                    }
                }
                if (result.user.displayName) {
                    resolve(await this.fetchProfileOfCurrentUser({ user: result.user, refreshToken: true }));
                    return;
                }
                console.warn("Logged in user does not have a displayName provided by the auth provider. " +
                    "This is probably an Apple user. We will check if the backend knows the name.");
                const firebaseUser = await firebase.auth().currentUser;
                if (firebaseUser && firebaseUser.uid !== result.user.uid) {
                    console.error("Login failed due to uid mismatch!");
                    resolve(null);
                    return;
                }
                if (!firebaseUser) {
                    console.error("Login failed due to lost login!");
                    resolve(null);
                    return;
                }
                if (firebaseUser.displayName) {
                    console.log("Fetched Apple login name from backend successfully", firebaseUser);
                    resolve(await this.fetchProfileOfCurrentUser({ user: result.user, refreshToken: true }));
                    return;
                }
                console.warn("An user logged in without providing an displayName during login and the backend " +
                    "does not know the displayName as well. This is probably an Apple user which has previously " +
                    "logged in another env. Will set name to unknown.");
                await firebaseUser.updateProfile({
                    displayName: "Unknown name",
                });
                // append unknown to return value. we can not rely on the fact that the changes of
                // "updateProfile" have already propagated that fetchProfileOfCurrentUser will know of it.
                resolve({ ...(await this.fetchProfileOfCurrentUser({ user: result.user, refreshToken: true })), ...{ displayName: "Unknown name" } });
            }).catch((error) => {
                console.error("Login failed!");
                console.error(error);
                resolve(null);
            })));
    }

    public registerLoginStateChangeHandler(onLogin: (user: EverestFirebaseUser, provider: string | null) => void, onLogout: () => void, beforeLoginHook: () => void) {
        firebase.auth().onAuthStateChanged(async(user) => {
            if (user) {
                const signInProvider = (await user.getIdTokenResult(false)).signInProvider;
                if (signInProvider === "password") {
                    if (!user.emailVerified) {
                        console.warn("email is not verified, preventing auto login");
                        return;
                    }
                }
                if (!user.displayName) {
                    console.warn("Prevent auto login via stateChangeHandler as user does not have a name.");
                    return;
                }
                beforeLoginHook();
                const everestUser = await this.fetchProfileOfCurrentUser({ user: user, refreshToken: true });
                onLogin(everestUser, signInProvider);
            } else {
                onLogout();
            }
        });
    }

    public async reloadCurrentUser(): Promise<EverestFirebaseUser | null> {
        const currentUser = firebase.auth().currentUser;
        if (currentUser === null) {
            return null;
        }
        await currentUser.reload();
        return await this.fetchProfileOfCurrentUser({ user: currentUser, refreshToken: true });
    }

    public logout() {
        return firebase.auth().signOut();
    }

    private async fetchProfileOfCurrentUser({ user, refreshToken }: FetchProfileOfCurrentUserParams): Promise<EverestFirebaseUser> {
        const client = await this.api;
        const token = await user.getIdToken(refreshToken);
        const { data } = await client.get<EverestUser>(`${cloudRunUrl.user}/api/user/me?${user.uid}`, {
            headers: { Authorization: "Bearer " + token, CacheId: user.uid },
        });
        return { ...data, emailVerified: user.emailVerified };
    }

    private authMethodAnalyticsKey(additionalUserInfo: AdditionalUserInfo) {
        if (additionalUserInfo.providerId === GoogleAuthProvider.PROVIDER_ID) {
            return getGlobalConfiguration().analytics_param_auth_with_google;
        }
        if (additionalUserInfo.providerId === APPLE_OAUTH_PROVIDER_ID) {
            return getGlobalConfiguration().analytics_param_auth_with_apple;
        }
        // This can only happen when new auth providers are added without handling them here.
        return getGlobalConfiguration().analytics_param_auth_with_unknown_method;
    }
}
