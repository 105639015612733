<template>
    <LoadingWrapper :is-loading="initiallyLoading">
        <div class="new-post-editor-wrapper">
            <PostCreateEditor :create-post="onCreatePost"/>
        </div>
        <div class="posts-wrapper">
            <StickyNewContentIndicator
                :fetch-content="fetchPostUpdates"
                :show="unseenPostCount > 0"
                :text="$t('new_content_available', [unseenPostCount])"
                scroll-into-view-selector=".new-post-editor-wrapper"
            />
            <Post
                v-for="post in posts"
                :key="post.uid"
                :delete-post="onDeletePost"
                :edit-post="onEditPost"
                :post="post"
            >
                <template #comment-container>
                    <CommentSection
                        :comment-count="post.commentCount"
                        :comments="post.comments"
                        :content-creator-uid="post.creatorUid"
                        :content-uid="post.uid"
                        :selected-reaction-type="post.currentUsersReaction"
                        :total-reactions="post.reactions"
                        content-type="post"
                    />
                </template>
            </Post>
            <SystemMessage
                v-if="hasError"
                type="fetchData"
            />
            <SystemMessage
                v-if="!hasError && !posts.length"
                :title="$t('no_posts')"
                type="timeout"
            >
                {{ $t("create_a_post_using_the_editor_above") }}
            </SystemMessage>
            <div
                v-if="!hasError && !hasMorePosts"
                class="padding-medium centered meta"
            >
                {{ posts.length }} {{ $t("posts") }}
            </div>
        </div>
        <PostLoadMore
            v-if="!hasError && hasMorePosts"
            :infinite-scroll="infiniteScroll"
            :loading="isLoading"
            @load-more="fetchPostsNextPage()"
        />
    </LoadingWrapper>
</template>

<script>
import {
    mapActions,
    mapGetters,
} from "vuex";
import PostCreateEditor from "@web/views/posts/PostCreateEditor";
import PostLoadMore from "@web/views/posts/PostLoadMore";
import StickyNewContentIndicator from "@web/components/StickyNewContentIndicator";
import { analytics } from "@web/analytics";
import { getGlobalConfiguration } from "@web/global-config";
import Post from "@web/views/posts/Post";
import CommentSection from "@web/components/comments/CommentSection";
import { usePosts } from "@web/store/post/PostsLocalStore";
import {
    LIVE_NEW_CONTENT_MODULE_NAME,
    UnseenContentType,
} from "@web/store/live-new-content/live-new-content";
import { GET_COUNT } from "@web/store/live-new-content/getters";
import { REMOVE_BY_TYPE_AND_TOPICS } from "@web/store/live-new-content/actions";

/**
 * single post timeLine
 */
export default {
    name: "PostTimeLine",
    components: {
        StickyNewContentIndicator,
        Post,
        CommentSection,
        PostCreateEditor,
        PostLoadMore,
    },
    props: {
        /** @type {string[]} */
        topicUids: { type: Array, default: () => [] },
        infiniteScroll: Boolean,
        loading: Boolean,
    },
    computed: {
        ...mapGetters({
            getUnseenContentCount: LIVE_NEW_CONTENT_MODULE_NAME + GET_COUNT,
        }),
        /** @type {PostsLocalStore} */
        postsStore() {
            return usePosts(this.$store, { topicUids: this.topicUids });
        },
        posts() {
            return this.postsStore.state.posts;
        },
        hasMorePosts() {
            return this.postsStore.state.hasMorePosts;
        },
        hasError() {
            return this.postsStore.state.hasError;
        },
        initiallyLoading() {
            return this.loading || !this.postsStore.state.initiallyLoaded;
        },
        isLoading() {
            return this.postsStore.state.isLoading;
        },
        unseenPostCount() {
            if (!this.topicUids || this.topicUids.length === 0) {
                return this.getUnseenContentCount({ type: UnseenContentType.post });
            }
            return this.getUnseenContentCount({ type: UnseenContentType.post, topics: this.topicUids });
        },
    },
    watch: {
        topicUids: {
            async handler() {
                await this.initialFetchPosts();
            },
            immediate: true,
        },
    },
    methods: {
        ...mapActions({
            removeSeenTopics: LIVE_NEW_CONTENT_MODULE_NAME + REMOVE_BY_TYPE_AND_TOPICS,
        }),
        async initialFetchPosts() {
            await this.postsStore.methods.fetchPostsInTopic({ initial: true });
            this.removeSeenTopics({ type: UnseenContentType.post, topicUids: this.topicUids });
        },
        async fetchPostsNextPage() {
            await this.postsStore.methods.fetchPostsInTopic();
        },
        async fetchPostUpdates() {
            await this.postsStore.methods.fetchPostsInTopic({ onlyUpdates: true });
            this.removeSeenTopics({ type: UnseenContentType.post, topicUids: this.topicUids });
            analytics.log(getGlobalConfiguration().analytics_event_name_new_content_loaded_in_posts);
        },
        async onCreatePost({ content, topics, attachmentManager }) {
            return await this.postsStore.methods.createPostInTopic({ content, topics, attachmentManager });
        },
        onEditPost({ post, content, topics, attachmentManager }) {
            return this.postsStore.methods.editPost({
                postUid: post.uid,
                content,
                topics,
                attachmentManager,
            });
        },
        onDeletePost(post) {
            return this.postsStore.methods.deletePostInTopic(post.uid);
        },
    },
};
</script>

<style lang="scss" scoped>
::v-deep {
    .image-container {
        padding-top: 0;
        margin-top: -50px;
    }
}
</style>
