import {
    firestore,
    firebase
} from "@web/firebase-instance";
import Query = firebase.firestore.Query;
import DocumentSnapshot = firebase.firestore.DocumentSnapshot;
import {
    ActivitySocialNotification,
    SocialNotificationType
} from "@backend/common/notification/activity-types";
import { Ping } from "@backend/common/notification/inbox/types";
import dayjs from "dayjs";

interface InboxQueryParams {
    intranetUid: string;
    userUid: string;
    limit: number;
    startAt?: DocumentSnapshot<Ping<ActivitySocialNotification>>;
}

interface RecentlySentNotificationQueryParams {
    intranetUid: string;
    senderUid: string;
    recipientUid: string;
    notificationType: SocialNotificationType;
    onlyUnread?: boolean;
}

export class SocialNotificationService {
    getInboxQuery(
        { intranetUid, userUid, limit, startAt }: InboxQueryParams
    ): Query<Ping<ActivitySocialNotification>> {
        const query = firestore
            .collection(`/intranet/${intranetUid}/notification/${userUid}/social-inbox`)
            .orderBy("created", "desc")
            .where("unread", "==", true)
            .limit(limit) as Query<Ping<ActivitySocialNotification>>;
        return startAt === undefined ? query : query.startAt(startAt);
    }

    getRecentlySentNotificationQuery(
        { intranetUid, senderUid, recipientUid, notificationType, onlyUnread }: RecentlySentNotificationQueryParams,
    ): Query<Ping<ActivitySocialNotification>> {
        let query = firestore
            .collection(`/intranet/${intranetUid}/notification/${recipientUid}/social-inbox`)
            .orderBy("created", "desc")
            .where("activity.creatorUid", "==", senderUid)
            .where("activity.meta.socialNotificationType", "==", notificationType)
            .where("created", ">", dayjs().subtract(2, "weeks").toDate())
            .limit(1) as Query<Ping<ActivitySocialNotification>>;
        if (onlyUnread) {
            query = query.where("unread", "==", true);
        }
        return query;
    }

    markAsRead(intranetUid: string, userUid: string, notificationUid: string): Promise<void> {
        return firestore
            .doc(`/intranet/${intranetUid}/notification/${userUid}/social-inbox/${notificationUid}`)
            .update({ unread: false });
    }
}
