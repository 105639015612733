

























import Vue from "vue";

export default Vue.extend({
    name: "Spinner",
    props: {
        additionalClasses: String,
        size: { type: String, default: "20" },
        inline: Boolean,
    },
    computed: {
        cssSize() {
            return {
                width: parseInt(this.size) + "px",
                height: parseInt(this.size) + "px",
            };
        }
    },
});
