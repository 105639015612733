<template>
    <user-v-card-on-hover
        v-if="vCardOnHover && uid"
        :user-uid="uid"
    >
        <router-link
            :to="{name:'user-profile', params: {userUid: uid}}"
            class="hover-underline"
        >
            <!-- @slot Content wrapped by the link -->
            <slot></slot>
        </router-link>
    </user-v-card-on-hover>
    <router-link
        v-else
        :to="{name:'user-profile', params: {userUid: uid}}"
        class="hover-underline"
    >
        <!-- @slot Content wrapped by the link -->
        <slot></slot>
    </router-link>
</template>

<script>
import UserVCardOnHover from "@web/components/v-card/UserVCardOnHover";
/**
 * A router link with a predefined route to user profiles.
 */
export default {
    name: "UserLink",
    components: { UserVCardOnHover },
    inheritAttrs: false,
    props: {
        /** A user's uid. Meets with the `EverestUser` interface. */
        uid: String,
        /** if true a UserVCard will be rendered on hover */
        vCardOnHover: {
            type: Boolean,
            default: true,
        }
    },
};
</script>

<style lang="scss" scoped>
</style>

<docs>
Can be used to wrap anything which should link to a user. Also allows for showing the user's V-Card on-hover.
</docs>
