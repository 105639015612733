import { PluginObject } from "vue";
import Notifications from "vue-notification";
import Clipboard from "v-clipboard";
import VueAxios from "vue-axios";
import axios from "axios";
import VModal from "vue-js-modal";
import VTooltip from "v-tooltip";
import Directives from "@web/directives";
import Button from "@web/components/Button.vue";
import LoadingWrapper from "@web/components/LoadingWrapper.vue";
import SystemMessage from "@web/components/SystemMessage.vue";
import Icon from "@web/components/Icon.vue";
import PostIt from "@web/components/PostIt.vue";
import Skeleton from "vue-loading-skeleton";
import VueLazyLoad from "vue-lazyload";

// @ts-ignore
import Calendar from "v-calendar/lib/components/calendar.umd";
// @ts-ignore
import ConfettiPlugin from "vue-confetti";

export default {
    install(Vue) {
        Vue.use(ConfettiPlugin);
        Vue.use(VueLazyLoad);
        Vue.use(Skeleton);
        Vue.use(Directives);
        Vue.use(Notifications);
        Vue.use(VueAxios, axios);
        Vue.use(Clipboard);
        Vue.use(VModal, {
            dynamic: true,
            componentName: "v-modal"
        });
        Vue.use(VTooltip, {
            defaultPlacement: "top",
            defaultContainer: "#app",
            defaultDelay: 20,
            defaultTrigger: "hover focus",
            defaultOffset: 0,
            disposeTimeout: 10000,
            // Options for popover
            popover: {
                defaultPlacement: "bottom-start",
                defaultClass: "vue-popover-theme",
                defaultBaseClass: "tooltip popover",
                defaultWrapperClass: "wrapper",
                defaultInnerClass: "tooltip-inner popover-inner depth-3",
                defaultArrowClass: "tooltip-arrow popover-arrow",
                defaultOpenClass: "open",
                defaultDelay: 0,
                defaultTrigger: "click",
                defaultOffset: -4,
                defaultContainer: "body",
                defaultBoundariesElement: undefined,
                defaultAutoHide: true,
                defaultHandleResize: true,
                disposeTimeout: 100,
            }
        });

        Vue.component("app-button", Button);
        Vue.component("LoadingWrapper", LoadingWrapper);
        Vue.component("SystemMessage", SystemMessage);
        Vue.component("Icon", Icon);
        Vue.component("PostIt", PostIt);
        Vue.component("calendar", Calendar);
    },
} as PluginObject<void>;
