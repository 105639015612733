<template>
    <div>
        <div
            v-if="!infiniteScroll"
            class="post-load-more"
        >
            <app-button
                :busy="loading"
                type="button light"
                @click="triggerLoadMore"
            >
                {{ $t("load_older_posts") }}
            </app-button>
        </div>
        <InfiniteScrollSpinner
            v-if="infiniteScroll"
            :loading="loading"
            @load-more="triggerLoadMore"
        />
    </div>
</template>

<script>
import InfiniteScrollSpinner from "@web/components/InfiniteScrollSpinner";

export default {
    name: "PostLoadMore",
    components: {
        InfiniteScrollSpinner,
    },
    props: {
        infiniteScroll: Boolean,
        loading: Boolean,
    },
    methods: {
        async triggerLoadMore() {
            this.$emit("load-more");
        },
    },
};
</script>

<style lang="scss">
.post-load-more {
    text-align: center;
}
</style>
