<template>
    <div
        v-click-blur="clear"
        class="pos-relative"
    >
        <transition
            duration="600"
            name="search-bar-from-right-to-left"
        >
            <search-input
                v-if="searchOpen"
                v-model="term"
                :busy="loading"
                :focus.sync="focus"
                :placeholder="$t('content_search_input_placeholder')"
                class="d-block opener-search-input"
                @keydown.esc="blur"
                @keydown.prevent.up="selectUpperEntry"
                @keydown.prevent.down="selectLowerEntry"
                @keydown.prevent.enter="selectEntry"
            />
            <span
                v-if="!searchOpen"
                class="pos-absolute opener-trigger cursor-pointer"
                @click="openSearchBar"
            >
                <icon name="search"/>
            </span>
        </transition>
        <content-search-result-listing-component
            v-if="term !== ''"
            :displayed-results.sync="displayedResults"
            :loading.sync="loading"
            :search-term="term"
        >
            <template #default="{ pageNumber, totalResults, results, lastQuery,loadingMore, hasMore, hasResult, fetchMoreResults, highlightedText, resultAccessor}">
                <div
                    v-if="hasResult"
                    class="depth-4 border-radius-small padding-small mtop-small pos-absolute content-search-autocomplete"
                >
                    <div
                        v-if="totalResults > 0"
                        class="mbottom-small small-text text-medium-contrast"
                    >
                        {{ $tc("results_tc", totalResults, [totalResults]) }}
                    </div>
                    <div
                        v-if="totalResults === 0"
                        class="pleft-small"
                    >
                        <h2>{{ $t("content_search_no_results_title") }}</h2>
                        <h3>{{ $t("content_search_no_results_subtitle", [lastQuery]) }}</h3>
                        <p>{{ $t("content_search_no_results_description") }}</p>
                    </div>
                    <search-result-item
                        v-for="(result, index) in results"
                        :key="result.uid"
                        ref="resultEntry"
                        :class="resultAccessor(result).cssClasses"
                        :active="index === selectedResultIndex"
                        :meta="resultAccessor(result).metaLine"
                        :to="resultAccessor(result).route"
                        @mouseenter.native="selectedResultIndex = index"
                        @mouseleave.native="selectedResultIndex = undefined"
                        @click.native="clear(); trackClick()"
                    >
                        <template #icon>
                            <avatar
                                v-if="result.contentEntityType === 'user'"
                                v-bind="{photoURL: result.context.photoURL, displayName: result.title}"
                            />
                            <icon
                                v-else
                                class="result-icon"
                                :name="resultAccessor(result).icon"
                            />
                        </template>
                        <template
                            v-if="result.title"
                            #title
                        >
                            {{ result.title }}
                        </template>
                        <template
                            v-if="resultAccessor(result).contextLine"
                            #context
                        >
                            {{ $t("content_search_comment_context", [resultAccessor(result).contextLine]) }}
                        </template>
                        <template
                            v-if="resultAccessor(result).showContent && highlightedText(result)"
                            #text
                        >
                            <span v-html="highlightedText(result)"></span>
                        </template>
                        <template #note>
                            <template v-if="resultAccessor(result).noteLine">
                                {{ resultAccessor(result).noteLine }}
                            </template>
                            <template v-if="resultAccessor(result).showAuthor">
                                <loading-avatar
                                    :user-uid="result.creatorUid"
                                    class="mright-xsmall"
                                    size="small"
                                />
                                <loading-content-author :user-uid="result.creatorUid"/>
                            </template>
                        </template>
                    </search-result-item>
                    <infinite-scroll-spinner
                        v-if="hasMore"
                        :loading="loadingMore"
                        @load-more="fetchMoreResults"
                    />
                </div>
            </template>
        </content-search-result-listing-component>
    </div>
</template>

<script>
import Avatar from "@web/components/Avatar";
import SearchInput from "@web/components/content-search/SearchInput";
import SearchResultItem from "@web/components/content-search/SearchResultItem";
import ContentSearchResultListingComponent from "@web/components/content-search/ContentSearchResultListingComponent";
import InfiniteScrollSpinner from "@web/components/InfiniteScrollSpinner";
import LoadingContentAuthor from "@web/components/user/LoadingContentAuthor";
import LoadingAvatar from "@web/components/user/LoadingAvatar";
import { analytics } from "@web/analytics";
import { getGlobalConfiguration } from "@web/global-config";

export default {
    name: "ContentSearchBar",
    components: {
        Avatar,
        LoadingAvatar,
        LoadingContentAuthor,
        InfiniteScrollSpinner,
        ContentSearchResultListingComponent,
        SearchResultItem,
        SearchInput,
    },
    data() {
        return {
            term: "",
            focus: false,
            loading: false,
            selectedResultIndex: undefined,
            displayedResults: 0,
            // simple flag to prohibit duplicated analytics events when navigating to a result entry by mouse or keyboard
            entrySelected: false,
        };
    },
    computed: {
        searchOpen() {
            return this.focus || this.term !== "";
        },
    },
    watch: {
        term() {
            this.selectedResultIndex = undefined;
        },
    },
    methods: {
        openSearchBar() {
            this.focus = true;
            analytics.log(getGlobalConfiguration().analytics_event_name_content_search_open_search_bar);
        },
        clear() {
            this.term = "";
        },
        blur() {
            this.clear();
            this.focus = false;
        },
        selectUpperEntry() {
            if (this.displayedResults === 0) return;
            if (this.selectedResultIndex <= 0) return;
            if (this.selectedResultIndex === undefined) {
                this.selectedResultIndex = 0;
                return;
            }
            this.selectedResultIndex--;
        },
        selectLowerEntry() {
            if (this.displayedResults === 0) return;
            if (this.selectedResultIndex >= this.displayedResults - 1) return;
            if (this.selectedResultIndex === undefined) {
                this.selectedResultIndex = 0;
                return;
            }
            this.selectedResultIndex++;
        },
        selectEntry() {
            if (this.selectedResultIndex === undefined) {
                analytics.log(getGlobalConfiguration().analytics_event_name_content_search_press_enter);
                return;
            }
            const entryElement = this.$refs.resultEntry[this.selectedResultIndex];
            if (!entryElement) return;
            analytics.log(getGlobalConfiguration().analytics_event_name_content_search_open_result_entry_by_enter);
            this.entrySelected = true;
            entryElement.$el.click();
            this.blur();
        },
        trackClick() {
            if (!this.entrySelected) analytics.log(getGlobalConfiguration().analytics_event_name_content_search_open_result_entry_by_click);
        },
    },
};
</script>

<style lang="scss" scoped>
.content-search-autocomplete {
    width: 100%;
    max-height: 80vh;
    z-index: 1000;
    background: var(--background);
    overflow-y: auto;

    @media screen and (max-width: $breakpoint-tablet) {
        width: 85vw;
        left: 50%;
        transform: translateX(-50%);
    }
}

.result-icon {
    // align result icons with displayed avatars
    width: 36px;
}

// positioned to be exactly in the same spot as the icon of the search-input
.opener-trigger {
    height: 100%;
    top: 0;
    right: 0;
    display: inline-flex;
    align-items: center;
}

.opener-search-input {
    box-sizing: border-box;
}

$opener-duration: 100ms;
$opener-transition: right $opener-duration;
$input-duration: 500ms;
$input-transition: width $input-duration;

.search-bar-from-right-to-left-enter,
.search-bar-from-right-to-left-leave-to {
    &.opener-trigger {
        right: $spacing-xsmall;
    }

    &.opener-search-input {
        width: 0;
    }
}

.search-bar-from-right-to-left-enter-to,
.search-bar-from-right-to-left-leave {
    &.opener-trigger {
        right: 0;
    }

    &.opener-search-input {
        width: 100%;
    }
}

.search-bar-from-right-to-left-enter-active,
.search-bar-from-right-to-left-leave-active {
    // right centered positioning to change width from right to left
    &.opener-search-input {
        z-index: 1;
        position: absolute;
        right: 0;
        transform: translate3d(0, -50%, 0);
        overflow: hidden;
        white-space: nowrap;
    }
}

.search-bar-from-right-to-left-enter-active {
    &.opener-trigger {
        transition: $opener-transition;
        transition-delay: $input-duration;
    }

    &.opener-search-input {
        transition: $input-transition;
        transition-delay: $opener-duration;
    }
}

.search-bar-from-right-to-left-leave-active {
    &.opener-trigger {
        transition: $opener-transition;
    }

    &.opener-search-input {
        transition: $input-transition;
    }
}
</style>
