
import Vue from "vue";
import { hostUrl } from "@web/firebaseConfig";
import {
    mapActions,
    mapGetters,
    mapState
} from "vuex";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import { INITIALIZE_INTRANET } from "@web/store/intranet/actions";
import * as firebase from "firebase";
import StyleMixin from "@web/views/StyleMixin.vue";
import { AUTH_MODULE_NAME } from "@web/store/auth/auth";
import { detectLocale } from "@web/i18n";

const ATLASSIAN_SCRIPT_URL = "https://connect-cdn.atl-paas.net/all.js";

export default Vue.extend<any, any, any, any>({
    name: "AtlassianCloudMixin",
    mixins: [
        StyleMixin
    ],
    props: [
        "intranetUid",
    ],
    data() {
        return {
            isUserLoggedIn: false,
            isPermissionDeniedForIntranet: false,
            isUnknownError: false,
            hostUrl
        };
    },
    computed: {
        hasError() {
            return !this.isUserLoggedIn || this.isPermissionDeniedForIntranet || this.isUnknownError;
        },
        ...mapState(AUTH_MODULE_NAME, ["authStateResolved"])
    },
    methods: {
        ...mapActions({ setup: INTRANET_MODULE_NAME + INITIALIZE_INTRANET }),
        async appendAtlassianScriptToHead(onloadFunction: any, autoResize = true) {
            const newScript = document.createElement("script");
            newScript.onerror = this.loadError;
            if (onloadFunction) {
                newScript.onload = onloadFunction;
            }
            if (!autoResize) {
                newScript.setAttribute("data-options", "resize:false");
            }
            document.head.appendChild(newScript);
            newScript.src = ATLASSIAN_SCRIPT_URL;
        },

        loadError(onError: any
        ) {
            throw new URIError("The script " + onError.target.src + " didn't load correctly.");
        },
        isUserLoggedInIntoFirebase() {
            const currentUser = firebase.auth().currentUser;
            return !!currentUser;
        },
        openNews(newsUid: string) {
            window.open(`${this.hostUrl}/intranet/${this.intranetUid}/news/id/${newsUid}`, "_blank");
        },
        async getCurrentLocale(integration: string): Promise<string> {
            if (integration === "confluence_server") {
                return detectLocale();
            }
            return await this.detectConfluenceLocale();
        },
        async detectConfluenceLocale(): Promise<string> {
            return new Promise((resolve) => {
                try {
                    // @ts-ignore
                    AP.user.getLocale((locale: string) => {
                        const confluenceLocale = locale.startsWith("de") ? "de" : "en";
                        resolve(confluenceLocale);
                    });
                } catch (e) {
                    console.warn("unable to detect locale, using fallback from browser", e);
                    resolve(detectLocale());
                }
            });
        }
    }
});
