import Vue from "vue";
import VueI18n,
{ DateTimeFormats } from "vue-i18n";
import en from "@web/i18n/en.json";
import de from "@web/i18n/de.json";

/**
 * this maps mobile i18n files for web usage
 */

Vue.use(VueI18n);

const locale = detectLocale();
const fallbackLocale = "en";
const messages = { en, de };

export const dateTimeFormats: DateTimeFormats = {
    en: {
        short: {
            year: "numeric",
            month: "numeric",
            day: "numeric",
        },
        long: {
            year: "numeric",
            month: "long",
            day: "numeric",
            weekday: "short",
            hour: "numeric",
            minute: "numeric",
        },
        dateLong: {
            year: "numeric",
            month: "long",
            day: "numeric",
        }
    },
    de: {
        short: {
            year: "numeric",
            month: "numeric",
            day: "numeric",
        },
        long: {
            year: "numeric",
            month: "long",
            day: "numeric",
            weekday: "short",
            hour: "numeric",
            minute: "numeric",
        },
        dateLong: {
            year: "numeric",
            month: "long",
            day: "numeric",
        }
    },
};

export const i18n = new VueI18n({
    locale,
    fallbackLocale,
    messages,
    dateTimeFormats,
    silentFallbackWarn: true,
});

export const detectLocaleForDateFormats = (): "de" | "en" => {
    return detectLocale();
};

export function detectLocale(): "de" | "en" {
    if (typeof navigator !== "undefined" && navigator.languages && navigator.languages[0].startsWith("de")) {
        return "de";
    }
    return "en";
}
