<template>
    <transition name="moveInFromBottom">
        <div
            v-if="isEditMode"
            class="editor-footer"
        >
            <div class="grid">
                <div class="left">
                    <app-button
                        v-if="isRemovable"
                        :class="{ disabled: loading }"
                        type="link warning"
                        @click="openDeleteConfirm()"
                    >
                        {{ $t("remove") }}
                    </app-button>
                </div>
                <div class="right">
                    <div class="button-group">
                        <app-button
                            :busy="loading"
                            :class="{ disabled: loading || !publishable }"
                            type="primary"
                            @click="saveAndLeave({asDraft: false})"
                        >
                            <span v-if="loadedAsDraft">{{ $t("publish_now") }}</span>
                            <span v-else>{{ $t("save_changes") }}</span>
                        </app-button>
                        <app-button
                            :class="{ disabled: loading || !publishable }"
                            type="link"
                            @click="saveAndLeave({asDraft: true})"
                        >
                            <span v-if="loadedAsDraft">{{ $t("save_for_later") }}</span>
                            <span v-else>{{ $t("unpublish") }}</span>
                        </app-button>
                        <app-button
                            v-if="isPublished"
                            type="link"
                            @click="confirmClose()"
                        >
                            <span>{{ $t("cancel") }}</span>
                        </app-button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
export default {
    name: "NewsActionsFooter",
    props: {
        confirmClose: { type: Function, required: true },
        isEditMode: { type: Boolean, required: true },
        isPublished: { type: Boolean, required: true },
        isRemovable: { type: Boolean, required: true },
        loadedAsDraft: { type: Boolean, required: true },
        openDeleteConfirm: { type: Function, required: true },
        publishable: { type: Boolean, required: true },
        saveAndLeave: { type: Function, required: true },
        loading: { type: Boolean, required: true },
    },
};
</script>

<style lang="scss" scoped>
.editor-footer {
    background: var(--background, #{$white});
    width: 100%;
    padding: 0.75rem;
    overflow: hidden;
}
</style>
