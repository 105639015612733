<template>
    <div
        v-click-blur="clickBlur"
        class="sidebar-wrapper"
        :class="{ closed: sidebarMinimized }"
    >
        <div
            v-fade-in.after="200"
            class="sidebar-toggle"
            :class="{ flipped: sidebarMinimized }"
            @click="sidebarMinimized = !sidebarMinimized; userMinimized = true"
        >
            <Icon name="chevron-left" />
        </div>

        <aside
            class="editor-sidebar"
            :class="{ closed: sidebarMinimized }"
            @click="sidebarMinimized = false"
        >
            <div class="sidebar-content">
                <h4>{{ $t("settings") }}</h4>
                <slot ></slot>
            </div>
        </aside>
    </div>
</template>

<script>
import Icon from "@web/components/Icon.vue";
import debounce from "lodash/debounce";

export default {
    data() {
        return {
            sidebarMinimized: false,
            userMinimized: false,
            windowWidth: 0,
            txt: ""

        };
    },
    methods: {
        clickBlur() {
            const sidebar = document.querySelector(".sidebar-wrapper");
            const sidebarMode = window.getComputedStyle(sidebar, null).getPropertyValue("position");
            if (sidebarMode === "absolute") {
                this.sidebarMinimized = true;
            }
        },

        // @ts-ignore
        handleResize: debounce(function() {
            if (window.innerWidth <= 940) {
                this.sidebarMinimized = true;
                this.userMinimized = false;
            } else if (window.innerWidth > 1050 && !this.userMinimized) {
                this.sidebarMinimized = false;
            }
        }, 60)
    },
    components: {
        Icon
    },

    mounted() {
        this.$nextTick(() => {
            if (window.innerWidth <= 940) {
                this.sidebarMinimized = true;
            } else if (window.innerWidth > 1050 && !this.userMinimized) {
                this.sidebarMinimized = false;
            }

            window.addEventListener("resize", this.handleResize);
        });
    },
    destroyed() {
        this.$nextTick(() => {
            window.removeEventListener("resize", this.handleResize);
        });
    }

};
</script>

<style lang="scss" scoped>
    .sidebar-wrapper {
        position: relative;

        .sidebar-toggle {
            z-index: 100;
            border-radius: 100%;
            padding: 0.5rem;
            position: absolute;
            top: 1.5rem;
            right: -3rem;
            transition: transform 0.5s ease, opacity 0.3s ease;
            cursor: pointer;
            &:hover {
                transform: translateX(-3px);
            }
            &.flipped {
                transform: rotate(-180deg);
                &:hover {
                    transform: translateX(3px) rotate(-180deg);
                }
            }
            @media(max-width: 680px) {
                top: 0.5rem;
                right: -2.5rem;
            }

        }
    }

    .editor-sidebar {
        position: relative;
        height: 100vh;
        width: 22rem;
        top: 0;
        right: 0;
        z-index: 5;
        overflow-x: hidden;
        overflow-y: auto;
        transition: width 0.5s $ease-out-quart,
            background 0.5s ease, box-shadow 0.3s ease-out, right 0.3s ease;
        //box-shadow: -8px 0 8px -8px rgba(var(--shadows, $shadows), 0.075) inset;
        h1, h2, h3, h4, h5, p, span {
            user-select: none;

        }

        background: var(--lowest-contrast, #{$off-white});
        border-right: 1px solid var(--lowest-contrast, #{$off-white});

        .sidebar-content {
            width: 21rem;
            padding: 2rem;
            transition: opacity 0.4s ease, transform 0.3s ease;
        }

        &.closed {
            width: 1.5rem;
            right: 0;
            background: var(--lowest-contrast, #{$off-white});
            box-shadow: -0.5rem 0 0.5rem -0.5rem rgba(var(--shadows, $shadows), 0.1) inset;
            cursor: pointer;
            &:hover {
                .sidebar-content {
                    transform: translateX(-1.1rem);
                }
            }
            .sidebar-content {
                opacity: 0.6;
                transform: translateX(-1rem);
            }
        }
    }

    @media (max-width: 880px) {
        .news-layout {
            .sidebar-wrapper {
                position: absolute;
                z-index: 100;

                &:after {
                    content: "";
                    opacity: 0;
                    background: rgba(var(--foreground, #{$black}), 0);
                    transition: opacity 0.5s ease;
                    pointer-events: none;
                }

                &:not(.closed) {
                    .sidebar-toggle {
                        right: 1rem;
                    }
                    &:after {
                        content: "";
                        width: 100vw;
                        height: 100vh;
                        background: var(--medium-contrast, #{$mid-grey});
                        position: absolute;
                        right: -100vw;
                        top: 0;
                        opacity: 1;
                    }
                }
            }
        }
    }
</style>
