import { PluginObject } from "vue";
import VueResize from "vue-resize-directive";
import FadeInDirective from "@web/directives/fade-in";
import ThemeDirective from "@web/directives/theme";
import ClickBlurDirective from "@web/directives/click-blur";
import AutofocusDirective from "@web/directives/autofocus";
import OpenEventModalDirective from "@web/directives/open-event-modal";
import GlobalPageTitle from "@web/directives/global-page-title";
import ResizeDirective from "@web/directives/resize";
import InputAutoWidth from "@web/directives/input-auto-width";

export default {
    install(Vue) {
        Vue.directive("fade-in", FadeInDirective);
        Vue.directive("theme", ThemeDirective);
        Vue.directive("click-blur", ClickBlurDirective);
        Vue.directive("autofocus", AutofocusDirective);
        Vue.directive("open-event-modal", OpenEventModalDirective);
        Vue.directive("global-page-title", GlobalPageTitle);
        Vue.directive("resize", ResizeDirective);
        Vue.directive("resize-callback", VueResize);
        Vue.directive("input-auto-width", InputAutoWidth);
    },
} as PluginObject<void>;
