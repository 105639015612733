<template>
    <modal
        ref="createTopicModal"
        :async-handler="createTopic"
        :confirm-button-disabled="buttonDisabled"
        :confirm-text="$t('create')"
        :error-handler="errorHandler"
        :name="name"
        :sub-title="$t('topics_add_modal_subtitle')"
        :title="$t('topics_add_modal_title')"
        @before-open="onBeforeOpen"
        @opened="focusInput"
        @before-close="reset"
        @keydown.native.enter="triggerConfirm"
    >
        <div class="form-group">
            <label :for="inputId">
                {{ $t("topics_new_label") }}
            </label>
            <input
                :id="inputId"
                ref="topicNameInput"
                v-model.trim="topicName"
                :class="errorMessage ? 'error' : ''"
                :maxlength="nameMaxLength"
                :minlength="nameMinLength"
                autocomplete="off"
                type="text"
            />
            <div class="flex flex-space-between mtop-xsmall">
                <div class="text-warning small-text flex flex-center">
                    <template v-if="errorMessage">
                        <icon
                            class="mright-xsmall"
                            color="var(--warning)"
                            name="alert-triangle"
                            size="small"
                        />
                        {{ errorMessage }}
                    </template>
                </div>
                <div class="meta">
                    {{ topicName.length }} / {{ nameMaxLength }}
                </div>
            </div>
            <span v-tooltip.right="tooltipContent">
                <input
                    :id="`autoFollow-${_uid}`"
                    v-model="autoFollow"
                    type="checkbox"
                    name="autoFollow"
                    :disabled="subscriptionLimitReached"
                />
                <label :for="`autoFollow-${_uid}`">{{ $t("topics_auto_follow") }}</label>
            </span>
        </div>
    </modal>
</template>

<script>
import Modal from "@web/components/modals/Modal";
import { getGlobalConfiguration } from "@web/global-config";
import {
    mapActions,
    mapGetters,
} from "vuex";
import { TOPIC_SUBSCRIPTION_MODULE_NAME } from "@web/store/topic-subscription/topic-subscription";
import { GET_TOPIC_UIDS } from "@web/store/topic-subscription/getters";
import { SUBSCRIBE_TOPIC } from "@web/store/topic-subscription/actions";

const nameMaxLength = getGlobalConfiguration().input_validation_topic_name_max_length;
const nameMinLength = getGlobalConfiguration().input_validation_topic_name_min_length;
const topicSubscriptionLimit = getGlobalConfiguration().topic_subscription_limit;

export default {
    name: "CreateTopicModal",
    components: {
        Modal,
    },
    props: {
        /** a unique name to address the modal */
        name: { type: String, required: true },
        /** @type {TopicsLocalStore} */
        topicStore: { type: Object, required: true },
        follow: { type: Boolean, default: false },
    },
    data() {
        return {
            autoFollow: false,
            topicName: "",
            errorMessage: "",
            nameMinLength,
            nameMaxLength,
            topicSubscriptionLimit,
        };
    },
    computed: {
        ...mapGetters({
            subscribedTopicUids: TOPIC_SUBSCRIPTION_MODULE_NAME + GET_TOPIC_UIDS,
        }),
        subscriptionLimitReached() {
            return this.subscribedTopicUids.length >= topicSubscriptionLimit;
        },
        inputId() {
            return `topic-name-${this._uid}`;
        },
        buttonDisabled() {
            return this.topicName.length < this.nameMinLength || this.topicName.length > this.nameMaxLength;
        },
        tooltipContent() {
            if (!this.subscriptionLimitReached) return {};
            return {
                content: this.$t("topics_auto_follow_disabled", [this.topicSubscriptionLimit]),
                html: false,
            };
        },
    },
    mounted() {
        this.autoFollow = !this.subscriptionLimitReached && this.follow;
    },
    methods: {
        ...mapActions({
            subscribeTopic: TOPIC_SUBSCRIPTION_MODULE_NAME + SUBSCRIBE_TOPIC,
        }),
        async createTopic() {
            // Occuring errors will be handled in the errorHandler function
            const newTopic = await this.topicStore.methods.createTopic({ topicName: this.topicName });
            if (this.autoFollow) {
                try {
                    await this.subscribeTopic(newTopic.uid);
                    this.$notify({
                        group: "app",
                        type: "success",
                        title: this.$t("topic_added_success_title"),
                        text: this.$t("topic_added_and_subscribed_success_message", [newTopic.name]),
                    });
                    newTopic.subscriptionCount++;
                } catch (e) {
                    this.$notify({
                        group: "app",
                        type: "error",
                        text: this.$t("topic_subscription_subscribe_error", [newTopic.name]),
                    });
                }
            } else {
                this.$notify({
                    group: "app",
                    type: "success",
                    title: this.$t("topic_added_success_title"),
                    text: this.$t("topic_added_success_message", [newTopic.name]),
                });
            }
            this.reset();
            this.$emit("topic-created");
        },
        reset() {
            this.topicName = "";
            this.errorMessage = "";
        },
        errorHandler(e) {
            if (e.response && e.response.status) {
                const status = e.response.status;
                if (status === 400) {
                    this.errorMessage = this.$t("topics_add_modal_error");
                    return;
                }
            }
            this.$notify({
                group: "app",
                type: "error",
                title: this.$t("unknown_error"),
                text: this.$t("unknown_error_text"),
            });
        },
        triggerConfirm() {
            this.$refs.createTopicModal.confirmModal();
        },
        focusInput() {
            this.$refs.topicNameInput.focus();
        },
        onBeforeOpen(event) {
            this.topicName = (event.params && event.params.topicName) || "";
        },
    },
};
</script>

<style lang="scss" scoped>
.tooltip {
    text-align: center;
}
</style>
