import { ApiClient } from "@web/api/ApiClient";
import { ApiClientBuilder } from "@web/api/ApiClientBuilder";
import { cloudRunUrl } from "@web/cloud-run-url";

export class UnsplashService {
    public static readonly unsplashServiceEndpoint = `${cloudRunUrl.thirdPartyUnsplash}/api/unsplash`;
    private api: Promise<ApiClient>;

    constructor() {
        this.api = new ApiClientBuilder().build();
    }

    public async getImagesFromUnsplash(keyWord: string, page: number, perPage: number) {
        const client = await this.api;

        return client.get(`${UnsplashService.unsplashServiceEndpoint}/search/${keyWord}/${page}/${perPage}`);
    }

    public async getRandomPhoto() {
        const client = await this.api;
        return client.get(`${UnsplashService.unsplashServiceEndpoint}/random`);
    }
}
