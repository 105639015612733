import { SocialNotificationType } from "@backend/common/notification/activity-types";
import HeyFiveInteraction from "@web/components/social-notifications/HeyFiveInteraction.vue";

export const SOCIAL_NOTIFICATION_TYPE_EMOJI_MAP: { [key in SocialNotificationType]: string } = {
    [SocialNotificationType.heyFive]: "✋",
    [SocialNotificationType.birthday]: "🎉",
    [SocialNotificationType.anniversary]: "🙌",
    [SocialNotificationType.newEmployee]: "👋",
};

export const SOCIAL_NOTIFICATION_INTERACTION_MAP: { [key in SocialNotificationType]?: any } = {
    [SocialNotificationType.heyFive]: HeyFiveInteraction,
};
