import Vue from "vue";
import { VuexRootState } from "@web/store";
import { Module } from "vuex";
import {
    APPLY_CURRENT_USER_PROFILE_OF_INTRANET,
    DELETE_PROFILE_IMAGE,
    FETCH_PROFILE,
    FETCH_PROFILES_FOR_USER_UIDS,
    UPDATE_PROFILE_IMAGE,
} from "@web/store/profile/actions";
import {
    APPEND_ALREADY_FETCHING_USER_UIDS,
    SET_CURRENT_USER_PROFILE,
    SET_USER_PROFILE,
} from "@web/store/profile/mutations";
import { profileService } from "@web/services";
import {
    CURRENT_USER_PROFILE,
    GET_CURRENT_DISPLAY_NAME,
    GET_USER_PROFILE,
} from "@web/store/profile/getters";
import { BaseUserProfile } from "@backend/user-profile/types";
import { EverestUser } from "@backend/user/types";

export const PROFILE_MODULE_NAME = "profile/";

export interface ProfileModuleState {
    currentUserProfile: BaseUserProfile;
    /**
     * Stores user objects (EverestUser) by their uid.
     */
    userProfiles: Record<string, BaseUserProfile>; // User profiles by id
    /**
     * Stores uids of users that are already fetched or currently fetching to prevent duplicate requests.
     */
    alreadyFetchingUserUids: string[]; //
}

export const PROFILE_MODULE = {
    namespaced: true,
    state: {
        currentUserProfile: {
            displayName: "",
            email: "",
            photoURL: "",
            uid: "",
            emailVerified: false,
        },
        userProfiles: {},
        alreadyFetchingUserUids: []
    },
    mutations: {
        [SET_CURRENT_USER_PROFILE](state, { profile }) {
            state.currentUserProfile = profile;
            Vue.set(state.userProfiles, profile.uid, profile);
        },
        [SET_USER_PROFILE](state, { profile }) {
            Vue.set(state.userProfiles, profile.uid, profile);
        },
        [APPEND_ALREADY_FETCHING_USER_UIDS](state, { userUids }) {
            state.alreadyFetchingUserUids = state.alreadyFetchingUserUids.concat(userUids);
        }
    },
    actions: {
        async [APPLY_CURRENT_USER_PROFILE_OF_INTRANET]({ rootState, commit }) {
            const intranet = rootState.intranet.intranet;
            const user = rootState.auth.currentUser;
            if (!intranet || !user) {
                return;
            }
            const profile = await profileService.getBaseUserProfile(intranet.uid, user.uid);
            commit(SET_CURRENT_USER_PROFILE, { profile });
        },
        async [FETCH_PROFILE]({ rootState, state, commit }, userUid: string): Promise<BaseUserProfile> {
            const intranet = rootState.intranet.intranet;
            if (state.userProfiles[userUid]) {
                return state.userProfiles[userUid];
            }
            if (!state.alreadyFetchingUserUids.includes(userUid)) {
                commit(APPEND_ALREADY_FETCHING_USER_UIDS, { userUids: [userUid] });
            }
            const profile = await profileService.getBaseUserProfile(intranet!.uid, userUid).catch(() => ({ uid: userUid, ...profileService.fallbackProfile }));
            commit(SET_USER_PROFILE, { profile });
            return profile;
        },
        async [FETCH_PROFILES_FOR_USER_UIDS]({ dispatch }, userUids: string[]): Promise<BaseUserProfile[]> {
            const profileRequests = userUids.map(userUid => dispatch(FETCH_PROFILE, userUid));
            return await Promise.all(profileRequests);
        },
        async [UPDATE_PROFILE_IMAGE]({ rootState, dispatch }, image: Blob) {
            const intranet = rootState.intranet.intranet;
            const user = rootState.auth.currentUser;
            if (!intranet || !user) {
                return;
            }
            await profileService.updateProfileImage(intranet.uid, user.uid, image);
            dispatch(APPLY_CURRENT_USER_PROFILE_OF_INTRANET);
        },
        async [DELETE_PROFILE_IMAGE]({ rootState, dispatch }) {
            const intranet = rootState.intranet.intranet;
            const user = rootState.auth.currentUser;
            if (!intranet || !user) {
                return;
            }
            await profileService.updateUserProfile(intranet.uid, user.uid, { profileImageStoragePath: null });
            dispatch(APPLY_CURRENT_USER_PROFILE_OF_INTRANET);
        },
    },
    getters: {
        [GET_CURRENT_DISPLAY_NAME](state) {
            return state.currentUserProfile.displayName;
        },
        [CURRENT_USER_PROFILE](state) {
            return state.currentUserProfile;
        },
        [GET_USER_PROFILE]: (state) => (userUid: string) => {
            return state.userProfiles[userUid];
        },
    },
} as Module<ProfileModuleState, VuexRootState>;
