<template>
    <router-link
        :class="{active, hover}"
        :to="to"
        class="search-result-item padding-small mbottom-small border-low-contrast border-radius-medium"
        @click.prevent
    >
        <span class="search-result-item-icon mright-small">
            <!-- @slot required preceding icon spot -->
            <slot name="icon"></slot>
        </span>
        <span>
            <strong
                v-if="$slots.title"
                class="d-block mbottom-xsmall"
            >
                <!-- @slot optional full customizable title -->
                <slot name="title"></slot>
            </strong>
            <small
                v-if="$slots.context"
                class="d-block small-text bold mbottom-xsmall"
            >
                <!-- @slot optional context above title -->
                <slot name="context"></slot>
            </small>
            <span
                v-if="$slots.text"
                class="search-result-item-text d-block"
            >
                <!-- @slot optional citation text -->
                <slot name="text"></slot>
            </span>
            <span
                v-if="meta || $slots.meta || $slots.note"
                class="search-result-item-meta flex flex-center mtop-xsmall"
            >
                <span
                    v-if="$slots.note"
                    class="mright-xsmall small-text flex flex-center"
                >
                    <!-- @slot optional normal colored text beneath -->
                    <slot name="note"></slot>
                </span>
                <span
                    v-if="meta || $slots.meta"
                    class="small-text text-medium-contrast"
                >
                    <!-- @slot optional slightly weaker text beneath next to the note -->
                    <slot name="meta">
                        {{ meta }}
                    </slot>
                </span>
            </span>
        </span>
    </router-link>
</template>

<script>
export default {
    name: "SearchResultItem",
    props: {
        /**
         * A URL or router location
         * @type {string | Location}
         * @see https://router.vuejs.org/api/#to
         */
        to: { type: [String, Object], required: true },
        /** Meta line text. */
        meta: String,
        /** Displays item an active style. */
        active: Boolean,
        /** Enables active style on hover. */
        hover: Boolean,
    },
};
</script>

<style lang="scss" scoped>
.search-result-item {
    display: flex;
    will-change: background;
    transition: background 100ms;

    &.active,
    &.hover:hover {
        background: var(--lowest-contrast);
    }

    .search-result-item-icon {
        flex: 0 0 auto;
    }
}

.search-result-item-icon {
    --icon-color: var(--highest-contrast);
}

.search-result-item-text {
    &::before {
        display: inline;
        content: "“…";
    }

    &::after {
        display: inline;
        content: "…”";
    }
}
</style>

<docs>
Minimal:
```vue
<search-result-item
    icon="home"
    to="/"
>
    <template #icon>
        <icon name="home"/>
    </template>
    <template #text>text</template>
</search-result-item>
```

Full:
```vue
<search-result-item
    meta="January 1st 2021"
    to="/"
>
    <template #icon>
        <icon name="home"/>
    </template>
    <template #title>Title</template>
    <template #note>
        <avatar class="mright-xsmall" photoURL="https://i.pravatar.cc/256" size="small"/>
        author
    </template>
    <template #text>
        <span v-html="'…Überall dieselbe alte Leier. Das Layout ist fertig, der Text lässt auf sich warten. Damit das <b>Layout</b> nun nicht nackt im Raume steht und sich klein und leer vorkommt, springe ich ein: der Blindtext. Genau zu diesem Zwecke erschaffen, immer im Schatten meines großen Bruders »Lorem Ipsum«, freue ich mi…'"></span>
    </template>
</search-result-item>
```

With context:
```vue
<search-result-item
    meta="January 1st 2021"
    to="/"
>
    <template #icon>
        <icon name="home"/>
    </template>
    <template #context>context</template>
    <template #note>
        <avatar class="mright-xsmall" photoURL="https://i.pravatar.cc/256" size="small"/>
        author
    </template>
    <template #text>
        <span v-html="'…Überall dieselbe alte Leier. Das Layout ist fertig, der Text lässt auf sich warten. Damit das <b>Layout</b> nun nicht nackt im Raume steht und sich klein und leer vorkommt, springe ich ein: der Blindtext. Genau zu diesem Zwecke erschaffen, immer im Schatten meines großen Bruders »Lorem Ipsum«, freue ich mi…'"></span>
    </template>
</search-result-item>
```

With hover:
```vue
<search-result-item
    hover
    icon="home"
    to="/"
>
    <template #icon>
        <icon name="home"/>
    </template>
    <template #text>text</template>
</search-result-item>
```

Active:
```vue
<search-result-item
    active
    icon="home"
    meta="date"
    to="/"
>
    <template #icon>
        <icon name="home"/>
    </template>
    <template #note>
        author
    </template>
    <template #text>text</template>
</search-result-item>
```

With an avatar icon:
```vue
<search-result-item
    meta="January 1st 2021"
    to="/"
>
    <template #icon>
        <avatar photoURL="https://i.pravatar.cc/256" size="large"/>
    </template>
    <template #title>Title</template>
    <template #note>
        author
    </template>
    <template #text>
        <span v-html="'…Überall dieselbe alte Leier. Das Layout ist fertig, der Text lässt auf sich warten. Damit das <b>Layout</b> nun nicht nackt im Raume steht und sich klein und leer vorkommt, springe ich ein: der Blindtext. Genau zu diesem Zwecke erschaffen, immer im Schatten meines großen Bruders »Lorem Ipsum«, freue ich mi…'"></span>
    </template>
</search-result-item>
```

As list:
```vue
<search-result-item
    v-for="i in 3"
    icon="home"
    meta="January 1st 2021"
    to="/"
>
    <template #icon>
        <icon name="home"/>
    </template>
    <template #note>
        <avatar :photoURL="`https://i.pravatar.cc/256?_=${i}`" class="mright-xsmall"/>
        author
    </template>
    <template #text>
        <span v-html="'…Überall dieselbe alte Leier. Das Layout ist fertig, der Text lässt auf sich warten. Damit das <b>Layout</b> nun nicht nackt im Raume steht und sich klein und leer vorkommt, springe ich ein: der Blindtext. Genau zu diesem Zwecke erschaffen, immer im Schatten meines großen Bruders »Lorem Ipsum«, freue ich mi…'"></span>
    </template>
</search-result-item>
```
</docs>
