import cloneDeep from "lodash/cloneDeep";

export interface PendingRequestCacheParams {
    deletionDelay?: number;
}

export class PendingRequestCache<T> {
    private readonly pending: Record<string, Promise<T>>;
    private readonly deletionDelay: number;

    constructor({ deletionDelay = 10 }: PendingRequestCacheParams = {}) {
        this.pending = {};
        this.deletionDelay = deletionDelay;
    }

    private scheduleDeletion(key: string): void {
        setTimeout(() => delete this.pending[key], this.deletionDelay);
    }

    public async waitFor(key: string, promiseProvider: () => Promise<T>): Promise<T> {
        if (this.pending.hasOwnProperty(key)) {
            const result = await this.pending[key];
            if (typeof result === "object") {
                return cloneDeep(result);
            }
            return result;
        }
        const request = promiseProvider();
        this.pending[key] = request;
        const result = await request;
        this.scheduleDeletion(key);
        return result;
    }
}
