export default {
    bind(el, binding, vnode) {
        let dly = 50;
        if (binding.arg === "push") {
            el.style.animation = "pushingFadeIn 2s cubic-bezier(0.215, 0.610, 0.355, 1.000) 1 normal both paused";
        } else {
            el.style.animation = "fadeIn 0.5s ease-in-out 1 normal both paused";
            if (binding.modifiers.after && binding.value) {
                dly = binding.value;
                setTimeout(() => {
                    el.style.animationPlayState = "running";
                }, dly);
            } else if (binding.modifiers.when) {

            } else {
                el.style.animationPlayState = "running";
            }
        }
    },
    componentUpdated(el, binding, vnode) {
        if (binding.modifiers.when) {
            if (binding.value) {
                el.style.animationPlayState = "running";
            }
        }
    }
};
