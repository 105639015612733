import { Store } from "vuex";
import { VuexRootState } from "@web/store";
import {
    FetchFunction,
    TopicMethods,
    TopicsLocalStore,
    TopicState,
} from "@web/store/topic/topic-local-store.types";
import Vue from "vue";
import { Topic } from "@backend/topic/types";
import sortedIndexBy from "lodash/sortedIndexBy";
import {
    indexEntityToTopic,
    QueryParams,
} from "@web/services/AbstractSearchService";
import { topicService } from "@web/store/topic/TopicService";
import { topicSearchService } from "@web/store/topic/TopicSearchService";

export function useTopics(store: Store<VuexRootState>): TopicsLocalStore {
    const state = Vue.observable<TopicState>({
        topics: [],
        initiallyLoaded: false,
        page: 0,
        hasMore: false,
        fetching: false,
        hasError: false,
    });

    const methods: TopicMethods = {
        async fetchTopics(fetchGenerator): Promise<void> {
            state.fetching = true;
            try {
                const intranetUid = store.state.intranet.intranet!.uid;
                await fetchGenerator()({ state, intranetUid });
            } catch (e) {
                state.hasError = true;
            } finally {
                state.fetching = false;
                state.initiallyLoaded = true;
            }
        },
        async createTopic({ topicName }: { topicName: string }): Promise<Topic> {
            const intranetUid = store.state.intranet.intranet!.uid;
            const newTopic = await topicService.createTopic({ intranetUid, topicName });
            const predecessorIndex = sortedIndexBy(state.topics, newTopic, "comparableName");
            state.topics.splice(predecessorIndex, 0, newTopic);
            return newTopic;
        },
        async deleteTopic({ topicUid }: { topicUid: string }): Promise<void> {
            const intranetUid = store.state.intranet.intranet!.uid;
            await topicService.deleteTopic({ intranetUid, topicUid });
            state.topics = state.topics.filter(topic => topic.uid !== topicUid);
        },
    };

    return {
        state,
        methods,
    };
}

export function searchTopics({ page, hitsPerPage = 20, ...searchParams }: Omit<QueryParams, "intranetUid"> = {}): FetchFunction {
    return async function searchTopics({ intranetUid, state }: { intranetUid: string, state: TopicState }): Promise<void> {
        if (page !== undefined) {
            state.page = page;
        } else {
            state.page++;
        }
        const response = await topicSearchService.search({ intranetUid, page: state.page, hitsPerPage, ...searchParams });
        const topics = response.hits.map(indexEntityToTopic);
        state.hasMore = response.page < response.nbPages;
        if (page === 0) {
            state.topics = topics;
        } else {
            state.topics.push(...topics);
        }
    };
}

export function getTopics(topicUids: string[]): FetchFunction {
    return async function getTopics({ intranetUid, state }): Promise<void> {
        state.topics = await topicService.getTopics({ intranetUid, topicUids });
        state.hasMore = false;
    };
}
