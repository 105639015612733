/**
 * Currently, quill has no built in way to detect if a blot is rendered in the read-only or edit mode.
 * See: https://github.com/quilljs/quill/issues/1162
 */
export function isInEditMode(e: Event): boolean {
    const quillRootElementClass = "ql-editor";
    let parent: HTMLElement | null = (e.target as HTMLElement).parentElement;
    while (parent?.parentNode && parent?.className !== quillRootElementClass) { // eslint-disable-line no-unmodified-loop-condition
        parent = parent.parentElement;
    }
    return parent?.className === quillRootElementClass && (parent?.attributes as any).contenteditable.value === "true";
}

/**
 * Prevents default and skips handler execution, if event target is inside an edit-mode quill editor
 */
export function preventHandlerInEditMode(handler: (event: Event) => void): (event: Event) => void {
    return (event: Event) => {
        event.preventDefault();

        if (isInEditMode(event)) {
            return;
        }

        handler(event);
    };
}
