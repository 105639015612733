<template>
    <span>
        &nbsp;&nbsp;·&nbsp;&nbsp;
    </span>
</template>

<script>
export default {
    name: "MetaDivider",
};
</script>

<style lang="scss" scoped>

</style>
