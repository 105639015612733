<template>
    <div class="macro-wrapper">
        <app-button
            v-if="!hasError"
            class="vertical-center"
            type="primary"
            icon="edit"
            @click="navigateTo()"
        >
            {{ $t('create_news_macro', locale) }}
        </app-button>
        <MacroError
            v-if="hasError"
            :is-user-logged-in="isUserLoggedIn"
            :is-permission-denied-for-intranet="isPermissionDeniedForIntranet"
            i18n-feature-name="create_news_macro"
        />
    </div>
</template>

<script>
import { hostUrl } from "@web/firebaseConfig";
import Vue from "vue";
import ConfluenceMacroMixin from "@web/views/atlassian-cloud/confluence/macro/ConfluenceMacroMixin.vue";
import MacroError from "./MacroError";
import { resizingService } from "@web/services";

export default Vue.extend({
    name: "NewsCreateMacro",
    components: {
        MacroError
    },
    mixins: [
        ConfluenceMacroMixin
    ],
    data() {
        return {
            url: hostUrl + "/atlassian-cloud/confluence/macro/news-create?intranetUid=" + this.intranetUid,
            intranetLoaded: false,
            locale: "",
        };
    },
    async created() {
        this.appendAtlassianScriptToHead(async() => {
            this.isUserLoggedIn = this.isUserLoggedInIntoFirebase();
            this.locale = await this.getCurrentLocale(this.$route.query.integration);
            if (!this.isUserLoggedIn) {
                resizingService.resize({
                    integration: this.$route.query.integration,
                    integrationUuid: this.$route.query.integrationUuid,
                });
                return;
            }
            this.setup(this.intranetUid)
                .then(this.handleSuccess, this.handleError)
                .finally(() => {
                    resizingService.resize({
                        integration: this.$route.query.integration,
                        integrationUuid: this.$route.query.integrationUuid,
                        height: "50px",
                    });
                });
        }, false);
    },
    methods: {
        handleError(error) {
            if (error.response.status === 403) {
                this.isPermissionDeniedForIntranet = true;
                return;
            }
            this.isUnknownError = true;
        },
        handleSuccess() {
            this.intranetLoaded = true;
            this.styleInject();
        },
        navigateTo() {
            window.open(this.url);
        }
    }
});
</script>

<style lang="scss" scoped>
    .error-wrapper {
        position: relative;
    }

    .error-overlay {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        left: 50%;
        transform: translate(-50%, -50%);
        top: 50%;
        max-width: 400px;
    }

    app-button {
        margin: 0;
        position: absolute;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
    }

</style>
