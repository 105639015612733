import Vue from "vue";
import { Module } from "vuex";
import countBy from "lodash/countBy";
import groupBy from "lodash/groupBy";
import mapValues from "lodash/mapValues";
import { VuexRootState } from "@web/store";
import { firestore } from "@web/firebase-instance";
import { INTRANET_COLLECTION_IDENTIFIER } from "@web/firestore/collections";
import { POST_COLLECTION_IDENTIFIER } from "@backend/post/collections";
import {
    EverestPostInFireStore,
    EverestPostInFireStoreFieldKeys,
} from "@backend/post/types";
import { castConverter } from "@web/lib/firestore-converter";
import {
    COUNT_BY_TOPIC,
    COUNT_BY_TOPIC_AND_TYPE,
    COUNT_BY_TYPE,
    COUNT_BY_TYPE_AND_TOPIC,
    FLATTEN_BY_TOPIC,
    GET_COUNT,
    GET_POST_TIMELINE_RELOAD,
} from "@web/store/live-new-content/getters";
import {
    ADD_IGNORE_CONTENT_ENTITY_UPDATE,
    ADD_UNSEEN_CONTENT,
    CLEAR,
    REMOVE_BY_ENTITY_UID,
    REMOVE_BY_TYPE_AND_TOPIC,
    REMOVE_IGNORE_CONTENT_ENTITY_UPDATE,
    SET_POST_TIMELINE_RELOAD,
    SET_UNSUBSCRIBE_LISTENER,
} from "@web/store/live-new-content/mutations";
import {
    ADD_OR_IGNORE_UNSEEN_CONTENT,
    REGISTER_LISTENERS,
    REMOVE_BY_TYPE_AND_TOPICS,
    UNREGISTER_LISTENERS,
} from "@web/store/live-new-content/actions";
import {
    EverestContentEntityInFireStoreFieldKeys,
    PublishableEntityStatus,
    PublishableFieldKeys,
} from "@backend/entity/types";

export enum UnseenContentType {
    post = "post",
    news = "news",
    event = "event",
}

export interface LiveNewContentModuleState {
    unseenContentInfoSet: UnseenContentInfo[],
    // contentUid -> number of ignores
    ignoreContentEntityUpdate: Record<string, number>,
    unsubscribeListeners: Partial<Record<UnseenContentType, () => void>>,
    postTimelineReload: boolean,
}

export interface UnseenContentInfo {
    type: UnseenContentType;
    entityUid: string;
    topics: readonly string[];
}

export interface FlatUnseenContentInfo {
    type: UnseenContentType;
    entityUid: string;
    topic: string;
}

type TypeRecord<T> = Partial<Record<UnseenContentType, T>>;
type TopicRecord<T> = Record<string, T>;

/** Maximum number of detected new content per `UnseenContentType`. */
const limitPerContentType = 1000;

export const LIVE_NEW_CONTENT_MODULE_ID = "liveNewContent";
export const LIVE_NEW_CONTENT_MODULE_NAME = LIVE_NEW_CONTENT_MODULE_ID + "/";

/**
 * Listenes to new content entities to notify about live updates while the app is running.
 * Use action `REGISTER_LISTENERS` to start listening and ensure to stop listening with `UNREGISTER_LISTENERS` if an intranet unloads.
 */
export const LIVE_NEW_CONTENT_MODULE: Module<LiveNewContentModuleState, VuexRootState> = {
    namespaced: true,
    state: (): LiveNewContentModuleState => ({
        unseenContentInfoSet: [],
        ignoreContentEntityUpdate: {},
        unsubscribeListeners: {},
        postTimelineReload: false,
    }),
    getters: {
        [GET_POST_TIMELINE_RELOAD](state): boolean {
            return state.postTimelineReload;
        },
        [FLATTEN_BY_TOPIC](state): readonly FlatUnseenContentInfo[] {
            return state.unseenContentInfoSet
                .flatMap(({ type, entityUid, topics }) => topics.map(topic => ({ type, entityUid, topic })));
        },
        [COUNT_BY_TOPIC](state, getters): TopicRecord<number> {
            return countBy(getters[FLATTEN_BY_TOPIC], "topic");
        },
        [COUNT_BY_TYPE](state): TypeRecord<number> {
            return countBy(state.unseenContentInfoSet, "type") as TypeRecord<number>;
        },
        /** returns per type the counts per topic */
        [COUNT_BY_TYPE_AND_TOPIC](state, getters): TypeRecord<TopicRecord<number>> {
            const groupByType = groupBy(getters[FLATTEN_BY_TOPIC], "type") as TypeRecord<FlatUnseenContentInfo[]>;
            return mapValues(groupByType, group => countBy(group, "topic"));
        },
        /** returns per topic the counts per type */
        [COUNT_BY_TOPIC_AND_TYPE](state, getters): TopicRecord<TypeRecord<number>> {
            const groupByTopic = groupBy(getters[FLATTEN_BY_TOPIC], "topic");
            return mapValues(groupByTopic, group => countBy(group, "type") as TypeRecord<number>);
        },
        [GET_COUNT](state, getters) {
            return ({ type, topic, topics }: { type?: UnseenContentType, topic?: string, topics?: string[] } = {}): number => {
                if (topic && topics) {
                    throw new Error("use either topic or topics");
                } else if (topics?.length === 0) {
                    return 0;
                } else if (type && topic) {
                    const countByTypeAndTopic = getters[COUNT_BY_TYPE_AND_TOPIC] as TypeRecord<TopicRecord<number>>;
                    return countByTypeAndTopic[type]?.[topic] ?? 0;
                } else if (type && topics) {
                    const flattenByTopic = getters[FLATTEN_BY_TOPIC] as FlatUnseenContentInfo[];
                    const matchedUnseenContentInfo = flattenByTopic.filter((flatUnseenContentInfo) => flatUnseenContentInfo.type === type && topics.includes(flatUnseenContentInfo.topic));
                    const entityUidSet = new Set(matchedUnseenContentInfo.map(({ entityUid }) => entityUid));
                    return entityUidSet.size;
                } else if (type === undefined && topics) {
                    const flattenByTopic = getters[FLATTEN_BY_TOPIC] as FlatUnseenContentInfo[];
                    const matchedUnseenContentInfo = flattenByTopic.filter((flatUnseenContentInfo) => topics.includes(flatUnseenContentInfo.topic));
                    const entityUidSet = new Set(matchedUnseenContentInfo.map(({ entityUid }) => entityUid));
                    return entityUidSet.size;
                } else if (type === undefined && topic) {
                    return getters[COUNT_BY_TOPIC]?.[topic] ?? 0;
                } else if (type) {
                    return getters[COUNT_BY_TYPE]?.[type] ?? 0;
                } else {
                    return state.unseenContentInfoSet.length;
                }
            };
        },
    },
    mutations: {
        [SET_POST_TIMELINE_RELOAD](state, isReloading: boolean): void {
            state.postTimelineReload = isReloading;
        },
        [SET_UNSUBSCRIBE_LISTENER](state, { type, unsubscribe }: { type: UnseenContentType, unsubscribe: () => void }): void {
            state.unsubscribeListeners[type] = unsubscribe;
        },
        [REMOVE_BY_ENTITY_UID](state, entityUid: string): void {
            const index = state.unseenContentInfoSet.findIndex((unseenContentInfo) => unseenContentInfo.entityUid === entityUid);
            state.unseenContentInfoSet.splice(index, 1);
            Vue.delete(state.ignoreContentEntityUpdate, entityUid);
        },
        [CLEAR](state): void {
            state.unseenContentInfoSet = [];
            state.ignoreContentEntityUpdate = {};
        },
        [REMOVE_BY_TYPE_AND_TOPIC](state, { type, topicUid }: { type: UnseenContentType, topicUid: string }): void {
            const entityUids = state.unseenContentInfoSet.filter((unseenContentInfo) => unseenContentInfo.type === type && unseenContentInfo.topics.includes(topicUid)).map(unseenContentInfo => unseenContentInfo.entityUid);
            for (const entityUid of entityUids) {
                const index = state.unseenContentInfoSet.findIndex((unseenContentInfo) => unseenContentInfo.entityUid === entityUid);
                state.unseenContentInfoSet.splice(index, 1);
                Vue.delete(state.ignoreContentEntityUpdate, entityUid);
            }
        },
        [REMOVE_IGNORE_CONTENT_ENTITY_UPDATE](state, entityUid: string): void {
            if (state.ignoreContentEntityUpdate.hasOwnProperty(entityUid)) {
                if (state.ignoreContentEntityUpdate[entityUid] > 1) {
                    state.ignoreContentEntityUpdate[entityUid]--;
                } else {
                    Vue.delete(state.ignoreContentEntityUpdate, entityUid);
                }
            }
        },
        [ADD_IGNORE_CONTENT_ENTITY_UPDATE](state, entityUid: string): void {
            if (state.ignoreContentEntityUpdate.hasOwnProperty(entityUid)) {
                state.ignoreContentEntityUpdate[entityUid]++;
            } else {
                Vue.set(state.ignoreContentEntityUpdate, entityUid, 1);
            }
        },
        [ADD_UNSEEN_CONTENT](state, unseenContentInfo: UnseenContentInfo): void {
            const unseenContentIndex = state.unseenContentInfoSet.findIndex(({ entityUid }) => entityUid === unseenContentInfo.entityUid);
            if (unseenContentIndex > -1) {
                state.unseenContentInfoSet.splice(unseenContentIndex, 1, unseenContentInfo);
            } else {
                state.unseenContentInfoSet.unshift(unseenContentInfo);
            }
        },
    },
    actions: {
        /** @private */
        [ADD_OR_IGNORE_UNSEEN_CONTENT]({ state, commit }, unseenContentInfo: UnseenContentInfo): void {
            if (state.ignoreContentEntityUpdate.hasOwnProperty(unseenContentInfo.entityUid)) {
                console.debug(`ignoring update for content entity ${unseenContentInfo.entityUid}`);
                commit(REMOVE_IGNORE_CONTENT_ENTITY_UPDATE, unseenContentInfo.entityUid);
            } else {
                commit(ADD_UNSEEN_CONTENT, unseenContentInfo);
            }
        },
        [REGISTER_LISTENERS]({ getters, rootState, commit, dispatch }): void {
            const intranetUid = rootState.intranet.intranet?.uid;
            const currentUserUid = rootState.auth.currentUser?.uid;
            if (!intranetUid) throw Error("intranet required");
            if (!currentUserUid) throw Error("auth user required");
            console.info("listening for new posts");
            let initializing = true;
            const unsubscribe = firestore
                .collection(INTRANET_COLLECTION_IDENTIFIER)
                .doc(intranetUid)
                .collection(POST_COLLECTION_IDENTIFIER)
                .withConverter(castConverter<EverestPostInFireStore>())
                .where(EverestPostInFireStoreFieldKeys.lastActivityDate, ">", new Date())
                .where(PublishableFieldKeys.status, "==", PublishableEntityStatus.published)
                .orderBy(EverestPostInFireStoreFieldKeys.lastActivityDate, "desc")
                .limit(limitPerContentType)
                .onSnapshot(async(querySnap) => {
                    if (initializing) {
                        initializing = false;
                        return;
                    }
                    const docChanges = querySnap.docChanges();
                    for (const docChange of docChanges) {
                        const doc = docChange.doc;
                        if (docChange.type === "removed") {
                            commit(REMOVE_BY_ENTITY_UID, doc.id);
                        } else {
                            if (getters[GET_COUNT]({ type: UnseenContentType.post }) >= limitPerContentType) return;
                            const docData = doc.data();
                            if (docData[EverestContentEntityInFireStoreFieldKeys.creatorUid] === currentUserUid) return;
                            if (docChange.newIndex <= docChange.oldIndex) return;
                            await dispatch(ADD_OR_IGNORE_UNSEEN_CONTENT, {
                                type: UnseenContentType.post,
                                entityUid: doc.id,
                                topics: docData[EverestPostInFireStoreFieldKeys.topics],
                            } as UnseenContentInfo);
                        }
                    }
                }, () => {
                    console.warn("lost onSnapshot listener on post query for live-new-content store");
                });
            commit(SET_UNSUBSCRIBE_LISTENER, { type: UnseenContentType.post, unsubscribe });
        },
        [UNREGISTER_LISTENERS]({ state, commit }): void {
            for (const contentType of Object.keys(UnseenContentType) as UnseenContentType[]) {
                state.unsubscribeListeners[contentType]?.();
                commit(SET_UNSUBSCRIBE_LISTENER, {
                    type: contentType,
                    unsubscribeFunction: undefined,
                });
            }
        },
        [REMOVE_BY_TYPE_AND_TOPICS]({ commit }, { type, topicUids }: { type: UnseenContentType, topicUids: string[] }): void {
            if (topicUids.length === 0) {
                commit(CLEAR);
                return;
            }
            for (const topicUid of topicUids) {
                commit(REMOVE_BY_TYPE_AND_TOPIC, { type, topicUid });
            }
        },
    },
};
