import { cloudRunUrl } from "@web/cloud-run-url";
import { ApiClientBuilder } from "@web/api/ApiClientBuilder";
import { ApiClient } from "@web/api/ApiClient";
import { AxiosResponse } from "axios";
import {
    MentionAction,
    mentionAnalytics
} from "@web/lib/quill/mention/everest-mention";
import { analytics } from "@web/analytics";
import { getGlobalConfiguration } from "@web/global-config";
import { MentionEntityType } from "@backend/mention/mention-types";
import { EverestComment } from "@backend/comment/types";
import { Operation } from "@backend/entity/delta/types";
import { length } from "@web/lib/quill/quill-utils";

export enum ContentType {
    post = "post",
    news = "news",
}

export class CommentService {
    public static readonly COMMENT_SERVICE_ENDPOINT = `${cloudRunUrl.comment}/api/comment`;
    public static readonly INITIAL_PAGE_SIZE = 10;
    public static readonly LOAD_MORE_PAGE_SIZE = 20;

    private readonly api: Promise<ApiClient>;

    constructor() {
        this.api = new ApiClientBuilder().build();
    }

    public async getComments(
        intranetUid: string,
        contentType: ContentType,
        contentUid: string,
        commentPath: string = "",
        limit = CommentService.INITIAL_PAGE_SIZE,
        startAfter?: string,
        startAt?: string,
    ): Promise<EverestComment[]> {
        const client = await this.api;
        const response: AxiosResponse = await client.get(
            this.buildUrl(intranetUid, contentType, contentUid, commentPath),
            {
                params: {
                    format: "web",
                    limit: limit,
                    startAfter: startAfter,
                    startAt: startAt,
                    desc: "true",
                },
            }
        );
        return response.data as EverestComment[];
    }

    public async createComment(
        intranetUid: string,
        contentType: ContentType,
        contentUid: string,
        content: Operation[],
        parentCommentPath: string = "",
    ) {
        const { uid } = await this._createComment(intranetUid, contentType, contentUid, parentCommentPath);
        const responseData = await this._editComment(
            intranetUid,
            contentType,
            contentUid,
            content,
            (parentCommentPath.length > 0 ? parentCommentPath + "." : "") + uid
        );
        this._logCommentChange(ChangeType.create, contentType, content, this.getDepthByCommentPathForAnalytics(parentCommentPath, false));
        return responseData;
    }

    private async _createComment(
        intranetUid: string,
        contentType: ContentType,
        contentUid: string,
        parentCommentPath: string = "",
    ): Promise<EverestComment> {
        const client = await this.api;
        return (await client.post(
            this.buildUrl(intranetUid, contentType, contentUid, parentCommentPath),
            {},
            { params: { format: "web" } }
        )).data as EverestComment;
    }

    public async editComment(
        intranetUid: string,
        contentType: ContentType,
        contentUid: string,
        content: Operation[],
        commentPath: string = ""
    ) {
        const responseData = await this._editComment(intranetUid, contentType, contentUid, content, commentPath);

        this._logCommentChange(ChangeType.edit, contentType, content, this.getDepthByCommentPathForAnalytics(commentPath, false));

        return responseData;
    }

    private async _editComment(
        intranetUid: string,
        contentType: ContentType,
        contentUid: string,
        content: object[],
        commentPath: string = ""
    ) {
        const client = await this.api;
        return (await client.put(
            this.buildUrl(intranetUid, contentType, contentUid, commentPath),
            { content },
            { params: { format: "web" } }
        )).data;
    }

    public async deleteComment(
        intranetUid: string,
        contentType: ContentType,
        contentUid: string,
        commentPath: string
    ) {
        const client = await this.api;
        const response: AxiosResponse = await client.delete(
            this.buildUrl(intranetUid, contentType, contentUid, commentPath)
        );
        analytics.log(
            getGlobalConfiguration().analytics_event_name_news_comment_deleted,
            { depth: this.getDepthByCommentPathForAnalytics(commentPath, true) }
        );
        return response.data;
    }

    private getDepthByCommentPathForAnalytics(commentPath: string, pathIdentifiesExistingComment: boolean) {
        const depth = !commentPath ? 0 : 1 + commentPath.split(".").length - 1;
        return pathIdentifiesExistingComment ? depth : depth + 1;
    }

    private buildUrl(
        intranetUid: string,
        contentType: ContentType,
        contentUid: string,
        commentPath: string
    ): string {
        return `${CommentService.COMMENT_SERVICE_ENDPOINT}` +
            `/${intranetUid}` +
            `/${contentType}` +
            `/${contentUid}` +
            `/${commentPath}`;
    }

    private _logCommentChange(changeType: ChangeType, contentType: ContentType, content: Operation[], depth: number) {
        try {
            const analyticsEvent = this._analyticsEventByChangeTypeAndContentType(changeType, contentType);
            const mentionEntityType = this._mentionEntityTypeByContentType(contentType);
            const mentionAction = this._mentionActionByChangeType(changeType);

            analytics.log(analyticsEvent, { depth, length: length(content) });
            mentionAnalytics.trackMentions(
                content,
                mentionEntityType,
                mentionAction
            );
        } catch (e) {

        }
    }

    private _mentionActionByChangeType(changeType: ChangeType): MentionAction {
        if (changeType === ChangeType.create) {
            return MentionAction.create;
        }
        if (changeType === ChangeType.edit) {
            return MentionAction.edit;
        }
        throw Error(`Unknown changeType: '${changeType}'`);
    }

    private _mentionEntityTypeByContentType(contentType: ContentType): MentionEntityType {
        if (contentType === ContentType.post) {
            return MentionEntityType.postComment;
        }
        if (contentType === ContentType.news) {
            return MentionEntityType.newsComment;
        }
        throw Error(`Unknown contentType: '${contentType}'`);
    }

    private _analyticsEventByChangeTypeAndContentType(changeType: ChangeType, contentType: ContentType): string {
        if (changeType === ChangeType.create) {
            if (contentType === ContentType.news) {
                return getGlobalConfiguration().analytics_event_name_news_comment_created;
            }
            if (contentType === ContentType.post) {
                return getGlobalConfiguration().analytics_event_name_post_comment_created;
            }
        }
        if (changeType === ChangeType.edit) {
            if (contentType === ContentType.news) {
                return getGlobalConfiguration().analytics_event_name_news_comment_edited;
            }
            if (contentType === ContentType.post) {
                return getGlobalConfiguration().analytics_event_name_post_comment_edited;
            }
        }
        throw Error(`Unknown changeType: '${changeType}' or '${contentType}'`);
    }
}

enum ChangeType {
    create = "create",
    edit = "edit"
}
