import { IntranetRole } from "@backend/intranet/types";
import {
    DateYMD,
    LatLngLiteral,
} from "@backend/user-profile/types";
import { Team } from "@backend/team/types";

export const USER_INDEX_NAME = "user";
export const TEAM_INDEX_NAME = "team";
export const CONTENT_SEARCH_INDEX_NAME = "content-search";
export const TOPIC_INDEX = "topic";
export const TOPIC_BY_USAGE_INDEX_REPLICA = "topic-by-usage";

/**
 * Names of all managed indexes.
 * To apply index settings use terraform within [infrastructure](../../../../dev/infrastructure/README.md).
 */
export type IndexName =
    typeof USER_INDEX_NAME
    | typeof TEAM_INDEX_NAME
    | typeof CONTENT_SEARCH_INDEX_NAME
    | typeof TOPIC_INDEX
    | typeof TOPIC_BY_USAGE_INDEX_REPLICA;

/**
 * A numeric timestamp of a date which is agnostic of its year. For example:
 *
 * 04.03.2021 -> 304
 * 04.03.1993 -> 304
 * 10.12.2005 -> 1210
 * 30.10.2015 -> 1030
 *
 * This allows for filtering in date ranges numerically, as algolia provide any date utilities.
 */
export type DatestampMD = number;

export interface SimpleLocation {
    name: string;
    detail?: string;
    position?: LatLngLiteral;
}

export interface UserIndexEntity extends IndexEntity {
    displayName: string | null;
    email?: string;
    photoURL?: string;
    birthday?: DateYMD;
    birthdayDatestampMD?: DatestampMD;
    hiringDate?: DateYMD;
    hiringDateTimestamp?: number;
    hiringDateDatestampMD?: DatestampMD;
    jobTitle?: string;
    aboutMe?: string;
    roles: IntranetRole[];
    location?: SimpleLocation;
    team?: Team;
}

export interface TeamIndexEntity extends IndexEntity {
    name: string;
}

export interface IndexEntity extends AlgoliaObject, IntranetScopedIndexEntity {
    uid: string;
}

export interface AlgoliaObject {
    objectID: string;
    /** a special field used for tag search
     * @see https://www.algolia.com/doc/api-reference/api-parameters/tagFilters/ */
    _tags?: string[];
}

export interface IntranetScopedIndexEntity {
    intranetUid: string;
}

export function buildAlgoliaObjectID(intranetUid: string, uid: string): string {
    return `${intranetUid}-${uid}`;
}

