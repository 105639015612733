import { VuexRootState } from "@web/store";
import { Module } from "vuex";
import { EverestPost } from "@backend/post/types";
import { SINGLE_POST_MODULE } from "@web/store/post/single-post/single-post";

export interface PostsInTopicModuleState {
    posts: EverestPost[];
    hasMorePosts: boolean;
    isLoading: boolean;
    hasError: boolean;
}

export const POST_MODULE_NAME = "post/";

export const POST_MODULE: Module<PostsInTopicModuleState, VuexRootState> = {
    namespaced: true,
    modules: {
        singlePost: SINGLE_POST_MODULE,
        topic: {
            namespaced: true,
            // … posts in topic modules dynamically registered
            modules: {},
        },
    },
};
