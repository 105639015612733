import { VuexRootState } from "@web/store";
import { Module } from "vuex";
import { RESET_COLORS } from "@web/store/theming/actions";
import {
    SET_COLORS,
    UPDATE_COLOR,
} from "@web/store/theming/mutations";
import { COLORS } from "@web/store/theming/getters";
import Vue from "vue";
import { IntranetColors } from "@backend/theming/types";

export const THEMING_MODULE_NAME = "theming/";

export interface ThemingModuleState {
    colors: IntranetColors | null;
}

export const THEMING_MODULE = {
    namespaced: true,
    state: {
        colors: null
    },
    mutations: {
        [SET_COLORS](state, colors) {
            state.colors = colors;
        },
        [UPDATE_COLOR](state, { colorKey, color }) {
            Vue.set(state.colors as object, colorKey, color);
        }
    },
    actions: {
        [RESET_COLORS]({ commit, rootState }) {
            const intranet = rootState.intranet.intranet;
            if (intranet !== null) {
                commit(SET_COLORS, { ...intranet.colors });
            }
        },
    },
    getters: {
        [COLORS]: state => state.colors
    }
} as Module<ThemingModuleState, VuexRootState>;
