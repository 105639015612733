import { VuexRootState } from "@web/store";
import { Module } from "vuex";
import { FETCH_USER_CONNECTION_STATUS } from "@web/store/atlassian/actions";
import { atlassianConnectService } from "@web/services";
import {
    SET_USER_CONNECTION_IS_FETCHING,
    SET_USER_CONNECTION_STATUS
} from "@web/store/atlassian/mutations";

export interface AtlassianModuleState {
    isFetching: boolean;
    isUserConnected: boolean;
}

export const ATLASSIAN_MODULE_NAME = "atlassian/";

const getDefaultState = () => ({
    isFetching: false,
    isUserConnected: false,
});

export const ATLASSIAN_MODULE = {
    namespaced: true,
    state: getDefaultState(),
    getters: {},
    mutations: {
        [SET_USER_CONNECTION_IS_FETCHING](state, isFetching) {
            state.isFetching = isFetching;
        },
        [SET_USER_CONNECTION_STATUS](state, isConnected) {
            state.isUserConnected = isConnected;
        }
    },
    actions: {
        async [FETCH_USER_CONNECTION_STATUS]({ state, commit, rootState }) {
            if (state.isFetching) {
                return;
            }
            const intranet = rootState.intranet.intranet;
            if (!intranet) {
                return;
            }
            commit(SET_USER_CONNECTION_IS_FETCHING, true);
            const isConnected = await atlassianConnectService.getUserConnectionStatus(intranet.uid);
            commit(SET_USER_CONNECTION_IS_FETCHING, false);
            commit(SET_USER_CONNECTION_STATUS, isConnected);
        },
    },
} as Module<AtlassianModuleState, VuexRootState>;
