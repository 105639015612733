<template>
    <div
        class="icon"
        :class="[sizeClass, {'pos-relative': badge}]"
        :data-badge="badge"
    >
        <div
            class="icon-svg"
            :style="style"
            v-html="buildUrl()"
        ></div>
        <div
            v-if="badge"
            class="badge"
        >
            {{ badge }}
        </div>
    </div>
</template>

<script>
export default {
    name: "Icon",
    props: {
        /**
         * The icon to use. See a list of all [icons in the design tokens](#/Design%20Tokens/Icons).
         */
        name: {
            type: String,
            required: true,
        },
        /**
         * Displays a badge. Only works in sidebar!
         */
        badge: {
            type: [String, Number],
            default: ""
        },
        /**
         * Color of the icon.
         */
        color: {
            type: String
        },
        /**
         * Size of the icon.
         * @values x-small, small, normal, large
         */
        size: {
            type: [String, Number],
            default: "normal"
        },
        /**
         * Subdirectory to look for the assets. This is usually not necessary to use.
         */
        type: {
            type: String,
            default: "icons"
        }
    },
    computed: {
        style() {
            if (isNaN(this.size)) {
                return {};
            }
            return {
                width: `${this.size}px`,
                height: `${this.size}px`,
            };
        },
        sizeClass() {
            if (!isNaN(this.size)) {
                return "";
            }
            return this.size;
        }
    },
    watch: {
        color() {
            this.setIconColor();
        }
    },
    mounted() {
        this.setIconColor();
    },
    methods: {
        buildUrl() {
            return require(`@web/assets/${this.type}/${this.name}.svg`);
        },
        setIconColor() {
            if (this.color && this.color !== "") {
                this.$el.style.setProperty("--icon-color", this.color, "important");
            }
        },
    }
};
</script>

<style lang="scss">
    .icon {
        display: inline-flex;
        align-items: center;
        justify-content: center;
        vertical-align: bottom;

        .icon-svg {
            width: var(--icon-size, 24px);
            height: var(--icon-size, 24px);
            overflow: hidden;
        }

        &.x-small {
            --icon-size: 12px;
        }

        &.small {
            --icon-size: 16px;
        }

        &.large {
            --icon-size: 32px;
        }

        .badge {
            position: absolute;
            top: -30%;
            right: -50%;
            background-color: var(--primary);
            border-radius: 50%;
            padding-top: 0.25rem;
            width: 1.25rem;
            height: 1.25rem;
            font-size: 0.5rem;
            line-height: 1.25;
            font-weight: bold;
            white-space: nowrap;
            text-align: center;
            color: var(--primary-text);
            z-index: 1;
        }
    }

    .icon-svg {
        transition: all 0.1s ease;
        svg {
            display: block;
            path {
                fill: var(--icon-color);
                transition: all 0.2s ease-out;
            }
        }
    }

    .icon.primary svg path {
        fill: var(--primary);
    }
</style>

<docs>
Normal use:
```vue
<icon name="home"/>
```

With a badge:
```vue
<icon name="contact-im" badge="1"/>
<icon name="contact-im" badge="999"/>
```

With a color:
```vue
<icon name="user-circle" color="gray"/>
<icon name="user-circle" color="var(--primary)"/>
<icon name="user-circle" color="var(--warning)"/>
<icon name="user-circle" color="#46916E"/>
```

With a size:
```vue
<icon name="map-pin" size="x-small"/>
<icon name="map-pin" size="small"/>
<icon name="map-pin" size="normal"/>
<icon name="map-pin" size="large"/>
```
</docs>
