<template>
    <div
        v-resize:compact-header="450"
        class="comment-header"
    >
        <div class="metadata">
            <div class="text">
                <div class="author-row">
                    <div
                        v-resize:longname.gt="140"
                        class="author ellipsis"
                    >
                        <loading-content-author
                            :user-uid="authorUserUid"
                            linked
                        />
                    </div>
                    <div
                        v-if="isContentAuthor"
                        v-tooltip.bottom="$t('is_author')"
                        class="author-pill"
                    >
                        <Icon
                            name="user"
                            size="small"
                        />
                    </div>
                </div>
                <div class="meta">
                    <div class="ellipsis-wrapper">
                        <router-link
                            :to="commentRoute"
                            class="ellipsis hover-underline"
                            :class="{'pointer-events-none': !hasCommentRoute}"
                        >
                            {{ formattedCreationDate }}
                        </router-link>
                        <Icon
                            v-if="isEdited"
                            v-tooltip="formattedLastEditedDate"
                            name="edit"
                            size="small"
                            style="display:inline-block"
                        />
                    </div>
                    <UnreadIndicator
                        :is-unread="isUnread"
                        class="unread-indicator"
                    />
                </div>
            </div>
        </div>
        <div
            v-if="showDotMenu"
            class="interactions"
        >
            <v-popover
                ref="popover"
                class="three-dot-menu"
                popover-arrow-class="tooltip-arrow popover-arrow arrow-top"
                hide-on-target-click="true"
                placement="top-end"
            >
                <app-button
                    type="light only-icon"
                    icon="ellipsis"
                />
                <template slot="popover">
                    <div
                        v-close-popover
                        class="actions"
                    >
                        <app-button
                            v-if="hasCommentRoute"
                            type="link no-padding small"
                            class="edit-action"
                            icon="link"
                        >
                            <div v-clipboard="deeplink">
                                {{ $t('copy_direct_link') }}
                            </div>
                        </app-button>
                        <app-button
                            v-if="isModificationAllowed"
                            type="link no-padding small"
                            class="comment-action edit-action"
                            icon="edit"
                            @click="$emit('edit')"
                        >
                            {{ $t('edit') }}
                        </app-button>
                        <app-button
                            v-if="isModificationAllowed"
                            type="link no-padding small"
                            class="comment-action delete-action"
                            icon="trash"
                            :busy="waitingForDelete"
                            @click="$emit('delete')"
                        >
                            {{ $t('delete') }}
                        </app-button>
                    </div>
                </template>
            </v-popover>
        </div>
    </div>
</template>

<script>
import {
    friendlyFormatDateFromDayJs,
    toDayJs
} from "@web/lib/time-utils";
import { AUTH_MODULE_NAME } from "@web/store/auth/auth";
import { CURRENT_USER } from "@web/store/auth/getters";
import { mapGetters } from "vuex";
import UnreadIndicator from "@web/components/pings/UnreadIndicator";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import { IS_ADMIN } from "@web/store/intranet/getters";
import LoadingContentAuthor from "@web/components/user/LoadingContentAuthor";

/**
 * meta data view for posts and comments
 */
export default {
    components: {
        LoadingContentAuthor,
        UnreadIndicator,
    },
    props: {
        authorUserUid: String,
        topic: String,
        creationDate: Object,
        lastEditedDate: Object,
        isJustEdited: Boolean,
        isCommentDeleted: Boolean,
        showActionMenu: Boolean,
        isContentAuthor: Boolean,
        commentRoute: String,
        waitingForDelete: Boolean,
        isUnread: Boolean,
    },
    computed: {
        ...mapGetters({
            currentUser: AUTH_MODULE_NAME + CURRENT_USER,
            isAdmin: INTRANET_MODULE_NAME + IS_ADMIN,
        }),
        formattedCreationDate() {
            return friendlyFormatDateFromDayJs(toDayJs(this.creationDate));
        },
        detailedCreationDate() {
            return `${this.$t("created")}: ${toDayJs(this.creationDate).toString()}`;
        },
        formattedLastEditedDate() {
            return `${this.$t("edited")}: ${friendlyFormatDateFromDayJs(toDayJs(this.lastEditedDate))}`;
        },
        detailedLastEditedDate() {
            return `${this.$t("edited")}: ${toDayJs(this.lastEditedDate).toString()}`;
        },
        isEdited() {
            return this.isJustEdited || (!toDayJs(this.lastEditedDate).isSame(toDayJs(this.creationDate)));
        },
        isModificationAllowed() {
            return (this.isCommentAuthor || this.isAdmin) && !this.isCommentDeleted;
        },
        isCommentAuthor() {
            return this.currentUser && this.currentUser.uid === this.authorUserUid;
        },
        hasCommentRoute() {
            return this.commentRoute && this.commentRoute.length > 0;
        },
        deeplink() {
            return window.location.origin + this.commentRoute;
        },
        showDotMenu() {
            if (this.isCommentDeleted) {
                return false;
            }
            if (!this.showActionMenu) {
                return false;
            }
            return this.hasCommentRoute || this.isCommentAuthor;
        },
    }
};
</script>

<style lang="scss" scoped>
.actions {
    min-width: 100px;
    display: flex;
    flex-direction: column;

    &::v-deep {
        .button + .button {
            margin: 0;
        }
    }
}

.comment-header {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin-top: -.5rem;
    margin-right: -.5rem;
    font-size: 12px;
    height: 40px;

    &::v-deep {
        .reaction-graph {
            font-size: 0.5rem;
        }
    }

    .button {
        background: none !important;
        padding: 0.4rem;
    }

    .metadata {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        flex: 1;

        .text {
            display: flex;
            flex-wrap: nowrap;
            flex-direction: row;
            align-items: center;
            user-select: none;
            flex: 1 1 auto;

            .author-row {
                display: flex;
                flex: 0 1 auto;
                flex-direction: row;
                flex-wrap: nowrap;
                margin-right: 0.3rem;
                min-width: 0;

                .author {
                    font-weight: bold;
                    flex: 0 1 auto;
                    line-height: 1.4rem; //this line only works with a unit
                }

                .author-pill {
                    margin: 0 0.3rem 0;
                    padding: 0.2rem 0.3rem;
                    background: $low-contrast;
                    border-radius: $border-radius-medium;
                    font-weight: 600;
                    font-size: 0.6rem;
                    text-transform: uppercase;
                    --icon-size: 1rem;
                    --icon-color: #{$high-contrast};
                }

                .target {
                    font-size: 0.75rem;
                    font-weight: 500;
                    line-height: 1.33;

                    .arrow {
                        margin: 0 0.3rem;
                        font-weight: 500;
                        opacity: 0.3;
                        font-size: 0.75rem;
                        line-height: 2;
                    }
                }
            }

            .ellipsis-wrapper {
                display: inline-flex;
                overflow: hidden;
                align-items: center;
            }

            .meta {
                line-height: 1;
                flex: 1 1 auto;
                flex-wrap: nowrap;
                display: flex;
                align-items: center;
                .icon {
                    margin-left: 0.4rem;
                    opacity: 0.4;
                    flex:0 0 auto;
                }
                .unread-indicator {
                    margin-left: .5rem;
                }
            }
        }

        .avatar {
            margin-right: 0.75rem;
            pointer-events: none;
        }
    }

    .interactions {
        flex: 0 0 auto;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: space-between;
        &::v-deep {
            .button {
                --icon-size: 1.25rem;
                padding: 0.2rem;
                margin: 0.2rem;
                &.selected {
                    transform: scale(0.75);
                    background:var(--low-contrast, #{$light-grey});
                }
            }
        }
        > div {
            margin-left: 0.5rem;
        }

        .actions {
            margin-left: 0.5rem;
        }

        .button {
            background: none;
        }
    }

    .avatar {
        margin-right: 0.5rem;
        flex: 0 0 auto;
    }
    &.compact-header {
        .author {
            &.longname {
                max-width: 152px;
            }
        }
    }
        .interactions {
            > div {
                margin-left: 0;
            }
        }

        .edited-badge {
            font-weight: 300;
            color: var(--medium-contrast, #{$mid-grey});
        }

}
.small-post {
    .comment-header {
        .metadata {
            .author-row {
                flex: 1 1 auto;
                width: 0;
                .author {
                    flex: unset;
                }
            }
            .text {
                .meta {
                    display: none;
                }

            }
        }
    }
}
</style>
