import Vue from "vue";
import { Store } from "vuex";
import { EverestPost } from "@backend/post/types";
import {
    postService,
    SearchPostsParams,
} from "@web/store/post/PostService";
import { TimestampEvent } from "@web/store/timestamping/types";
import {
    AttachmentRootEntityType,
    EntityWithAttachmentReference,
} from "@web/services/attachments";
import { VuexRootState } from "@web/store";
import { TIMESTAMP_MODULE_NAME } from "@web/store/timestamping/timestamping";
import { RESOLVE_EVENT } from "@web/store/timestamping/mutations";
import {
    PostsLocalStore,
    PostsMethods,
    PostsState,
    UsePostsParams,
} from "@web/store/post/PostsLocalStore.types";
import { createFilterToExcludeExisting } from "@web/lib/exclude-existing-filter";
import { LIVE_NEW_CONTENT_MODULE_NAME } from "@web/store/live-new-content/live-new-content";
import { SET_POST_TIMELINE_RELOAD } from "@web/store/live-new-content/mutations";

export function usePosts(store: Store<VuexRootState>, { topicUids, postPageSize = 10, initialCommentsPageSize = 3 }: UsePostsParams): PostsLocalStore {
    const state = Vue.observable<PostsState>({
        posts: [],
        initiallyLoaded: false,
        hasMorePosts: false,
        isLoading: false,
        hasError: false,
    });

    function setPosts(posts: EverestPost[]): void {
        state.posts = posts;
    }

    function prependPosts(posts: EverestPost[]): void {
        state.posts = [...posts, ...state.posts.filter(createFilterToExcludeExisting(posts))];
    }

    function appendPosts(posts: EverestPost[]): void {
        state.posts = [...state.posts.filter(createFilterToExcludeExisting(posts)), ...posts];
    }

    const methods: PostsMethods = {
        async fetchPostsInTopic({ initial, onlyUpdates } = {}) {
            state.isLoading = true;
            state.hasError = false;
            const intranetUid = store.state.intranet.intranet!.uid;
            const limit = postPageSize + 1;
            const withComments = initialCommentsPageSize;
            let commitMethod: (posts: EverestPost[]) => void;
            const searchPostsParams: SearchPostsParams = {
                intranetUid,
                withComments,
                limit,
                topics: topicUids,
            };
            if (initial) {
                state.initiallyLoaded = false;
                commitMethod = setPosts;
            } else if (onlyUpdates) {
                // Load sum of updates posts and comments to ensure that all content updates are loaded
                searchPostsParams.limit = store.state.timestamp.count[TimestampEvent.postPublished] + store.state.timestamp.count[TimestampEvent.postCommentPublished];
                commitMethod = prependPosts;
            } else {
                searchPostsParams.startAfter = state.posts[state.posts.length - 1].uid;
                commitMethod = appendPosts;
            }
            try {
                store.commit(LIVE_NEW_CONTENT_MODULE_NAME + SET_POST_TIMELINE_RELOAD, true);
                const { posts, lastViewed } = await postService.searchPosts(searchPostsParams);
                const loadedPosts = posts.slice(0, postPageSize);
                if (!onlyUpdates) {
                    state.hasMorePosts = posts.length > postPageSize;
                }
                commitMethod(loadedPosts);
                store.commit(TIMESTAMP_MODULE_NAME + RESOLVE_EVENT, TimestampEvent.postPublished);
                store.commit(TIMESTAMP_MODULE_NAME + RESOLVE_EVENT, TimestampEvent.postCommentPublished);
            } catch (e) {
                state.hasError = true;
            } finally {
                state.initiallyLoaded = true;
                state.isLoading = false;
                store.commit(LIVE_NEW_CONTENT_MODULE_NAME + SET_POST_TIMELINE_RELOAD, false);
            }
        },
        async createPostInTopic({ content, topics, attachmentManager }) {
            const intranetUid = store.state.intranet.intranet!.uid;
            const postDraft = await postService.createPost(intranetUid);
            await attachmentManager.save(
                new EntityWithAttachmentReference({
                    intranetUid,
                    entityType: AttachmentRootEntityType.post,
                    entityUid: postDraft.uid,
                    entity: postDraft,
                }),
                postDraft.creatorUid,
                true,
            );
            const post = await postService.editPost({ intranetUid, postUid: postDraft.uid, content, topics });
            state.posts.unshift({
                ...post,
                // adds an arbitrary flag to indicate self created posts
                _new: true,
                comments: [],
            });
            return post;
        },
        async editPost({ postUid, content, topics, attachmentManager }) {
            const intranetUid = store.state.intranet.intranet!.uid;
            const updatedPost = await postService.editPost({ intranetUid, postUid, content, topics });
            await attachmentManager.save(
                new EntityWithAttachmentReference({
                    intranetUid,
                    entityType: AttachmentRootEntityType.post,
                    entityUid: updatedPost.uid,
                    entity: updatedPost,
                }),
                updatedPost.creatorUid,
            );
            const postIndex = state.posts.findIndex(p => p.uid === postUid);
            if (postIndex >= 0) {
                state.posts.splice(postIndex, 1, { ...updatedPost, comments: state.posts[postIndex].comments });
            }
        },
        async deletePostInTopic(postUid) {
            const intranetUid = store.state.intranet.intranet!.uid;
            await postService.deletePost(intranetUid, postUid);
            const postIndex = state.posts.findIndex(p => p.uid === postUid);
            if (postIndex >= 0) {
                state.posts.splice(postIndex, 1);
            }
        },
    };

    return {
        state,
        methods,
    };
}
