import { EverestStory } from "@backend/story/types";
import { StoryResource } from "@web/store/story/StoryResource";
import { CachingService } from "@web/store/story/CachingService";

export interface StoryRequestParameter {
    intranetUid: string;
    limit: number;
    story?: EverestStory;
}

export interface UserStoryRequestParameter extends StoryRequestParameter {
    userUid: string;
}

export interface DeleteStoryParameter {
    intranetUid: string;
    userUid: string;
    story: EverestStory;
}

export class StoryService {
    private readonly resource = new StoryResource();
    private readonly cachingService = new CachingService("linchpin-cloud-story-service-cache-key");

    async fetchStories({ intranetUid, story, limit }: StoryRequestParameter): Promise<EverestStory[]> {
        return await this.resource.fetchStories(intranetUid, limit, story?.creatorUid);
    }

    async fetchStoriesForUser({ intranetUid, userUid, limit }: UserStoryRequestParameter): Promise<Array<EverestStory>> {
        return await this.resource.fetchStoriesForUser(intranetUid, userUid, limit);
    }

    async fetchNextUser(intranetUid: string, userUid: string): Promise<string> {
        if (userUid !== "") {
            return await this.resource.fetchNextUser(intranetUid, userUid, 1, "desc");
        }
        return Promise.resolve("");
    }

    async fetchPreviousUser(intranetUid: string, userUid: string): Promise<string> {
        if (userUid != "") {
            return await this.resource.fetchNextUser(intranetUid, userUid, 1, "asc");
        }
        return Promise.resolve("");
    }

    async fetchDownloadUrlForStoryImage(intranetUid: string, everestStory: EverestStory): Promise<string> {
        return new Promise(async(resolve) => {
            const cacheKey = this.cachingService.createCacheKey(intranetUid, everestStory.uid, everestStory.imageRef);
            const downLoadUrl = this.cachingService.loadContent(cacheKey);
            if (!downLoadUrl) {
                const result = await this.resource.downloadUrlForStoryImage(intranetUid, everestStory.uid, everestStory.imageRef);
                this.cachingService.saveCacheKey(cacheKey, result.url, result.expire);
                resolve(result.url);
            } else {
                resolve(downLoadUrl);
            }
        });
    }

    async deleteStoryFromUser({ intranetUid, userUid, story }: DeleteStoryParameter): Promise<void> {
        return await this.resource.deleteStoryFromUser(intranetUid, userUid, story.uid);
    }
}
