import { VuexRootState } from "@web/store";
import { Module } from "vuex";
import {
    EverestEvent,
    EverestEventAttendee,
} from "@backend/event/types";
import {
    CREATE_EVENT,
    DELETE_EVENT,
    FETCH_ATTENDEE_STATUS_FOR_CURRENT_USER,
    FETCH_ATTENDEES_FOR_CURRENT_EVENT,
    FETCH_EVENTS,
    FETCH_MY_EVENTS,
    INITIAL_FETCH_EVENTS,
    JOIN_EVENT,
    LEAVE_EVENT,
    LOAD_CURRENT_EVENT,
    LOAD_EVENT,
    RELOAD_EVENT_IN_LIST,
    RELOAD_EXISTING_EVENT_RANGE,
    UPDATE_EVENT,
} from "@web/store/event/actions";
import { eventService } from "@web/services";
import {
    SET_ATTENDEE_STATUS_FOR_CURRENT_USER,
    SET_ATTENDEES_FOR_EVENT,
    SET_CURRENT_EVENT,
    SET_EVENTS,
} from "@web/store/event/mutations";
import {
    GET_ATTENDEES_FOR_EVENT,
    GET_CURRENT_EVENT,
    GET_CURRENT_USER_ATTENDEE_STATUS,
    GET_EVENTS,
    HAS_NEXT_ATTENDEES_FOR_EVENT,
    IS_NEXT_PAGE,
} from "@web/store/event/getters";
import Vue from "vue";
import { asTimestamp } from "@web/lib/time-utils";

export interface EventList {
    events: EverestEvent[];
    isNextPage: boolean;
}

export enum EventListFilterKeys {
    publishedUpcoming = "publishedUpcoming",
    myCreatedEvents = "myCreatedEvents",
    myEvents = "myEvents"
}

export interface EventListsByFilter {
    [EventListFilterKeys.publishedUpcoming]: EventList;
    [EventListFilterKeys.myCreatedEvents]: EventList;
    [EventListFilterKeys.myEvents]: EventList;

}

export interface EventModuleState {
    eventListsByFilter: EventListsByFilter;
    currentEvent: EverestEvent | null;
    currentEventAttendees: EverestEventAttendee[];
    currentEventHasNextAttendees: boolean;
    // TODO: Change to map when Vue 3 supports Map
    currentUserAttendeeStatusByEventUid: any; // Map<string, EverestEventAttendeeStatus>;
}

const getDefaultState = (): EventModuleState => ({
    eventListsByFilter: {
        publishedUpcoming: {
            events: [],
            isNextPage: false,
        },
        myCreatedEvents: {
            events: [],
            isNextPage: false,
        },
        myEvents: {
            events: [],
            isNextPage: false,
        },
    },
    currentEvent: null,
    currentEventAttendees: [],
    currentEventHasNextAttendees: false,
    currentUserAttendeeStatusByEventUid: {},
});

export const EVENT_MODULE_NAME = "event/";
const EVENT_PAGE_SIZE = 10;
const EVENT_ATTENDEES_PAGE_SIZE = 10;

export const EVENTS_MODULE = {
    strict: true,
    namespaced: true,
    state: getDefaultState(),
    getters: {
        [GET_EVENTS]: state => ({ eventListKey = EventListFilterKeys.publishedUpcoming } = {}) => {
            const checkedEventListKey = eventListsFilterKeyOrDefault(eventListKey);
            return [...state.eventListsByFilter[checkedEventListKey].events];
        },
        [IS_NEXT_PAGE]: state => ({ eventListKey = EventListFilterKeys.publishedUpcoming } = {}) => {
            const checkedEventListKey = eventListsFilterKeyOrDefault(eventListKey);
            return state.eventListsByFilter[checkedEventListKey].isNextPage;
        },
        [GET_ATTENDEES_FOR_EVENT]: state => () => {
            if (!state.currentEventAttendees) {
                return [];
            }
            return [...state.currentEventAttendees];
        },
        [HAS_NEXT_ATTENDEES_FOR_EVENT]: state => () => {
            return state.currentEventHasNextAttendees;
        },
        [GET_CURRENT_USER_ATTENDEE_STATUS]: state => (event: EverestEvent) => {
            return state.currentUserAttendeeStatusByEventUid[event.uid];
        },
        [GET_CURRENT_EVENT]: state => {
            return state.currentEvent;
        },
    },
    mutations: {
        [SET_EVENTS](state, { events, isNextPage, eventListKey }: { events: EverestEvent[], isNextPage?: boolean, eventListKey: EventListFilterKeys }) {
            const checkedListKey = eventListsFilterKeyOrDefault(eventListKey);
            state.eventListsByFilter[checkedListKey].events = events;

            if (isNextPage !== undefined) {
                state.eventListsByFilter[checkedListKey].isNextPage = isNextPage;
            }
        },
        [SET_ATTENDEES_FOR_EVENT](state, { attendees, isNextPage }: { attendees: EverestEventAttendee[], isNextPage: boolean }) {
            state.currentEventAttendees = attendees;
            state.currentEventHasNextAttendees = isNextPage;
        },
        [SET_ATTENDEE_STATUS_FOR_CURRENT_USER](state, { event, attendee }: { event: EverestEvent, attendee: EverestEventAttendee }) {
            Vue.set(state.currentUserAttendeeStatusByEventUid, event.uid, attendee?.status);
        },
        [SET_CURRENT_EVENT](state, currentEvent) {
            state.currentEvent = currentEvent;
            if (currentEvent?.uid !== state.currentEvent?.uid) {
                // Reset current event attendees, when current event changes
                state.currentEventAttendees = getDefaultState().currentEventAttendees;
                state.currentEventHasNextAttendees = getDefaultState().currentEventHasNextAttendees;
            }
        },
    },
    actions: {
        async [INITIAL_FETCH_EVENTS]({ dispatch, commit, rootState, state }, { eventListKey = EventListFilterKeys.publishedUpcoming, limit = EVENT_PAGE_SIZE } = {}) {
            commit(SET_EVENTS, { events: [], isNextPage: true, eventListKey });
            await dispatch(FETCH_EVENTS, { eventListKey, limit });
        },
        async [FETCH_EVENTS]({ dispatch, commit, rootState, state }, { eventListKey = EventListFilterKeys.publishedUpcoming, limit = EVENT_PAGE_SIZE } = {}) {
            const intranetUid = rootState.intranet.intranet!.uid;
            const checkedEventListKey = eventListsFilterKeyOrDefault(eventListKey);

            if (checkedEventListKey === EventListFilterKeys.myEvents) {
                dispatch(FETCH_MY_EVENTS);
                return;
            }

            const previousEvents = state.eventListsByFilter[checkedEventListKey].events;
            const loadedEvents = await eventService.getEvents({
                ...filterParamsForEventList(checkedEventListKey, rootState),
                intranetUid,
                periodStart: asTimestamp(new Date()), // TODO: Move to eventsFilterParams for configurable ranges in the ui
                startAfter: previousEvents[previousEvents.length - 1]?.uid,
                limit: limit + 1,
            });
            const isNextPage = loadedEvents.length === limit + 1;
            if (isNextPage) {
                loadedEvents.pop();
            }
            const events = previousEvents.concat(loadedEvents);
            commit(SET_EVENTS, { events, isNextPage, eventListKey: checkedEventListKey });
            await dispatch(FETCH_ATTENDEE_STATUS_FOR_CURRENT_USER);
        },
        async [FETCH_MY_EVENTS]({ dispatch, commit, rootState }) {
            const intranet = rootState.intranet.intranet!;
            const events = await eventService.getMyEvents(intranet);

            // My events has not pagination
            const isNextPage = false;
            commit(SET_EVENTS, { events, isNextPage, eventListKey: EventListFilterKeys.myEvents });
            await dispatch(FETCH_ATTENDEE_STATUS_FOR_CURRENT_USER);
        },
        async [FETCH_ATTENDEES_FOR_CURRENT_EVENT]({ rootState, state, commit }, { reloadAttendees = false }: { reloadAttendees: boolean, resetAttendees: boolean }) {
            const intranet = rootState.intranet.intranet!;
            if (!state.currentEvent) {
                console.debug("no current event");
                return;
            }
            const previousAttendeesForEvent = state.currentEventAttendees || [];

            const limit = reloadAttendees ? Math.max(previousAttendeesForEvent.length, EVENT_ATTENDEES_PAGE_SIZE) : EVENT_ATTENDEES_PAGE_SIZE;
            const startAfter = reloadAttendees ? undefined : previousAttendeesForEvent[previousAttendeesForEvent.length - 1]?.changedTimestamp?._seconds;

            const loadedAttendees = await eventService.getAttendeeForEvent(intranet, state.currentEvent, {
                limit: limit + 1,
                startAfter,
            });
            const isNextPage = loadedAttendees.length === limit + 1;
            if (isNextPage) {
                // TODO: Use immutable way to pop the item
                loadedAttendees.pop();
            }
            if (reloadAttendees) {
                commit(SET_ATTENDEES_FOR_EVENT, {
                    attendees: loadedAttendees,
                    isNextPage,
                });
            } else {
                commit(SET_ATTENDEES_FOR_EVENT, {
                    attendees: previousAttendeesForEvent.concat(loadedAttendees),
                    isNextPage,
                });
            }
        },
        async [RELOAD_EXISTING_EVENT_RANGE]({ rootState, state, commit, dispatch }, { eventListKey = EventListFilterKeys.publishedUpcoming, limit = EVENT_PAGE_SIZE } = {}) {
            const intranetUid = rootState.intranet.intranet!.uid;
            const checkedEventListKey = eventListsFilterKeyOrDefault(eventListKey);
            const previousEvents = state.eventListsByFilter[checkedEventListKey].events;
            if (previousEvents.length < limit) {
                dispatch(INITIAL_FETCH_EVENTS, { eventListKey });
                return;
            }
            const events = await eventService.getEvents({
                intranetUid,
                periodStart: asTimestamp(new Date()),
                endAt: previousEvents[previousEvents.length - 1].uid,
            });
            const isNextPage = previousEvents.length <= events.length;
            commit(SET_EVENTS, { events, isNextPage, eventListKey: checkedEventListKey });
            await dispatch(FETCH_ATTENDEE_STATUS_FOR_CURRENT_USER);
        },
        async [FETCH_ATTENDEE_STATUS_FOR_CURRENT_USER]({ commit, state, rootState }) {
            const intranet = rootState.intranet.intranet!;
            const user = rootState.auth.currentUser!;
            const allEvents = Object.values(EventListFilterKeys)
                .flatMap(eventListKey => state.eventListsByFilter[eventListKey].events);

            const uniqueEvents = allEvents.filter((event, index) => {
                return index === allEvents.findIndex(otherEvent => otherEvent.uid === event.uid);
            });

            await Promise.all(uniqueEvents.map((event) => {
                return eventService.getAttendeeStatus(intranet, user, event).then(attendee => {
                    commit(SET_ATTENDEE_STATUS_FOR_CURRENT_USER, { event, attendee });
                });
            }));
        },
        async [RELOAD_EVENT_IN_LIST]({ commit, state, rootState }, { event, setAsCurrentEvent }) {
            const intranet = rootState.intranet.intranet!;
            const reloadedEvent = await eventService.getEvent(intranet, event.uid);

            // Set reloaded event in all lists
            Object.values(EventListFilterKeys).forEach(eventListKey => {
                const events = state.eventListsByFilter[eventListKey].events.map(event => {
                    if (event.uid === reloadedEvent.uid) {
                        return reloadedEvent;
                    }
                    return event;
                });
                commit(SET_EVENTS, { events, eventListKey });
            });
            if (setAsCurrentEvent) {
                commit(SET_CURRENT_EVENT, reloadedEvent);
            }
        },
        async [LOAD_EVENT]({ rootState, commit }, { eventUid }) {
            const intranet = rootState.intranet.intranet!;
            const event = await eventService.getEvent(intranet, eventUid);
            const user = rootState.auth.currentUser!;
            const attendee = await eventService.getAttendeeStatus(intranet, user, event);
            commit(SET_ATTENDEE_STATUS_FOR_CURRENT_USER, { event: event, attendee });
            return event;
        },
        async [LOAD_CURRENT_EVENT]({ dispatch, commit }, { eventUid }) {
            const event = await dispatch(LOAD_EVENT, { eventUid });
            commit(SET_CURRENT_EVENT, event);
        },
        async [CREATE_EVENT]({ commit, rootState }) {
            const intranet = rootState.intranet.intranet!;
            const currentEvent = await eventService.createEvent(intranet);
            commit(SET_CURRENT_EVENT, currentEvent);
            // TODO: Add to existing list?
        },
        async [UPDATE_EVENT]({ dispatch, commit, state, rootState, getters }, { event }) {
            const intranet = rootState.intranet.intranet!;
            const updatedEvent = await eventService.updateEvent(intranet, event);
            commit(SET_CURRENT_EVENT, null);
            // Reload all lists
            Object.values(EventListFilterKeys).forEach((eventListKey: string) => {
                dispatch(RELOAD_EXISTING_EVENT_RANGE, { eventListKey });
            });
            return updatedEvent;
        },
        async [JOIN_EVENT]({ dispatch, commit, state, rootState, getters }, { event }) {
            const intranet = rootState.intranet.intranet!;
            const user = rootState.auth.currentUser!;
            const attendee = await eventService.joinEvent(intranet, user, event);
            event.attendees++;
            event.lastAttendees.unshift(user.uid);
            commit(SET_ATTENDEE_STATUS_FOR_CURRENT_USER, { event, attendee });
            dispatch(RELOAD_EVENT_IN_LIST, { event, setAsCurrentEvent: true });
            dispatch(FETCH_ATTENDEES_FOR_CURRENT_EVENT, { reloadAttendees: true });
        },
        async [LEAVE_EVENT]({ dispatch, commit, state, rootState, getters }, { event }) {
            const intranet = rootState.intranet.intranet!;
            const user = rootState.auth.currentUser!;
            const attendee = await eventService.leaveEvent(intranet, user, event);
            event.attendees--;
            event.lastAttendees = event.lastAttendees.filter((uid: string) => uid !== user.uid);
            commit(SET_ATTENDEE_STATUS_FOR_CURRENT_USER, { event, attendee });
            dispatch(RELOAD_EVENT_IN_LIST, { event, setAsCurrentEvent: true });
            dispatch(FETCH_ATTENDEES_FOR_CURRENT_EVENT, { reloadAttendees: true });
        },
        async [DELETE_EVENT]({ dispatch, commit, state, rootState, getters }, { event }) {
            const intranet = rootState.intranet.intranet!;
            await eventService.deleteEvent(intranet, event);

            // Filter deleted event from all lists
            Object.values(EventListFilterKeys).forEach(eventListKey => {
                const events = state.eventListsByFilter[eventListKey].events.filter(eventFromState => event.uid !== eventFromState.uid);
                commit(SET_EVENTS, { events, eventListKey });
            });
        },
    },
} as Module<EventModuleState, VuexRootState>;

function eventListsFilterKeyOrDefault(eventListKey: any): EventListFilterKeys {
    if (eventListKey === undefined) {
        return EventListFilterKeys.publishedUpcoming;
    }
    if (Object.values(EventListFilterKeys).includes(eventListKey)) {
        return eventListKey as EventListFilterKeys;
    }
    throw new Error(`Unknown eventListKey: ${eventListKey}`);
}

function filterParamsForEventList(eventListKey: EventListFilterKeys, rootState: VuexRootState) {
    const creatorUid = rootState.auth.currentUser!.uid;
    return eventListKey === EventListFilterKeys.myCreatedEvents ? { creatorUid } : {};
}
