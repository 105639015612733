<template>
    <div class="bubbles-container">
        <div
            class="bubbles"
            :class="[loggedIn ? 'hasUser' : 'hasNoUser']"
        >
            <div class="bubble bubble-1" ></div>
            <div class="bubble bubble-2" ></div>
            <div class="bubble bubble-3" ></div>
            <div class="bubble bubble-4" ></div>
            <div class="bubble bubble-5" ></div>
        </div>
    </div>
</template>

<script>
import { AUTH_MODULE_NAME } from "@web/store/auth/auth";
import { LOGGED_IN } from "@web/store/auth/getters";

import { mapGetters } from "vuex";

export default {
    name: "Bubbles",
    computed: {
        ...mapGetters({ loggedIn: AUTH_MODULE_NAME + LOGGED_IN })
    }
};
</script>

<style lang="scss" scoped>
    .bubbles-container {
        position: absolute;
        top: 0;
        left: 0;
        overflow: hidden;
        width: 100vw;
        height: 100vh;
    }
    .bubbles {
        position: absolute;
        top: -30vh;
        left: 0;
        height: 100vh;
        width: 100vw;
        overflow: visible;
        transition: transform 2s $ease-in-out-cubic;
        &.hasUser {
            transform: translate(0, 50%);
        }
    }
    .bubble {
        background: rgba(var(--background, #{$white}), 0.1);
        background: linear-gradient(
            180deg,
            var(--lowest-contrast, #{$off-white}) 0%,
            rgba(var(--background, #{$white}), 0.5) 100%
        );
        box-shadow: $depth-5;
        border-radius: 100%;
        position: absolute;
        transition: all 0.3s ease;
        animation: subtleMove 3s ease-in-out infinite alternate both running;
        $bubble1: 12vw;
        $bubble2: $bubble1 * 1.6;
        $bubble3: $bubble2 * 1.6;
        $bubble4: $bubble3 * 1.6;
        $bubble5: $bubble4 * 1.6;

        &.bubble-1 {
            left: 16vw;
            top: 61vh;
            width: $bubble1 * 1.6;
            height: $bubble1 * 1.6;
            animation-delay: 0.5s;
            animation-duration: 2.5s;
        }

        &.bubble-2 {
            left: 80vw;
            top: 74vh;
            width: $bubble2;
            height: $bubble2;
            animation-delay: 2.5s;
            animation-duration: 3s;
        }

        &.bubble-3 {
            left: 0vw;
            top: 72vh;
            width: $bubble3;
            height: $bubble3;
            animation-delay: 0.5s;
            animation-duration: 3.2s;
        }

        &.bubble-4 {
            left: 45vw;
            top: 28vh;
            width: $bubble4;
            height: $bubble4;
            animation-delay: 1.22s;
            animation-duration: 3.4s;
        }

        &.bubble-5 {
            left: 14vw;
            top: 50vh;
            width: $bubble5;
            height: $bubble5;
            animation-duration: 2.8s;
        }
    }
</style>
