export enum MailInviteInFireStoreFieldKeys {
    inviteId = "inviteId",
    mail = "mail",
    invitationDate = "invitationDate",
}

export interface MailInvite extends MailInviteInFireStore {
    [MailInviteInFireStoreFieldKeys.inviteId]: string;
}

export interface MailInviteInFireStore {
    [MailInviteInFireStoreFieldKeys.mail]: string;
    [MailInviteInFireStoreFieldKeys.invitationDate]: Timestamp;
}

export interface Timestamp {
    _seconds: number;
    _nanoseconds: number;
}
