<template>
    <div
        :class="{
            'new': ping.unread,
            'read': !ping.unread,
            'menu-visible': menuOpen
        }"
        class="inbox-ping"
    >
        <div class="ping-origin">
            <loading-avatar
                :user-uid="firstOriginatorUid"
                size="medium"
                :class="{ stacked: ping.occurrences > 1 }"
            />
        </div>
        <router-link
            v-close-popover.all
            :to="link"
            @click.native="readPing(true); $emit('close-inbox')"
        >
            <div class="ping-text">
                <div class="ping-content">
                    <div class="ping-title">
                        <loading-content-author
                            :user-uid="firstOriginatorUid"
                            class="bold"
                        />
                        <span class="multi-ping-occurrences bold">{{ getMultiPingOccurrencesLabelForPing(ping) }}</span>
                        <span class="activity-information"> {{ getActivityInformationLabelForPing(ping) }}</span>
                    </div>
                    <div class="ping-meta-line ping-subtitle">
                        <Icon
                            :name="icon"
                            class="type-icon small"
                        />
                        {{ getMetaLabelForPing(ping) }}
                    </div>
                </div>
            </div>
        </router-link>
        <div
            ref="pingMenu"
            class="ping-menu cursor-pointer"
        >
            <div
                v-if="ping.unread"
                :disabled="!ping.unread"
                class="unread-circle"
                @click="readPing(true)"
            ></div>
            <div
                v-else
                class="spacer"
            ></div>
            <v-popover
                ref="popover"
                :boundaries-element="menuBoundary"
                :container="menuBoundary"
                :open="menuOpen"
                :placement="inView ? 'top-end' : 'bottom-end'"
                :popover-arrow-class="`tooltip-arrow popover-arrow ${inView ? 'arrow-top' : 'arrow-bottom'}`"
                popover-class="ping-menu-popover"
                @hide="menuOpen = false"
                @show="onOpenMenu"
            >
                <Icon
                    class="cursor-pointer"
                    name="ellipsis"
                />
                <template slot="popover">
                    <Spinner v-if="isFollowing === null && tried"/>
                    <div
                        v-else
                        class="ping-actions padding-small"
                    >
                        <app-button
                            icon="check"
                            type="link no-padding small"
                            @click="readPing(ping.unread)"
                        >
                            {{ $t(`mark_as_${ping.unread ? "read" : "unread"}`) }}
                        </app-button>
                        <app-button
                            icon="trash"
                            type="link no-padding small"
                            @click="deletePing()"
                        >
                            {{ $t("remove") }}
                        </app-button>
                        <app-button
                            v-if="!(isFollowing === null) && tried"
                            :disabled="deleted"
                            :icon="isFollowing ? 'bell-off' : 'bell'"
                            type="link no-padding small"
                            @click="setPingFollowStatus(!isFollowing)"
                        >
                            {{ $t(isFollowing ? "activity_unfollow" : "activity_follow") }}
                        </app-button>
                    </div>
                </template>
            </v-popover>
        </div>
    </div>
</template>

<script>
import Vue from "vue";
import Icon from "@web/components/Icon.vue";
import {
    followService,
    inboxService,
} from "@web/services";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import { INTRANET } from "@web/store/intranet/getters";
import { mapState } from "vuex";
import {
    getActivityInformationLabelForPing,
    getMetaLabelForPing,
    getMultiPingOccurrencesLabelForPing,
} from "@web/views/intranet/inbox-ping-label-aggregation";
import Spinner from "@web/components/Spinner";
import { ActivityType } from "@backend/common/notification/activity-types";
import { MentionEntityType } from "@backend/mention/mention-types";
import LoadingAvatar from "@web/components/user/LoadingAvatar";
import LoadingContentAuthor from "@web/components/user/LoadingContentAuthor";

export default Vue.extend({
    name: "InboxPing",
    components: {
        LoadingContentAuthor,
        LoadingAvatar,
        Icon,
        Spinner,
    },
    props: {
        ping: { type: Object, default: () => ({}) },
        menuBoundary: { type: [Element, Window], default: document.documentElement },
        isLast: { type: Boolean, default: false },
    },
    data() {
        return {
            menuOpen: false,
            isFollowing: null,
            icons: {
                COMMENT_PUBLISHED: "message-square",
                NEWS_PUBLISHED: "newspaper",
                EVENT_PUBLISHED: "event",
                POST_PUBLISHED: "message-square",
                post: "message-square",
                news: "newspaper",
                event: "event",
            },
            pingType: this.ping.activity.type,
            pingMeta: this.ping.activity.meta,
            inView: false,
            tried: false,
            deleted: false,
        };
    },
    computed: {
        ...mapState(INTRANET_MODULE_NAME, [INTRANET]),
        type() {
            if (this.pingMeta.rootEntityType) {
                return this.pingMeta.rootEntityType;
            }
            return "";
        },
        icon() {
            return this.icons[this.type];
        },
        hasCommentWithRootEntityUid() {
            // News comments need to use the rootEntityUid, but have not deep link to comments
            return this.hasCommentWithEntityLocationPath ||
                (this.pingType === ActivityType.userMentioned && this.pingMeta.entityType === MentionEntityType.newsComment);
        },
        hasCommentWithEntityLocationPath() {
            return (this.pingType === ActivityType.reactionCreated && this.pingMeta.entityLocation.path !== undefined) ||
                (this.pingType === ActivityType.userMentioned && this.pingMeta.entityType === MentionEntityType.postComment);
        },
        activityUid() {
            if (this.hasCommentWithRootEntityUid) {
                return this.pingMeta.entityLocation.rootEntityUid;
            }
            if (this.pingMeta.entityUid) {
                return this.pingMeta.entityUid;
            }
            return "";
        },
        link() {
            const link = `/intranet/${this.ping.activity.intranetUid}/${this.type}/id/${this.activityUid}`;
            if (this.pingType === ActivityType.commentPublished && this.type === "post") {
                return `${link}/${this.pingMeta.commentPath}`;
            } else if (this.hasCommentWithEntityLocationPath) {
                return `${link}/${this.pingMeta.entityLocation.path}`;
            }
            return link;
        },
        firstOriginatorUid() {
            return this.ping.originatorUserUid || this.ping.originatorUserUids[0];
        },
    },
    methods: {
        readPing(read) {
            try {
                inboxService.updatePingByDocumentId(
                    this.intranet.uid,
                    this.$store.state.auth.currentUser.uid,
                    this.ping.id,
                    { unread: !read },
                );
            } catch {
                this.isFollowing = false;
                this.$notify({
                    group: "app",
                    type: "error",
                    text: `${this.$t("ping_failed_to_set_read_status")}`,
                });
            }
            this.ping.unread = !read;
            this.menuOpen = false;
        },
        deletePing() {
            try {
                inboxService.deletePingByDocumentId(this.intranet.uid, this.$store.state.auth.currentUser.uid, this.ping.id);
            } catch {
                this.$notify({
                    group: "app",
                    type: "error",
                    text: `${this.$t("ping_failed_to_delete_ping")}`,
                });
            }
            this.menuOpen = false;
        },
        async setPingFollowStatus(follow) {
            try {
                await followService[follow ? "follow" : "unfollow"]({
                    intranetUid: this.intranet.uid,
                    followableType: this.type,
                    contentUid: this.activityUid,
                });
                this.$notify({
                    group: "app",
                    type: "success",
                    text: `${this.$t(follow ? "ping_success_follow" : "ping_success_unfollow", [this.type])}`,
                });
                this.isFollowing = follow;
            } catch {
                this.$notify({
                    group: "app",
                    type: "error",
                    text: `${this.$t("ping_failed_to_set_follow_state")}`,
                });
            }
            this.menuOpen = false;
        },
        async onOpenMenu() {
            if (this.menuOpen) {
                return;
            }
            this.inView = this.checkIfInView();
            this.menuOpen = true;
            this.isFollowing = await followService.isFollowing(
                {
                    intranetUid: this.intranet.uid,
                    followableType: this.type,
                    contentUid: this.activityUid,
                },
            ).catch(e => {
                if (e.response.status === 404) {
                    this.deleted = true;
                    this.$notify({
                        group: "app",
                        type: "error",
                        text: `${this.$t("ping_entity_deleted", [this.pingMeta.rootEntityType])}`,
                    });
                } else {
                    this.$notify({
                        group: "app",
                        type: "error",
                        text: `${this.$t("ping_failed_fetch_follow_status")}`,
                    });
                }
            });
            this.tried = true;
        },
        checkIfInView() {
            const inboxBoundaries = this.menuBoundary.getBoundingClientRect();
            const pingBoundaries = this.$refs.pingMenu.getBoundingClientRect();
            return inboxBoundaries.bottom > pingBoundaries.top + pingBoundaries.height + 200;
        },
        getMultiPingOccurrencesLabelForPing: getMultiPingOccurrencesLabelForPing,
        getActivityInformationLabelForPing: getActivityInformationLabelForPing,
        getMetaLabelForPing: getMetaLabelForPing,
    },
});
</script>

<style lang="scss" scoped>
@keyframes breathingborder {
    0% {
        opacity: 1;
        transform: scale(1);
    }
    50% {
        opacity: 0;
        transform: scale(3);
    }
    100% {
        opacity: 0;
        transform: scale(3);
    }
}

.inbox-ping {
    padding: .75rem 1.5rem;
    max-width: 100%;
    position: relative;
    display: grid;
    grid-gap: 0 1rem;
    grid-template-columns: auto 1fr auto;
    transition: background-color .1s ease-in-out;

    &::v-deep {
        .v-popover {
            display: flex;
            opacity: 0;
            transition: opacity .2s ease-in-out;
            pointer-events: none;
        }

        &:hover, &.menu-visible {
            .v-popover {
                opacity: 1;
                pointer-events: all;
            }
        }
    }

    &:hover {
        background: var(--lowest-contrast, #{$off-white});
    }
}

.unread-circle {
    position: relative;
    margin: .4rem 0;
    cursor: pointer;
    align-self: center;

    &, &:after {
        content: ' ';
        width: .6rem;
        height: .6rem;
        box-sizing: border-box;
        border-radius: 50%;
        background: var(--primary);
    }

    &:after {
        position: absolute;
        animation: breathingborder 2s ease-out 1;
    }
}

.ping-content {
    padding-right: 1rem;
}

.ping-subtitle {
    margin-top: .25rem;
    line-height: 1;
}

.type-icon {
    vertical-align: bottom;
    margin-right: .25rem;
    display: inline-block;
}

.ping-origin {
    position: relative;
    z-index: 1;
}

.stacked {
    position: relative;
    display: inline-block;

    &:before, &:after {
        content: ' ';
        display: block;
        border-radius: 50%;
        border: 2px solid var(--background, #{$white});
        position: absolute;
        width: 44px;
        height: 44px;
        left: 0;
    }

    &:before {
        top: 6px;
        background: var(--medium-contrast, #{$mid-grey});
        z-index: -1;
    }

    &:after {
        top: 12px;
        background: var(--low-contrast, #{$light-grey});
        z-index: -2;
    }
}

.ping-subtitle {
    opacity: 0.5;
}

.ping-menu {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    height: 100%;

}

.ping-actions {
    margin: -.5rem;
    min-width: 20ch;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    > a {
        padding: .5rem 0;
        margin: 0 .5rem;
    }
}
</style>
