<template>
    <span class="topic-label d-inline-flex flex-nowrap background-lowest-contrast border-radius-small overflow-hidden">
        <router-link
            v-if="to"
            :class="usedLabelClasses"
            :to="to"
            class="focus-outline-none"
        >
            <!-- @slot Label content to display -->
            <slot></slot>
        </router-link>
        <span
            v-if="!to"
            :class="usedLabelClasses"
        >
            <slot></slot>
        </span>
        <template v-if="!readonly">
            <!-- @slot adds custom actions with your own a-tags -->
            <slot name="action">
                <!--
                Triggered when clicking unsubscribe button part
                @event unsubscribe
                -->
                <a
                    v-if="subscribed"
                    class="px-xxsmall"
                    :title="$t('topics_unsubscribe')"
                    @click="$emit('unsubscribe')"
                >
                    <icon
                        color="var(--primary)"
                        name="check"
                        size="small"
                    />
                </a>
                <!--
                Triggered when clicking subscribe button part
                @event subscribe
                -->
                <a
                    v-if="!subscribed"
                    class="px-xxsmall"
                    :title="$t('topics_subscribe')"
                    @click="$emit('subscribe')"
                >
                    <icon
                        color="var(--high-contrast)"
                        name="plus"
                        size="small"
                    />
                </a>
            </slot>
        </template>
    </span>
</template>

<script>
/**
 * A label to display topics with the interaction possibility to subscribe and unsubscribe it.
 * Note: Pointer events on the label are disabled until topic pages are implemented.
 */
export default {
    name: "TopicLabel",
    inheritAttrs: false,
    props: {
        /**
         * A URL or router location for the label button part
         * @type {string | Location}
         * @see https://router.vuejs.org/api/#to
         */
        to: [String, Object],
        /** Displays the subscribed variant */
        subscribed: Boolean,
        /** Displays no option to subscribe */
        readonly: Boolean,
        /** Applied CSS classes to the label part */
        labelClasses: [String, Object, Array],
    },
    computed: {
        usedLabelClasses() {
            if (this.labelClasses) {
                return this.labelClasses;
            }
            if (!this.readonly) {
                return "pleft-small pright-xsmall py-xxsmall";
            }
            return "px-small py-xxsmall";
        },
    },
};
</script>

<style lang="scss" scoped>
.topic-label {
    line-height: 1.1;
    align-items: stretch;
    vertical-align: bottom;

    + .topic-label {
        margin-left: $spacing-xsmall;
    }
}

a {
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
        background-color: $low-contrast;
    }
}
</style>

<docs>
Basic topic usage:
```
<topic-label to="#">
    topic
</topic-label>
```

Subscribed variant:
```
<topic-label
    subscribed
    to="#"
>
    topic
</topic-label>
```

Unlinked variant:
```
<topic-label>topic</topic-label>
```

Readonly variant:
```
<topic-label readonly>
    topic
</topic-label>
```

Different label classes:
```
<topic-label
    label-classes="padding-xxsmall"
    readonly
>
    topic
</topic-label>
```

Subscribe callbacks:
```
<template>
    <topic-label
        @subscribe="subscribed = true"
        @unsubscribe="subscribed = false"
        :subscribed="subscribed"
    >
        topic
    </topic-label>
</template>
<script>
    export default {
        data() {
            return {
                subscribed: false,
            };
        },
    };
</script>
```

Custom action:
```
<template>
    <topic-label>
        topic
        <template #action>
            <a @click="action">
                woot
            </a>
        </template>
    </topic-label>
</template>
<script>
    export default {
        methods: {
            action() {
                window.alert("hi");
            },
        },
    };
</script>
```
</docs>
