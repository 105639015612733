import {MinPaginationSearchParameters} from "@backend/common/pagination-types";

export enum ReactableRootEntityType {
    news = "news",
    // newsComment = "news-comment", // deprecated
    post = "post",
    // postComment = "post-comment" // deprecated
    story = "story",
}

export enum ReactionDtoInFirestoreFieldKeys {
    reactionType = "reactionType",
    userUid = "userUid",
    rootEntityUid = "rootEntityUid",
    entityLocation = "entityLocation",
    recipientUserUid = "recipientUserUid",
    entityType = "entityType",
    entityUid = "entityUid",
    changedTimestamp = "changedTimestamp"
}

export interface ReactionInFirestore {
    [ReactionDtoInFirestoreFieldKeys.reactionType]: ReactionType;
    [ReactionDtoInFirestoreFieldKeys.userUid]: string;
    [ReactionDtoInFirestoreFieldKeys.rootEntityUid]: string;
    [ReactionDtoInFirestoreFieldKeys.entityLocation]: ReactableEntityLocation;
    [ReactionDtoInFirestoreFieldKeys.recipientUserUid]: ReactionRecipientUserUid;
    [ReactionDtoInFirestoreFieldKeys.entityType]: ReactableRootEntityType;
    [ReactionDtoInFirestoreFieldKeys.entityUid]: string;
    [ReactionDtoInFirestoreFieldKeys.changedTimestamp]: Timestamp;
}

// the users uid whose content got content was reacted to
// currently every reaction has to specify this. this may change in future when users can react to things like events
export type ReactionRecipientUserUid = string;

export interface ReactionWriteResult {
    removedReactionType?: ReactionType;
    newReactionType?: ReactionType;
}

export enum ReactionType {
    love = "love",
    like = "like",
    dislike = "dislike",
    clap = "clap",
    smile = "smile"
}

// Stable order for the reactions which should be independent from the enum definition
export const reactionOrder = [
    ReactionType.love,
    ReactionType.like,
    ReactionType.clap,
    ReactionType.smile,
    ReactionType.dislike
];

export interface ReactionsOnEntity {
    [ReactionType.love]: number;
    [ReactionType.like]: number;
    [ReactionType.dislike]: number;
    [ReactionType.clap]: number;
    [ReactionType.smile]: number;
}

export interface ReactableEntityLocation {
    rootEntityUid: string;
    path?: string;
}

interface Timestamp {
    _seconds: number;
    _nanoseconds: number;
}

export type ReactionsPaginationSearchParameters = MinPaginationSearchParameters<number>;

export class InvalidReactionError extends Error {
    constructor(message: string) {
        super(message);
    }
}
