<template>
    <div>
        <loading-topic-label
            v-for="topicUid in topicUids"
            :key="topicUid"
            :topic-uid="topicUid"
        />
    </div>
</template>

<script>
import LoadingTopicLabel from "@web/components/topics/LoadingTopicLabel";

export default {
    name: "LoadingTopicList",
    components: {
        LoadingTopicLabel,
    },
    props: {
        /** @type {string[]} */
        topicUids: Array,
    },
};
</script>

<style lang="scss" scoped>
</style>
