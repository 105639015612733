<template>
    <div
        ref="containerbox"
        class="macro-wrapper"
    >
        <div
            v-if="!hasError && intranet"
            class="triplet-grid"
        >
            <DashboardNews />
        </div>
        <MacroError
            v-if="hasError"
            :is-user-logged-in="isUserLoggedIn"
            :is-permission-denied-for-intranet="isPermissionDeniedForIntranet"
            i18n-feature-name="news"
        >
            <div class="triplet-grid blurred">
                <div class="dummy-news">
                    <div class="dummy-teaser-image grey-box" ></div>
                    <div class="dummy-headline grey-box" ></div>
                </div>
                <div class="dummy-news">
                    <div class="dummy-teaser-image grey-box" ></div>
                    <div class="dummy-headline grey-box" ></div>
                </div>
                <div class="dummy-news">
                    <div class="dummy-teaser-image grey-box" ></div>
                    <div class="dummy-headline grey-box" ></div>
                </div>
            </div>
        </MacroError>
    </div>
</template>

<script>
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import Vue from "vue";
import {
    mapActions,
    mapGetters,
} from "vuex";
import { resizingService } from "@web/services";
import { INITIALIZE_INTRANET } from "@web/store/intranet/actions";
import ConfluenceMacroMixin from "@web/views/atlassian-cloud/confluence/macro/ConfluenceMacroMixin.vue";
import MacroError from "@web/views/atlassian-cloud/confluence/macro/MacroError.vue";
import DashboardNews from "@/views/intranet/DashboardNews";
import { INTRANET } from "@/store/intranet/getters";

/**
 * this is a confluence cloud macro view, rendering the first 3 news of the linked intranet
 * intranet uid is provided via query param
 */
export default Vue.extend({
    name: "ConfluenceDashboardNews",
    components: {
        DashboardNews,
        MacroError
    },
    mixins: [
        ConfluenceMacroMixin
    ],
    computed: {
        ...mapGetters({
            intranet: INTRANET_MODULE_NAME + INTRANET
        }),
    },
    methods: {
        ...mapActions({ setup: INTRANET_MODULE_NAME + INITIALIZE_INTRANET }),
        handleError(e) {
            if (e.response.status === 403) {
                this.isPermissionDeniedForIntranet = true;
                resizingService.resize({
                    integration: this.$route.query.integration,
                    integrationUuid: this.$route.query.integrationUuid,
                });
                return;
            }
            this.isUnknownError = true;
        },
        async handleSuccess() {
            this.styleInject();
        },
        handleResize() {
            resizingService.resize({
                integration: this.$route.query.integration,
                integrationUuid: this.$route.query.integrationUuid,
                width: "100%",
                height: document.body.scrollHeight + "px",
                sizeToParent: false
            });
        },
    },
    data() {
        return {
            news: [],
            isPermissionDeniedForIntranet: false,
            resizeObserver: new ResizeObserver(entries => {
                this.handleResize();
            })
        };
    },
    mounted() {
        this.resizeObserver.observe(this.$refs.containerbox);
    },
    destroyed() {
        this.resizeObserver.disconnect();
    },
    async created() {
        this.appendAtlassianScriptToHead(async() => {
            this.isUserLoggedIn = this.isUserLoggedInIntoFirebase();
            if (!this.isUserLoggedIn) {
                resizingService.resize({
                    integration: this.$route.query.integration,
                    integrationUuid: this.$route.query.integrationUuid,
                });
                return;
            }
            await this.setup(this.intranetUid)
                .then(this.handleSuccess, this.handleError)
                .finally(this.handleResize());
        }, false);
    },
});
</script>
<style>
    body {
        padding: 20px 20px 0;
    }
</style>
<style lang="scss" scoped>
    .error-wrapper {
        position: relative;
    }

    .blurred {
        filter: blur(5px);
    }

    .grey-box {
        background: var(--lowest-contrast, #{$off-white});
        border-radius: 0.5rem;

        &.dummy-headline {
            height: 1.5rem;
            width: 80%;
        }

        &.dummy-teaser-image {
            padding-top: 60%;
            width: 100%;
            margin-bottom: 0.75rem;
        }
    }

    .error-overlay {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        left: 50%;
        transform: translate(-50%, -50%);
        top: 50%;
        max-width: 400px;
    }

    .grid {
        .col.half {
            .card {
                height: 100%;
            }
        }
    }

    .triplet-grid {
        display: flex;
        margin: 0;

        > div, > article {
            width: calc(30% - 2rem);
            flex: 1 0 auto;

            &.news-teaser.cover {
                padding-bottom: 0;
            }

            margin: 2rem 2rem 0;
        }
    }
</style>
