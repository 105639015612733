<template>
    <transition
        name="fade"
        @after-enter="showInteraction = true"
    >
        <div
            v-if="selectedForInteraction"
            class="overlay"
        >
            <div class="background"></div>
            <div
                id="backgroundClickTarget"
                class="content-container center-container"
                @click="onBackgroundClick"
            >
                <social-notification-interaction-card
                    :notification="selectedForInteraction"
                    :show.sync="showInteraction"
                />
                <component
                    :is="interactionComponent"
                    :show.sync="showInteraction"
                    @after-interaction="afterInteraction"
                />
            </div>
        </div>
    </transition>
</template>

<script>
import { SOCIAL_NOTIFICATION_MODULE_NAME } from "@web/store/social-notifications/social-notifications";
import { GET_SELECTED_FOR_INTERACTION } from "@web/store/social-notifications/getters";
import {
    mapGetters,
    mapMutations,
    mapActions
} from "vuex";
import { SET_SELECTED_FOR_INTERACTION } from "@web/store/social-notifications/mutations";
import { SOCIAL_NOTIFICATION_INTERACTION_MAP } from "@web/components/social-notifications/config";
import SocialNotificationInteractionCard from "@web/components/social-notifications/SocialNotificationInteractionCard";
import { MARK_AS_READ } from "@web/store/social-notifications/actions";
import { analytics } from "@web/analytics";
import { getGlobalConfiguration } from "@web/global-config";

export default {
    name: "SocialNotificationInteractionPopup",
    components: { SocialNotificationInteractionCard },
    data() {
        return {
            showInteraction: false,
        };
    },
    computed: {
        ...mapGetters({
            selectedForInteraction: SOCIAL_NOTIFICATION_MODULE_NAME + GET_SELECTED_FOR_INTERACTION,
        }),
        interactionComponent() {
            const type = this.selectedForInteraction.activity.meta.socialNotificationType;
            const component = SOCIAL_NOTIFICATION_INTERACTION_MAP[type];
            if (!component) {
                throw new Error(`Interacting with Social Notification Type ${type} is not supported`);
            }
            return component;
        },
    },
    methods: {
        ...mapMutations({
            setSelectedForInteraction: SOCIAL_NOTIFICATION_MODULE_NAME + SET_SELECTED_FOR_INTERACTION,
        }),
        ...mapActions({
            markAsRead: SOCIAL_NOTIFICATION_MODULE_NAME + MARK_AS_READ,
        }),
        onBackgroundClick(event) {
            if (event.target.id === "backgroundClickTarget") {
                this.showInteraction = false;
            }
        },
        async afterInteraction({ interactionCompleted }) {
            if (interactionCompleted) {
                await this.markAsRead(this.selectedForInteraction.id);
                analytics.log(getGlobalConfiguration().analytics_event_name_social_notification_interaction_completed, {
                    socialNotificationType: this.selectedForInteraction.activity.meta.socialNotificationType,
                });
            }
            this.setSelectedForInteraction(null);
        },
    },
};
</script>

<style lang="scss" scoped>
.overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9999;
    backdrop-filter: blur(10px);

    .background, .content-container {
        position: absolute;
        width: 100%;
        height: 100%;
        flex-direction: column;
    }

    .background {
        background: black;
        opacity: .5;
    }
}
</style>
