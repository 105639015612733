import {
    Commentable,
    EntityWithAttachments,
    EverestContentEntity,
    Publishable,
    Reactable,
} from "@backend/entity/types";
import { Operation } from "@backend/entity/delta/types";
import { ViewTrackable } from "@backend/view-tracking/types";

export enum EverestCommentInFireStoreFieldKeys {
    isDeleted = "isDeleted",
}

export interface EverestCommentInFireStore
    extends EverestContentEntity, Commentable, Publishable, Reactable, EntityWithAttachments {
    [EverestCommentInFireStoreFieldKeys.isDeleted]: boolean;
}

export interface EverestComment extends EverestCommentInFireStore, ViewTrackable {
    uid: string;
    comments?: EverestComment[];
    content: Operation[];
    _path: string;
    _commentableEntityType: CommentableEntityType;
}

export enum CommentableEntityType {
    news = "news",
    post = "post",
}
