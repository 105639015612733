import { ApiClient } from "@web/api/ApiClient";
import { ApiClientBuilder } from "@web/api/ApiClientBuilder";
import { cloudRunUrl } from "@web/cloud-run-url";
import {
    NotificationSettingState,
    NotificationType,
    NotificationUserSettings,
} from "@backend/notification-settings/types";
import { ActivityType } from "@backend/common/notification/activity-types";
import { AxiosResponse } from "axios";

interface UpdateParams {
    intranetUid: string,
    userUid: string,
    activity: ActivityType,
    type: NotificationType,
    state: NotificationSettingState,
}

export class NotificationSettingsService {
    public static readonly notificationSettingsEndpoint = `${cloudRunUrl.notificationSettings}/api/notification-settings`;

    private readonly api: Promise<ApiClient>;

    constructor() {
        this.api = new ApiClientBuilder().build();
    }

    public async fetchSettings({
        intranetUid,
        userUid,
    }: { intranetUid: string, userUid: string }): Promise<AxiosResponse<NotificationUserSettings>> {
        const client = await this.api;

        return client.get<NotificationUserSettings>(`${(NotificationSettingsService.notificationSettingsEndpoint)}/${intranetUid}/${userUid}`);
    }

    public async updateSetting({
        intranetUid,
        userUid,
        activity,
        type,
        state,
    }: UpdateParams): Promise<AxiosResponse<void>> {
        const client = await this.api;
        return client.post<void>(`${(NotificationSettingsService.notificationSettingsEndpoint)}/${intranetUid}/${userUid}`, {
            activity,
            type,
            state,
        });
    }
}
