import {
    AUTH_MODULE,
    AuthModuleState,
} from "@web/store/auth/auth";
import {
    INTRANET_MODULE,
    IntranetModuleState,
} from "@web/store/intranet/intranet";
import {
    INVITE_MODULE,
    InviteModuleState,
} from "@web/store/invite/invite";
import Vue from "vue";
import Vuex from "vuex";
import {
    THEMING_MODULE,
    ThemingModuleState,
} from "@web/store/theming/theming";
import { PEOPLE_MODULE } from "@web/store/people/people";
import {
    NEWS_MODULE,
    NewsModuleState,
} from "@web/store/news/news";
import {
    TIMESTAMP_MODULE,
    TimestampingModuleState,
} from "@web/store/timestamping/timestamping";
import {
    ATLASSIAN_MODULE,
    AtlassianModuleState,
} from "@web/store/atlassian/atlassian";
import {
    GOOGLE_CALENDAR_MODULE,
    GoogleCalendarModuleState,
} from "@web/store/google/google-calendar";
import {
    MAINTENANCE_MODULE,
    MaintenanceModuleState,
} from "@web/store/maintenance/maintenance";
import {
    PROFILE_MODULE,
    ProfileModuleState,
} from "@web/store/profile/profile";
import {
    POST_MODULE,
    PostsInTopicModuleState,
} from "@web/store/post/post";
import {
    EventModuleState,
    EVENTS_MODULE,
} from "@web/store/event/event";
import {
    LAUNCHPAD_MODULE,
    LaunchpadModuleState,
} from "@web/store/launchpad/launchpad";
import {
    REACTION_MODULE,
    ReactionModuleState,
} from "@web/store/reaction/reaction";
import {
    EDITOR_MODULE,
    EditorModuleState,
} from "@web/store/editor/editor";
import {
    STORY_MODULE,
    StoryModuleState,
} from "@web/store/story/story";
import {
    TEAM_MODULE,
    TeamModuleState,
} from "@web/store/team/team";
import {
    SOCIAL_NOTIFICATION_MODULE,
    SocialNotificationModuleState,
} from "@web/store/social-notifications/social-notifications";
import {
    TOPIC_SUBSCRIPTION_MODULE,
    TopicSubscriptionModuleState,
} from "@web/store/topic-subscription/topic-subscription";
import {
    LIVE_NEW_CONTENT_MODULE,
    LIVE_NEW_CONTENT_MODULE_ID,
    LiveNewContentModuleState,
} from "@web/store/live-new-content/live-new-content";

Vue.use(Vuex);

const auth = AUTH_MODULE;
const profile = PROFILE_MODULE;
const intranet = INTRANET_MODULE;
const invite = INVITE_MODULE;
const theming = THEMING_MODULE;
const people = PEOPLE_MODULE;
const news = NEWS_MODULE;
const timestamp = TIMESTAMP_MODULE;
const atlassian = ATLASSIAN_MODULE;
const googleCalendar = GOOGLE_CALENDAR_MODULE;
const maintenance = MAINTENANCE_MODULE;
const post = POST_MODULE;
const event = EVENTS_MODULE;
const launchpad = LAUNCHPAD_MODULE;
const reaction = REACTION_MODULE;
const editor = EDITOR_MODULE;
const story = STORY_MODULE;
const team = TEAM_MODULE;
const socialNotification = SOCIAL_NOTIFICATION_MODULE;
const topicSubscription = TOPIC_SUBSCRIPTION_MODULE;

export default new Vuex.Store<VuexRootState>({
    strict: process.env.NODE_ENV === "development",
    modules: {
        auth,
        profile,
        intranet,
        invite,
        theming,
        people,
        news,
        timestamp,
        atlassian,
        googleCalendar,
        maintenance,
        post,
        event,
        launchpad,
        reaction,
        editor,
        story,
        team,
        socialNotification,
        topicSubscription,
        [LIVE_NEW_CONTENT_MODULE_ID]: LIVE_NEW_CONTENT_MODULE,
    },
    state: {} as VuexRootState,
    getters: {},
    mutations: {},
    actions: {},
});

export interface VuexRootState {
    auth: AuthModuleState;
    profile: ProfileModuleState;
    intranet: IntranetModuleState;
    invite: InviteModuleState;
    theming: ThemingModuleState;
    news: NewsModuleState;
    timestamp: TimestampingModuleState;
    atlassian: AtlassianModuleState;
    googleCalendar: GoogleCalendarModuleState;
    maintenance: MaintenanceModuleState;
    post: PostsInTopicModuleState;
    event: EventModuleState;
    launchpad: LaunchpadModuleState;
    reaction: ReactionModuleState;
    editor: EditorModuleState;
    story: StoryModuleState,
    team: TeamModuleState,
    socialNotification: SocialNotificationModuleState,
    topicSubscription: TopicSubscriptionModuleState,
    [LIVE_NEW_CONTENT_MODULE_ID]: LiveNewContentModuleState,
}
