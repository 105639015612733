<template>
    <user-loading-component :user-uid="userUid">
        <template #default="{userLoading, user}">
            <pu-skeleton
                :loading="userLoading"
                height="1rem"
                width="10rem"
            >
                <template v-if="user">
                    <user-link
                        v-if="linked && user.uid"
                        class="hover-underline"
                        v-bind="user"
                    >
                        {{ user.displayName }}
                    </user-link>
                    <template v-else>
                        <span>{{ user.displayName }}</span>
                    </template>
                </template>
            </pu-skeleton>
        </template>
    </user-loading-component>
</template>

<script>
import UserLoadingComponent from "@web/components/user/UserLoadingComponent";
import UserLink from "@web/components/user/UserLink";

/**
 * A dynamically loaded user display name with an optional linking to the user profile.
 */
export default {
    name: "LoadingContentAuthor",
    components: {
        UserLink,
        UserLoadingComponent,
    },
    props: {
        userUid: String,
        linked: Boolean,
    },
};
</script>

<style lang="scss" scoped>
</style>
