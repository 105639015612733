import { DirectiveOptions } from "vue/types/options";

/**
 * Adjusts an input's width to its value.
 * This is not available by usual means.
 */
function autoWidthInput(this: HTMLElement): void {
    this.style.boxSizing = "content-box";
    this.style.width = "1px";
    const scrollWidth = this.scrollWidth;
    if (scrollWidth > 0) {
        this.style.width = scrollWidth + "px";
    }
}

const inputAutoWidth: DirectiveOptions = {
    bind(el) {
        el.addEventListener("input", autoWidthInput);
    },
    inserted(el) {
        autoWidthInput.apply(el);
    },
    unbind(el) {
        el.removeEventListener("input", autoWidthInput);
    },
};

export default inputAutoWidth;
