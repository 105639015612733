<template>
    <div
        v-if="!!$slots.default"
        class="deletable"
        :class="{editMode: editMode}"
        @mouseover="hovered = true"
        @mouseleave="hovered = false"
    >
        <slot></slot>
        <template v-if="editMode">
            <div class="delete-button-container">
                <transition :name="transitionName">
                    <Icon
                        v-if="hovered"
                        v-tooltip.right="$t('remove')"
                        name="x"
                        class="remove-icon depth-1"
                        @click.native="$emit('delete')"
                    />
                </transition>
            </div>
        </template>
    </div>
</template>

<script>
import Icon from "@web/components/Icon";

export default {
    name: "DeletableWrapper",
    components: {
        Icon,
    },
    props: {
        /** enables the delete icon */
        editMode: { type: Boolean, default: false },
        /** transition which is used to render the delete button */
        transitionName: { type: String, default: "fade" },
    },
    data() {
        return {
            hovered: false,
        };
    }
};
</script>

<style lang="scss" scoped>
.moveInFromRight-enter,
.moveInFromRight-leave-to {
    transform: translateX(200%);
}

.deletable {
    position: relative;
}

.delete-button-container {
    overflow: hidden;
    position: absolute;
    top: 0;
    right: 0;
}

.remove-icon {
    margin-top: .5rem;
    margin-right: .5rem;
    display: flex;
    cursor: pointer;
    border-radius: 50%;
    background-color: var(--background);
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
}
</style>

<docs>
### Test
```vue
<DeletableWrapper edit-mode>
    <SystemMessage type="success"/>
</DeletableWrapper>
```
</docs>
