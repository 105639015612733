<template>
    <div class="macro-wrapper">
        <div
            v-if="loaded"
            id="confluence-post-time-line-wrapper"
            class="triplet-grid"
        >
            <PostTimeLine/>
            <ContentUpdateObserver/>
        </div>
        <MacroError
            v-if="hasError"
            :is-user-logged-in="isUserLoggedIn"
            :is-permission-denied-for-intranet="isPermissionDeniedForIntranet"
            i18n-feature-name="posts"
        >
            <div class="card padding-small mbottom-medium">
                <div class="avatar avatar-medium"></div>
            </div>
            <div class="card padding-small mbottom-medium">
                <div class="mock-post-header">
                    <div class="avatar avatar-medium"></div>
                    <h4>This is a post that does not exist</h4>
                </div>
                <!-- non-sense text which is shown blurred when timeline can not get loaded -->
                <p>Fatback jowl hamburger, chuck flank ground round jerky tri-tip drumstick biltong andouille beef. </p>
                <p>
                    Pork loin picanha jerky, pork tenderloin tri-tip meatball. Strip steak shank ribeye brisket ball tip
                    bresaola doner bacon jerky pastrami chicken cupim landjaeger short ribs beef. Chuck short loin jowl
                    bacon doner picanha chislic hamburger. Pork chop chicken salami ground round.
                </p>
                <p>
                    Drumstick pork loin meatloaf turkey boudin salami tri-tip t-bone pork. Jerky tri-tip buffalo salami
                    landjaeger frankfurter tail. Pork loin brisket meatloaf chuck tenderloin, meatball pork picanha
                    strip steak spare ribs leberkas doner venison pork chop corned beef. Swine landjaeger strip steak
                    ribeye porchetta.
                </p>
            </div>
            <div class="card padding-small mbottom-medium">
                <div class="mock-post-header">
                    <div class="avatar avatar-medium"></div>
                    <h4>This is a post that does not exist</h4>
                </div>
                <p>
                    Pork loin picanha jerky, pork tenderloin tri-tip meatball. Strip steak shank ribeye brisket ball tip
                    bresaola doner bacon jerky pastrami chicken cupim landjaeger short ribs beef. Chuck short loin jowl
                    bacon doner picanha chislic hamburger. Pork chop chicken salami ground round.
                </p>
                <p>Fatback jowl hamburger, chuck flank ground round jerky tri-tip drumstick biltong andouille beef. </p>
            </div>
        </MacroError>
    </div>
</template>

<script>
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import Vue from "vue";
import {
    mapActions,
    mapState,
} from "vuex";
import { INITIALIZE_INTRANET } from "@web/store/intranet/actions";
import ConfluenceMacroMixin from "@web/views/atlassian-cloud/confluence/macro/ConfluenceMacroMixin.vue";
import PostTimeLine from "@web/views/posts/PostTimeLine";
import MacroError from "@web/views/atlassian-cloud/confluence/macro/MacroError";
import debounce from "lodash/debounce";
import { resizingService } from "@web/services";
import ContentUpdateObserver from "@/components/renderless/ContentUpdateObserver";

export default Vue.extend({
    name: "PostTimeLineMacro",
    components: {
        PostTimeLine,
        MacroError,
        ContentUpdateObserver,
    },
    mixins: [
        ConfluenceMacroMixin,
    ],
    computed: {
        ...mapState(INTRANET_MODULE_NAME, ["intranet"]),
    },
    methods: {
        ...mapActions({ setup: INTRANET_MODULE_NAME + INITIALIZE_INTRANET }),
        handleError(e) {
            if (e.response.status === 403) {
                this.isPermissionDeniedForIntranet = true;
                return;
            }
            this.isUnknownError = true;
        },
        async handleSuccess() {
            this.styleInject();
            this.loaded = true;
        },
    },
    data() {
        return {
            loaded: false,
            isPermissionDeniedForIntranet: false,
        };
    },
    async created() {
        this.appendAtlassianScriptToHead(async() => {
            this.isUserLoggedIn = this.isUserLoggedInIntoFirebase();

            if (!this.isUserLoggedIn) {
                resizingService.resize({
                    integration: this.$route.query.integration,
                    integrationUuid: this.$route.query.integrationUuid,
                    height: "800px",
                });
                return;
            }
            await this.setup(this.intranetUid)
                .then(this.handleSuccess, this.handleError)
                .finally(() => {
                    // Atlassian makes it hard for macros which have a dynamic and potentially endless height with animations
                    // that is why we continuously report our needed size to atlassian
                    const onResize = entries => {
                        for (const entry of entries) {
                            const height = Math.ceil(entry.contentRect.height) + 70;
                            resizingService.resize({
                                integration: this.$route.query.integration,
                                integrationUuid: this.$route.query.integrationUuid,
                                width: "100%",
                                height: height + "px",
                            });
                            setTimeout(() => {
                                const appWrapper = document.querySelector(".app-wrapper");
                                if (appWrapper) {
                                    appWrapper.style.maxHeight = height + "px";
                                    appWrapper.style.overflow = "clip";
                                }
                            }, 200);
                        }
                    };
                    const debouncedResize = debounce(onResize, 250);
                    const resizeObserver = new ResizeObserver(entries => debouncedResize(entries));
                    resizeObserver.observe(document.getElementById("confluence-post-time-line-wrapper"));
                });
        }, false);
    },
});
</script>
<style lang="scss">
body {
    padding: 0 !important;
    box-sizing: border-box;

    .app-wrapper {
        transition: max-height 0.5s ease-in-out !important;
    }
}
</style>
<style lang="scss" scoped>
.card {
    color: var(--medium-contrast, #{$mid-grey});
}

.mock-post-header {
    display: flex;
    align-items: center;

    .avatar {
        flex: 0 0 auto;
        margin-right: 1rem;
    }

    h4 {
        margin: 0;
    }
}
</style>
