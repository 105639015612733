<template>
    <div
        v-if="intranet && showUpgradeSubscriptionHint && !loading"
        class="upgrade-subscription-hint-header mbottom-small"
        :class="upgradeSubscriptionHintColorClass"
    >
        {{ hintText }}&nbsp;<b>{{ `${subscriptionDaysLeft || 0} ${$tc("day", subscriptionDaysLeft || 0)} (${expirationDatePrettyString}) ` }}</b>
        <everest-button
            href="mailto:support@linchpin-hey.com"
            open-in-new-tab
            :type="`inverted ${upgradeSubscriptionHintColorClass}`"
        >
            {{ $t("contact_us") }}
        </everest-button>
        <router-link :to="{name: 'workspace'}">
            <everest-button type="borderless primary">
                {{ $t("subscription_upgrade_button_text") }}
            </everest-button>
        </router-link>
        <icon
            v-if="subscriptionDaysLeft !== undefined && subscriptionDaysLeft > 7"
            class="icon cursor-pointer"
            :color="`var(--${upgradeSubscriptionHintColorClass}-text)`"
            name="x"
            @click.native="skipUpgradeSubscriptionHint"
        />
    </div>
</template>

<script>
import EverestButton from "@/components/Button";
import { mapGetters } from "vuex";
import { INTRANET_MODULE_NAME } from "@/store/intranet/intranet";
import {
    INTRANET,
    IS_ADMIN,
    SUBSCRIPTION_DAYS_LEFT,
    SUBSCRIPTION_EXPIRATION_DATE,
    SUBSCRIPTION_STATUS,
} from "@/store/intranet/getters";
import {
    dayjsFromDate,
    formatDate,
    toDayJs,
} from "@/lib/time-utils";
import { productSubscriptionService } from "@web/services";
import { ProductSubscriptionStatus } from "@backend/product-subscription/types";

export default {
    name: "UpgradeSubscriptionHint",
    components: { EverestButton },
    props: {
        loading: { type: Boolean },
    },
    data() {
        return {
            hintSkipped: false,
        };
    },
    computed: {
        ...mapGetters({
            intranet: INTRANET_MODULE_NAME + INTRANET,
            expirationDate: INTRANET_MODULE_NAME + SUBSCRIPTION_EXPIRATION_DATE,
            subscriptionDaysLeft: INTRANET_MODULE_NAME + SUBSCRIPTION_DAYS_LEFT,
            isAdmin: INTRANET_MODULE_NAME + IS_ADMIN,
            subscriptionStatus: INTRANET_MODULE_NAME + SUBSCRIPTION_STATUS,
        }),
        hintText() {
            if (this.subscriptionStatus === ProductSubscriptionStatus.trialing) {
                return this.$t("subscription_trial_welcome_text");
            } else {
                return this.$t("upgrade_subscription_hint_text");
            }
        },
        upgradeSubscriptionHintColorClass() {
            if (this.subscriptionDaysLeft === undefined || this.subscriptionDaysLeft <= 7) {
                return "warning";
            } else if (this.subscriptionDaysLeft <= 14) {
                return "info";
            }
            return "green";
        },
        expirationDatePrettyString() {
            return formatDate(this.intranet.subscription.expirationDate);
        },
        showUpgradeSubscriptionHint() {
            if (this.subscriptionStatus === ProductSubscriptionStatus.active || !this.isAdmin || this.subscriptionDaysLeft === undefined) {
                return false;
            }
            if (this.expirationDate && !this.hintSkipped) {
                return this.subscriptionDaysLeft <= 30;
            }
            return false;
        },
    },
    async mounted() {
        if (!this.isAdmin || this.subscriptionDaysLeft === undefined) {
            return;
        }
        try {
            const skipTimestamp = await productSubscriptionService.getSkipTimestamp(this.intranet.uid);
            if (!skipTimestamp) return;
            const dayJSSkipTimestamp = toDayJs(skipTimestamp);
            const dayJSExpirationDate = dayjsFromDate(this.expirationDate);
            const daysBetweenSkipAndExpiration = dayJSExpirationDate.diff(dayJSSkipTimestamp, "days");
            this.hintSkipped = (daysBetweenSkipAndExpiration <= 30 && this.subscriptionDaysLeft <= 14) ||
                (daysBetweenSkipAndExpiration <= 14 && this.subscriptionDaysLeft <= 7);
        } catch (e) {
            this.$notify({
                group: "app",
                type: "error",
                title: this.$t("upgrade_subscription_hint_error_title"),
                text: this.$t("upgrade_subscription_hint_error_text"),
            });
        }
        this.$emit("show-upgrade-subscription-hint", this.showUpgradeSubscriptionHint);
    },
    methods: {
        skipUpgradeSubscriptionHint() {
            this.hintSkipped = true;
            try {
                productSubscriptionService.setSkipTimestamp(this.intranet.uid);
            } catch (e) {
                this.$notify({
                    group: "app",
                    type: "error",
                    title: this.$t("upgrade_subscription_hint_error_title"),
                    text: this.$t("upgrade_subscription_hint_error_text"),
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.upgrade-subscription-hint-header {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 0.3rem 0;

    > .button, > a {
        margin-left: 1rem;
    }

    .icon {
        position: absolute;
        right: 1rem;
    }

    &.info {
        background: var(--info);
        color: var(--info-text);
    }

    &.warning {
        background: var(--warning);
        color: var(--warning-text);
    }

    &.green {
        background: #00cc76;
        color: white;
    }
}
</style>
