import { Hit } from "@algolia/client-search";
import { CommentIndexEntity } from "@backend/comment/comment-index-types";
import { EventIndexEntity } from "@backend/event/event-index-types";
import { NewsIndexEntity } from "@backend/news/news-index-types";
import { PostIndexEntity } from "@backend/post/post-index-types";
import {
    formatDate,
    timeForRange,
} from "@web/lib/time-utils";
import {
    Location,
    RawLocation,
} from "vue-router";
import { ContentSearchIndexEntity } from "@backend/content-search/entity-type";
import { i18n } from "@web/i18n";
import { UserContentIndexEntity } from "@backend/user-profile/content-search/index-types";

abstract class ResultAccessor<INDEXTYPE, INDEXMETATYPE> {
    constructor(
        protected readonly result: Hit<ContentSearchIndexEntity<INDEXTYPE, INDEXMETATYPE>>,
    ) {
    }

    get cssClasses(): string | undefined {
        return undefined;
    }

    get contextLine(): string | undefined {
        return undefined;
    }

    get metaLine(): string | undefined {
        return formatDate(this.result.creationDate);
    }

    get noteLine(): string | undefined {
        return undefined;
    }

    get showAuthor(): boolean {
        return false;
    }

    get showContent(): boolean {
        return true;
    }

    get icon(): string | undefined {
        return "arrow-right";
    }

    get route(): RawLocation {
        return "#";
    }
}

class UnknownResultAccessor<INDEXTYPE, INDEXMETATYPE> extends ResultAccessor<INDEXTYPE, INDEXMETATYPE> {
}

abstract class CommentResultAccessor extends ResultAccessor<CommentIndexEntity["contentEntityType"], CommentIndexEntity["context"]> {
    get contextLine(): string | undefined {
        return this.result.context?.rootTitle;
    }
}

class PostResultAccessor extends ResultAccessor<PostIndexEntity["contentEntityType"], PostIndexEntity["context"]> {
    get showAuthor(): boolean {
        return true;
    }

    get icon(): string {
        return "message-square";
    }

    get route(): Location {
        return { name: "post-route", params: { postUid: this.result.uid } };
    }
}

class PostCommentResultAccessor extends CommentResultAccessor {
    get showAuthor(): boolean {
        return true;
    }

    get icon(): string {
        return "comment";
    }

    get route(): Location {
        return { name: "post-route", params: { postUid: this.result.context!.rootUid, commentPath: this.result.context!.commentPath } };
    }
}

class NewsResultAccessor extends ResultAccessor<NewsIndexEntity["contentEntityType"], NewsIndexEntity["context"]> {
    get showAuthor(): boolean {
        return true;
    }

    get icon(): string {
        return "newspaper";
    }

    get route(): Location {
        return { name: "news-route", params: { newsUid: this.result.uid } };
    }
}

class NewsCommentResultAccessor extends CommentResultAccessor {
    get icon(): string {
        return "news-comment";
    }

    get route(): Location {
        return { name: "news-route", params: { newsUid: this.result.context!.rootUid, commentPath: this.result.context!.commentPath } };
    }
}

class EventResultAccessor extends ResultAccessor<EventIndexEntity["contentEntityType"], EventIndexEntity["context"]> {
    get cssClasses(): string | undefined {
        const endTimestamp = this.result.context?.endTimestamp ?? 0;
        if (endTimestamp < Date.now()) {
            return "text-high-contrast";
        }
        return undefined;
    }

    get metaLine(): string | undefined {
        return undefined;
    }

    get noteLine(): string | undefined {
        if (this.result.context?.startTimestamp) {
            return i18n.t("events_time_range", [
                formatDate(this.result.context.startTimestamp, "DD.MM.YYYY"),
                timeForRange(this.result.context.startTimestamp, this.result.context.endTimestamp),
            ]) as string;
        }
        return undefined;
    }

    get icon(): string {
        return "event";
    }

    get route(): Location {
        return { name: "event-modal-route", params: { eventUid: this.result.uid } };
    }
}

class UserResultAccessor extends ResultAccessor<UserContentIndexEntity["contentEntityType"], UserContentIndexEntity["context"]> {
    get metaLine(): string | undefined {
        return this.result.context?.team;
    }

    get noteLine(): string | undefined {
        return this.result.context?.jobTitle;
    }

    get showContent(): boolean {
        return false;
    }

    get icon(): string | undefined {
        return undefined;
    }

    get route(): Location {
        return { name: "user-profile", params: { userUid: this.result.uid } };
    }
}

type ContentSearchResult = Hit<EventIndexEntity> | Hit<NewsIndexEntity> | Hit<PostIndexEntity> | Hit<CommentIndexEntity> | Hit<UserContentIndexEntity>;

export function resultAccessor(result: Hit<ContentSearchResult>): ResultAccessor<ContentSearchResult["contentEntityType"], ContentSearchResult["context"]> {
    if (result.contentEntityType === "post") {
        return new PostResultAccessor(result);
    } else if (result.contentEntityType === "comment" && result.context?.rootType === "post") {
        return new PostCommentResultAccessor(result);
    } else if (result.contentEntityType === "news") {
        return new NewsResultAccessor(result);
    } else if (result.contentEntityType === "comment" && result.context?.rootType === "news") {
        return new NewsCommentResultAccessor(result);
    } else if (result.contentEntityType === "event") {
        return new EventResultAccessor(result);
    } else if (result.contentEntityType === "user") {
        return new UserResultAccessor(result);
    } else {
        return new UnknownResultAccessor(result);
    }
}

export function textHighlighter({ highlightPreTag, highlightPostTag }: { highlightPreTag: string, highlightPostTag: string }): (result: ContentSearchResult) => string | undefined {
    return function highlightedText(result: ContentSearchResult): string | undefined {
        return result._snippetResult?.content.value
            .replace("<", "&lt;")
            .replace(">", "&gt;")
            .replace("&", "&amp;")
            // makes results too high
            // .replace(/\n/g, "<br/>")
            .replaceAll(highlightPreTag, "<strong>")
            .replaceAll(highlightPostTag, "</strong>");
    };
}
