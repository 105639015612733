import { AbstractSearchService } from "@web/services/AbstractSearchService";
import { CONTENT_SEARCH_INDEX_NAME } from "@backend/index/types";
import { cloudRunUrl } from "@web/cloud-run-url";
import { PostIndexEntity } from "@backend/post/post-index-types";
import { CommentIndexEntity } from "@backend/comment/comment-index-types";
import { NewsIndexEntity } from "@backend/news/news-index-types";
import { EventIndexEntity } from "@backend/event/event-index-types";

const contentSearchTokenEndpoint = `${cloudRunUrl.thirdPartySearch}/api/search/content-search-token/`;

export class ContentSearchService extends AbstractSearchService<PostIndexEntity | CommentIndexEntity | NewsIndexEntity | EventIndexEntity> {
    constructor() {
        super(CONTENT_SEARCH_INDEX_NAME, contentSearchTokenEndpoint);
    }
}
