<template>
    <ReactionContainer
        v-slot="{changedTotalReactions, onReact, openReactionsModal}"
        class="footer"
        :content-type="contentType"
        :root-entity-uid="contentUid"
        :entity-uid="commentUid || contentUid"
        :path="path"
        :selected-reaction-type="selectedReactionType"
        :total-reactions="totalReactions"
    >
        <ReactionButton
            :init-selection="selectedReactionType"
            @react="onReact"
            @unreact="onReact(null)"
        />
        <div
            v-if="!hideReply"
            class="reply-button"
            @click="$emit('reply')"
        >
            <Icon
                name="message-square"
            />
            <span class="title">{{ $t("comment") }}</span>
        </div>

        <div class="comment-counter">
            <span v-if="commentCount > 0">{{ commentCount }} {{ commentCount > 1 ? $t("comments") : $t("comment") }}</span>
        </div>

        <ReactionGraph
            :reactions="changedTotalReactions"
            @open="openReactionsModal"
        />
    </ReactionContainer>
</template>

<script>
import ReactionButton from "@web/components/reaction/ReactionButton";
import ReactionGraph from "@web/components/reaction/ReactionGraph";
import ReactionContainer from "@/views/story/ReactionContainer";

export default {
    name: "CommentableContentFooter",
    components: { ReactionContainer, ReactionButton, ReactionGraph },
    props: {
        contentType: { type: String, required: true },
        contentUid: { type: String, required: true },
        commentUid: { type: String, default: null },
        path: { type: String, default: "" },
        totalReactions: { type: Object, default: null },
        selectedReactionType: { type: String, default: null },
        commentCount: { type: Number, default: 0 },
        hideReply: { type: Boolean, default: false },
    },
};
</script>

<style lang="scss" scoped>
.footer {
    display: flex;
    position: relative;
    align-items: center;

    .reply-button {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-left: 1rem;
        user-select: none;

        .icon {
            --icon-size: 22px;
            --icon-color: var(--highest-contrast, #{$dark-grey});
        }

        .title {
            padding-left: .3rem;
            font-weight: 600;
            font-size: .7rem;
        }
    }

    .comment-counter {
        margin-left: auto;
        font-size: 0.7rem;
        color: var(--medium-contrast, #{$mid-grey});
    }

    .reaction-graph {
        margin-left: 1.8rem;
    }
}
</style>
