import {
    KnownInsertsQuill,
    Operation
} from "@backend/entity/delta/types";
import { MentionEntityType } from "@backend/mention/mention-types";
import { analytics } from "@web/analytics";
import { getGlobalConfiguration } from "@web/global-config";

export const countMentions = (content: Operation[]) => {
    return content.filter((o) => typeof o.insert === "object" && (o.insert as KnownInsertsQuill).mention !== undefined).length;
};

export const mentionTracking = {
    trackMentions(content: Operation[], entityType: MentionEntityType) {
        const mentionCount = countMentions(content);
        analytics.log(getGlobalConfiguration().analytics_event_name_mention_on_entity, {
            entityType,
            mentionCount
        });
    }
};
