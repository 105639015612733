<template>
    <div class="youtube-wrapper">
        <iframe
            class="youtube-embed"
            :src="`https://www.youtube-nocookie.com/embed/${videoID}`"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
        >
        </iframe>
    </div>
</template>

<script>
export default {
    name: "YoutubeEmbed",
    inheritAttrs: false,
    props: {
        /** @see VideoContentData */
        videoID: { type: String, required: true },
    },
};
</script>

<style lang="scss" scoped>
// TODO HALO-3589: replace this ugly mess with CSS property aspect-ratio when Safari supports it
/**
.youtube-wrapper {
    width: 100%;
    .youtube-embed {
        width: 100%;
        aspect-ratio: 16 / 9;
    }
}
 */
// https://www.h3xed.com/web-development/how-to-make-a-responsive-100-width-youtube-iframe-embed
.youtube-wrapper {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    .youtube-embed {
        border-radius: var(--border-radius);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
</style>
