<template>
    <div
        v-if="isFeaturePreviewActive"
        class="sticky-info"
    >
        <slot ></slot>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { FEATURE_PREVIEW_ACTIVE } from "@web/store/intranet/getters";

export default {
    computed: {
        ...mapGetters({ isFeaturePreviewActive: "intranet/" + FEATURE_PREVIEW_ACTIVE }),
    }
};
</script>

<style lang="scss" scoped>
.sticky-info {
    line-height: 1.2;
    padding:  2rem 1rem;
    margin: 2rem auto;
    background: rgb(255,245,168);
    box-shadow: -1px 2px 1px rgba(var(--shadows, $shadows), 0.05);
    transform: rotate(-2deg);
    color: $black;
    position: relative;
        text-align: center;
        max-width: 400px;

}

.sticky-info::before {
    content: '';
    width: 40px;
    height: 14px;
    background: #50463c;
    position:absolute;
    top:0;
    left:-10px;
    transform: rotate(-30deg)

}

.sticky-info::after {
    content: '';
    width: 40px;
    height: 14px;
    background: #50463c;
    position:absolute;
    top:0;
    right:-10px;
    transform: rotate(40deg)

}
</style>
