import { render, staticRenderFns } from "./Spinner.vue?vue&type=template&id=50ccb8bb&scoped=true&"
import script from "./Spinner.vue?vue&type=script&lang=ts&"
export * from "./Spinner.vue?vue&type=script&lang=ts&"
import style0 from "./Spinner.vue?vue&type=style&index=0&id=50ccb8bb&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50ccb8bb",
  null
  
)

/* custom blocks */
import block0 from "./Spinner.vue?vue&type=custom&index=0&blockType=docs"
if (typeof block0 === 'function') block0(component)

export default component.exports