import Vue from "vue";
import Router, {
    Route
} from "vue-router";
import Intranet from "@web/views/Intranet.vue";
import Events from "@web/views/events/Events.vue";
import EventModalRoute from "@web/views/events/EventModalRoute.vue";
import Portal from "@web/views/Portal.vue";
import AtlassianCloudWrapper from "@web/views/atlassian-cloud/AtlassianCloudWrapper.vue";
import { analytics } from "@web/analytics";
import { getGlobalConfiguration } from "@web/global-config";
import AuthWrapper from "@web/views/atlassian-cloud/confluence/macro/AuthWrapper.vue";
import NewsList from "@web/views/atlassian-cloud/confluence/macro/NewsList.vue";
import NewsMagazine from "@web/views/atlassian-cloud/confluence/macro/NewsMagazine.vue";
import PostTimeLineMacro from "@web/views/atlassian-cloud/confluence/macro/PostTimeLineMacro.vue";
import NewsCreate from "@web/views/atlassian-cloud/confluence/macro/NewsCreate.vue";
import ConfluenceDashboardNews from "@web/views/atlassian-cloud/confluence/macro/ConfluenceDashboardNews.vue";
import NewsCreateMacro from "@web/views/atlassian-cloud/confluence/macro/NewsCreateMacro.vue";
import { IntranetAccessController } from "@web/views/portal/intranet-access";
import { hubspotService } from "@web/services";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import {
    INTRANET,
    SUBSCRIPTION_DAYS_LEFT
} from "@web/store/intranet/getters";
import { wait } from "@web/lib/wait";

Vue.use(Router);

interface EverestRouterData {
    isOpenedFromDirectLink?: { triggered: boolean, pathName: string };
}

const router: Router & EverestRouterData = new Router({
    mode: "history",
    scrollBehavior(to, from, savedPosition) {
        return savedPosition || { x: 0, y: 0 };
    },
    routes: [
        {
            path: "/intranet/:intranetId",
            name: "intranet",
            components: {
                default: Intranet,
                // @ts-ignore
                modal: false,
            },
            props: {
                default: true,
            },
            children: [
                {
                    path: "",
                    redirect: "dashboard",
                },
                {
                    path: "dashboard",
                    name: "dashboard",
                    meta: {
                        analyticsEventKey: "analytics_event_name_dashboard_opened",
                        titleI18nKey: "dashboard",
                    },
                    component: () => import(/* webpackChunkName: "dashboard" */ "./views/intranet/Dashboard.vue"),
                },
                {
                    path: "directory",
                    name: "directory",
                    component: () => import(/* webpackChunkName: "about" */ "./views/intranet/Directory.vue"),
                    children: [
                        {
                            path: "",
                            redirect: { name: "people" },
                        },
                        {
                            path: "network",
                            name: "network",
                            meta: {
                                analyticsEventKey: "analytics_event_name_your_network_opened",
                                titleI18nKey: "your_network",
                            },
                            component: () => import(/* webpackChunkName: "network" */ "./views/intranet/directory/YourNetwork.vue"),
                        },
                        {
                            path: "people",
                            name: "people",
                            meta: {
                                analyticsEventKey: "analytics_event_name_people_directory_opened",
                                titleI18nKey: "people",
                            },
                            component: () => import(/* webpackChunkName: "peopleList" */ "./views/intranet/directory/PeopleList.vue"),
                        },
                        {
                            path: "teams",
                            name: "teams",
                            meta: {
                                analyticsEventKey: "analytics_event_name_teams_overview_opened",
                                titleI18nKey: "teams",
                            },
                            component: () => import(/* webpackChunkName: "teams" */ "./views/intranet/directory/Teams.vue"),
                        },
                        {
                            path: "groups",
                            name: "groups",
                            meta: {
                                analyticsEventKey: "analytics_event_name_groups_overview_opened",
                                titleI18nKey: "groups",
                            },
                            component: () => import(/* webpackChunkName: "groups" */ "./views/intranet/directory/Groups.vue"),
                        },
                        {
                            path: "skillfinder",
                            name: "skillfinder",
                            meta: {
                                analyticsEventKey: "analytics_event_name_skillfinder_opened",
                                titleI18nKey: "skillfinder",
                            },
                            component: () => import(/* webpackChunkName: "skillFinder" */ "./views/intranet/directory/Skillfinder.vue"),
                        },

                    ],
                },
                {
                    path: "teams/:teamUid",
                    name: "team-profile",
                    meta: {
                        analyticsEventKey: "analytics_event_name_teams_profile_opened",
                        analyticsParams: (route: Route): Record<string, unknown> => ({
                            teamUid: route.params.teamUid,
                        }),
                    },
                    component: () => import(/* webpackChunkName: "groupDetail" */ "./views/intranet/directory/teams/TeamProfile.vue"),
                    props: true,
                },
                {
                    path: "groups/:groupId",
                    name: "group",
                    meta: {
                        analyticsEventKey: "analytics_event_name_group_view_opened",
                    },
                    component: () => import(/* webpackChunkName: "groupDetail" */ "./views/intranet/directory/GroupDetail.vue"),
                    props: true,
                },
                {
                    path: "topics",
                    name: "topics",
                    meta: {
                        analyticsEventKey: "analytics_event_name_topics_opened",
                        titleI18nKey: "topics",
                    },
                    component: () => import(/* webpackChunkName: "topics-route" */ "./views/intranet/topics/TopicsRoute.vue"),
                },
                {
                    path: "people/:userUid/:focusSection?",
                    name: "user-profile",
                    component: () => import(/* webpackChunkName: "profile" */ "./views/intranet/directory/ProfileRoute.vue"),
                    props: true,
                },
                {
                    path: "news",
                    name: "news",
                    meta: {
                        analyticsEventKey: "analytics_event_name_news_hub_opened",
                        titleI18nKey: "news",
                    },
                    component: () => import(/* webpackChunkName: "news" */ "./views/news/News.vue"),
                },
                {
                    path: "news/id/:newsUid/:commentPath?",
                    name: "news-route",
                    meta: {
                        analyticsEventKey: "analytics_event_name_news_opened",
                    },
                    component: () => import(/* webpackChunkName: "news-route" */ "./views/news/NewsRoute.vue"),
                    props: true,
                },
                {
                    path: "posts",
                    name: "posts",
                    meta: {
                        analyticsEventKey: "analytics_event_name_posts_overview_opened",
                    },
                    component: () => import(/* webpackChunkName: "posts-route" */ "./views/posts/PostsRoute.vue"),
                    props: true,
                },
                {
                    // The "/topic" path is not optional for reverse referencing in router-link and there is no different approach in vue-router v3 to unify this route with the "posts" route altough it has to be the very same.
                    path: "posts/topic/:topicUid",
                    name: "posts-single-topic",
                    meta: {
                        analyticsEventKey: "analytics_event_name_posts_overview_opened",
                    },
                    component: () => import(/* webpackChunkName: "posts-route" */ "./views/posts/PostsRoute.vue"),
                    props: true,
                },
                {
                    path: "post/id/:postUid/:commentPath?",
                    name: "post-route",
                    meta: {
                        analyticsEventKey: "analytics_event_name_post_single_view_opened",
                    },
                    component: () => import(/* webpackChunkName: "post-route" */ "./views/posts/PostRoute.vue"),
                },
                {
                    path: "event",
                    name: "event",
                    redirect: { name: "event-overview" },
                    component: () => import(/* webpackChunkName: "social-happenings-tab" */ "./views/events/Events.vue"),
                    children: [
                        {
                            path: "social",
                            name: "social",
                            meta: {},
                            component: () => import(/* webpackChunkName: "social-happenings-tab" */ "./views/events/SocialHappeningsTab.vue"),
                        },
                        {
                            path: "event-overview",
                            name: "event-overview",
                            meta: {
                                analyticsEventKey: "analytics_event_name_events_overview_opened",
                            },
                            component: () => import(/* webpackChunkName: "event-overview-tab" */ "./views/events/EventOverviewTab.vue"),
                        },
                    ],
                },
                {
                    path: "event/:eventUid",
                    name: "event-modal-route",
                    meta: {
                        analyticsEventKey: "analytics_event_name_event_single_view_opened",
                    },
                    props: true,
                    beforeEnter: (to, from, next) => {
                        router.isOpenedFromDirectLink = router.isOpenedFromDirectLink || {
                            triggered: from.name === null,
                            pathName: location.pathname,
                        };
                        to.matched[0].components.default = Intranet;
                        to.matched[1].components.default = from.matched.length ? from.matched[1].components.default : Events;
                        to.matched[1].components.modal = EventModalRoute;
                        next();
                    },
                },
                {
                    path: "admin",
                    name: "admin",
                    component: () => import(/* webpackChunkName: "admin" */ "./views/intranet/Admin.vue"),
                    children: [
                        {
                            path: "",
                            alias: "general",
                            name: "general",
                            meta: {
                                analyticsEventKey: "analytics_event_name_admin_general_opened",
                                titleI18nKey: "general",
                            },
                            component: () => import(/* webpackChunkName: "general" */"@web/views/intranet/admin/general/GeneralSettings.vue"),
                        }, {
                            path: "theme",
                            name: "theme",
                            meta: {
                                analyticsEventKey: "analytics_event_name_admin_theme_opened",
                                titleI18nKey: "theme",
                            },
                            component: () => import(/* webpackChunkName: "theme" */"./views/intranet/admin/theming/Theming.vue"),
                        }, {
                            path: "admin-integrations",
                            name: "admin-integrations",
                            meta: {
                                analyticsEventKey: "analytics_event_name_admin_integrations_opened",
                                titleI18nKey: "integrations",
                            },
                            component: () => import(/* webpackChunkName: "admin-integrations" */"@web/views/intranet/UserSettings/integration/IntegrationSettings.vue"),
                        }, {
                            path: "workspace",
                            name: "workspace",
                            meta: {
                                analyticsEventKey: "analytics_event_name_admin_account_opened",
                                titleI18nKey: "workspace_and_subscription",
                            },
                            component: () => import(/* webpackChunkName: "account" */"@web/views/intranet/admin/account/AccountSettings.vue"),
                        }, {
                            path: "launchpad",
                            name: "launchpad",
                            meta: {
                                analyticsEventKey: "analytics_event_name_launchpad_admin_view_opened",
                                titleI18nKey: "launchpad",
                            },
                            component: () => import(/* webpackChunkName: "launchpad" */"@web/views/intranet/admin/launchpad/Launchpad.vue"),
                        }, {
                            path: "user-access",
                            name: "user-access",
                            meta: {
                                analyticsEventKey: "analytics_event_name_admin_user_access_opened",
                                titleI18nKey: "admin_user_access_title",
                            },
                            component: () => import(/* webpackChunkName: "user-access" */"./views/intranet/admin/user-access/UserAccess.vue"),
                        }, {
                            path: "features",
                            name: "features",
                            meta: {
                                analyticsEventKey: "analytics_event_name_admin_features_opened",
                                titleI18nKey: "admin_features_title",
                            },
                            component: () => import(/* webpackChunkName: "features" */"./views/intranet/admin/features/FeatureSettings.vue"),
                        },
                    ],
                },
                {
                    path: "usersettings",
                    name: "usersettings",
                    component: () => import(/* webpackChunkName: "usersettings" */ "./views/intranet/UserSettings/UserSettings.vue"),
                    redirect: { name: "user-integrations" },
                    children: [
                        {
                            path: "notifications",
                            name: "notifications",
                            meta: {
                                titleI18nKey: "notifications",
                            },
                            component: () => import(/* webpackChunkName: "usersettings-notifications" */"@web/views/intranet/UserSettings/notification-settings/NotificationSettings.vue"),
                        }, {
                            path: "user-integrations",
                            name: "user-integrations",
                            meta: {
                                titleI18nKey: "integrations",
                            },
                            component: () => import(/* webpackChunkName: "usersettings-user-integrations" */"@web/views/user-options/IntegrationSettings.vue"),
                        },
                    ],
                },
                {
                    path: "kitchensink",
                    name: "kitchensink",
                    meta: {
                        titleI18nKey: "kitchensink",
                    },
                    component: () => import(/* webpackChunkName: "kitchensink" */ "./components/KitchenSink.vue"),
                },
                {
                    path: "subscription-expired",
                    name: "subscription-expired",
                    component: () => import("./views/SubscriptionExpiredScreen.vue"),
                },
            ],
        },
        {
            alias: "/",
            path: "/portal",
            name: "portal",
            component: Portal,
            children: [
                {
                    alias: "/",
                    path: "login",
                    name: "login",
                    meta: {
                        titleI18nKey: "login",
                        allowUnauthenticatedAccess: true,
                        openAsDeepLinkOnMobile: true,
                    },
                    component: () => import(/* webpackChunkName: "login" */ "./views/portal/Login.vue"),
                },
                {
                    path: "redeem-invite",
                    name: "redeem-invite",
                    meta: {
                        analyticsEventKey: "analytics_event_name_invite_redeem_opened",
                        allowUnauthenticatedAccess: true,
                        openAsDeepLinkOnMobile: true,
                    },
                    component: () => import(/* webpackChunkName: "login" */ "./views/portal/Login.vue"),
                    props: (route) => ({
                        intranetAccessController: IntranetAccessController.forInvite({
                            intranetUid: route.query.intranetUid as string,
                            inviteId: route.query.token as string,
                        }),
                    }),
                },
                {
                    path: "join",
                    name: "join",
                    meta: {
                        analyticsEventKey: "analytics_event_name_join_opened",
                        allowUnauthenticatedAccess: true,
                        openAsDeepLinkOnMobile: true,
                    },
                    component: () => import(/* webpackChunkName: "login" */ "./views/portal/Login.vue"),
                    props: (route) => ({
                        intranetAccessController: IntranetAccessController.forAccessCode({
                            intranetUid: route.query.intranetUid as string | undefined,
                            accessCode: route.query.accessCode as string | undefined,
                        }),
                    }),
                },
            ],
        },
        {
            path: "/atlassian-cloud",
            name: "atlassian-cloud",
            component: AtlassianCloudWrapper,
            children: [
                {
                    path: "confluence/admin/main-configuration",
                    name: "confluence-cloud-admin-main-configuration",
                    meta: {
                        analyticsEventKey: "analytics_event_name_atl_cloud_view_main_configuration",
                    },
                    component: () => import(/* webpackChunkName: "confluence-cloud-admin-main-configuration" */ "./views/atlassian-cloud/confluence/admin/ConfluenceConfiguration.vue"),
                },
                {
                    path: "confluence/macro/dashboard-news",
                    name: "confluence-cloud-macro-dashboard-news",
                    meta: {
                        analyticsEventKey: "analytics_event_name_atl_cloud_view_dashboard_news",
                    },
                    component: {
                        extends: AuthWrapper,
                        props: {
                            component: { default: () => ConfluenceDashboardNews },
                        },
                    },
                    props: (route) => ({ intranetUid: route.query.intranetUid }),
                },
                {
                    path: "confluence/macro/news-list",
                    name: "confluence-cloud-macro-news-list",
                    meta: {
                        analyticsEventKey: "analytics_event_name_atl_cloud_view_news_list",
                    },
                    component: {
                        extends: AuthWrapper,
                        props: {
                            component: { default: () => NewsList },
                        },
                    },
                    props: (route) => ({ intranetUid: route.query.intranetUid }),
                },
                {
                    path: "confluence/macro/news-magazine",
                    name: "confluence-cloud-macro-news-magazine",
                    meta: {
                        analyticsEventKey: "analytics_event_name_atl_cloud_view_news_magazine",
                    },
                    component: {
                        extends: AuthWrapper,
                        props: {
                            component: { default: () => NewsMagazine },
                        },
                    },
                    props: (route) => ({ intranetUid: route.query.intranetUid }),
                },
                {
                    path: "confluence/macro/post-timeline",
                    name: "confluence-cloud-macro-post-timeline",
                    meta: {
                        analyticsEventKey: "analytics_event_name_atl_cloud_view_post_timeline",
                    },
                    component: {
                        extends: AuthWrapper,
                        props: {
                            component: { default: () => PostTimeLineMacro },
                        },
                    },
                    props: (route) => ({ intranetUid: route.query.intranetUid }),
                },
                {
                    path: "confluence/macro/news-create",
                    name: "confluence-cloud-macro-news-create",
                    meta: {
                        analyticsEventKey: "",
                    },
                    component: {
                        extends: AuthWrapper,
                        props: {
                            component: { default: () => NewsCreate },
                        },
                    },
                    props: (route) => ({ intranetUid: route.query.intranetUid }),
                },
                {
                    path: "confluence/macro/news-create-macro",
                    name: "confluence-cloud-macro-news-create-macro",
                    meta: {
                        analyticsEventKey: "",
                    },
                    component: {
                        extends: AuthWrapper,
                        props: {
                            component: { default: () => NewsCreateMacro },
                        },
                    },
                    props: (route) => ({ intranetUid: route.query.intranetUid }),
                },

            ],
        },
        {
            path: "/bc",
            name: "broadcast-channel",
            component: () => import(/* webpackChunkName: "broadcast" */ "./views/BroadcastChannel.vue"),
        },
    ],
});

// re-routing when subscription is expired
router.afterEach(async(to: Route, _: Route) => {
    const allowedRoutes = [
        "subscription-expired",
        "workspace",
        "portal",
        "login",
        "/",
    ];
    if (allowedRoutes.includes(to.name || "some-fallback-name-for-typing-purpose")) {
        return;
    }
    if (!router.app.$store.getters[INTRANET_MODULE_NAME + INTRANET]) {
        // when initially opening the dashboard, the intranet is not immediately set
        // since requests are blocked by the backend anyway, there should be no issue doing this
        // 5 seconds seems to be enough, but might need testing and adjustment on prod
        let waitCounter = 0;
        while (waitCounter < 10 && !router.app.$store.getters[INTRANET_MODULE_NAME + INTRANET]) {
            await wait(500);
            waitCounter++;
        }
    }
    const subscriptionDaysLeft = router.app.$store.getters[INTRANET_MODULE_NAME + SUBSCRIPTION_DAYS_LEFT];
    if (subscriptionDaysLeft !== undefined && subscriptionDaysLeft <= 0) {
        await router.push({ name: "subscription-expired" });
    }
});

// Mobile device re-routing
router.beforeEach((to, from, next) => {
    if (
        from.name == null &&
        to.meta?.openAsDeepLinkOnMobile === true &&
        (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone/i))
    ) {
        window.open(`linchpincloud://${to.fullPath}`, "_self");
        setTimeout(() => next(), 500);
        return;
    }
    next();
});

// Analytics events
router.afterEach((to: Route, from: Route) => {
    // Read hubspot lead information (hsl = Hubspot lead)
    if (to.query.hsl_channel) {
        hubspotService.setLeadInformation({
            leadChannel: to.query.hsl_channel as string,
            pageUrl: to.query.hsl_page_url as string,
            pageName: to.query.hsl_page_name as string,
        });
    }

    // Log analytic events
    if (to.meta?.analyticsEventKey) {
        const analyticsEvent = (getGlobalConfiguration() as any)[to.meta.analyticsEventKey];
        if (!analyticsEvent) {
            console.error(`No analyticsEvent found for '${to.meta.analyticsEventKey}'`);
        }
        const intentParam = to.query?.intent == null ? {} : { intent: to.query.intent };
        const params = to.meta.analyticsParams?.(to);
        analytics.log(analyticsEvent, { intranetUid: to.params.intranetId, ...intentParam, ...params });
    }
});

// Product Fruits events
router.afterEach(() => {
    // @ts-ignore
    if (window.productFruits && window.productFruits.pageChanged) {
        // @ts-ignore
        window.productFruits.pageChanged();
    }
});

export default router;
