<template>
    <div v-if="authStateResolved">
        <component
            :is="component"
            v-if="component"
            :intranet-uid="intranetUid"
        />
    </div>
</template>

<script>

import Vue from "vue";
import { mapState } from "vuex";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import { AUTH_MODULE_NAME } from "@web/store/auth/auth";

export default Vue.extend({
    name: "AuthWrapper",
    props: {
        component: Object,
        intranetUid: String
    },
    computed: {
        ...mapState(INTRANET_MODULE_NAME, ["intranet"]),
        ...mapState(AUTH_MODULE_NAME, ["authStateResolved"])
    }
});

</script>
