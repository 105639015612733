import { VuexRootState } from "@web/store";
import { Module } from "vuex";
import { LaunchpadDestination } from "@backend/launchpad/types";
import {
    GET_DESTINATIONS,
    GET_ERROR,
    IS_EMPTY
} from "@web/store/launchpad/getters";
import {
    SET_FETCHING,
    SET_LAUNCHPAD_DESTINATIONS
} from "@web/store/launchpad/mutations";
import {
    CREATE_DESTINATION,
    DELETE_DESTINATION,
    FETCH_DESTINATIONS,
    RESET_DESTINATIONS,
    UPDATE_DESTINATION
} from "@web/store/launchpad/actions";
import { launchpadService } from "@web/services";

export const LAUNCHPAD_MODULE_NAME = "launchpad/";
const LAUNCHPAD_PAGE_SIZE = 10;

export interface LaunchpadModuleState {
    destinations: LaunchpadDestination[];
    initiallyLoaded: boolean;
    hasMore: boolean;
    fetching: boolean;
    hasError: boolean;
}

const getDefaultState = (): LaunchpadModuleState => ({
    destinations: [],
    initiallyLoaded: false,
    hasMore: false,
    fetching: true,
    hasError: false,
});

export const LAUNCHPAD_MODULE = {
    strict: true,
    namespaced: true,
    state: getDefaultState(),
    getters: {
        [GET_DESTINATIONS]: state => () => {
            return state.destinations;
        },
        [GET_ERROR]: state => {
            return state.hasError;
        },
        [IS_EMPTY]: state => {
            return state.destinations.length === 0 && !state.fetching && state.initiallyLoaded;
        },
    },
    mutations: {
        [SET_LAUNCHPAD_DESTINATIONS](state, { destinations, hasNextPage, error }: {destinations: LaunchpadDestination[], hasNextPage: boolean, error: boolean}) {
            state.initiallyLoaded = true;
            state.destinations = destinations;
            state.hasMore = hasNextPage;
            state.hasError = error;
        },
        [SET_FETCHING](state, { fetching }: {fetching: boolean}) {
            state.fetching = fetching;
        }
    },
    actions: {
        async [RESET_DESTINATIONS]({ commit }) {
            commit(SET_LAUNCHPAD_DESTINATIONS, { destinations: [], hasNextPage: true, error: false });
        },
        async [FETCH_DESTINATIONS]({ commit, rootState, state }) {
            commit(SET_FETCHING, { fetching: true });

            const intranetUid = rootState.intranet.intranet!.uid;
            const previouslyFetchedDestinations = state.destinations;
            const fetchedDestinations: LaunchpadDestination[] = await launchpadService.getDestinations(intranetUid, {
                limit: LAUNCHPAD_PAGE_SIZE + 1,
                startAfter: state.destinations[state.destinations.length - 1]?.uid,
            }).catch(() => {
                commit(SET_LAUNCHPAD_DESTINATIONS, {
                    destinations: [],
                    hasNextPage: false,
                    error: true,
                });
                commit(SET_FETCHING, { fetching: false });
            });
            const hasNextPage = fetchedDestinations.length > LAUNCHPAD_PAGE_SIZE;
            if (hasNextPage) {
                fetchedDestinations.pop();
            }
            const allFetchedDestinations = previouslyFetchedDestinations.concat(fetchedDestinations);

            commit(SET_LAUNCHPAD_DESTINATIONS, {
                destinations: allFetchedDestinations,
                hasNextPage,
                error: false,
            });
            commit(SET_FETCHING, { fetching: false });
        },
        async [CREATE_DESTINATION]({ commit, rootState, state }, { url, title, icon }) {
            const intranetUid = rootState.intranet.intranet!.uid;
            const destinations = state.destinations;
            const createdDestination = await launchpadService.createDestination(intranetUid, {
                url,
                title,
                icon,
            });
            destinations.push(createdDestination);
            destinations.sort((destination1: LaunchpadDestination, destination2: LaunchpadDestination) => destination1.title.localeCompare(destination2.title));

            commit(SET_LAUNCHPAD_DESTINATIONS, {
                destinations,
                hasNextPage: state.hasMore,
                error: state.hasError,
            });
        },
        async [DELETE_DESTINATION]({ commit, rootState, state }, { destinationUid }) {
            const intranetUid = rootState.intranet.intranet!.uid;
            const previousDestinations = state.destinations;
            await launchpadService.deleteDestination(intranetUid, destinationUid);
            const updatedDestinations = previousDestinations.filter((destination) => destination.uid !== destinationUid);

            commit(SET_LAUNCHPAD_DESTINATIONS, {
                destinations: updatedDestinations,
                hasNextPage: state.hasMore,
                error: state.hasError,
            });
        },
        async [UPDATE_DESTINATION]({ commit, rootState, state }, updatedDestination) {
            const intranetUid = rootState.intranet.intranet!.uid;
            const destinations = state.destinations;
            await launchpadService.updateDestination(intranetUid, updatedDestination);
            const index = destinations.findIndex((destination) => destination.uid === updatedDestination.uid);
            destinations.splice(index, 1, updatedDestination);

            commit(SET_LAUNCHPAD_DESTINATIONS, {
                destinations,
                hasNextPage: state.hasMore,
                error: state.hasError,
            });
        }
    },
} as Module<LaunchpadModuleState, VuexRootState>;
