import {EverestContentEntity} from "@backend/entity/types";
import {SignedResizedImageUrls} from "@backend/common/image-resizing/types";

export enum EntityWithAttachmentType {
    post = "post",
    comment = "comment",
    news = "news",
}

export enum AttachmentType {
    image = "IMAGE",
    // video = "VIDEO",
    file = "FILE",
}

export interface AttachmentInFirestore extends EverestContentEntity {
    intranetStoragePath: string;
    attachmentType: AttachmentType;
    fileName: string;
}

export interface SignedStorageUrls {
    original: string;
    resized?: SignedResizedImageUrls;
}

export interface Attachment extends AttachmentInFirestore {
    uid: string;
    urls: SignedStorageUrls;
    attachmentType: AttachmentType;
    fileName: string;
}
