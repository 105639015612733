import { DeltaOperation } from "quill";
import Delta from "quill-delta";
import { cloneDeepClean } from "@web/lib/cloneDeepClean";

export const blotName = "read-more";

export type TruncateClickCallback = () => void;

interface TruncateDeltaParams {
    ops: DeltaOperation[];
    maxLength: number;
    readMoreLabel: string;
    onClick: TruncateClickCallback;
}

function getBlockAttributesAtPosition({ source, position }: { source: Delta, position: number }): Record<string, string | number> {
    let blockAttributes = {};
    let analyzedCharacters = 0;
    source.eachLine((line, attributes) => {
        analyzedCharacters += line.length();
        if (analyzedCharacters > position) {
            blockAttributes = attributes;
            return false;
        }
    });
    return blockAttributes;
}

const ellipsis = "… ";

export function truncateDelta({ ops, maxLength, readMoreLabel, onClick }: TruncateDeltaParams): DeltaOperation[] {
    const sourceDelta = new Delta(cloneDeepClean(ops));
    const maxEfficientLength = maxLength + ellipsis.length + readMoreLabel.length;
    if (sourceDelta.length() > maxEfficientLength) {
        const blockAttributes = getBlockAttributesAtPosition({
            source: sourceDelta,
            position: maxLength,
        });
        const truncatingDelta = new Delta()
            .retain(maxLength)
            .delete(Number.POSITIVE_INFINITY)
            .insert(ellipsis)
            .insert(readMoreLabel, { [blotName]: onClick })
            .insert("\n", blockAttributes);
        const truncatedDelta = sourceDelta.compose(truncatingDelta);
        return truncatedDelta.ops;
    }
    return ops;
}
