
import Vue from "vue";
import { mapState } from "vuex";
import { INTRANET_MODULE_NAME } from "@web/store/intranet/intranet";
import { IntranetColors } from "@backend/theming/types";

// this mixin provides theming colors as css variables. Use the method injectStyle after setup intranet.
// within your stylesheet you can use var({YOUR_COLOR}) to access the current theme color
// i.E var(--primary) provides you the primary color
export default Vue.extend<any, any, any, any>({
    name: "StyleMixin",
    computed: {
        ...mapState(INTRANET_MODULE_NAME, ["intranet"])
    },
    methods: {
        setCSSVariable(property: string, val: string) {
            (this.$el as HTMLElement).style.setProperty(property, val); // tslint:disable-line
            (document.body as HTMLElement).style.setProperty(property, val); // tslint:disable-line
        },
        hexToRgb(color: string) {
            const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(color);
            if (result) { return `${parseInt(result[1], 16)},${parseInt(result[2], 16)},${parseInt(result[3], 16)}`; }
            return null;
        },
        styleInject() {
            if (!this.intranet) {
                return;
            }
            this.setIntranetColors(this.intranet.colors);
        },
        setIntranetColors(colors: IntranetColors) {
            this.setCSSVariable("--primary", colors.primaryColor);
            this.setCSSVariable("--primary-text", colors.primaryTextColor);
            this.setCSSVariable("--secondary", colors.secondaryColor);
            this.setCSSVariable("--secondary-text", colors.secondaryTextColor);
            this.setCSSVariable("--warning", colors.errorColor);
            this.setCSSVariable("--warning-text", colors.errorTextColor);
            this.setCSSVariable("--info", colors.infoColor);
            this.setCSSVariable("--info-text", colors.infoTextColor);
            this.setCSSVariable("--sidebar-bg", colors.sidebarColor);
            this.setCSSVariable("--sidebar-text", colors.sidebarTextColor);
            this.setCSSVariable("--sidebar-active", colors.sidebarActiveColor);
            this.setCSSVariable("--primary-list", this.hexToRgb(colors.primaryColor));
        }
    }
});
