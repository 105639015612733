import {
    ReactableEntityLocation,
    ReactionRecipientUserUid,
    ReactionType
} from "@backend/reaction/types";
import {
    MentionEntityLocation,
    MentionEntityType
} from "@backend/mention/mention-types";
import { Intranet } from "@backend/intranet/types";

/**
 * Activities describe events that happen within the application. Their payload consists of different extension
 * of the interface ActivityInPubSub. Currently activities are intranet-scoped and published to pub/sub topic
 * intranet-activity. Users might be informed over different notifications channels when an activity occurs.
 */
export enum ActivityType {
    commentPublished = "COMMENT_PUBLISHED",
    reactionCreated = "REACTION_CREATED",
    newsPublished = "NEWS_PUBLISHED",
    //eventPublished = "EVENT_PUBLISHED", not implemented yet, part of HALO-2499
    postPublished = "POST_PUBLISHED",
    userMentioned = "USER_MENTIONED",
    socialNotification = "SOCIAL_NOTIFICATION",
}

export const UnicastTypes = [
    // ActivityType.REACTION_CREATED,
    ActivityType.socialNotification,
];

export enum SocialNotificationType {
    heyFive = "HEY_FIVE",
    birthday = "BIRTHDAY",
    anniversary = "ANNIVERSARY",
    newEmployee = "NEW_EMPLOYEE",
}

export enum ActivityEntityType {
    news = "news",
    post = "post",
    //event = "event",
}

export type Activity =
    ActivityCommentPublished
    | ActivityReactionCreated
    | ActivityNewsPublished
    //| ActivityEventPublished
    | ActivityPostPublished
    | ActivityUserMentioned
    | ActivitySocialNotification

export const MAX_USER_PER_INTRANET_LIMIT_FOR_NOTIFICATION_FEATURE = 5000;

// every activity should have at least these keys
// most implementation will bring some kind of a creatorUid or userUid.
// this is not part of this base implementation as there may be some activities in the future
// where there is no explicit initiator for an activity (birthdays, recommendations, ...)
interface ActivityInPubSub {
    intranetUid: string;
    dateUtc: string; // TODO: check if we can use a date object
}

export interface UnicastActivity extends ActivityInPubSub {
    creatorUid: string;
    recipientUid: string;
}

export interface ActivitySocialNotification extends UnicastActivity {
    type: ActivityType.socialNotification;
    meta: {
        socialNotificationType: SocialNotificationType,
        message?: string,
    };
}

export interface ActivityCommentPublished extends ActivityInPubSub {
    type: ActivityType.commentPublished;
    meta: {
        rootEntityType: ActivityEntityType,
        entityUid: string,
        trimmedContentTitle: string,
        trimmedContentSubTitle: string,
        commentPath: string,
        creatorUid: string,
        creatorDisplayName: string,
        creatorPhotoUrl: string,
        intranet: Intranet,
    };
}

export interface ActivityReactionCreated extends ActivityInPubSub {
    type: ActivityType.reactionCreated;
    meta: {
        rootEntityType: ActivityEntityType,
        entityUid: string,
        userUid: string,
        userDisplayName: string,
        userPhotoUrl: string,
        reactionType: ReactionType,
        trimmedContentTitle: string,
        trimmedContentSubTitle: string,
        entityLocation: ReactableEntityLocation,
        recipientUserUid: ReactionRecipientUserUid,
        intranet: Intranet,
    }
}

export interface ActivityNewsPublished extends ActivityInPubSub {
    type: ActivityType.newsPublished;
    meta: {
        rootEntityType: ActivityEntityType,
        entityUid: string,
        creatorUid: string,
        creatorDisplayName: string,
        creatorPhotoUrl: string,
        trimmedTitle: string,
        trimmedSubTitle: string,
        intranet: Intranet,
    };
}

/*export interface ActivityEventPublished extends ActivityInPubSub {
    type: ActivityType.EVENT_PUBLISHED;
    meta: { eventUid: string, creatorUid: string, trimmedTitle: string, };
}*/

export interface ActivityPostPublished extends ActivityInPubSub {
    type: ActivityType.postPublished;
    meta: {
        rootEntityType: ActivityEntityType,
        entityUid: string,
        creatorUid: string,
        creatorDisplayName: string,
        creatorPhotoUrl: string,
        intranet: Intranet,
    };
}

export interface ActivityUserMentioned extends ActivityInPubSub {
    type: ActivityType.userMentioned;
    meta: {
        rootEntityType: ActivityEntityType,
        entityType: MentionEntityType,
        mentionedUserUids: string[],
        entityLocation: MentionEntityLocation,
        entityUid: string,
        creatorUid: string,
        creatorDisplayName: string,
        creatorPhotoUrl: string,
        trimmedTitle: string,
        trimmedSubTitle: string,
        intranet: Intranet,
    };
}

export type PickForType<A, T> = A extends { type: T } ? A : never
