import { cloudRunUrl } from "@web/cloud-run-url";
import {
    USER_INDEX_NAME,
    UserIndexEntity,
} from "@backend/index/types";
import dayjs from "dayjs";
import { dayJsToDatestampMD } from "@web/lib/time-utils";
import {
    AbstractSearchService,
    QueryParams,
} from "@web/services/AbstractSearchService";
import { UserProfile } from "@backend/user-profile/types";
import {
    SearchForFacetValuesResponse,
    SearchResponse,
} from "@algolia/client-search";

export interface AnniversaryRangeQueryParams {
    intranetUid: string;
    facetName: string,
    from: dayjs.Dayjs,
    to: dayjs.Dayjs,
    pageSize?: number;
    pageNumber?: number;
    /**
     * filter query string which is appended to the generated query using AND
     */
    filterExtension?: string;
}

function range(facetName: string, from: string | number, to: string | number): string {
    return `${facetName}:${from} TO ${to}`;
}

function or(a: string, b: string): string {
    return `(${a} OR ${b})`;
}

function and(a: string, b: string): string {
    return `(${a} AND ${b})`;
}

const userSearchTokenEndpoint = `${cloudRunUrl.thirdPartySearch}/api/search/user-search-token/`;

interface SearchFacetsParams {
    intranetUid: string;
    field: keyof UserProfile;
    query: string;
    pageNumber: number;
    pageSize: number;
}

export class UserSearchService extends AbstractSearchService<UserIndexEntity> {
    constructor() {
        super(USER_INDEX_NAME, userSearchTokenEndpoint);
    }

    public async searchTeamMembers({ teamUid, ...queryParams }: QueryParams & { teamUid: string }): Promise<SearchResponse<UserIndexEntity>> {
        return this.search({
            facetFilters: [`team.uid:${teamUid}`],
            ...queryParams,
        });
    }

    public async searchUserProfileFieldValues({ intranetUid, field, query, pageNumber, pageSize }: SearchFacetsParams): Promise<SearchForFacetValuesResponse> {
        const index = await this.getSearchIndex(intranetUid);
        return index.searchForFacetValues(field, query, { page: pageNumber, maxFacetHits: pageSize });
    }

    /**
     * Queries for anniversaries in a given range using `DatestampMD`s. In case a range spans over two years
     * the query is split into two date ranges.
     *
     * NOTE: date ranges >= 1 year are currently not supported!
     */
    public async filterAnniversaryRange(
        { intranetUid, facetName, from, to, pageSize, pageNumber, filterExtension }: AnniversaryRangeQueryParams,
    ): Promise<UserIndexEntity[]> {
        if (from.diff(to, "years") !== 0) {
            throw new Error("SearchService.filterAnniversaryRange doesn't support ranges larger than a year yet!");
        }

        const fromTimestamp = dayJsToDatestampMD(from);
        const toTimestamp = dayJsToDatestampMD(to);

        let filters;
        // in case the range goes from something like 30.12.2021 to 02.01.2022 we have to do a query per year
        if (from.year() - to.year() !== 0) {
            const endOfYear = dayJsToDatestampMD(from.endOf("year"));
            const startOfYear = dayJsToDatestampMD(to.startOf("year"));
            filters = or(range(facetName, fromTimestamp, endOfYear), range(facetName, startOfYear, toTimestamp));
        } else {
            filters = range(facetName, fromTimestamp, toTimestamp);
        }
        if (filterExtension) {
            filters = and(filters, `(${filterExtension})`);
        }
        const results = await this.search({ intranetUid, filters, pageSize, pageNumber });
        return results.hits;
    }
}

export function locationEquals(indexEntity: UserIndexEntity, index: number, array: UserIndexEntity[]): boolean {
    return indexEntity.location?.position?.lat === array[0].location?.position?.lat &&
        indexEntity.location?.position?.lng === array[0].location?.position?.lng;
}
