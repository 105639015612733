import { cloudRunUrl } from "@web/cloud-run-url";
import { ApiClient } from "@web/api/ApiClient";
import { ApiClientBuilder } from "@web/api/ApiClientBuilder";
import { Iso2Language } from "@backend/user-profile/iso2LanguageCodes";
import { FileUploadService } from "@web/services/file-upload";
import { ProfileService } from "@web/store/profile/ProfileService";
import { asTimestamp } from "@web/lib/time-utils";

export class ProfileAuxiliaryService {
    public static readonly profileAuxiliaryEndpoint = `${cloudRunUrl.userProfile}/api/profile`;

    private readonly api: Promise<ApiClient>;

    constructor(private readonly fileUploadService: FileUploadService, private readonly profileService: ProfileService) {
        this.api = new ApiClientBuilder().build();
    }

    public async getIsoLanguages(): Promise<Iso2Language[]> {
        const client = await this.api;
        const response = await client.get<Iso2Language[]>(ProfileAuxiliaryService.profileAuxiliaryEndpoint + "/languages");
        return response.data;
    }

    public async updateProfileCoverImage(intranetUid: string, userUid: string, file: Blob): Promise<void> {
        const uploadTarget = await this.fileUploadService.uploadFile(
            `${ProfileAuxiliaryService.profileAuxiliaryEndpoint}/user/${intranetUid}/${userUid}/upload-profile-cover-image`,
            file,
            userUid,
            intranetUid,
        );

        await this.profileService.updateUserProfile(intranetUid, userUid, { profileCoverImageStoragePath: uploadTarget.path });
    }

    public async updateProgressSkipTimestamp(intranetUid: string, userUid: string): Promise<void> {
        await this.profileService.updateUserProfile(intranetUid, userUid, { progressHintSkippedTimestamp: asTimestamp(new Date()) });
    }
}
