import { VNode } from "vue";
import { DirectiveBinding } from "vue/types/options";
import VueI18n from "vue-i18n";

export default {
    bind(el: HTMLElement, binding: DirectiveBinding, vnode: VNode) {
        // Check required expression
        if (!binding.expression) {
            throw new Error(`Missing parameter for directive '${binding.name}'`);
        }

        updatePageTitle(el, binding, vnode);
    },
    update(el: HTMLElement, binding: DirectiveBinding, vnode: VNode) {
        updatePageTitle(el, binding, vnode);
    }
};

function updatePageTitle(el: HTMLElement, binding: DirectiveBinding, vnode: VNode) {
    const i18n: VueI18n | undefined = vnode.context?.$i18n;
    const pageTitle = getPageTitle(binding.value, i18n);
    const intranetName = currentIntranet(vnode).displayName || "";
    const titleSystem = i18n?.t("title_system").toString() || "";
    const titleDelimiter = i18n?.t("title_delimiter").toString();

    if (pageTitle && intranetName) {
        document.title = pageTitle + titleDelimiter + intranetName;
    } else if (!pageTitle && !intranetName) {
        document.title = titleSystem;
    } else if (!intranetName) {
        document.title = pageTitle + titleDelimiter + titleSystem;
    } else if (!pageTitle) {
        document.title = intranetName;
    }
}

function getPageTitle(object: any, $i18n: VueI18n | undefined) {
    if (isRoute(object)) {
        if (object.meta && object.meta.titleI18nKey) {
            return $i18n?.t(object.meta.titleI18nKey);
        } else {
            return "";
        }
    }
    return object;
}

function isRoute(object: any) {
    return object && object.fullPath;
}

function currentIntranet(vnode: VNode) {
    if (vnode && vnode.context && vnode.context.$store && vnode.context.$store.getters) {
        return vnode.context.$store.getters["intranet/intranet"] || {};
    }
    return {};
}
